
const geoSettings = {
    geoserverRestPath: 'https://gis.dmt.de/geoserver/rest/',
    geoserverPath: 'https://gis.dmt.de/geoserver/',
    geoserverAuthentification: {Authorization: 'Basic ' + 'YWRtaW46QXVndXN0QDIwMTE='},
    wmsWorkspace: 'DMT',
    activeBaseLayer: 'gm_layer_maps',
    counterLayers:4,
    poiLayerKey:100000000000,
    sensorTypeImageUrl: '/assets/sensortypes/',
    redirectUrlPermalink: process.env.NODE_ENV === 'development' ? 'http://localhost:1841/' : 'https://app.safeguard.dmt-group.com/',
};

export default geoSettings;