/*
 * ChannelCalibrationOverview.tsx
 * Author: lnappenfeld
 * Date: 14.06.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {useTranslation} from 'react-i18next';
import {deleteChannelCalibration, getChannelCalibrations} from '../functions/Metrology';
import CustomDialog, {CustomDialogHeaderType} from '../global/CustomDialog';
import {ChannelCalibration, ChannelCalibrationForm, ChannelCalibrationFormType} from './ChannelCalibrationForm';
import {
    buttonCreateChannelCalibrationFirst,
    buttonCreateChannelCalibrationNow,
    buttonCreateChannelCalibrationZeros,
    buttonDeleteChannelCalibration,
    makeButtonItem
} from '../global/viewButtonSelection';
import ViewButtons from '../global/ViewButtons';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {hideWaitAnimation, showWaitAnimation} from '../global/CustomWaitAnimation';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';


type Props = {
    projectId: string
    channelId: string | null
}
export const ChannelCalibrationOverview = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    const [channelCalibrations, setChannelCalibrations] = useState<any>([]);
    const [selectedChannelCalibrations, setSelectedChannelCalibrations] = useState<any[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const [channelCalibrationFormType, setChannelCalibrationFormType] = useState<ChannelCalibrationFormType>(ChannelCalibrationFormType.Default);
    const [showChannelCalibrationDialog, setShowChannelCalibrationDialog] = useState<boolean>(false);

    useEffect(() => {
        _getChannelCalibrations();
    }, []);

    const _getChannelCalibrations = () => {
        if (props.channelId) {
            setDataLoaded(false);
            getChannelCalibrations(props.projectId, props.channelId).then(result => {
                setChannelCalibrations(result);
                setDataLoaded(true);
            });
        }
    };

    const _deleteChannelCalibrations = () => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('metrologyManagement:dialogs.deleteChannelCalibrationDialog.message'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                showWaitAnimation();
                deleteChannelCalibration(props.projectId, selectedChannelCalibrations).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.channelCalibrationDeleted'));
                        setSelectedChannelCalibrations([]);
                        _getChannelCalibrations();
                    }
                    hideWaitAnimation();
                });
            }
        });
    };


    const channelsColumns: ColumnObject[] = [
        {field: 'channel_name', header: t('channel'), filter: true, filterField: 'channel_name'},
        {field: 'timestamp', header: t('metrologyManagement:attributes.validFrom'), filter: false, type: 4, dateFormat: 'DD.MM.YYYY HH:mm:ssZ'},
        {field: 'factor', header: t('metrologyManagement:attributes.factor'), filter: false, style: {maxWidth: '80px'}},
        {field: 'addend', header: t('metrologyManagement:attributes.addend'), filter: false, style: {maxWidth: '100px'}},
        {field: 'changed_by_username', header: t('changedByUsername'), style: {minWidth: '255px'}},
        {field: 'changed_at_utc', header: t('changedAtLocale'), type: 4},
    ];

    return (<>
        <div className={'mt-3 ml-2 mr-2'}>
            <div className={'card'}>
                <ViewButtons
                    title={t('')}
                    items={[
                        makeButtonItem(buttonCreateChannelCalibrationNow, t, () => {
                            setChannelCalibrationFormType(ChannelCalibrationFormType.Now);
                            setSelectedChannelCalibrations([]);
                            setShowChannelCalibrationDialog(true);
                        }),
                        makeButtonItem(buttonCreateChannelCalibrationFirst, t, () => {
                            setChannelCalibrationFormType(ChannelCalibrationFormType.First);
                            setSelectedChannelCalibrations([]);
                            setShowChannelCalibrationDialog(true);
                        }),
                        makeButtonItem(buttonCreateChannelCalibrationZeros, t, () => {
                            setChannelCalibrationFormType(ChannelCalibrationFormType.Zeros);
                            setSelectedChannelCalibrations([]);
                            setShowChannelCalibrationDialog(true);
                        }),
                        makeButtonItem(buttonDeleteChannelCalibration, t, () => {
                            _deleteChannelCalibrations();
                        }, true, selectedChannelCalibrations.length < 1)
                    ]}
                />
                <CustomDataTable
                    id='channelCalibrations'
                    selectionMode={'multiple'}
                    columns={channelsColumns}
                    editable={true}
                    sortable={true}
                    value={channelCalibrations}
                    selection={selectedChannelCalibrations}
                    onEditClick={(data: ChannelCalibration) => {
                        setChannelCalibrationFormType(ChannelCalibrationFormType.Edit);
                        setSelectedChannelCalibrations([data]);
                        setShowChannelCalibrationDialog(true);
                    }}
                    onSelectionChange={(e: any) => {
                        setSelectedChannelCalibrations(e.value);
                    }}
                    headerTitle={t('metrologyManagement:global.channelCalibrations')}
                    ableToDelete={false}
                    ableToUpdate={true}
                    dataloaded={dataLoaded}
                />
            </div>
        </div>
        <CustomDialog
            header={selectedChannelCalibrations.length > 0
                ? t('metrologyManagement:dialogs.editChannelCalibrationDialog.header')
                : t('metrologyManagement:dialogs.createChannelCalibrationDialog.header')
            }
            headerType={selectedChannelCalibrations.length > 0
                ? CustomDialogHeaderType.Update
                : CustomDialogHeaderType.Create
            }
            formName={'formChannelCalibration'}
            onCancel={() => {setShowChannelCalibrationDialog(false);}}
            onHide={() => {setShowChannelCalibrationDialog(false);}}
            visible={showChannelCalibrationDialog}
            style={{ width: '25vw'}}
        >
            <ChannelCalibrationForm
                type={channelCalibrationFormType}
                projectId={props.projectId}
                channelId={props.channelId}
                channelCalibration={selectedChannelCalibrations.length > 0 ? selectedChannelCalibrations[0]: null}
                onFinished={()=>{
                    setShowChannelCalibrationDialog(false);
                    setSelectedChannelCalibrations([]);
                    _getChannelCalibrations();
                }}
            />
        </CustomDialog>
    </>);
};
