import React, {useEffect, useRef, useState} from 'react';
import CustomDialog from '../global/CustomDialog';
import {Button} from 'primereact/button';
import {WizardMDeviceEditForm} from './MetrologyWizardMeasuringDevice';
import {TabView} from 'primereact/tabview';
import {useTranslation} from 'react-i18next';
import {createMetrologyFromCSV, createMetrologyFromWizard} from '../functions/Metrology';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {hideWaitAnimation, showWaitAnimation} from '../global/CustomWaitAnimation';

type Props = {
    projectId: string,
    onFinish?: () => void,
    setEditMetrology: (edit: boolean) => void,
    editMetrology: boolean
}
export const MetrologyWizardFromCSV = (props: Props): JSX.Element => {
    const projectId = props.projectId;
    const {t} = useTranslation(['common']);

    const saveData = (e:any) => {
        console.log(e);
        console.log(e.target.files[0]);
        const reader = new FileReader();
        reader.readAsText(e.target.files[0]);
        reader.onload = function (eLoad) {
            console.log(reader.result);
            const result:any = reader.result;

                const data = {
                    'project_id': props.projectId,
                    'data': result
                };
                showWaitAnimation();
                createMetrologyFromCSV(data).then(result => {
                    hideWaitAnimation();
                    if (result.error) {
                        showMessageOnError(t('error'), JSON.stringify(result.error));
                        return;
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.metrologyFromWizardCreated'));
                        if (props.onFinish) props.onFinish();
                    }
                });
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    return (
        <CustomDialog
            onHide={() => { props.setEditMetrology(false); }}
            maximized={true}
            visible={props.editMetrology}
            header={t('metrologyManagement:editDialog.header')}
            onCancel={() => { props.setEditMetrology(false); }}
            showFooter={true}
        >
            <div className={'grid'}>
            <input type={'file'} name={'csvFile'} accept={'text/csv'} onChange={saveData}/>
            </div>
        </CustomDialog>
    );
}