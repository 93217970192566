/*
 * OperatingStateOverview.tsx
 * Author: lnappenfeld
 * Date: 16.06.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */
import React, {useEffect, useState} from 'react';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {useTranslation} from 'react-i18next';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {checkPermission, getGroupedItems} from '../../functions/functionLibrary';
import {permissions} from '../../config/permissions';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {
    deleteOperatingStates,
    getOperatingStates,
    OperatingState,
    OperatingStateCondition
} from '../functions/ConditionMonitoring';
import {OperatingStateConditionOverview} from './OperatingStateConditionOverview';
import {OSCFormType} from './OperatingStateConditionForm';

type Props = {
    project: any,
    reload?: boolean,
    setReload?: (reload: boolean) => void
    setSelectedOperatingStates?: (data: OperatingState[]) => void
    selectedOperatingStates?: OperatingState[],
    setEditOperatingState?: (data: boolean) => void,
    // OperatingStateConditions
    reloadOperatingStateConditions?: boolean,
    setReloadOperatingStateConditions?: (reload: boolean) => void
    setSelectedOperatingStateConditions?: (data: OperatingStateCondition[]) => void
    selectedOperatingStateConditions?: OperatingStateCondition[],
    setEditOperatingStateCondition?: (data: boolean) => void,
}
export const OperatingStateOverview = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    const [operatingStates, setOperatingStates] = useState<any[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    // arrays for filter dropdowns
    const [elements, setElements] = useState<any[]>([]);

    useEffect(() => {
        console.log('reload: ', props.reloadOperatingStateConditions)
    }, [props.reloadOperatingStateConditions]);

    const _setOperatingStates = () => {
        getOperatingStates(props.project.id).then(result => {
            for (let i = 0; i < result.length; i++) {
                // Füge die Unterkomponente nur hinzu, wenn der OperatingState auch OperatingStateConditions hat
                if (result[i].has_operating_state_conditions)
                    result[i]['expandableElement'] = (
                        <div className={'card'}>
                            <OperatingStateConditionOverview
                                type={OSCFormType.Default}
                                key={'co'+i}
                                projectId={props.project.id}
                                operatingStateId={result[i].operating_state_id}
                                selectedOperatingStateConditions={props.selectedOperatingStateConditions}
                                setSelectedOperatingStateConditions={props.setSelectedOperatingStateConditions}
                                setEditOperatingStateCondition={props.setEditOperatingStateCondition}
                                setReload={props.setReloadOperatingStateConditions}
                                reload={props.reloadOperatingStateConditions}
                            />
                        </div>
                    );

            }
            setOperatingStates(result);
            setDataLoaded(true);
            setElements(getGroupedItems(result, 'element_id', 'element_name'));
        });
    };

    useEffect(() => {
        _setOperatingStates();
    }, []);

    useEffect(() => {
        // Lade neu, z.B. wenn Elemente gelöscht wurden
        if (props.reload) {
            _setOperatingStates();
            if (props.setReload) props.setReload(false);
        }
    }, [props.reload]);

    const columns: ColumnObject[] = [
        {
            field: 'operating_state_name', header: t('name'),
            filter: true, filterField: 'operating_state_name'
        }, {
            field: 'element_name', header: t('metrologyManagement:attributes.operatingStateElementName'),
            filter: true, filterField: 'element_id', filterDropdownValues: elements,
        }, {
            field: 'description', header: t('description'),
            filter: true, filterField: 'description'
        }];

    const filters = {
        'operating_state_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'element_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'description': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
    };

    return (
        <>
            <CustomDataTable
                key={'operating_states'}
                id='operating_states'
                selectionMode={'multiple'}
                expandable={true}
                columns={columns}
                editable={checkPermission(permissions.editMetrology)}
                sortable={true}
                value={operatingStates}
                paginator={operatingStates.length > 100}
                selection={props.selectedOperatingStates}
                onSelectionChange={(e: any) => {
                    if (props.setSelectedOperatingStates) props.setSelectedOperatingStates(e.value);
                }}
                onRemoveClick={(data: OperatingState) => {
                    // eslint-disable-next-line new-cap
                    CustomConfirmDialog({
                        message: t('metrologyManagement:dialogs.deleteOperatingStateDialog.message'),
                        header: t('confirmation'),
                        translation: t,
                        onConfirm: () => {
                            if(data.operating_state_id)
                                deleteOperatingStates(props.project.id, [data.operating_state_id]).then(result => {
                                    if (result.error) {
                                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                                    } else {
                                        _setOperatingStates();
                                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.operatingStateDeleted'));
                                    }
                                });
                        }
                    });
                }}
                onEditClick={(data: OperatingState) => {
                    if (props.setSelectedOperatingStates) props.setSelectedOperatingStates([data]);
                    if (props.setEditOperatingState) props.setEditOperatingState(true);
                }}
                ableToDelete={checkPermission(permissions.editMetrology)}
                ableToUpdate={checkPermission(permissions.editMetrology)}
                filters={filters}
                dataloaded={dataLoaded}
            />
        </>
    );
};
