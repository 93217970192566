/*
 * NotificationTypeSelectList.tsx
 * Author: lnappenfeld
 * Date: 02.05.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import Input from '../global/Input';
import {getNotificationTypes} from '../functions/Notification';

type Props = {
    projectId: string,
    label: string,
    value?: number,
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    name?: string,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    disabled?: boolean,
}
const NotificationTypeSelectList = (props: Props): JSX.Element => {

    // Initialisiere mit undefined, damit das Skeletonloading funktioniert
    const [list, setList] = useState();

    useEffect(() => {
        getNotificationTypes(props.projectId).then(result => {
            const tmp: any = [];
            result.forEach((key: any) => {
                tmp.push({
                    value: key.id.toString(),
                    label: key.name,
                });
            });
            setList(tmp);
        });
    }, []);

    return (
        <Input
            name={props.name ? props.name : 'notification_type_id'}
            edit={true}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            label={props.label}
            value={props.value}
            dropdownOptions={list} type={'dropdown'}
            onChange={(e: any) => {
                if (props.setValue) props.setValue(e.value);
                if (props.onChange) props.onChange(e);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};
export default NotificationTypeSelectList;
