/*
 * CreateDashboardPanelForm.tsx
 * Author: lnappenfeld
 * Date: 04.10.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Channel, createGrafanaPanel,} from '../functions/Metrology';
import Input from '../global/Input';
import {useForm} from 'react-hook-form';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {hideWaitAnimation, showWaitAnimation} from '../global/CustomWaitAnimation';
import {GrafanaDashboardSelectList} from '../global/GrafanaDashboardSelectList';

type Props = {
    projectId: string
    channels: Channel[]
    onFinished: (success: boolean) => void,
}

export const CreateDashboardPanelForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const defaultValues = {
        'dashboard_id': null,
        'panel_title': null,
        'is_downsampling_active': false
    };

    const {control, formState: {errors}, handleSubmit} = useForm({defaultValues});

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    const saveData = (data: any) => {

        showWaitAnimation();
        const filteredChannels = [];
        for (const channel of props.channels) {
            filteredChannels.push({
                'channel_id': channel.channel_id,
                'channel_name': channel.channel_name
            });
        }

        const projectId = {'project_id': props.projectId};
        const channels = {'channels': filteredChannels};
        data = {...data, ...projectId, ...channels};

        createGrafanaPanel(data).then(result => {
            hideWaitAnimation();
            if (result.error) {
                showMessageOnError(t('error'), t(result.error));
            } else {
                showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.dashboardPanelCreated'));
                props.onFinished(true);
            }
        });
    };

    const channelsColumns: ColumnObject[] = [
        {field: 'is_active', header: t('active'), style: {maxWidth: '100px'}},
        {field: 'channel_name', header: t('name')},
        {field: 'unit', header: t('unit'), style: {maxWidth: 105}},
        {field: 'channel_type_name', header: t('type')},
        {field: 'sensor_name', header: t('sensor')},
        {field: 'measuring_device_name', header: t('measuring_device')},
        {field: 'location_name', header: t('location')},
        {field: 'project_group_name', header: t('projectManagement:attributes.projectGroup')},
        {field: 'timezone', header: t('timezone')}
    ];

    return (
        <>
            <div className={'card'}>
                <CustomDataTable
                    id="selectedChannels"
                    style={{'maxHeight': '400px', 'overflow': 'auto'}}
                    selectionMode={'single'}
                    expandable={false}
                    columns={channelsColumns}
                    editable={false}
                    sortable={true}
                    value={props.channels}
                    paginator={props.channels.length > 100}
                    ableToDelete={false}
                    ableToUpdate={false}
                    filters={[]}
                    headerTitle={t('metrologyManagement:global.selectedChannels')}
                    dataloaded={true}
                />
            </div>
            <form id='createDashboardPanelForm' onSubmit={handleSubmit(saveData)}>
                <div className='card'>
                    <div className={'grid w-auto mt-3'}>
                        <div className={'col-4'}>
                            <GrafanaDashboardSelectList
                                projectId={props.projectId}
                                label={t('metrologyManagement:attributes.grafanaDashboard')}
                                name={'dashboard_id'}
                                showClear={false}
                                validationControl={control}
                                validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: t('metrologyManagement:attributes.grafanaDashboard') + ' ' + t('input:required')}}
                            />
                        </div>
                        <div className={'col-4'}>
                            <Input
                                edit={true} label={t('metrologyManagement:attributes.panelTitle')} name={'panel_title'}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: t('metrologyManagement:attributes.panelTitle') + ' ' + t('input:required')}}
                            />
                        </div>
                        <div className={'col-4'}>
                            <Input
                                edit={true} label={t('metrologyManagement:attributes.isDownsamplingActive')}
                                name={'is_downsampling_active'} type={'checkbox'}
                                tooltip={t('metrologyManagement:tooltips.isDownsamplingActive')}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: false}}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};