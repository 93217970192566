import React, {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {useTranslation} from 'react-i18next';
import {
    getCMCorrelatedValues,
    getMonitoringAppAreaConfiguration,
    getMonitoringAppParameter
} from '../functions/EnergyEfficiencyConfiguration';
import Formula from 'fparser';
import {showMessageOnError} from '../../global/CustomToast';

type GraphProps = {
    projectId: string,
    monitoringAppId: string,
    monitoringAppTypeId: string,
    updateGraph? : boolean
}
const PolynomialGraph = (props: GraphProps)=>{
    const { t } = useTranslation(['common']);

    const [immutable, setImmutable] = useState<boolean>(false);

    const valArr1: any = [];
    const valArr2: any = [];
    for (let i=0; i <= 100; i++) {
        const calculate = -2.73451360119785 * Math.pow(10, -11) * Math.pow(i, 7) + 7.42856191616696 * Math.pow(10, -9) * Math.pow(i, 6) + -7.91442907930069 * Math.pow(10, -7) * Math.pow(i, 5) + 0.0000420142645484276 * Math.pow(i, 4) + -0.00121090628831478 * Math.pow(i, 3) + 0.0155606448014406 * Math.pow(i, 2) + -0.125125178118036 * Math.pow(i, 1) + 316.50664159516 * Math.pow(i, 0);
        valArr1.push(calculate);

        const calculate1 = -4.07364431513201 * Math.pow(10, -12) * Math.pow(i, 7) + 4.07138313145943* Math.pow(10, -10) * Math.pow(i, 6) + 5.38206000690537 * Math.pow(10, -8) * Math.pow(i, 5) + -8.95708387267135 * Math.pow(10, -6) * Math.pow(i, 4) + 0.000432938935017391 * Math.pow(i, 3) + -0.0166699104413879 * Math.pow(i, 2) + 1.3278132984849 * Math.pow(i, 1) + 0.00457934161996888 *  Math.pow(i, 0);
        valArr2.push(calculate1);
    }

    const [graphMax, setGraphMax] = useState<number>();
    const [minThreshold, setMinThreshold] = useState<any>(null);
    const [maxThreshold, setMaxThreshold] = useState<any>(null);
    const [forderhohePolynom, setForderhohePolynom] = useState<any>([]);
    const [forderhoheValues, setForderhoheValues] = useState<any>([]);

    const [leistungsbedarfPolynom, setLeistungsbedarfPolynom] = useState<any>([]);
    const [leistungsbedarfValues, setLeistungsbedarfValues] = useState<any>([]);

    const [wirkungsgradPolynom, setWirkungsgradPolynom] = useState<any>([]);
    const [wirkungsgradValues, setWirkungsgradValues] = useState<any>([]);

    const [startTime, setStartTime] = useState<string>(() => {
        const date = new Date();
        date.setDate(date.getDate() - 2);
        date.setHours(0, 0, 0, 0);
        const user = JSON.parse(localStorage.getItem('user') as string);
        const options:any = {
            timeZone: user['timezone'],
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false // Use 24-hour format
        };

        return date.toLocaleString('en-US', options);
    });
    const [endTime, setEndTime] = useState<string>(() => {
        const date = new Date();
        date.setHours(23, 59, 0, 0);
        const user = JSON.parse(localStorage.getItem('user') as string);
        const options:any = {
            timeZone: user['timezone'],
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false // Use 24-hour format
        };

        return date.toLocaleString('en-US', options);

    });

    // equation = -2.73451360119785 * 10^-11 * x^7 + 7.42856191616696 * 10^-9 * x^6 + -7.91442907930069 * 10^-7 * x^5 + 0.0000420142645484276 * x^4 + -0.00121090628831478 * x^3 + 0.0155606448014406 * x^2 + -0.125125178118036 * x^1 + 316.50664159516 * x^0
    // equation2 = -4.07364431513201 * 10^-12 * x^7 + 4.07138313145943* 10^-10 * x^6 + 5.38206000690537 * 10^-8 * x^5 + -8.95708387267135 * 10^-6 * x^4 + 0.000432938935017391 * x^3 + -0.0166699104413879 * x^2 + 1.3278132984849 * x^1 + 0.00457934161996888 *  x^0

    const setGraphPolynoms = (data: any) => {
        if (data[0]['min'] && data[0]['max'] !== null) {
            const values : any = [];
            for (let i=Number(data[0]['min']); i <= Number(data[0]['max']); i++) {
                try {
                    const calculate = new Formula(data[0]['function']);
                    values.push(calculate.evaluate({x: i}));
                }
                catch (e) {
                    showMessageOnError(t('error'), t('conditionMonitoring:formula_error'));
                }
            }
            if (data[0]['min'] > 0) {
                for (let i= 0; i < Number(data[0]['min']); i++) {
                    values.unshift(null);
                }
            }
            setForderhohePolynom(values);
        }
        if (data[1]['min'] && data[1]['max'] !== null) {
            const values : any = [];
            for (let i=Number(data[1]['min']); i <= Number(data[1]['max']); i++) {
                try {
                    const calculate = new Formula(data[1]['function']);
                    values.push(calculate.evaluate({x: i}));
                }
                catch (e) {
                    showMessageOnError(t('error'), t('conditionMonitoring:formula_error'));
                }
            }
            if (data[1]['min'] > 0) {
                for (let i= 0; i < Number(data[1]['min']); i++) {
                    values.unshift(null);
                }
            }
            setLeistungsbedarfPolynom(values);
        }
        if (data[2]['min'] && data[2]['max'] !== null) {
            const values : any = [];
            for (let i=Number(data[2]['min']); i <= Number(data[2]['max']); i++) {
                try {
                    const calculate = new Formula(data[2]['function']);
                    values.push(calculate.evaluate({x: i}));
                }
                catch (e) {
                    showMessageOnError(t('error'), t('conditionMonitoring:formula_error'));
                }
            }
            if (data[2]['min'] > 0) {
                for (let i= 0; i < Number(data[2]['min']); i++) {
                    values.unshift(null);
                }
            }
            setWirkungsgradPolynom(values);
        }

    };

    function createScatterSeries(
        name: string,
        color: string,
        data: any[],
        valueSuffix: string
    ) {
        // console.log(name, ' ===> ', data);
        return {
            name,
            type: 'scatter',
            color,
            data: data.map((point) => ({
                x: point.value_x,
                y: point.value_y,
                date: point.date
            })),
            tooltip: {
                pointFormatter: function (this: Highcharts.Point): string {
                    interface CustomPoint extends Highcharts.Point {
                        date: string;
                    }
                    const customPoint = this as CustomPoint;
                    return `<span>Date: ${Highcharts.dateFormat(
                        '%m/%d/%Y %l:%M%p',
                        new Date(customPoint.date).getTime()
                    )}</span><br/>
          <span>Value X: ${customPoint.x} ${valueSuffix}</span><br/>
          <span>Value Y: ${customPoint.y}</span>`;
                }
            },
            enableMouseTracking: true
        };
    }
    const forderhoheSeries = createScatterSeries('Forderhöhe', 'red', forderhoheValues, 'Kw');
    const leistungsbedarfSeries = createScatterSeries(
        'Leistungsbedarf',
        'green',
        leistungsbedarfValues,
        '%'
    );
    const wirkungsgradSeries = createScatterSeries(
        'Wirkungsgrad',
        'yellow',
        wirkungsgradValues,
        'm3/h'
    );

    const
        options ={
            chart: {
                zoomType: 'xy',
                // height: 500,
            },
            title: {
                text: `${t('conditionMonitoring:energy_eff_graph')}`,
            },
            credits: {
                enabled: false,
            },
            xAxis: [{
                min: 0,
                max: Number(graphMax),
                title: {
                    text: 'Volumenstrom [m3/h]',
                },
                plotLines: [{
                    color: '#FF0000', // Red
                    width: 2,
                    value: minThreshold,
                    label: {
                        text: minThreshold,
                        rotation: '(center, 0, 0)',
                        style: {color: '#FF0000'},
                        y: 15,
                        x: -25
                    }
                },{
                    color: '#FF0000', // Red
                    width: 2,
                    value: maxThreshold,
                    label: {
                        text: maxThreshold,
                        rotation: '(center, 0, 0)',
                        style: {color: '#FF0000'},
                        y: 15,
                        x: 8
                    }
                }]
            }],
            yAxis: [{ // Primary yAxis
                title: {
                    text: 'Förderhöhe [m]/Leistungsbedarf [Kw] ',
                },
            }, { // Secondary yAxis
                title: {
                    text: 'Wirkungsgrad [%]',
                },
                opposite: true
            }],
            tooltip: {
                shared: true
            },
            series: [
                {
                    name: 'Förderhöhe_Kennl.',
                    type: 'line',
                    // yAxis: 1,
                    data: forderhohePolynom,
                    color: 'blue',
                    enableMouseTracking: false
                },
                {
                    name: 'Wirkungsgrad_Kennl.',
                    type: 'line',
                    states:{
                        hover:{
                            enabled: false
                        },
                    },
                    // yAxis: 1,
                    data: wirkungsgradPolynom,
                    color: 'orange',
                    enableMouseTracking: false
                },
                {
                    name: 'Leistungsbedarf_Kennl.',
                    type: 'line',
                    states:{
                        hover:{
                            enabled: false
                        },
                    },
                    // yAxis: 1,
                    data: leistungsbedarfPolynom,
                    color: 'yellow',
                    enableMouseTracking: false
                },
                forderhoheSeries,
                leistungsbedarfSeries,
                wirkungsgradSeries,
                {
                    data: [0],
                    yAxis: 1,
                    showInLegend: false,
                    enableMouseTracking: false,
                    // visible: true
                }
            ],
        };

    const chartRef = useRef<any>(null);
    const colors = ['yellow', 'blue', 'orange'];

    const apiCalls = async ()=>{
        setImmutable(true);
        const monitoringAppAreaResponse = await getMonitoringAppAreaConfiguration(props.projectId, props.monitoringAppId, props.monitoringAppTypeId);

        const forderhoheChannel = monitoringAppAreaResponse['responseArr'][0]['channel_id'];
        const leistungsbedarfChannel = monitoringAppAreaResponse['responseArr'][1]['channel_id'];
        const wirkungsgradChannel = monitoringAppAreaResponse['responseArr'][2]['channel_id'];

        const monitoringAppParameterResponse = await getMonitoringAppParameter(props.projectId, props.monitoringAppId);
        const max1 = monitoringAppParameterResponse['polynomfunctions'][0]['max'];
        const max2 = monitoringAppParameterResponse['polynomfunctions'][1]['max'];
        const max3 = monitoringAppParameterResponse['polynomfunctions'][2]['max'];
        setGraphPolynoms(monitoringAppParameterResponse['polynomfunctions']);
        const channelX = monitoringAppParameterResponse['thresholds'][0]['channel_id'];
        const maxNumber = Math.max(max1, max2, max3);
        setGraphMax(maxNumber);
        const min = monitoringAppParameterResponse['thresholds'][0]['qmin'] === '' ? null : monitoringAppParameterResponse['thresholds'][0]['qmin'];
        const max = monitoringAppParameterResponse['thresholds'][0]['qmax'] === '' ? null : monitoringAppParameterResponse['thresholds'][0]['qmax'];
        setMinThreshold(min);
        setMaxThreshold(max);

        if (channelX !== null && forderhoheChannel !== null) {
            const cmCorrelatedValuesResponse = await getCMCorrelatedValues(props.projectId, channelX, forderhoheChannel, '2023-08-16T00:00:00Z', '2023-08-30T23:59:00Z', '0 hour');
            setForderhoheValues(cmCorrelatedValuesResponse);
        }
        if (channelX !== null && leistungsbedarfChannel !== null) {
            const cmCorrelatedValuesResponse = await getCMCorrelatedValues(props.projectId, channelX, leistungsbedarfChannel, startTime, endTime, '0 hour');
            setLeistungsbedarfValues(cmCorrelatedValuesResponse);
        }
        if (channelX !== null && wirkungsgradChannel !== null) {
            const cmCorrelatedValuesResponse = await getCMCorrelatedValues(props.projectId, channelX, wirkungsgradChannel, startTime, endTime, '0 hour');
            setWirkungsgradValues(cmCorrelatedValuesResponse);
        }
        monitoringAppAreaResponse['responseArr'].map((val: any, index: number) => {
            const chart = chartRef.current?.chart;
            if (chart) {
                const xAxis = chart?.xAxis[0];
                const yAxis = chart?.yAxis[0];
                const x1 = xAxis?.toPixels(Number(val.x1));
                const y1 = yAxis?.toPixels(Number(val.y1));
                const x2 = xAxis?.toPixels(Number(val.x2));
                const y2 = yAxis?.toPixels(Number(val.y2));
                const width = Math.abs(x2 - x1);
                const height = Math.abs(y1 - y2);

                chart?.renderer.rect(Number(x1), Number(y1), Number(width), Number(height), 5)
                    .attr({
                        'stroke-width': 2,
                        stroke: 'black',
                        fill: colors[index],
                        opacity: 0.1,
                        zIndex: 5
                    })
                    .add()
                    .toFront();
            }
        });

    };
    useEffect(() => {
        apiCalls();

    }, [chartRef, props.updateGraph]);

    return (<>
        <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} immutable={immutable}/>
    </>);
};

export default PolynomialGraph;