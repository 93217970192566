import {updateAccessToken} from '../services/KeycloakService';

const keycloakfetch = {
    get: (url) => {
        return updateAccessToken().then(() => {
            return new Promise(function (resolve, reject) {
                // console.log(url);
                resolve(fetch(url, {
                    headers: {
                        authorization: 'Bearer ' + localStorage.getItem('keycloak-token')
                    }
                }).then(res => {
                    const contentType = res.headers.get('content-type');
                    if (contentType && contentType.indexOf('application/json') !== -1) {
                        return res.json();
                    }else if (contentType && contentType.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') !== -1) {
                        return res.blob();
                    } else {
                        return res.text();
                    }
                }));
            });
        })
    },
    post: (url, data) => {
        return updateAccessToken().then(() => {
            return new Promise(function (resolve, reject) {
                resolve(fetch(url, {
                    method: 'POST',
                    headers: {
                        authorization: 'Bearer ' + localStorage.getItem('keycloak-token'),
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                }).then(res => {
                    const contentType = res.headers.get('content-type');
                    if (contentType && contentType.indexOf('application/json') !== -1) {
                        return res.json();
                    } else {
                        return res.text();
                    }
                }));
            });
        })
    },
    put: (url, data) => {
        return updateAccessToken().then(() => {
            return new Promise(function (resolve, reject) {
                resolve(fetch(url, {
                    method: 'PUT',
                    headers: {
                        authorization: 'Bearer ' + localStorage.getItem('keycloak-token'),
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                }).then(res => {
                    const contentType = res.headers.get('content-type');
                    if (contentType && contentType.indexOf('application/json') !== -1) {
                        return res.json();
                    } else {
                        return res.text();
                    }
                }));
            });
        })
    },
    delete: (url, data) => {
        return updateAccessToken().then(() => {
            return new Promise(function (resolve, reject) {
                resolve(fetch(url, {
                    method: 'DELETE',
                    headers: {
                        authorization: 'Bearer ' + localStorage.getItem('keycloak-token'),
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                }).then(res => {
                    const contentType = res.headers.get('content-type');
                    if (contentType && contentType.indexOf('application/json') !== -1) {
                        return res.json();
                    } else {
                        return res.text();
                    }
                }));
            });
        })
    },
};

export default keycloakfetch;
