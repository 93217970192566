/*
 * ProjectAdvancedSettingsForm.tsx
 * Author: lnappenfeld
 * Date: 24.01.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */


import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {showMessageOnError, showMessageOnSuccess, showMessageOnWarn} from '../global/CustomToast';
import {
    addNextcloudToProject,
    getProjectBilling,
    Project, updateAdvancedProjectSettings,
} from '../functions/Project';
import {Button} from 'primereact/button';
import PortMasterSelectList from './PortMasterSelectList';
import {getLocation, getLocations, Location, Nullable} from '../functions/Global';
import LocationSelectList from '../global/LocationSelectList';
import BaseLayerSelectList from './BaseLayerSelectList';
import {LocationDialogEditForm} from '../global/LocationDialogEditForm';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLocationDot} from '@fortawesome/free-solid-svg-icons';
import Input from '../global/Input';
import {checkPermission, checkProjectType, ProjectType, urlValidation} from '../../functions/functionLibrary';
import {permissions} from '../../config/permissions';
import {callBaseLayerMap} from '../../functions/functionsOpenlayer';
import {ViewButton} from '../global/ViewButton';
import {ViewButtonsContainer} from '../global/ViewButtonsContainer';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {hideWaitAnimation, showWaitAnimation} from '../global/CustomWaitAnimation';
import {ControlDevice, startFu} from '../functions/Metrology';

type Props = {
    project: Project,
    onFinished?: (project: Nullable<Project>) => void,
}

/**
 * Form for editing and creating of projects
 * @param props
 * @constructor
 */
const ProjectAdvancedSettingsForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [locationCenter, setLocationCenter] = useState<Nullable<Location>>(null);
    // Initialisiere mit undefined, damit das Skeletonloading funktioniert
    const [locations, setLocations] = useState<Location[]>();
    const [selectedLocations, setSelectedLocations] = useState<Location[]>([]);
    const [editLocation, setEditLocation] = useState<boolean>(false);
    const [projectId, setProjectId] = useState<string | null>(null);
    const [workspaceBaseLayers, setWorkspaceBaseLayers] = useState<any>([]);

    const defaultValues = {
        'location_id': props.project ? props.project.location_id : null,
        'location_zoom': props.project && props.project.location_zoom ? props.project.location_zoom : 10.0,
        'geoserver_workspace': props.project ? props.project.geoserver_workspace : null,
        'baselayer_workspace': props.project ? props.project.baselayer_workspace : null,
        'base_layer': props.project ? props.project.base_layer : null,
        'cesium': props.project ? props.project.cesium : null,
        'scanner': props.project ? props.project.scanner : null,
        'nextcloud': props.project ? props.project.nextcloud : null,
        'wiki_url': props.project ? props.project.wiki_url : null,
        'safeguard_portmaster': props.project ? props.project.safeguard_portmaster : null,
        'billing': {
            'beginning':    null,
            'ending':       null,
            'factor':       null,
            'sap_number':   null,
            'sap_position': null,
            'sap_contract': null,
        }
    };

    const {control, formState: {errors}, handleSubmit, reset, getValues, setValue, watch} = useForm({defaultValues});

    watch('location_id');
    watch('nextcloud');
    watch('billing.beginning');
    watch('billing.ending');
    watch('billing.factor');
    watch('billing.sap_number');
    watch('billing.sap_position');
    watch('billing.sap_contract');

    const getFormErrorMessage = (name: string) => {
        if (name.includes('.')) {
            const elements = name.split('.');
            const inputName = elements[1];
            // @ts-ignore
            if (errors['billing'] && errors['billing'][inputName]) {
                // @ts-ignore
                return (errors['billing'][inputName] &&
                    // @ts-ignore
                    <small className="p-error custom">{errors['billing'][inputName].message}</small>);
            }
        } else {
            // @ts-ignore
            return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
        }
    };

    const _getLocation = () => {
        if (props.project && props.project.location_id) {
            getLocation(props.project.id, props.project.location_id).then(result => {
                if (result) {
                    setLocationCenter(result);
                    setSelectedLocations([result]);
                }
            });
        }
    };

    useEffect(() => {
        if (props.project) {
            const projectId = props.project.id;
            setProjectId(projectId);

            _getLocations();

            // Nur bei den Projektypen GeoMonitoring und MonitoringEverything
            if (checkProjectType(ProjectType.GeoMonitoring, props.project)) {
                _getProjectBilling();

                // Aktualisiere BaserLayerSelectList
                let workspace = 'DMT';
                if (props.project.baselayer_workspace !== null) {
                    workspace = props.project.baselayer_workspace;
                }
                _getWmsBaseLayers(workspace);
            }
        }

        _getLocation();

        // Update inputs
        reset(defaultValues);
    }, [props.project]);

    useEffect(() => {
        if (getValues('location_id')) {
            getLocation(props.project.id, getValues('location_id')).then(result => {
                if (result) {
                    setLocationCenter(result);
                    setSelectedLocations([result]);
                }
            });
        } else {
            setSelectedLocations([]);
        }
    },[getValues('location_id')]);

    // Nachdem der LocationDialog mit abbrechen geschlossen wurde, werden die selectedLocations zurückgesetzt.
    // Das soll hier aber nicht passieren, deswegen setze danach wieder die selectedLocations
    useEffect(() => {
        if (!editLocation && locationCenter) {
            setSelectedLocations([locationCenter]);
        }
    }, [editLocation]);

    const _getLocations = () => {
        getLocations(props.project.id).then(locations => {
            setLocations(locations);
        });
    };

    const _getProjectBilling = () => {
        getProjectBilling(props.project.id).then(result => {
            if (result) {
                const _billing = {
                    ...result,
                    beginning: result.beginning ? new Date(result.beginning) : null,
                    ending: result.ending ? new Date(result.ending) : null
                };
                // setBilling(_billing);

                const _defaultValues = {
                    ...defaultValues,
                    billing: _billing
                };
                reset(_defaultValues);
            } else {
                // Wenn es kein Billing-Eintrag für das Projekt gibt, muss trotzdem neu gesetzt werden, weil diese
                // Funktion auch beim reload aufgerufen wird.
                reset(defaultValues);
            }
        });
    };


    const _getWmsBaseLayers = (workspace: string) => {
        callBaseLayerMap(workspace).then(result => {
            if (result && result.success === true) {
                const wmsLayers = result.data.wmsLayers.wmsLayer;
                const _workspaceBaseLayers = [];
                for (const layer of wmsLayers) {
                    _workspaceBaseLayers.push({
                        value: layer.name,
                        label: t('geomonitoring:baseLayers.' + layer.name.toLowerCase())
                    });
                }
                setWorkspaceBaseLayers(_workspaceBaseLayers);
            }
        }).catch(err => {
            if (err && err.success === false) {
                showMessageOnError(t('error'), err.info);
                setWorkspaceBaseLayers([]);
            }
        });
    };

    const onAddNextcloudClick = () => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('projectManagement:dialogs.addNextcloudToProject.message'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const vars = {'project_id': projectId};
                showWaitAnimation();
                addNextcloudToProject(vars).then(result => {
                    hideWaitAnimation();
                    if (result.error) {
                        showMessageOnError(t('error'), result.error);
                    } else if (result.warning) {
                        showMessageOnWarn(t('warning'), result.warning);
                    } else {
                        showMessageOnSuccess(t('success'), t('projectManagement:toasts.nextcloudAddedToProject'));
                        if (props.onFinished)
                            props.onFinished(result);
                    }
                });
            }
        });
    }

    const saveData = (data: any) => {
        const projectAddId = {
            'project_id': projectId,
            'id': projectId, // For updating at onFinished()
        };
        data = {...projectAddId, ...data};

        if (!areBillingValuesRequired()) {
            delete data.billing;
        }

        updateAdvancedProjectSettings(data).then(result => {
            if (result.error) {
                showMessageOnError(t('error'), result.error);
            } else {
                showMessageOnSuccess(t('success'), t('projectManagement:toasts.projectUpdated'));
                if (props.onFinished)
                    props.onFinished(data);
            }
        });

    };

    const areBillingValuesRequired = () => {
        return (
            getValues('billing.beginning') !== null || getValues('billing.ending') !== null ||
            getValues('billing.factor') !== null || getValues('billing.sap_contract') !== null ||
            getValues('billing.sap_number') !== null || getValues('billing.sap_position') !== null
        );
    };

    return (<>
        <form id='projectForm' onSubmit={handleSubmit(saveData)} style={{height: '100%'}}>
            <div className='grid' style={{height: '100%'}}>
                <div className='col-12 md:col-4' style={{height: '100%'}}>
                    <div className={'card'} style={{height: '100%'}}>
                        <h2>GIS</h2>
                        <div className='grid -mb-2'>
                            <div className='col-12 md:col-10'>
                                {projectId &&
                                <LocationSelectList
                                    projectId={projectId}
                                    disabled={locations ? locations.length <= 0 : true}
                                    locations={locations}
                                    label={t('projectManagement:attributes.center')}
                                    tooltip={locations && locations.length > 0 ? '' : t('projectManagement:tooltips.center')}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                />}
                            </div>
                            <div className='col-12 md:col-2'>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    height: '7vh'
                                }}>
                                    <Button
                                        className={'p-button-outlined mt-auto'}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            setEditLocation(true);
                                        }}
                                    >
                                        <FontAwesomeIcon className='sidebar-icon' icon={faLocationDot}/>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {checkProjectType(ProjectType.GeoMonitoring) && <>
                            <Input
                                edit={checkPermission(permissions.editProject)}
                                label={t('projectManagement:attributes.geoserver_workspace')}
                                name={'geoserver_workspace'} type={'text'}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: false}}
                            />
                            <Input
                                edit={checkPermission(permissions.editProject)}
                                label={t('projectManagement:attributes.baselayer_workspace')}
                                tooltip={t('projectManagement:tooltips.baselayer_workspace')}
                                name={'baselayer_workspace'} type={'text'}
                                onBlur={(e: any) => {
                                    // Aktualisiere BaserLayerSelectList
                                    let workspace = 'DMT';
                                    if (e.target.value !== null && e.target.value !== '') {
                                        workspace = e.target.value;
                                    }
                                    _getWmsBaseLayers(workspace);
                                }}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: false}}
                            />
                            <BaseLayerSelectList
                                label={t('projectManagement:attributes.baseLayer')}
                                name={'base_layer'} baseLayers={workspaceBaseLayers}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: false}}
                            />
                        </>}
                    </div>
                </div>
                <div className='col-12 md:col-4'>
                    <div className={'card'} style={{height: '100%'}}>
                        <h2>{t('projectManagement:global.miscellaneous')}</h2>
                        <PortMasterSelectList
                            label={t('Portmaster')}
                            mayEditProject={true}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            // value={defaultValues.safeguard_portmaster}
                        />
                        {checkProjectType(ProjectType.GeoMonitoring) && <>
                            <Input
                                edit={checkPermission(permissions.editProject)}
                                label={t('projectManagement:attributes.cesium')}
                                tooltip={t('projectManagement:tooltips.cesium')}
                                name={'cesium'} type={'text'}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{ ...{required: false}, ...urlValidation(t)}}
                            />
                            <Input
                                edit={checkPermission(permissions.editProject)}
                                label={t('projectManagement:attributes.wiki_url')}
                                tooltip={t('projectManagement:tooltips.wiki_url')}
                                name={'wiki_url'} type={'text'}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{ ...{required: false}, ...urlValidation(t)}}
                            />
                            <Input
                                edit={checkPermission(permissions.editProject)}
                                label={t('projectManagement:attributes.scanner')}
                                tooltip={t('projectManagement:tooltips.scanner')}
                                name={'scanner'} type={'text'}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{ ...{required: false}, ...urlValidation(t)}}
                            />
                        </>}
                        <div className='grid -mb-2'>
                            {getValues('nextcloud') !== null &&
                            <div className={getValues('nextcloud') === null ? 'col-12 md:col-4' : 'col-12 md:col-12'}>
                                <Input
                                    edit={checkPermission(permissions.editProject)}
                                    label={t('projectManagement:attributes.nextcloud')}
                                    tooltip={t('projectManagement:tooltips.nextcloud')}
                                    name={'nextcloud'} type={'text'}
                                    disabled={true}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                />
                            </div>}
                            {getValues('nextcloud') === null &&
                            <div className='col-12 md:col-8'>
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <ViewButton
                                        key={'butt_' + 'addNextcloud'}
                                        className={'p-button mt-5'}
                                        label={t('projectManagement:buttons.addNextcloudToProject')}
                                        onClick={onAddNextcloudClick}
                                    />
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className='col-12 md:col-4'>
                    <div className={'card'} style={{height: '100%'}}>
                        {checkProjectType(ProjectType.GeoMonitoring) && <>
                            <h2>{t('projectManagement:global.billing')}</h2>
                            <div className='grid'>
                                <div className='col-12 md:col-6'>
                                    <Input
                                        edit={true} className="w-full" type={'date'} name={'billing.beginning'}
                                        label={t('projectManagement:attributes.beginning')}
                                        options={{showTime: false, showButtonBar: true, showIcon: true}}
                                        onChange={(data: any) => {
                                            // @ts-ignore
                                            if (getValues('billing.ending') !== null && (data.value > getValues('billing.ending'))) {
                                                // Wenn beginning > ending ist wird ending auf null gesetzt
                                                setValue('billing.ending', null);
                                            } else if (data.value === null) {
                                                // Wenn beginning gecleart wird, wird ending ebenfalls auf null gesetzt
                                                setValue('billing.ending', null);
                                            }
                                        }}
                                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                                        validationRules={{required: areBillingValuesRequired()
                                            ? t('projectManagement:attributes.beginning') + ' ' + t('input:required')
                                            : false}
                                        }
                                    />
                                </div>
                                <div className='col-12 md:col-6'>
                                    <Input
                                        edit={true} className="w-full" type={'date'} name={'billing.ending'}
                                        label={t('projectManagement:attributes.ending')}
                                        disabled={!getValues('billing.beginning') ? true : getValues('billing.beginning') === null}
                                        options={{showTime: false, showButtonBar: true, showIcon: true, minDate: getValues('billing.beginning')}}
                                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                                        validationRules={{required: false}}
                                    />
                                </div>
                            </div>
                            <div className='grid -mt-3'>
                                <div className='col-12 md:col-6'>
                                    <Input
                                        edit={true} name={'billing.factor'} type={'number'} maxFractionDigits={3}
                                        label={t('projectManagement:attributes.factor')}
                                        onChange={(event: any) => {
                                            setValue('billing.factor', event.value);
                                        }}
                                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                                        validationRules={{required: areBillingValuesRequired()
                                            ? t('projectManagement:attributes.factor') + ' ' + t('input:required')
                                            : false}
                                        }
                                    />
                                </div>
                                <div className='col-12 md:col-6'>
                                    <Input
                                        edit={true} name={'billing.sap_number'}
                                        label={t('projectManagement:attributes.sap_number')}
                                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                                        validationRules={{required: areBillingValuesRequired()
                                            ? t('projectManagement:attributes.sap_number') + ' ' + t('input:required')
                                            : false}
                                        }
                                    />
                                </div>
                            </div>
                            <div className='grid -mt-4'>
                                <div className='col-12 md:col-6'>
                                    <Input
                                        edit={true} name={'billing.sap_position'} type={'number'}
                                        maxFractionDigits={0} useGrouping={false}
                                        label={t('projectManagement:attributes.sap_position')}
                                        onChange={(event: any) => {
                                            setValue('billing.sap_position', event.value);
                                        }}
                                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                                        validationRules={{required: areBillingValuesRequired()
                                            ? t('projectManagement:attributes.sap_position') + ' ' + t('input:required')
                                            : false}
                                        }
                                    />
                                </div>
                                <div className='col-12 md:col-6'>
                                    <Input
                                        edit={true} name={'billing.sap_contract'}
                                        label={t('projectManagement:attributes.sap_contract')}
                                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                                        validationRules={{required: false}}
                                    />
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </form>
        {editLocation &&
        <LocationDialogEditForm
            project={props.project}
            setSelectedLocations={setSelectedLocations}
            selectedLocations={selectedLocations}
            showSaveAndNewButton={false}
            setEditLocation={setEditLocation}
            editLocation={editLocation}
            isProjectCenter={true}
            setReloadLocations={(reload: boolean) => {
                if (reload) _getLocation();
            }}
        />}
    </>);
};

export default ProjectAdvancedSettingsForm;
