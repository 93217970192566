/*
 * ReportsOverview.tsx
 * Author: lnappenfeld
 * Date: 14.07.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {useTranslation} from 'react-i18next';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {
    getReports, Report, deleteReports, getReportTypes, ReportTemplate, getReportTemplates
} from '../functions/Reporting';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {OnlyOfficeDialog} from '../global/OnlyOfficeDialog';
import {
    faFileLines,
} from '@fortawesome/free-regular-svg-icons';
import {ReportFilesOverview} from './ReportFilesOverview';
import settings from '../../config/settings';
import {Tooltip} from 'primereact/tooltip';
import CustomDialog, {CustomDialogHeaderType} from '../global/CustomDialog';
import ReportForm from './ReportForm';
import {ReportActionsMapOverview} from './ReportActionsMapOverview';

type Props = {
    projectId: string
    mayEditReporting: boolean,
    reportTemplateId?: string // for subtable
    reload?: boolean,
    setReload?: (reload: boolean) => void,
    selectedReports?: Report[],
    setSelectedReports?: (reports: Report[]) => void,
    showReportDialog?: boolean,
    setShowReportDialog?: (data: boolean) => void,
    showDeleteReportsDialog?: boolean,
    setShowDeleteReportDialog?: (e: boolean) => void,
}
export const ReportsOverview = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);
    const [reports, setReports] = useState<Report[]>([]);

    const [selectedReportFile, setSelectedReportFile] = useState<any>(null);
    const [showTemplateInOnlyOffice, setShowTemplateInOnlyOffice] = useState<boolean>(false);
    const [showFileInOnlyOffice, setShowFileInOnlyOffice] = useState<boolean>(false);
    const [showTemplateId, setShowTemplateId] = useState<string>('');

    // Lokale States für die Untertabelle einer expandedRow, muss so gelöst werden, weil die props an das
    // 'expandedElement' nur einmal initial übergeben werden können, aber keine Aktualisierten props
    const [selectedReports, setSelectedReports] = useState<Report[]>([]);
    const [showReportDialog, setShowReportDialog] = useState<boolean>(false);

    // arrays for filter dropdowns
    const [reportTypes, setReportTypes] = useState<any[]>([]);
    const [reportTemplates, setReportTemplates] = useState<ReportTemplate[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const _setReportTypes = () => {
        getReportTypes().then(result => {
            setReportTypes(result.filter((ele: any) => ele.id !== 1));
        });
    };

    const _setReportTemplates = () => {
        getReportTemplates(props.projectId).then(result => {
            setReportTemplates(result);
        });
    };

    const _setReports = () => {
        getReports(props.projectId, props.reportTemplateId ? props.reportTemplateId : null).then(result => {
            for (let i = 0; i < result.length; i++) {
                if (result[i].last_report) {
                    result[i]['expandableElement'] = (<>
                        {result[i].report_type_id !== 2 &&
                            <div className={'card'}>
                                <ReportActionsMapOverview
                                    projectId={props.projectId}
                                    reportId={result[i].id}
                                />
                            </div>}
                        <div className={'card'}>
                            <ReportFilesOverview
                                projectId={props.projectId}
                                reportId={result[i].id}
                                editReportFile={(file: any)=>{
                                    setSelectedReportFile(file);
                                    setShowFileInOnlyOffice(true);
                                }}
                            />
                        </div>
                    </>);
                }
            }
            setReports(result);
            setDataLoaded(true);
        });
    };

    useEffect(() => {
        // Setze die lokalen States für den Dialog zum Anlegen eines NEUEN Reports
        // Könnte auch direkt an den Dialog übergeben werden, so muss aber nur auf die lokalen States geprüft werden
        if (props.showReportDialog) setShowReportDialog(props.showReportDialog);
        if (props.selectedReports) setSelectedReports(props.selectedReports);
    }, [props.showReportDialog, props.selectedReports]);

    useEffect(() => {
        // Lade neu, wenn Elemente gelöscht wurden
        if (props.reload) {
            _setReports();
            if (props.setReload) props.setReload(false);
        }
    }, [props.reload]);

    useEffect(() => {
        _setReportTypes();
        _setReportTemplates();
        _setReports();
        if (props.setSelectedReports) {
            props.setSelectedReports([]);
        } else {
            setSelectedReports([]);
        }
    }, []);

    useEffect(() => {
        if (props.showDeleteReportsDialog && props.selectedReports) {
            delReports(props.selectedReports);
        }
    }, [props.showDeleteReportsDialog]);

    const reportColumns: ColumnObject[] = [
        {
            field: 'is_enabled', header: t('status'), style: {maxWidth: 'fit-content', minWidth: '80px'}
        }, {
            field: 'save_in_nextcloud', header: 'Nextcloud', style: {minWidth: '105px', maxWidth: '105px'},
            filter: false, display: false
        }, {
            field: 'send_as_pdf', header: 'PDF', style: {maxWidth: '70px', minWidth: '70px'},
            filter: false, display: false
        }, {
            field: 'id', header: t('reportingManagement:attributes.reportId'),
            filter: false, display: false
        },
        {field: 'name', header: t('name'), filter: true, filterField: 'name'},
        {field: 'report_type_name', header: t('type'), filter: true, filterField: 'report_type_id',
            filterDropdownValues: reportTypes
        },
        {field: 'report_template_name', header: t('template'), filter: true, filterField: 'report_template_id',
            filterDropdownValues: reportTemplates,
            body: props.mayEditReporting ? (report: Report) => {
                return (<>
                    <Tooltip target=".cell-function" />
                    <u className='cell-function' onClick={(e) => {
                        e.stopPropagation();
                        setShowTemplateId(report.report_template_id);
                        setShowTemplateInOnlyOffice(true);
                    }} data-pr-tooltip={t('reportingManagement:tooltips.openTemplateInOnlyOffice')}
                    data-pr-position={'top'}>
                        <FontAwesomeIcon icon={faFileLines} className='ml-2'/> {report.report_template_name}
                    </u>
                </>);
            } : null
        },
        {field: 'interval', header: t('reportingManagement:attributes.interval')},
        {field: 'evaluation_period_text', header: t('reportingManagement:attributes:evaluationPeriod')},
        {
            field: 'email_subject', header: t('reportingManagement:attributes.emailSubject'),
            filter: false, display: false
        },
        {field: 'email_message', header: t('reportingManagement:attributes.emailMessage'),
            body: (rowData: any) => {
                return rowData['email_message'] && rowData['email_message'] !== ''
                    ? (rowData['email_message']).replace(/(\r\n|\n|\r)/gm, '').substring(0, 40) + '...'
                    : '';
            },
            filter: false, display: false
        },
        {field: 'timezone', header: t('timezone'), filter: false, display: false},
        {field: 'last_report', header: t('reportingManagement:attributes.lastReportTimestamp'), type: 4},
        {field: 'changed_by_username', header: t('changedByUsername')},
        {field: 'changed_at_utc', header: t('changedAtLocale'), type: 4},
    ];

    const filters = {
        'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'is_enabled': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
        'report_type_id': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.IN}]},
        'report_template_id': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.IN}]},
    };


    const delReports = (data: Report[]) => {
        const reportIds: string[] = [];
        for (const report of data) {
            reportIds.push(report.id);
        }
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('reportingManagement:dialogs.deleteReportsDialog.message'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                deleteReports(props.projectId, reportIds).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('reportingManagement:toasts.reportsDeleted'));
                        _setReports();
                        if (props.setSelectedReports) props.setSelectedReports([]);
                    }
                    if (props.setShowDeleteReportDialog) props.setShowDeleteReportDialog(false);
                });
            },
            onCancel: () => {
                if (props.setShowDeleteReportDialog) props.setShowDeleteReportDialog(false);
            }
        });
    };

    const  dynamicSelectionMode = props.reportTemplateId || !props.mayEditReporting
        ? {}
        : {selectionMode: 'multiple'};

    return (
        <>
            <div className='h-full'>
                <CustomDataTable
                    {...dynamicSelectionMode}
                    id='reports-reports'
                    columns={reportColumns}
                    expandable={!props.reportTemplateId}
                    editable={true}
                    sortable={true}
                    displayColumnFilter={true}
                    value={reports}
                    selection={selectedReports}
                    onSelectionChange={(e: any) => {
                        if (props.setSelectedReports) props.setSelectedReports(e.value);
                    }}
                    onRemoveClick={(data: any) => {
                        // eslint-disable-next-line new-cap
                        CustomConfirmDialog({
                            message: t('reportingManagement:dialogs.deleteReportDialog.message'),
                            header: t('confirmation'),
                            translation: t,
                            onConfirm: () => {
                                deleteReports(props.projectId, [data.id]).then(result => {
                                    if (result.error) {
                                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                                    } else {
                                        showMessageOnSuccess(t('success'), t('reportingManagement:toasts.reportDeleted'));
                                        _setReports();
                                    }
                                });
                            }
                        });
                    }}
                    onEditClick={(data) => {
                        if (props.setSelectedReports) props.setSelectedReports([data]);
                        setSelectedReports([data]);
                        setShowReportDialog(true);
                    }}
                    filters={filters}
                    ableToDelete={props.mayEditReporting}
                    ableToUpdate={props.mayEditReporting}
                    dataloaded={dataLoaded}
                    showExportCSV={true}
                    showClearFiltersButton={true}
                />
            </div>
            <CustomDialog
                onHide={() => {
                    if (props.setShowReportDialog) props.setShowReportDialog(false);
                    setShowReportDialog(false);
                }}
                visible={showReportDialog}
                header={selectedReports.length > 0
                    ? t('reportingManagement:dialogs.editReportDialog.header')
                    : t('reportingManagement:dialogs.createReportDialog.header')}
                headerType={selectedReports.length > 0 ? CustomDialogHeaderType.Update : CustomDialogHeaderType.Create}
                onCancel={() => {
                    if (props.setShowReportDialog) props.setShowReportDialog(false);
                    setShowReportDialog(false);
                }}
                formName={'formDialog'}
                className={'h-full w-8'}
            >
                <ReportForm
                    projectId={props.projectId}
                    report={selectedReports.length > 0 ? selectedReports[0] : null}
                    onFinished={()=> {
                        if (props.setShowReportDialog) props.setShowReportDialog(false);
                        if (props.setSelectedReports) props.setSelectedReports([]);
                        setShowReportDialog(false);
                        setSelectedReports([]);
                        _setReports();
                    }}
                />
            </CustomDialog>
            {showTemplateInOnlyOffice &&
                <OnlyOfficeDialog
                    fileType={'template'}
                    urlToOpenFile={settings.ooApiUrl + '/getReportTemplateFileAsBuffer?report_id='}
                    urlToSaveFile={settings.ooApiUrl + '/saveReportTemplateFileFromBuffer?report_id='}
                    setShowOnlyOfficeDialog={setShowTemplateInOnlyOffice}
                    plugins={true}
                    fileId={showTemplateId}
                    projectId={props.projectId}
                />}
            {showFileInOnlyOffice &&
                <OnlyOfficeDialog
                    fileType={'file'}
                    urlToOpenFile={settings.ooApiUrl + '/getReportFileAsBuffer?report_id='}
                    urlToSaveFile={settings.ooApiUrl + '/saveReportFileFromBuffer?report_id='}
                    plugins={false}
                    setShowOnlyOfficeDialog={setShowFileInOnlyOffice}
                    fileId={selectedReportFile ? selectedReportFile.id : ''}
                    projectId={props.projectId}
                />}
        </>
    );
};
