/*
 * EPSGCodeSelectList.tsx
 * Author: lnappenfeld
 * Date: 04.10.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState, useEffect} from 'react';
import Input from '../global/Input';
import {getEPSGCodes} from '../functions/Global';

type Props = {
    projectId: string,
    label: string,
    name: string,
    value?: string;
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    disabled?: boolean,
    showClear?: boolean
}

const EPSGCodeSelectList = (props: Props):JSX.Element => {

    // Initialisiere mit undefined, damit das Skeletonloading funktioniert
    const [list, setList] = useState();

    useEffect(() => {
        getEPSGCodes(props.projectId).then(result => {
            const tmp: any = [];
            result.forEach((key: any) => {
                tmp.push({
                    value: key.code,
                    // label: key.code + ': ' + key.name,
                    label: key.name + ' (Code: ' + key.code + ')',
                });
            });
            setList(tmp);

        });
    }, []);

    return (
        <Input
            name={props.name ? props.name : 'epsg_code'}
            edit={true}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            label={props.label}
            value={props.value}
            showClear={typeof props.disabled === 'boolean' ? props.disabled : false}
            dropdownOptions={list}
            scrollHeight={'350px'}
            type={'dropdown'}
            onChange={(e: any) => {
                if (props.setValue) props.setValue(e.value);
                if (props.onChange) props.onChange(e);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};

export default EPSGCodeSelectList;