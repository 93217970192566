import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';

// Define a type for the slice state
interface ConfigLayers {
    layerData: any
}

// Define the initial state using that type
const initialState: ConfigLayers = {
    layerData: {
        alarmArray:[],
        baseLayer: '',
        baseLayerWorkspace: '',
        changedLayer:'',
        editAttributesArray:[],
        externLegendArray:[],
        externLegendPic:[],
        filterMp:null,
        formatLayerAttributesArray:[],
        geoserverWorkspace: '',
        layerArray: [],
        layerAttributesArray:[],
        layerCounterArray:[],
        layerDoneCounter: [],
        layerfeatureInfosArray:[],
        layerNameArray:[],
        layerNodeArray:[],
        mpTypes:[],
        mpsSet:false,
        permaLinkLayer:[],
        isPermaLink:false,
        styleArray: [],
        styleArraySet: false,
        showPopup:true,
    }
};

export const configLayersSlice = createSlice({
    name: 'layerArray',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        addToCounter: (state, action: PayloadAction<any>) => {
            state.layerData.layerDoneCounter.push(action.payload);
        },
        addLayerToCounterArray:(state, action:PayloadAction<any>)=>{
            state.layerData.layerCounterArray.push(action.payload);
        },
        setFilterMp: (state, action: PayloadAction<any>) => {
            state.layerData.filterMp=action.payload;
        },
        setAlarmArray:(state, action: PayloadAction<any>) => {
            state.layerData.alarmArray=action.payload;
        },
        isPermaLinkView: (state, action: PayloadAction<any>) => {
            state.layerData.isPermaLink=action.payload;
        },
        reportChangedLayer: (state, action: PayloadAction<any>) => {
            state.layerData.changedLayer=action.payload;
        },
        resetData:(state) => {
            state.layerData.layerDoneCounter = 0;
            state.layerData.layerCounterArray = [];
            state.layerData.baseLayer = '';
            state.layerData.geoserverWorkspace = '';
            state.layerData.formatLayerAttributesArray = [];
            state.layerData.isPermaLink = false;
            state.layerData.styleArray=[];
            state.layerData.layerArray=[];
            state.layerData.permaLinkLayer=[];
            state.layerData.layerNameArray=[];
            state.layerData.layerAttributesArray=[];
            state.layerData.editAttributesArray=[];
            state.layerData.layerfeatureInfosArray=[];
            state.layerData.externLegendArray=[];
            state.layerData.externLegendPic=[];
        },
        setAttributesFormat: (state, action: PayloadAction<any>) => {
            state.layerData.formatLayerAttributesArray = action.payload;
        },
        setBaseLayer: (state, action: PayloadAction<any>) => {
            state.layerData.baseLayer = (action.payload !== null && typeof action.payload !== 'undefined')
                ? action.payload.toLowerCase()
                : null;
        },
        setBaseWorkspace: (state, action: PayloadAction<any>) => {
            state.layerData.baseLayerWorkspace = action.payload;
        },
        setConfigData:(state, action: PayloadAction<any>) => {
            const payload=action.payload;
            state.layerData.layerCounterArray.push(payload.layerCounter);
            state.layerData.styleArray = payload.styleArray;
            state.layerData.styleArraySet = true;
            state.layerData.layerNameArray=payload.layerNames;
            state.layerData.layerAttributesArray=payload.layerAttributes;
            state.layerData.formatLayerAttributesArray = payload.attributesFormat;
            state.layerData.editAttributesArray=payload.editAttributes;
            state.layerData.layerfeatureInfosArray=payload.layerFeatures;
            state.layerData.externLegendArray=payload.externLegend;
            state.layerData.externLegendPic=payload.externLegendPic;
            state.layerData.layerNodeArray=payload.treeName;
        },
        setEditAttributes:(state, action:PayloadAction<any>)=>{
            state.layerData.editAttributesArray=action.payload;
        },
        setExternLegend:(state, action:PayloadAction<any>)=>{
            state.layerData.externLegendArray=action.payload;
        },
        setExternLegendPic:(state, action:PayloadAction<any>)=>{
            state.layerData.externLegendPic.push(action.payload);
        },
        setLayerArray: (state, action: PayloadAction<any>) => {
            state.layerData.layerArray.push(action.payload);
        },
        setLayerAttributes:(state, action:PayloadAction<any>)=>{
            state.layerData.layerAttributesArray=action.payload;
        },
        setLayerFeatures:(state, action:PayloadAction<any>)=>{
            state.layerData.layerfeatureInfosArray=action.payload;
        },
        setLayerNames:(state, action:PayloadAction<any>)=>{
            state.layerData.layerNameArray=action.payload;
        },
        setLayerNodes:(state, action:PayloadAction<any>)=>{
            state.layerData.layerNodeArray=action.payload;
        },
        setMPTypes:(state, action:PayloadAction<any>)=>{
            state.layerData.mpTypes=action.payload;
            state.layerData.mpsSet=true;
        },
        setPermaLinkLayer: (state, action: PayloadAction<any>) => {
            state.layerData.permaLinkLayer=action.payload;
        },
        setStyleArray: (state, action: PayloadAction<any>) => {
           // console.log('changed',action.payload)
            state.layerData.styleArray = action.payload;
            state.layerData.styleArraySet = true;
        },
        setWorkspace: (state, action: PayloadAction<any>) => {
            state.layerData.geoserverWorkspace = action.payload;
        },
        setShowPopUp: (state, action: PayloadAction<any>) => {
            state.layerData.showPopup = action.payload;
        },
    }
    ,
});

export const {
    addToCounter,
    addLayerToCounterArray,
    setAlarmArray,
    isPermaLinkView,
    setFilterMp,
    reportChangedLayer,
    resetData,
    setAttributesFormat,
    setBaseLayer,
    setBaseWorkspace,
    setConfigData,
    setEditAttributes,
    setExternLegend,
    setExternLegendPic,
    setLayerArray,
    setLayerAttributes,
    setLayerFeatures,
    setLayerNames,
    setLayerNodes:setLayerNodes,
    setPermaLinkLayer,
    setMPTypes,
    setStyleArray,
    setWorkspace,
    setShowPopUp
} = configLayersSlice.actions;

export default configLayersSlice.reducer;