import React, {FunctionComponent, useCallback, useContext, useEffect, useRef, useState} from 'react';
import MapContext from '../map/MapContext';
import {Control} from 'ol/control';
import {useAppSelector, useAppDispatch} from '../../../redux/hooks';
import {setBaseLayer} from '../../../redux/GeomonitoringSlice';

type controlProps = {
    baseLayer: string
}
const ToggleMapControl: FunctionComponent<controlProps> = ({baseLayer}) => {
    // @ts-ignore
    const {map} = useContext(MapContext);
    const [futureBaseLayer, setFutureBaseLayer] = useState('');
    const refFutureBaseLayer = useRef(futureBaseLayer);
    const baseLayerdispatch = useAppSelector((state: any) => state.configLayer.layerData.baseLayer);
    const dispatch = useAppDispatch();
    refFutureBaseLayer.current = futureBaseLayer;

    const createBaseMapButton = () => {
        const buttonBaseMap = document.createElement('button');
        buttonBaseMap.id = 'buttonBaseMap';
        buttonBaseMap.title = 'Sat/Strasse';
        const newBaseLayer = getButtonTitleAndFutureLayer();
        buttonBaseMap.innerHTML = '<span style="color: black">' + newBaseLayer.bttn + '</span>';
        buttonBaseMap.addEventListener('click', toggleMapBttn, false);
        return buttonBaseMap;
    };


    const toggleMapBttn = useCallback((me) => {
        dispatch(setBaseLayer(refFutureBaseLayer.current));
        const getNewButtonTitle = getButtonTitleAndFutureLayer();
        const title = getNewButtonTitle.bttn;
        me.innerHTML = title;
    }, [map]);

    const getButtonTitleAndFutureLayer = () => {
        let bLayer;
        let titel;
        switch (baseLayerdispatch) {
            case 'gm_layer_sat':
                titel = '<i class="fa fa-map"/>';
                bLayer = 'gm_layer_maps';
                break;
            case 'gm_layer_maps':
                titel = '<i class="fa fa-globe"/>';
                bLayer = 'gm_layer_sat';
                break;
            default:
                titel = '<i class="fa fa-globe"/>';
                bLayer = 'gm_layer_sat';
                break;
        }
        setFutureBaseLayer(bLayer);
        refFutureBaseLayer.current = bLayer;
        return {bttn: titel};
    };


    const getControls = () => {
        const buttonBaseMap = createBaseMapButton();

        const elementLegende = document.createElement('div');
        elementLegende.className = 'ctrl-map ol-unselectable ol-control';
        elementLegende.id = 'olToolkitControls';
        elementLegende.appendChild(buttonBaseMap);
        const locationLegende = new Control({
            element: elementLegende
        });

        return [locationLegende];
    };

    const addControl = () => {
        const bookmarkElem = document.getElementById('buttonBookmark');
        if (bookmarkElem === null) {
            const buttonBookmarkMap = createBaseMapButton();
            const elementLegende = document.getElementById('olToolkitControls');
            elementLegende?.appendChild(buttonBookmarkMap);
        }
        return [bookmarkElem];
    };

    useEffect(() => {
        //console.log('getNewBasealyer', baseLayerdispatch);
        const interval = setInterval(() => {
            if (baseLayerdispatch) {
                clearInterval(interval);
                let bttnValue = getButtonTitleAndFutureLayer();
                const bttn = document.getElementById('buttonBaseMap');
                const title = bttnValue.bttn;
                if (bttn) {
                    // @ts-ignore
                    bttn.innerHTML = '<span style="color: black">' + title + '</span>';
                }
            }
        }, 1000);
        return () => clearInterval(interval);

    }, [baseLayerdispatch]);

    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        // const toggleControl = getControls();
        // map.addControl(toggleControl[0]);

        const toggleControl = addControl();
        return () => map.controls.remove(toggleControl);
    }, [map]);

    return null;
};

export default ToggleMapControl;
