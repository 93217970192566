import keycloakfetch from '../../../functions/keycloakfetch';
import settings from '../../../config/settings';

export async function getAlerts (projectId: string, data: any, offset: any, limit: any) {
    let retValue: any = [];
    let totalRecords = 0;
    const finalArr: any = [];
    const params = new URLSearchParams({
        'project_id': projectId,
        'offset': offset,
        'limit': limit
    });
    await keycloakfetch.post(settings.apiUrl + '/getCMStatusList?' + params, data).then(result=>{
        if (result) {
            retValue = result;
            totalRecords = result['TotalCount'];
        }

    });

    retValue['Data'].map((result: any)=>{
        const json = {
            'id': result['status_origin_id'],
            'timestamp': result['status_start'],
            'alarm_end': result['status_end'],
            'plant_ids': result['machine_id'],
            'plant_name': result['machine_name'],
            'component_name': result['element_name'],
            'component_ids': result['element_id'],
            'channel_ids': result['channel_id'],
            'channel_name': result['channel_name'],
            'acknowledged_timestamp': result['acknowledged_timestamp'],
            'acknowledgedbyuser': result['acknowledged_by_user'],
            'acknowledged_by_user_ids': result['acknowledged_by_user_id']!== null ? result['acknowledged_by_user_id'] : 'Unacknowldged',
            'monitoring_system': result['status_origin_type_name'],
            'status_origin_type_ids': result['status_origin_type_id'],
            'description': result['status_description'],
            'status_id': result['status_id'],
            'status_origin': result['status_origin']
        };

        finalArr.push(json);
    });
    const finalJson = {
        finalArr,
        totalRecords
    };
    return finalJson;
}

export async function getAcknowledgeFilter(projectId: string) {
    let retValue: any;
    const params = new URLSearchParams({
        'project_id': projectId
    });
    await keycloakfetch.get(settings.apiUrl + '/getCMStatusFilterParamsAcknowledge?' + params).then(result=>{
        if (result) {
            retValue = result;
        }
    });
    return retValue;
}

export async function getChannelFilter(projectId: string) {
    let retValue: any;
    const params = new URLSearchParams({
        'project_id': projectId
    });
    await keycloakfetch.get(settings.apiUrl + '/getCMStatusFilterParamsChannel?' + params).then(result=>{
        if (result) {
            retValue = result;
        }
    });
    return retValue;
}

export async function getPlantFilter(projectId: string) {
    let retValue: any;
    const params = new URLSearchParams({
        'project_id': projectId
    });
    await keycloakfetch.get(settings.apiUrl + '/getCMStatusFilterParamsPlants?' + params).then(result=>{
        if (result) {
            retValue = result;
        }
    });
    return retValue;
}

export async function getSubPlantFilter(projectId: string, plantId: any) {
    let retValue: any;
    const params = new URLSearchParams({
        'project_id': projectId,
        'element_ids': plantId
    });
    await keycloakfetch.get(settings.apiUrl + '/getCMStatusFilterParamsComponents?' + params).then(result=>{
        if (result) {
            retValue = result;
        }
    });
    return retValue;
}

export async function getAlarmOriginFilter(projectId: string) {
    let retValue: any;
    const params = new URLSearchParams({
        'project_id': projectId
    });
    await keycloakfetch.get(settings.apiUrl + '/getCMStatusFilterParamsStatusOrigin?' + params).then(result=>{
        if (result) {
            retValue = result;
        }
    });
    return retValue;
}
export async function acknowledgeAlerts(data: any) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/acknowledgeCMCharacteristicStatus', data).then(result=>{
        if (result) {
            retValue = result;
        }
    });

    return retValue;
}

