/*
 * ViewButtons.tsx
 * Author: fwunderlich
 * Date: 28.02.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */
import React, {useEffect, useRef, useState} from 'react';
import {Button} from 'primereact/button';
import {Menu} from 'primereact/menu';
import {ViewButton} from './ViewButton';
import {ViewButtonsContainer} from './ViewButtonsContainer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

type Props = {
    title: string,
    items: Item[],
    rerender?: boolean,
}

type Item = {
    label: string,
    onClick: (e: any) => void,
    visible: boolean,
    className?: string,
    icon?: string,
    disabled?: boolean,
    submit?: string,
}

const ViewButtons = (props: Props): JSX.Element => {
    const ref: any = useRef(null);
    const menu: any = useRef(null);

    const [addEvent, setAddEvent] = useState<boolean>(true);
    // Anzahl der Buttons die nebeneinander gerendert werden sollen
    const [displayButtons, setDisplayButtons] = useState(-1);
    const [width, setWidth] = useState(0);
    const [collapsedItems, setCollapsedItems] = useState<any[]>([]);

    const resizeEvent = () => {
        if (ref && ref.current) {
            // console.log('resize')
            setWidth(ref.current.offsetWidth);
            setDisplayButtons(-1);
        }
    };

    useEffect(() => {
        setWidth(0);
        // console.log('length', ref, ref.current.getElementsByClassName('testing').length);
        if (ref && ref.current && ref.current.getElementsByClassName('testing').length > 0) {
            setAddEvent(false);
            // console.log('ref changed', ref.current)
            window.addEventListener('resize', resizeEvent);
            resizeEvent();

            return () => {
                window.removeEventListener('resize', resizeEvent);
                setDisplayButtons(-1);
            };
        }
    }, [(ref.current && ref.current.getElementsByClassName('testing'))]);

    useEffect(() => {
        if (!addEvent) {
            // console.log('update');
            updateButtons();
        }
    }, [width, props.rerender, props.items]);

    const updateButtons = () => {
        if (ref && ref.current) {
            const buttons = ref.current.getElementsByClassName('testing');

            let buttonsWidth = 0;
            let displayButtons = 0;
            const collapsedItems = [];

            const items = [];
            for (let i = 0; i < props.items.length; i++) {
                const item = props.items[i];
                let icon: any = item.icon;
                if (item.icon && typeof item.icon !== 'string')
                    icon = <FontAwesomeIcon key={'icon'+i} className='sidebar-icon mr-2' icon={item.icon}/>;
                items.push({label: item.label, icon: icon, command: item.onClick, disabled: item.disabled, visible: item.visible});
            }

            for (let i = 0; i < buttons.length; i++) {
                const button = buttons[i];
                button.style.display = 'block';

                buttonsWidth += button.offsetWidth + 50;
                if (buttonsWidth < width) {
                    displayButtons++;
                } else {
                    collapsedItems.push(items[i]);
                }

                button.style.display = 'none';
            }
            setDisplayButtons(displayButtons);
            setCollapsedItems(collapsedItems);
        }
    };

    // Lokale Zählervariable, die nur hochgezählt wird, wenn der jeweilige Button visible ist
    let _index = 0;
    return (
        <ViewButtonsContainer innerRef={ref} flex={true}>
            {props.items.map((item: Item, index) => {
                return (
                    <React.Fragment key={'butt_' + index}>
                        {item.visible &&
                        <ViewButton
                            key={'butt_' + index} className={item.className + ' testing'} style={{'display': 'none'}}
                            label={item.label} submit={item.submit}
                            onClick={item.onClick} icon={item.icon} disabled={item.disabled}
                        />}
                    </React.Fragment>
                );
            })}

            {props.items.map((item: Item, index) => {
                if (displayButtons === -1 || _index < displayButtons) {
                    if (item.visible) _index++;
                    return (
                        <React.Fragment key={'butt_' + index}>
                            {item.visible &&
                            <ViewButton
                                key={'butt_' + index} className={item.className} label={item.label} submit={item.submit}
                                onClick={item.onClick} icon={item.icon} disabled={item.disabled}
                            />}
                        </React.Fragment>
                    );
                }
            })}
            {collapsedItems.length > 0 &&
            <div className='col-fixed pr-0'>
                <Menu popup ref={menu} model={[{label: '', items: collapsedItems}]} style={{'minWidth': '200px'}}/>
                <Button className='p-button-outlined' style={{height: '35px'}} onClick={(event) => {
                    if (menu.current) {
                        menu.current.toggle(event);
                    }
                }}>{props.title}<i className='pi pi-angle-down'/></Button>
            </div>}
        </ViewButtonsContainer>
    );
};
export default ViewButtons;
