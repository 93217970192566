/*
 * LanguageSelectList.tsx
 * Author: amusiol
 * Date: 22.02.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';
import Input from './Input';

type Props = {
    label: string,
    value?: string,
    setValue?: (e: any) => void,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
}
const LanguageSelectList = (props: Props): JSX.Element => {

    // Initialisiere mit undefined, damit das Skeletonloading funktioniert
    const [list, setList] = useState();

    useEffect(() => {
        keycloakfetch.get(settings.apiUrl + '/getAvailableLanguages').then(result => {
            const tmp: any = [];
            result.forEach((key: any) => {
                tmp.push({
                    value: key.language,
                    label: key.name,
                });
            });
            setList(tmp);
        });
    }, []);

    return (
        <Input
            name={'language_id'}
            edit={true}
            label={props.label}
            value={props.value}
            dropdownOptions={list} type={'dropdown'}
            onChange={(e: any) => {
                if (props.setValue) props.setValue(e.value);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};
export default LanguageSelectList;
