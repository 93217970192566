import keycloakfetch from '../../../functions/keycloakfetch';
import {Nullable} from '../../functions/Global';
import settings from '../../../config/settings';
import moment from 'moment/moment';

export type Points = {
    'box_detail': string,
    tagName: string,
    displayMin: Nullable<string>,
    displayMax: Nullable<string>,
    alertMin: Nullable<string>,
    alertMax: Nullable<string>
}
export async function getDoppelfilterDetails (projectId: string, monitoringAppId: string, monitoringAppTypeId: string, schematicIllustrationTypeId: string, elementId: string) {
    const params = new URLSearchParams({
        'project_id': projectId,
        'monitoring_app_id': monitoringAppId,
        'monitoring_app_type_id': monitoringAppTypeId,
        'schematic_illustration_type_id': schematicIllustrationTypeId,
        'element_id': elementId
    });
    const resultArr: any = [];

    await keycloakfetch.get(settings.apiUrl + '/getSchematicIllustrationConfiguration?' + params).then(resp =>{
        for (let index=1, value=0; index<=resp.length; index++, value++) {
            const json = {
                'number': index,
                'box_detail': resp[value]['decription'],
                'tag_name': resp[value]['channel_name'] ? resp[value]['channel_name'] : '---',
                'channel_id': resp[value]['channel_id'],
                'chart_min': resp[value]['chart_min'] === null ? '' : resp[value]['chart_min'],
                'chart_max': resp[value]['chart_max'] === null ? '' : resp[value]['chart_max'],
                'main_alarm_to': resp[value]['main_alarm_from'] === null ? '' : resp[value]['main_alarm_from'],
                'main_alarm_from': resp[value]['main_alarm_to'] === null ? '' : resp[value]['main_alarm_to'],
                'characteristic_monitoring_task_id': resp[value]['characteristic_monitoring_task_id'],
                'tag': resp[value]['tag'],
                // 'schematic_illustration_box_id': resp[value]['schematic_illustration_box_id'],
                'monitoring_app_task_id': resp[value]['monitoring_app_task_id'],
                'schematic_illustration_box_type_id': resp[value]['schematic_illustration_box_type_id']
            };

            resultArr.push(json);
        }
    });
    
    return resultArr;

}

export async function getPointChannels (projectId: string) {
    let retValue: any = [];

    const params = new URLSearchParams({
        'project_id': projectId,
    });
    await keycloakfetch.get(settings.apiUrl + '/getChannels?' + params).then(result =>{
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getMappedSVGDiagram(projectId: string, elementId: string, monitoringAppTypeId: string) {
    let retValue: any = [];
    const params = new URLSearchParams({
        'project_id': projectId,
        'element_id': elementId,
        'monitoring_app_type_id': monitoringAppTypeId
    });
    await keycloakfetch.get(settings.apiUrl + '/getMappedSchematicIllustrationType?' + params).then(result=>{
        if (result)
            retValue = result;
    });
    return retValue;

}
export async function getAllSVGDiagrams(projectId: string, monitoringAppTypeId: string) {
    let retValue: any = [];
    const params = new URLSearchParams({
        'project_id': projectId,
        'monitoring_app_type_id': monitoringAppTypeId
    });
    await keycloakfetch.get(settings.apiUrl + '/getSchematicIllustrationTypesAvailable?' + params).then(result=>{
        if (result)
            retValue = result;
    });
    return retValue;

}
export async function getSVGDiagram (projectId: string, schematicIllustrationTypeId: string ) {
    let retValue: any = [];
    const params = new URLSearchParams({
        'project_id': projectId,
        'schematic_illustration_type_id': schematicIllustrationTypeId
    });
    await keycloakfetch.get(settings.apiUrl + '/getSchematicIllustrationType?' + params).then(result=>{
        if (result)
            retValue = result;
    });
    return retValue;

}

export async function updateSVGConfiguration (data: any) {
    let retValue:any = [];
    try {
        await keycloakfetch.put(settings.apiUrl + '/adjustSchematicIllustrationConfiguration', data).then(result=>{
            if (result)
                retValue = result;
        });
    }
    catch (e: any) {
        retValue = e;
    }

    return retValue;
}

export async function getOperationalData(projectId: string, channelId: string) {
    let retValue:any = [];
    const params = new URLSearchParams({
        'project_id': projectId,
        'channel_ids': channelId
    });
    await keycloakfetch.get(settings.apiUrl + '/getCMOperatingDataLast?' + params).then(result=>{
        if (result)
            retValue = result;
    });

    return retValue;
}

export async function getBoxValueData(data: any) {
    let retValue:any = [];
    await keycloakfetch.post(settings.apiUrl + '/getCMOperatingDataLast', data).then(result=>{
        if (result)
            retValue = result;
    });

    return retValue;
}

export async function getStatusColor(data: any) {
    let retValue:any = [];

    await keycloakfetch.post(settings.apiUrl + '/getCMCharacteristicStatusCurrent?', data).then(result=>{
        if (result)
            retValue = result;
    });

    return retValue;
}
export async function getMultipleOperationalData(projectId: string, channelIds: any, timestampFrom: any, timestampTo: any) {
    let retValue:any = [];
    const periodFrom:any = moment(timestampFrom).tz('Europe/Berlin').format().split('+');
    const periodTo: any = moment(timestampTo).tz('Europe/Berlin').format().split('+');

    const params = new URLSearchParams({
        'project_id': projectId,
        'channel_ids': channelIds,
        'timestamp_from': periodFrom[0] + 'Z',
        'timestamp_to': periodTo[0] + 'Z'
    });

    await keycloakfetch.get(settings.apiUrl + '/getCMOperatingDataSets?' + params).then(result=>{
        if (result)
            retValue = result;
    });

    return retValue;
}

export async function getMeasurePointValues(projectId: string, channelIds: string){
    let retValue:any = [];
    const params = new URLSearchParams({
        'project_id': projectId,
        'channel_ids': channelIds,
    });

    await keycloakfetch.get(settings.apiUrl + '/getChannelsById?' + params).then(result=>{
        if (result)
            retValue = result;
    });

    return retValue;
}