import React, { useState, useEffect } from 'react';
import CustomDialog from '../../global/CustomDialog';
import {useForm} from 'react-hook-form';
import Input from '../../global/Input';
import {useTranslation} from 'react-i18next';
import {createMonitoringApps, getAllMonitoringApps, updateMonitoringApps} from '../functions/DetailsService';
import {showMessageOnError, showMessageOnSuccess} from '../../global/CustomToast';

type monitoringAppProps = {
    projectId: string;
    count: number|undefined;
    monitoringApps: any
    type: string
    modal: boolean;
    hidePopup: () => void;
    allApps: any

}
const UpdateMonitoringApps = (props: monitoringAppProps)=>{
    const { t } = useTranslation(['common']);
    const [monitoringValues, setMonitoringValues] = useState<any>([]);
    const [error, setError] = useState<boolean>(false);
    const [dropdownOptions, setDropdownOptions] = useState<any>([]);

    const defaultValues = {
        count: props.count
    };
    const {
        control,
        formState: { errors },
        handleSubmit,
        getValues,
        setValue,
    } = useForm<any>({ defaultValues });

    const getFormErrorMessage = (name: string) => {
        return (
            errors[name] && (
                <small className="p-error custom">{errors[name]!.message}</small>
            )
        );
    };

    const saveData = (data: any) => {
        if (data['count'] > 0 && data['count'] <= 10) {
            const updatedData = {
                'project_id': props.projectId,
                'element_id': localStorage.getItem('componentId'),
                'monitoring_app_type_id': monitoringValues,
                'count': data['count'] - 1,
                'schematic_illustration_type_id': props.monitoringApps && props.monitoringApps['schematic_illustration_type_id'] ? props.monitoringApps['schematic_illustration_type_id'] : null
            };

            if (props.type === 'create') {
                createMonitoringApps(updatedData).then(resp => {
                    if (resp['error'] && resp['error'].includes('App is already mapped.')) {
                        showMessageOnError(t('error'), t('conditionMonitoring:monitoring_app_exists'));
                    } else {
                        showMessageOnSuccess(t('success'), t('conditionMonitoring:monitoring_app_created'));
                        props.hidePopup();
                    }
                });
            }
            if (props.type === 'edit') {
                const findApp = props.allApps.find((item: any) => item['count'] === Number(data['count']));
                if(findApp){
                    if(findApp['monitoringAppTypeId'] === props.monitoringApps['monitoringAppTypeId']){
                        updateMonitoringApps(updatedData).then(resp => {
                            showMessageOnSuccess(t('success'), t('conditionMonitoring:monitoring_app_created'));
                            props.hidePopup();
                        });
                    }
                    else{
                        showMessageOnError(t('success'), t('conditionMonitoring:position_assigned'));
                    }
                }
                else{
                    updateMonitoringApps(updatedData).then(resp => {
                        showMessageOnSuccess(t('success'), t('conditionMonitoring:monitoring_app_created'));
                        props.hidePopup();
                    });
                }

            }
        }
        else {
            setError(true);
        }

    };
    
    useEffect(()=>{
        const dropdownVals : any = [];
        getAllMonitoringApps(props.projectId).then(response => {
            response.map((val: any) => {
                dropdownVals.push({
                    value: val['id'],
                    label: val['name']
                });
            });
            setDropdownOptions(dropdownVals);
        });
        if (props.monitoringApps) {
            setMonitoringValues(props.monitoringApps['monitoringAppTypeId']);
        }
    },[]);
    return (<>
        <CustomDialog onHide={()=>{props.hidePopup();}} onCancel={()=>{props.hidePopup();}} visible={props.modal} header={t('conditionMonitoring:element')} showFooter={true} headerType={props.monitoringApps && props.type === 'edit' ? 3 : 2 } formName={'monitoringAppForm'} >
            <form id="monitoringAppForm" onSubmit={handleSubmit(saveData)}>
                <div className="grid" style={{ width: '100%' }}>
                    {error && <p style={{color: 'red'}}>{t('conditionMonitoring:position_err')}</p> }
                    <div className='col-12 md:col-8'>
                        <Input
                            edit={true}
                            placeholder=''
                            label={t('conditionMonitoring:monitoring_apps')}
                            name={'monitoring_app'}
                            dropdownOptions={dropdownOptions}
                            value={monitoringValues}
                            type={'dropdown'}
                            onChange={
                                (e)=>{
                                    setMonitoringValues(e.value);
                                }
                            }
                            validationRules={{
                                required: t('conditionMonitoring:monitoring_apps_error'),
                            }}
                            disabled={props.type === 'edit' && true}
                        />
                    </div>
                    <div className="col-12 md:col-4">
                        <Input
                            edit={true
                                // typeof props.mayEditPlant === 'boolean'
                                //     ? props.mayEditPlant
                                //     : true
                            }
                            label={t('conditionMonitoring:position')}
                            name={'count'}
                            type={'text'}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{
                                required: t('conditionMonitoring:position_error'),
                            }}
                            disabled={props.type === 'create'}
                        />
                    </div>
                </div>
            </form>
        </CustomDialog>


    </>);

};

export default UpdateMonitoringApps;