import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';
import {getUserFromLocalStorage} from './Global';
import moment from 'moment/moment';

export async function getCMDataSets(projectId: string, channelId: string, timestampFrom: string, timestampTo = '') {
    let retValue: any = [];
    const endpointStr = '/getCMDataSets?project_id=' + projectId + '&channel_id=' + channelId +
        '&timestamp_from=' + timestampFrom + '&timestamp_to=' + timestampTo;
    await keycloakfetch.get(settings.apiUrl + endpointStr).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getCMDataset(projectId: string, datasetIds: string[], measuringDataTable: string) {
    let retValue: any = [];
    const endpointStr = '/getCMDataSet?project_id=' + projectId + '&dataset_id=' + datasetIds.join(',') +
        '&measuring_data_table=' + measuringDataTable;
    await keycloakfetch.get(settings.apiUrl + endpointStr).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getData(projectId: string, channelIds: string[], combineValues: boolean, from?: Date, to?: Date) {
    let retValue: any = [];

    let dates = '';

    if (from) {
        dates = '&from=' + (typeof from === 'object' ? from.toISOString() : from);
    }

    if (to) {
        dates += '&to=' + (typeof to === 'object' ? to.toISOString() : from);
    }

    await keycloakfetch.get(settings.apiUrl + '/getData?project_id=' + projectId + '&channel_ids=' + channelIds.join(',') + '&combine_values=' + combineValues + dates).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function exportData(projectId: string, channelIds: string[], combineChannelsType: number, from: Date, to: Date, languageId: string, actionId: string) {
    let retValue: any = [];

    const body = {
        'project_id': projectId,
        'channel_ids': channelIds,
        'action_id': actionId,
        'combine_channels_type': combineChannelsType,
        'from': from.toISOString(),
        'to': to.toISOString(),
        'language_id': languageId
    };

    await keycloakfetch.post(settings.apiUrl + '/exportData?', body).then(async result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getBlobValue(projectId: string, channelId: string, timestamp: number) {
    let retValue: any = null;

    await keycloakfetch.get(settings.apiUrl + '/getBlobValue?project_id=' + projectId + '&channel_id=' + channelId + '&timestamp=' + timestamp).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getValueTypes() {
    let retValue: any = null;
    const user = getUserFromLocalStorage();

    await keycloakfetch.get(settings.apiUrl + '/getValueTypes?language=' + user.language_id).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getHighchartsImage(projectId: string, channelId: string, timezone: string, theme: string, from?: Date, to?: Date) {
    let retValue: any;

    let paramString = '?project_id=' + projectId + '&channel_id=' + channelId + '&timezone=' + timezone + '&theme=' + theme;
    if (from && to) {
        paramString += '&from=' + from.toISOString() + '&to=' + to.toISOString();
    }

    await keycloakfetch.get(settings.apiUrl + '/getHighchartsImage' + paramString).then(async result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getLastWeatherData(projectId: string, locationId: string) {
    let retValue: any;
    const paramString = '?project_id=' + projectId + '&location_id=' + locationId;

    await keycloakfetch.get(settings.apiUrl + '/getLastWeatherData' + paramString).then(async result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getFirstValue(projectId: string, channelId: string) {
    let retValue: any;
    const paramString = '?project_id=' + projectId + '&channel_id=' + channelId;

    await keycloakfetch.get(settings.apiUrl + '/getFirstValue' + paramString).then(async result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getLastValue(projectId: string, channelId: string) {
    let retValue: any;
    const paramString = '?project_id=' + projectId + '&channel_id=' + channelId;

    await keycloakfetch.get(settings.apiUrl + '/getLastValue' + paramString).then(async result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getClosestValueInTime(projectId: string, channelId: string, timestamp: Date) {
    let retValue: any;
    const paramString = '?project_id=' + projectId + '&channel_id=' + channelId + '&timestamp=' + timestamp.toISOString();

    await keycloakfetch.get(settings.apiUrl + '/getClosestValueInTime' + paramString).then(async result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getForecastData(channelId:string[]){
    let retValue: any;
    const paramString = '?channel_id=' + channelId;

    await keycloakfetch.get(settings.pyApiUrl + '/getForecast' + paramString).then(async result => {
        if (result)
            retValue = JSON.parse(result);
    });
    return retValue;

}
