/*
 * OperatingStateConditionDialogEditForm.tsx
 * Author: lnappenfeld
 * Date: 16.06.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React from 'react';
import {useTranslation} from 'react-i18next';
import CustomDialog, {CustomDialogHeaderType} from '../global/CustomDialog';
import {OperatingStateConditionForm, OSCFormType} from './OperatingStateConditionForm';
import {OperatingState, OperatingStateCondition} from '../functions/ConditionMonitoring';


type Props = {
    type: OSCFormType,
    projectId: string,
    selectedOperatingStateConditions: any,
    setSelectedOperatingStateConditions: any,
    editOperatingStateCondition: boolean,
    setEditOperatingStateCondition: (data: boolean) => void,
    setReloadOperatingStateConditions: (data: boolean) => void,
    // Damit die OperatingStateSelectList vorausgefüllt wird
    selectedOperatingStates?: OperatingState[]
    // For Type 'NewOperatingState'
    addOperatingStateCondition?: (osc: OperatingStateCondition) => void
    updateOperatingStateCondition?: (osc: OperatingStateCondition) => void
}

export const OperatingStateConditionDialogEditForm = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    return (
        <CustomDialog
            header={props.selectedOperatingStateConditions.length > 0
                ? t('metrologyManagement:dialogs.editOperatingStateCondition.header')
                : t('metrologyManagement:dialogs.createOperatingStateCondition.header')}
            headerType={props.selectedOperatingStateConditions.length > 0
                ? CustomDialogHeaderType.Update
                : CustomDialogHeaderType.Create}
            formName={'formOperatingStateCondition'}
            onCancel={() => {
                props.setEditOperatingStateCondition(false);
            }}
            onHide={() => {
                props.setEditOperatingStateCondition(false);
            }}
            visible={props.editOperatingStateCondition}
            style={{ width: '80vw'}}
        >
            <OperatingStateConditionForm
                type={props.type}
                projectId={props.projectId}
                onFinished={() => {
                    props.setEditOperatingStateCondition(false);
                    props.setSelectedOperatingStateConditions([]);
                    props.setReloadOperatingStateConditions(true);
                }}
                operatingStateCondition={props.selectedOperatingStateConditions.length > 0
                    ? props.selectedOperatingStateConditions[0]
                    : null}
                selectedOperatingState={props.selectedOperatingStates && props.selectedOperatingStates.length > 0
                    ? props.selectedOperatingStates[0]
                    : null}
                addOperatingStateCondition={props.addOperatingStateCondition}
                updateOperatingStateCondition={props.updateOperatingStateCondition}
            />
        </CustomDialog>
    );

};

