/*
 * IntervalSelection.tsx
 * Author: lnappenfeld
 * Date: 15.07.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import Input from './Input';
import {getExecutionIntervalFrequencies, getExecutionIntervalWeekdays} from '../functions/Reporting';
import {Nullable} from '../functions/Global';

type DropdownOption = {
    value: string,
    label: string
}

export type Interval = {
    type: DropdownOption,   // [0,1,2] 0: monthly, 1: weekly, 2: daily
    day: DropdownOption,    // [0,1,2,3,4,5,6] 0: sunday, 1: monday, ..., 6: saturday -> day of week  (type: 1)
                            // [1,2,...,30,31] 1: 1., 2: 2., ..., 30: 30., 31: 31.    -> day of month (type: 0)
    time: DropdownOption    // [0,1,...,22,23] 0: 00:00 Uhr, 1: 01:00 Uhr, ..., 23: 23:00 Uhr
}

type Props = {
    interval: Nullable<string>,
    onChange: (interval: Nullable<string>, intervalText: string) => void,
    intervalDay: string[],
    setIntervalDay: (intervalDay: string[]) => void,
    intervalTime: string[],
    validationControl: any,
    validationErrorMessage: (name: string) => void,
}

/**
 * Input (props.interval) als String in folgender Form: <Type>;<Day>;<Time>.
 * z.B. 1;4;14 für 'wöchentlich am Donnerstag um 14:00 Uhr'
 * @param props
 * @constructor
 */

const IntervalSelection = (props: Props): JSX.Element => {

    // bei den dropdownoptions ist value als string, da in Verbindung mit einem <input> das im oder über dem Dropdown
    // stehen soll ein Bug beim value-Wert: 0 bei Primereact existiert (p-inputwrapper-filled wird nicht gesetzt)
    // https://github.com/primefaces/primeng/issues/10245
    // const intervalTypes: DropdownOption[] = [
    //     {value: '0', label: 'monatlich'}, {value: '1', label: 'wöchentlich'}, {value: '2', label: 'täglich'}
    // ];

    const intervalTimes: DropdownOption[] = [
        {value: '0', label: '00:00'},  {value: '1', label: '01:00'},  {value: '2', label: '02:00'},  {value: '3', label: '03:00'},
        {value: '4', label: '04:00'},  {value: '5', label: '05:00'},  {value: '6', label: '06:00'},  {value: '7', label: '07:00'},
        {value: '8', label: '08:00'},  {value: '9', label: '09:00'},  {value: '10', label: '10:00'}, {value: '11', label: '11:00'},
        {value: '12', label: '12:00'}, {value: '13', label: '13:00'}, {value: '14', label: '14:00'}, {value: '15', label: '15:00'},
        {value: '16', label: '16:00'}, {value: '17', label: '17:00'}, {value: '18', label: '18:00'}, {value: '19', label: '19:00'},
        {value: '20', label: '20:00'}, {value: '21', label: '21:00'}, {value: '22', label: '22:00'}, {value: '23', label: '23:00'}
    ];

    // const intervalWeekDays: DropdownOption[] = [
    //     {value: '1', label: 'Montag'},
    //     {value: '2', label: 'Dienstag'},
    //     {value: '3', label: 'Mittwoch'},
    //     {value: '4', label: 'Donnerstag'},
    //     {value: '5', label: 'Freitag'},
    //     {value: '6', label: 'Samstag'},
    //     {value: '0', label: 'Sonntag'},
    // ];

    const getIntervalMonthDays = () => {
        const options = [];
        for (let i = 1; i <= 31; i++) {
            options.push({
                value: i.toString(),
                label: (i > 9 ? i : '0' + i) + '.' + (i > 28 ? ' (oder Monatsende)' : '')
            });
        }
        return options;
    };

    const getInitialIntervalDayOptions = () => {
        if (props.interval === null) {
            return getIntervalMonthDays();
        } else {
            switch (props.interval.split(';')[0]) {
                case '0':
                    return getIntervalMonthDays();
                case '1':
                    getExecutionIntervalWeekdays().then(result => {
                        return result;
                    });
                    break;
                case '2':
                    return [];
                default:
                    return [];
            }
        }
    };


    const {t} = useTranslation(['common']);

    const intervalMonthDays: DropdownOption[] = getIntervalMonthDays();
    const [intervalWeekDays, setIntervalWeekDays] = useState<any[]>([]);

    const [intervalTypeOptions, setIntervalTypeOptions] = useState<any[]>([]);
    const [intervalDayOptions, setIntervalDayOptions] = useState<any>(null);
    const [intervalTimeOptions, setIntervalTimeOptions] = useState<DropdownOption[]>(intervalTimes);

    const [intervalType, setIntervalType] = useState<string>(props.interval === null || props.interval.split(';').length < 2
        ? '0'
        : props.interval.split(';')[0]
    );

    const [mounted, setMounted] = useState<boolean>(false);

    const sortArray = (array: string []) => {
        return array.sort(function (a, b) {  return parseInt(a) - parseInt(b);  });
    };

    useEffect(() => {

        getExecutionIntervalFrequencies().then(result => {
            setIntervalTypeOptions(result);
        });

        getExecutionIntervalWeekdays().then(result => {
            setIntervalWeekDays(result);
        });

        if (props.interval === null) {
            setIntervalDayOptions(getIntervalMonthDays());
        } else {
            switch (props.interval.split(';')[0]) {
                case '0':
                    setIntervalDayOptions(getIntervalMonthDays());
                    break;
                case '1':
                    getExecutionIntervalWeekdays().then(result => {
                        setIntervalDayOptions(result);
                    });
                    break;
                case '2':
                    setIntervalDayOptions([]);
                    break;
                default:
                    setIntervalDayOptions([]);
                    break;
            }
        }

        if (intervalType === '2') {
            props.setIntervalDay([]);
        }
    },[]);

    /*
    useEffect(() => {
        console.log('intervalDayOptions: ', intervalDayOptions);
    }, [intervalDayOptions])
     */

    /*
    useEffect(() => {
        console.log('intervalWeekDays: ', intervalWeekDays);
    }, [intervalWeekDays])
     */

    useEffect(() => {
        // Immer wenn sich der intervalType, die intervalDays oder die intervalTimes ändern wird der
        // IntervalText neu zusammengesetzt
        let intervalDayText = '';
        switch (parseInt(intervalType)) {
            case 0:
                // Setze den intervalDayText zusammen
                // Z.B.: ' am 01., 04., 15.'

                const monthDays = intervalMonthDays.filter((x: any) => {
                    return props.intervalDay.includes(x.value);
                });

                intervalDayText += ' ' + t('on') + ' ';
                for (let i=0; i<monthDays.length-1; i++) {
                    intervalDayText += monthDays[i].label + ', ';
                }
                if (monthDays.length > 0)
                    intervalDayText += monthDays[monthDays.length-1].label;

                break;
            case 1:
                // Setze den intervalDayText zusammen
                // Z.B.: ' am Dienstag, Donnerstag, Freitag'

                const weekDays = intervalWeekDays.filter((x: any) => {
                    return props.intervalDay.includes(x.value);
                });

                intervalDayText += ' ' + t('on') + ' ';
                for (let i=0; i<weekDays.length-1; i++) {
                    intervalDayText += weekDays[i].label + ', ';
                }
                if (weekDays.length > 0)
                    intervalDayText += weekDays[weekDays.length-1].label;

                break;
            case 2:
                intervalDayText = '';
                break;
        }

        // Setze den intervalDayText zusammen
        // Z.B.: ' um 01:00, 03:00, 06:00 Uhr'
        let intervalTimeText = ' ' + t('at') + ' ';
        
        const intervalTimes = intervalTimeOptions.filter((x: any) => {
            return props.intervalTime.includes(x.value);
        });
        
        for (let i=0; i<intervalTimes.length-1; i++) {
            intervalTimeText += intervalTimes[i].label + ', ';
        }
        
        if (props.intervalTime.length > 0 && intervalTimes.length > 0)
            intervalTimeText += intervalTimes[intervalTimes.length-1].label + ' ' + t('clock');


        // Setze den kompletten intervalText zusammen
        // Z.B.: 'wöchentlich am Dienstag, Donnerstag, Freitag um 01:00, 03:00, 06:00 Uhr'
        const intervalTypes = intervalTypeOptions.filter((x: any) => {
            return x.value === intervalType;
        });
        const intervalTypeText = intervalTypes.length > 0 ? intervalTypes[0].label : '';
        const intervalText = intervalTypeText + intervalDayText + intervalTimeText;

        // Baue das neue Interval durch ; getrennt
        const newInterval = intervalType + ';' + sortArray(props.intervalDay).toString() + ';' + sortArray(props.intervalTime).toString();

        // console.log('intervalText: ', intervalText);
        // console.log('interval: ', props.interval);
        // console.log('newInterval: ', newInterval);

        props.onChange(newInterval, intervalText);
        setMounted(true);

    }, [intervalType, props.intervalDay, props.intervalTime, intervalWeekDays, intervalTypeOptions]);

    useEffect(() => {
        // Setze die Wochen- oder Montatstage zurück wenn sich der intervalType ändert.
        // Führe diesen useEffect nicht beim mounten aus weil sonst intervalDay überschrieben wird.
        // Aber wenn der Benutzer den intervalType ändert, ist dieses Verhalten erwünscht.
        if (!mounted) {
            return;
        }
        switch (intervalType) {
            case '0':
                setIntervalDayOptions(intervalMonthDays);
                // setIntervalDay('1');
                break;
            case '1':
                setIntervalDayOptions(intervalWeekDays);
                // setIntervalDay('0');
                break;
            case '2':
                setIntervalDayOptions([]);

                break;
        }
        props.setIntervalDay([]);
    }, [intervalType]);

    return (
        <div className='grid'>
            <div className='col-12 md:col-4'>
                <Input
                    name={'interval_type'}
                    edit={true}
                    label={t('type')}
                    dropdownOptions={intervalTypeOptions}
                    type={'dropdown'}
                    value={intervalType}
                    onChange={(e: any) => {
                        setIntervalType(e.value);
                    }}
                />

            </div>
            <div className='col-12 md:col-4'>
                { intervalDayOptions && <Input
                    name={'interval_day'}
                    edit={true}
                    disabled={intervalType === '2'}
                    label={t('day')}
                    dropdownOptions={intervalDayOptions}
                    type={'multiselect'}
                    // value={intervalDay}
                    // onChange={(e: any) => {
                    //     setIntervalDay(e.value);
                    // }}
                    validationControl={props.validationControl}
                    validationErrorMessage={props.validationErrorMessage}
                    validationRules={{required: intervalType === '2' ? false : t('day') + ' ' + t('input:required')}}
                />}
            </div>
            <div className='col-12 md:col-4'>
                {intervalTimeOptions && <Input
                    name={'interval_time'}
                    edit={true}
                    label={t('time')}
                    dropdownOptions={intervalTimeOptions}
                    type={'multiselect'}
                    // value={intervalTime}
                    // onChange={(e: any) => {
                    //     console.log(e.value);
                    //     setIntervalTime(e.value);
                    // }}
                    validationControl={props.validationControl}
                    validationErrorMessage={props.validationErrorMessage}
                    validationRules={{required: t('time') + ' ' + t('input:required')}}
                />}
            </div>
        </div>
    );

};

export default IntervalSelection;

