/*
 * OperatingStateDialogEditForm.tsx
 * Author: lnappenfeld
 * Date: 16.06.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React from 'react';
import {useTranslation} from 'react-i18next';
import CustomDialog, {CustomDialogHeaderType} from '../global/CustomDialog';
import {OperatingStateForm} from './OperatingStateForm';
import {OperatingStateCondition} from '../functions/ConditionMonitoring';


type Props = {
    projectId: string,
    selectedOperatingStates: any,
    setSelectedOperatingStates: any,
    editOperatingState: boolean,
    setEditOperatingState: (data: boolean) => void,
    setReloadOperatingStates: (data: boolean) => void,
    // OperatingStateConditions
    reloadOperatingStateConditions?: boolean,
    setReloadOperatingStateConditions?: (reload: boolean) => void
    setSelectedOperatingStateConditions?: (data: OperatingStateCondition[]) => void
    selectedOperatingStateConditions?: OperatingStateCondition[],
    setEditOperatingStateCondition?: (data: boolean) => void,
}

export const OperatingStateDialogEditForm = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    return (
        <CustomDialog
            header={props.selectedOperatingStates.length > 0
                ? t('metrologyManagement:dialogs.editOperatingState.header')
                : t('metrologyManagement:dialogs.createOperatingState.header')}
            headerType={props.selectedOperatingStates.length > 0
                ? CustomDialogHeaderType.Update
                : CustomDialogHeaderType.Create}
            formName={'formOperatingState'}
            onCancel={() => {
                props.setEditOperatingState(false);
            }}
            onHide={() => {
                props.setEditOperatingState(false);
            }}
            visible={props.editOperatingState}
        >
            <OperatingStateForm
                projectId={props.projectId}
                onFinished={() => {
                    props.setEditOperatingState(false);
                    props.setSelectedOperatingStates([]);
                    props.setReloadOperatingStates(true);
                }}
                operatingState={props.selectedOperatingStates.length > 0 ? props.selectedOperatingStates[0] : null}
                // selectedOperatingStateConditions={props.selectedOperatingStateConditions}
                // setSelectedOperatingStateConditions={props.setSelectedOperatingStateConditions}
                // setEditOperatingStateCondition={props.setEditOperatingStateCondition}
                // setReloadOperatingStateConditions={props.setReloadOperatingStateConditions}
                // reloadOperatingStateConditions={props.reloadOperatingStateConditions}
            />
        </CustomDialog>
    );

};

