/*
 * NotificationRangeDialog.tsx
 * Author: lnappenfeld
 * Date: 06.05.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */


import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';

import Input from '../global/Input';
import ChannelSelectList from '../metrology/ChannelSelectList';

import NotificationSelectList from './NotificationSelectList';
import {
    createNotificationRange,
    NotificationRange,
    updateNotificationRanges
} from '../functions/Notification';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {Nullable} from '../functions/Global';


export enum Type {
    Single = 1,
    Multiple = 2,
    Wizard = 3, // Schwellwerte im NotificationWizard anzeigen
    Add,
    Remove
}

type Props = {
    notificationRangeData: NotificationRange[],
    projectId: string,
    onFinished: (success: boolean) => void
    type: Type
    // Bisher nur für den NotificationWizard vorgesehen
    setNotificationRangeData?: (notification: Nullable<any>) => void
}

const NotificationRangeEditForm = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    const notificationRangeId = props.notificationRangeData.length === 0 ? null : props.notificationRangeData[0].id;

    const defaultValues = {
        'notification_id': props.notificationRangeData.length === 0 ? null : props.notificationRangeData[0].notification_id,
        'channel_id': props.notificationRangeData.length === 0 ? null : props.notificationRangeData[0].channel_id,
        'minimum': props.notificationRangeData.length === 0  ? null : props.notificationRangeData[0].minimum,
        'maximum': props.notificationRangeData.length === 0  ? null : props.notificationRangeData[0].maximum,
        'flag_auto_reset_after_minutes': props.notificationRangeData.length === 0  ? 60 : props.notificationRangeData[0].flag_auto_reset_after_minutes,
    };

    const {control, formState: {errors}, handleSubmit, reset, getValues, watch, setValue, trigger} = useForm({defaultValues});

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    useEffect(() => {
        // console.log('type: ', props.type);

        if (props.type === Type.Single) {
            if (props.setNotificationRangeData) {
                // props.setNotificationRangeData([]);
            }
        } else if (props.type === Type.Multiple && props.notificationRangeData.length > 0) {
            // setze die Werte Minimum, Maximum und FlagAutoResetAfterMinutes auf Null, wenn nicht alle übergebenen Werte
            // jeweils gleich sind, d.h. wenn nicht alle Minimums, Maximums und AutoResets identisch sind
            const firstRange = props.notificationRangeData[0];
            for (const range of props.notificationRangeData) {
                if (range.minimum !== firstRange.minimum) {
                    setValue('minimum', null);
                    break;
                }
            }
            for (const range of props.notificationRangeData) {
                if (range.maximum !== firstRange.maximum) {
                    setValue('maximum', null);
                    break;
                }
            }
            for (const range of props.notificationRangeData) {
                if (range.flag_auto_reset_after_minutes !== firstRange.flag_auto_reset_after_minutes) {
                    setValue('flag_auto_reset_after_minutes', null);
                    break;
                }
            }

        } else if (props.type === Type.Wizard && props.setNotificationRangeData) {
            // set initial values in parent component
            updateNotificationRangeData();
        }
    }, []);

    const saveData = (notificationRange: any) => {
        const projectId = {'project_id': props.projectId};
        notificationRange = {...projectId, ...notificationRange};

        switch (props.type) {
            case Type.Single:
                if (notificationRangeId !== null) {
                    delete notificationRange.notification_id;
                    updateNotificationRanges(props.projectId, [notificationRangeId], notificationRange).then(result => {
                        if (result.error) {
                            showMessageOnError(t('error'),result.error);
                        } else {
                            showMessageOnSuccess(t('success'), t('alarmManagement:toasts.notificationRangeUpdated'));
                            props.onFinished(true);
                        }
                    });
                } else {
                    createNotificationRange(props.projectId, notificationRange).then(result => {
                        if (result.error) {
                            showMessageOnError(t('error'),result.error);
                        } else {
                            showMessageOnSuccess(t('success'), t('alarmManagement:toasts.notificationRangeCreated'));
                            props.onFinished(true);
                        }
                    });
                }
                break;
            case Type.Multiple:
                console.log(notificationRange);
                const notificationRangeIds: string[] = [];
                props.notificationRangeData.forEach((range:any) =>{
                    notificationRangeIds.push(range.id);
                });
                delete notificationRange.notification_id;
                delete notificationRange.channel_id;
                updateNotificationRanges(props.projectId, notificationRangeIds, notificationRange).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'),result.error);
                    } else {
                        showMessageOnSuccess(t('success'), t('alarmManagement:toasts.notificationRangeUpdated'));
                        props.onFinished(true);
                    }
                });
                break;
        }

    };

    // Für den NotificationWizard: wenn man zwischen den Schritten hin und her springt müssen die Daten gespeichert werden
    const updateNotificationRangeData = () => {
        const data = {
            'minimum': getValues('minimum'),
            'maximum': getValues('maximum'),
            'flag_auto_reset_after_minutes': getValues('flag_auto_reset_after_minutes'),
        };

        if (props.setNotificationRangeData) {
            props.setNotificationRangeData(data);
        }
    };

    return (
        <form id='formDialog' onSubmit={(event) => {
            if (props.type === Type.Wizard && props.setNotificationRangeData) {
                trigger().then((success) => {
                    event.preventDefault();
                    props.onFinished(success);
                });
            } else {
                trigger().then(() => {
                    saveData(getValues());
                    event.preventDefault();
                });
            }
        }}>
            <div className='card'>
                {props.type === Type.Single &&
                <>
                    <NotificationSelectList
                        projectId={props.projectId}
                        disabled={getValues('notification_id') !== null}
                        name={'notification_id'}
                        label={t('alarmManagement:attributes.notification')}
                        onChange={e => {
                            setValue('notification_id', e.value);
                        }}
                        validationControl={control}
                        validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('alarmManagement:attributes.notification') + ' ' + t('input:required')}}
                    />
                    <ChannelSelectList
                        type={'notification'}
                        projectId={props.projectId}
                        label={t('channel')}
                        name={'channel_id'}
                        onChange={e => setValue('channel_id', e.value)}
                        validationControl={control}
                        validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('channel') + ' ' + t('input:required')}}
                    />
                </>
                }
                <Input
                    edit={true} label={t('alarmManagement:attributes.maximum')} name={'maximum'}
                    type={'number'} maxFractionDigits={8}
                    onChange={(maximum) => {
                        setValue('maximum', maximum.value);
                        if (props.type === Type.Wizard && props.setNotificationRangeData) {updateNotificationRangeData();}
                    }}
                    validationControl={control} validationRules={{required: false}}
                />
                <Input
                    edit={true} label={t('alarmManagement:attributes.minimum')} name={'minimum'}
                    type={'number'} maxFractionDigits={8}
                    onChange={(minimum) => {
                        setValue('minimum', minimum.value);
                        if (props.type === Type.Wizard && props.setNotificationRangeData) {updateNotificationRangeData();}}}
                    validationControl={control} validationRules={{required: false}}
                />
                <Input
                    edit={true}
                    label={t('alarmManagement:attributes.flag_auto_reset_after_minutes')}
                    name={'flag_auto_reset_after_minutes'}
                    type={'number'}
                    onChange={(minutes) => {
                        setValue('flag_auto_reset_after_minutes', minutes.value);
                        if (props.type === Type.Wizard && props.setNotificationRangeData) {updateNotificationRangeData();}
                    }}
                    validationControl={control}
                    validationRules={{required: false}}
                />
            </div>
        </form>
    );

};

export default NotificationRangeEditForm;
