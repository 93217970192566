/*
 * HistoricalAlarmsOverview.tsx
 * Author: lnappenfeld
 * Date: 11.10.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */
import React, {useEffect, useState} from 'react';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {useTranslation} from 'react-i18next';
import {FilterMatchMode} from 'primereact/api';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getHistoricalAlarms, rateHistoricalAlarms} from '../functions/Notification';
import moment from 'moment';
import {Button} from 'primereact/button';
import date from '../../functions/date';
import {MultiSelect} from 'primereact/multiselect';

import {faThumbsDown, faThumbsUp} from '@fortawesome/free-regular-svg-icons';

type Props = {
    projectId: string,
    notificationId: string,
    groupingTimeInMinutes: number | null,
    from?: Date,
    to?: Date
}
export const HistoricalAlarmsOverview = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    const [historicalAlarms, setHistoricalAlarms] = useState<any>([]);
    const [groupingOptions, setGroupingOptions] = useState<any>({});
    // const [expandedRows, setExpandedRows] = useState<any>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const isReportingRelevantFilterType = [
        {id: 0, value: null, name: t('Unknown')},
        {id: 1, value: false, name: t('no')},
        {id: 2, value: true, name: t('yes')}
    ];

    const isReportingRelevantItemTemplate = (option: any) => {
        return (<div>{option.name}</div>);
    };

    const isReportingRelevantFilterTemplate = (options: any) => {
        return (
            <MultiSelect
                value={options.value} options={isReportingRelevantFilterType}
                itemTemplate={isReportingRelevantItemTemplate}
                onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="name" placeholder="Any"
                className="p-column-filter"
            />
        );
    };

    useEffect(() => {
        _setHistoricalAlarms();
        // setExpandedRows([]);
    }, [props.notificationId]);

    useEffect(() => {
        _setHistoricalAlarms();
        // setExpandedRows([]);
    }, [props.from, props.to]);

    useEffect(() => {
        console.log(props.groupingTimeInMinutes)
        if (props.groupingTimeInMinutes) {
            const groupedAlarms = groupRowsByTime(historicalAlarms, props.groupingTimeInMinutes);
            setHistoricalAlarms(groupedAlarms);
            setDataLoaded(true);

            setGroupingOptions(
                {
                    rowGroupMode: 'subheader',
                    groupRowsBy: 'group_id',
                    expandableRowGroups: true,
                    rowGroupHeaderTemplate: groupHeaderTemplate,
                    rowGroupFooterTemplate: groupFooterTemplate,
                }
            )
        } else {
            setGroupingOptions({});
        }
        // setExpandedRows([]);
    }, [props.groupingTimeInMinutes]);

    const _setHistoricalAlarms = () => {
        getHistoricalAlarms(props.projectId, props.notificationId, props.from, props.to).then(result => {
            if (props.groupingTimeInMinutes) {
                const groupedAlarms = groupRowsByTime(result, props.groupingTimeInMinutes);
                setHistoricalAlarms(groupedAlarms);
            } else {
                setHistoricalAlarms(result);
            }
            setDataLoaded(true);
        });
    };

    /**
     * Die Datensätze werden gruppiert, indem ein Attribut 'group_id' hinzugefügt, oder falls vorhanden,
     * überschrieben wird
     * @param data
     * @param groupingTimeInMinutes
     */
    const groupRowsByTime = (data: any, groupingTimeInMinutes: number) => {
        let groupCount = 1;
        if (data.length >= 2) {
            for (let i = 0; i < data.length - 1; i++) {
                const currentGroupMoment = moment(data[i].alarm_timestamp_utc);
                const nextMoment = moment(data[i + 1].alarm_timestamp_utc);
                currentGroupMoment.add(groupingTimeInMinutes, 'minutes');

                // Ändere die GroupId im aktuellen Datensatz mit dem aktuellen groupCount
                if (data[i].hasOwnProperty('group_id')) {
                    data[i]['group_id'] = groupCount;
                } else {
                    const addId = {'group_id': groupCount};
                    data[i] = {...addId, ...data[i]};
                }

                // Prüfe ob Datensatz in Gruppe ist, sonst zähle hoch
                if (currentGroupMoment.diff(nextMoment) < 0) {
                    groupCount++;
                }
            }

            // Passe noch die GroupId im letzten Datensatz an
            if (data[data.length - 1].hasOwnProperty('group_id')) {
                data[data.length - 1]['group_id'] = groupCount;
            } else {
                const addId = {'group_id': groupCount};
                data[data.length - 1] = {...addId, ...data[data.length - 1]};
            }
        }

        return data;
    };

    // channel_name, channel_type, min_value, alarm_value usw. geht nicht, weil es auf notification-Ebene und nicht Range-Ebene ist
    const columns: ColumnObject[] = [
        // {field: 'alarm_name', header: t('name'), filter: true, filterField: 'name'},
        {field: 'location_name', header: t('location')},
        {field: 'sensor_name', header: t('sensor')},
        {field: 'sensor_type_name', header: t('metrologyManagement:attributes.sensorType')},
        {field: 'alarm_type', header: t('alarmManagement:attributes.alarmType')},
        {field: 'alarm_value', header: t('alarmManagement:attributes.flag_set_value')},
        {field: 'alarm_timestamp_utc', header: t('timestamp'), type: 4},
        {
            field: 'is_reporting_relevant', header: t('alarmManagement:attributes.isReportingRelevant'), filter: true,
            filterElement: isReportingRelevantFilterTemplate,
            body: (rowData: any) => {
                return rowData['is_reporting_relevant'] === null
                    ? t('Unknown')
                    : rowData['is_reporting_relevant']
                        ? t('yes')
                        : t('no');
            }
        }
    ];

    const updateLocalAndRatedAlarms = (actionQueueCacheIds: string[], isReportingRelevant: boolean) => {
        const newAlarms = historicalAlarms.map((item: any) => {
            if (actionQueueCacheIds.includes(item.action_queue_cache_id)) {
                item['is_reporting_relevant'] = isReportingRelevant;
            }
            return item;
        });
        setHistoricalAlarms(newAlarms);
        setDataLoaded(true);
    };

    const getActionQueueCacheIds = (alarm: any) => {
        // Get all alarms with current group
        const currentGroupAlarms = historicalAlarms.filter((x: any) => {
            return x.group_id === alarm.group_id;
        });

        // Add actionQueueCacheIds to array
        const actionQueueCacheIds = [];
        for (const groupAlarm of currentGroupAlarms) {
            actionQueueCacheIds.push(groupAlarm.action_queue_cache_id);
        }
        return actionQueueCacheIds;
    };


    /** **************************************************************************************************
     * Row Grouping
     ****************************************************************************************************/

    const rowGroupHeaderButtons = (selection: any) => {
        // console.log(selection);

        const headerButtons = [{
            // icons: [{key: null, value: null, icon: <FontAwesomeIcon icon={['far', 'star']}/>}],
            icons: [{key: null, value: null, icon: <FontAwesomeIcon icon={faThumbsDown}/>}],
            tooltip: t('alarmManagement:tooltips.tooltipReportingIrrelevant'),
            tooltipOptions: {position: 'top'},
            onClick: (alarm: any) => {
                const isReportingRelevant = false;
                const actionQueueCacheIds = getActionQueueCacheIds(alarm);
                const data = {
                    'action_queue_cache_ids': actionQueueCacheIds,
                    'is_reporting_relevant': isReportingRelevant
                };
                rateHistoricalAlarms(props.projectId, data).then();

                updateLocalAndRatedAlarms(actionQueueCacheIds, isReportingRelevant);
            },
            permitted: true
        }, {
            // icons: [{key: null, value: null, icon: <FontAwesomeIcon icon={['fas', 'star']}/>}],
            icons: [{key: null, value: null, icon: <FontAwesomeIcon icon={faThumbsUp}/>}],
            tooltip: t('alarmManagement:tooltips.tooltipReportingRelevant'),
            tooltipOptions: {position: 'top'},
            onClick: (alarm: any) => {
                const isReportingRelevant = true;
                const actionQueueCacheIds = getActionQueueCacheIds(alarm);
                const data = {
                    'action_queue_cache_ids': actionQueueCacheIds,
                    'is_reporting_relevant': isReportingRelevant
                };
                rateHistoricalAlarms(props.projectId, data).then();

                updateLocalAndRatedAlarms(actionQueueCacheIds, isReportingRelevant);
            },
            permitted: true
        }];

        return (
            <>
                {/* <div style={{textAlign: 'right'}}>*/}
                {headerButtons.map(item => {
                    if (item.permitted) {
                        // Setze icon initial mit dem nullten Element (Standardfall)
                        let icon = item.icons[0].icon;
                        // Bei mehreren, setze icon je nach Wert
                        for (const iconObj of item.icons) {
                            // @ts-ignore
                            const value = selection[iconObj.key];
                            if (value === iconObj.value) {
                                icon = iconObj.icon;
                                break;
                            }
                        }

                        return (
                            <Button
                                className='p-button m-1 p-button-text' style={{minWidth: '20px'}}
                                // Tooltip flackert mit den StyleSheets
                                tooltip={item.tooltip}
                                // tooltipOptions={item.tooltipOptions}
                                onClick={() => {
                                    item.onClick(selection);
                                }}
                            >
                                {icon}
                            </Button>
                        );
                    }
                })}
                {/* </div>*/}
            </>
        );
    };

    const calculateGroupRowsTotal = (name: string) => {
        let total = 0;
        for (const row of historicalAlarms) {
            if (row.group_id === name) {
                total++;
            }
        }
        return total;
    };

    const groupHeaderTemplate = (data: any) => {
        return (
            <React.Fragment>

                {/* <span className='grid'>*/}
                {/*    <span className='col-12 md:col-10' style={{alignSelf: 'center'}}>*/}
                {/*        <strong>Benachrichtigung:</strong> {data.alarm_name} |*/}
                {/*        <strong> Erster Alarm: </strong> {date.getLocaleString(new Date(data.alarm_timestamp))} |*/}
                {/*        <strong> Anzahl: </strong> {calculateGroupRowsTotal(data.group_id)}*/}
                {/*    </span>*/}
                {/*    <span className='col-12 md:col-2'>*/}
                {/*        {rowGroupHeaderButtons(data)}*/}
                {/*    </span>*/}
                {/* </span>*/}

                <span style={{verticalAlign: '-webkit-baseline-middle'}}>
                    <strong>Benachrichtigung:</strong> {data.alarm_name} |
                    <strong> Erster Alarm: </strong> {date.getLocaleString(new Date(data.alarm_timestamp_tz))} |
                    <strong> Anzahl: </strong> {calculateGroupRowsTotal(data.group_id)}
                </span>
                <span className={'rowGroupHeaderSpan'}>
                    {rowGroupHeaderButtons(data)}
                </span>
            </React.Fragment>
        );
    };

    const groupFooterTemplate = (data: any) => {
        return (
            <React.Fragment>
                <td style={{textAlign: 'right'}}>Total Alarms</td>
                <td>{calculateGroupRowsTotal(data.group_id)}</td>
            </React.Fragment>
        );
    };

    /** **************************************************************************************************
     * Row Grouping - End
     ****************************************************************************************************/

    return (
        <div className='card' style={{height: '565px'}}>
            <CustomDataTable
                id='historicalAlarms'
                rowClass={
                    [
                        {
                            key: 'is_reporting_relevant',
                            value: null,
                            class: 'font-bold'
                        },
                        {
                            key: 'is_reporting_relevant',
                            value: false,
                            class: 'font-bold'
                        }
                    ]
                }
                columns={columns}
                editable={true}
                sortable={true}
                value={historicalAlarms}
                className='overflow-hidden'
                scrollHeight='flex'
                headerTitle={t('alarmManagement:global:historicalAlarms')}
                filters={{
                    'is_reporting_relevant': {value: null, matchMode: FilterMatchMode.IN}
                }}
                customButtons={[{
                    icons: [{key: null, value: null, icon: <FontAwesomeIcon icon={faThumbsDown}/>}],
                    tooltip: t('alarmManagement:tooltips.tooltipReportingIrrelevant'),
                    tooltipOptions: {position: 'top'},
                    onClick: (alarm: any) => {
                        const isReportingRelevant = false;
                        const actionQueueCacheIds = [alarm.action_queue_cache_id];
                        const data = {
                            'action_queue_cache_ids': actionQueueCacheIds,
                            'is_reporting_relevant': isReportingRelevant
                        };
                        rateHistoricalAlarms(props.projectId, data).then();

                        updateLocalAndRatedAlarms(actionQueueCacheIds, isReportingRelevant);
                    },
                    permitted: true
                }, {
                    icons: [{key: null, value: null, icon: <FontAwesomeIcon icon={faThumbsUp}/>}],
                    tooltip: t('alarmManagement:tooltips.tooltipReportingRelevant'),
                    tooltipOptions: {position: 'top'},
                    onClick: (alarm: any) => {
                        const isReportingRelevant = true;
                        const actionQueueCacheIds = [alarm.action_queue_cache_id];
                        const data = {
                            'action_queue_cache_ids': actionQueueCacheIds,
                            'is_reporting_relevant': isReportingRelevant
                        };
                        rateHistoricalAlarms(props.projectId, data).then();

                        updateLocalAndRatedAlarms(actionQueueCacheIds, isReportingRelevant);
                    },
                    permitted: true
                }
                ]}
                style={{height: '94%'}}
                ableToDelete={false}
                ableToUpdate={false}
                {...groupingOptions}
                dataloaded={dataLoaded}
            />
        </div>
    );
};
