import React, {useEffect, useMemo, useState} from 'react';
import CustomDialog from '../../global/CustomDialog';
import Input from '../../global/Input';
import {useForm} from 'react-hook-form';
import GraphConfiguration from './GraphConfiguration';
import {Button} from 'primereact/button';
import Formula from 'fparser';
import {showMessageOnError, showMessageOnSuccess} from '../../global/CustomToast';
import {useTranslation} from 'react-i18next';
// import {functionVals, thresholdVals, rectangleVals} from './settingsEE';
import CustomDataTable, {ColumnObject} from '../../global/CustomDataTable';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencil, faTrash} from '@fortawesome/free-solid-svg-icons';
import CreateConfiguration from '../doppelfilter/CreateConfiguration';
import {CustomConfirmDialog} from '../../global/CustomConfirmDialog';
import {
    getCMCorrelatedValues,
    getMonitoringAppAreaConfiguration,
    getMonitoringAppParameter,
    getOperatingStates, setMonitoringAppParameterAndAreaConfiguration
} from '../functions/EnergyEfficiencyConfiguration';
import {Calendar} from 'primereact/calendar';
import moment from 'moment';

type ConfigurationProps = {
    projectId: string,
    modal: boolean,
    onHide: ()=>void
}
const EnergyEfficiencyConfiguration = (props: ConfigurationProps)=>{
    const { t } = useTranslation(['common']);
    const monitoringAppId: any = localStorage.getItem('monitoringAppId') ? localStorage.getItem('monitoringAppId') : '';
    const monitoringAppTypeId: any = localStorage.getItem('monitoringAppTypeId') ? localStorage.getItem('monitoringAppTypeId') : '';

    const [functionTableVals, setFunctionTableVals] = useState<any>([]);
    const [thresholdTableVals, setThresholdTableVals] = useState<any>([]);
    const [rectangleTableVals, setRectangleTableVals] = useState<any>([]);
    const [tableName, setTableName] = useState<string>('');

    const [channelName, setChannelName] = useState<string>('');
    const [measurementDialogue, setMeasurementDialogue] = useState<boolean>(false);
    const [deleteValue, setDeleteValue] = useState(0);
    const [formType, setFormType] = useState<string>('');
    const [rowValue, setRowValue ] = useState<any>();


    const [forderhoheValues, setForderhoheValues] = useState<any>([]);
    const [wirkungsgradValues, setWirkungsgradValues] = useState<any>([]);
    const [leistungsbedarfValues, setLeistungsbedarfValues] = useState<any>([]);

    const [graphMax, setGraphMax] = useState<number>(0);

    const [minThreshold, setMinThreshold] = useState<any>(null);
    const [maxThreshold, setMaxThreshold] = useState<any>(null);
    const [dropdownOptions, setDropdownOptions] = useState<any>([]);
    const [operationalValues, setOperationalValues] = useState<any>();
    const [graphForderhohe, setGraphForderhohe] = useState<any>([]);
    const [graphLeistungsbedarf, setGraphLeistungsbedarf] = useState<any>([]);
    const [graphWirkungsgrad, setGraphWirkungsgrad] = useState<any>([]);

    const [startTime, setStartTime] = useState<string>(() => {
        const date = new Date();
        date.setDate(date.getDate() - 2);
        date.setHours(0, 0, 0, 0);
        const user = JSON.parse(localStorage.getItem('user') as string);
        const options:any = {
            timeZone: user['timezone'],
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false // Use 24-hour format
        };

        return date.toLocaleString('en-US', options);
    });
    const [endTime, setEndTime] = useState<string>(() => {
        const date = new Date();
        date.setHours(23, 59, 0, 0);
        const user = JSON.parse(localStorage.getItem('user') as string);
        const options:any = {
            timeZone: user['timezone'],
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false // Use 24-hour format
        };

        return date.toLocaleString('en-US', options);

    });

    const defaultRect = {
        x1:0,
        y1:0,
        x2:0,
        y2:0,
    };

    // const [rects, setRects] = useState([defaultRect, defaultRect, defaultRect]);

    const updateGraphXAxis = ()=>{
        const max1 = functionTableVals[0]['max'];
        const max2 = functionTableVals[1]['max'];
        const max3 = functionTableVals[2]['max'];

        const maxNumber = Math.max(max1, max2, max3);
        setGraphMax(maxNumber);
    };

    const calculatePolynomialFunction = (name: number) =>{
        if (name === 1) {
            if (Number(functionTableVals[0]['min']) < Number(functionTableVals[0]['max'])) {
                const values : any = [];
                for (let i=Number(functionTableVals[0]['min']); i <= Number(functionTableVals[0]['max']); i++) {
                    try {
                        const calculate = new Formula(functionTableVals[0]['function']);
                        values.push(calculate.evaluate({x: i}));
                    }
                    catch (e) {
                        showMessageOnError(t('error'), t('conditionMonitoring:formula_error'));
                    }
                }
                if (functionTableVals[0]['min'] > 0) {
                    for (let i= 0; i < Number(functionTableVals[0]['min']); i++) {
                        values.unshift(null);
                    }
                }
                setForderhoheValues(values);
                updateGraphXAxis();
            }
            else {
                showMessageOnError(t('error'), t('conditionMonitoring:min_max_error'));
            }
        }
        else if (name === 2) {
            if (Number(functionTableVals[1]['min']) < Number(functionTableVals[1]['max'])) {
                const values : any = [];
                for (let i=Number(functionTableVals[1]['min']); i <= Number(functionTableVals[1]['max']); i++) {
                    const calculate = new Formula(functionTableVals[1]['function']);
                    values.push(calculate.evaluate({x: i}));
                }
                if (Number(functionTableVals[1]['min']) > 0) {
                    for (let i= 0; i < Number(functionTableVals[1]['min']); i++) {
                        values.unshift(null);
                    }
                }
                setLeistungsbedarfValues(values);
                updateGraphXAxis();
            }
            else {
                showMessageOnError(t('error'), t('conditionMonitoring:min_max_error'));
            }

        }
        else if (name === 3) {
            if (Number(functionTableVals[2]['min']) < Number(functionTableVals[2]['max'])) {
                const values : any = [];
                for (let i=Number(functionTableVals[2]['min']); i <= Number(functionTableVals[2]['max']); i++) {
                    try {
                        const calculate = new Formula(functionTableVals[2]['function']);
                        values.push(calculate.evaluate({x: i}));
                    }
                    catch (e) {
                        showMessageOnError(t('error'), t('conditionMonitoring:formula_error'));
                    }
                }
                if (functionTableVals[2]['min'] > 0) {
                    for (let i= 0; i < Number(functionTableVals[2]['min']); i++) {
                        values.unshift(null);
                    }
                }
                setWirkungsgradValues(values);
                updateGraphXAxis();
            }
            else {
                showMessageOnError(t('error'), t('conditionMonitoring:min_max_error'));
            }
        }

    };

    const deletePolynomialFunction = (name: number)=>{
        if (name === 1) {
            setForderhoheValues([]);
            functionTableVals[0]['function'] = '';
            functionTableVals[0]['min'] = '';
            functionTableVals[0]['max'] = '';
        }
        else if (name === 2) {
            setLeistungsbedarfValues([]);
            functionTableVals[1]['function'] = '';
            functionTableVals[1]['min'] = '';
            functionTableVals[1]['max'] = '';
        }
        else if (name === 3) {
            setWirkungsgradValues([]);
            functionTableVals[2]['function'] = '';
            functionTableVals[2]['min'] = '';
            functionTableVals[2]['max'] = '';
        }
    };
    const [rectangleCount, setRectangleCount] = useState<number>(0);
    const handleRemoveRectangles = ()=>{
        rectangleTableVals[0]['x1'] = 0;
        rectangleTableVals[0]['x2'] = 0;
        rectangleTableVals[0]['y1'] = 0;
        rectangleTableVals[0]['y2'] = 0;

        rectangleTableVals[1]['x1'] = 0;
        rectangleTableVals[1]['x2'] = 0;
        rectangleTableVals[1]['y1'] = 0;
        rectangleTableVals[1]['y2'] = 0;

        rectangleTableVals[2]['x1'] = 0;
        rectangleTableVals[2]['x2'] = 0;
        rectangleTableVals[2]['y1'] = 0;
        rectangleTableVals[2]['y2'] = 0;
        setRectangleCount(0);
    };

    const columns1: ColumnObject[] = [
        {field: 'name', header: t('conditionMonitoring:function_name'), filter: false, body: (data: any)=>{
            return (
                <>{data['name']} [{data['unit']}]</>
            );
        }},
        {field: 'function', header: t('conditionMonitoring:function_detail'), filter: false, editCellClassName:'function_table_input',
            editCell(e: any) {
                functionTableVals.map((val: any) => {
                    if (val['count'] === e['count']) {
                        val['function'] = e['function'];
                    }
                    return;
                });
            }},
        {field: 'min', header: t('conditionMonitoring:function_min'), filter: false, style: {maxWidth: '100px'}, editCellClassName:'table_input',
            editCell(e: any) {
                functionTableVals.map((val: any) => {
                    if (val['count'] === e['count']) {
                        val['min'] = Number(e['min']);
                    }
                    return;
                });
            }},
        {field: 'max', header: t('conditionMonitoring:function_max'), filter: false, style: {maxWidth: '100px'}, editCellClassName:'table_input',
            editCell(e: any) {
                functionTableVals.map((val: any) => {
                    if (val['count'] === e['count']) {
                        val['max'] = Number(e['max']);
                    }
                    return;
                });
            }},
        {field: 'option', header: t('options'), filter: false, style: {maxWidth: '80px'},
            body: (data: any)=>{
                return (<>
                    <Button
                        icon='pi pi-trash'
                        style={{color: 'red'}}
                        className="p-button-rounded p-button-secondary p-button-text filter-icon"
                        onClick={(e) => {
                            e.preventDefault();
                            deletePolynomialFunction(data['count'] + 1);
                        }}
                    />
                    <Button
                        icon='pi pi-calculator'
                        className="p-button-rounded p-button-secondary p-button-text filter-icon"
                        onClick={(e) => {
                            e.preventDefault();
                            calculatePolynomialFunction(data['count'] + 1);
                        }}
                    />

                </>);
            }}
    ];

    const columns2: ColumnObject[] = [
        {field: 'qmin', header: t('conditionMonitoring:minimum_threshold'), filter: false, editCellClassName:'table_input',
            editCell(e: any) {
                thresholdTableVals.map((val: any) => {
                    if (val['count'] === e['count']) {
                        val['qmin'] = (e['qmin']);
                    }
                    return;
                });
            }},
        {field: 'qmax', header: t('conditionMonitoring:maximum_threshold'), filter: false, editCellClassName:'table_input',
            editCell(e: any) {
                thresholdTableVals.map((val: any) => {
                    if (val['count'] === e['count']) {
                        val['qmax'] = (e['qmax']);
                    }
                    return;
                });
            }},
        {field: 'option', header: t('options'), filter: false, style: {maxWidth: '100px'},
            body: (data: any)=>{
                return (<>
                    <Button
                        icon='pi pi-trash'
                        style={{color: 'red'}}
                        className="p-button-rounded p-button-secondary p-button-text filter-icon"
                        onClick={(e) => {
                            e.preventDefault();
                            thresholdTableVals[0]['qmin'] = '';
                            thresholdTableVals[0]['qmax'] = '';
                            setMinThreshold(null);
                            setMaxThreshold(null);
                        }}
                    />
                    <Button
                        icon='pi pi-calculator'
                        className="p-button-rounded p-button-secondary p-button-text filter-icon"
                        onClick={(e) => {
                            e.preventDefault();
                            if (Number(thresholdTableVals[0]['qmin']) <= Number(thresholdTableVals[0]['qmax'])) {
                                const min: any = thresholdTableVals[0]['qmin'] === '' ? null : thresholdTableVals[0]['qmin'];
                                const max: any = thresholdTableVals[0]['qmax'] === '' ? null : thresholdTableVals[0]['qmax'];
                                setMinThreshold(min);
                                setMaxThreshold(max);
                            }
                            else {
                                showMessageOnError(t('error'), t('conditionMonitoring:min_max_error'));
                            }
                        }}
                    />

                </>);
            }
        },
        {
            field:'channel_name', header: t('conditionMonitoring:channel_name'), filter: false, style:{maxWidth: '180px'},
            body: (points: any)=>{
                return (<u className="cell-function update-channels" onClick={(e)=>{
                    e.stopPropagation();
                }}>
                    <FontAwesomeIcon icon={faPencil} className='ml-2' onClick={(e)=>{
                        setTableName('thresholdTable');
                        setMeasurementDialogue(true);
                        setFormType('edit');
                        setChannelName(points['channel_name']);
                        setRowValue(points);
                    }}/>&nbsp;
                    {points['channel_name'] !== null && <FontAwesomeIcon icon={faTrash} className='ml-2' onClick={(e)=>{
                        points['channel_name'] = null;
                        points['channel_unit'] = null;
                        points['channel_id'] = null;
                        setDeleteValue(deleteValue + 1);
                        return;
                    }}/> }&nbsp; &nbsp;
                    {points.channel_name}
                </u> );
            }
        }

    ];

    const columns3: ColumnObject[]=[
        {field: 'area_type_name', header: t('description'), filter: false, body: (data: any)=>{
            return (
                <>{data['area_type_name']} [{data['unit']}]</>
            );
        }},
        {field: 'x1', header: 'X1', style: {maxWidth: '100px'},editCellClassName:'table_input',
            editCell(e: any) {
                rectangleTableVals.map((val: any) => {
                    if (val['number'] === e['number']) {
                        val['x1'] = Number(e['x1']);
                    }
                    return;
                });
                rectangleTableVals[e['number'] - 1].x1 = e['x1'];
                setRectangleTableVals(JSON.parse(JSON.stringify(rectangleTableVals)));
            }},
        {field: 'x2', header: 'X2', style: {maxWidth: '100px'}, editCellClassName:'table_input',
            editCell(e: any) {
                rectangleTableVals.map((val: any) => {
                    if (val['number'] === e['number']) {
                        val['x2'] = (e['x2']);
                    }
                    return;
                });
                rectangleTableVals[e['number']-1].x2 = e['x2'];
                setRectangleTableVals(JSON.parse(JSON.stringify(rectangleTableVals)));
            }},
        {field: 'y1', header: 'Y1', style: {maxWidth: '100px'}, editCellClassName:'table_input',
            editCell(e: any) {
                rectangleTableVals.map((val: any) => {
                    if (val['number'] === e['number']) {
                        val['y1'] = (e['y1']);
                    }
                    return;
                });
                rectangleTableVals[e['number']-1].y1 = e['y1'];
                setRectangleTableVals(JSON.parse(JSON.stringify(rectangleTableVals)));
            }},
        {field: 'y2', header: 'Y2', style: {maxWidth: '100px'}, editCellClassName:'table_input',
            editCell(e: any) {
                rectangleTableVals.map((val: any) => {
                    if (val['number'] === e['number']) {
                        val['y2'] = (e['y2']);
                    }
                    return;
                });
                rectangleTableVals[e['number'] - 1].y2 = e['y2'];
                setRectangleTableVals(JSON.parse(JSON.stringify(rectangleTableVals)));
            }},
        {field: 'options', header: t('options'), style: {maxWidth: '80px'}, body:(data: any)=>{
            return (<>
                <Button
                    icon='pi pi-refresh'
                    className="p-button-rounded p-button-secondary p-button-text"
                    onClick={(e) => {
                        e.preventDefault();
                        CustomConfirmDialog({
                            message: t('conditionMonitoring:rect_refresh_text'),
                            header: t('confirmation'),
                            translation: t,
                            onConfirm: () => {
                                rectangleTableVals[data['number'] - 1].x1 = 0;
                                rectangleTableVals[data['number'] - 1].x2 = 0;
                                rectangleTableVals[data['number'] - 1].y1 = 0;
                                rectangleTableVals[data['number'] - 1].y2 = 0;
                                setRectangleTableVals(JSON.parse(JSON.stringify(rectangleTableVals)));
                                setRectangleCount(data['number'] - 1);
                            }
                        });
                    }}
                />
            </>);
        }},
        {
            field: 'channel_name', header: t('conditionMonitoring:channel_name'),
            filter:false, style:{maxWidth: '180px'}, body: (points: any)=>{
                return (<u className="cell-function update-channels" onClick={(e)=>{
                    e.stopPropagation();
                }}>
                    <FontAwesomeIcon icon={faPencil} className='ml-2' onClick={(e)=>{
                        setTableName('rectangleTable');
                        setMeasurementDialogue(true);
                        setFormType('edit');
                        setChannelName(points['channel_name']);
                        setRowValue(points);
                    }}/>&nbsp;
                    {points['channel_name'] !== null && <FontAwesomeIcon icon={faTrash} className='ml-2' onClick={(e)=>{
                        points['channel_name'] = null;
                        points['channel_id'] = null;
                        setDeleteValue(deleteValue + 1);
                        return;
                    }}/> }&nbsp; &nbsp;

                    {points.channel_name}</u> );
            }
        },

    ];

    const {
        handleSubmit
    } = useForm<any>({ });

    const saveData = () => {

        const monitoringAppParameterJSON = [
            {
                'monitoring_app_parameter_type_id': functionTableVals[0]['monitoring_app_parameter_type_id_function'],
                'value': functionTableVals[0]['function']
            },
            {
                'monitoring_app_parameter_type_id': functionTableVals[0]['monitoring_app_parameter_type_id_min'],
                'value': functionTableVals[0]['min']
            },
            {
                'monitoring_app_parameter_type_id': functionTableVals[0]['monitoring_app_parameter_type_id_max'],
                'value': functionTableVals[0]['max']
            },
            {
                'monitoring_app_parameter_type_id': functionTableVals[1]['monitoring_app_parameter_type_id_function'],
                'value': functionTableVals[1]['function']
            },
            {
                'monitoring_app_parameter_type_id': functionTableVals[1]['monitoring_app_parameter_type_id_min'],
                'value': functionTableVals[1]['min']
            },
            {
                'monitoring_app_parameter_type_id': functionTableVals[1]['monitoring_app_parameter_type_id_max'],
                'value': functionTableVals[1]['max']
            },
            {
                'monitoring_app_parameter_type_id': functionTableVals[2]['monitoring_app_parameter_type_id_function'],
                'value': functionTableVals[2]['function']
            },
            {
                'monitoring_app_parameter_type_id': functionTableVals[2]['monitoring_app_parameter_type_id_min'],
                'value': functionTableVals[2]['min']
            },
            {
                'monitoring_app_parameter_type_id': functionTableVals[2]['monitoring_app_parameter_type_id_max'],
                'value': functionTableVals[2]['max']
            },
            {
                'monitoring_app_parameter_type_id': thresholdTableVals[0]['monitoring_app_parameter_type_id_qmin'],
                'value': thresholdTableVals[0]['qmin']
            },
            {
                'monitoring_app_parameter_type_id': thresholdTableVals[0]['monitoring_app_parameter_type_id_qmax'],
                'value': thresholdTableVals[0]['qmax']
            },
            {
                'monitoring_app_parameter_type_id': thresholdTableVals[0]['monitoring_app_parameter_type_id_channel_id'],
                'value': thresholdTableVals[0]['channel_id']
            }
        ];

        console.log('monitoringAppParameterJSON ', monitoringAppParameterJSON)

        const rectangleVals: any = [];
        rectangleTableVals.map((val: any)=>{
            const json = {
                'area_monitoring_task_id': val['area_monitoring_task_id'],
                'area_type_id': val['area_type_id'],
                'area_type_name': val['area_type_name'],
                'area_type_tag': val['area_type_tag'],
                'channel_name': val['channel_name'],
                'channel_id': val['channel_id'],
                'operating_state_id': operationalValues,
                'monitoring_app_task_id': val['monitoring_app_task_id'],
                'operating_state_name': val['operating_state_name'],
                'good_x1': val['x1'],
                'good_x2': val['x2'],
                'good_y1': val['y1'],
                'good_y2': val['y2'],
            };

            rectangleVals.push(json);
        });

        const json = {
            'element_id': localStorage.getItem('componentId'),
            'monitoring_app_id': monitoringAppId,
            'monitoring_app_type_id': monitoringAppTypeId,
            'operating_state_id': operationalValues,
            'project_id': props.projectId,
            'monitoring_app_area_parameter': rectangleVals,
            'monitoring_app_parameter': monitoringAppParameterJSON
        };

        setMonitoringAppParameterAndAreaConfiguration(json).then(response => {
            if (response === 'Monitoring App Parameter And Area Configuration Edited.') {
                props.onHide();
                showMessageOnSuccess(t('success'), t('conditionMonitoring:doppelfilter_configSuccess'));
            }
            else {
                showMessageOnError(t('error'), 'Error');
            }

        });

    };
    const setGraphPolynoms = (data: any) => {
        if (data[0]['min'] && data[0]['max'] !== null) {
            const values : any = [];
            for (let i=Number(data[0]['min']); i <= Number(data[0]['max']); i++) {
                try {
                    const calculate = new Formula(data[0]['function']);
                    values.push(calculate.evaluate({x: i}));
                }
                catch (e) {
                    showMessageOnError(t('error'), t('conditionMonitoring:formula_error'));
                }
            }
            if (data[0]['min'] > 0) {
                for (let i= 0; i < Number(data[0]['min']); i++) {
                    values.unshift(null);
                }
            }
            setForderhoheValues(values);
            setRectangleCount(0);
        }
        if (data[1]['min'] && data[1]['max'] !== null) {
            const values : any = [];
            for (let i=Number(data[1]['min']); i <= Number(data[1]['max']); i++) {
                try {
                    const calculate = new Formula(data[1]['function']);
                    values.push(calculate.evaluate({x: i}));
                }
                catch (e) {
                    showMessageOnError(t('error'), t('conditionMonitoring:formula_error'));
                }
            }
            if (data[1]['min'] > 0) {
                for (let i= 0; i < Number(data[1]['min']); i++) {
                    values.unshift(null);
                }
            }
            // setWirkungsgradValues(values);
            setLeistungsbedarfValues(values);
            setRectangleCount(1);

            // updateGraphXAxis();
        }
        if (data[2]['min'] && data[2]['max'] !== null) {
            const values : any = [];
            for (let i=Number(data[2]['min']); i <= Number(data[2]['max']); i++) {
                try {
                    const calculate = new Formula(data[2]['function']);
                    values.push(calculate.evaluate({x: i}));
                }
                catch (e) {
                    showMessageOnError(t('error'), t('conditionMonitoring:formula_error'));
                }
            }
            if (data[2]['min'] > 0) {
                for (let i= 0; i < Number(data[2]['min']); i++) {
                    values.unshift(null);
                }
            }
            setWirkungsgradValues(values);
            setRectangleCount(3);
            // updateGraphXAxis();
        }

    };

    const updateGraphClouds = async (name: string, value: any)=>{
        let startDate;
        let endDate;
        if (name === 'startTime') {
            startDate = value;
            endDate = endTime;
        }
        else if (name === 'endTime') {
            startDate = startTime;
            endDate = value;
        }

        const updateStartDate = moment(startDate).tz('Europe/Berlin').format().split('+');
        const updateEndDate = moment(endDate).tz('Europe/Berlin').format().split('+');
        const channelX = thresholdTableVals[0]['channel_id'];
        const forderhoheChannel = rectangleTableVals[0]['channel_id'];
        const leistungsbedarfChannel = rectangleTableVals[1]['channel_id'];
        const wirkungsgradChannel = rectangleTableVals[2]['channel_id'];
        
        if (channelX !== null && forderhoheChannel !== null) {
            const cmCorrelatedValuesResponse1 = await getCMCorrelatedValues(props.projectId, channelX, forderhoheChannel, updateStartDate[0] + 'Z', updateEndDate[0] + 'Z', '0 hour');
            setGraphForderhohe(cmCorrelatedValuesResponse1);
        }
        if (channelX !== null && leistungsbedarfChannel !== null) {
            const cmCorrelatedValuesResponse2 = await getCMCorrelatedValues(props.projectId, channelX, leistungsbedarfChannel, updateStartDate[0] + 'Z', updateEndDate[0] + 'Z', '0 hour');
            setGraphLeistungsbedarf(cmCorrelatedValuesResponse2);
        }
        if (channelX !== null && wirkungsgradChannel !== null) {
            const cmCorrelatedValuesResponse3 = await getCMCorrelatedValues(props.projectId, channelX, wirkungsgradChannel, updateStartDate[0] + 'Z', updateEndDate[0] + 'Z', '0 hour');
            setGraphWirkungsgrad(cmCorrelatedValuesResponse3);
        }
    };
    const apiCall = async ()=>{
        const operatingStatesResponse = await getOperatingStates(props.projectId);
        const valueArr = operatingStatesResponse.map((val: any) => ({
            label: val['operating_state_name'],
            value: val['operating_state_id']
        }));
        setDropdownOptions(valueArr);

        const monitoringAppAreaResponse = await getMonitoringAppAreaConfiguration(props.projectId, monitoringAppId, monitoringAppTypeId);
        setOperationalValues(monitoringAppAreaResponse['operating_state_id']);
        setRectangleTableVals(monitoringAppAreaResponse['responseArr']);
        const forderhoheChannel = monitoringAppAreaResponse['responseArr'][0]['channel_id'];
        const leistungsbedarfChannel = monitoringAppAreaResponse['responseArr'][1]['channel_id'];
        const wirkungsgradChannel = monitoringAppAreaResponse['responseArr'][2]['channel_id'];

        const monitoringAppParameterResponse = await getMonitoringAppParameter(props.projectId, monitoringAppId);
        setFunctionTableVals(monitoringAppParameterResponse['polynomfunctions']);
        setThresholdTableVals(monitoringAppParameterResponse['thresholds']);
        const channelX = monitoringAppParameterResponse['thresholds'][0]['channel_id'];

        const max1 = monitoringAppParameterResponse['polynomfunctions'][0]['max'];
        const max2 = monitoringAppParameterResponse['polynomfunctions'][1]['max'];
        const max3 = monitoringAppParameterResponse['polynomfunctions'][2]['max'];
        const maxNumber = Math.max(max1, max2, max3);
        setGraphMax(maxNumber);

        const min = monitoringAppParameterResponse['thresholds'][0]['qmin'] === '' ? null : monitoringAppParameterResponse['thresholds'][0]['qmin'];
        const max = monitoringAppParameterResponse['thresholds'][0]['qmax'] === '' ? null : monitoringAppParameterResponse['thresholds'][0]['qmax'];
        setMinThreshold(min);
        setMaxThreshold(max);
        setGraphPolynoms(monitoringAppParameterResponse['polynomfunctions']);


        if (channelX !== null && forderhoheChannel !== null) {
            const cmCorrelatedValuesResponse = await getCMCorrelatedValues(props.projectId, channelX, forderhoheChannel, '2023-08-01T23:59:00Z', endTime, '0 hour');
            setGraphForderhohe(cmCorrelatedValuesResponse);
        }
        if (channelX !== null && leistungsbedarfChannel !== null) {
            const cmCorrelatedValuesResponse = await getCMCorrelatedValues(props.projectId, channelX, leistungsbedarfChannel, '2023-08-01T23:59:00Z', endTime, '0 hour');
            setGraphLeistungsbedarf(cmCorrelatedValuesResponse);
        }
        if (channelX !== null && wirkungsgradChannel !== null) {
            const cmCorrelatedValuesResponse = await getCMCorrelatedValues(props.projectId, channelX, wirkungsgradChannel, '2023-08-01T23:59:00Z', endTime, '0 hour');
            setGraphWirkungsgrad(cmCorrelatedValuesResponse);
        }

    };

    const graphConf = useMemo(()=>{
        return( <GraphConfiguration
            forderhoheFunction={forderhoheValues}
            wirkungsgradFunction={wirkungsgradValues}
            leistungsbedarfFunction={leistungsbedarfValues}
            graphMax={graphMax}
            minThreshold={minThreshold}
            maxThreshold={maxThreshold}
            getFordValues={(val) => {
                rectangleTableVals[0]['x1'] = +val['x1'];
                rectangleTableVals[0]['x2'] = +val['x2'];
                rectangleTableVals[0]['y1'] = +val['y1'];
                rectangleTableVals[0]['y2'] = +val['y2'];
                setRectangleTableVals(JSON.parse(JSON.stringify(rectangleTableVals)));
            }}
            getWirkValues={(val) => {
                rectangleTableVals[1]['x1'] = +val['x1'];
                rectangleTableVals[1]['x2'] = +val['x2'];
                rectangleTableVals[1]['y1'] = +val['y1'];
                rectangleTableVals[1]['y2'] = +val['y2'];
                setRectangleTableVals(JSON.parse(JSON.stringify(rectangleTableVals)));
            }}
            rects={rectangleTableVals.map((e:any) => {
                return {
                    x1:e.x1,
                    x2:e.x2,
                    y1:e.y1,
                    y2:e.y2
                };
            })}
            updateRects={(x:any)=>{
                x.map((e:any, index:number) => {
                    rectangleTableVals[index].x1 = e.x1;
                    rectangleTableVals[index].x2 = e.x2;
                    rectangleTableVals[index].y1 = e.y1;
                    rectangleTableVals[index].y2 = e.y2;
                });
                setRectangleTableVals(JSON.parse(JSON.stringify(rectangleTableVals)));
            }}
            getLeistValues={(val)=>{
                rectangleTableVals[2]['x1'] = +val['x1'];
                rectangleTableVals[2]['x2'] = +val['x2'];
                rectangleTableVals[2]['y1'] = +val['y1'];
                rectangleTableVals[2]['y2'] = +val['y2'];
                setRectangleTableVals(JSON.parse(JSON.stringify(rectangleTableVals)));
            }}
            rectangleCount={rectangleCount}
            setRectangleCount={setRectangleCount}
            graphForderhohe={graphForderhohe}
            graphLeistungsbedarf={graphLeistungsbedarf}
            graphWirkungsgrad={graphWirkungsgrad}
        />);
    }, [forderhoheValues, wirkungsgradValues, leistungsbedarfValues, graphMax, minThreshold, maxThreshold, rectangleCount, graphForderhohe, graphLeistungsbedarf, graphWirkungsgrad])
    useEffect(()=>{
        apiCall();
    },[]);

    return (<>
        <CustomDialog header={t('conditionMonitoring:energy_efficiency_configuration')} onCancel={()=> props.onHide()} visible={props.modal} maximized={true} showFooter={true} formName={'energyEffForm'}>

            <div className="grid justify-content-center">
                <div className="col-4">
                    <div className="card plot" style={{height: '98%'}}>
                        <div className="flex justify-content-center mb-2">
                            <label htmlFor="range" className="time-select m-3 mb-2">
                                {t('conditionMonitoring:from')}
                            </label>
                            <Calendar
                                className="pl-2"
                                value={new Date(startTime)}
                                onChange={(e: any) => {
                                    setStartTime(e.target.value);
                                    updateGraphClouds('startTime', e.target.value);
                                }}
                                showTime
                                dateFormat="dd.mm.yy"
                                hideOnDateTimeSelect
                                maxDate={new Date()}
                                // maxDate={endTime || new Date('01.01.30000')}
                            />
                            <label htmlFor="range" className="time-select m-3 mb-2">
                                {t('conditionMonitoring:to')}
                            </label>
                            <Calendar
                                className="pl-2"
                                value={new Date(endTime)}
                                onChange={(e:any) => {
                                    setEndTime(e.target.value);
                                    updateGraphClouds('endTime', e.target.value);
                                }}
                                showTime
                                dateFormat="dd.mm.yy"
                                hideOnDateTimeSelect
                                minDate={new Date(startTime)}
                                // minDate={startTime || new Date('01.01.01')}
                            />
                        </div>
                        {graphConf}
                    </div>
                </div>

                <div className='col-8'>
                    <form id={'energyEffForm'} onSubmit={(e) => {
                        e.preventDefault();
                        const element: any = e.target;
                        if (element.id !== 'energyEffForm') return;
                        saveData();
                        return;
                    }}>
                        <div className='card'>
                            <div className='grid'>
                                <div className="col-6 md:col-6">
                                    <Input
                                        edit={true}
                                        placeholder={t('conditionMonitoring:operational_status')}
                                        label={t('conditionMonitoring:operational_status')}
                                        name={'operational_status'}
                                        dropdownOptions={dropdownOptions}
                                        value={operationalValues}
                                        type={'dropdown'}
                                        onChange={
                                            (e)=>{
                                                setOperationalValues(e.value);
                                            }
                                        }
                                        validationRules={{
                                            required: false,
                                        }}
                                    />
                                </div>
                                {/* <div className="col-6 md:col-6">*/}
                                <Button
                                    icon='pi pi-trash'
                                    style={{color: 'red', transform: 'translateY(45px)'}}
                                    className="p-button-rounded p-button-secondary p-button-text"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setOperationalValues(null);
                                    }}
                                />
                            </div>

                            <h5>{t('conditionMonitoring:threshold_function')}</h5>
                            <CustomDataTable id={'thresholdTable'} columns={columns2} editable={false} sortable={false} value={thresholdTableVals} hideSearch={true}/>

                            <h5>{t('conditionMonitoring:configuration_polynom_function')}</h5>
                            <CustomDataTable id={'functionTable'} columns={columns1} editable={false} sortable={false} value={functionTableVals} hideSearch={true}/>

                            <h5>{t('conditionMonitoring:rectangle_configuration')} <Button
                                icon='pi pi-trash'
                                style={{color: 'red', transform: 'translateY(9px)'}}
                                className="p-button-rounded p-button-secondary p-button-text"
                                onClick={(e) => {
                                    e.preventDefault();
                                    CustomConfirmDialog({
                                        message: t('conditionMonitoring:rect_remove_text'),
                                        header: t('confirmation'),
                                        translation: t,
                                        onConfirm: () => {
                                            handleRemoveRectangles();
                                        }
                                    });

                                }}
                            /></h5>
                            <CustomDataTable className='mb-2' id={'rectangleTable'} columns={columns3} editable={false} sortable={false} value={rectangleTableVals} hideSearch={true}/>

                            {measurementDialogue && <CreateConfiguration projectId={props.projectId} modal={measurementDialogue}
                                onHide={()=>{
                                    setMeasurementDialogue(false); setRowValue([]);}}
                                formType={formType} rowValue={rowValue} channelName={channelName} details={rectangleTableVals}
                                onReturn={(value: any)=>{
                                    if (tableName === 'thresholdTable') {
                                        thresholdTableVals.map((val: any) =>{
                                            val['channel_name'] = value['unit'] !== null ? value['channel_name'] + ' [' + value['unit'] + ']' : value['channel_name'];
                                            val['channel_id'] = value['channel_id'];

                                            return;
                                        });
                                    }
                                    else if (tableName === 'rectangleTable') {
                                        rectangleTableVals.map((val: any) =>{
                                            if (val['number'] === value['number']) {
                                                val['channel_name'] = value['unit'] !== null ? value['channel_name'] + ' [' + value['unit'] + ']' : value['channel_name'];
                                                val['channel_id'] = value['channel_id'];
                                            }
                                            return;
                                        });
                                    }

                                    setMeasurementDialogue(false);
                                }} />}

                        </div>
                    </form>
                </div>

            </div>


        </CustomDialog>
    </>);
};

export default EnergyEfficiencyConfiguration;