import React, {useEffect, useState} from 'react';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {useTranslation} from 'react-i18next';
import {
    Channel, deleteMeasuringDevice, deleteSensor,
    getMeasuringDevices,
    getMeasuringDeviceTypes,
    getMeasuringManager,
    MeasuringDevice,
    Sensor
} from '../functions/Metrology';
import {checkPermission, checkProjectType, getGroupedItems, ProjectType} from '../../functions/functionLibrary';
import {permissions} from '../../config/permissions';
import {SensorOverview} from './SensorOverview';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {filterTriStateCheckBox} from '../global/filter';
import {getLocation, Location} from '../functions/Global';
import {faPencil} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';

type Props = {
    project: any,
    reload?: boolean,
    setReload?: (reload: boolean) => void
    reloadSensors?: boolean,
    setReloadSensors?: (reload: boolean) => void
    reloadChannels?: boolean,
    setReloadChannels?: (reload: boolean) => void
    setSelectedMeasuringDevices?: (data: any[]) => void
    selectedMeasuringDevices?: any[],
    editMeasuringDevice?: boolean,
    setEditChannel?: (data: boolean) => void,
    setEditMeasuringDevice?: (data: boolean) => void,
    setSelectedLocations: (data: Location[]) => void,
    setEditLocation: (data: boolean) => void,
    setEditSensor?: (data: boolean) => void,
    setSelectedSensors?: (data: Sensor[]) => void
    selectedSensors?: Sensor[],
    setSelectedChannels?: (data: Channel[]) => void
    selectedChannels?: Channel[],
    measuringManagerId?: string,
    setEditMeasuringManager?: (data: boolean) => void,
    setSelectedMeasuringManagers?: (data: any[]) => void
    showHeader: boolean
}
export const MeasuringDeviceOverview = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    const [devices, setDevices] = useState<MeasuringDevice[]>([]);
    const [selectedDevices, setSelectedDevices] = useState<MeasuringDevice[]>([]);

    // arrays for filter dropdowns
    const [measuringDeviceTypes, setMeasuringDeviceTypes] = useState<any[]>([]);
    const [measuringManagers, setMeasuringManagers] = useState<any[]>([]);
    const [locations, setLocations] = useState<any[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const _setMeasuringDevices = () => {
        const tmplist: any = [];
        getMeasuringDevices(props.project.id).then(result => {
            for (let i = 0; i < result.length; i++) {
                const measuringDevice = {...result[i]};
                delete measuringDevice.expandableElement;
                // Füge die Unterkomponente nur hinzu, wenn das Messgerät auch Sensoren hat
                if (result[i].has_sensors)
                    result[i]['expandableElement'] = (
                        <div className={'card'}>
                            <SensorOverview
                                key={'so'+i}
                                measuringDeviceId={result[i].measuring_device_id}
                                project={props.project}
                                showHeader={true}
                                setEditSensor={props.setEditSensor}
                                selectedSensors={props.selectedSensors}
                                setSelectedSensors={props.setSelectedSensors}
                                reload={props.reloadSensors}
                                setReload={props.setReloadSensors}
                                setEditChannel={props.setEditChannel}
                                selectedChannels={props.selectedChannels}
                                setSelectedChannels={props.setSelectedChannels}
                                reloadChannels={props.reloadChannels}
                                setReloadChannels={props.setReloadChannels}
                            />
                        </div>
                    );
                if (props.measuringManagerId === result[i].measuring_manager_id)
                    tmplist.push(result[i]);
            }
            if (props.measuringManagerId) {
                setDevices(tmplist);
                setDataLoaded(true);
                // filter dropdown values
                setMeasuringDeviceTypes(getGroupedItems(result, 'measuring_device_type_id', 'measuring_device_type_name'));
                setMeasuringManagers(getGroupedItems(tmplist, 'measuring_manager_id', 'measuring_manager_name'));
                setLocations(getGroupedItems(tmplist, 'location_id', 'location_name'));
            } else {
                setDevices(result);
                setDataLoaded(true);
                // filter dropdown values
                setMeasuringDeviceTypes(getGroupedItems(result, 'measuring_device_type_id', 'measuring_device_type_name'));
                setMeasuringManagers(getGroupedItems(result, 'measuring_manager_id', 'measuring_manager_name'));
                setLocations(getGroupedItems(result, 'location_id', 'location_name'));
            }
        });
    };

    useEffect(() => {
        _setMeasuringDevices();
    }, []);

    useEffect(() => {
        // console.log('reload devices: ', props.reload);
        // Lade neu, z.B. wenn Elemente gelöscht wurden
        if (props.reload) {
            _setMeasuringDevices();
            if (props.setReload) props.setReload(false);
        }
    }, [props.reload]);


    useEffect(() => {
        if (props.selectedMeasuringDevices) setSelectedDevices(props.selectedMeasuringDevices);
    }, [props.selectedMeasuringDevices]);

    const filterCheckboxIsVisible = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), t('visible'), t('invisible'));
    };

    const columns: ColumnObject[] = [
        {
            field: 'is_visible', header: t('visible'), style: {maxWidth: '120px'},
            filter: true, filterElement: filterCheckboxIsVisible
        },{
            field: 'measuring_device_id', header: t('metrologyManagement:attributes.measuringDeviceId'),
            filter: false, display: false
        },{
            field: 'measuring_device_name', header: t('name'),
            filter: true, filterField: 'measuring_device_name'
        },{
            field: 'description', header: t('description'),
            filter: false, display: false
        },{
            field: 'location_id', header: t('locations:attributes.locationId'),
            filter: false, display: false
        },{
            field: 'location_name', header: t('location'),
            filter: true, filterField: 'location_name',
            filterDropdownValues: locations,
            body: checkPermission(permissions.editMetrology) ? (device: any)=>{
                return device.location_id ? <u className='cell-function' onClick={(e) => {
                    e.stopPropagation();
                    getLocation(props.project.id, device.location_id).then((location) => {
                        props.setSelectedLocations([location]);
                        props.setEditLocation(true);
                    });
                }
                }><FontAwesomeIcon icon={faPencil} className='ml-2'/>&nbsp;{device.location_name}</u>
                    : null;
            } : null
        },{
            field: 'measuring_device_type_id', header: t('typeId'), style: {maxWidth: '100px'},
            filter: false, display: false
        },{
            field: 'measuring_device_type_name', header: t('type'),
            filter: true, filterField: 'measuring_device_type_id',
            filterDropdownValues: measuringDeviceTypes
        },{
            field: 'manager', header: t('metrologyManagement:attributes.measuringDeviceManager'),
            filter: true, filterField: 'manager',
            filterDropdownValues: [
                {value: 'portmaster', name: 'Portmaster'},
                {value: 'messmanager', name: 'Messmanager'}
            ]
        }
    ];

    // Zeige die Spalte nur in ConditionMonitoring-Projekten an (und natürlich bei MonitoringEverything)
    if (checkProjectType(ProjectType.ConditionMonitoring)) {
        columns.push({
            field: 'measuring_manager_id', header: t('metrologyManagement:attributes.measuringManagerId'),
            filter: false, display: false
        },{
            field: 'measuring_manager_name', header: t('metrologyManagement:attributes.measuringManager'),
            filter: true, filterField: 'measuring_manager_id',
            filterDropdownValues: measuringManagers,
            body: checkPermission(permissions.editMetrology) ? (device: MeasuringDevice)=>{
                return device.measuring_manager_id ? <u className='cell-function' onClick={(e) => {
                    e.stopPropagation();
                    getMeasuringManager(props.project.id, device.measuring_manager_id).then((measuringManager) => {
                        if (props.setSelectedMeasuringManagers) props.setSelectedMeasuringManagers([measuringManager]);
                        if (props.setEditMeasuringManager) props.setEditMeasuringManager(true);
                    });
                }
                }><FontAwesomeIcon icon={faPencil} className='ml-2'/>&nbsp;{device.measuring_manager_name}</u>
                    : null;
            } : null
        });
    }

    const filters = {
        'is_visible': {
            operator: FilterOperator.AND,
            constraints: [{value: true, matchMode: FilterMatchMode.EQUALS}]
        },
        'measuring_device_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'measuring_manager_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'measuring_device_type_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'manager': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'location_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        }
    };

    return (
        <>
            <CustomDataTable
                key={'measuringDevices'}
                id='measuringDevices'
                selectionMode={(props.measuringManagerId) ? 'single' : 'multiple'}
                expandable={true}
                columns={columns}
                editable={checkPermission(permissions.editMetrology)}
                sortable={true}
                displayColumnFilter={true}
                value={devices}
                paginator={devices.length > 100}
                selection={selectedDevices}
                onSelectionChange={(e: any) => {
                    if (props.setSelectedMeasuringDevices) props.setSelectedMeasuringDevices(e.value);
                }}
                onRemoveClick={(data: MeasuringDevice) => {
                    // eslint-disable-next-line new-cap
                    CustomConfirmDialog({
                        message: t('metrologyManagement:dialogs.deleteMeasuringDeviceDialog.message'),
                        header: t('confirmation'),
                        translation: t,
                        onConfirm: () => {
                            if(data.measuring_device_id)
                                deleteMeasuringDevice(props.project.id, [data.measuring_device_id]).then(result => {
                                    if (result.error) {
                                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                                    } else {
                                        _setMeasuringDevices();
                                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.measuringDeviceDeleted'));
                                    }
                                });
                        }
                    });
                }}
                onEditClick={(data: any) => {
                    if (props.setSelectedMeasuringDevices) props.setSelectedMeasuringDevices([data]);
                    setSelectedDevices([data]);
                    if (props.setEditMeasuringDevice) props.setEditMeasuringDevice(true);
                }}
                ableToDelete={checkPermission(permissions.editMetrology)}
                ableToUpdate={checkPermission(permissions.editMetrology)}
                filters={filters}
                headerTitle={props.showHeader ? t('metrologyManagement:global.measuringDevices') : ''}
                dataloaded={dataLoaded}
                showExportCSV={true}
                showClearFiltersButton={true}
            />
        </>
    );
};
