/*
 * ChannelSelectTableDialog.tsx
 * Author: lnappenfeld
 * Date: 25.07.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CustomDialog, {CustomDialogHeaderType} from '../global/CustomDialog';
import {
    Channel,
    getChannels,
} from '../functions/Metrology';
import {getTimezones, Nullable} from '../functions/Global';
import {checkProjectType, getGroupedItems, ProjectType} from '../../functions/functionLibrary';
import {filterTriStateCheckBox} from '../global/filter';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {FilterMatchMode, FilterOperator} from 'primereact/api';

export enum ChannelSelectTableDialogType {
    Default = 1,
    SpectralData = 2,
    OperatingData = 3
}

type Props = {
    type: ChannelSelectTableDialogType,
    projectId: string,
    selectedChannel: Nullable<Channel>,
    onFinish: (channel: Channel) => void,
    visible: boolean,
    setVisible: (data: boolean) => void,
}

export const ChannelSelectTableDialog = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    const [channels, setChannels] = useState<Channel[] | []>([]);

    const [selectedChannel, setSelectedChannel] = useState<Nullable<Channel>>(props.selectedChannel);

    // arrays for filter dropdowns
    const [channelTypes, setChannelTypes] = useState<any[]>([]);
    const [timezones, setTimezones] = useState<any[]>([]);
    const [measuringDevices, setMeasuringDevices] = useState<any[]>([]);
    const [sensors, setSensors] = useState<any[]>([]);
    const [locations, setLocations] = useState<any[]>([]);
    const [units, setUnits] = useState<any[]>([]);
    const [dataLink, setDataLink] = useState<any[]>([]);
    const [ports, setPorts] = useState<any[]>([]);
    const [operatingStates, setOperatingStates] = useState<any[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const _setTimezones = () => {
        getTimezones().then(result => {
            const items: any = [];
            result.forEach((key: any) => {
                items.push({
                    value: key.label,
                    name: key.value,
                });
            });
            setTimezones(items);
        });
    };


    const _setChannels = () => {
        let type: Nullable<string> = null;
        if (props.type === ChannelSelectTableDialogType.SpectralData) {
            type = 'MonitoringTaskForm';
        } else if (props.type === ChannelSelectTableDialogType.OperatingData) {
            type = 'CharacteristicMonitoringTaskForm';
        }
        getChannels(props.projectId, [], null, type).then((result: any) => {
            setChannels(result);
            setDataLoaded(true);

            // filter dropdown values
            setMeasuringDevices(getGroupedItems(result, 'measuring_device_id', 'measuring_device_name'));
            setSensors(getGroupedItems(result, 'sensor_id', 'sensor_name'));
            setLocations(getGroupedItems(result, 'location_id', 'location_name'));
            setUnits(getGroupedItems(result, 'unit', 'unit'));
            setPorts(getGroupedItems(result, 'port', 'port'));
            setDataLink(getGroupedItems(result, 'datalink_id', 'datalink_name'));
            setChannelTypes(getGroupedItems(result, 'channel_type_id', 'channel_type_name'));
            setOperatingStates(getGroupedItems(result, 'operating_state_id', 'operating_state_name'));
        });
    };

    useEffect(() => {
        _setTimezones();
        _setChannels();
    }, []);


    const filterCheckboxIsEnabled = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), t('enabled'), t('disabled'));
    };

    const channelsColumns: ColumnObject[] = [
        {
            field: 'is_active', header: t('active'), style: {maxWidth: '100px'},
            filter: true, filterElement: filterCheckboxIsEnabled,
        }, {
            field: 'channel_name', header: t('name'),
            filter: true, filterField: 'channel_name',
        }, {
            field: 'unit', header: t('unit'), style: {maxWidth: 105},
            filter: true, filterField: 'unit', filterDropdownValues: units
        }, {
            field: 'channel_type_name', header: t('type'),
            filter: true, filterField: 'channel_type_id', filterDropdownValues: channelTypes
        }, {
            field: 'channel_id', header: t('channel')+ ' ' +t('ID'), style: {minWidth: '330px'},
            filter: false, display: false
        }, {
            field: 'sensor_name', header: t('sensor'),
            filter: true, filterField: 'sensor_id', filterDropdownValues: sensors
        }, {
            field: 'measuring_device_name', header: t('measuring_device'),
            filter: true, filterField: 'measuring_device_id', filterDropdownValues: measuringDevices,
            display: checkProjectType(ProjectType.ConditionMonitoring)
        }, {
            field: 'location_name', header: t('location'),
            filter: true, filterField: 'location_id', filterDropdownValues: locations,
            display: checkProjectType(ProjectType.GeoMonitoring)
        }, {
            field: 'timezone', header: t('timezone'),
            filter: true, filterField: 'timezone', filterDropdownValues: timezones,
            display: false
        }, {
            field: 'operating_state_name', header: t('metrologyManagement:attributes.operatingState'),
            filter: true, filterField: 'operating_state_id', filterDropdownValues: operatingStates
        }, {
            field: 'datalink_name', header: t('metrologyManagement:attributes.dataLink'),
            filter: true, filterField: 'datalink_id', filterDropdownValues: dataLink,
            display: false
        }, {
            field: 'port', header: t('metrologyManagement:attributes.port'),
            display:checkProjectType(ProjectType.GeoMonitoring),
            filter: true, filterField: 'port', filterDropdownValues: ports
        }
    ];

    const filters = {
        'is_active': {
            value: null,
            matchMode: FilterMatchMode.EQUALS
        },
        'channel_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'unit': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'location_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'sensor_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'measuring_device_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'channel_type_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'timezone': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'datalink_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'port': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'operating_state_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (selectedChannel)
            props.onFinish(selectedChannel);
    };

    return (
        <CustomDialog
            header={t('alarmManagement:dialogs.selectChannelDialog.header')}
            headerType={CustomDialogHeaderType.Select}
            formName={'formSelectChannel'}
            onCancel={() => {props.setVisible(false);}}
            onHide={() => {props.setVisible(false);}}
            visible={props.visible}
            submitButtonDisabled={selectedChannel === null}
            maximized={true}
        >
            <form id='formSelectChannel' onSubmit={handleSubmit}>
                <div className={'card'}>
                    <CustomDataTable
                        key={'channels'}
                        id='channels'
                        selectionMode={'single'}
                        expandable={false}
                        columns={channelsColumns}
                        displayColumnFilter={true}
                        editable={false}
                        sortable={true}
                        value={channels}
                        selection={selectedChannel}
                        onSelectionChange={(e: any) => {
                            setSelectedChannel(e.value);
                        }}
                        headerTitle={t('channels')}
                        filters={filters}
                        dataloaded={dataLoaded}
                    />
                </div>
            </form>
        </CustomDialog>
    );
};
