/*
 * CustomChip.tsx
 * Author: fwunderlich
 * Date: 02.08.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import './CustomChip.scss';
import {InputText} from 'primereact/inputtext';


type Props = {
    label: string,
    editable: boolean,
    startInEditMode: boolean,
    deletable: boolean,
    deleted: boolean,
    onCreate?: (value: string) => void,
    onChange?: (value: string) => void,
    onDelete?: () => void,
    onUndo?: () => void,
    className?: string,
    icon?: string,
}


const CustomChip = (props: Props): JSX.Element => {

    const [origLabel, setOrigLabel] = useState<string>('');
    const [label, setLabel] = useState<string>('');
    const [edit, setEdit] = useState<boolean>(false);
    const [saveEdit, setSaveEdit] = useState<boolean>(false);
    const [startEdit, setStartEdit] = useState<boolean>(false);

    useEffect(() => {
        setOrigLabel(props.label);
        setLabel(props.label);

        if (props.startInEditMode) {
            setEdit(true);
            setStartEdit(true);
        }
    }, []);

    useEffect(() => {
        if (saveEdit) {
            if (startEdit) {
                setEdit(false);
                setSaveEdit(false);
                setOrigLabel(label);
                if (props.onCreate) {
                    props.onCreate(label);
                }
                setStartEdit(false);
            } else {
                setEdit(false);
                setSaveEdit(false);
                if (props.onChange) {
                    props.onChange(label);
                }
            }
        }
    }, [saveEdit]);

    return (
        <div className={props.className + ' chip-firstElement' + (props.deletable ? ' deletable' : '')}>
            {props.icon && <i className={props.icon + ' chip-icon'}/>}
            {!edit && <label
                className={'chip-label ' + (props.deleted ? ' deleted' : '') + (origLabel !== label ? ' underline' : '') + (!props.deleted && props.editable ? ' editable' : '')}
                onClick={() => {
                    if (!props.deleted && props.editable) {
                        setEdit(true);
                    }
                }}>{label}</label>}
            {edit && <InputText autoFocus={true} type="text" value={label} onInput={(e: any) => {
                setLabel(e.target.value);
            }} onBlur={() => {
                setSaveEdit(true);
            }}/>}
            {props.deletable &&
                <div className="chip-iconButtons"
                    onClick={() => {
                        if (props.deleted && props.onUndo) {
                            props.onUndo();
                        } else if (props.onDelete) {
                            props.onDelete();
                        }
                        // CustomConfirmDialog({
                        //     translation: t,
                        //     header: t('deleteConfirmHeader'),
                        //     message: props.label + " " + t('confirmDelete'),
                        //     onConfirm: () => {
                        //         props.onDelete()
                        //     },
                        //     onCancel: () => {
                        //     }
                        // });
                    }}><i
                        className={'chip-iconButton delete pi pi-times-circle' + (!edit && !props.deleted ? ' visible' : '')}/>
                    <i className={'chip-iconButton undo pi pi-undo' + (props.deleted ? ' visible' : '')}/></div>}
        </div>
    );
}
;

export default CustomChip;
