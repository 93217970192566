import React, {useEffect, useState} from 'react';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {useTranslation} from 'react-i18next';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {
    Channel,
    deleteChannel,
    getChannels,
    getChannelTypes,
    getMeasuringDevice,
    getSensor,
    MeasuringDevice,
    Sensor
} from '../functions/Metrology';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {getLocation, getTimezones, Location} from '../functions/Global';
import {checkPermission, checkProjectType, getGroupedItems, ProjectType} from '../../functions/functionLibrary';
import {permissions} from '../../config/permissions';
import {filterTriStateCheckBox} from '../global/filter';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChartLine, faPencil} from '@fortawesome/free-solid-svg-icons';
import {HighchartsImageDialog} from '../data/HighchartsImageDialog';


type Props = {
    projectId: string,
    sensorId?: string,
    showHeader: boolean,
    reload?: boolean,
    setReload?: (reload: boolean) => void
    setSelectedChannels?: (data: Channel[]) => void
    selectedChannels?: Channel[],
    setEditChannel?: (data: boolean) => void,
    setSelectedLocations?: (data: Location[]) => void,
    setEditLocation?: (data: boolean) => void,
    setSelectedSensors?: (data: Sensor[]) => void,
    setEditSensor?: (data: boolean) => void,
    setSelectedMeasuringDevice?: (data: MeasuringDevice[]) => void,
    setEditMeasuringDevice?: (data: boolean) => void,
}
export const ChannelsOverview = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    const [channels, setChannels] = useState<Channel[] | []>([]);
    // Lokale States für die Untertabelle einer expandedRow, muss so gelöst werden, weil die props an das
    // 'expandedElement' nur einmal initial übergeben werden können, aber keine Aktualisierten props
    const [selectedChannels, setSelectedChannels] = useState<Channel[]>([]);
    const [showChart, setShowChart] = useState<boolean>(false);

    // arrays for filter dropdowns
    const [channelTypes, setChannelTypes] = useState<any[]>([]);
    const [timezones, setTimezones] = useState<any[]>([]);
    const [sensors, setSensors] = useState<any[]>([]);
    const [measuringDevices, setMeasuringDevices] = useState<any[]>([]);
    const [locations, setLocations] = useState<any[]>([]);
    const [projectGroups, setProjectGroups] = useState([]);
    const [units, setUnits] = useState<any[]>([]);
    const [dataLink, setDataLink] = useState<any[]>([]);
    const [ports, setPorts] = useState<any[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const _setTimezones = () => {
        getTimezones().then(result => {
            const items: any = [];
            result.forEach((key: any) => {
                items.push({
                    value: key.label,
                    name: key.value,
                });
            });
            setTimezones(items);
        });
    };


    const _setChannels = () => {
        if (props.sensorId) {
            getChannels(props.projectId, [], props.sensorId).then(result => {
                // filter dropdown values
                setChannelTypes(getGroupedItems(result, 'channel_type_id', 'channel_type_name'));
                setSensors(getGroupedItems(result, 'sensor_id', 'sensor_name'));
                setMeasuringDevices(getGroupedItems(result, 'measuring_device_id', 'measuring_device_name'));
                setLocations(getGroupedItems(result, 'location_id', 'location_name'));
                setProjectGroups(getGroupedItems(result, 'project_group_id', 'project_group_name'));
                setUnits(getGroupedItems(result, 'unit', 'unit'));
                setPorts(getGroupedItems(result, 'port', 'port'));
                setDataLink(getGroupedItems(result, 'datalink_id', 'datalink_name'));
                setChannels(result);
                setDataLoaded(true);
            });
        } else {
            getChannels(props.projectId).then(result => {
                // filter dropdown values
                setChannelTypes(getGroupedItems(result, 'channel_type_id', 'channel_type_name'));
                setSensors(getGroupedItems(result, 'sensor_id', 'sensor_name'));
                setMeasuringDevices(getGroupedItems(result, 'measuring_device_id', 'measuring_device_name'));
                setLocations(getGroupedItems(result, 'location_id', 'location_name'));
                setProjectGroups(getGroupedItems(result, 'project_group_id', 'project_group_name'));
                setUnits(getGroupedItems(result, 'unit', 'unit'));
                setPorts(getGroupedItems(result, 'port', 'port'));
                setDataLink(getGroupedItems(result, 'datalink_id', 'datalink_name'));
                setChannels(result);
                setDataLoaded(true);
            });
        }

    };

    useEffect(() => {
        _setTimezones();
        _setChannels();
    }, []);

    useEffect(() => {
        // Lade neu, wenn Elemente gelöscht wurden
        if (props.reload) {
            //showWaitAnimation();
            _setChannels();
            if (props.setReload) props.setReload(false);
        }
    }, [props.reload]);

    useEffect(() => {
        if (props.selectedChannels) setSelectedChannels(props.selectedChannels);
    }, [props.selectedChannels]);

    const filterCheckboxIsEnabled = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), t('enabled'), t('disabled'));
    };

    const filterCheckboxStatus = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), 'OK', 'TIMEOUT');
    };

    const filterCheckboxIsLogical = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), t('metrologyManagement:attributes.isLogical'), t('metrologyManagement:attributes.isNotLogical'));
    };

    const channelsColumns: ColumnObject[] = [
        {
            field: 'is_active', header: t('active'), style: {maxWidth: '100px'},
            filter: true, filterElement: filterCheckboxIsEnabled,
        }, {
            field: 'status', header: t('status'), style: {maxWidth: '100px'},
            body: (channel: any) =>{
                return channel.status === 'TRUE' ?  'OK': 'TIMEOUT';
            },
            filter: true, filterElement: filterCheckboxStatus
        }, {
            field: 'is_logical', header: t('metrologyManagement:attributes.isLogical'), style: {maxWidth: '130px'},
            filter: true, filterElement: filterCheckboxIsLogical, display: false
        }, {
            field: 'channel_id', header: t('channel') + ' ' + '(ID)',
            filter: false, display: false
        }, {
            field: 'channel_name', header: t('name'), filter: true, filterField: 'channel_name',
            body: (device: any) => {
                return (<u className='cell-function' onClick={(e) => {
                    e.stopPropagation();
                    setSelectedChannels([device]);
                    setShowChart(true);
                }}>
                    <FontAwesomeIcon icon={faChartLine} className='ml-2'/>&nbsp;{device.channel_name}</u>);
            }
        }, {
            field: 'description', header: t('description'),
            filter: true, filterField: 'description', display: false
        },{
            field: 'unit', header: t('unit'), style: {maxWidth: 105},
            filter: true, filterField: 'unit', filterDropdownValues: units
        }, {
            field: 'channel_type_id', header: t('typeId'), style: {maxWidth: '100px'},
            filter: false, display: false
        }, {
            field: 'channel_type_name', header: t('type'),
            filter: true, filterField: 'channel_type_id', filterDropdownValues: channelTypes
        }, {
            field: 'sensor_id', header: t('metrologyManagement:attributes.sensorId'),
            filter: false, display: false
        }, {
            field: 'sensor_name', header: t('sensor'),
            filter: true, filterField: 'sensor_id', filterDropdownValues: sensors,
            body: (checkPermission(permissions.editMetrology) && props.setEditSensor) ? (device: any) => {
                return device.sensor_id ? (<u className='cell-function' onClick={(e) => {
                    e.stopPropagation();
                    getSensor(props.projectId, device.sensor_id).then((sensor) => {
                        if (props.setSelectedSensors) props.setSelectedSensors([sensor]);
                        if (props.setEditSensor) props.setEditSensor(true);
                    });
                }}>
                    <FontAwesomeIcon icon={faPencil} className='ml-2'/>&nbsp;{device.sensor_name}</u>)
                    : null;
            } : null
        }, {
            field: 'measuring_device_id', header: t('metrologyManagement:attributes.measuringDeviceId'),
            filter: false, display: false
        }, {
            field: 'measuring_device_name', header: t('measuring_device'),
            filter: true, filterField: 'measuring_device_id', filterDropdownValues: measuringDevices,
            display: checkProjectType(ProjectType.ConditionMonitoring) && !props.sensorId,
            body: (checkPermission(permissions.editMetrology) && props.setEditMeasuringDevice) ? (device: any) => {
                return device.measuring_device_id ? (<u className='cell-function' onClick={(e) => {
                    e.stopPropagation();
                    getMeasuringDevice(props.projectId, device.measuring_device_id).then((dev) => {
                        if (props.setSelectedMeasuringDevice) props.setSelectedMeasuringDevice([dev]);
                        if (props.setEditMeasuringDevice) props.setEditMeasuringDevice(true);
                    });
                }}>
                    <FontAwesomeIcon icon={faPencil} className='ml-2'/>&nbsp;{device.measuring_device_name}</u>)
                    : null;
            } : null
        }, {
            field: 'location_id', header: t('locations:attributes.locationId'),
            filter: false, display: false
        },{
            field: 'location_name', header: t('location'),
            filter: true, filterField: 'location_id', filterDropdownValues: locations,
            display: checkProjectType(ProjectType.GeoMonitoring),
            body: (checkPermission(permissions.editMetrology) && props.setEditLocation) ? (device: any) => {
                return device.location_id ? (<u className='cell-function' onClick={(e) => {
                    e.stopPropagation();
                    getLocation(props.projectId, device.location_id).then((location) => {
                        if (props.setSelectedLocations) props.setSelectedLocations([location]);
                        if (props.setEditLocation) props.setEditLocation(true);
                    });
                }}>
                    <FontAwesomeIcon icon={faPencil} className='ml-2'/>&nbsp;{device.location_name}</u>) : null;
            } : null
        }, {
            field: 'project_group_id', header: t('projectManagement:attributes.projectGroupId'),
            filter: false, display: false
        },{
            field: 'project_group_name', header: t('projectManagement:attributes.projectGroup'),
            filter: true, filterField: 'project_group_id', filterDropdownValues: projectGroups, display: false
        }, {
            field: 'timezone', header: t('timezone'),
            filter: true, filterField: 'timezone', filterDropdownValues: timezones, display: false
        }, {
            field: 'max_allowed_data_age_in_hours', header: t('metrologyManagement:attributes.maxAllowedDataAgeInHours'),
            filter: true, filterField: 'max_allowed_data_age_in_hours', display: false
        },{
            field: 'chart_round', header: t('metrologyManagement:attributes.chartRound'),
            filter: true, filterField: 'chart_round', display: false
        },{
            field: 'chart_min',
            header: checkProjectType(ProjectType.ConditionMonitoring)
                ? t('metrologyManagement:attributes.chartMinAbsolute')
                : t('metrologyManagement:attributes.chartMinRelative'),
            filter: true, filterField: 'chart_min', display: false
        },{
            field: 'chart_max',
            header: checkProjectType(ProjectType.ConditionMonitoring)
                ? t('metrologyManagement:attributes.chartMaxAbsolute')
                : t('metrologyManagement:attributes.chartMaxRelative'),
            filter: true, filterField: 'chart_max', display: false
        },{
            field: 'datalink_name', header: t('metrologyManagement:attributes.dataLink'),
            filter: true, filterField: 'datalink_id', filterDropdownValues: dataLink,
            display: false
        }, {
            field: 'port', header: t('metrologyManagement:attributes.port'),
            display:checkProjectType(ProjectType.GeoMonitoring),
            filter: true, filterField: 'port', filterDropdownValues: ports
        }
    ];

    const filters = {
        'is_active': {
            operator: FilterOperator.AND,
            constraints: [{value: true, matchMode: FilterMatchMode.EQUALS}]
        },
        'status': {
            operator: FilterOperator.AND,
            constraints: [{value: true, matchMode: FilterMatchMode.EQUALS}]
        },
        'is_logical': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
        },
        'channel_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'description': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'unit': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'location_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'project_group_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'sensor_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'measuring_device_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'channel_type_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'timezone': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'max_allowed_data_age_in_hours': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'chart_round': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'chart_min': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'chart_max': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'datalink_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'port': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
    };
    return (<>
        <CustomDataTable
            key={'channels'}
            id='channels'
            selectionMode={(props.sensorId) ? 'single' : 'multiple'}
            expandable={false}
            columns={channelsColumns}
            displayColumnFilter={true}
            editable={checkPermission(permissions.editMetrology)}
            sortable={true}
            value={channels}
            selection={selectedChannels}
            onSelectionChange={(e: any) => {
                if (props.setSelectedChannels) props.setSelectedChannels(e.value);
            }}
            onRemoveClick={(data: Channel) => {
                // eslint-disable-next-line new-cap
                CustomConfirmDialog({
                    message: t('metrologyManagement:dialogs.deleteChannelDialog.message'),
                    header: t('confirmation'),
                    translation: t,
                    onConfirm: () => {
                        deleteChannel(props.projectId, [data.channel_id]).then(result => {
                            if (result.error) {
                                showMessageOnError(t('error'), t('errorMessages:' + result.error));
                            } else {
                                if (props.setSelectedChannels) props.setSelectedChannels([]);
                                if (props.setReload) props.setReload(true);
                                showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.channelDeleted'));
                            }
                        });
                    }
                });
            }}
            onEditClick={(data: Channel) => {
                if (props.setSelectedChannels) props.setSelectedChannels([data]);
                setSelectedChannels([data]);
                if (props.setEditChannel) props.setEditChannel(true);
            }}
            headerTitle={props.showHeader ? t('channels') : ''}
            ableToDelete={checkPermission(permissions.editMetrology)}
            ableToUpdate={checkPermission(permissions.editMetrology)}
            filters={filters}
            dataloaded={dataLoaded}
            showExportCSV={true}
            showClearFiltersButton={true}
        />
        { showChart && selectedChannels.length > 0 &&
            <HighchartsImageDialog
                setShowDialog={setShowChart}
                projectId={props.projectId}
                channelId={selectedChannels[0].channel_id}
            />
        }
    </>);
};
