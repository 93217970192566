import React, {useState} from 'react';
import Input from '../global/Input';
import LocationSelectList, {LocationSelectListType} from '../global/LocationSelectList';
import CustomDialog from '../global/CustomDialog';
import {Button} from 'primereact/button';
import {useTranslation} from 'react-i18next';
import DataLinkSelectList from './DataLinkSelectList';
import {measuringDeviceObject} from './measuringDevice';

type PropsDevice = {
    projectId: string,
    fields: any,
    manager: string,
    fieldIndex: string,
    setFormValues: any,
    selectedDevice: any,
    setSelectedDevice: any,
    deviceList: any,
    projectLocations: any,
    projectDataLinks: any,
    control: any,
    errors: any
}


export const WizardMDeviceEditForm = (propsDevice: PropsDevice) => {
    const {t} = useTranslation(['common']);
    const [openDialog, setOpenDialog] = useState(false);

    const onChangeFunction = (e: any, name: string, type: string) => {
        propsDevice.setFormValues(propsDevice.fieldIndex, name, e.target.value);
        if (type === 'dropdown') {
            propsDevice.setSelectedDevice(e.target.value);
        }
    };
    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return propsDevice.errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };
    const makeInput = (name: string, value: any, obj: any, edit: boolean) => {
        let options = {};
        if (obj.type === 'dropdown')
            options = {...{dropdownOptions: propsDevice.deviceList}};
        if (propsDevice.fields.measuring_device_type_id === 204
            && (name === 'protokoll' || name === 'ip' || name === 'port'
                || name === 'login' || name === 'password' || name === 'interval')) {
            options = {
                ...{
                    validationControl:propsDevice.control,
                    validationErrorMessage: getFormErrorMessage,
                    validationRules: {required: t(name) + ' ' + t('input:required')}
                }
            };
        }
        return <Input
            name={name}
            onChange={(e: any) => onChangeFunction(e, name, obj.type)}
            edit={edit}
            value={value}
            label={t(obj.label)}
            type={obj.type}
            {...options}
        />;
    };
    const mainFormFields = (<>
        <div className={'col-6'}>
            {makeInput('measuring_device_type_id',
                propsDevice.fields.measuring_device_type_id,
                measuringDeviceObject.measuring_device_type_id,
                true)}
            {makeInput('name',
                propsDevice.fields.name,
                measuringDeviceObject.name,
                true)}
        </div>
        <div className={'col-6'}>
            <LocationSelectList
                type={LocationSelectListType.MetrologyWizard}
                locations={propsDevice.projectLocations}
                value={propsDevice.fields.location_id}
                onChange={(e) => {
                    propsDevice.setFormValues(propsDevice.fieldIndex, 'location_id', e.value);
                }}
                projectId={propsDevice.projectId} label={t('location')}/>
            {
                propsDevice.manager === 'portmaster' &&
                <DataLinkSelectList
                    projectId={propsDevice.projectId}
                    label={'datalink_id'}
                    value={propsDevice.fields.datalink_id}
                    list={propsDevice.projectDataLinks}
                    onChange={(e) => {
                        propsDevice.setFormValues(propsDevice.fieldIndex, 'datalink_id', e.value);
                    }}/>
            }
        </div>
    </>);
    const extendedFormFields = (<>
        <div className={'col-6'}>
            {makeInput('description',
                propsDevice.fields.description,
                measuringDeviceObject.description,
                true)}
            {makeInput('is_visible',
                propsDevice.fields.is_visible,
                measuringDeviceObject.is_visible,
                true)}
            {makeInput('qrcode',
                propsDevice.fields.qrcode,
                measuringDeviceObject.qrcode,
                true)}
        </div>
        <div className={'col-6'}>{propsDevice.fields.measuring_device_type_id === 204 && <>
            {makeInput('protokoll',
                propsDevice.fields.protokoll,
                measuringDeviceObject.protokoll,
                true)}
            {makeInput('ip',
                propsDevice.fields.ip,
                measuringDeviceObject.ip,
                true)}
            {makeInput('port',
                propsDevice.fields.port,
                measuringDeviceObject.port,
                true)}
            {makeInput('login',
                propsDevice.fields.login,
                measuringDeviceObject.login,
                true)}
            {makeInput('password',
                propsDevice.fields.password,
                measuringDeviceObject.password,
                true)}
            {makeInput('interval',
                propsDevice.fields.interval,
                measuringDeviceObject.interval,
                true)}</>}
        </div>
    </>);
    return (
        <div className={'device card row border-1'} style={{borderColor: '#6ebd00'}}>
            <CustomDialog
                showFooter={false}
                visible={openDialog}
                header={t('Metrology:editDialog.header')}
                onCancel={() => setOpenDialog(false)}
                formName={'formMetrology'}>
                <div className={'grid'}>
                    {mainFormFields}
                    {extendedFormFields}
                </div>
            </CustomDialog>
            <div className={'grid'}>
                {mainFormFields}
                <Button type={'button'} value={'more'} label={'weitere Einstellungen'} onClick={() => {
                    setOpenDialog(true);
                }}/>
            </div>
        </div>);
};