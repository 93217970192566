import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { SubPlants } from '../../functions/SubPlantsService';
import { Nullable } from '../../../functions/Global';
import { useForm } from 'react-hook-form';
import Input from '../../../global/Input';
import { Button } from 'primereact/button';
import { createPlant, updatePlant } from '../../functions/PlantsService';
import {showMessageOnError, showMessageOnSuccess} from '../../../global/CustomToast';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../../global/CustomDialog';

type CreateFormProps = {
  projectId: string;
  plants?: Nullable<SubPlants>;
  type: string;
  mayEditPlant?: boolean;
  modal: boolean;
  plantId: string;
  hidePopup: () => void;
};
const CreateForm = (props: CreateFormProps) => {
    const { t } = useTranslation(['common']);

    const defaultValues = {
        plant_name: props.plants ? props.plants.plant_name : null,
        longitude: props.plants ? props.plants.longitude : null,
        latitude: props.plants ? props.plants.latitude : null,
        altitude: props.plants ? props.plants.altitude : null,
        sapNumber: props.plants ? props.plants.sapNumber : null,
        description: props.plants ? props.plants.description : null,
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        getValues,
        setValue,
    } = useForm<any>({ defaultValues });

    const getFormErrorMessage = (name: string) => {
        return (
            errors[name] && (
                <small className="p-error custom">{errors[name]!.message}</small>
            )
        );
    };

    const saveData = (data: any) => {
        const finalData = {
            project_id: props.projectId,
            parent_id: props.plantId,
            name: data['plant_name'],
            element_id: props.plants ? props.plants['id'] : null,
            description: data['description'],
            element_type_id: 13,
            longitude: data['longitude'] ? data['longitude'] : null,
            latitude: data['latitude'] ? data['latitude'] : null,
            altitude: data['altitude'] ? data['altitude'] : null,
            equipment_id: data['sapNumber'] ? data['sapNumber'] : null,
            element_parameter: data['sapNumber'] ? [
                {'element_parameter_type_id':37,'value':data['sapNumber']}
            ]: ''
        };
        if (props.plants && props.type === 'editForm') {
            updatePlant(finalData).then((resp: any) => {
                if (resp['error'] && resp['error'][0].includes('Ein Element mit dem Namen')) {
                    showMessageOnError(t('error'), t('conditionMonitoring:plant_exist_error'));
                }
                else if(resp['error']){
                    showMessageOnError(t('error'), t('conditionMonitoring:doppelfilter_configError'));
                }
                else {
                    showMessageOnSuccess(
                        t('conditionMonitoring:plant_updated_head'),
                        t('conditionMonitoring:plant_updated')
                    );
                    reset();
                    props.hidePopup();
                }
            });
        } else {
            createPlant(finalData).then((resp: any) => {
                if (resp['error'] && resp['error'][0].includes('Ein Element mit dem Namen')) {
                    showMessageOnError(t('error'), t('conditionMonitoring:plant_exist_error'));
                }
                else if(resp['error']){
                    showMessageOnError(t('error'), t('conditionMonitoring:doppelfilter_configError'));
                }
                else {
                    showMessageOnSuccess(
                        t('conditionMonitoring:plant_created_head'),
                        t('conditionMonitoring:plant_created')
                    );
                    reset();
                    props.hidePopup();
                }
            });
        }
    };

    return (
        <>
            <CustomDialog onHide={()=>{props.hidePopup();}} onCancel={()=>{props.hidePopup();}} visible={props.modal} header={t('conditionMonitoring:element')} showFooter={true} headerType={props.plants && props.type === 'editForm' ? 3 : 2 } formName={'plantForm'} >
                <form id="plantForm" onSubmit={handleSubmit(saveData)}>
                    <div className="grid" style={{ width: '100%' }}>
                        <div className="col-12 md:col-6">
                            <Input
                                edit={
                                    typeof props.mayEditPlant === 'boolean'
                                        ? props.mayEditPlant
                                        : true
                                }
                                label={t('name')}
                                name={'plant_name'}
                                type={'text'}
                                validationControl={control}
                                validationErrorMessage={getFormErrorMessage}
                                validationRules={{
                                    required: t('conditionMonitoring:plant_name_required'),
                                }}
                            />
                        </div>

                        <div className="col-12 md:col-6">
                            <Input
                                edit={
                                    typeof props.mayEditPlant === 'boolean'
                                        ? props.mayEditPlant
                                        : true
                                }
                                label={t('conditionMonitoring:description')}
                                name={'description'}
                                type={'text'}
                                validationControl={control}
                                validationErrorMessage={getFormErrorMessage}
                                validationRules={{ required: false }}
                            />
                        </div>

                        <div className="col-12 md:col-6">
                            <Input
                                edit={
                                    true
                                    // typeof props.mayEditPlant === 'boolean'
                                    //     ? props.mayEditPlant
                                    //     : true
                                }
                                label={t('conditionMonitoring:longitude')}
                                name={'longitude'}
                                validationControl={control}
                                validationErrorMessage={getFormErrorMessage}
                                validationRules={{ required: false }}
                                type={'number'}
                                maxFractionDigits={15}
                                onChange={(e)=>{
                                    setValue('longitude', e.value);
                                }}
                            />
                        </div>

                        <div className="col-12 md:col-6">
                            <Input
                                edit={
                                    true
                                    // typeof props.mayEditPlant === 'boolean'
                                    //     ? props.mayEditPlant
                                    //     : true
                                }
                                label={t('conditionMonitoring:latitude')}
                                name={'latitude'}
                                validationControl={control}
                                validationErrorMessage={getFormErrorMessage}
                                validationRules={{ required: false }}
                                type={'number'}
                                maxFractionDigits={15}
                                onChange={(e)=>{
                                    setValue('latitude', e.value);
                                }}
                            />
                        </div>

                        <div className="col-12 md:col-6">
                            <Input
                                edit={
                                    true
                                    // typeof props.mayEditPlant === 'boolean'
                                    //     ? props.mayEditPlant
                                    //     : true
                                }
                                label={t('conditionMonitoring:altitude')}
                                name={'altitude'}
                                validationControl={control}
                                validationErrorMessage={getFormErrorMessage}
                                validationRules={{ required: false }}
                                type={'number'}
                                maxFractionDigits={15}
                                onChange={(e)=>{
                                    setValue('altitude', e.value);
                                }}
                            />
                        </div>

                        <div className="col-12 md:col-12" >
                            <Input
                                edit={
                                    typeof props.mayEditPlant === 'boolean'
                                        ? props.mayEditPlant
                                        : true
                                }
                                label={t('conditionMonitoring:sap_equipment')}
                                name={'sapNumber'}
                                type={'text'}
                                validationControl={control}
                                validationErrorMessage={getFormErrorMessage}
                                validationRules={{ required: false }}
                            />
                        </div>
                    </div>

                </form>

            </CustomDialog>
        </>
    );
};

export default CreateForm;
