/*
 * HistoricalAlarms.tsx
 * Author: lnappenfeld
 * Date: 11.10.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */
import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import NotificationSelectList from './NotificationSelectList';
import {HistoricalAlarmsOverview} from './HistoricalAlarmsOverview';
import TimeGroupSelectList from './TimeGroupSelectList';
import TimeRangePicker from '../global/TimeRangePicker';

type Props = {
    projectId: any
}

const HistoricalAlarms = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common', 'alarms']);

    const [timeGroup, setTimeGroup] = useState<any>(null);
    const [selectedNotificationId, setSelectedNotificationId] = useState<string | null>(null);

    const [filterFrom, setFilterFrom] = useState<Date>();
    const [filterTo, setFilterTo] = useState<Date>();

    useEffect(() => {
        const today = new Date();
        const sevenDaysBefore = new Date();
        sevenDaysBefore.setDate(today.getDate() - 7);

        setFilterFrom(sevenDaysBefore);
        setFilterTo(today);
    }, []);

    return (
        <>
            <div className='card'>
                <div className='grid'>
                    <div className='col-12 md:col-3'>
                        <NotificationSelectList
                            projectId={props.projectId}
                            name={'notification_id'}
                            label={t('notification')}
                            value={selectedNotificationId}
                            setValue={setSelectedNotificationId}
                        />
                    </div>
                    <div className='col-12 md:col-6'>
                        <TimeRangePicker from={filterFrom} to={filterTo} onChange={(from, to) => {
                            setFilterFrom(from);
                            setFilterTo(to);
                        }}/>
                    </div>
                    <div className='col-12 md:col-3'>
                        <TimeGroupSelectList
                            label={t('alarmManagement:attributes.timeGroup')}
                            name={'time_group'}
                            value={timeGroup ? timeGroup.value : null}
                            onChange={(timeGroup: any) => {
                                // contains minutes
                                setTimeGroup(timeGroup);
                            }}
                            validationRules={{required: false}}
                        />
                    </div>
                </div>
            </div>
            {selectedNotificationId &&
            <HistoricalAlarmsOverview
                projectId={props.projectId}
                notificationId={selectedNotificationId}
                groupingTimeInMinutes={timeGroup ? timeGroup.minutes : null}
                from={filterFrom}
                to={filterTo}
            />}
        </>
    );
};

export default HistoricalAlarms;
