/*
 * OperatingStateConditionOverview.tsx
 * Author: lnappenfeld
 * Date: 16.06.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */
import React, {useEffect, useState} from 'react';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {useTranslation} from 'react-i18next';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {checkPermission, getGroupedItems} from '../../functions/functionLibrary';
import {permissions} from '../../config/permissions';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {
    deleteOperatingStateConditions,
    getOperatingStateConditions,
    OperatingStateCondition, operatingStateConditionBooleanOptions,
    operatingStateConditionOperatorOptions
} from '../functions/ConditionMonitoring';
import {Nullable} from '../functions/Global';
import {classNames} from 'primereact/utils';
import {Dropdown} from 'primereact/dropdown';
import {getValue} from '@testing-library/user-event/dist/utils';
import {OSCFormType} from './OperatingStateConditionForm';

type Props = {
    type: OSCFormType,
    projectId: any,
    operatingStateId: Nullable<string>,
    reload?: boolean,
    setReload?: (reload: boolean) => void
    setSelectedOperatingStateConditions?: (data: OperatingStateCondition[]) => void
    selectedOperatingStateConditions?: OperatingStateCondition[],
    setEditOperatingStateCondition?: (data: boolean) => void,

    operatingStateConditions?: OperatingStateCondition[],
    setOperatingStateConditions?: (data: OperatingStateCondition[]) => void
}
export const OperatingStateConditionOverview = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    const [operatingStateConditions, setOperatingStateConditions] = useState<OperatingStateCondition[]>(
        props.operatingStateConditions ? props.operatingStateConditions : []
    );

    // arrays for filter dropdowns
    const [channels, setChannels] = useState<any[]>([]);
    const [units, setUnits] = useState<any[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const _setOperatingStateConditions = () => {
        if (props.operatingStateId) {
            getOperatingStateConditions(props.projectId, props.operatingStateId).then(result => {
                setOperatingStateConditions(result);
                setChannels(getGroupedItems(result, 'channel_id', 'channel_name'));
                setUnits(getGroupedItems(result, 'unit', 'unit'));
                setDataLoaded(true);
            });
        }
    };

    // useEffect(() => {
    //     setOperatingStateConditions()
    // }, [props.operatingStateConditions]);

    useEffect(() => {
        console.log('reload2: ', props.reload)
    }, [props.reload]);

    useEffect(() => {
        console.log('props.operatingStateId: ', props.operatingStateId)
        _setOperatingStateConditions();
    }, []);

    useEffect(() => {
        // Lade neu, z.B. wenn Elemente gelöscht wurden
        if (props.reload) {
            _setOperatingStateConditions();
            if (props.setReload) props.setReload(false);
        }
    }, [props.reload]);

    const columns: ColumnObject[] = [{
        field: 'condition_type', header: t('metrologyManagement:attributes.conditionType'),
        body: () => {return t('metrologyManagement:attributes.withinTheBorders');}
    }, {
        field: 'op3', header: t('metrologyManagement:attributes.op3'),
        body: (operatingStateCondition: any) => {
            const obj = operatingStateConditionBooleanOptions.find(option => option.value === operatingStateCondition.op3);
            if (obj) {
                return t('metrologyManagement:attributes.' + obj.label);
            }
        }
    }, {
        field: 'value1', header: t('metrologyManagement:attributes.value1')
    }, {
        field: 'op1', header: t('metrologyManagement:attributes.op1'),
        body: (operatingStateCondition: any) => {
            const obj = operatingStateConditionOperatorOptions.find(option => option.value === operatingStateCondition.op1);
            if (obj) {
                return obj.label;
            }
            // return (<Dropdown
            //     id={'op1'}
            //     disabled={true} editable={true}
            //     options={operatingStateConditionOperatorOptions}
            //     value={operatingStateCondition.op1}
            // />);
        }
    }, {
        field: 'channel_name', header: t('channel'),
        filter: true, filterField: 'channel_id', filterDropdownValues: channels
    }, {
        field: 'unit', header: t('unit'),
        filter: true, filterField: 'unit', filterDropdownValues: units
    }, {
        field: 'op2', header: t('metrologyManagement:attributes.op2'),
        body: (operatingStateCondition: any) => {
            const obj = operatingStateConditionOperatorOptions.find(option => option.value === operatingStateCondition.op2);
            if (obj) {
                return obj.label;
            }
        }
    }, {
        field: 'value2', header: t('metrologyManagement:attributes.value2')
    }];

    const filters = {
        'condition_type': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'channel_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'unit': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        }
    };

    return (
        <>
            <CustomDataTable
                key={'operating_state_conditions'}
                id='operating_state_conditions'
                selectionMode={'single'}
                columns={columns}
                editable={checkPermission(permissions.editMetrology)}
                sortable={true}
                value={props.type === OSCFormType.NewOperatingState
                    ? (props.operatingStateConditions ? props.operatingStateConditions : [])
                    : operatingStateConditions
                }
                paginator={operatingStateConditions.length > 100}
                onRemoveClick={(data: OperatingStateCondition) => {
                    // eslint-disable-next-line new-cap
                    CustomConfirmDialog({
                        message: t('metrologyManagement:dialogs.deleteOperatingStateCondition.message'),
                        header: t('confirmation'),
                        translation: t,
                        onConfirm: () => {
                            if (props.type === OSCFormType.NewOperatingState) {
                                // Lösche die Betriebszustandsbedingung nur aus dem lokalen Speichern (useState)
                                if (props.setOperatingStateConditions) {
                                    const newArray = props.operatingStateConditions?.filter(
                                        obj => obj.operating_state_condition_id !== data.operating_state_condition_id
                                    ) as OperatingStateCondition[];
                                    props.setOperatingStateConditions(newArray);
                                }
                            } else {
                                // Lösche die Betriebszustandsbedingung in der Datenbank
                                if (data.operating_state_condition_id)
                                    deleteOperatingStateConditions(props.projectId, [data.operating_state_condition_id]).then(result => {
                                        if (result.error) {
                                            showMessageOnError(t('error'), t('errorMessages:' + result.error));
                                        } else {
                                            _setOperatingStateConditions();
                                            showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.operatingStateConditionDeleted'));
                                        }
                                    });
                            }
                        }
                    });
                }}
                onEditClick={(data: OperatingStateCondition) => {
                    if (props.setSelectedOperatingStateConditions) props.setSelectedOperatingStateConditions([data]);
                    if (props.setEditOperatingStateCondition) props.setEditOperatingStateCondition(true);
                }}
                headerTitle={t('metrologyManagement:global.operatingStateConditions')}
                ableToDelete={checkPermission(permissions.editMetrology)}
                ableToUpdate={checkPermission(permissions.editMetrology)}
                filters={filters}
                dataloaded={props.type === OSCFormType.NewOperatingState
                    ? (props.operatingStateConditions ? true: false)
                    : dataLoaded
                }
            />
        </>
    );
};
