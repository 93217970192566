/*
 * TimeGroupSelectList.tsx
 * Author: lnappenfeld
 * Date: 12.10.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState, useEffect, useRef} from 'react';
import Input from '../global/Input';

type Props = {
    label: string,
    value?: number,
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    name?: string,
    disabled?: boolean,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
}

const TimeGroupSelectList = (props: Props): JSX.Element => {

    const options = [
        {value:  '0', label: 'Gruppierung zurücksetzen', minutes: null},
        {value: '10', label: '5 Minuten', minutes: 5},
        {value: '11', label: '10 Minuten', minutes: 10},
        {value: '12', label: '15 Minuten', minutes: 15},
        {value: '13', label: '20 Minuten', minutes: 20},
        {value: '14', label: '30 Minuten', minutes: 30},
        {value: '15', label: '60 Minuten', minutes: 60},
        {value: '16', label: '90 Minuten', minutes: 90},
        {value: '17', label: '2 Stunden', minutes: 120},
        {value: '18', label: '3 Stunden', minutes: 180},
        {value: '19', label: '4 Stunden', minutes: 240},
    ];

    return (
        <Input
            name={props.name ? props.name : 'time_group'}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            edit={true}
            label={props.label}
            value={props.value}
            dropdownOptions={options}
            type={'dropdown'}
            onChange={(e: any) => {
                const option = options.filter((x: any) => {
                    return x.value === e.value;
                })[0];
                if (props.setValue) props.setValue(option);
                if (props.onChange) props.onChange(option);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );

};

export default TimeGroupSelectList;

