import React, {useEffect, useRef, useState} from 'react';
import {ElementTree} from './ElementTree';
import {getElementTree} from '../functions/Metrology';
import {ContextMenu} from 'primereact/contextmenu';

type Props = {
    projectId:string,
    elements?: any,
    setSelectedElementId?: (elementId: string) => void
}
export const ElementOverview = (props: Props): JSX.Element => {
    const [loader, setLoader]= useState(true);

    let gTree:any = null;

    const cm = useRef(null);
    const [data, setData] = useState(props.elements ? props.elements : null);
    const [selectedNode, setSelectedNode] = useState('');
    const [copyNode, setCopyNode] = useState('');

    useEffect(() =>{
        if (!props.elements) {
            getElementTree(props.projectId).then((data) =>{
                setData(data.root);
            });
        }
    },[]);

    useEffect(() =>{
        if (data !== null) {
            const treeProps:any = {
                data: data,
                nodeOnClick: (id:string, info:any) =>{
                    console.log(id);
                    if (props.setSelectedElementId) props.setSelectedElementId(id);

                    /* props.setComponentId(id);
                    props.setFormSettings(info); */
                },
                nodeOnChangePosition: (parentId:string, newListOrder:any) => {
                    console.log('parentId', parentId, 'newListOrder', newListOrder);
                },
                contextMenu: cm,
                setSelectedNode: setSelectedNode,
                setLoader: setLoader
            };

            ElementTree (treeProps, gTree);
            setLoader(false);
        }
    }, [data]);

    const commands = {
        copy: () =>{
            console.log('copy', selectedNode);
            setCopyNode(selectedNode);
        },
        paste: () =>{
            console.log('paste', copyNode, '=>', selectedNode);
        }
    };
    const menuModel = [
        {label: 'Copy', icon: 'pi pi-fw pi-copy', disabled:false, command: () => commands.copy()},
        {label: 'Paste', icon: 'pi pi-fw pi-plus', disabled:true, command: () => commands.paste()},
        {separator: true},
        {label: 'Delete', icon: 'pi pi-fw pi-trash', disabled:false, command: () => {
                console.log('delete', selectedNode);
            }}
    ];

    useEffect(() => {
        console.log('setSelectedNode:', selectedNode);
    },[selectedNode]);

    return (<>
        <div ref={el => {gTree = el; }} />
        <ContextMenu model={menuModel} ref={cm}></ContextMenu>
    </>);
};