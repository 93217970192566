/*
 * SamplingRateSelectList.tsx
 * Author: lnappenfeld
 * Date: 07.06.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import Input from '../../global/Input';
import {useTranslation} from 'react-i18next';

type Data = {
    value: number,
    label: string
}

type Props = {
    channelTypeId: number, // [200, 202, 203, 205]
    signalTypeId: number, // [0,1,2]
    channelAddress: number, // [1-16]
    label: string,
    value?: number | null,
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    name?: string,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    disabled?: boolean,
    showClear?: boolean,
}

const options1 = [
    {value: 25600, label: '25600 Hz'},
    {value: 5120, label: '5120 Hz'},
    {value: 512, label: '512 Hz'},
    {value: 51.2, label: '51.2 Hz'}
] as Data[];

const options2 = [
    {value: 5120, label: '5120 Hz'},
    {value: 512, label: '512 Hz'},
    {value: 51.2, label: '51.2 Hz'}
] as Data[];

const options3 = [
    {value: 5120, label: '5120 Hz (Bandpass 10 Hz - 1000Hz)'},
    {value: 512, label: '512 Hz (Bandpass 1 Hz - 100Hz)'},
    {value: 51.2, label: '51.2 Hz (Bandpass 0.1 Hz - 10Hz)'}
] as Data[];

const options4 = [
    {value: 1280, label: '1280 Hz'},
    {value: 128, label: '128 Hz'},
    {value: 12.8, label: '12.8 Hz'}
] as Data[];

const optionsError = [
    {value: -1, label: 'Interner Fehler (Falsche Parameter)'},
] as Data[];

export const SamplingRateSelectList = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [list, setList] = useState<Data[]>([]);

    const CHANNEL_ADDRESS_MIN = 0;
    const CHANNEL_ADDRESS_BETWEEN = 7.5;
    const CHANNEL_ADDRESS_MAX = 15;

    useEffect(() => {
        console.log('channelTypeId : ', props.channelTypeId);
        console.log('signalTypeId  : ', props.signalTypeId);
        console.log('channelAddress: ', props.channelAddress);
        switch (props.channelTypeId) {
            case 200: // BEDA
                if (props.channelAddress >= CHANNEL_ADDRESS_MIN && props.channelAddress < CHANNEL_ADDRESS_BETWEEN) {
                    switch (props.signalTypeId) {
                        case 0:
                            setList(options2);
                            break;
                        case 1:
                        case 2:
                            setList(options3);
                            break;
                    }
                } else if (props.channelAddress > CHANNEL_ADDRESS_BETWEEN && props.channelAddress <= CHANNEL_ADDRESS_MAX) {
                    setList(options4);
                } else {
                    setList(optionsError);
                }
                break;
            case 202: // ZEIT
                if (props.channelAddress >= CHANNEL_ADDRESS_MIN && props.channelAddress < CHANNEL_ADDRESS_BETWEEN) {
                    switch (props.signalTypeId) {
                        case 0:
                            setList(options1);
                            break;
                        case 1:
                        case 2:
                            setList(options3);
                            break;
                    }
                } else if (props.channelAddress > CHANNEL_ADDRESS_BETWEEN && props.channelAddress <= CHANNEL_ADDRESS_MAX) {
                    setList(options4);
                } else {
                    setList(optionsError);
                }
                break;
            case 203: // HKBD
                if (props.channelAddress >= CHANNEL_ADDRESS_MIN && props.channelAddress < CHANNEL_ADDRESS_BETWEEN) {
                    switch (props.signalTypeId) {
                        case 0:
                            setList(options1);
                            break;
                        case 1:
                        case 2:
                            setList(options3);
                            break;
                    }
                } else {
                    setList(optionsError);
                }
                break;

            case 205: // HKZT
                setList(options2);
                break;
            default:
                setList(optionsError);
                break;
        }
    }, [props.channelTypeId, props.signalTypeId, props.channelAddress]);

    return (
        <Input
            name={props.name ? props.name : 'sampling_rate'}
            edit={true}
            showClear={typeof props.showClear === 'boolean' ? props.showClear : false}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            tooltip={t('metrologyManagement:tooltips.samplingRate')}
            label={props.label}
            value={props.value}
            dropdownOptions={list} type={'dropdown'}
            onChange={(e: any) => {
                if (props.setValue) props.setValue(e.value);
                if (props.onChange) props.onChange(e);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};
