/*
 * CMValueTypeSelectList.tsx
 * Author: lnappenfeld
 * Date: 03.05.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import Input from '../global/Input';

type Props = {
    label: string,
    value?: number,
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    name?: string,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    edit?: boolean
    disabled?: boolean,
}
const CMValueTypeSelectList = (props: Props): JSX.Element => {

    const list = [
        {value: '0', label: 'Einzel-Datensätze'},
        {value: '1', label: 'Tagesmittel-Datensätze'},
        {value: '2', label: 'Laufzeitgemittelte-Datensätze'}
    ];

    return (
        <Input
            name={props.name ? props.name : 'value_type'}
            edit={typeof props.edit === 'boolean' ? props.edit : true}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            label={props.label}
            value={props.value}
            dropdownOptions={list} type={'dropdown'}
            onChange={(e: any) => {
                if (props.setValue) props.setValue(e.value);
                if (props.onChange) props.onChange(e);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};
export default CMValueTypeSelectList;
