import React, {useState, useEffect, useRef} from 'react';
import CustomDataTable from '../global/CustomDataTable';
import {useTranslation} from 'react-i18next';
import date from '../../functions/date';
import {checkPermission, getGroupedItems} from '../../functions/functionLibrary';
import {permissions} from '../../config/permissions';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChartLine} from '@fortawesome/free-solid-svg-icons';
import {HighchartsImageDialog} from '../data/HighchartsImageDialog';
import {Channel} from '../functions/Metrology';

type Props = {
    alarms: any,
    project: any
}

const Alarms = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);
    const [alarmColumns, setAlarmColumns] = useState([]);
    const [selectedChannels, setSelectedChannels] = useState<Channel[]>([]);
    const [showChart, setShowChart] = useState<boolean>(false);
    const projectGroups =  getGroupedItems(props.alarms, 'project_group_name', 'project_group_name');
    const locations = getGroupedItems(props.alarms, 'location', 'location');
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const statusBodyTemplate = (row: any) => {
        return <span className={'alarm_' + row.status.toLowerCase()}> {row.status} </span>;
    };

    const dateBodyTemplate = (row: any) => {
        return <>{row.last_notification ? row.last_notification === 'no data' ? row.last_notification : date.getLocaleString(new Date(new Date(row.last_notification))) : date.getLocaleString(new Date(new Date(row.timestamp)))}</>;
    };

    useEffect(() => {

        setAlarmColumns(makeTableColumns(props.alarms));
    }, []);

    const makeTableColumns = (data: any) => {
        const _cols: any = [];
        let first = true;
        for (const alarm of data) {
            if (first) {
                first = false;
                for (const key in alarm) {
                    if (key === 'status') {
                        _cols.push({
                            field: key,
                            header: t('alarmManagement:attributes.' + key),
                            body: statusBodyTemplate
                        });
                    } else if (key === 'timestamp' || key === 'last_notification') {
                        _cols.push({
                            field: key,
                            header: t('alarmManagement:attributes.' + key),
                            // body: dateBodyTemplate,
                            type: 4
                        });
                    } else if (key === 'location') {
                        _cols.push({
                            field: key,
                            header: t('alarmManagement:attributes.' + key),
                            filter: true,
                            filterField: 'location',
                            filterDropdownValues: locations,
                        });
                    } else if (key === 'project_group_name') {
                        if (props.project.project_groups.length > 1)
                            _cols.push({
                                field: key,
                                header: t('alarmManagement:attributes.' + key),
                                filter: true,
                                filterField: 'project_group_name',
                                filterDropdownValues: projectGroups,
                            });
                    } else if (key === 'channel_name') {
                        _cols.push({
                            field: key,
                            header: t('alarmManagement:attributes.' + key),
                            display: true,
                            body: (device: any) => {
                                return (<u className='cell-function' onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedChannels([device]);
                                    setShowChart(true);
                                }}>
                                    <FontAwesomeIcon icon={faChartLine} className='ml-2'/>&nbsp;{device.channel_name}</u>);
                            }
                        });
                    } else if (key === 'notification_name'
                        ||   key === 'unit'
                        || key === 'value' || key === 'last_notification'
                        || key === 'minimum' || key === 'maximum') {
                        _cols.push({
                            field: key,
                            header: t('alarmManagement:attributes.' + key),
                            display: true
                        });
                    } else if (key !== 'project_id' && key !== 'channel_id') {
                        _cols.push({
                            field: key,
                            header: t('alarmManagement:attributes.' + key),
                            display: false
                        });
                    }
                }
            }
        }
        return _cols;
    };
    const filters = {
        'channel_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'location': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'project_group_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'sensor_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'channel_type_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        }
    };
    return (
        <div className="project-overview card w-auto h-auto m-0">
            {checkPermission(permissions.seeAlarms) &&
                <CustomDataTable
                    key='alarms'
                    id='alarms'
                    displayColumnFilter={true}
                    editable={false}
                    sortable={true}
                    value={props.alarms}
                    columns={alarmColumns}
                    filters={filters}
                    dataloaded={true}
                />
            }
            { showChart && selectedChannels.length > 0 &&
                <HighchartsImageDialog
                setShowDialog={setShowChart}
                projectId={props.project.id}
                channelId={selectedChannels[0].channel_id}/>
            }
        </div>
    );
};

export default Alarms;
