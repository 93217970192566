import React, {useEffect, useState} from 'react';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {filterTriStateCheckBox} from '../global/filter';
import {
    getNotifications,
    getNotificationTypes,
    Notification
} from '../functions/Notification';
import {useTranslation} from 'react-i18next';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {checkPermission} from '../../functions/functionLibrary';
import {permissions} from '../../config/permissions';
import {NotificationRangesOverview} from './NotificationRangesOverview';
import {NotificationsActionsMapOverview} from './NotificationsActionsMapOverview';
import CustomDialog, {CustomDialogHeaderType} from '../global/CustomDialog';
import NotificationEditForm from './NotificationEditForm';
import NotificationWizard from './NotificationWizard';


type Props = {
    projectId: string,
    selectedNotifications: Notification[],
    setSelectedNotifications: (data: Notification[]) => void,
    reloadNotifications: boolean,
    setReloadNotifications:(data:boolean)=>void,
    editNotification?: boolean,
    setEditNotification?:(data:boolean)=>void,
    notificationWizard?: boolean,
    setNotificationWizard?:(data:boolean)=>void,
}
export const NotificationsOverview = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [notificationTypes, setNotificationTypes] = useState<any[]>([]);

    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [notificationWizardSubmitDisabled, setNotificationWizardSubmitDisabled] = useState<boolean>(true);

    useEffect(() => {
        getNotificationTypes(props.projectId).then(result => {
            setNotificationTypes(result);
        });
        props.setSelectedNotifications([]);
    }, []);

    useEffect(() => {
        if (props.setReloadNotifications) {
            props.setReloadNotifications(false);
            _setNotifications();
        }
        return () => setNotifications([]);
    }, [props.reloadNotifications]);

    const _setNotifications = () => {
        getNotifications(props.projectId).then(result => {
            for (let i = 0; i < result.length; i++) {
                if (result[i].additional_settings)
                    result[i]['expandableElement'] = (<>{getNotificationSettingsOverview(result[i])}</>);
            }
            setNotifications(result);
            setDataLoaded(true);
        });
    };

    const getNotificationSettingsOverview = (selectedNotification: Notification) => {
        switch (selectedNotification?.notification_type_id) {
            case 1:
            case 6:
            case 7:
                return (<>
                    <div className={'card'}>
                        <h6>{t('alarmManagement:attributes.coincidence_type_name')}: {selectedNotification?.additional_settings.coincidence_type_name}</h6>
                        {(selectedNotification.additional_settings.coincidence_type_id === 2) &&
                            <h6>{t('alarmManagement:attributes.coincidence_level')}: {selectedNotification?.additional_settings.coincidence_level}</h6>}
                        <NotificationRangesOverview
                            projectId={props.projectId}
                            notificationId={selectedNotification.id}
                            headerTitle={t('alarmManagement:global.notificationRanges')}
                        />
                    </div>
                    <div className={'card'}>
                        {<NotificationsActionsMapOverview
                            projectId={props.projectId}
                            notificationId={selectedNotification.id}
                            headerTitle={t('alarmManagement:global.notificationActions')}
                        />}
                    </div>
                </>);
            case 3:
                return (<>
                    <h6>{t('metrologyManagement:element')}: {selectedNotification?.additional_settings.element_id}</h6>
                    <h6>{t('alarmManagement:attributes.is_spectral_monitoring_active')}: {selectedNotification?.additional_settings.is_spectral_monitoring_active}</h6>
                    <h6>{t('alarmManagement:attributes.number_cmi_alarms')}: {selectedNotification?.additional_settings.number_cmi_alarms}</h6>
                    <h6>{t('alarmManagement:attributes.threshold_cmi')}: {selectedNotification?.additional_settings.threshold_cmi}</h6>
                    <h6>{t('alarmManagement:attributes.value_type')}: {selectedNotification?.additional_settings.value_type}</h6>
                    <h6>{t('alarmManagement:attributes.number_beda_alarms')}: {selectedNotification?.additional_settings.number_beda_alarms}</h6>
                    <h6>{t('alarmManagement:attributes.report_pre_alarm')}: {selectedNotification?.additional_settings.report_pre_alarm}</h6>
                </>);
            default:
                return (<></>);
        }
    };
    const filterCheckboxIsEnabled = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), t('enabled'), t('disabled'));
    };
    const filterCheckboxIsTimed = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), t('alarmManagement:attributes.timed'), t('alarmManagement:attributes.alarmControlled'));
    };

    const columns: ColumnObject[] = [
        {
            field: 'is_enabled', header: t('status'), filter: true,
            filterElement: filterCheckboxIsEnabled, style: {maxWidth: '100px'}
        }, {
            field: 'id', header: t('alarmManagement:attributes.notificationId'),
            filter: false, display: false
        }, {
            field: 'name', header: t('name'),
            body: (n:any) =>{
                return <span title={n.description}>{n.name}</span>;
            },
            filter: true, filterField: 'name'
        }, {
            field: 'notification_type_id', header: t('alarmManagement:attributes.alarmTypeId'), style: {maxWidth: '100px'},
            filter: false, display: false
        }, {
            field: 'notification_type_name', header: t('alarmManagement:attributes.alarmType'), filter: true,
            filterField: 'notification_type_id',
            filterDropdownValues: notificationTypes
        }, {
            field: 'is_timed', header: t('alarmManagement:attributes.execution_type'), filter: true,
            filterElement: filterCheckboxIsTimed,
            body: (rowData: any) => {
                return rowData['is_timed']
                    ? t('alarmManagement:attributes.timed')
                    : t('alarmManagement:attributes.alarmControlled');
            }
        }, {
            field: 'last_timestamp', header: t('alarmManagement:attributes.last_notification'), type: 4
        }, {
            field: 'last_send_timestamp', header: t('alarmManagement:attributes.last_send_notification'), type: 4
        }, {
            field: 'max_allowed_data_age_in_minutes', header: t('alarmManagement:attributes.max_allowed_data_age_in_minutes'),
            filter: false, display: false
        }
    ];

    const filters = {
        'is_enabled': {
            operator: FilterOperator.AND,
            constraints: [{value: true, matchMode: FilterMatchMode.EQUALS}]
        },
        'is_timed': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
        },
        'name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'notification_type_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        }
    };

    return (<>
        <CustomDataTable
            id='notifications'
            columns={columns}
            editable={true}
            sortable={true}
            displayColumnFilter={true}
            value={notifications}
            onEditClick={(data)=>{
                props.setSelectedNotifications([data]);
                if (props.setEditNotification) props.setEditNotification(true);
            }}
            filters={filters}
            ableToDelete={false}//checkPermission(permissions.editNotification)}
            ableToUpdate={checkPermission(permissions.editNotification)}
            expandable={true}
            selectionMode={'multiple'}
            selection={props.selectedNotifications}
            onSelectionChange={(e: any) => {
                props.setSelectedNotifications(e.value);
            }}
            dataloaded={dataLoaded}
            showExportCSV={true}
            showClearFiltersButton={true}
        />

        {
            <CustomDialog
                header={props.selectedNotifications.length > 0 ? t('alarmManagement:dialogs.editNotificationDialog.header') : t('alarmManagement:dialogs.createNotificationDialog.header')}
                headerType={props.selectedNotifications.length > 0 ? CustomDialogHeaderType.Update : CustomDialogHeaderType.Create}
                formName={'formDialog'}
                maximized={false}
                visible={(props.editNotification) ? props.editNotification : false}
                onHide={() => {
                    if (props.setEditNotification) props.setEditNotification(false);
                }}
                onCancel={() => {
                    if (props.setEditNotification) props.setEditNotification(false);
                }}
            >
                <NotificationEditForm
                    projectId={props.projectId}
                    onFinished={() => {
                        if (props.setEditNotification) props.setEditNotification(false);
                        if (props.setReloadNotifications) props.setReloadNotifications(true);
                    }}
                    notificationData={props.selectedNotifications.length > 0 ? props.selectedNotifications[0] : null}
                />
            </CustomDialog>
        }
        {
            <CustomDialog
                header={t('alarmManagement:dialogs.notificationWizard.header')}
                headerType={CustomDialogHeaderType.Create}
                formName={'formDialog'}
                maximized={true}
                visible={(props.notificationWizard) ? props.notificationWizard : false}
                onHide={() => {
                    if (props.setNotificationWizard) props.setNotificationWizard(false);
                }}
                onCancel={() => {
                    if (props.setNotificationWizard) props.setNotificationWizard(false);
                }}
                submitButtonDisabled={notificationWizardSubmitDisabled}
            >
                <NotificationWizard
                    projectId={props.projectId}
                    onFinished={(success: boolean) => {
                        if (success && props.setNotificationWizard) {
                            props.setReloadNotifications(true);
                            props.setNotificationWizard(false);
                        }
                    }}
                    setSubmitButtonDisabled={setNotificationWizardSubmitDisabled}
                />
            </CustomDialog>
        }
    </>);
};
