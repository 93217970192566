import React, {FunctionComponent,useContext, useEffect, useRef, useState} from 'react';

import MapContext from '../map/MapContext';
import Select from 'ol/interaction/Select.js';
import {getDPI, mapScale, transformCoordinateArray} from '../../../services/SpatialService';
import * as turf from '@turf/turf';
import {isNotUndefined} from '../../../functions/functionsOpenlayer';
import { Coordinate } from 'openlayers';
import {LineString} from 'ol/geom';
import {Feature} from 'ol';

type props = unknown;
const PoiClusterInteraction: FunctionComponent<props> = () => {
    // @ts-ignore
    const {map} = useContext(MapContext);
    const [overlayLayer, setOverlayLayer] = useState({});
    const [overlaySource, setOverlaySource] = useState({
        clear() {
        },
        addFeature(feature:any) {
        }
    });
    const refOverlayLayer = useRef(overlayLayer);
    const refOverlaySource = useRef(overlaySource);

    const getOverlayLayer = () => {
        const layers = [...map.getLayers().getArray()];
        layers.forEach(function (layer) {
            const layerId = layer.get('id');

            if (layerId && layerId.toLowerCase() === 'overlaylayer') {
                setOverlayLayer(layer);
                setOverlaySource(layer.getSource());
                refOverlayLayer.current = layer;
                refOverlaySource.current = layer.getSource();
            }
        });
        return true;
    };


    const styleFunction = (f: any) => {
        if (f.getId()) {
            if (f.get('layerKind') === 'poiLayer') {
                // nix
            } else {
                // hier noch andere Stylefunktionen einfügen, wenn weitere layer kommen
            }
        }
    };

    const createCircleFeature = (coords: any, lenght: number) => {
        const startCoords = transformCoordinateArray([coords], 'EPSG:3857', 'EPSG:4326', true);
        const radius = mapScale(getDPI(), map);
        const options = {steps: lenght > 4 ? lenght : 4, units: 'meters', properties: {foo: 'bar'}};
        // @ts-ignore
        const circle = turf.circle(startCoords[0], radius, options);
        const circleFeatures = turf.explode(circle).features;
        delete (circleFeatures[0]);
        return circleFeatures;
    };

    const createOverlayFeatures = (featureMain: { clone: () => any; }, featureSub: turf.helpers.Feature<turf.helpers.Point, turf.helpers.Properties>, mainCoordinates: Coordinate, index: string)=>
    {
        const newFeature = featureMain.clone();
        const id = 'overlay_' + index;
        newFeature.setId(newFeature.get('id'));
        newFeature.set('overlay', true);
        const transformedCoords = transformCoordinateArray([featureSub.geometry.coordinates], 'EPSG:4326', 'EPSG:3857', false);
        newFeature.getGeometry().setCoordinates([transformedCoords[0][1], transformedCoords[0][0]]);
        refOverlaySource.current.addFeature(newFeature);

        const line = new LineString([
            newFeature.getGeometry().getCoordinates(),
            mainCoordinates
        ]);

        const lineId = id + '_line';
        const newLine = new Feature({
            geometry: line,
            id: lineId
        });

        refOverlaySource.current.addFeature(newLine);
    };

    const selectFunction=(evt:any)=> {

        const overlaySource = refOverlaySource.current;

        if (evt.selected[0] && evt.selected[0].values_.features) {

            overlaySource.clear();

            const features = evt.selected[0].values_.features;
            const mainCoordinates = evt.selected[0].getGeometry().getCoordinates();

            if (features.length === 1) {
                map.getView().setZoom(19);
                map.getView().setCenter(mainCoordinates);
            } else {
                const circleFeatures = createCircleFeature(mainCoordinates, features.length);
                mainCoordinates.reverse();

                for (const index  in circleFeatures) {
                    const feature=circleFeatures[index];
                    if (feature && isNotUndefined(features[parseInt(index) - 1]))
                    {
                        createOverlayFeatures(features[parseInt(index) - 1], feature, mainCoordinates, index);
                    }
                }
            }

        }

    };

    const getInteraction = () => {
        
        getOverlayLayer();// DrawingLayer
        
        const select = new Select({
            // @ts-ignore
            filter: function (feature: any|undefined, layer: any|undefined) {
                if (layer && layer.get('id') === 'poiLayer' && (Object.keys(feature.values_)).indexOf('features') >= 0) {
                    return true;
                }
            },
            condition: function (evt) {
                return evt.type === 'singleclick';
            },
            style: styleFunction

        });

        select.on('select', function (evt) {
            if (evt.selected[0]) {
                const id = evt.selected[0].getId();

                if (id && evt.selected[0].get('layerKind') === 'poiLayer') {
                } else {
                    selectFunction(evt);
                }

            }
        });
        return select;
    };

    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        const selectInteraction = getInteraction();

        map.addInteraction(selectInteraction);
        map.getViewport().addEventListener('contextmenu', function()
        {
            if (refOverlayLayer.current)
            {
                refOverlaySource.current.clear();
            }
        });

        return () => map.interactions.remove(selectInteraction);
    }, [map]);

    return null;
};

export default PoiClusterInteraction;