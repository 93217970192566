import {FunctionComponent, useContext, useEffect} from 'react';
import MapContext from '../map/MapContext';
import {Vector as VectorSource} from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import {Icon, Stroke, Style} from 'ol/style';
import {
    getColorCode,
    getFeatureImage,
    scaleImage,
    styleMeasuringpointsText
} from '../../../services/MeasuringPointService';
import {addLayerToCounterArray, setLayerArray} from "../../../redux/GeomonitoringSlice";
import {useAppDispatch} from "../../../redux/hooks";


type overlayProps = {projectId:number};
const OverlayLayer: FunctionComponent<overlayProps> = (projectId) => {
    // @ts-ignore
    const {map} = useContext(MapContext);
    const dispatch = useAppDispatch();
    const simplePointStyle= (feature: any, resolution: any)=> {
        const zoom = Math.round(map.getView().getZoom());
        const colorCodes = getColorCode(feature.get('status'));
        const iconStyle = new Icon(({
            anchor: [0.5, 46],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
            src: getFeatureImage(feature, colorCodes)
        }));

        const style = new Style({
            image: iconStyle,
            text: styleMeasuringpointsText(feature),
            stroke: new Stroke({
                color: '#55e3e1',
                width: 5 / 4
            })
        });

        const scale = scaleImage[zoom as keyof typeof scaleImage] ? 0.1 : 0.09;
        iconStyle.setScale(scale);
        return style;
    };

    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        const source = new VectorSource({});

        const overlayLayer = new VectorLayer({
            source:source,
            style:simplePointStyle,
            zIndex:100000001
        });
        overlayLayer.set('id', 'overlayLayer');
        overlayLayer.set('toogleHidden',true);
        map.addLayer(overlayLayer);
        dispatch(setLayerArray({name:'overlayLayer',mainLayerId:'overlayLayer', id:'overlayLayer', type:'vector', toogleHidden:true, visible:true}));
        dispatch(addLayerToCounterArray(projectId.projectId));
    //    console.log('add overlaylayers')
        return () => {
            if (map) {
                map.removeLayer(overlayLayer);
            }
        };
    }, [map]);

    return null;
};

export default OverlayLayer;