/*
 * OperatingStateSelectList.tsx
 * Author: lnappenfeld
 * Date: 07.06.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import Input from '../../global/Input';
import {getOperatingStates} from '../../functions/ConditionMonitoring';
import {useTranslation} from 'react-i18next';

type Data = {
    value: string,
    label: string
}

type Props = {
    projectId: string,
    label: string,
    value?: string | null,
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    name?: string,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    disabled?: boolean,
    showClear?: boolean,
    tooltip?: string
}
export const OperatingStateSelectList = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [list, setList] = useState<Data[]>();

    useEffect(() => {
        getOperatingStates(props.projectId).then(result => {
            // const tmp: any = [{
            //     value: null,
            //     label: t('metrologyManagement:dropdowns.operatingStateIsNotRelevant'),
            // }];
            const tmp: any[] = [];
            result.forEach((key: any) => {
                tmp.push({
                    value: key.operating_state_id,
                    label: key.operating_state_name,
                });
            });
            setList(tmp);
        });
    }, []);

    return (
        <Input
            name={props.name ? props.name : 'operating_state_id'}
            edit={true}
            showClear={typeof props.showClear === 'boolean' ? props.showClear : false}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            tooltip={props.tooltip ? props.tooltip : ''}
            label={props.label}
            value={props.value}
            dropdownOptions={list} type={'dropdown'}
            onChange={(e: any) => {
                if (props.setValue) props.setValue(e.value);
                if (props.onChange) props.onChange(e);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};
