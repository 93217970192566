import React from 'react';
import {useTranslation} from 'react-i18next';
import CustomDialog, {CustomDialogHeaderType} from '../global/CustomDialog';
import ChannelEditForm from './ChannelEditForm';
import {Nullable} from '../functions/Global';

type Props = {
    projectId: string,
    selectedChannels: any,
    setSelectedChannels: any,
    editChannel: boolean,
    setEditChannel: (data: boolean) => void,
    selectedChannelType?: Nullable<number>,
    setReloadChannel: (data: boolean) => void,
}

export const ChannelDialogEditForm = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);
    return (
        <CustomDialog
            header={props.selectedChannels.length > 0 ? t('metrologyManagement:dialogs.editChannelDialog.header') : t('metrologyManagement:dialogs.createChannelDialog.header')}
            headerType={props.selectedChannels.length > 0 ? CustomDialogHeaderType.Update : CustomDialogHeaderType.Create}
            formName={'formChannel'}
            onCancel={() => {
                if (props.setEditChannel) props.setEditChannel(false);
                props.setEditChannel(false);
            }}
            onHide={() => {
                if (props.setEditChannel) props.setEditChannel(false);
                props.setEditChannel(false);
            }}
            visible={props.editChannel}
            style={{ width: '60vw', height: '65vw' }}
        >
            <ChannelEditForm
                projectId={props.projectId}
                channelId={props.selectedChannels.length > 0 ? props.selectedChannels[0].channel_id : null}
                onFinished={()=>{
                    props.setEditChannel(false);
                    props.setSelectedChannels([]);
                    props.setReloadChannel(true);
                }}
                channelType={props.selectedChannelType}
            />
        </CustomDialog>
    );

};
