import keycloakfetch from '../../../functions/keycloakfetch';
import {Nullable} from '../../functions/Global';
import settings from '../../../config/settings';

export type SubPlants = {
  'id': string,
  'plant_name': string,
  'longitude': Nullable<string>,
  'latitude': Nullable<string>,
  'altitude': Nullable<string>,
  'sapNumber': Nullable<string>,
  'description': Nullable<string>
}
export async function getSubPlants (projectId: string, plantId: any) {
    const elementIdArr: any = [];
    const allEments: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getElements?project_id=' + projectId + '&parent_id=' + plantId + '&element_type_id=13').then(async response => {
        await Promise.all(response.map(async (val: any, index: number) => {
            elementIdArr.push(val['element_id']);
            allEments.push(val);

        }));
    });
    const params = new URLSearchParams({
        'project_id': projectId,
    });
    const body = {
        'element_ids' : elementIdArr
    };
    const getStatus = await keycloakfetch.post(settings.apiUrl + '/getCMStatusForElements?' + params, body);

    const newArr:any = [];
    allEments.map((val: any, index: number)=>{
        let color;
        if (getStatus[val['element_id']] === undefined || getStatus[val['element_id']] === 0) {
            color = 'gray';
        }
        else if (getStatus[val['element_id']] === 1) {
            color = 'green';
        }
        else if (getStatus[val['element_id']] === 2) {
            color = 'yellow';
        }
        else if (getStatus[val['element_id']] === 3) {
            color = 'red';
        }
        const json = {
            index: index,
            id: val['element_id'],
            name: val['element_name'],
            status: '10',
            alerts: '2',
            longitude: val['longitude'],
            latitude: val['latitude'],
            altitude: val['altitude'],
            timestamp: '02.11.2022 23:45:00',
            color: 'gray',
            alarm: true,
            description: val['description'],
            elementTypeId: val['element_type_id'],
            parentId: val['parent_id'],
            sapNumber: val['element_parameter']!== null ? val['element_parameter'][0]['value'] : null
        };

        newArr.push(json);
    });

    return newArr;
}

export async function getSingleComponent (projectId: string, elementId:string ) {
    let retVal: any = [];
    const params = new URLSearchParams({
        'project_id' : projectId,
        'element_id': elementId
    });
    await keycloakfetch.get(settings.apiUrl + '/getElement?' + params).then(result => {
        if (result) {
            retVal = result;
        }
    });
    return retVal;
}