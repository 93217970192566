/*
 * EvaluationPeriodSelectList.tsx
 * Author: lnappenfeld
 * Date: 18.07.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState, useEffect, useRef} from 'react';
import Input from '../global/Input';
import {getEvaluationPeriods} from '../functions/Reporting';

type Props = {
    label: string,
    value?: number,
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    name?: string,
    disabled?: boolean,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
}

const EvaluationPeriodSelectList = (props: Props): JSX.Element => {

    // Initialisiere mit undefined, damit das Skeletonloading funktioniert
    const [evaluationPeriods, setEvaluationPeriods] = useState<any>();

    // set evaluationPeriod in parent component
    useEffect(() => {
        getEvaluationPeriods().then(result => {
            setEvaluationPeriods(result);

            const evaluationPeriod = result.filter((x: any) => {
                return x.value === props.value;
            })[0];
            if (props.setValue) props.setValue(evaluationPeriod);
            if (props.onChange) props.onChange(evaluationPeriod);
        });


    }, []);

    return (
        <Input
            name={props.name ? props.name : 'evaluation_period'}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            edit={true}
            label={props.label}
            value={props.value}
            dropdownOptions={evaluationPeriods}
            type={'dropdown'}
            onChange={(e: any) => {
                const evaluationPeriod = evaluationPeriods.filter((x: any) => {
                    return x.value === e.value;
                })[0];
                if (props.setValue) props.setValue(evaluationPeriod);
                if (props.onChange) props.onChange(evaluationPeriod);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );

};

export default EvaluationPeriodSelectList;

