/*
 * CharacteristicMonitoringTaskTypeSelectList.tsx
 * Author: lnappenfeld
 * Date: 22.02.2024
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import Input from '../../global/Input';
import {getCharacteristicMonitoringTaskTypes} from '../../functions/Notification';

type Props = {
    projectId: string,
    label: string,
    list?: any
    value?: number,
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    name?: string,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    disabled?: boolean,
}
const CharacteristicMonitoringTaskTypeSelectList = (props: Props): JSX.Element => {

    const [list, setList] = useState<any>(props.list);

    useEffect(() => {
        if (!props.list) {
            getCharacteristicMonitoringTaskTypes(props.projectId).then(result => {
                const tmp: any = [];
                result.forEach((key: any) => {
                    tmp.push({
                        value: key.id,
                        label: key.name,
                    });
                });
                setList(tmp);
            });
        } else {
            setList(props.list);
        }
    }, [props.list]);

    return (
        <Input
            name={props.name ? props.name : 'characteristic_monitoring_task_type_id'}
            edit={true}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            label={props.label}
            value={props.value}
            dropdownOptions={list} type={'dropdown'}
            onChange={(e: any) => {
                if (props.setValue) props.setValue(e.value);
                if (props.onChange) props.onChange(e);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};
export default CharacteristicMonitoringTaskTypeSelectList;
