/* eslint-disable camelcase */
// used in MetrologyWizardMeasuringDevice.tsx & MeasuringDeviceEditForm
export const measuringDeviceObject = {
    measuring_device_type_id:{
        label: 'measuring_device',
        type: 'dropdown'
    },
    measuring_manager_id:{
        label: 'manager',
        type: 'dropdown'
    },
    name:{
        label: 'name',
        type: 'text'
    },
    description:{
        label: 'description',
        type: 'textarea'
    },
    is_visible: {
        label: 'visible',
        type: 'checkbox'
    },
    qrcode: {
        label: 'qrcode',
        type: 'text'
    },
    protokoll: {
        label: 'metrologyManagement:attributes.connectionProtocol',
        type: 'text'
    },
    ip: {
        label: 'metrologyManagement:attributes.ip',
        type: 'text'
    },
    port: {
        label: 'metrologyManagement:attributes.port',
        type: 'number'
    },
    login: {
        label: 'metrologyManagement:attributes.login',
        type: 'text'
    },
    password: {
        label: 'metrologyManagement:attributes.password',
        type: 'text'
    },
    interval: {
        label: 'metrologyManagement:attributes.intervalInDays',
        type: 'number'
    }
};