import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import LayoutMainContentTabMenu from '../components/global/LayoutMainContentTabMenu';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faAsterisk,
    faGamepad, faHardDrive,
    faLocationDot,
    faPlug,
    faTachographDigital,
    faTowerObservation
} from '@fortawesome/free-solid-svg-icons';
import {checkPermission, checkProjectType, ProjectType} from '../functions/functionLibrary';
import {permissions} from '../config/permissions';
import ViewButtons from '../components/global/ViewButtons';
import {
    buttonActivateChannel,
    buttonActivateControlDevice,
    buttonActivateSensor,
    buttonAddChannelCalibration,
    buttonChangeLocation,
    buttonChangeProjectGroup,
    buttonChangeTimezone,
    buttonControlDevice,
    buttonCreateChannel,
    buttonCreateControlDevice,
    buttonCreateGrafanaPanel,
    buttonCreateLocation,
    buttonCreateMeasuringDevice,
    buttonCreateMeasuringManager,
    buttonCreateMetrology,
    buttonCreateMetrologyFromCSV,
    buttonCreateOperatingState,
    buttonCreateOperatingStateCondition,
    buttonCreateSensor,
    buttonCreateVirtualChannel,
    buttonDeactivateChannel,
    buttonDeactivateControlDevice,
    buttonDeactivateSensor,
    buttonDeleteChannel,
    buttonDeleteControlDevice,
    buttonDeleteLocation,
    buttonDeleteMeasuringDevice,
    buttonDeleteMeasuringManagers,
    buttonDeleteOperatingStates,
    buttonDeleteSensor,
    buttonDuplicateMeasuringDevice,
    buttonHideLocation,
    buttonHideMeasuringDevice,
    buttonHideSensor,
    buttonShowLocation,
    buttonShowMeasuringDevice,
    buttonShowSensor,
    makeButtonItem
} from '../components/global/viewButtonSelection';
import {
    Channel,
    ControlDevice,
    deleteChannel,
    deleteControlDevice,
    deleteMeasuringDevice,
    deleteSensor,
    MeasuringDevice,
    duplicateMeasuringDevice,
    Sensor,
    updateStatus, MeasuringManager, deleteMeasuringManagers
} from '../components/functions/Metrology';
import {ChannelsOverview} from '../components/metrology/ChannelsOverview';
import {SensorOverview} from '../components/metrology/SensorOverview';
import {CustomConfirmDialog} from '../components/global/CustomConfirmDialog';
import {showMessageOnError, showMessageOnSuccess} from '../components/global/CustomToast';
import {deleteLocation, Location, Nullable} from '../components/functions/Global';
import {LocationOverview} from '../components/global/LocationOverview';
import CustomDialog, {CustomDialogHeaderType} from '../components/global/CustomDialog';
import {MetrologyWizard} from '../components/metrology/MetrologyWizard';
import {MeasuringDeviceOverview} from '../components/metrology/MeasuringDeviceOverview';
import LocationSelectList from '../components/global/LocationSelectList';
import TimezoneSelectList from '../components/global/TimezoneSelectList';
import {LocationDialogEditForm} from '../components/global/LocationDialogEditForm';
import {MeasuringDeviceDialogEditForm} from '../components/metrology/MeasuringDeviceDialogEditForm';
import {SensorDialogEditForm} from '../components/metrology/SensorDialogEditForm';
import {ChannelDialogEditForm} from '../components/metrology/ChannelDialogEditForm';
import {ControlDeviceOverview} from '../components/metrology/ControlDeviceOverview';
import {ControlDeviceForm} from '../components/metrology/ControlDeviceForm';
import {ControlDeviceControlForm} from '../components/metrology/ControlDeviceControlForm';
import {MetrologyWizardFromCSV} from '../components/metrology/MetrologyWizardFromCSV';
import {hideWaitAnimation, showWaitAnimation} from '../components/global/CustomWaitAnimation';
import {MeasuringManagerOverview} from '../components/metrology/MeasuringManagerOverview';
import {MeasuringManagerDialogEditForm} from '../components/metrology/MeasuringManagerDialogEditForm';
import {
    deleteOperatingStates,
    OperatingState,
    OperatingStateCondition
} from '../components/functions/ConditionMonitoring';
import {OperatingStateOverview} from '../components/metrology/OperatingStateOverview';
import {OperatingStateDialogEditForm} from '../components/metrology/OperatingStateDialogEditForm';
import {OperatingStateConditionDialogEditForm} from '../components/metrology/OperatingStateConditionDialogEditForm';
import {OSCFormType} from '../components/metrology/OperatingStateConditionForm';
import {CreateDashboardPanelForm} from '../components/metrology/CreateDashboardPanelForm';
import ProjectGroupSelectList from '../components/global/ProjectGroupSelectList';
import {MultipleChannelsCalibrationForm} from '../components/metrology/MultipleChannelsCalibrationForm';

type Props = {
    'project': any,
}

export const Metrology = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);
    const [editMetrology, setEditMetrology] = useState<boolean>(false);
    const [editMetrologyFromCSV, setEditMetrologyFromCSV] = useState<boolean>(false);
    const index = 0;

    // Um die Projektgruppe mehrerer Locations zu ändern
    const [selectedProjectGroupId, setSelectedProjectGroupId] = useState<Nullable<string>>(null);
    const showProjectGroupSelectList = props.project.project_groups.length !== 1;

    const [selectedLocations, setSelectedLocations] = useState<Location[]>([]);
    const [reloadLocations, setReloadLocations] = useState<boolean>(true);
    const [editLocation, setEditLocation] = useState<boolean>(false);
    const [changeLocationGroup, setChangeLocationGroup] = useState<boolean>(false);

    const [selectedMeasuringManagers, setSelectedMeasuringManagers] = useState<MeasuringManager[]>([]);
    const [reloadMeasuringManagers, setReloadMeasuringManagers] = useState<boolean>(true);
    const [editMeasuringManager, setEditMeasuringManager] = useState<boolean>(false);

    const [selectedMeasuringDevices, setSelectedMeasuringDevices] = useState<MeasuringDevice[]>([]);
    const [reloadMeasuringDevices, setReloadMeasuringDevices] = useState<boolean>(true);
    const [editMeasuringDevice, setEditMeasuringDevice] = useState<boolean>(false);
    const [changeDeviceLocation, setChangeDeviceLocation] = useState<boolean>(false);

    const [selectedSensors, setSelectedSensors] = useState<Sensor[]>([]);
    const [reloadSensors, setReloadSensors] = useState<boolean>(true);
    const [editSensor, setEditSensor] = useState<boolean>(false);
    // Um den Standort mehrerer Entitäten zu ändern
    const [selectedLocation, setSelectedLocation] = useState<any>(null);
    const [changeSensorLocation, setChangeSensorLocation] = useState<boolean>(false);

    const [selectedChannels, setSelectedChannels] = useState<Channel[]>([]);
    const [reloadChannels, setReloadChannels] = useState<boolean>(true);
    const [editChannel, setEditChannel] = useState<boolean>(false);
    const [selectedChannelType, setSelectedChannelType] = useState<Nullable<number>>(null);
    const [selectedTimeZone, setSelectedTimeZone] = useState<any>('Europe/Berlin');
    const [changeTimeZone, setChangeTimeZone] = useState<boolean>(false);
    const [addChannelCalibration, setAddChannelCalibration] = useState<boolean>(false);

    const [selectedControlDevices, setSelectedControlDevices] = useState<ControlDevice[]>([]);
    const [reloadControlDevices, setReloadControlDevices] = useState<boolean>(true);
    const [editControlDevice, setEditControlDevice] = useState<boolean>(false);
    const [controlDevices, setControlDevices] = useState<boolean>(false);
    const [changeControlDeviceLocation, setChangeControlDeviceLocation] = useState<boolean>(false);
    // Nur aktive Steuergeräte dürfen gesteuert werden. Deswegen enable den Button 'Steuergeräte steuern' nur, wenn
    // alle ausgewählten Steuergeräte aktiv sind.
    const [allSelectedControlDevicesAreActive, setAllSelectedControlDevicesAreActive] = useState(true);

    const [createDashboardPanel, setCreateDashboardPanel] = useState<boolean>(false);

    const [selectedOperatingStates, setSelectedOperatingStates] = useState<OperatingState[]>([]);
    const [reloadOperatingStates, setReloadOperatingStates] = useState<boolean>(true);
    const [editOperatingState, setEditOperatingState] = useState<boolean>(false);

    const [selectedOperatingStateConditions, setSelectedOperatingStateConditions] = useState<OperatingStateCondition[]>([]);
    const [reloadOperatingStateConditions, setReloadOperatingStateConditions] = useState<boolean>(true);
    const [editOperatingStateCondition, setEditOperatingStateCondition] = useState<boolean>(false);

    useEffect(() => {
        for (const controlDevice of selectedControlDevices) {
            if (!controlDevice.is_active) {
                setAllSelectedControlDevicesAreActive(false);
                return;
            }
        }
        setAllSelectedControlDevicesAreActive(true);
    }, [selectedControlDevices]);


    const _hideLocation = (data: Location[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('locations:dialogs.hideLocationState'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const n: string[] = [];
                data.forEach((location: Location) => {
                    n.push(location.location_id);
                });
                const obj = {
                    // eslint-disable-next-line camelcase
                    project_id: props.project.id,
                    statusType: 'location',
                    // eslint-disable-next-line camelcase
                    status: {is_visible: false},
                    ids: n
                };
                updateStatus(obj).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('apiErrors:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('locations:toasts.locationHided'));
                        setSelectedLocations([]);
                        setReloadLocations(true);
                    }
                });
            }
        });
    };
    const _showLocation = (data: Location[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('locations:dialogs.showLocationState'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const n: string[] = [];
                data.forEach((location: Location) => {
                    n.push(location.location_id);
                });
                const obj = {
                    // eslint-disable-next-line camelcase
                    project_id: props.project.id,
                    statusType: 'location',
                    // eslint-disable-next-line camelcase
                    status: {is_visible: true},
                    ids: n
                };
                updateStatus(obj).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('apiErrors:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('locations:toasts.locationShowed'));
                        setSelectedLocations([]);
                        setReloadLocations(true);
                    }
                });
            }
        });
    };
    const _changeLocationGroup = (data: Location[]) => {
        const n: string[] = [];
        data.forEach((location: Location) => {
            n.push(location.location_id);
        });
        const obj = {
            'project_id': props.project.id,
            'statusType': 'location',
            'status': {'project_group_id': selectedProjectGroupId},
            'ids': n
        };
        updateStatus(obj).then(result => {
            if (result.error) {
                showMessageOnError(t('error'), t('apiErrors:' + result.error));
            } else {
                showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.projectGroupChanged'));
                setSelectedProjectGroupId(null);
                setSelectedLocations([]);
                setReloadLocations(true);
            }
        });
        setChangeLocationGroup(false);
    };
    const _deleteLocation = (data: Location[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('locations:dialogs.deleteLocationDialog.message'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const ids: string[] = [];
                data.forEach((location: Location) => {
                    ids.push(location.location_id);
                });
                deleteLocation(props.project.id, ids).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('locations:toasts.locationDeleted'));
                        setSelectedLocations([]);
                        setReloadLocations(true);
                    }
                });
            }
        });
    };

    const _deleteMeasuringManagers = (data: MeasuringManager[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('metrologyManagement:dialogs.deleteMeasuringManagersDialog.message'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const ids: string[] = [];
                data.forEach((manager: any) => {
                    ids.push(manager.measuring_manager_id);
                });
                deleteMeasuringManagers(props.project.id, ids).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.measuringManagersDeleted'));
                        setSelectedMeasuringManagers([]);
                        setReloadMeasuringManagers(true);
                    }
                });
            }
        });
    };

    const _hideMeasuringDevice = (data: MeasuringDevice[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('metrologyManagement:dialogs.hideMeasuringDeviceState'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const n: string[] = [];
                data.forEach((device: any) => {
                    n.push(device.measuring_device_id);
                });
                const obj = {
                    // eslint-disable-next-line camelcase
                    project_id: props.project.id,
                    statusType: 'measuringDevice',
                    // eslint-disable-next-line camelcase
                    status: {is_visible: false},
                    ids: n
                };
                updateStatus(obj).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('apiErrors:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.measuringDeviceHided'));
                        setSelectedMeasuringDevices([]);
                        setReloadMeasuringDevices(true);
                    }
                });
            }
        });
    };
    const _showMeasuringDevice = (data: MeasuringDevice[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('metrologyManagement:dialogs.showMeasuringDeviceState'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const n: string[] = [];
                data.forEach((device: any) => {
                    n.push(device.measuring_device_id);
                });
                const obj = {
                    // eslint-disable-next-line camelcase
                    project_id: props.project.id,
                    statusType: 'measuringDevice',
                    // eslint-disable-next-line camelcase
                    status: {is_visible: true},
                    ids: n
                };
                updateStatus(obj).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('apiErrors:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.measuringDeviceShowed'));
                        setSelectedMeasuringDevices([]);
                        setReloadMeasuringDevices(true);
                    }
                });
            }
        });
    };
    const _deleteMeasuringDevice = (data: MeasuringDevice[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('metrologyManagement:dialogs.deleteMeasuringDeviceDialog.message'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const ids: string[] = [];
                data.forEach((device: any) => {
                    ids.push(device.measuring_device_id);
                });
                deleteMeasuringDevice(props.project.id, ids).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.measuringDeviceDeleted'));
                        setSelectedMeasuringDevices([]);
                        setReloadMeasuringDevices(true);
                    }
                });
            }
        });
    };
    const _changeDeviceLocation = (data: MeasuringDevice[]) => {
        const n: string[] = [];
        data.forEach((device: any) => {
            n.push(device.measuring_device_id
            );
        });
        const obj = {
            // eslint-disable-next-line camelcase
            project_id: props.project.id,
            statusType: 'measuringDevice',
            // eslint-disable-next-line camelcase
            status: {location_id: selectedLocation},
            ids: n
        };
        updateStatus(obj).then(result => {
            if (result.error) {
                showMessageOnError(t('error'), t('apiErrors:' + result.error));
            } else {
                showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.locationAdded'));
                setSelectedLocation(null);
                setSelectedMeasuringDevices([]);
                setReloadMeasuringDevices(true);
            }
        });
        setChangeDeviceLocation(false);
    };
    const _duplicateMeasuringDevice = (data: MeasuringDevice[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('metrologyManagement:dialogs.duplicateMeasuringDeviceDialog'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const n: string[] = [];
                data.forEach((device: any) => {
                    n.push(device.measuring_device_id);
                });
                const obj = {
                    // eslint-disable-next-line camelcase
                    project_id: props.project.id,
                    measuring_device_ids: n
                };
                duplicateMeasuringDevice(obj).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.measuringDeviceDuplicated'));
                        setSelectedMeasuringDevices([]);
                        setReloadMeasuringDevices(true);
                    }
                });
            }
        });
    };

    const _deactivateSensor = (data: Sensor[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('metrologyManagement:dialogs.deactivateSensorState'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const n: string[] = [];
                data.forEach((sensor: Sensor) => {
                    n.push(sensor.sensor_id);
                });
                const obj = {
                    // eslint-disable-next-line camelcase
                    project_id: props.project.id,
                    statusType: 'sensor',
                    // eslint-disable-next-line camelcase
                    status: {is_active: false},
                    ids: n
                };
                updateStatus(obj).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('apiErrors:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.sensorDisabled'));
                        setSelectedSensors([]);
                        setReloadSensors(true);
                    }
                });
            }
        });
    };
    const _activateSensor = (data: Sensor[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('metrologyManagement:dialogs.activateSensorState'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const n: string[] = [];
                data.forEach((sensor: Sensor) => {
                    n.push(sensor.sensor_id);
                });
                const obj = {
                    // eslint-disable-next-line camelcase
                    project_id: props.project.id,
                    statusType: 'sensor',
                    // eslint-disable-next-line camelcase
                    status: {is_active: true},
                    ids: n
                };
                updateStatus(obj).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('apiErrors:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.sensorEnabled'));
                        setSelectedSensors([]);
                        setReloadSensors(true);
                    }
                });
            }
        });
    };
    const _hideSensor = (data: Sensor[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('metrologyManagement:dialogs.hideSensorState'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const n: string[] = [];
                data.forEach((sensor: Sensor) => {
                    n.push(sensor.sensor_id);
                });
                const obj = {
                    // eslint-disable-next-line camelcase
                    project_id: props.project.id,
                    statusType: 'sensor',
                    // eslint-disable-next-line camelcase
                    status: {is_visible: false},
                    ids: n
                };
                updateStatus(obj).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('apiErrors:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.sensorHided'));
                        setSelectedSensors([]);
                        setReloadSensors(true);
                    }
                });
            }
        });
    };
    const _showSensor = (data: Sensor[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('metrologyManagement:dialogs.showSensorState'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const n: string[] = [];
                data.forEach((sensor: Sensor) => {
                    n.push(sensor.sensor_id);
                });
                const obj = {
                    // eslint-disable-next-line camelcase
                    project_id: props.project.id,
                    statusType: 'sensor',
                    // eslint-disable-next-line camelcase
                    status: {is_visible: true},
                    ids: n
                };
                updateStatus(obj).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('apiErrors:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.sensorShowed'));
                        setSelectedSensors([]);
                        setReloadSensors(true);
                    }
                });
            }
        });
    };
    const _changeSensorLocation = (data: Sensor[]) => {
        const n: string[] = [];
        data.forEach((sensor: Sensor) => {
            n.push(sensor.sensor_id);
        });
        const obj = {
            // eslint-disable-next-line camelcase
            project_id: props.project.id,
            statusType: 'sensor',
            // eslint-disable-next-line camelcase
            status: {location_id: selectedLocation},
            ids: n
        };
        updateStatus(obj).then(result => {
            if (result.error) {
                showMessageOnError(t('error'), t('apiErrors:' + result.error));
            } else {
                showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.locationAdded'));
                setSelectedLocation(null);
                setSelectedSensors([]);
                setReloadSensors(true);
            }
        });
        setChangeSensorLocation(false);
    };
    const _deleteSensor = (data: Sensor[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('metrologyManagement:dialogs.deleteSensorDialog.message'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const ids: string[] = [];
                data.forEach((sensor: Sensor) => {
                    ids.push(sensor.sensor_id);
                });
                deleteSensor(props.project.id, ids).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.sensorDeleted'));
                        setSelectedSensors([]);
                        setReloadSensors(true);
                    }
                });
            }
        });
    };

    const _deactivateChannel = (data: Channel[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('metrologyManagement:dialogs.deactivateChannelState'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const n: string[] = [];
                data.forEach((channel: Channel) => {
                    n.push(channel.channel_id);
                });
                const obj = {
                    // eslint-disable-next-line camelcase
                    project_id: props.project.id,
                    statusType: 'channel',
                    // eslint-disable-next-line camelcase
                    status: {is_active: false},
                    ids: n
                };
                updateStatus(obj).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('apiErrors:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.channelDisabled'));
                        setSelectedChannels([]);
                        setReloadChannels(true);
                    }
                });
            }
        });
    };
    const _activateChannel = (data: Channel[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('metrologyManagement:dialogs.activateChannelState'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const n: string[] = [];
                data.forEach((channel: Channel) => {
                    n.push(channel.channel_id);
                });
                const obj = {
                    // eslint-disable-next-line camelcase
                    project_id: props.project.id,
                    statusType: 'channel',
                    // eslint-disable-next-line camelcase
                    status: {is_active: true},
                    ids: n
                };
                updateStatus(obj).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('apiErrors:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.channelEnabled'));
                        setSelectedChannels([]);
                        setReloadChannels(true);
                    }
                });
            }
        });
    };
    const _hideChannel = (data: Channel[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('metrologyManagement:dialogs.hideChannelState'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const n: string[] = [];
                data.forEach((channel: Channel) => {
                    n.push(channel.channel_id);
                });
                const obj = {
                    // eslint-disable-next-line camelcase
                    project_id: props.project.id,
                    statusType: 'channel',
                    // eslint-disable-next-line camelcase
                    status: {is_visible: false},
                    ids: n
                };
                updateStatus(obj).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('apiErrors:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.channelHided'));
                        setSelectedChannels([]);
                        setReloadChannels(true);
                    }
                });
            }
        });
    };
    const _showChannel = (data: Channel[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('metrologyManagement:dialogs.showChannelState'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const n: string[] = [];
                data.forEach((channel: Channel) => {
                    n.push(channel.channel_id);
                });
                const obj = {
                    // eslint-disable-next-line camelcase
                    project_id: props.project.id,
                    statusType: 'channel',
                    // eslint-disable-next-line camelcase
                    status: {is_visible: true},
                    ids: n
                };
                updateStatus(obj).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('apiErrors:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.channelShowed'));
                        setSelectedChannels([]);
                        setReloadChannels(true);
                    }
                });
            }
        });
    };
    const _deleteChannel = (data: Channel[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('metrologyManagement:dialogs.deleteChannelDialog.message'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const ids: string[] = [];
                data.forEach((channel: Channel) => {
                    ids.push(channel.channel_id);
                });
                showWaitAnimation();
                deleteChannel(props.project.id, ids).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.channelDeleted'));
                        setSelectedChannels([]);
                        setReloadChannels(true);
                    }
                    hideWaitAnimation();
                });
            }
        });
    };
    const _changeChannelTimezone = (data: any) => {
        console.log(data);
        const n: string[] = [];
        data.forEach((channel: Channel) => {
            n.push(channel.channel_id);
        });
        const obj = {
            // eslint-disable-next-line camelcase
            project_id: props.project.id,
            statusType: 'channel',
            // eslint-disable-next-line camelcase
            status: {timezone: selectedTimeZone},
            ids: n
        };
        updateStatus(obj).then(result => {
            if (result.error) {
                showMessageOnError(t('error'), t('apiErrors:' + result.error));
            } else {
                showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.TimezoneChanged'));
                setSelectedChannels([]);
                setReloadChannels(true);
            }
        });
        setChangeTimeZone(false);
    };

    const _deactivateControlDevice = (data: ControlDevice[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('metrologyManagement:dialogs.deactivateControlDeviceState'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const n: string[] = [];
                data.forEach((controlDevice: ControlDevice) => {
                    n.push(controlDevice.control_device_id);
                });
                const obj = {
                    'project_id': props.project.id,
                    statusType: 'controlDevice',
                    status: {'is_active': false},
                    ids: n
                };
                updateStatus(obj).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('apiErrors:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.controlDeviceDisabled'));
                        setSelectedControlDevices([]);
                        setReloadControlDevices(true);
                    }
                });
            }
        });
    };
    const _activateControlDevice = (data: ControlDevice[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('metrologyManagement:dialogs.activateControlDeviceState'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const n: string[] = [];
                data.forEach((controlDevice: ControlDevice) => {
                    n.push(controlDevice.control_device_id);
                });
                const obj = {
                    'project_id': props.project.id,
                    statusType: 'controlDevice',
                    status: {'is_active': true},
                    ids: n
                };
                updateStatus(obj).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.controlDeviceEnabled'));
                        setSelectedControlDevices([]);
                        setReloadControlDevices(true);
                    }
                });
            }
        });
    };
    const _deleteControlDevice = (data: ControlDevice[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('metrologyManagement:dialogs.deleteControlDevice.message'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const ids: string[] = [];
                data.forEach((controlDevice: ControlDevice) => {
                    ids.push(controlDevice.control_device_id);
                });
                deleteControlDevice(props.project.id, ids).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.controlDeviceDeleted'));
                        setSelectedControlDevices([]);
                        setReloadControlDevices(true);
                    }
                });
            }
        });
    };
    const _changeControlDeviceLocation = (data: ControlDevice[]) => {
        const n: string[] = [];
        data.forEach((controlDevice: any) => {
            n.push(controlDevice.control_device_id);
        });
        const obj = {
            // eslint-disable-next-line camelcase
            project_id: props.project.id,
            statusType: 'controlDevice',
            // eslint-disable-next-line camelcase
            status: {location_id: selectedLocation},
            ids: n
        };
        updateStatus(obj).then(result => {
            if (result.error) {
                showMessageOnError(t('error'), t('apiErrors:' + result.error));
            } else {
                showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.locationAdded'));
                setSelectedLocation(null);
                setSelectedControlDevices([]);
                setReloadControlDevices(true);
            }
        });
        setChangeControlDeviceLocation(false);
    };

    const _deleteOperatingStates = (data: OperatingState[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('metrologyManagement:dialogs.deleteOperatingState.message'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const ids: string[] = [];
                data.forEach((operatingState: OperatingState) => {
                    ids.push(operatingState.operating_state_id);
                });
                deleteOperatingStates(props.project.id, ids).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.operatingStateDeleted'));
                        setSelectedOperatingStates([]);
                        setReloadOperatingStates(true);
                    }
                });
            }
        });
    };

    const menuItems = [
        {
            id: 'locations',
            label: t('locations'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faLocationDot}/>,
            component: <LocationOverview
                project={props.project}
                reload={reloadLocations}
                setReload={setReloadLocations}
                selectedLocations={selectedLocations}
                setSelectedLocations={setSelectedLocations}
                editLocation={editLocation}
                setEditLocation={setEditLocation}
            />,
            visible: checkPermission(permissions.seeMetrology),
            buttons: (
                <ViewButtons
                    title={t('')}
                    items={checkPermission(permissions.editMetrology) ? [
                        makeButtonItem(buttonCreateLocation, t, () => {
                            setSelectedLocations([]);
                            setEditLocation(true);
                        }),
                        makeButtonItem(buttonDeleteLocation, t,
                            () => _deleteLocation(selectedLocations),
                            checkPermission(permissions.editMetrology),
                            selectedLocations.length < 1),
                        makeButtonItem(buttonChangeProjectGroup, t,
                            () => setChangeLocationGroup(true),
                            checkPermission(permissions.editMetrology) && showProjectGroupSelectList,
                            selectedLocations.length < 1),
                        makeButtonItem(buttonShowLocation, t,
                            () => _showLocation(selectedLocations),
                            checkPermission(permissions.editMetrology),
                            selectedLocations.length < 1),
                        makeButtonItem(buttonHideLocation, t,
                            () => _hideLocation(selectedLocations),
                            checkPermission(permissions.editMetrology),
                            selectedLocations.length < 1)
                    ] : []}/>
            )
        },
        {
            id: 'measuringManager',
            label: t('metrologyManagement:global.measuringManagers'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faTowerObservation}/>,
            projectRequired: true,
            component: <MeasuringManagerOverview
                selectedMeasuringManagers={selectedMeasuringManagers}
                setSelectedMeasuringManagers={setSelectedMeasuringManagers}
                setEditMeasuringManager={setEditMeasuringManager}
                setReload={setReloadMeasuringManagers}
                reload={reloadMeasuringManagers}
                project={props.project}
                editMeasuringDevice={editMeasuringDevice}
                selectedMeasuringDevices={selectedMeasuringDevices}
                setSelectedMeasuringDevices={setSelectedMeasuringDevices}
                selectedSensors={selectedSensors}
                setSelectedSensors={setSelectedSensors}
                selectedChannels={selectedChannels}
                setSelectedChannels={setSelectedChannels}
                setEditMeasuringDevice={setEditMeasuringDevice}
                setEditSensor={setEditSensor}
                setEditChannel={setEditChannel}
                setEditLocation={setEditLocation}
                setSelectedLocations={setSelectedLocations}
                setReloadChannels={setReloadChannels}
                setReloadSensors={setReloadSensors}
                setReloadMeasuringDevices={setReloadMeasuringDevices}
            />,
            visible: (checkProjectType(ProjectType.ConditionMonitoring) && checkPermission(permissions.seeMetrology)),
            buttons: (
                <ViewButtons
                    title={t('')}
                    items={checkPermission(permissions.editMetrology) ? [
                        makeButtonItem(buttonCreateMeasuringManager, t, () => {
                            setSelectedMeasuringManagers([]);
                            setEditMeasuringManager(true);
                        }),
                        makeButtonItem(buttonDeleteMeasuringManagers, t,
                            () => _deleteMeasuringManagers(selectedMeasuringManagers),
                            checkPermission(permissions.editMetrology),
                            selectedMeasuringManagers.length < 1),
                    ] : []}/>
            )
        },
        {
            id: 'measuringDevice',
            label: t('metrologyManagement:global.measuringDevices'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faTachographDigital}/>,
            projectRequired: true,
            component: <MeasuringDeviceOverview
                editMeasuringDevice={editMeasuringDevice}
                project={props.project}
                showHeader={false}
                selectedMeasuringDevices={selectedMeasuringDevices}
                setSelectedMeasuringDevices={setSelectedMeasuringDevices}
                selectedSensors={selectedSensors}
                setSelectedSensors={setSelectedSensors}
                selectedChannels={selectedChannels}
                setSelectedChannels={setSelectedChannels}
                setReload={setReloadMeasuringDevices}
                reload={reloadMeasuringDevices}
                setEditMeasuringDevice={setEditMeasuringDevice}
                setEditSensor={setEditSensor}
                setEditChannel={setEditChannel}
                setEditLocation={setEditLocation}
                setSelectedLocations={setSelectedLocations}
                setEditMeasuringManager={setEditMeasuringManager}
                setSelectedMeasuringManagers={setSelectedMeasuringManagers}
                setReloadChannels={setReloadChannels}
                setReloadSensors={setReloadSensors}
            />,
            visible: (checkPermission(permissions.seeMetrology)),
            buttons: (
                <ViewButtons
                    title={t('')}
                    items={checkPermission(permissions.editMetrology) ? [
                        makeButtonItem(buttonCreateMetrologyFromCSV, t, () => {
                            setEditMetrologyFromCSV(true);
                        }, checkPermission(permissions.isAdmin)),
                        makeButtonItem(buttonCreateMetrology, t, () => {
                            setEditMetrology(true);
                        }),
                        makeButtonItem(buttonCreateMeasuringDevice, t, () => {
                            setSelectedMeasuringDevices([]);
                            setEditMeasuringDevice(true);
                        }),
                        makeButtonItem(buttonDuplicateMeasuringDevice, t,
                            () => _duplicateMeasuringDevice(selectedMeasuringDevices),
                            checkPermission(permissions.editMetrology),
                            selectedMeasuringDevices.length < 1),
                        makeButtonItem(buttonChangeLocation, t,
                            () => setChangeDeviceLocation(true),
                            checkPermission(permissions.editMetrology),
                            selectedMeasuringDevices.length < 1),
                        makeButtonItem(buttonDeleteMeasuringDevice, t,
                            () => _deleteMeasuringDevice(selectedMeasuringDevices),
                            checkPermission(permissions.editMetrology),
                            selectedMeasuringDevices.length < 1),
                        makeButtonItem(buttonShowMeasuringDevice, t,
                            () => _showMeasuringDevice(selectedMeasuringDevices),
                            checkPermission(permissions.editMetrology),
                            selectedMeasuringDevices.length < 1),
                        makeButtonItem(buttonHideMeasuringDevice, t,
                            () => _hideMeasuringDevice(selectedMeasuringDevices),
                            checkPermission(permissions.editMetrology),
                            selectedMeasuringDevices.length < 1)
                    ] : []}/>
            )
        },
        {
            id: 'sensors',
            label: t('metrologyManagement:global.sensors'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faPlug}/>,
            projectRequired: true,
            component: <SensorOverview
                project={props.project}
                reload={reloadSensors}
                setReload={setReloadSensors}
                selectedSensors={selectedSensors}
                setSelectedSensors={setSelectedSensors}
                setEditSensor={setEditSensor}
                selectedChannels={selectedChannels}
                setSelectedChannels={setSelectedChannels}
                reloadChannels={reloadChannels}
                setReloadChannels={setReloadChannels}
                setEditChannel={setEditChannel}
                showHeader={false}
                setEditLocation={setEditLocation}
                setSelectedLocations={setSelectedLocations}
                setSelectedMeasuringDevices={setSelectedMeasuringDevices}
                setEditMeasuringDevice={setEditMeasuringDevice}
            />,
            visible: (checkPermission(permissions.seeMetrology)),
            buttons: (
                <ViewButtons
                    title={t('')}
                    items={checkPermission(permissions.editMetrology) ? [
                        makeButtonItem(buttonCreateMetrology, t, () => {
                            setEditMetrology(true);
                        }),
                        makeButtonItem(buttonCreateSensor, t, () => {
                            setSelectedSensors([]);
                            setEditSensor(true);
                        }, checkPermission(permissions.editMetrology)),
                        makeButtonItem(buttonDeleteSensor, t,
                            () => _deleteSensor(selectedSensors),
                            checkPermission(permissions.editMetrology),
                            selectedSensors.length < 1),
                        makeButtonItem(buttonChangeLocation, t,
                            () => setChangeSensorLocation(true),
                            checkPermission(permissions.editMetrology),
                            selectedSensors.length < 1),
                        makeButtonItem(buttonActivateSensor, t,
                            () => _activateSensor(selectedSensors),
                            checkPermission(permissions.editMetrology),
                            selectedSensors.length < 1),
                        makeButtonItem(buttonDeactivateSensor, t,
                            () => _deactivateSensor(selectedSensors),
                            checkPermission(permissions.editMetrology),
                            selectedSensors.length < 1),
                        makeButtonItem(buttonShowSensor, t,
                            () => _showSensor(selectedSensors),
                            checkPermission(permissions.editMetrology),
                            selectedSensors.length < 1),
                        makeButtonItem(buttonHideSensor, t,
                            () => _hideSensor(selectedSensors),
                            checkPermission(permissions.editMetrology),
                            selectedSensors.length < 1)
                    ] : []}/>
            )
        },
        {
            id: 'channels',
            label: t('metrologyManagement:global.channels'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faAsterisk}/>,
            projectRequired: true,
            component: <ChannelsOverview
                projectId={props.project.id}
                showHeader={false}
                reload={reloadChannels}
                setReload={setReloadChannels}
                selectedChannels={selectedChannels}
                setSelectedChannels={setSelectedChannels}
                setEditChannel={setEditChannel}
                setEditLocation={setEditLocation}
                setSelectedLocations={setSelectedLocations}
                setSelectedSensors={setSelectedSensors}
                setEditSensor={setEditSensor}
                setEditMeasuringDevice={setEditMeasuringDevice}
                setSelectedMeasuringDevice={setSelectedMeasuringDevices}
            />,
            visible: (checkPermission(permissions.seeMetrology)),
            buttons: (
                <ViewButtons
                    title={t('')}
                    items={checkPermission(permissions.editMetrology) ? [
                        makeButtonItem(buttonCreateMetrology, t, () => {
                            setEditMetrology(true);
                        }),
                        makeButtonItem(buttonCreateChannel, t, () => {
                            setSelectedChannelType(null);
                            setSelectedChannels([]);
                            setEditChannel(true);
                        }, checkPermission(permissions.editMetrology)),
                        makeButtonItem(buttonCreateVirtualChannel, t, () => {
                            setSelectedChannelType(140);
                            setSelectedChannels([]);
                            setEditChannel(true);
                        }),
                        makeButtonItem(buttonDeleteChannel, t,
                            () => _deleteChannel(selectedChannels),
                            checkPermission(permissions.editMetrology),
                            selectedChannels.length < 1),
                        makeButtonItem(buttonChangeTimezone, t,
                            () => setChangeTimeZone(true),
                            checkPermission(permissions.editMetrology),
                            selectedChannels.length < 1),
                        makeButtonItem(buttonActivateChannel, t,
                            () => _activateChannel(selectedChannels),
                            checkPermission(permissions.editMetrology),
                            selectedChannels.length < 1),
                        makeButtonItem(buttonDeactivateChannel, t,
                            () => _deactivateChannel(selectedChannels),
                            checkPermission(permissions.editMetrology),
                            selectedChannels.length < 1),
                        makeButtonItem(buttonAddChannelCalibration, t,
                            () => setAddChannelCalibration(true),
                            checkProjectType(ProjectType.GeoMonitoring),
                            selectedChannels.length < 1),
                        makeButtonItem(buttonCreateGrafanaPanel, t,
                            () => setCreateDashboardPanel(true),
                            checkProjectType(ProjectType.GeoMonitoring) &&
                            checkPermission(permissions.editGrafanaDashboards),
                            selectedChannels.length < 1),
                        // makeButtonItem(buttonShowChannel, t,
                        //     () => _showChannel(selectedChannels),
                        //     checkPermission(permissions.editMetrology),
                        //     selectedChannels.length < 1),
                        // makeButtonItem(buttonHideChannel, t,
                        //     () => _hideChannel(selectedChannels),
                        //     checkPermission(permissions.editMetrology),
                        //     selectedChannels.length < 1)
                    ] : []}/>
            )
        },
        {
            id: 'controlDevices',
            label: t('metrologyManagement:global.controlDevices'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faGamepad}/>,
            projectRequired: true,
            component: <ControlDeviceOverview
                projectId={props.project.id}
                reload={reloadControlDevices}
                setReload={setReloadControlDevices}
                selectedControlDevices={selectedControlDevices}
                setSelectedControlDevices={setSelectedControlDevices}
                setEditControlDevice={setEditControlDevice}
            />,
            visible: (checkProjectType(ProjectType.GeoMonitoring) && (
                checkPermission(permissions.controlDevices) ||
                checkPermission(permissions.turnOnDevices) ||
                checkPermission(permissions.turnOffDevices)
            )),
            buttons: (
                <ViewButtons
                    title={t('')}
                    items={checkPermission(permissions.controlDevices) ? [
                        makeButtonItem(buttonCreateControlDevice, t, () => {
                            setSelectedControlDevices([]);
                            setEditControlDevice(true);
                        }, checkPermission(permissions.controlDevices)),
                        makeButtonItem(buttonDeleteControlDevice, t,
                            () => _deleteControlDevice(selectedControlDevices),
                            checkPermission(permissions.controlDevices),
                            selectedControlDevices.length < 1),
                        makeButtonItem(buttonActivateControlDevice, t,
                            () => _activateControlDevice(selectedControlDevices),
                            checkPermission(permissions.controlDevices),
                            selectedControlDevices.length < 1),
                        makeButtonItem(buttonDeactivateControlDevice, t,
                            () => _deactivateControlDevice(selectedControlDevices),
                            checkPermission(permissions.controlDevices),
                            selectedControlDevices.length < 1),
                        makeButtonItem(buttonControlDevice, t,
                            () => {
                                setControlDevices(true);
                            },
                            checkPermission(permissions.controlDevices),
                            selectedControlDevices.length < 1 || !allSelectedControlDevicesAreActive),
                        makeButtonItem(buttonChangeLocation, t,
                            () => setChangeControlDeviceLocation(true),
                            checkPermission(permissions.editMetrology),
                            selectedControlDevices.length < 1),
                    ] : []}
                />
            )
        },
        {
            id: 'operatingStates',
            label: t('metrologyManagement:global.operatingStates'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faHardDrive}/>,
            component: <OperatingStateOverview
                project={props.project}
                reload={reloadOperatingStates}
                setReload={setReloadOperatingStates}
                selectedOperatingStates={selectedOperatingStates}
                setSelectedOperatingStates={setSelectedOperatingStates}
                setEditOperatingState={setEditOperatingState}
                // OperatingStateCondition
                selectedOperatingStateConditions={selectedOperatingStateConditions}
                setSelectedOperatingStateConditions={setSelectedOperatingStateConditions}
                reloadOperatingStateConditions={reloadOperatingStateConditions}
                setReloadOperatingStateConditions={setReloadOperatingStateConditions}
                setEditOperatingStateCondition={setEditOperatingStateCondition}
            />,
            visible: (checkProjectType(ProjectType.ConditionMonitoring) && checkPermission(permissions.seeMetrology)),
            buttons: (
                <ViewButtons
                    title={t('')}
                    items={checkPermission(permissions.editMetrology) ? [
                        makeButtonItem(buttonCreateOperatingState, t, () => {
                            setSelectedOperatingStates([]);
                            setEditOperatingState(true);
                        }),
                        makeButtonItem(buttonCreateOperatingStateCondition, t, () => {
                            setSelectedOperatingStates([]);
                            setSelectedOperatingStateConditions([]);
                            setEditOperatingStateCondition(true);
                        }),
                        makeButtonItem(buttonDeleteOperatingStates, t,
                            () => _deleteOperatingStates(selectedOperatingStates),
                            checkPermission(permissions.editMetrology),
                            selectedOperatingStates.length < 1),
                    ] : []}/>
            )
        },
    ];

    // @ts-ignore
    return (<>
        <LayoutMainContentTabMenu
            items={menuItems}
            activeIndex={index}
        />

        <MetrologyWizard
            projectId={props.project.id}
            onFinish={()=>{
                setReloadChannels(true);
                setReloadSensors(true);
                setReloadMeasuringDevices(true);}}
            setEditMetrology={setEditMetrology}
            editMetrology={editMetrology}
        />
        <MetrologyWizardFromCSV
            projectId={props.project.id}
            onFinish={()=>{
                setReloadChannels(true);
                setReloadSensors(true);
                setReloadMeasuringDevices(true);
                setEditMetrologyFromCSV(false);
            }}
            setEditMetrology={setEditMetrologyFromCSV}
            editMetrology={editMetrologyFromCSV}
        />
        {
            <CustomDialog
                onHide={() => {setChangeLocationGroup(false);}}
                maximized={false}
                visible={changeLocationGroup}
                onCancel={() => {setChangeLocationGroup(false);}}
                header={t('metrologyManagement:dialogs.changeProjectGroupDialog.header')}
                headerType={CustomDialogHeaderType.Update}
                formName={'changeProjectGroupForm'}
                style={{ width: '350px'}}
            >
                <form id='changeProjectGroupForm' onSubmit={(event ) => {
                    event.preventDefault();
                    _changeLocationGroup(selectedLocations);
                }}>
                    <div className={'card'}>
                        <ProjectGroupSelectList
                            projectId={props.project.id}
                            label={t('projectManagement:attributes.projectGroup')}
                            value={selectedProjectGroupId}
                            onChange={(data) => setSelectedProjectGroupId(data.value)}
                        />
                    </div>
                </form>
            </CustomDialog>
        }
        {
            <CustomDialog
                onHide={() => {
                    setChangeSensorLocation(false);
                    setChangeDeviceLocation(false);
                    setChangeControlDeviceLocation(false);
                }}
                maximized={false}
                visible={changeSensorLocation || changeDeviceLocation || changeControlDeviceLocation}
                onCancel={() => {
                    setChangeSensorLocation(false);
                    setChangeDeviceLocation(false);
                    setChangeControlDeviceLocation(false);
                }}
                header={t('metrologyManagement:dialogs.changeLocationDialog.header')}
                headerType={CustomDialogHeaderType.Update}
                formName={'changeLocationForm'}
                style={{ width: '350px'}}
            >
                <form id='changeLocationForm' onSubmit={(event ) => {
                    event.preventDefault();
                    if (changeSensorLocation)
                        _changeSensorLocation(selectedSensors);
                    if (changeDeviceLocation)
                        _changeDeviceLocation(selectedMeasuringDevices);
                    if (changeControlDeviceLocation)
                        _changeControlDeviceLocation(selectedControlDevices);
                }}>
                    <div className={'card'}>
                        <LocationSelectList
                            projectId={props.project.id}
                            label={t('location')}
                            value={selectedLocation}
                            onChange={(data) => setSelectedLocation(data.value)}
                        />
                    </div>
                </form>
            </CustomDialog>
        }
        {
            <CustomDialog
                onHide={() => {setChangeTimeZone(false);}}
                maximized={false}
                visible={changeTimeZone}
                header={t('metrologyManagement:dialogs.changeTimezoneDialog.header')}
                headerType={CustomDialogHeaderType.Update}
                onCancel={() => {setChangeTimeZone(false);}}
                formName={'changeTimezoneForm'}
                style={{ width: '350px'}}
            >
                <form id='changeTimezoneForm' onSubmit={(event ) => {
                    event.preventDefault();
                    _changeChannelTimezone(selectedChannels);
                }}>
                    <div className={'card'}>
                        <TimezoneSelectList
                            label={t('timezone')}
                            value={selectedTimeZone}
                            onChange={(data) => setSelectedTimeZone(data.value)}
                        />
                    </div>
                </form>
            </CustomDialog>
        }
        {
            <LocationDialogEditForm
                project={props.project}
                showSaveAndNewButton={selectedLocations.length < 1}
                setSelectedLocations={setSelectedLocations}
                selectedLocations={selectedLocations}
                setEditLocation={setEditLocation}
                editLocation={editLocation}
                setReloadLocations={setReloadLocations}
            />
        }
        {
            <MeasuringManagerDialogEditForm
                projectId={props.project.id}
                editMeasuringManager={editMeasuringManager}
                setEditMeasuringManager={setEditMeasuringManager}
                selectedMeasuringManagers={selectedMeasuringManagers}
                setSelectedMeasuringManagers={setSelectedMeasuringManagers}
                setReloadMeasuringManagers={setReloadMeasuringManagers}
            />
        }
        {
            <MeasuringDeviceDialogEditForm
                projectId={props.project.id}
                editMeasuringDevice={editMeasuringDevice}
                setEditMeasuringDevice={setEditMeasuringDevice}
                selectedSelectedMeasuringDevices={selectedMeasuringDevices}
                setSelectedMeasuringDevices={setSelectedMeasuringDevices}
                setReloadMeasuringDevice={setReloadMeasuringDevices}
            />
        }
        {
            <SensorDialogEditForm
                projectId={props.project.id}
                selectedSensors={selectedSensors}
                setSelectedSensors={setSelectedSensors}
                editSensor={editSensor}
                setEditSensor={setEditSensor}
                setReloadSensors={setReloadSensors}
            />
        }
        {
            <ChannelDialogEditForm
                projectId={props.project.id}
                selectedChannels={selectedChannels}
                setSelectedChannels={setSelectedChannels}
                editChannel={editChannel}
                setEditChannel={setEditChannel}
                selectedChannelType={selectedChannelType}
                setReloadChannel={setReloadChannels}
            />
        }
        {
            <OperatingStateDialogEditForm
                projectId={props.project.id}
                editOperatingState={editOperatingState}
                setEditOperatingState={setEditOperatingState}
                selectedOperatingStates={selectedOperatingStates}
                setSelectedOperatingStates={setSelectedOperatingStates}
                setReloadOperatingStates={setReloadOperatingStates}
                reloadOperatingStateConditions={reloadOperatingStateConditions}
                setEditOperatingStateCondition={setEditOperatingStateCondition}
                selectedOperatingStateConditions={selectedOperatingStateConditions}
                setSelectedOperatingStateConditions={setSelectedOperatingStateConditions}
                setReloadOperatingStateConditions={setReloadOperatingStateConditions}
            />
        }
        {
            <OperatingStateConditionDialogEditForm
                type={OSCFormType.Default}
                projectId={props.project.id}
                editOperatingStateCondition={editOperatingStateCondition}
                setEditOperatingStateCondition={setEditOperatingStateCondition}
                selectedOperatingStateConditions={selectedOperatingStateConditions}
                setSelectedOperatingStateConditions={setSelectedOperatingStateConditions}
                setReloadOperatingStateConditions={setReloadOperatingStateConditions}
                selectedOperatingStates={selectedOperatingStates}
            />
        }
        {
            <CustomDialog
                onHide={() => {setEditControlDevice(false);}}
                visible={editControlDevice}
                header={selectedControlDevices.length > 0
                    ? t('metrologyManagement:dialogs.editControlDevice.header')
                    : t('metrologyManagement:dialogs.createControlDevice.header')}
                headerType={selectedControlDevices.length > 0 ? CustomDialogHeaderType.Update : CustomDialogHeaderType.Create}
                onCancel={() => {setEditControlDevice(false);}}
                formName={'controlDeviceDialog'}
                className={'w-3'}
            >
                <ControlDeviceForm
                    projectId={props.project.id}
                    controlDevice={selectedControlDevices.length > 0 ? selectedControlDevices[0] : null}
                    onFinished={()=> {
                        setEditControlDevice(false);
                        setSelectedControlDevices([]);
                        setReloadControlDevices(true);
                    }}
                />
            </CustomDialog>
        }
        {
            <CustomDialog
                onHide={() => {setControlDevices(false);}}
                visible={controlDevices}
                header={selectedControlDevices.length > 1
                    ? t('metrologyManagement:dialogs.controlDevices.header')
                    : t('metrologyManagement:dialogs.controlDevice.header')}
                headerType={CustomDialogHeaderType.Update}
                onCancel={() => {setControlDevices(false);}}
                formName={'controlDevicesDialog'}
                className={selectedControlDevices.length > 1 ? 'w-7' : 'w-10'}
                showFooter={false}
            >
                <ControlDeviceControlForm
                    projectId={props.project.id}
                    controlDevices={selectedControlDevices}
                />
            </CustomDialog>
        }
        {
            <CustomDialog
                onHide={() => {setAddChannelCalibration(false);}}
                visible={addChannelCalibration}
                header={t('metrologyManagement:dialogs.addMultipleChannelCalibration.header')}
                headerType={CustomDialogHeaderType.Add}
                onCancel={() => {setAddChannelCalibration(false);}}
                formName={'addChannelCalibrationForm'}
                className={'w-8'}
            >
                <MultipleChannelsCalibrationForm
                    projectId={props.project.id}
                    channels={selectedChannels}
                    onFinished={()=> {
                        setAddChannelCalibration(false);
                        setSelectedChannels([]);
                    }}
                />
            </CustomDialog>
        }
        {
            <CustomDialog
                onHide={() => {setCreateDashboardPanel(false);}}
                visible={createDashboardPanel}
                header={t('metrologyManagement:dialogs.createGrafanaPanelDialog.header')}
                headerType={CustomDialogHeaderType.Create}
                onCancel={() => {setCreateDashboardPanel(false);}}
                formName={'createDashboardPanelForm'}
                className={'w-8'}
            >
                <CreateDashboardPanelForm
                    projectId={props.project.id}
                    channels={selectedChannels}
                    onFinished={()=> {
                        setCreateDashboardPanel(false);
                        setSelectedChannels([]);
                    }}
                />
            </CustomDialog>
        }
    </>);
};