/*
 * ControlDeviceForm.tsx
 * Author: lnappenfeld
 * Date: 13.03.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import Input from '../global/Input';
import {Nullable} from '../functions/Global';
import {ControlDevice, createControlDevice, updateControlDevice} from '../functions/Metrology';
import {ipValidation} from '../../functions/functionLibrary';
import {ControlDeviceTypeSelectList} from './ControlDeviceTypeSelectList';
import LocationSelectList from '../global/LocationSelectList';

type Props = {
    projectId: string,
    controlDevice: Nullable<ControlDevice>,
    onFinished: (success: boolean) => void
}

export const ControlDeviceForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const controlDeviceId = props.controlDevice ? props.controlDevice.control_device_id : null;

    const defaultValues = {
        'control_device_name': props.controlDevice ? props.controlDevice.control_device_name : null,
        'description': props.controlDevice ? props.controlDevice.description : null,
        'control_device_type_id': props.controlDevice ? props.controlDevice.control_device_type_id : null,
        'location_id': props.controlDevice ? props.controlDevice.location_id : null,
        'is_active': props.controlDevice ? props.controlDevice.is_active : true,
        'ip': props.controlDevice ? props.controlDevice.ip : null,
    };

    const {control, formState: {errors}, handleSubmit, reset, getValues, watch, setValue, trigger} = useForm({defaultValues});

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    const saveData = (controlDevice: any) => {
        const name = {'name': controlDevice.control_device_name};
        const projectId = {'project_id': props.projectId};
        controlDevice = {...projectId, ...name, ...controlDevice};

        if (controlDeviceId !== null) {
            const _controlDeviceId = {'control_device_id': controlDeviceId};
            controlDevice = {..._controlDeviceId, ...controlDevice};

            updateControlDevice(controlDevice).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), t('errorMessages:' + result.error));
                } else {
                    showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.controlDeviceUpdated'));
                    props.onFinished(true);
                }
            });
        } else {
            createControlDevice(controlDevice).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), t('errorMessages:' + result.error));
                } else {
                    showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.controlDeviceCreated'));
                    props.onFinished(true);
                }
            });
        }

    };

    return (
        <form id='controlDeviceDialog' onSubmit={handleSubmit(saveData)}>
            <div className='card'>
                <Input
                    edit={true} label={t('name')} name={'control_device_name'}
                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: t('name') + ' ' + t('input:required')}}
                />
                <Input
                    edit={true} label={t('description')} name={'description'} type={'textarea'}
                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: false}}
                />
                <ControlDeviceTypeSelectList
                    projectId={props.projectId}
                    label={t('type')}
                    name={'control_device_type_id'}
                    validationControl={control}
                    validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: t('type') + ' ' + t('input:required')}}
                />
                <LocationSelectList
                    projectId={props.projectId}
                    label={t('location')}
                    validationControl={control}
                    validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: false}}
                />
                <Input
                    edit={true} label={t('ip')} name={'ip'}
                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                    validationRules={{...{required: t('ip') + ' ' + t('input:required')}, ...ipValidation(t)}}
                />
                <Input
                    edit={true} label={t('active')} name={'is_active'} type={'checkbox'}
                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: false}}
                    tooltip={t('metrologyManagement:tooltips.controlDeviceIsActive')}
                />
            </div>
        </form>
    );
};