/*
 * ChannelCalibrationForm.tsx
 * Author: lnappenfeld
 * Date: 30.10.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Input from '../global/Input';
import {useForm} from 'react-hook-form';
import {hideWaitAnimation, showWaitAnimation} from '../global/CustomWaitAnimation';
import {createMonitoringTask, updateMonitoringTask} from '../functions/Notification';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {Nullable} from '../functions/Global';
import {getClosestValueInTime, getFirstValue, getLastValue} from '../functions/Data';
import {createChannelCalibration, updateChannelCalibration} from '../functions/Metrology';

export type ChannelCalibration = {
    'project_id'?: string
    'channel_id': string
    'timestamp': string
    'factor': number
    'addend': number
    'changed_at_utc': string
    'changed_by_user_id': string
}

export enum ChannelCalibrationFormType {
    Default = 0,
    Now = 1,
    First = 2,
    Zeros = 3,
    Edit = 4
}

type Props = {
    type: ChannelCalibrationFormType
    projectId: string
    channelId: Nullable<string>
    channelCalibration: Nullable<ChannelCalibration>
    onFinished: (success: boolean) => void
}
export const ChannelCalibrationForm = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    const [firstValue, setFirstValue] = useState<Nullable<number>>(null);
    const [lastValue, setLastValue] = useState<Nullable<number>>(null);
    const [closestValueInTime, setClosestValueInTime] = useState<Nullable<number>>(null);

    const [minDate, setMinDate] = useState<Nullable<Date>>(null);
    const [maxDate, setMaxDate] = useState<Nullable<Date>>(null);

    const defaultValues = {
        'channel_id': props.channelCalibration ? props.channelCalibration.channel_id : null,
        'timestamp': props.channelCalibration ? new Date(props.channelCalibration.timestamp) : null,
        'prev_timestamp': props.channelCalibration ? new Date(props.channelCalibration.timestamp) : null,
        'factor': props.channelCalibration ? props.channelCalibration.factor : 1,
        'addend': props.channelCalibration ? props.channelCalibration.addend : 0,
    };

    const {control, formState: {errors}, handleSubmit, reset, setValue, getValues, watch, trigger} = useForm({defaultValues});

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    watch('timestamp');

    useEffect(() => {
        const now = new Date();
        // Für minDate
        _getFirstValue();
        // Für maxDate
        setMaxDate(now);
        switch (props.type) {
            // Es geht nur Typ Now bei der Gruppenfunktion
            // Die anderen Werte kann man nicht ohne weiteres ermitteln, bzw. dafür müsste man dann einen
            // weiteren Dialog schreiben
            case ChannelCalibrationFormType.Now:
                setValue('timestamp', now);
                _getLastValue();
                break;
            case ChannelCalibrationFormType.First:
                _getFirstValue();
                break;
            case ChannelCalibrationFormType.Zeros:
                setValue('timestamp', now);
                _getClosestValueInTime(now);
                break;
            case ChannelCalibrationFormType.Edit:
                break;
            default:
                break;
        }
    }, []);

    const onTimestampBlur = () => {
        if (props.type === ChannelCalibrationFormType.Zeros || props.type === ChannelCalibrationFormType.Edit) {
            // @ts-ignore
            const date = new Date(getValues('timestamp'));
            _getClosestValueInTime(date);
        }
    };

    const _getFirstValue = () => {
        if (props.channelId) {
            getFirstValue(props.projectId, props.channelId).then(result => {
                setMinDate(new Date(result.timestamp));
                setFirstValue(result.uncalibrated_value);

                if (props.type === ChannelCalibrationFormType.First) {
                    setValue('timestamp', new Date(result.timestamp));
                    setValue('addend', 0 - result.uncalibrated_value);
                }
            });
        }
    };

    const _getLastValue = () => {
        if (props.channelId) {
            getLastValue(props.projectId, props.channelId).then(result => {
                setLastValue(result.uncalibrated_value);
                setValue('addend', 0 - result.uncalibrated_value);
            });
        }
    };

    const _getClosestValueInTime = (timestamp: Date) => {
        if (props.channelId) {
            getClosestValueInTime(props.projectId, props.channelId, timestamp).then(result => {
                setClosestValueInTime(result.uncalibrated_value);
                setValue('addend', 0 - result.uncalibrated_value);
            });
        }
    };

    const saveChannelCalibration = (data: any) => {
        const addProjectId = {'project_id': props.projectId};
        data = {...addProjectId, ...data};
        data['channel_id'] = props.channelId;
        if (props.channelCalibration) {
            showWaitAnimation();
            updateChannelCalibration(data).then(result => {
                hideWaitAnimation();
                if (!result.error) {
                    showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.channelCalibrationUpdated'));
                    props.onFinished(true);
                } else {
                    showMessageOnError(t('error'), result.error);
                }
            });
        } else {
            showWaitAnimation();
            createChannelCalibration(data).then(result => {
                hideWaitAnimation();
                if (result.error) {
                    showMessageOnError(t('error'), result.error);
                } else {
                    showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.channelCalibrationCreated'));
                    props.onFinished(true);
                }
            });
        }
    };

    return (
        <div className={'mt-3 ml-2 mr-2'}>
            <div className={'card'}>
                <form id='formChannelCalibration' onSubmit={handleSubmit(saveChannelCalibration)}>
                    <Input
                        edit={true} label={t('metrologyManagement:attributes.validFrom')}
                        name={'timestamp'} type={'date'} minDate={minDate} maxDate={maxDate}
                        onBlur={onTimestampBlur}
                        // disabled={props.type === ChannelCalibrationFormType.Edit}
                        options={{showTime: true, showSeconds: true, showIcon: true}}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('metrologyManagement:attributes.validFrom') + ' ' + t('input:required')}}
                    />
                    {props.type === ChannelCalibrationFormType.First &&
                        <Input
                            edit={true} label={t('metrologyManagement:attributes.firstValue')}
                            tooltip={t('metrologyManagement:tooltips.forInformationOnly')}
                            name={'firstValue'} type={'number'} disabled={true}
                            value={firstValue} maxFractionDigits={6}
                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: false}}
                        />
                    }
                    {props.type === ChannelCalibrationFormType.Now &&
                        <Input
                            edit={true} label={t('metrologyManagement:attributes.lastValue')}
                            tooltip={t('metrologyManagement:tooltips.forInformationOnly')}
                            name={'lastValue'} type={'number'} disabled={true}
                            value={lastValue} maxFractionDigits={6}
                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: false}}
                        />
                    }
                    {props.type === ChannelCalibrationFormType.Zeros &&
                        <Input
                            edit={true} label={t('metrologyManagement:attributes.closestValueInTime')}
                            tooltip={t('metrologyManagement:tooltips.forInformationOnly')}
                            name={'closestValueInTime'} type={'number'} disabled={true}
                            value={closestValueInTime} maxFractionDigits={6}
                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: false}}
                        />
                    }
                    <Input
                        edit={true} label={t('metrologyManagement:attributes.factor')}
                        name={'factor'} type={'number'}
                        onChange={(data: any) => {
                            setValue('factor', data.value);
                        }}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('metrologyManagement:attributes.factor') + ' ' + t('input:required')}}
                    />
                    <Input
                        edit={true} label={t('metrologyManagement:attributes.addend')}
                        name={'addend'} type={'number'}
                        maxFractionDigits={4}
                        onChange={(data: any) => {
                            setValue('addend', data.value);
                        }}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('metrologyManagement:attributes.addend') + ' ' + t('input:required')}}
                    />
                </form>
            </div>
        </div>
    );
};
