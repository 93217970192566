/*
 * ProjectOverview.tsx
 * Author: lnappenfeld
 * Date: 07.11.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DataView, DataViewLayoutOptions, DataViewLayoutType} from 'primereact/dataview';
import {useNavigate} from 'react-router-dom';
import {TriStateCheckbox} from 'primereact/tristatecheckbox';
import {getProjects, getProjectTypes, Project} from '../functions/Project';
import {checkPermission} from '../../functions/functionLibrary';
import {permissions} from '../../config/permissions';
import {Button} from 'primereact/button';
import CustomImage from '../global/CustomImage';
import {Badge} from 'primereact/badge';
import {MultiSelect, MultiSelectChangeParams} from 'primereact/multiselect';
import {InputText} from 'primereact/inputtext';
import SkeletonProjectOverview from '../skeletons/SkeletonProjectOverview';

type Props = {
    keycloakToken: string,
    setSelectedProject: (project: any) => void,
    setHideMenuitemProjects: (value: boolean) => void
}
export const ProjectOverview = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);
    const navigate = useNavigate();

    // Array das immer alle Projekte enthält für die Filterfunktion
    const [allProjects, setAllProjects] = useState<Project[]>([]);
    // Projekte die nach der Filterung in der Liste angezeigt werden
    const [projects, setProjects] = useState<Project[]>([]);
    const [projectTypes, setProjectTypes] = useState([]);

    const [layout, setLayout] = useState<DataViewLayoutType>('grid');
    // const ableToSeeAllProjects = checkPermission(permissions.seeAllProjects);

    // Filters
    const [projectTypeFilters, setProjectTypeFilters] = useState<any[]>([]);
    const [projectStatusFilter, setProjectStatusFilter] = useState<boolean | null | undefined>(true);
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [showSkeleton, setShowSkeleton] = useState<boolean>(true);

    const columnWidth = {
        number: '15%',
        name: '20%',
        comment: '40%',
        type: '20%'
    };

    useEffect(() => {
        getProjectsFetch();
        props.setSelectedProject(null);
    }, []);

    // analyze filters
    useEffect(() => {
        // on search input text changed
        // check on project.name and project.comment
        const searchInputFilteredProjects: any[] = allProjects.filter((ele: any) => {
            return (
                ele.name.toLowerCase().indexOf(globalFilter.toLowerCase()) > -1 ||
                    (ele.comment ? ele.comment.toLowerCase().indexOf(globalFilter.toLowerCase()) > -1 : false)
            );
        });

        // on project type filter changed
        let projectTypeFilteredProjects: any [] = allProjects;
        if (projectTypeFilters.length > 0) {
            projectTypeFilteredProjects = allProjects.filter((ele: any) => {
                return projectTypeFilters.some((f: any) => {
                    return f.id === ele.project_type_id;
                });
            });
        }

        // on project status filter changed
        let projectStatusFilteredProjects: any[] = allProjects;
        if (projectStatusFilter !== null) {
            projectStatusFilteredProjects = allProjects.filter((ele: any) => {
                return ele.is_active === projectStatusFilter;
            });
        }

        // Gebe nur die Projekte zurück, die in allen Filterlisten existieren
        const finalFilteredProjects: any[] = searchInputFilteredProjects.filter((ele: any) => {
            return (projectTypeFilteredProjects.includes(ele) && projectStatusFilteredProjects.includes(ele));
        });

        setProjects(finalFilteredProjects);

    }, [allProjects, globalFilter, projectTypeFilters, projectStatusFilter]);


    const getProjectsFetch = () => {
        getProjectTypes().then(result => {
            setProjectTypes(result);

            getProjects().then(result => {
                if (result.length === 1 && !checkPermission(permissions.createProject)) {
                    props.setHideMenuitemProjects(true);
                    onProjectChange(result[0]);
                } else {
                    setAllProjects(result);
                    setProjects(result);
                }
                setShowSkeleton(false);
            });
        });
    };

    const onProjectChange = (e: any) => {
        props.setSelectedProject(e);
        if (e.project_type_id === 1 && e.location !== null) {
            navigate('/project/' + e.id + '/#geomonitoring');
            return;
        } else if (e.project_type_id === 2 && (e.elements !== null && e.elements.length > 0)) {
            navigate('/project/' + e.id + '/#conditionomonitoring');
            return;
        }
        if (e.location !== null)
            navigate('/project/' + e.id + '/#geomonitoring');
        else if (e.elements !== null && e.elements.length > 0)
            navigate('/project/' + e.id + '/#conditionomonitoring');
        else if (e.grafana_dashboards !== null && e.grafana_dashboards.length > 0)
            navigate('/project/' + e.id + '/#dashboards');
        else
            navigate('/project/' + e.id + '/#project');

    };

    const clearFilter = () => {
        setGlobalFilter('');
        setProjectTypeFilters([]);
        setProjectStatusFilter(null);
    };


    const renderListItem = (project: any) => {
        return (
            <div className="col-12">
                <div
                    className="flex flex-column md:flex-row align-items-center p-2 w-full cursor-pointer"
                    style={{border: '3px solid var(--surface-border)', borderWidth: '0 0 1px 0'}}
                    onClick={() => onProjectChange(project)}>
                    <table style={{width: '100%'}}>
                        <tr>
                            <td style={{width: columnWidth.number}}>{project.number}</td>
                            <td style={{width: columnWidth.name}}>{project.name}</td>
                            <td style={{width: columnWidth.comment}}>{project.comment}</td>
                            <td style={{width: columnWidth.type}}>{project.project_type_name}</td>
                            <td className={'flex sm:flex-column md:flex-column md:align-items-end'}>
                                <Badge style={{
                                    backgroundColor: project.is_active ? '#8cf000' : 'grey',
                                    height: '1.5rem',
                                    width: '1.5rem'
                                }}/>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        );
    };

    const renderGridItem = (project: any) => {
        return (
            <div className="col-12 sm:col-6 md:col-4 lg:col-4 xl:col-3">
                <div
                    className={'card m-2 project-card border-1 cursor-pointer surface-border ' + (project.is_active ? 'green-border' : '')}
                    // style move into a file
                    style={{maxHeight: '250px', minHeight: '250px', overflow: 'hidden'}}
                    onClick={() => onProjectChange(project)}>
                    <div className="flex align-items-center justify-content-between mb-3">
                        <div className="flex align-items-center">
                            <i className="pi pi-tag product-type-icon"></i>
                            <span className="project-type ml-2">{project.project_type_name}</span>
                        </div>
                        {/* {ableToSeeAllProjects &&*/}
                        {/* <Badge style={{backgroundColor: project.is_active ? '#8cf000' : 'grey'}}/>*/}
                        {/* }*/}
                    </div>
                    <div className="product-grid-item-content align-items-center" style={{textAlign: 'center'}}>
                        {/* <CustomImage image={project.logo} maxHeight={project.logo ? '200px' : '150px'} width={project.logo ? '300px' : '150px'} showPlaceholder={true}/>*/}
                        <div className="project-card-img">
                            <CustomImage
                                image={project.logo} showPlaceholder={true}
                                maxWidth={''}
                            />
                        </div>
                        <h4 className="project-name">{project.name}</h4>
                        <div
                            className="project-description">{project.comment && project.comment.length > 70 ? project.comment.substring(0, 70) + '...' : project.comment}</div>
                    </div>
                    <div className="product-grid-item-bottom">
                        <span className="project-number">{project.number}</span>
                    </div>
                </div>
            </div>

        );
    };

    const itemTemplate = (project: any, layout: any) => {
        if (!project) {
            return;
        }

        if (layout === 'list')
            return renderListItem(project);
        else if (layout === 'grid')
            return renderGridItem(project);
    };

    const filterTemplateForStatus = () => {
        const label = projectStatusFilter === null ? t('showAll') : (projectStatusFilter ? t('active') : t('inactive'));
        return (
            <div className="field-checkbox mt-2">
                <TriStateCheckbox value={projectStatusFilter} onChange={(e) => {
                    setProjectStatusFilter(e.value);
                }}/>
                <label className="pr-2">{label}</label>
            </div>
        );
    };

    const filterDropdownTemplate = (values: any[]) => {
        return (
            <MultiSelect
                className="justify-content-end"
                value={projectTypeFilters} options={values}
                style={{width: '14.2rem'}}
                selectedItemsLabel={'{0} ' + t('customDataTable:filter.items_selected')}
                maxSelectedLabels={1}
                onChange={(e: MultiSelectChangeParams) => {
                    setProjectTypeFilters(e.value);
                }}
                optionLabel={'name'}
                placeholder={t('customDataTable:filter.any')}
                // placeholder={t('type')}
            />
        );
    };

    const renderHeader = () => {
        return (
            <div
                className="grid-nogutter pb-2"
                style={{border: '3px solid var(--surface-border)', borderWidth: '0 0 1px 0'}}
            >
                <div className="grid grid-nogutter pt-1">
                    <div className="col-10" style={{textAlign: 'left'}}>
                        <div className="grid">
                            <div className="search-input p-2">
                                <span className="p-input-icon-left">
                                    <i className="pi pi-search"/>
                                    <InputText
                                        type="search"
                                        value={globalFilter}
                                        onInput={(e: any) => setGlobalFilter(e.target.value)}
                                        placeholder={t('customDataTable:search.placeholder')}
                                        tooltip={t('Suche nach Name und Beschreibung')}
                                        tooltipOptions={{position: 'top'}}
                                    />
                                </span>
                            </div>
                            <div className="p-2">
                                {filterDropdownTemplate(projectTypes)}
                            </div>
                            <div className="p-2" style={{width: '150px'}}>
                                {filterTemplateForStatus()}
                            </div>
                            {(globalFilter !== '' || projectTypeFilters.length > 0 || projectStatusFilter !== null) &&
                                <div className="p-2">
                                    <Button
                                        type="button" icon="pi pi-filter-slash" label={t('clearFilters')}
                                        className="p-button-outlined" onClick={clearFilter}
                                    />
                                </div>}

                        </div>
                    </div>
                    <div className="col-2" style={{textAlign: 'right'}}>
                        <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)}/>
                    </div>
                </div>
                {layout === 'list' &&
                    <div
                        className="flex flex-column md:flex-row align-items-center p-2 w-full cursor-pointer"
                        style={{border: '3px solid var(--surface-border)', borderWidth: '1px 0 1px 0'}}
                    >
                        <table style={{width: '100%'}}>
                            <tr>
                                <td style={{width: columnWidth.number}}>{t('number')}</td>
                                <td style={{width: columnWidth.name}}>{t('name')}</td>
                                <td style={{width: columnWidth.comment}}>{t('description')}</td>
                                <td style={{width: columnWidth.type}}>{t('type')}</td>
                                <td className={'flex sm:flex-column md:flex-column md:align-items-end'}>{t('active')}</td>
                            </tr>
                        </table>
                    </div>}
            </div>
        );
    };

    // const header = renderHeader();

    // TODO:
    // sticky Header: Header separat im DOM platzieren, ein Div definieren, wo alle Buttons und Header rein kommen und fixieren

    return (
        <>
            {showSkeleton && <SkeletonProjectOverview/>}
            {(!showSkeleton && props.keycloakToken !== '') &&
                <div className="project-overview card w-auto h-auto m-0">
                    {renderHeader()}
                    <div className={'relative w-full h-full'}>
                        <DataView
                            // className="overflow-y-scroll"
                            // style={{height: '80%'}}
                            value={projects}
                            layout={layout}
                            // header={header}
                            itemTemplate={itemTemplate}
                        />
                    </div>
                </div>}
        </>
    );
}
;
