/*
 * ProjectMonthBillingOverview.tsx
 * Author: lnappenfeld
 * Date: 01.08.2024
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {
    BillingItemSumMonth,
    getBillingItemSumMonth,
} from '../functions/Billing';
import {getGroupedItems} from '../../functions/functionLibrary';
import {FilterMatchMode, FilterOperator} from 'primereact/api';

type Props = {
    projectId: string,
    reload: boolean,
    setReload: (reload: boolean) => void,
}

export const ProjectMonthBillingOverview = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [billingItemSumMonth, setBillingItemSumMonth] = useState<BillingItemSumMonth[]>([]);

    // arrays for filter dropdowns
    const [billingTypes, setBillingTypes] = useState<any[]>([]);
    const [filterDates, setFilterDates] = useState<any[]>([]);

    useEffect(() => {
        _getBillingItemSumMonth();
    }, []);

    useEffect(() => {
        if (props.reload) {
            props.setReload(false);
            _getBillingItemSumMonth();
        }
    }, [props.reload]);

    const _getBillingItemSumMonth = () => {
        getBillingItemSumMonth(props.projectId).then(result => {
            setBillingItemSumMonth(result);
            setDataLoaded(true);

            // filter dropdown values
            setBillingTypes(getGroupedItems(result, 'billing_item_main_type_id', 'billing_item_main_type_name'));
            setFilterDates(getGroupedItems(result, 'date_month', 'date_month'));
        });
    };

    const columns: ColumnObject[] = [
        {
            field: 'project_name', header: t('common:project'),
            filter: true, display: false
        }, {
            field: 'billing_item_main_type_id', header: t('typeId'), style: {maxWidth: '100px'},
            filter: false, display: false
        }, {
            field: 'billing_item_main_type_name', header: t('type'),
            filter: true, filterField: 'billing_item_main_type_id', filterDropdownValues: billingTypes
        }, {
            field: 'sum_quantity', header: t('billing:attributes.sum_quantity'), type: 1,
            filter: true
        }, {
            field: 'price', header: t('billing:attributes.price'), type: 1,
            filter: true, currency: 'EUR'
        }, {
            field: 'sum_total', header: t('billing:attributes.sum_total'), type: 1,
            filter: true, currency: 'EUR'
        }, {
            field: 'description', header: t('description'),
            filter: true, display: false
        }, {
            field: 'date_month', header: t('common:month'), type: 4, dateFormat: 'MMMM YYYY',
            filter: true, filterField: 'date_month', filterDropdownValues: filterDates
        }
    ];

    const filters = {
        'project_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'billing_item_main_type_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'description': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'sum_quantity': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'price': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'sum_total': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'date_month': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        }
    };

    return (
        <CustomDataTable
            id='projectMonthBillingOverview'
            columns={columns}
            filters={filters}
            editable={false}
            sortable={true}
            value={billingItemSumMonth}
            ableToUpdate={true}
            dataloaded={dataLoaded}
            showExportCSV={true}
            showClearFiltersButton={true}
            displayColumnFilter={true}
            paginator={true}
        />
    );
};