import React, { useState, useEffect } from 'react';
import '../styles/conditionMonitoring.scss';
import {
    getSingleComponent,
    getSubPlants,
} from '../functions/SubPlantsService';
import { Button } from 'primereact/button';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { MultiSelect } from 'primereact/multiselect';
import CreateForm from './SubPlantsComponent/CreateForm';
import Details from '../details/Details';
import { useTranslation } from 'react-i18next';
import {CustomConfirmDialog} from '../../global/CustomConfirmDialog';
import {deletePlant, getFilteredPlants, getPlants} from '../functions/PlantsService';
import {showMessageOnError, showMessageOnSuccess} from '../../global/CustomToast';
import {hideWaitAnimation, showWaitAnimation} from '../../global/CustomWaitAnimation';

type SubPlantsProps = {
  colorTheme: any;
  // plantId: string;
  projectId: string;
  setCustomBreadcrumb:any;
};

const SubPlants = (props: SubPlantsProps) => {
    const { t } = useTranslation(['common']);

    const [items, setItems] = useState<any>([]);
    const [layout, setLayout] = useState<any>('grid');
    const [selectedFilter, setSelectedFilter] = useState<any>([]);
    const [isCreateForm, setIsCreateForm] = useState<boolean>(false);
    const [formType, setFormType] = useState<string>('');
    const [plantDetails, setPlantDetails] = useState<any>([]);
    const [plantId, setPlantId] = useState<string>('');
    const [plantName, setPlantName] = useState<string>('');
    const [details, setDetails] = useState<boolean>(false);
    const [componentName, setComponentName] = useState<string>('');
    const filterOptions = [
        { field: 'Vibration Monitoring', header: t('conditionMonitoring:vibration_monitoring') },
        { field: 'Mechanical Seal Filter', header: t('conditionMonitoring:seal_filter') },
        { field: 'Energy Efficiency', header: t('conditionMonitoring:energy_efficiency') },
        { field: 'Oil Monitoring', header: t('conditionMonitoring:oil_condition') },
    ];
    const columnWidth = {
        number: '16.5%',
        name: '20%',
        comment: '40%',
        type: '20%',
    };

    const statusTemplate = (rowData: any) => {
        let running_icon;
        if (rowData.status === 0 || rowData.status <= 20) {
            running_icon = <div className="green-circle">{rowData.status}</div>;
        } else if (rowData.status > 20 || rowData.status <= 80) {
            running_icon = <div className="orange-circle">{rowData.status}</div>;
        } else if (rowData.status > 80) {
            running_icon = <div className="red-circle">{rowData.status}</div>;
        }
        return <div>{running_icon}</div>;
    };

    const openComponent = (item: any) => {
        setDetails(true);
        setPlantDetails({
            id: item['id'],
            name: item['name'],
            sapNumber: item['sapNumber'],
        });
        setComponentName(item['name']);

        if (item['elementTypeId'] === 14)
            window.location.hash = '#conditionomonitoring_' + item['id'];
        else if (item['elementTypeId'] === 13) {
            getSingleComponent(props.projectId, item['parentId']).then(response => {
                if (response) {
                    localStorage.setItem('plants', response[0]['element_name']);
                    localStorage.setItem('plantId', response[0]['element_id']);
                    localStorage.setItem('component', item['name']);
                    localStorage.setItem('componentId', item['id']);
                    window.location.hash = '#conditionomonitoring_' + item['id'] + '_detail';
                }
            });
        }
    };
    const renderListItem = (item: any) => {
        return (
            <div className="col-12 table-row">
                <div className="flex flex-column md:flex-row align-items-center p-2 w-full cursor-pointer">
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr className="">
                                <td
                                    className="custom-td"
                                    style={{ width: columnWidth.number }}
                                    onClick={() => {
                                        openComponent(item);
                                    }}
                                >
                                    {item.name}
                                </td>
                                <td
                                    className="custom-td"
                                    style={{ width: columnWidth.number }}
                                    onClick={() => {
                                        openComponent(item);
                                    }}
                                >
                                    {statusTemplate(item)}
                                </td>
                                <td
                                    className="custom-td"
                                    style={{ width: columnWidth.name }}
                                    onClick={() => {
                                        openComponent(item);
                                    }}
                                >
                                    {item.alerts}
                                </td>
                                <td
                                    className="custom-td"
                                    onClick={() => {
                                        openComponent(item);
                                    }}
                                >
                                    {item.timestamp}
                                </td>
                                <td>
                                    <div className="p-formgroup-inline">
                                        <Button
                                            icon="pi pi-pencil"
                                            className="p-button-rounded p-button-secondary p-button-text table-edit-button"
                                            aria-label="Edit"
                                            tooltip="Edit"
                                            onClick={() => {
                                                setFormType('editForm');
                                                setPlantDetails({
                                                    id: item['id'],
                                                    latitude: item['latitude'],
                                                    longitude: item['longitude'],
                                                    plant_name: item['name'],
                                                    altitude: item['altitude'],
                                                    sapNumber: item['sapNumber'],
                                                    description: item['description'],
                                                    parentId: item['parentId'],
                                                });
                                                setIsCreateForm(true);
                                            }}
                                        />
                                        <Button
                                            icon="pi pi-trash"
                                            className="p-button-rounded p-button-danger p-button-text table-edit-button"
                                            aria-label="Delete"
                                            tooltip="Delete"
                                            onClick={(e: any) => {
                                                CustomConfirmDialog({
                                                    message: t('conditionMonitoring:plant_deleted_message') + item['name'] + t('conditionMonitoring:plant_deleted_message_con'),
                                                    header: t('confirmation'),
                                                    translation: t,
                                                    onConfirm: () => {
                                                        const data = {
                                                            project_id: props.projectId,
                                                            element_id: item['id'],
                                                        };
                                                        deletePlant(data).then((resp:any) =>{
                                                            if (resp['error']) {
                                                                showMessageOnError(t('error'), t('conditionMonitoring:delete_error'));
                                                            } else {
                                                                showMessageOnSuccess(
                                                                    t('conditionMonitoring:object_deleted'),
                                                                    t('conditionMonitoring:object_deleted_first') + item['name'] + t('conditionMonitoring:object_deleted_second')
                                                                );
                                                                getAllPlants(plantId);
                                                            }
                                                        });
                                                    }
                                                });

                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    const renderGridItem = (item: any) => {
        const color = props.colorTheme === 'dark' ? 'white' : 'black';
        const bookmarkColor = props.colorTheme === 'dark' ? 'black' : 'white';
        const bgColorMiddle = props.colorTheme === 'dark' ? '#050505' : '#eaeaea';
        const bgColor = props.colorTheme === 'dark' ? '#333333' : '#ffffff';
        const borderColor = props.colorTheme === 'dark' ? '#444444' : '#efefef';
        const dropdownBgColor = props.colorTheme === 'dark' ? 'black' : 'white';
        const dropdownFontColor = props.colorTheme === 'dark' ? 'white' : 'black';
        return (
            <>
                <div
                    key={item.name + '_' + item.color + '_' + item.bookmarkColor}
                    className="grid flex flex-column card-container green-container card-example mt-2"
                    style={{
                        color: color,
                        background:
              'linear-gradient(180deg, ' +
              bgColor +
              ' 0%, ' +
              bgColorMiddle +
              ' 0%, ' +
              item.color +
              ' 100%)',
                        borderColor: borderColor,
                    }}
                    onClick={() => {
                        openComponent(item);
                    }}
                >
                    <div className="flex w-full">
                        <div className="grid w-full justify-content-end card-top-line">
                            {/* <div className="col bookmark-content">*/}
                            {/*    {item.bookmarkColor && (*/}
                            {/*        <>*/}
                            {/*            <i*/}
                            {/*                className="pi pi-bookmark-fill bookmark-color"*/}
                            {/*                style={{ color: item.bookmarkColor }}*/}
                            {/*            />*/}
                            {/*            <i*/}
                            {/*                className="pi pi-bookmark bookmark-color"*/}
                            {/*                style={{ color: color }}*/}
                            {/*            />*/}
                            {/*        </>*/}
                            {/*    )}*/}
                            {/*    {item.bookmarkColor === undefined && (*/}
                            {/*        <i*/}
                            {/*            className="pi pi-bookmark-fill bookmark-white"*/}
                            {/*            style={{ color: 'white' }}*/}
                            {/*        />*/}
                            {/*    )}*/}
                            {/* </div>*/}
                            <div className="col-auto m-auto card-menu">
                                <div className='dropdown'><i className="pi pi-ellipsis-h dropbtn" />
                                    <div className='dropdown-content'>
                                        <a onClick={(e)=> {
                                            e.stopPropagation();
                                            setFormType('editForm');
                                            setPlantDetails({
                                                id: item['id'],
                                                latitude: item['latitude'],
                                                longitude: item['longitude'],
                                                plant_name: item['name'],
                                                altitude: item['altitude'],
                                                sapNumber: item['sapNumber'],
                                                description: item['description'],
                                            });
                                            setIsCreateForm(true);
                                        }} style={{
                                            color: dropdownFontColor, backgroundColor: dropdownBgColor
                                        }}>{t('edit')}</a>
                                        <a onClick={(e: any)=>{
                                            e.stopPropagation();
                                            CustomConfirmDialog({
                                                message: t('conditionMonitoring:plant_deleted_message') + item['name'] + t('conditionMonitoring:plant_deleted_message_con'),
                                                header: t('confirmation'),
                                                translation: t,
                                                onConfirm: () => {
                                                    const data = {
                                                        project_id: props.projectId,
                                                        element_id: item['id'],
                                                    };
                                                    deletePlant(data).then((resp:any) =>{
                                                        if (resp['error']) {
                                                            showMessageOnError(t('error'), t('conditionMonitoring:delete_error'));
                                                        } else {
                                                            showMessageOnSuccess(
                                                                t('conditionMonitoring:object_deleted'),
                                                                t('conditionMonitoring:object_deleted_first') + item['name'] + t('conditionMonitoring:object_deleted_second')
                                                            );
                                                            getAllPlants(plantId);
                                                        }
                                                    });
                                                }
                                            });
                                        }} style={{
                                            color: dropdownFontColor, backgroundColor: dropdownBgColor
                                        }}>{t('delete')}</a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-grow-1 w-full grid grid-nogutter">
                        <div className="grid grid-nogutter h-full">
                            <div className="col-12 h-auto">
                                {item.name}
                                <br />
                                <div className="subline">
                                    {item.description && item.description }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex grid">
                        <div className="col-3">
                            {/* {item['name'] === '1 - P4.2 (Doppelfilter)' ? 96 : item['value']}*/}
                        </div>
                        <div className="col-6 m-auto">
                            {/* <div className="offline-border">*/}
                            {/*    <div*/}
                            {/*        className="offline"*/}
                            {/*        style={{*/}
                            {/*            width: item.offlineHours*/}
                            {/*                ? (item.offlineHours / 24) * 100 + '%'*/}
                            {/*                : '0%',*/}
                            {/*        }}*/}
                            {/*    ></div>*/}
                            {/* </div>*/}
                        </div>
                        <div className="col-3">
                            {/* {item.alarm && (*/}
                            {/*    <div className="arrow-up">*/}
                            {/*        <i*/}
                            {/*            className="pi pi-exclamation-triangle"*/}
                            {/*            style={{ color: 'black' }}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/* )}*/}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const renderHeader = () => {
        return (
            <>
                <div className="grid-nogutter pb-2">
                    <div className="grid">
                        <div className="col-8 flex flex-wrap">
                            <div className="search-input p-2">
                                <span className="p-input-icon-left">
                                    <i className="pi pi-search"></i>
                                    <input
                                        type="search"
                                        placeholder={t('conditionMonitoring:search')}
                                        className="p-inputtext p-component"
                                        onChange={(e) => {
                                            if (e.target.value === '') {
                                                showWaitAnimation();
                                                getSubPlants(props.projectId, localStorage.getItem('plantId')).then((response: any) => {
                                                    setItems(response);
                                                    hideWaitAnimation();
                                                });
                                            } else {
                                                showWaitAnimation();
                                                getFilteredPlants(props.projectId, e.target.value).then((response) => {
                                                    setItems(response);
                                                    hideWaitAnimation();
                                                });
                                            }
                                        }}
                                    />
                                </span>
                            </div>
                            <div className="p-2">
                                <MultiSelect
                                    value={selectedFilter}
                                    placeholder={t('conditionMonitoring:filter')}
                                    options={filterOptions}
                                    optionLabel="header"
                                    onChange={(e) => {
                                        localStorage.setItem('filterOptions', JSON.stringify(e.value));
                                        setSelectedFilter(e.value);
                                    }}
                                    style={{ width: '15em' }}
                                />
                            </div>
                        </div>

                        <div className="col-2 mt-2">
                            <Button
                                label={t('conditionMonitoring:create_plant')}
                                icon="pi pi-plus"
                                className="p-button-text p-button-raised"
                                style={{ float: 'right' }}
                                onClick={() => {
                                    setIsCreateForm(true);
                                    setFormType('createForm');
                                }}
                            />
                        </div>
                        <div className="col-2 mt-3" style={{ textAlign: 'right' }}>
                            <DataViewLayoutOptions
                                layout={layout}
                                onChange={(e) => setLayout(e.value)}
                            />
                        </div>
                    </div>
                </div>
                {layout === 'list' && (
                    <div className="flex flex-column cursor-pointer">
                        <table style={{ width: '100%' }}>
                            <tr>
                                <td style={{ width: columnWidth.number }}>
                                    {t('conditionMonitoring:plant_name')}
                                </td>
                                <td style={{ width: columnWidth.number }}>
                                    {t('conditionMonitoring:status')}
                                </td>
                                <td style={{ width: columnWidth.name }}>
                                    {t('conditionMonitoring:alerts')}
                                </td>
                                <td style={{ width: '28%' }}>
                                    {t('conditionMonitoring:timestamp')}
                                </td>
                                <td>{t('conditionMonitoring:option')}</td>
                            </tr>
                        </table>
                    </div>
                )}
            </>
        );
    };

    const header = renderHeader();

    const itemTemplate = (plant: any, layout: any) => {
        if (!plant) {
            return;
        }

        if (layout === 'list') return renderListItem(plant);
        else if (layout === 'grid') return renderGridItem(plant);
    };

    const getAllPlants = (plantId: string) => {
        showWaitAnimation();
        getSubPlants(props.projectId, plantId).then((response: any) => {
            setItems(response);
            hideWaitAnimation();
        });
    };

    useEffect(() => {
        const index = location.hash.lastIndexOf('_');
        const plantId = location.hash.substr(index+1);
        setPlantId(plantId);
        setPlantName(localStorage.getItem('plants')|| '');
        getAllPlants(plantId);
        props.setCustomBreadcrumb([
            {id: 1, label: 'Condition Monitoring', command:()=>{window.location.hash = '#conditionomonitoring';}},
            {id: 2, label: localStorage.getItem('plants'), command:()=>{}}
        ]);
        const filterOption = JSON.parse(localStorage.getItem('filterOptions') as string);
        setSelectedFilter(filterOption);

    }, []);

    return (
        <>
            {details && (
                <Details
                    projectId={props.projectId}
                    colorTheme={props.colorTheme}
                    setCustomBreadcrumb={props.setCustomBreadcrumb}
                />
            )}
            {!details && (
                <>
                    <DataView
                        className="plants-card card"
                        value={items}
                        layout={layout}
                        header={header}
                        itemTemplate={itemTemplate}
                        // paginator
                        rows={60}
                        // sortOrder={sortOrder} sortField={sortField}
                    />
                    {isCreateForm ? (
                        <CreateForm
                            projectId={props.projectId}
                            plants={plantDetails}
                            type={formType}
                            modal={isCreateForm}
                            plantId={plantId}
                            hidePopup={() => {
                                setIsCreateForm(false);
                                getAllPlants(plantId);
                                setPlantDetails([]);
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </>
            )}
        </>
    );
};

export default SubPlants;
