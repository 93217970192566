/*
 * ReportForm.tsx
 * Author: lnappenfeld
 * Date: 14.07.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {useForm} from 'react-hook-form';
import {createReport, createReportPreview, updateReport} from '../functions/Reporting';
import Input from '../global/Input';
import ActionMultiSelectList from '../userManagement/ActionMultiSelectList';
import IntervalSelection from '../global/IntervalSelection';
import EvaluationPeriodSelectList from './EvaluationPeriodSelectList';
import ReportTemplateSelectList from './ReportTemplateSelectList';
import {TabMenu} from 'primereact/tabmenu';
import {Button} from 'primereact/button';
import {Calendar} from 'primereact/calendar';
import {confirmDialog} from 'primereact/confirmdialog';
import NullMeasurementSelectList from './NullMeasurementSelectList';
import YAxisModeSelectList from './YAxisModeSelectList';
import IntegerSelectList from '../global/IntegerSelectList';
import DaytimeSelectList from '../global/DaytimeSelectList';
import TimezoneSelectList from '../global/TimezoneSelectList';
import LanguageSelectList from '../global/LanguageSelectList';
import ReportTypeSelectList from './ReportTypeSelectList';
import {getUserFromLocalStorage, Nullable} from '../functions/Global';
import {hideWaitAnimation, showWaitAnimation} from '../global/CustomWaitAnimation';
import {getNextcloudProjectFolderId} from '../functions/Project';

type Props = {
    projectId: string,
    report: any,
    onFinished: (success: boolean) => void,
}

const ReportForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [menuIndex, setMenuIndex] = useState((props.report && props.report.report_type_id && props.report.report_type_id === 2) ? 1 : 0);
    const [previewType, setPreviewType] = useState<number>(0);
    const [previewFrom, setPreviewFrom] = useState<any>(null);
    const [previewTo, setPreviewTo] = useState<any>(null);

    const [executionInterval, setExecutionInterval] = useState<Nullable<string>>(props.report ? props.report.execution_interval : null);
    const [executionIntervalText, setExecutionIntervalText] = useState<string>('');
    const [evaluationPeriod, setEvaluationPeriod] = useState<any>(null);

    const [hasProjectNextcloud, setHastProjectNextcloud] = useState<boolean>(false);


    const reportId = props.report ? props.report.id : null;

    const menuItems = [
        {label: t('configuration'), icon: 'pi pi-fw pi-home'},
        {label: t('reportingManagement:global.manualReport'), icon: 'pi pi-fw pi-calendar'}
    ];

    const reportStatus = [
        {name: t('reportingManagement:global.reportPeriod'), value: 0},
        {name: t('reportingManagement:global.manualPeriod'), value: 1},
    ];

    const defaultValues = {
        'name': props.report ? props.report.name : null,
        'description': props.report ? props.report.description : null,
        'is_enabled': props.report ? props.report.is_enabled : true,
        'report_type_id': props.report && props.report.report_type_id ? props.report.report_type_id.toString() : '0',
        'action_ids': props.report ? props.report.action_ids : null,
        'evaluation_period': props.report ? props.report.evaluation_period : '6',
        'evaluation_period_daytime_from': props.report && props.report.evaluation_period_daytime_from ? props.report.evaluation_period_daytime_from : '0',
        'evaluation_period_daytime_to': props.report && props.report.evaluation_period_daytime_to ? props.report.evaluation_period_daytime_to : '0',
        'timezone': props.report && props.report.timezone ? props.report.timezone : getUserFromLocalStorage().timezone,
        'language_id': props.report && props.report.language_id ? props.report.language_id : getUserFromLocalStorage().language_id,
        'email_subject': props.report ? props.report.email_subject : null,
        'email_message': props.report ? props.report.email_message : null,
        'report_template_id': props.report ? props.report.report_template_id : null,
        'null_measurement': props.report ? props.report.null_measurement : '0',
        'y_axis_mode': props.report ? props.report.y_axis_mode : '0',
        'y_axis_min': props.report ? props.report.y_axis_min : -0.1,
        'y_axis_max': props.report ? props.report.y_axis_max : 0.1,
        'charts_per_page': props.report ? props.report.charts_per_page : '2',
        'save_in_nextcloud': props.report ? props.report.save_in_nextcloud : false,
        'send_as_pdf': props.report ? props.report.send_as_pdf : false,
        'generate_csv': props.report ? props.report.generate_csv : false,
        'generate_xls': props.report ? props.report.generate_xls : false,
        // Nur für die automatische Validierung. Das executionInterval wird weiterhin in der Komponente
        // 'IntervalSelection' gesetzt und in dem state 'executionInterval' verwaltet
        'interval_day': props.report && props.report.execution_interval ? props.report.execution_interval.split(';')[1].split(',') : [],
        'interval_time': props.report && props.report.execution_interval ? props.report.execution_interval.split(';')[2].split(',') : []
    };

    const {control, formState: {errors}, handleSubmit, setValue, getValues, watch} = useForm({defaultValues});
    watch('is_enabled');
    watch('report_type_id');
    watch('save_in_nextcloud');
    watch('send_as_pdf');
    watch('generate_csv');
    watch('generate_xls');
    watch('null_measurement');
    watch('y_axis_mode');
    watch('charts_per_page');
    watch('evaluation_period');
    watch('evaluation_period_daytime_from');
    watch('evaluation_period_daytime_to');
    watch('interval_day');
    watch('interval_time');

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    useEffect(() => {
        getNextcloudProjectFolderId(props.projectId).then(result => {
            if (result && result.folderId !== null) {
                setHastProjectNextcloud(true);
            } else {
                setHastProjectNextcloud(false);
            }
        });
    }, []);

    useEffect(() => {
        if (evaluationPeriod && !evaluationPeriod.isDaytimeRelevant) {
            setValue('evaluation_period_daytime_from', '0');
            setValue('evaluation_period_daytime_to', '0');
        }
    }, [evaluationPeriod]);

    useEffect(() => {
        if (previewFrom) {
            if (new Date(previewFrom) > previewTo) {
                setPreviewTo(previewFrom);
            }
        }
    }, [previewFrom]);

    useEffect(() => {
        if (previewTo) {
            if (new Date(previewTo) < previewFrom) {
                setPreviewFrom(previewTo);
            }
        }
    }, [previewTo]);

    const getConfig = () => {
        // replace {X} with DaytimeFrom and {Y} with DaytimeTo e.g. in 'Gestern von {X} Uhr bis {Y} Uhr'
        if (evaluationPeriod.isDaytimeRelevant) {
            let evaluationPeriodText = evaluationPeriod.label.replace('{X}', getValues('evaluation_period_daytime_from'));
            evaluationPeriodText = evaluationPeriodText.replace('{Y}', getValues('evaluation_period_daytime_to'));
            evaluationPeriod.label = evaluationPeriodText;
        }

        return {
            intervalText: parseInt(getValues('report_type_id')) === 0 ? executionIntervalText : '-',
            evaluationPeriod: evaluationPeriod,
            evaluationPeriodDaytimeFrom: getValues('evaluation_period_daytime_from'),
            evaluationPeriodDaytimeTo: getValues('evaluation_period_daytime_to'),
            timezone: getValues('timezone'),
            languageId: getValues('language_id'),
            nullMeasurement: getValues('null_measurement'),
            yAxis: {
                mode: getValues('y_axis_mode'),
                min: getValues('y_axis_mode') === '1' ? getValues('y_axis_min') : -0.1,
                max: getValues('y_axis_mode') === '1' ? getValues('y_axis_max') : 0.1,
            },
            chartsPerPage: getValues('charts_per_page'),
            saveInNextcloud: getValues('save_in_nextcloud'),
            sendAsPDF: getValues('send_as_pdf'),
            generateCSV: getValues('generate_csv'),
            generateXLS: getValues('generate_xls')
        };
    };

    const showPreview = () => {
        const config = getConfig();

        if (previewFrom && previewTo) {
            const from = new Date(previewFrom);
            const to = new Date(previewTo);
            from.setSeconds(0);
            to.setSeconds(0);
            config.evaluationPeriod.from = from.getTime().toString();
            config.evaluationPeriod.to = to.getTime().toString();
        }

        let data = {
            'name': getValues('name'),
            'description': getValues('description'),
            'report_type_id': parseInt(getValues('report_type_id')),
            'configuration': config,
            'execution_interval': executionInterval,
            'email_subject': getValues('email_subject'),
            'email_message': getValues('email_message'),
            'report_template_id': getValues('report_template_id'),
            'project_id': props.projectId,
        };

        if (reportId) {
            const reportAddId = {'report_id': reportId};
            data = {...reportAddId, ...data};
        }

        confirmDialog({
            message: t('reportingManagement:dialogs.createReportPreview.message'),
            header: t('reportingManagement:dialogs.createReportPreview.header'),
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: t('yes'),
            rejectLabel: t('no'),
            accept: () => {
                showWaitAnimation();
                createReportPreview(data).then((result: any) => {
                    if (result.error)
                        showMessageOnError(t('error'), result.error);

                    hideWaitAnimation();
                });
            },
        });
    };

    const saveReport = (report: any) => {
        let data = {
            'name': report.name,
            'description': report.description,
            'action_ids': report.action_ids,
            'report_type_id': parseInt(getValues('report_type_id')),
            'execution_interval': executionInterval,
            'configuration': getConfig(),
            'email_subject': report.email_subject,
            'email_message': report.email_message,
            'report_template_id': report.report_template_id,
            'project_id': props.projectId,
            'is_enabled': parseInt(getValues('report_type_id')) === 2 ? null : report.is_enabled
        };

        // console.log('data: ', data);

        if (reportId) {
            const reportAddId = {'report_id': reportId};
            data = {...reportAddId, ...data};

            updateReport(data).then(result => {
                if (!result.error) {
                    showMessageOnSuccess(t('success'), t('reportingManagement:toasts.reportUpdated'));
                    props.onFinished(true);
                } else {
                    showMessageOnError(t('error'), result.error);
                }
            });

        } else {
            createReport(data).then(result => {
                if (result.error)
                    showMessageOnError(t('error'), result.error);
                else
                    showMessageOnSuccess(t('success'), t('reportingManagement:toasts.reportCreated'));
                props.onFinished(true);
            });
        }
    };


    return (
        <>
            <TabMenu model={menuItems} activeIndex={menuIndex} onTabChange={(e) => setMenuIndex(e.index)}/>
            <form id='formDialog' onSubmit={handleSubmit(saveReport)}>
                {menuIndex === 0 &&
                <div className={'mt-3'}>
                    <div className={'card'}>
                        <div className='grid'>
                            <div className='col-12 md:col-6'>
                                <Input
                                    edit={true} label={t('name')} name={'name'}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('name') + ' ' + t('input:required')}}
                                />
                                <Input
                                    edit={true} label={t('description')} name={'description'}
                                    type={'textarea'} validationControl={control} validationRules={{required: false}}
                                />
                            </div>
                            <div className='col-12 md:col-6'>
                                <Input
                                    edit={true} label={t('reportingManagement:attributes.emailSubject')} name={'email_subject'}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('reporting:emailSubject') + ' ' + t('input:required')}}
                                />
                                <Input
                                    edit={true} label={t('reportingManagement:attributes.emailMessage')} name={'email_message'}
                                    type={'textarea'} validationControl={control} validationRules={{required: false}}
                                />
                            </div>
                        </div>
                        {parseInt(getValues('report_type_id')) === 0 &&
                        <ActionMultiSelectList
                            projectId={props.projectId}
                            name={'action_ids'}
                            label={t('actions')}
                            actionTypeId={1} // Only E-Mail
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('action') + ' ' + t('input:required')}}
                        />}
                        <div className='grid'>
                            <div className='col-12 md:col-6'>
                                <ReportTypeSelectList
                                    label={t('type')}
                                    onChange={e => {
                                        if (e.value === 2) { // Statischer Report
                                            setValue('is_enabled', null);
                                        } else {
                                            setValue('is_enabled', true);
                                        }
                                    }}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('language') + ' ' + t('input:required')}}
                                />
                            </div>
                            <div className='col-12 md:col-6'>
                                <ReportTemplateSelectList
                                    projectId={props.projectId}
                                    name={'report_template_id'}
                                    label={t('reportingManagement:global.reportTemplate')}
                                    onChange={e => {
                                        setValue('report_template_id', e.value);
                                    }}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('reportingManagement:attributes.reportTemplate') + ' ' + t('input:required')}}
                                />
                            </div>
                        </div>


                        <div className='grid'>
                            <div className='col-12 md:col-6'>
                                <TimezoneSelectList
                                    label={t('timezone')}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('timezone') + ' ' + t('input:required')}}
                                />
                            </div>
                            <div className='col-12 md:col-6'>
                                <LanguageSelectList
                                    label={t('language')}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('language') + ' ' + t('input:required')}}
                                />
                            </div>
                        </div>
                    </div>
                    {parseInt(getValues('report_type_id')) === 0 &&
                    <div className={'card'}>
                        <h5>{t('reportingManagement:attributes.executionInterval')}</h5>
                        <IntervalSelection
                            interval={executionInterval}
                            onChange={(interval: Nullable<string>, intervalText: string) => {
                                setExecutionInterval(interval);
                                setExecutionIntervalText(intervalText);
                            }}

                            intervalDay={getValues('interval_day')}
                            setIntervalDay={(intervalDay: string[]) => {
                                // console.log('Edit: intervalDay: ', intervalDay);
                                setValue('interval_day', intervalDay);
                            }}
                            intervalTime={getValues('interval_time')}

                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                        />
                    </div>}
                    <div className={'card'}>
                        <h5>{t('reportingManagement:attributes.evaluationPeriod')}</h5>
                        <div className='grid'>
                            <div className='col-12 md:col-4'>
                                <EvaluationPeriodSelectList
                                    label={t('reportingManagement:attributes.evaluationPeriod')}
                                    name={'evaluation_period'}
                                    value={getValues('evaluation_period')} // for init value in useEffect
                                    onChange={(evaluationPeriod: any) => {
                                    // contains from and to
                                        setEvaluationPeriod(evaluationPeriod);
                                    }}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('reportingManagement:attributes.evaluationPeriod') + ' ' + t('input:required')}}
                                />
                            </div>
                            <div className='col-12 md:col-4'>
                                <DaytimeSelectList
                                    name={'evaluation_period_daytime_from'}
                                    label={t('reportingManagement:attributes.evaluationPeriodDaytimeFrom')}
                                    value={getValues('evaluation_period_daytime_from')}
                                    onChange={e => {
                                        setValue('evaluation_period_daytime_from', e.value);
                                    }}
                                    disabled={evaluationPeriod && !evaluationPeriod.isDaytimeRelevant}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('reportingManagement:attributes.evaluationPeriodDaytimeFrom') + ' ' + t('input:required')}}
                                />
                            </div>
                            <div className='col-12 md:col-4'>
                                <DaytimeSelectList
                                    label={t('reportingManagement:attributes.evaluationPeriodDaytimeTo')}
                                    name={'evaluation_period_daytime_to'}
                                    value={getValues('evaluation_period_daytime_to')}
                                    onChange={(daytimeTo: any) => {
                                        setValue('evaluation_period_daytime_to', daytimeTo.value);
                                    }}
                                    disabled={evaluationPeriod && !evaluationPeriod.isDaytimeRelevant}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('reportingManagement:attributes.evaluationPeriodDaytimeTo') + ' ' + t('input:required')}}
                                />
                            </div>
                        </div>
                    </div>
                    {parseInt(getValues('report_type_id')) === 0 &&
                    <div className={'card'}>
                        <div className='grid'>
                            <div className='col-12 md:col-4'>
                                <Input
                                    edit={true}
                                    label={t('enabled')}
                                    name={'is_enabled'}
                                    disabled={parseInt(getValues('report_type_id')) === 2}
                                    type={'inputswitch'}
                                    value={getValues('is_enabled')}
                                    onChange={e => setValue('is_enabled', e.value)}
                                />
                            </div>
                            <div className='col-12 md:col-4'>
                                {/* <Input
                                    edit={true}
                                    disabled={true} // disabled bis wir das beim Reporting anbieten
                                    label={t('reportingManagement:attributes.generateCSV')}
                                    name={'generate_csv'}
                                    type={'inputswitch'}
                                    value={getValues('generate_csv')}
                                    onChange={e => setValue('generate_csv', e.value)}
                                /> */}
                                <Input
                                    edit={true}
                                    disabled={!hasProjectNextcloud}
                                    tooltip={t(hasProjectNextcloud
                                        ? 'reportingManagement:tooltips.saveInNextcloud'
                                        : 'reportingManagement:tooltips.saveInNextcloudNotPossible'
                                    )}
                                    label={t('reportingManagement:attributes.saveInNextcloud')}
                                    name={'save_in_nextcloud'}
                                    type={'inputswitch'}
                                    value={getValues('save_in_nextcloud')}
                                    onChange={e => setValue('save_in_nextcloud', e.value)}
                                />
                            </div>
                            <div className='col-12 md:col-4'>
                                {/* <Input
                                    edit={true}
                                    disabled={true} // disabled bis wir das beim Reporting anbieten
                                    label={t('reportingManagement:attributes.generateXLS')}
                                    name={'generate_xls'}
                                    type={'inputswitch'}
                                    value={getValues('generate_xls')}
                                    onChange={e => setValue('generate_xls', e.value)}
                                /> */}
                                <Input
                                    edit={true}
                                    tooltip={t('reportingManagement:tooltips.sendAsPDF')}
                                    label={t('reportingManagement:attributes.sendAsPDF')}
                                    name={'send_as_pdf'}
                                    type={'inputswitch'}
                                    value={getValues('send_as_pdf')}
                                    onChange={e => setValue('send_as_pdf', e.value)}
                                />
                            </div>
                        </div>
                    </div>}
                    <div className={'card'}>
                        <div className='grid'>
                            <div className='col-12 md:col-6'>
                                <NullMeasurementSelectList
                                    label={t('reportingManagement:attributes.nullMeasurement')}
                                    name={'null_measurement'}
                                    value={getValues('null_measurement')} // for init value in useEffect
                                    onChange={(nullMeasurement: any) => {
                                        setValue('null_measurement', nullMeasurement.value);
                                    }}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('reportingManagement:attributes.nullMeasurement') + ' ' + t('input:required')}}
                                />
                            </div>
                            <div className='col-12 md:col-6'>
                                <IntegerSelectList
                                    min={1} max={2}
                                    label={t('reportingManagement:attributes.chartsPerPage')}
                                    name={'charts_per_page'}
                                    value={getValues('charts_per_page')} // for init value in useEffect
                                    onChange={(chartsPerPage: any) => {
                                        setValue('charts_per_page', chartsPerPage.value);
                                    }}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('reportingManagement:attributes.chartsPerPage') + ' ' + t('input:required')}}
                                />
                            </div>
                        </div>
                        <div className='grid'>
                            <div className='col-12 md:col-4'>
                                <YAxisModeSelectList
                                    label={t('reportingManagement:attributes.yAxisMode')}
                                    name={'y_axis_mode'}
                                    value={getValues('y_axis_mode')} // for init value in useEffect
                                    onChange={(yAxisMode: any) => {
                                        setValue('y_axis_mode', yAxisMode.value);
                                    }}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('reportingManagement:attributes.yAxisMode') + ' ' + t('input:required')}}
                                />
                            </div>
                            {/* {getValues('y_axis_mode') === '1' &&*/}
                            <>
                                <div className='col-12 md:col-4'>
                                    <Input
                                        edit={true} label={t('reportingManagement:attributes.yAxisMin')} name={'y_axis_min'} type={'number'}
                                        value={getValues('y_axis_min')}
                                        disabled={getValues('y_axis_mode') === '0'}
                                        onChange={(yAxisMin: any) => {
                                            setValue('y_axis_min', yAxisMin.value);
                                        }}
                                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                                        validationRules={{required: t('reportingManagement:attributes.yAxisMin') + ' ' + t('input:required')}}
                                    />
                                </div>
                                <div className='col-12 md:col-4'>
                                    <Input
                                        edit={true} label={t('reportingManagement:attributes.yAxisMax')} name={'y_axis_max'} type={'number'}
                                        value={getValues('y_axis_max')}
                                        disabled={getValues('y_axis_mode') === '0'}
                                        onChange={(yAxisMax: any) => {
                                            setValue('y_axis_max', yAxisMax.value);
                                        }}
                                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                                        validationRules={{required: t('reportingManagement:attributes.yAxisMax') + ' ' + t('input:required')}}
                                    />
                                </div>
                            </>
                        </div>
                    </div>
                </div>}
                {menuIndex === 1 &&
                <div className={'mt-3'}>
                    <div className={'card'}>
                        <Input
                            edit={true}
                            label={t('reportingManagement:attributes.evaluationPeriodType')}
                            name={'previewType'}
                            type={'selectButton'}
                            value={previewType}
                            onChange={(e: any) => {
                                if (e.value !== null) {
                                    setPreviewType(e.value);
                                }
                                if (e.value === 0) { // Report über Konfigurationszeitraum
                                    setPreviewFrom(null);
                                    setPreviewTo(null);
                                }

                            }}
                            selectButtonOptions={reportStatus}
                        />

                        {previewType === 0 &&
                        <div className='grid'>
                            <div className={ evaluationPeriod && evaluationPeriod.isDaytimeRelevant ? 'col-12 md:col-4' : 'col-12 md:col-12'}>
                                <EvaluationPeriodSelectList
                                    label={t('reportingManagement:attributes.evaluationPeriod')}
                                    name={'evaluation_period'}
                                    disabled={true}
                                    value={getValues('evaluation_period')} // for init value in useEffect
                                    onChange={(evaluationPeriod: any) => {
                                        // contains from and to
                                        setEvaluationPeriod(evaluationPeriod);
                                    }}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('reportingManagement:attributes.evaluationPeriod') + ' ' + t('input:required')}}
                                />
                            </div>
                            {evaluationPeriod && evaluationPeriod.isDaytimeRelevant &&
                            <>
                                <div className="col-12 md:col-4">
                                    <DaytimeSelectList
                                        name={'evaluation_period_daytime_from'}
                                        label={t('reportingManagement:attributes.evaluationPeriodDaytimeFrom')}

                                        onChange={e => {
                                            setValue('evaluation_period_daytime_from', e.value);
                                        }}
                                        disabled={true}
                                        validationControl={control}
                                        validationErrorMessage={getFormErrorMessage}
                                        validationRules={{required: t('reportingManagement:attributes.evaluationPeriodDaytimeFrom') + ' ' + t('input:required')}}/>
                                </div>
                                <div className="col-12 md:col-4">
                                    <DaytimeSelectList
                                        name={'evaluation_period_daytime_to'}
                                        label={t('reportingManagement:attributes.evaluationPeriodDaytimeTo')}
                                        onChange={e => {
                                            setValue('evaluation_period_daytime_to', e.value);
                                        }}
                                        disabled={true}
                                        validationControl={control}
                                        validationErrorMessage={getFormErrorMessage}
                                        validationRules={{required: t('reportingManagement:attributes.evaluationPeriodDaytimeTo') + ' ' + t('input:required')}}/>
                                </div>
                            </>}
                        </div>}
                        {previewType === 1 &&
                        <>
                            <div className='grid'>
                                <div className='col-auto mx-2'>
                                    <h4>{t('from')}</h4>
                                    <Calendar
                                        id="basic"
                                        value={previewFrom}
                                        onChange={(e) => setPreviewFrom(e.value)}
                                        showTime inline
                                        dateFormat='dd.mm.yy'
                                    />
                                </div>
                                <div className='col-auto mx-2'>
                                    <h4>{t('to')}</h4>
                                    <Calendar
                                        id="basic"
                                        value={previewTo}
                                        onChange={(e) => setPreviewTo(e.value)}
                                        showTime inline
                                        dateFormat='dd.mm.yy'
                                    />
                                </div>
                            </div>
                        </>}
                        <div className="w-100 flex">
                            <Button
                                type={'button'}
                                className={'p-button p-button-outlined p-button-rounded mt-3 mx-auto'}
                                onClick={() => showPreview()}>
                                <><i className='pi pi-file mr-2'/>{t('reportingManagement:buttons.createReportPreview')} </>
                            </Button>
                        </div>
                    </div>
                </div>}
            </form>
        </>
    );
};

export default ReportForm;

