/*
 * ReportManagement.tsx
 * Author: lnappenfeld
 * Date: 30.11.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {checkPermission, checkProjectType, ProjectType} from '../functions/functionLibrary';
import {permissions} from '../config/permissions';
import ViewButtons from '../components/global/ViewButtons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faFileImage,
    faFileInvoice, faRadiationAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
    buttonCreateReport, buttonCreateReportTemplate, buttonDuplicateReport, buttonDuplicateReportTemplate,
    buttonRemoveReports, buttonRemoveReportTemplates,
    makeButtonItem
} from '../components/global/viewButtonSelection';
import LayoutMainContentTabMenu from '../components/global/LayoutMainContentTabMenu';
import CustomDialog, {CustomDialogHeaderType} from '../components/global/CustomDialog';

import {
    createReport,
    createReportTemplate, duplicateReportTemplate,
    getReportTemplates,
    Report,
    ReportTemplate
} from '../components/functions/Reporting';
import {ReportsOverview} from '../components/reporting/ReportsOverview';
import ReportTemplateForm from '../components/reporting/ReportTemplateForm';
import {ReportTemplatesOverview} from '../components/reporting/ReportTemplatesOverview';
import HistoricalAlarms from '../components/notification/HistoricalAlarms';
import {setRefreshMenu} from './Main';
import {ControlDevice, updateStatus} from '../components/functions/Metrology';
import {CustomConfirmDialog} from '../components/global/CustomConfirmDialog';
import {showMessageOnError, showMessageOnSuccess} from '../components/global/CustomToast';


type Props = {
    project: any
}

const ReportManagement = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    // Global
    const [mayEditReporting, setMayEditReporting] = useState<boolean>(false);
    const [tabMenuIndex, setTabMenuIndex] = useState<number | null>(null);

    // Reports
    const [selectedReports, setSelectedReports] = useState<Report[]>([]);
    const [reloadReports, setReloadReports] = useState(false);
    const [showReportDialog, setShowReportDialog] = useState<boolean>(false);
    const [showDeleteReportsDialog, setShowDeleteReportsDialog] = useState<boolean>(false);

    // Report Templates
    const [selectedReportTemplates, setSelectedReportTemplates] = useState<ReportTemplate[]>([]);
    const [reloadReportTemplates, setReloadReportTemplates] = useState(false);
    const [showReportTemplateDialog, setShowReportTemplateDialog] = useState<boolean>(false);
    const [showDeleteReportTemplatesDialog, setShowDeleteReportTemplatesDialog] = useState<boolean>(false);

    useEffect(() => {
        if (checkPermission(permissions.editReports)) {
            setMayEditReporting(true);
        }

        // If there are not any templates, go to templates
        getReportTemplates(props.project.id).then(result => {
            if (result.length === 0) {
                setTabMenuIndex(1); // report templates
            } else {
                setTabMenuIndex(0); // report tasks
            }
            setRefreshMenu();
        });

    }, []);

    const _duplicateReport = (data: Report[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('reportingManagement:dialogs.duplicateReport.message'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const report: any = data[0];
                delete report['id'];
                createReport(report).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('errorMessage: ' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('reportingManagement:toasts.reportDuplicated'));
                        setSelectedReports([]);
                        setReloadReports(true);
                    }
                });
            }
        });
    };

    const _duplicateReportTemplate = (data: ReportTemplate[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('reportingManagement:dialogs.duplicateReportTemplate.message'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const reportTemplate: any = data[0];
                duplicateReportTemplate(props.project.id, reportTemplate['id']).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('errorMessage: ' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('reportingManagement:toasts.reportTemplateDuplicated'));
                        setSelectedReportTemplates([]);
                        setReloadReportTemplates(true);
                    }
                });
            }
        });
    };

    const menuItems = [
        {
            id: 'reports',
            label: t('reportingManagement:global.reports'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faFileImage}/>,
            component: <ReportsOverview
                projectId={props.project.id}
                reload={reloadReports}
                setReload={setReloadReports}
                mayEditReporting={mayEditReporting}
                selectedReports={selectedReports}
                setSelectedReports={setSelectedReports}
                showReportDialog={showReportDialog}
                setShowReportDialog={setShowReportDialog}
                showDeleteReportsDialog={showDeleteReportsDialog}
                setShowDeleteReportDialog={setShowDeleteReportsDialog}
            />,
            visible: checkPermission(permissions.seeReports),
            buttons: (
                <ViewButtons title={t('')} items={[
                    makeButtonItem(buttonCreateReport, t, () => {
                        setSelectedReports([]);
                        setShowReportDialog(true);
                    }, mayEditReporting),
                    makeButtonItem(buttonDuplicateReport, t, () => {
                        _duplicateReport(selectedReports)
                    }, mayEditReporting, !(selectedReports && selectedReports.length === 1)),
                    makeButtonItem(buttonRemoveReports, t, () => {
                        setShowDeleteReportsDialog(true);
                    }, mayEditReporting, !(selectedReports && selectedReports.length > 0)),
                ]}
                />
            )
        },
        {
            id: 'reportTemplates',
            label: t('reportingManagement:global.reportTemplates'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faFileInvoice}/>,
            component:  <ReportTemplatesOverview
                projectId={props.project.id}
                mayEditReporting={mayEditReporting}
                reload={reloadReportTemplates}
                setReload={setReloadReportTemplates}
                editReportTemplate={(template: ReportTemplate)=>{
                    setSelectedReportTemplates([template]);
                    setShowReportTemplateDialog(true);
                }}
                selectedReportTemplates={selectedReportTemplates}
                setSelectedReportTemplates={setSelectedReportTemplates}
                showDeleteReportTemplatesDialog={showDeleteReportTemplatesDialog}
                setShowDeleteReportTemplatesDialog={setShowDeleteReportTemplatesDialog}
            />,
            visible: checkPermission(permissions.seeReports),
            buttons: (
                <ViewButtons title={t('')} items={[
                    makeButtonItem(buttonCreateReportTemplate, t, () => {
                        setSelectedReportTemplates([]);
                        setShowReportTemplateDialog(true);
                    }, mayEditReporting),
                    makeButtonItem(buttonDuplicateReportTemplate, t, () => {
                        _duplicateReportTemplate(selectedReportTemplates)
                    }, mayEditReporting, !(selectedReportTemplates && selectedReportTemplates.length === 1)),
                    makeButtonItem(buttonRemoveReportTemplates, t, () => {
                        setShowDeleteReportTemplatesDialog(true);
                    }, mayEditReporting, !(selectedReportTemplates && selectedReportTemplates.length > 0)),
                ]}
                />
            )
        },
        {
            id: 'historicalAlarms',
            label: t('alarmManagement:global.historicalAlarms'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faRadiationAlt}/>,
            component: <HistoricalAlarms projectId={props.project.id}/>,
            visible: (props.project.id !== null && checkProjectType(ProjectType.GeoMonitoring) && checkPermission(permissions.seeReports))
        }
    ];

    return (
        <>
            {tabMenuIndex !== null &&
                <LayoutMainContentTabMenu items={menuItems} activeIndex={tabMenuIndex}/>
            }
            {/** All usable dialogs in this view **/}
            {/*<CustomDialog*/}
            {/*    onHide={() =>{ setShowReportDialog(false);}}*/}
            {/*    visible={showReportDialog}*/}
            {/*    header={selectedReport*/}
            {/*        ? t('reportingManagement:dialogs.editReportDialog.header')*/}
            {/*        : t('reportingManagement:dialogs.createReportDialog.header')}*/}
            {/*    headerType={selectedReport ? CustomDialogHeaderType.Update : CustomDialogHeaderType.Create}*/}
            {/*    onCancel={() => setShowReportDialog(false)}*/}
            {/*    formName={'formDialog'}*/}
            {/*    className={'h-full w-8'}*/}
            {/*>*/}
            {/*    <ReportForm*/}
            {/*        projectId={props.project.id}*/}
            {/*        report={selectedReport}*/}
            {/*        onFinished={()=>{*/}
            {/*            setShowReportDialog(false);*/}
            {/*            setSelectedReport(null);*/}
            {/*            setReloadReports(true);*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</CustomDialog>*/}
            <CustomDialog
                onHide={() =>{ setShowReportTemplateDialog(false);}}
                visible={showReportTemplateDialog}
                header={selectedReportTemplates.length > 0
                    ? t('reportingManagement:dialogs.editReportTemplateDialog.header')
                    : t('reportingManagement:dialogs.createReportTemplateDialog.header')}
                headerType={selectedReportTemplates.length > 0 ? CustomDialogHeaderType.Update : CustomDialogHeaderType.Create}
                onCancel={() => setShowReportTemplateDialog(false)}
                formName={'formDialog'}
                style={{ width: '400px'}}
            >
                <ReportTemplateForm
                    projectId={props.project.id}
                    reportTemplate={selectedReportTemplates.length > 0 ? selectedReportTemplates[0] : null}
                    onFinished={()=>{
                        setSelectedReportTemplates([]);
                        setShowReportTemplateDialog(false);
                        setReloadReportTemplates(true);
                    }}
                />
            </CustomDialog>
        </>
    );
};

export default ReportManagement;
