import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Input from '../global/Input';
import {useForm} from 'react-hook-form';
import {
    createSensor,
    getMeasuringDeviceTypeSensorTypeMap,
    getSensor,
    getSensorTypes,
    MeasuringDevice,
    MeasuringDeviceTypeSensorTypeMap,
    Sensor,
    SensorType,
    updateSensor
} from '../functions/Metrology';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import TypesSelectList from './TypesSelectList';
import LocationSelectList from '../global/LocationSelectList';
import {Nullable} from '../functions/Global';
import MetrologySelectList from './MetrologySelectList';
import {checkProjectType, ProjectType} from '../../functions/functionLibrary';
import {ElementTreeSelectList} from './ElementTreeSelectList';

type Props = {
    projectId: string,
    sensorId: Nullable<string>,
    onFinished: (success: boolean) => void
}

const SensorForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);
    const defaultValues: any = {
        'sensor_id': null,
        'sensor_name': null,
        'sensor_type_id': null,
        'sensor_type_name': null,
        'qrcode': null,
        'epsg_code': null,
        'description': null,
        'is_active': true,
        'is_visible': true,
        'is_permanently_visible': true,
        'is_component_required': false,
        'is_measuring_device_required': false,
        'measuring_device_id': null,
        'location_id': null,
        'project_id': props.projectId,
        'element_id': null,
        'sensitivity': 1,
        'sensitivity_unit': null,
        'count': 0,
    };

    const {control, formState: {errors}, handleSubmit, reset, getValues, setValue, watch} = useForm({defaultValues});

    watch('measuring_device_id');
    watch('sensor_type_id');

    const [sensor, setSensor] = useState<Sensor>(defaultValues);
    const [showEpsg, setShowEpsg] = useState<boolean>(false);

    // Für das Mapping zwischen MeasuringDevice und SensorType
    const [sensorTypes, setSensorTypes] = useState<SensorType[]>([]);
    // Initialisiere mit undefined, damit das Skeletonloading funktioniert
    const [selectableSensorTypes, setSelectableSensorTypes] = useState<SensorType[]>();
    const [measuringDeviceTypeSensorTypeMap, setMeasuringDeviceTypeSensorTypeMap] = useState<MeasuringDeviceTypeSensorTypeMap[]>([]);

    const _setMeasuringDeviceTypeSensorTypeMap = () => {
        getMeasuringDeviceTypeSensorTypeMap(props.projectId).then(result => {
            setMeasuringDeviceTypeSensorTypeMap(result);
        });
    };

    const _setSensorTypes = () => {
        getSensorTypes(props.projectId).then(result => {
            setSensorTypes(result);
            setSelectableSensorTypes(result);
        });
    };

    useEffect(() => {
        _setSensorTypes();
        _setMeasuringDeviceTypeSensorTypeMap();

        if (props.sensorId !== null) {
            getSensor(props.projectId, props.sensorId).then(sensor => {
                setShowEpsg(sensor.epsg_code !== null);
                setSensor(sensor);
                reset(sensor);
            });
        }
    }, [props.sensorId]);


    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    const onMeasuringDeviceChange = (measuringDevice: MeasuringDevice) => {
        setValue('sensor_type_id', null);

        if (measuringDevice === undefined) {
            setSelectableSensorTypes(sensorTypes);
            return;
        }

        // Set selectable SensorTypes
        const currentSensorTypes = [] as SensorType[];
        // Messgerät ist ausgewählt
        // -> Mapping zwischen Messgerät und Sensor muss korrekt sein
        for (const sensorType of sensorTypes) {
            for ( const mappingObject of measuringDeviceTypeSensorTypeMap) {
                if (mappingObject.measuring_device_type_id === measuringDevice.measuring_device_type_id &&
                    mappingObject.sensor_type_id === sensorType.id) {
                    currentSensorTypes.push(sensorType);
                }
            }
        }

        setSelectableSensorTypes(currentSensorTypes);
    };

    const saveSensor = (data: any) => {
        // Rename sensor_name to name
        data['name'] = data['sensor_name'];
        delete data['sensor_name'];
        if (data.sensor_id !== null) {
            updateSensor(data).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), t('errorMessages:' + result.error));
                } else {
                    showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.sensorUpdated'));
                    props.onFinished(true);
                }
            });
        } else {
            delete data.sensor_id;
            createSensor(data).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), t('errorMessages:' + result.error));
                } else {
                    showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.sensorCreated'));
                    props.onFinished(true);
                }
            });
        }
    };

    return (<form id='formSensor' onSubmit={handleSubmit(saveSensor)}>
        <div className={'card'}>
            <div className={'grid w-auto'}>
                <div className={'col-6'}>
                    <MetrologySelectList
                        type={'measuring_device'}
                        projectId={props.projectId}
                        onChange={(measuringDevice: MeasuringDevice) => {
                            onMeasuringDeviceChange(measuringDevice);
                        }}
                        showClear={checkProjectType(ProjectType.GeoMonitoring)}
                        disabled={sensor.sensor_id !== null && getValues('measuring_device_id') !== null}
                        validationControl={control}
                        validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: checkProjectType(ProjectType.ConditionMonitoring)
                            ? t('metrologyManagement:attributes.measuringDevice') + ' ' + t('input:required')
                            : false
                        }}
                    />
                    <TypesSelectList
                        projectId={props.projectId}
                        type={'sensor'}
                        list={selectableSensorTypes}
                        onChange={(sensorType) => {
                            setValue('sensor_type_id', sensorType.id);
                            // Set default name for sensor
                            setValue('sensor_name', sensorType.name);
                        }}
                        disabled={sensor.sensor_id !== null || (checkProjectType(ProjectType.ConditionMonitoring) && getValues('measuring_device_id') === null)}
                        validationControl={control}
                        validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('metrologyManagement:attributes.sensorType') + ' ' + t('input:required')}}
                    />
                    <Input
                        edit={true} label={t('name')} name={'sensor_name'}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('name') + ' ' + t('input:required')}}
                    />
                    <Input
                        edit={true} label={t('description')} name={'description'} type={'textarea'}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: false}}
                    />
                </div>
                <div className={'col-6'}>
                    <LocationSelectList
                        projectId={props.projectId}
                        validationControl={control}
                        validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: false}}
                        label={t('location')}
                    />
                    <Input
                        edit={true}
                        label={t('qrcode')}
                        name={'qrcode'}
                        validationControl={control}
                        validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: false}}
                    />
                    {checkProjectType(ProjectType.ConditionMonitoring) &&
                        <ElementTreeSelectList
                            type={'sensor'}
                            projectId={props.projectId}
                            showClear={true}
                            // value={getValues('element_id')}
                            label={t('metrologyManagement:attributes.sensorElementName')}
                            name={'element_id'}
                            onChange={e => setValue('element_id', e.value)}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: false}}
                        />}
                    <div className={'grid'}>
                        <div className={'col-6'}>
                            <Input
                                edit={true} label={t('visible')} name={'is_visible'} type={'checkbox'}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: false}}
                            />
                        </div>
                        <div className={'col-6'}>
                            <Input
                                edit={true} label={t('active')} name={'is_active'} type={'checkbox'}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: false}}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {getValues('sensor_type_id') >= 200 && getValues('sensor_type_id') < 300 && getValues('sensor_type_id') !== 226 && // 226: PI-Sensor
            <div className={'card'}>
                <h4>{t('metrologyManagement:attributes.sensorSpecificParameter')}</h4>
                <div className={'grid w-auto'}>
                    <div className={'col-6'}>
                        {getValues('sensor_type_id') !== 226 && // Sensitivität nur wenn es nicht vom Typ 'PiSensor' ist
                        <Input
                            edit={true} type={'number'} name={'sensitivity'} maxFractionDigits={10}
                            label={t('metrologyManagement:attributes.sensitivity')}
                            value={getValues('sensitivity')}
                            onChange={ (e:any) => {
                                setValue('sensitivity', e.value);
                            }}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('metrologyManagement:attributes.sensitivity') + ' ' + t('input:required')}}
                        />}
                    </div>
                    <div className={'col-6'}>
                        {getValues('sensor_type_id') !== 226 && // Sensitivität nur wenn es nicht vom Typ 'PiSensor' ist
                        <Input
                            edit={true}
                            label={t('metrologyManagement:attributes.sensitivityUnit')}
                            name={'sensitivity_unit'}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: false}}
                            // validationRules={{required: t('metrologyManagement:attributes.sensitivityUnit') + ' ' + t('input:required')}}
                        />}
                    </div>
                </div>
            </div>}
        {showEpsg &&
            <div className={'card'}>
                <h4>{t('metrologyManagement:attributes.sensorSpecificParameter')}</h4>
                <Input
                    edit={true}
                    label={t('locations:attributes.epsg')}
                    name={'epsg_code'}
                    validationControl={control}
                    validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: false}}
                />
            </div>}
    </form>);
};

export default SensorForm;

