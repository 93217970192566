/*
 * ActionDialog.tsx
 * Author: lnappenfeld
 * Date: 28.04.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */


import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';

import Input from '../global/Input';
import UsersSelectList from './UsersSelectList';
import ActionTypeSelectList from './ActionTypeSelectList';

import functionLibrary from '../../functions/functionLibrary';
import {Action, createAction, updateAction} from '../functions/Notification';
import {getUser} from '../functions/UserManagement';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';

type Nullable<T> = T | null;

type Props = {
    actionData: Nullable<Action>,
    projectId: string,
    onFinished: () => void,
    setVisible: any
}

const ActionEditForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [mounted, setMounted] = useState(false);

    const actionId = props.actionData ? props.actionData.action_id : null;

    const recipientAddressSourceOptions = [
        {name: t('userManagement:global.user'), value: 0},
        {name: t('noUser'), value: 1},
    ];

    const defaultValues = {
        'name': !props.actionData ? null : props.actionData.action_name,
        'project_id': !props.actionData ? null : props.actionData.project_id,
        'recipient_id': !props.actionData ? null : props.actionData.recipient_id,
        'recipient_address_source': !props.actionData ? 0 : props.actionData.recipient_address_source,
        'recipient_address': !props.actionData ? null : props.actionData.recipient_address,
        'recipient_type': !props.actionData ? 0 : props.actionData.recipient_type,
        'action_type_id': !props.actionData ? '0' : props.actionData.action_type_id.toString(),
        'email': !props.actionData ? null : props.actionData.email,
        'sms': !props.actionData ? null : props.actionData.sms,
    };

    const {control, formState: {errors}, handleSubmit, getValues, watch, setValue} = useForm({defaultValues});
    watch('recipient_id');
    watch('action_type_id');
    watch('recipient_address_source');

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    const onSubmit = (data: any) => {
        saveData(data);
    };

    useEffect(() => {
        setMounted(true);
    },[]);

    /**
     * useEffect wird ausgelöst wenn sich der Empfänger oder Aktiontyp ändert.
     * Dementsprechend werden im Formular die passenden Attribute wie EMail/SMS gesetzt.
     */
    useEffect(() => {
        if (!mounted) return;

        if (getValues('recipient_address_source') === 1) {
            return;
        }
        if (getValues('recipient_id')) {
            getUser(props.projectId, getValues('recipient_id')).then(result => {
                if (result) {
                    setValue('email', result.email);
                    setValue('sms', result.sms);
                }
                switch (getValues('action_type_id')) {
                    case '0':
                        setValue('recipient_address', getValues('sms'));
                        break;
                    case '1':
                        setValue('recipient_address', getValues('email'));
                        break;
                    default:
                        setValue('recipient_address', '');
                        break;
                }
            });
        }


    }, [getValues('recipient_id'), getValues('action_type_id')]);


    const getRecipientAddressValidation = () => {
        switch (getValues('action_type_id')) {
            case '0':
                return functionLibrary.smsValidation(t);
            case '1':
                return functionLibrary.emailValidation(t);
            default:
                return {
                    required: t('userManagement:global.recipientAddress') + ' ' + t('input:required'),
                    pattern: {
                        value: /^/i,
                        message: ''
                    }
                };
        }
    };

    const saveData = (data: any) => {
        if (actionId !== null && typeof actionId !== 'undefined') {
            updateAction(props.projectId, actionId, data).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), t('errorMessages:' + result.error));
                } else {
                    showMessageOnSuccess(t('success'), t('userManagement:toasts.actionUpdated'));
                    props.onFinished();
                    props.setVisible(false);
                }
            });
        } else {
            createAction(props.projectId, data).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), t('errorMessages:' + result.error));
                } else {
                    showMessageOnSuccess(t('success'), t('userManagement:toasts.actionCreated'));
                    props.onFinished();
                    props.setVisible(false);
                }
            });
        }
    };

    return (
        <form id='formEditAction' onSubmit={handleSubmit(onSubmit)}>
            <div className={'card'}>
                <Input
                    edit={true} label={t('name')} name={'name'}
                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: t('name') + ' ' + t('input:required')}}
                />
                <ActionTypeSelectList
                    name={'action_type_id'}
                    label={t('userManagement:attributes.actionType')}
                    onChange={e => setValue('action_type_id', e.value)}
                    validationControl={control}
                    validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: t('userManagement:attributes.actionType') + ' ' + t('input:required')}}
                />
                <Input
                    edit={true}
                    label={t('userManagement:attributes.recipientType')}
                    name={'recipient_address_source'}
                    type={'selectButton'}
                    // value={getValues('recipient_address_source')}
                    onChange={e => setValue('recipient_address_source', e.value)}
                    selectButtonOptions={recipientAddressSourceOptions}
                    validationControl={control}
                    validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: true}}
                />
                {getValues('recipient_address_source') === 0
                    ? <>
                        <UsersSelectList
                            name={'recipient_id'}
                            type={'projectAndGlobalUsers'}
                            label={t('userManagement:global.user')}
                            projectId={props.projectId}
                            onChange={e => setValue('recipient_id', e.value)}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('userManagement:global.user') + ' ' + t('input:required')}}
                        />
                        <Input
                            edit={false}
                            label={t('userManagement:attributes.recipientAddress')}
                            name={'recipient_address'}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={getRecipientAddressValidation()}
                        />
                    </>
                    : <>
                        <Input
                            edit={true}
                            label={t('userManagement:attributes.recipientAddress')}
                            name={'recipient_address'}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={getRecipientAddressValidation()}
                            // validationRules={{required: t('action.recipient_address') + ' ' + t('input:required')}}
                        />
                    </>}
            </div>
        </form>
    );

};

export default ActionEditForm;
