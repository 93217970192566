import React, {useEffect, useRef, useState} from 'react';
import {
    createMetrologyFromWizard,
    getChannelTypes, getDataLinks,
    getMeasuringDeviceSensorMap, getMeasuringDeviceTypes,
    getSensorChannelMap,
    getSensorTypes
} from '../functions/Metrology';
import {useTranslation} from 'react-i18next';
import {getLocations} from '../functions/Global';
import {WizardMDeviceEditForm} from './MetrologyWizardMeasuringDevice';
import {WizardSensorEditForm} from './MetrologyWizardSensor';
import {WizardChannelEditForm} from './MetrologyWizardChannel';
import {TabMenu} from 'primereact/tabmenu';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {Button} from 'primereact/button';
import CustomDialog from '../global/CustomDialog';
import {TabPanel, TabView} from 'primereact/tabview';
import {useForm} from 'react-hook-form';


type Props = {
    projectId: string,
    onFinish?: () => void,
    setEditMetrology: (edit: boolean) => void,
    editMetrology: boolean
}
export const MetrologyWizard = (props: Props): JSX.Element => {
    const projectId = props.projectId;
    const {t} = useTranslation(['common']);
    // todo:
    const [wizardType, setWizardType] = useState('device'); // device, channel:
    const [deviceTypes, setDeviceTypes] = useState<any>([]);
    const [sensorTypes, setSensorTypes] = useState<any>([]);
    const [channelTypes, setChannelTypes] = useState<any>([]);
    const [projectLocations, setProjectLocations] = useState<any>(null);
    const [projectDataLinks, setProjectDataLinks] = useState<any>(null);
    const [deviceList, setDeviceList] = useState<any>(null);
    const [sensorList, setSensorList] = useState<any>([]);
    const [channelList, setChannelList] = useState<any>([]);
    const [mapDeviceSensor, setMapDeviceSensor] = useState([]);
    const [mapSensorChannel, setMapSensorChannel] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState<number>(-1);
    const [selectedSensor, setSelectedSensor] = useState<number>(-1);
    const [selectedChannel, setSelectedChannel] = useState<number>(-1);
    const [manager, setManager] = useState('');
    const startValue = {
        measuringDevice: {
            // eslint-disable-next-line camelcase
            measuring_device_type_id: '',
            name: '',
            // eslint-disable-next-line camelcase
            datalink_id: '',
            description: '',
            // eslint-disable-next-line camelcase
            is_visible: true,
            // eslint-disable-next-line camelcase
            location_id: null,
            qrcode: '',
            protokoll: 'http',
            ip: '',
            port: '',
            login: '',
            password: '',
            interval: 1
        },
        sensors: []
    };

    const startValueSensor = {
        active: true,
        // eslint-disable-next-line camelcase
        sensor_type_id: null,
        name: '',
        description: '',
        // eslint-disable-next-line camelcase
        is_visible: true,
        // eslint-disable-next-line camelcase
        is_active: true,
        // eslint-disable-next-line camelcase
        location_id: null,
        qrcode: '',
        channels: []
    };
    const startValueChannel = {
        active: true,
        // eslint-disable-next-line camelcase
        channel_type_id: null,
        name: '',
        description: '',
        unit: '',
        timezone: 'Europe/Berlin',
        // eslint-disable-next-line camelcase
        media_link: '',
        // eslint-disable-next-line camelcase
        is_visible: true,
        // eslint-disable-next-line camelcase
        is_active: true,
        // eslint-disable-next-line camelcase
        max_allowed_data_age_in_hours: 24,
        // eslint-disable-next-line camelcase
        chart_min: 0,
        // eslint-disable-next-line camelcase
        chart_max: 0,
        // eslint-disable-next-line camelcase
        chart_round: 0,
        count: 0,
        // eslint-disable-next-line camelcase
        port: '',
        // eslint-disable-next-line camelcase
        is_logical: false,
        // eslint-disable-next-line camelcase
        logical_channel_settings: {
            fp1: '',
            fp2: '',
            fp3: '',
            fp4: '',
            fp5: '',
        }
    };
    const [defaultValues, setDefaultValues] = useState(startValue);
    const defaultValuesDevice = {
        measuring_device_type_id: defaultValues.measuringDevice.measuring_device_type_id,
        name: defaultValues.measuringDevice.name,
        description: defaultValues.measuringDevice.description,
        is_visible: defaultValues.measuringDevice.is_visible,
        location_id: defaultValues.measuringDevice.location_id,
        qrcode: defaultValues.measuringDevice.qrcode,
        ip: defaultValues.measuringDevice.ip,
        port: defaultValues.measuringDevice.port,
        login: defaultValues.measuringDevice.login,
        password: defaultValues.measuringDevice.password,
        interval: defaultValues.measuringDevice.interval,
        protokoll: defaultValues.measuringDevice.protokoll
    };
    const {control, formState: {errors}} = useForm({});
    const makeList = (result: any, seter: any) => {
        const tmp: any = [];
        result.forEach((key: any) => {
            tmp.push({value: key.id, label: key.name});
        });
        seter(tmp);

    };
    useEffect(() => {
        getLocations(projectId).then(location => {
            const tmp: any = [];
            location.forEach((key: any) => {
                tmp[key.location_id] = key;
            });
            setProjectLocations([]);
            setProjectLocations(tmp);
        });
        getChannelTypes(props.projectId).then(result => {
            makeList(result, setChannelList);
            const tmp: any = [];
            result.forEach((key: any) => {
                tmp[key.id] = key;
            });
            setChannelTypes(tmp);
        });
        getSensorTypes(props.projectId).then(result => {
            makeList(result, setSensorList);
            const tmp: any = [];
            result.forEach((key: any) => {
                tmp[key.id] = key;
            });
            setSensorTypes(tmp);
        });
        getMeasuringDeviceTypes(props.projectId).then(result => {
            makeList(result, setDeviceList);
            const tmp: any = [];
            result.forEach((key: any) => {
                tmp[key.id] = key;
            });
            setDeviceTypes(tmp);
        });
        getMeasuringDeviceSensorMap().then(map => {
            setMapDeviceSensor(map);
        });
        getSensorChannelMap().then(map => {
            setMapSensorChannel(map);
        });
        getDataLinks(props.projectId).then(result => {
            setProjectDataLinks(result);
        });

    }, []);
    const [refreshComponents, setRefreshComponents] = useState(false);
    const setFormValues = (fieldIndex: string, field: string, value: any, countIndexSensor: number = -1, countIndexChannel: number = -1) => {
        const tmp: any = defaultValues;

        console.log(fieldIndex, field, value, countIndexSensor, countIndexChannel);

        if (fieldIndex === 'sensors') {
            tmp['sensors'][countIndexSensor][field] = value;
        } else if (fieldIndex === 'measuringDevice') {
            tmp['measuringDevice'][field] = value;
            if (field === 'measuring_device_type_id') {
                tmp.measuringDevice['name'] = deviceTypes[value].name;
            }
            if (field === 'location_id') {
                for (let i = 0; i < defaultValues.sensors.length; i++) {
                    if (defaultValues.sensors[i]['location_id'] !== 'none') {
                        tmp.sensors[i]['location_id'] = value;
                        // todo: name noch mal überdenken
                        tmp.sensors[i].name = projectLocations[value].location_name + ' ' + tmp.sensors[i].name;
                        setRefreshComponents(true);
                    }
                }
            }
            if (field === 'datalink_id') {
                setRefreshComponents(true);
            }
        } else {
            if (tmp.sensors[countIndexSensor].channels[countIndexChannel]) {
                tmp['sensors'][countIndexSensor].channels[countIndexChannel][field] = value;
                if (field === 'port') {
                    tmp['sensors'][countIndexSensor].channels[countIndexChannel].name = value;
                }
            }
            console.log(tmp.sensors);
        }
        setDefaultValues({...tmp});
    };
    useEffect(() => {
        if (refreshComponents) {
            getComponents();
            setRefreshComponents(false);
        }
    }, [defaultValues]);

    const saveData = () => {
        const sendObj = JSON.parse(JSON.stringify(defaultValues));

        sendObj.sensors.forEach((obj:any, index:number) => {
            if (!obj.active) {
                delete sendObj.sensors[index];
            } else {
                let ci = obj.channels.length;
                for ( let i =0; i < obj.channels.length; i++) {
                    if (defaultValues.measuringDevice.datalink_id === '') {
                        if (!obj.channels[i].active) {
                            delete obj.channels[i];
                            ci--;
                        }
                    } else {
                        if (obj.channels[i].port === '') {
                            delete obj.channels[i];
                            ci--;
                        }
                    }
                }
                if (ci === 0)
                    delete sendObj.sensors[index];
            }
        });

        const data = {
            'project_id': props.projectId,
            'data': JSON.stringify(sendObj)
        };
        createMetrologyFromWizard(data).then(result => {
            if (result.error) {
                showMessageOnError(t('error'), JSON.stringify(result.error));
                return;
            } else {
                showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.metrologyFromWizardCreated'));
                if (props.onFinish) props.onFinish();
            }
        });
    };

    const makeComponentsFromDevice = () => {
        const sensors: any = [];
        let i = 0;
        const tmpSL = [];
        const tmpCL = [];
        const tmp = {...defaultValues};
        for (const keyS in mapDeviceSensor) {
            if (mapDeviceSensor[keyS]['measuring_device_type_id'] === selectedDevice) {
                tmpSL.push(sensorTypes[mapDeviceSensor[keyS]['sensor_type_id']]);
                sensors[i] = {...startValueSensor};
                // eslint-disable-next-line camelcase
                sensors[i].sensor_type_id= mapDeviceSensor[keyS]['sensor_type_id'];
                sensors[i].name= sensorTypes[mapDeviceSensor[keyS]['sensor_type_id']]['name'];
                // eslint-disable-next-line camelcase
                sensors[i].location_id = tmp.measuringDevice.location_id;
                const channels: any = [];
                let j = 0;
                for (const keyC in mapSensorChannel) {
                    if (mapSensorChannel[keyC]['sensor_type_id'] === mapDeviceSensor[keyS]['sensor_type_id']) {
                        if (mapSensorChannel[keyC]['channel_type_id'] !== 140) {
                            tmpCL.push(channelTypes[mapSensorChannel[keyC]['channel_type_id']]);
                            channels[j] = { ...startValueChannel };
                            // eslint-disable-next-line camelcase
                            channels[j].channel_type_id = mapSensorChannel[keyC]['channel_type_id'];
                            channels[j].name = channelTypes[mapSensorChannel[keyC]['channel_type_id']].name;
                            channels[j].unit = channelTypes[mapSensorChannel[keyC]['channel_type_id']].measuring_unit;
                            // eslint-disable-next-line camelcase
                            channels[j].chart_min = channelTypes[mapSensorChannel[keyC]['channel_type_id']].chart_min;
                            // eslint-disable-next-line camelcase
                            channels[j].chart_max = channelTypes[mapSensorChannel[keyC]['channel_type_id']].chart_max;
                            // eslint-disable-next-line camelcase
                            channels[j].chart_round = channelTypes[mapSensorChannel[keyC]['channel_type_id']].chart_round;
                            j++;
                        }
                    }
                }
                sensors[i].channels = channels;
                i++;
            }
        }
        makeList(tmpSL, setSensorList);
        makeList(tmpCL, setChannelList);
        tmp['sensors'] = sensors;
        setRefreshComponents(true);
        setDefaultValues(tmp);
    };

    useEffect(() => {
        if (selectedDevice !== -1) {
            if (tabView) tabView.current.reset();
            setActiveIndex(0);
            makeComponentsFromDevice();
            deviceTypes.forEach((obj: any) => {
                if (selectedDevice === obj.id) {
                    setManager(obj.manager);
                }
            });
        }
    }, [selectedDevice]);

    useEffect(() => {
        if (selectedSensor !== -1 && wizardType === 'sensor') {
            const tmp2 = [];
            for (const key in mapDeviceSensor) {
                if (mapDeviceSensor[key]['sensor_type_id'] === selectedSensor)
                    tmp2.push(deviceTypes[mapDeviceSensor[key]['measuring_device_type_id']]);
            }
            makeList(tmp2, setDeviceList);
            const tmp1 = [];
            for (const key in mapSensorChannel) {
                if (mapSensorChannel[key]['sensor_type_id'] === selectedSensor)
                    tmp1.push(channelTypes[mapSensorChannel[key]['channel_type_id']]);
            }
            makeList(tmp1, setChannelList);
            setRefreshComponents(true);
            setDefaultValues({...defaultValues});
        }
    }, [selectedSensor]);

    useEffect(() => {
        if (selectedChannel !== -1 && wizardType === 'channel') {
            const tmpS:any = [];
            const tmpD:any = [];
            for (const key in mapSensorChannel) {
                if (mapSensorChannel[key]['channel_type_id'] === selectedChannel) {
                    tmpS.push(sensorTypes[mapSensorChannel[key]['sensor_type_id']]);
                    for (const keyD in mapDeviceSensor) {
                        if (mapDeviceSensor[keyD]['sensor_type_id'] === mapSensorChannel[key]['sensor_type_id']) {
                            tmpD.push(deviceTypes[mapDeviceSensor[keyD]['measuring_device_type_id']]);
                        }
                    }
                }
            }
            makeList(tmpS, setSensorList);
            makeList(tmpD, setDeviceList);
            setRefreshComponents(true);
            setDefaultValues({...defaultValues});
        }
    }, [selectedChannel]);

    useEffect(() => {
        setRefreshComponents(true);
        makeList(deviceTypes, setDeviceList);
        makeList(sensorTypes, setSensorList);
        makeList(channelTypes, setChannelList);
        setSelectedChannel(-1);
        setSelectedSensor(-1);
        setSelectedDevice(-1);
        const channel: any = [];
        const sensors: any = [];
        switch (wizardType) {
            // @ts-ignore
            case 'channel':
                channel[0] = startValueChannel;
                sensors[0] = startValueSensor;
                sensors[0].channels = channel;
                const tmp = startValue;
                tmp['sensors'] = sensors;
                setDefaultValues({...tmp});
                break;
            default:
                setDefaultValues({ ... startValue});
                break;
        }
    }, [wizardType]);

    const [menuItems, setMenuItems] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const tabView: any = useRef();
    const getComponents = () => {
        console.log(defaultValues);
        const item:any = [];
        for (let i = 0; i < defaultValues.sensors.length; i++) {
            if (defaultValues.sensors[i]) {
                const channelComp: any = [];
                // @ts-ignore
                for (let j = 0; j < defaultValues.sensors[i]['channels'].length; j++) {
                    console.log('makeComponent:',i, j);
                    channelComp.push(
                        <WizardChannelEditForm
                            key={'c'+j+''+i}
                            setSelectedChannel={setSelectedChannel}
                            channelList={channelList}
                            manager={manager}
                            setFormValues={setFormValues}
                            projectLocations={projectLocations}
                            wizardType={wizardType}
                            projectDataLink={defaultValues.measuringDevice.datalink_id}
                            projectId={props.projectId}
                            fields={defaultValues.sensors[i]['channels'][j]}
                            fieldIndex={'sensors[' + i + ']channels'}
                            countIndexSensor={i}
                            countIndex={j}
                            defaultValues={defaultValues}
                            setRefreshComponents={setRefreshComponents}
                            setDefaultValues={setDefaultValues}/>
                    );
                }

                item.push(<TabPanel key={'tb'+i} header={defaultValues.sensors[i]['name']} closable={defaultValues.sensors.length > 1}>
                    {<div className={'grid'} key={'sensor'+i}><WizardSensorEditForm
                        key={'WizardSensorEditForm' + i}
                        wizardType={wizardType}
                        setSelectedSensor={setSelectedSensor}
                        projectLocations={projectLocations}
                        setFormValues={setFormValues}
                        sensorList={sensorList}
                        projectId={props.projectId}
                        fields={defaultValues.sensors[i]}
                        fieldIndex={'sensors'}
                        countIndex={i}
                        defaultValues={defaultValues}
                        setRefreshComponents={setRefreshComponents}
                        setDefaultValues={setDefaultValues}
                    />
                    {<div className={'channel col-8'} key={'channels'+i}>
                        { // @ts-ignore
                            defaultValues.sensors[i].active &&
                                <>{channelComp}</>
                            } </div>}
                    </div>
                    }
                </TabPanel>);
            }
        }
        setMenuItems(item);
    };

    return (
        <CustomDialog
            onHide={() => { props.setEditMetrology(false); }}
            maximized={true}
            visible={props.editMetrology}
            header={t('metrologyManagement:editDialog.header')}
            onCancel={() => { props.setEditMetrology(false); }}
            showFooter={true}
            customButtons={[<Button key={'mwb'} type='button' name='save' label='SAVE DATA' onClick={saveData}/>]}
        >
            <div className={'grid'}>
                <div className={'col'}>
                    {projectLocations !== null && deviceTypes.length > 0 && projectDataLinks !== null &&
                    <WizardMDeviceEditForm
                        projectId={props.projectId}
                        manager={manager}
                        setFormValues={setFormValues}
                        setSelectedDevice={setSelectedDevice}
                        selectedDevice={selectedDevice}
                        deviceList={deviceList}
                        projectLocations={projectLocations}
                        fields={defaultValues.measuringDevice}
                        fieldIndex='measuringDevice'
                        projectDataLinks={projectDataLinks}
                        errors={errors}
                        control={control}
                    />}
                </div>
            </div>
            {<div className='card'>
                <TabView
                    ref={tabView} activeIndex={activeIndex}
                    onTabClose={(e)=>{
                        // @ts-ignore
                        defaultValues.sensors[e.index].active = false;
                        console.log(defaultValues.sensors);
                    }}
                    onTabChange={(e) => {
                        setActiveIndex(e.index);
                    }}>
                    {menuItems}
                </TabView>
            </div>}
        </CustomDialog>
    );
};

