/*
 * ActionMultiSelectList.tsx
 * Author: lnappenfeld
 * Date: 15.07.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import Input from '../global/Input';
import {getActions} from '../functions/Notification';
import {useTranslation} from 'react-i18next';

type Data = {
    value: string,
    label: string
}

type Props = {
    projectId: string,
    label: string,
    value?: any[],
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    name?: string,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    disabled?: boolean,
    showClear?: boolean,
    actionTypeId?: number
}
const ActionMultiSelectList = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common', 'actions']);

    // Initialisiere mit undefined, damit das Skeletonloading funktioniert
    const [list, setList] = useState<Data[]>();

    useEffect(() => {
        getActions(props.projectId, t, props.actionTypeId).then(result => {
            const tmp: any = [];
            result.forEach((key: any) => {
                tmp.push({
                    value: key.action_id,
                    label: key.action_name,
                });
            });
            setList(tmp);
        });
    }, []);
    return (
        <Input
            name={props.name ? props.name : 'action_ids'}
            edit={true}
            showClear={typeof props.showClear === 'boolean' ? props.showClear : false}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            label={props.label}
            value={props.value}
            dropdownOptions={list}
            type={'multiselect'}
            onChange={(e: any) => {
                if (props.setValue) props.setValue(e);
                if (props.onChange) props.onChange(e);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};
export default ActionMultiSelectList;
