/*
 * KeyCloakService.tsx
 * Author: fwunderlich
 * Date: 19.01.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect} from 'react';
import Keycloak from 'keycloak-js';
import {keycloak} from '../functions/keycloak';
import settings from '../config/settings';
import keycloakfetch from '../functions/keycloakfetch';


type Props = {
    onAuth: (token: string) => void
    onSessionExpired: () => void
}

const logMyLogin = () => {
    keycloakfetch.get(settings.apiUrl + '/logMyLogin').then(result => {
        if (result) {
            localStorage.setItem('loginRequired', '0');
        }
    });
};

const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

// update access token wenn dieser kurz vor dem Ablaufen ist
const updateAccessToken = async () => {
    const token = localStorage.getItem('keycloak-token') || '';
    const tokenPayload = parseJwt(token);
    const tokenExpiredTime = tokenPayload.exp;
    // console.log(tokenExpiredTime, ' (access-token expired time)');

    const now = new Date().getTime() / 1000 | 0;
    // console.log(now, " (now)");

    if ((tokenExpiredTime - now) < 30) {
        await updateKeycloak();
    }
};

const isRefreshTokenExpired = () => {
    const refreshToken = localStorage.getItem('keycloak-refresh-token') || '';
    const tokenPayload = parseJwt(refreshToken);
    const tokenExpiredTime = tokenPayload.exp;
    // console.log(tokenExpiredTime, ' (refresh-token expired time)');

    const now = new Date().getTime() / 1000 | 0;
    // console.log(now, " (now)");

    return (tokenExpiredTime - now) < 0;
};

const initKeycloak = async (keycloak: Keycloak.KeycloakInstance) => {
    // console.info('initKeycloak');
    let token: string = '';
    await keycloak.init({onLoad: 'check-sso', checkLoginIframe: false}).then((auth) => {
        // console.info('Authenticate auth: ', auth);
        if (!auth) {
            keycloak.init({onLoad: 'login-required'});
            localStorage.setItem('loginRequired', '1');
        } else {
            localStorage.setItem('keycloak-token', keycloak.token || '');
            localStorage.setItem('keycloak-refresh-token', keycloak.refreshToken || '');
            localStorage.setItem('keycloak-id-token', keycloak.idToken || '');
            // userLogin();
            token = keycloak.token || '';

            if (localStorage.getItem('loginRequired') === '1') {
                logMyLogin();
            }
        }
    }).catch((e) => {
        console.log(e);
        console.error('Authenticated Failed');
    });
    return token;
};

const updateKeycloak = async () => {
    const refreshToken = localStorage.getItem('keycloak-refresh-token') || '';

    const paramsBody = 'client_id=' + keycloak.clientId + '&grant_type=refresh_token&refresh_token=' + refreshToken;

    const requestOptions = {
        method: 'POST',
        useDefaultXhrHeader: false,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: paramsBody
    };

    const response = await fetch(settings.keycloakAuthUrl, requestOptions);

    if (response.status === 200) {
        // console.log('Token updated!');
        const data = await response.json();

        localStorage.setItem('keycloak-token', data.access_token);
        localStorage.setItem('keycloak-refresh-token', data.refresh_token);
    }

    return response;
};

const KeycloakService = (props: Props): JSX.Element => {

    useEffect(() => {
        (async () => {
            const token = await initKeycloak(keycloak);
            props.onAuth(token);

            if (token !== '') {
                const interval = setInterval(() => {
                    // console.log('Token updated!');
                    // updateKeycloak().then((res) => {
                    //     if (res.status === 400) {
                    //         props.onSessionExpired();
                    //     }
                    // });

                    if (isRefreshTokenExpired()) {
                        props.onSessionExpired();
                        clearInterval(interval);
                    }
                }, 200000);

                return () => clearInterval(interval);
            }
        })();
    }, []);

    return (<></>);
};

export {
    updateAccessToken,
    KeycloakService,
    parseJwt
};
