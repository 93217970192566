/*
 * MultipleChannelsCalibrationForm.tsx
 * Author: lnappenfeld
 * Date: 03.01.2024
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Channel, createMultipleChannelCalibrations,} from '../functions/Metrology';
import Input from '../global/Input';
import {useForm} from 'react-hook-form';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {hideWaitAnimation, showWaitAnimation} from '../global/CustomWaitAnimation';
import {Nullable} from '../functions/Global';

type Props = {
    projectId: string
    channels: Channel[]
    onFinished: (success: boolean) => void,
}

export const MultipleChannelsCalibrationForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [minDate, setMinDate] = useState<Nullable<Date>>(null);
    const [maxDate, setMaxDate] = useState<Nullable<Date>>(null);


    const defaultValues = {
        'timestamp': null
    };

    const {control, formState: {errors}, handleSubmit} = useForm({defaultValues});

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    useEffect(() => {
        const now = new Date();
        setMaxDate(now);
    }, []);

    const saveData = (data: any) => {
        showWaitAnimation();
        const _channelIds = [];
        for (const channel of props.channels) {
            _channelIds.push(channel.channel_id);
        }

        const projectId = {'project_id': props.projectId};
        const channelIds = {'channel_ids': _channelIds};
        data = {...data, ...projectId, ...channelIds};

        createMultipleChannelCalibrations(data).then(result => {
            hideWaitAnimation();
            if (result.error) {
                showMessageOnError(t('error'), t(result.error));
            } else {
                showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.dashboardPanelCreated'));
                props.onFinished(true);
            }
        });
    };

    const channelsColumns: ColumnObject[] = [
        {field: 'is_active', header: t('active'), style: {maxWidth: '100px'}},
        {field: 'channel_name', header: t('name')},
        {field: 'unit', header: t('unit'), style: {maxWidth: 105}},
        {field: 'channel_type_name', header: t('type')},
        {field: 'sensor_name', header: t('sensor')},
        {field: 'measuring_device_name', header: t('measuring_device')},
        {field: 'location_name', header: t('location')},
        {field: 'project_group_name', header: t('projectManagement:attributes.projectGroup')},
        {field: 'timezone', header: t('timezone')}
    ];

    return (
        <>
            <div className={'card'}>
                <CustomDataTable
                    id="selectedChannels"
                    style={{'maxHeight': '400px', 'overflow': 'auto'}}
                    selectionMode={'single'}
                    expandable={false}
                    columns={channelsColumns}
                    editable={false}
                    sortable={true}
                    value={props.channels}
                    paginator={props.channels.length > 100}
                    ableToDelete={false}
                    ableToUpdate={false}
                    filters={[]}
                    headerTitle={t('metrologyManagement:global.selectedChannels')}
                    dataloaded={true}
                />
            </div>
            <form id='addChannelCalibrationForm' onSubmit={handleSubmit(saveData)}>
                <div className='card'>
                    <div className={'grid w-auto mt-3'}>
                        <div className={'col-3'}>

                        </div>
                        <div className={'col-6'}>
                            <Input
                                edit={true} label={t('metrologyManagement:attributes.validFrom')}
                                name={'timestamp'} type={'date'} minDate={minDate} maxDate={maxDate}
                                options={{showTime: true, showSeconds: true, showIcon: true}}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: t('metrologyManagement:attributes.validFrom') + ' ' + t('input:required')}}
                            />
                        </div>
                        <div className={'col-3'}>

                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};