/*
 * Cesium.tsx
 * Author: fwunderlich
 * Date: 19.04.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */
import React, {useState, useEffect, useRef, createRef} from 'react';

type IFrameProps = {
    url: any,
    show: boolean
}

const Cesium = (props: IFrameProps): JSX.Element => {
    const componentRef: any = useRef();
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        setHeight(componentRef.current.offsetHeight);
        setWidth(componentRef.current.offsetWidth);
    });

    useEffect(() => {
        console.log('hhh', height, width);
        if (height > 0 && width > 0) {
            var iframeElement: any = document.querySelector('iframe');

            iframeElement.style.width = width + 'px';
            iframeElement.style.height = height + 'px';
        }
    }, [height, width]);

    return (<div ref={componentRef} className={'iframe-wrapper cesium'} style={{display: props.show ? 'inline' : 'none'}}>
            <iframe
                ref={iframeRef}
                allowFullScreen={true}
                style={{'borderRadius': '6px'}}
                src={props.url}
                width="100%"
                height="100%"
                id={'cesium_iframe'}
                className=""
                frameBorder="0"
                onLoad={(e) => {
                    // props.removeGrafanaElements();
                }}
            />
        </div>
    );
};

export default Cesium;
