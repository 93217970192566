/*
 * GrafanaDashboardsEditForm.tsx
 * Author: lnappenfeld, fwunderlich
 * Date: 18.10.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */


import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Input from '../global/Input';
import {useForm} from 'react-hook-form';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {createGrafanaDashboard, Project, updateGrafanaDashboard} from '../functions/Project';
import {GrafanaDashboard, Nullable} from '../functions/Global';
import {otherStringValidation} from '../../functions/functionLibrary';


type Props = {
    project: Project,
    onFinished: (success: boolean) => void,
    grafanaDashboard: Nullable<GrafanaDashboard>;
}

export const GrafanaDashboardsEditForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common', 'users', 'input', 'project']);

    const defaultValues = {
        'name': props.grafanaDashboard ? props.grafanaDashboard.name : null,
        'mobile': props.grafanaDashboard ? props.grafanaDashboard.mobile : false
    };

    const {control, formState: {errors}, handleSubmit} = useForm({defaultValues});

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    const saveData = (data: any) => {

        const addProjectId = {'project_id': props.project.id};
        data = {...addProjectId, ...data};

        if (props.grafanaDashboard) {
            const addDashboardId = {'dashboard_id': props.grafanaDashboard.dashboard_id};
            data = {...addDashboardId, ...data};
            updateGrafanaDashboard(data).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), result.error);
                } else {
                    showMessageOnSuccess(t('success'), t('projectManagement:toasts:grafanaDashboardUpdated'));
                    props.onFinished(true);
                }
            });
        } else {
            createGrafanaDashboard(data).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), result.error);
                } else {
                    showMessageOnSuccess(t('success'), t('projectManagement:toasts.grafanaDashboardCreated'));
                    props.onFinished(true);
                }
            });
        }
    };

    return (
        <form id="grafanaDashboardForm" onSubmit={handleSubmit(saveData)}>
            <div className={'card'}>
                <Input
                    edit={true} label={t('name')} name={'name'} type={'text'}
                    tooltip={t('projectManagement:tooltips.grafanaDashboardName')}
                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                    validationRules={{
                        ...{required: t('name') + ' ' + t('input:required')},
                        ...otherStringValidation(t,'input:dashboardNameProjectNameValidation', props.project.name)
                    }}
                />
                <Input
                    edit={true} label={t('forMobile')} name={'mobile'} type={'checkbox'}
                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: false}}
                />
            </div>
        </form>
    );
};