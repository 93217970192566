/*
 * GlobalBillingOverview.tsx
 * Author: lnappenfeld
 * Date: 31.07.2024
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {
    BillingItemSumMonthProProject,
    BillingItemSumTotalProProject,
    getBillingItemSumTotalProProject
} from '../functions/Billing';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {filterTriStateCheckBox} from '../global/filter';

type Props = {
    reload: boolean,
    setReload: (reload: boolean) => void,
}

export const GlobalBillingOverview = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [billingItemSumTotalProProject, setBillingItemSumTotalProProject] = useState<BillingItemSumTotalProProject[]>([]);

    useEffect(() => {
        _getBillingItemSumTotalProProject();
    }, []);

    useEffect(() => {
        if (props.reload) {
            props.setReload(false);
            _getBillingItemSumTotalProProject();
        }
    }, [props.reload]);

    const _getBillingItemSumTotalProProject = () => {
        getBillingItemSumTotalProProject().then(result => {
            setBillingItemSumTotalProProject(result);
            setDataLoaded(true);
        });
    };

    const filterCheckboxIsEnabled = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), t('enabled'), t('disabled'));
    };

    const columns: ColumnObject[] = [
        {
            field: 'is_project_active', header: t('active'), style: {maxWidth: '100px'},
            filter: true, filterElement: filterCheckboxIsEnabled,
        }, {
            field: 'project_name', header: t('common:project'), style: {maxWidth: 400},
            body: (item: BillingItemSumTotalProProject) => {
                return (<u>
                    <a className='cell-function' href={'project/' + item.project_id + '/#billing_3'}>
                        {/* <FontAwesomeIcon icon={faUpRightFromSquare} className='ml-2'/>&nbsp;*/}
                        {item.project_name}
                    </a>
                </u>);
            },
            filter: true
        }, {
            field: 'project_timestamp_start', header: t('billing:attributes.project_timestamp_start'), type: 4, dateFormat: 'DD.MM.YYYY',
            filter: true, filterField: 'project_timestamp_start'
        }, {
            field: 'project_timestamp_end', header: t('billing:attributes.project_timestamp_end'), type: 4, dateFormat: 'DD.MM.YYYY',
            filter: true, filterField: 'project_timestamp_end'
        }, {
            field: 'sum', header: t('billing:attributes.sum'), type: 1, filter: true, currency: 'EUR'
        }
    ];

    const filters = {
        'is_project_active': {
            operator: FilterOperator.AND,
            constraints: [{value: true, matchMode: FilterMatchMode.EQUALS}]
        },
        'project_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'project_timestamp_start': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'project_timestamp_end': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        }
    };

    return (
        <CustomDataTable
            id='globalBillingOverview'
            columns={columns}
            filters={filters}
            editable={false}
            sortable={true}
            value={billingItemSumTotalProProject}
            ableToUpdate={true}
            dataloaded={dataLoaded}
            paginator={true}
            showExportCSV={true}
            showClearFiltersButton={true}
        />
    );
};