import {Channel, LogicalChannel, Sensor} from '../functions/Metrology';
import React, {useEffect, useState} from 'react';
import Input from '../global/Input';
import {useTranslation} from 'react-i18next';
import {OperatingStateSelectList} from './simon2/OperatingStateSelectList';
import {CutoffFrequencySelectList} from './simon2/CutoffFrequencySelectList';
import {SignalTypeSelectList} from './simon2/SignalTypeSelectList';
import {SamplingRateSelectList} from './simon2/SamplingRateSelectList';
import {DurationInSecondsInput} from './simon2/DurationInSecondsInput';
import {MeasuringTypeSelectList} from './simon2/MeasuringTypeSelectList';

type Props = {
    channel: Channel,
    logicalChannelSettings: LogicalChannel,
    sensor: Sensor,
    getValues: any,
    setValue: any,
    watch: any,
    control: any,
    getFormErrorMessage: any
}
export const LogicalChannelEditForm = (props: Props)=>{
    const {t} = useTranslation(['common']);

    const [measuringType, setMeasuringType] = useState<number>(0); // Standard, synchrone, kontinuierliche Mes.

    props.watch('logical_channel_settings.saving_condition_rmsa');
    props.watch('logical_channel_settings.saving_condition_rmsv');
    props.watch('logical_channel_settings.saving_condition_rmsd');
    props.watch('logical_channel_settings.signal_type');
    props.watch('logical_channel_settings.sampling_rate');
    props.watch('logical_channel_settings.cutoff_frequency');
    props.watch('logical_channel_settings.duration_in_seconds');
    props.watch('logical_channel_settings.operating_state_id');

    const _setMeasuringType = () => {
        const synchronousMeasuring = props.getValues('logical_channel_settings.synchronous_measuring');
        const continuallyMeasuring = props.getValues('logical_channel_settings.continually_measuring');
        if (!synchronousMeasuring && !continuallyMeasuring) {
            setMeasuringType(1);
        } else if (synchronousMeasuring && !continuallyMeasuring) {
            setMeasuringType(2);
        } else if (!synchronousMeasuring && continuallyMeasuring) {
            setMeasuringType(3);
        } else {
            // Irgendetwas ist falsch, es kann nicht synchroneMessung und kontinuierliche Messung gleichzeit aktiv sein
        }
    };

    const updateLeadTimeZEITuHKZT = (samplingRate: number, durationInSeconds: number) => {
        // Aktualisiere die Vorlaufzeit, abhängig von der Abtastrate und der Signallänge
        if (props.channel['channel_type_id'] === 202 || props.channel['channel_type_id'] === 205) { // ZEIT, HKZT
            switch (samplingRate) {
                case 25600:
                    props.setValue('logical_channel_settings.lead_time', 0);
                    break;
                case 5120:
                case 512:
                case 51.2:
                    props.setValue('logical_channel_settings.lead_time', durationInSeconds + 1);
                    break;
            }
        }
    };

    const updateLeadTimeMEDAuHKMD = (cutoffFrequency: number) => {
        // Aktualisiere die Vorlaufzeit, abhängig von der Eckfrequenz
        if (props.channel['channel_type_id'] === 201 || props.channel['channel_type_id'] === 204) { // MEDA, HKMD
            switch (cutoffFrequency) {
                case 12800:
                    props.setValue('logical_channel_settings.lead_time', 1);
                    break;
                case 2560:
                    props.setValue('logical_channel_settings.lead_time', 5);
                    break;
                case 256:
                    props.setValue('logical_channel_settings.lead_time', 85);
                    break;
                case 25.6:
                    props.setValue('logical_channel_settings.lead_time', 850);
                    break;
            }
        }
    };

    const onCutoffFrequencyChange = (data: any) => {
        const cutoffFrequency = data.value;
        // Setze den Wert Eckfrequenz
        props.setValue('logical_channel_settings.cutoff_frequency', cutoffFrequency);

        if (props.getValues('logical_channel_settings.operating_state_id') !== null) {
            // Aktualisiere die Vorlaufzeit, abhängig von der Eckfrequenz
            updateLeadTimeMEDAuHKMD(cutoffFrequency);
        }
    };

    const onSamplingRateChange = (data: any) => {
        const samplingRate = data.value;
        // Setze den Wert Abtastrate
        props.setValue('logical_channel_settings.sampling_rate', samplingRate);

        if (props.getValues('logical_channel_settings.operating_state_id') !== null) {
            // Aktualisiere die Vorlaufzeit, abhängig von der Abtastrate und der Signallänge
            updateLeadTimeZEITuHKZT(samplingRate, props.getValues('logical_channel_settings.duration_in_seconds'));
        }
    };

    const onDurationInSecondsChange = (value: number) => {
        const durationInSeconds = value;
        // Setze den Wert 'Signallänge'
        props.setValue('logical_channel_settings.duration_in_seconds', durationInSeconds);

        if (props.getValues('logical_channel_settings.operating_state_id') !== null) {
            // Aktualisiere die Vorlaufzeit, abhängig von der Abtastrate und der Signallänge
            updateLeadTimeZEITuHKZT(props.getValues('logical_channel_settings.sampling_rate'), durationInSeconds);
        }

    };

    const onOperatingStateChange = (data: any) => {
        const operatingStateId = data.value;
        // Setze den Wert Betriebszustands Id
        props.setValue('logical_channel_settings.operating_state_id', operatingStateId);

        // Setze die Vorlaufzeit und die Nachlaufzeit zu null, wenn der Betriebszustand ebenfalls null ist
        if (operatingStateId === null) {
            props.setValue('logical_channel_settings.lead_time', null);
            props.setValue('logical_channel_settings.follow_up_time', null);
        } else {
            updateLeadTimeZEITuHKZT(
                props.getValues('logical_channel_settings.sampling_rate'),
                props.getValues('logical_channel_settings.duration_in_seconds')
            );
            updateLeadTimeMEDAuHKMD(props.getValues('logical_channel_settings.cutoff_frequency'));
            // Default der Nachlaufzeit ist immer 0
            props.setValue('logical_channel_settings.follow_up_time', 0);
        }
    };

    useEffect(() => {
        _setMeasuringType();
    }, []);

    return (<>
        {(props.channel.channel_type_id === 200 || props.channel.channel_type_id === 203) && // Betriebsdaten oder Hüllkurven-Betriebsdaten
        <div className={'-mt-10'}>
            <div className={'grid card'}>
                <div className={'col-6 -mt-6 -mb-3'}>
                    <Input
                        edit={true} label={t('metrologyManagement:attributes.saveData')}
                        name={'logical_channel_settings.save_data'} type={'checkbox'}
                        tooltip={t('metrologyManagement:tooltips.saveData')}
                        validationControl={props.control} validationErrorMessage={props.getFormErrorMessage}
                        validationRules={{required: false}}
                    />
                </div>
                <div className={'col-6'}>

                </div>
            </div>
            <div className={'grid card'}>
                <div className={'col-6'}>
                    <Input
                        edit={true} name={'logical_channel_settings.scaling_factor'} type={'number'} maxFractionDigits={2}
                        label={t('metrologyManagement:attributes.scalingFactor')}
                        tooltip={t('metrologyManagement:tooltips.scalingFactor')}
                        validationControl={props.control} validationErrorMessage={props.getFormErrorMessage}
                        validationRules={{required: t('metrologyManagement:attributes.scalingFactor') + ' ' + t('input:required')}}
                        value={props.getValues('logical_channel_settings.scaling_factor')}
                        onChange={(data: any) => {
                            props.setValue('logical_channel_settings.scaling_factor', data.value);
                        }}
                    />
                </div>
                <div className={'col-6'}>
                    <Input
                        edit={true} name={'logical_channel_settings.offset'} type={'number'} maxFractionDigits={2}
                        label={t('metrologyManagement:attributes.offset')}
                        tooltip={t('metrologyManagement:tooltips.offset')}
                        validationControl={props.control} validationErrorMessage={props.getFormErrorMessage}
                        validationRules={{required: t('metrologyManagement:attributes.offset') + ' ' + t('input:required')}}
                        value={props.getValues('logical_channel_settings.offset')}
                        onChange={(data: any) => {
                            props.setValue('logical_channel_settings.offset', data.value);
                        }}
                    />
                </div>
            </div>
        </div>}

        {(props.channel.channel_type_id === 202 || props.channel.channel_type_id === 205  || // Zeitsignaldaten oder Hüllkurven-Zeitsignal
          props.channel.channel_type_id === 201 || props.channel.channel_type_id === 204) && // Spektrale Messdaten oder Hüllkurven-Spektrum
            <div className={'grid'}>
                <div className={'col-5 card -ml-1 mr-2'}>
                    <OperatingStateSelectList
                        projectId={props.channel.project_id}
                        label={t('metrologyManagement:attributes.operatingState')}
                        tooltip={t('metrologyManagement:tooltips.operatingState')}
                        name={'logical_channel_settings.operating_state_id'}
                        showClear={true}
                        value={props.getValues('logical_channel_settings.operating_state_id')}
                        onChange={onOperatingStateChange}
                        validationControl={props.control}
                        validationErrorMessage={props.getFormErrorMessage}
                        validationRules={{required: false}}
                    />
                    <Input
                        edit={true} name={'logical_channel_settings.lead_time'} type={'number'} maxFractionDigits={1}
                        label={t('metrologyManagement:attributes.leadTime')}
                        tooltip={t('metrologyManagement:tooltips.leadTime')}
                        disabled={props.getValues('logical_channel_settings.operating_state_id') === null}
                        value={props.getValues('logical_channel_settings.lead_time')}
                        onChange={(data: any) => {
                            props.setValue('logical_channel_settings.lead_time', data.value);
                        }}
                        validationControl={props.control} validationErrorMessage={props.getFormErrorMessage}
                        // Vorlaufzeit ist nur erforderlich, wenn ein Betriebszustand ausgewählt und Channeltyp ZEIT oder HKZT ist
                        validationRules={{required: props.getValues('logical_channel_settings.operating_state_id') !== null &&
                                (props.getValues('channel_type_id') === 202 || props.getValues('channel_type_id') === 205) // ZEIT | HKZT
                            ? t('metrologyManagement:attributes.followUpTime') + ' ' + t('input:required')
                            : false
                        }}
                    />
                    <Input
                        edit={true} name={'logical_channel_settings.follow_up_time'} type={'number'} maxFractionDigits={1}
                        label={t('metrologyManagement:attributes.followUpTime')}
                        tooltip={t('metrologyManagement:tooltips.followUpTime')}
                        disabled={props.getValues('logical_channel_settings.operating_state_id') === null}
                        value={props.getValues('logical_channel_settings.follow_up_time')}
                        onChange={(data: any) => {
                            props.setValue('logical_channel_settings.follow_up_time', data.value);
                        }}
                        validationControl={props.control} validationErrorMessage={props.getFormErrorMessage}
                        // Nachlaufzeit ist nur erforderlich, wenn ein Betriebszustand ausgewählt und Channeltyp ZEIT oder HKZT ist
                        validationRules={{required: props.getValues('logical_channel_settings.operating_state_id') !== null &&
                                (props.getValues('channel_type_id') === 202 || props.getValues('channel_type_id') === 205) // ZEIT | HKZT
                            ? t('metrologyManagement:attributes.followUpTime') + ' ' + t('input:required')
                            : false
                        }}
                    />
                    {(props.channel.channel_type_id === 201 || props.channel.channel_type_id === 204) && // Spektrale Messdaten oder Hüllkurven-Spektrum
                    <CutoffFrequencySelectList
                        channelTypeId={props.channel.channel_type_id}
                        label={t('metrologyManagement:attributes.cutoffFrequency')}
                        name={'logical_channel_settings.cutoff_frequency'}
                        value={props.getValues('logical_channel_settings.cutoff_frequency')}
                        onChange={onCutoffFrequencyChange}
                        validationControl={props.control}
                        validationErrorMessage={props.getFormErrorMessage}
                        validationRules={{required: t('metrologyManagement:attributes.cutoffFrequency') + ' ' + t('input:required')}}
                    />}
                </div>
                {(props.channel.channel_type_id === 201 || props.channel.channel_type_id === 204) && // Spektrale Messdaten oder Hüllkurven-Spektrum
                <div className={'col-7 card -mr-3'}>
                    <div className={'grid'}>
                        <div className={'col-7'}>
                            <Input
                                edit={true} label={t('metrologyManagement:attributes.savingConditionRmsa')}
                                name={'logical_channel_settings.saving_condition_rmsa'} type={'checkbox'}
                                tooltip={t('metrologyManagement:tooltips.savingConditionRmsa')}
                                validationControl={props.control} validationErrorMessage={props.getFormErrorMessage}
                                validationRules={{required: false}}
                            />
                        </div>
                        <div className={'col-5'}>
                            <Input
                                edit={true} label={t('metrologyManagement:attributes.thresholdRmsa')}
                                name={'logical_channel_settings.threshold_rmsa'} type={'number'} maxFractionDigits={3}
                                tooltip={t('metrologyManagement:tooltips.saveData')}
                                value={props.getValues('logical_channel_settings.thresholdRmsa')}
                                onChange={(data: any) => {
                                    props.setValue('logical_channel_settings.threshold_rmsa', data.value);
                                }}
                                validationControl={props.control} validationErrorMessage={props.getFormErrorMessage}
                                validationRules={{required: props.getValues('logical_channel_settings.saving_condition_rmsa')
                                    ? t('metrologyManagement:attributes.thresholdRmsa') + ' ' + t('input:required')
                                    : false
                                }}
                            />
                        </div>
                    </div>
                    <div className={'grid'}>
                        <div className={'col-7'}>
                            <Input
                                edit={true} label={t('metrologyManagement:attributes.savingConditionRmsv')}
                                name={'logical_channel_settings.saving_condition_rmsv'} type={'checkbox'}
                                tooltip={t('metrologyManagement:tooltips.savingConditionRmsv')}
                                validationControl={props.control} validationErrorMessage={props.getFormErrorMessage}
                                validationRules={{required: false}}
                            />
                        </div>
                        <div className={'col-5'}>
                            <Input
                                edit={true} label={t('metrologyManagement:attributes.thresholdRmsv')}
                                name={'logical_channel_settings.threshold_rmsv'} type={'number'} maxFractionDigits={3}
                                tooltip={t('metrologyManagement:tooltips.thresholdRmsv')}
                                value={props.getValues('logical_channel_settings.threshold_rmsv')}
                                onChange={(data: any) => {
                                    props.setValue('logical_channel_settings.threshold_rmsv', data.value);
                                }}
                                validationControl={props.control} validationErrorMessage={props.getFormErrorMessage}
                                validationRules={{required: props.getValues('logical_channel_settings.saving_condition_rmsv')
                                    ? t('metrologyManagement:attributes.thresholdRmsv') + ' ' + t('input:required')
                                    : false
                                }}
                            />
                        </div>
                    </div>
                    <div className={'grid'}>
                        <div className={'col-7'}>
                            <Input
                                edit={true} label={t('metrologyManagement:attributes.savingConditionRmsd')}
                                tooltip={t('metrologyManagement:tooltips.savingConditionRmsd')}
                                name={'logical_channel_settings.saving_condition_rmsd'} type={'checkbox'}
                                validationControl={props.control} validationErrorMessage={props.getFormErrorMessage}
                                validationRules={{required: false}}
                            />
                        </div>
                        <div className={'col-5'}>
                            <Input
                                edit={true} label={t('metrologyManagement:attributes.thresholdRmsd')}
                                name={'logical_channel_settings.threshold_rmsd'} type={'number'} maxFractionDigits={3}
                                tooltip={t('metrologyManagement:tooltips.thresholdRmsd')}
                                value={props.getValues('logical_channel_settings.threshold_rmsd')}
                                onChange={(data: any) => {
                                    props.setValue('logical_channel_settings.threshold_rmsd', data.value);
                                }}
                                validationControl={props.control} validationErrorMessage={props.getFormErrorMessage}
                                validationRules={{required: props.getValues('logical_channel_settings.saving_condition_rmsd')
                                    ? t('metrologyManagement:attributes.thresholdRmsd') + ' ' + t('input:required')
                                    : false
                                }}
                            />
                        </div>
                    </div>
                </div>}
                {(props.channel.channel_type_id === 202 || props.channel.channel_type_id === 205) && // Zeitsignaldaten oder Hüllkurven-Zeitsignal
                <div className={'col-7 card -mr-3'}>
                    <MeasuringTypeSelectList
                        label={t('metrologyManagement:attributes.measuringType')}
                        value={measuringType}
                        onChange={(e: any) => {
                            setMeasuringType(e.value);
                            switch (e.value) {
                                case 1: // Standardmessung
                                    props.setValue('logical_channel_settings.synchronous_measuring', false);
                                    props.setValue('logical_channel_settings.continually_measuring', false);
                                    break;
                                case 2: // Synchrone Messung
                                    props.setValue('logical_channel_settings.synchronous_measuring', true);
                                    props.setValue('logical_channel_settings.continually_measuring', false);
                                    break;
                                case 3: // Kontinuierliche Messung
                                    props.setValue('logical_channel_settings.synchronous_measuring', false);
                                    props.setValue('logical_channel_settings.continually_measuring', true);
                                    break;
                            }
                        }}
                        validationControl={props.control}
                        validationErrorMessage={props.getFormErrorMessage}
                        validationRules={{required: false}} // Eins ist immer ausgewählt
                    />
                    {/*<Input*/}
                    {/*    edit={true} label={t('metrologyManagement:attributes.synchronousMeasuring')}*/}
                    {/*    name={'logical_channel_settings.synchronous_measuring'} type={'checkbox'}*/}
                    {/*    tooltip={t('metrologyManagement:tooltips.synchronousMeasuring')}*/}
                    {/*    validationControl={props.control} validationErrorMessage={props.getFormErrorMessage}*/}
                    {/*    validationRules={{required: false}}*/}
                    {/*/>*/}
                    {/*<Input*/}
                    {/*    edit={true} label={t('metrologyManagement:attributes.continuallyMeasuring')}*/}
                    {/*    name={'logical_channel_settings.continually_measuring'} type={'checkbox'}*/}
                    {/*    tooltip={t('metrologyManagement:tooltips.continuallyMeasuring')}*/}
                    {/*    validationControl={props.control} validationErrorMessage={props.getFormErrorMessage}*/}
                    {/*    validationRules={{required: false}}*/}
                    {/*/>*/}
                </div>}
            </div>
        }

        {(props.channel.channel_type_id === 200 || props.channel.channel_type_id === 203 || // Betriebsdaten oder Hüllkurven-Betriebsdaten
          props.channel.channel_type_id === 202 || props.channel.channel_type_id === 205) && // Zeitsignaldaten oder Hüllkurven-Zeitsignal
            <div className={'grid card -mr-2 -ml-2'}>
                <div className={'col-6'}>
                    {props.channel.channel_type_id !== 205 && // Darf nicht Hüllkurven-Zeitsignal sein
                    <SignalTypeSelectList
                        label={t('metrologyManagement:attributes.signalType')}
                        name={'logical_channel_settings.signal_type'}
                        value={props.getValues('logical_channel_settings.signal_type')}
                        onChange={(data: any) => {
                            props.setValue('logical_channel_settings.signal_type', data.value);
                        }}
                        validationControl={props.control}
                        validationErrorMessage={props.getFormErrorMessage}
                        validationRules={{required: t('metrologyManagement:attributes.signalType') + ' ' + t('input:required')}}
                    />}

                    {(props.channel.channel_type_id === 202 || props.channel.channel_type_id === 205) && // Zeitsignaldaten oder Hüllkurven-Zeitsignal
                    <DurationInSecondsInput
                        channelTypeId={props.channel.channel_type_id}
                        signalTypeId={props.getValues('logical_channel_settings.signal_type')}
                        channelAddress={props.sensor.channel_address ? props.sensor.channel_address : 0}
                        samplingRate={props.getValues('logical_channel_settings.sampling_rate')}
                        label={t('metrologyManagement:attributes.durationInSeconds')}
                        name={'logical_channel_settings.duration_in_seconds'}
                        value={props.getValues('logical_channel_settings.duration_in_seconds')}
                        setValue={onDurationInSecondsChange}
                        validationControl={props.control}
                        validationErrorMessage={props.getFormErrorMessage}
                        validationRules={{required: t('metrologyManagement:attributes.durationInSeconds') + ' ' + t('input:required')}}
                    />}
                </div>
                <div className={'col-6'}>
                    <SamplingRateSelectList
                        channelTypeId={props.channel.channel_type_id}
                        // signalTypeId={props.logicalChannelSettings.signal_type}
                        signalTypeId={props.getValues('logical_channel_settings.signal_type')}
                        channelAddress={typeof props.sensor.channel_address === 'number' ? props.sensor.channel_address : -1}
                        label={t('metrologyManagement:attributes.samplingRate')}
                        name={'logical_channel_settings.sampling_rate'}
                        value={props.getValues('logical_channel_settings.sampling_rate')}
                        onChange={onSamplingRateChange}
                        validationControl={props.control}
                        validationErrorMessage={props.getFormErrorMessage}
                        validationRules={{required: t('metrologyManagement:attributes.samplingRate') + ' ' + t('input:required')}}
                    />
                </div>
            </div>
        }
    </>);
}