import React, {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import MapContext from '../map/MapContext';
import {Overlay} from 'ol';
import {Accordion, AccordionTab} from 'primereact/accordion';
import {useAppSelector} from '../../../redux/hooks';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {parseStyle} from '../../../functions/functionsOpenlayer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDashboard, faExternalLink} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Button} from 'primereact/button';
import keycloakfetch from '../../../functions/keycloakfetch';
import settings from '../../../config/settings';
import {hideWaitAnimation, showWaitAnimation} from '../../global/CustomWaitAnimation';
import {classNames} from 'primereact/utils';

type popupProps = {
    projectId: string
}
type featureProps = {
    [key: string]: any
}
const Popup: FunctionComponent<popupProps> = ({projectId}) => {
    // @ts-ignore
    const {map} = useContext(MapContext);
    const {t} = useTranslation(['geomonitoring']);
    const navigate = useNavigate();
    const layerAttributeArray = useAppSelector((state: any) => state.configLayer.layerData.layerAttributesArray);
    const layerNameArray = useAppSelector((state: any) => state.configLayer.layerData.layerNameArray);
    const layerStyleArray = useAppSelector((state: any) => state.configLayer.layerData.styleArray);
    const featureInfoArray = useAppSelector((state: any) => state.configLayer.layerData.layerfeatureInfosArray);
    const formatedAttributesArray = useAppSelector((state: any) => state.configLayer.layerData.formatLayerAttributesArray);
    const showPopup = useAppSelector((state: any) => state.configLayer.layerData.showPopup);
    const container = document.getElementById('popup');
    const content = document.getElementById('popup-content');
    const closer = document.getElementById('popup-closer');
    const [popup, setPopup] = useState({
        setPosition(coordinate: any) {
        }
    });
    const [popupClassName, setPopupClassName] = useState('top-right');
    const [activeIndex, setActiveIndex] = useState(0);
    const [grafanaLink, setGrafanaLink] = useState('');
    const [seismicEventId, setSeismicEventId] = useState<string | undefined>('');
    const [formerFeature, setFormerFeature] = useState<featureProps>({});
    const [accordionContent, setAccordionContent] = useState([]);
    const [featureArray, setFeatureArray] = useState<featureProps>([]);
    const [formatedValuesArray, setFormatedValuesArray] = useState([]);
    const refFeature = useRef(featureArray);
    const refFormerFeature = useRef(formerFeature);
    const popupRef = useRef(popup);
    const refLayerName = useRef(layerNameArray);
    const refLayerStyles = useRef(layerStyleArray);
    const refLayerAttribute = useRef(layerAttributeArray);
    const refFeatureInfo = useRef(featureInfoArray);
    const refGrafana = useRef(grafanaLink);
    const refFormatedAttributes = useRef(formatedAttributesArray);
    const refFormatedValues = useRef(formatedValuesArray);
    const refPopup=useRef(showPopup);
    let dataTableArray: JSX.Element[] = [];

    const replaceValues = (key: string, layerId: string) => {
        let value = key;
        // console.log(key, refFormatedValues.current,refFormatedAttributes.current[layerId])
        if (refFormatedAttributes.current[layerId]) {
            refFormatedValues.current.forEach((val: any) => {

                // console.log(val.name, key)
                if (val.name === key) {
                    // console.log('replace', key, decodeURIComponent(escape(window.atob(val.unit_html_base64))))
                    value = decodeURIComponent(escape(window.atob(val.unit_html_base64)));
                }

            });
        }

        if (key == 'previewsg') {
            value = 'Image preview';
        }

        // console.log('final return', key, value)
        // if(formatedValues.length==0){
        return value;
        //  }

    };
    const getValues2Format = async () => {
        let retValue: any = [];
        await keycloakfetch.get(settings.apiUrl + '/getLayerAliasUnit?project_id=' + projectId + '&filter_by=projectId + projectId').then(result => {
            if (result) {
                // console.log('result:',result)
                retValue = result;
            }

        });
        return retValue;
    };

    const sortThroughProperties = (layerId: string, properties: any) => {

        const propKeys = Object.keys(properties);
        const allowedValues = refLayerAttribute.current[layerId] && refLayerAttribute.current[layerId].length > 0 ? refLayerAttribute.current[layerId] : propKeys;
        const ignoredValues = ['geom', 'id', 'slice', 'THE_GEOM', 'objectid', 'geometry'];
        const propArray: any = [];

        if (properties && propKeys.length > 0) {
            const subArray: { name: any; value: any; }[] = [];
            let counter = 0;
            propKeys.forEach((key: any) => {
                if (!ignoredValues.includes(key)) {
                    const attributeValue = typeof properties[key] === 'object' ? '' : properties[key];
                    switch (true) {
                        case (key === 'slice'):
                            break;
                        case (allowedValues.includes(key)):
                            const newKey = replaceValues(key, layerId);
                            subArray.push({name: newKey, value: attributeValue});
                            break;
                    }
                }
                counter++;
            });
            if (Object.keys(subArray).length > 0 && counter === propKeys.length) {
                propArray.title = refLayerName.current[layerId];
                propArray.values = subArray;
            }
        }
        // console.log(propKeys)
        return propArray.sort((a: string, b: string) => {
            return propKeys.indexOf(a) - propKeys.indexOf(b);
        });
    };

    const setFirstLetterToUppercase = (word: string) => {
        if (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }
        return word;
    };

    const getFormatedDataRow = (dataRow: { name: string; value: string | undefined; }) => {

        let dataValue;

        switch (dataRow.name) {
            case 'link':
                dataValue = (
                    <a href={dataRow.value} target="_blank" rel="noreferrer">
                        {// @ts-ignore
                            dataRow.value.split('\/').pop()
                        }
                    </a>
                );
                break;
            case 'previewsg':
                dataValue = <img src={dataRow.value} alt="Image Preview" style={{width: '200px'}}/>;
                break;
            case 'Image preview':
                dataValue = <img src={dataRow.value} alt="Image Preview" style={{width: '200px'}}/>;
                break;
            default:
                dataValue = dataRow.value;
                break;

        }
        return dataValue;
    };

    const htmlTemplate = (dataRow: any) => {
        // return setFirstLetterToUppercase(dataRow.name) + ':';
        const isHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(dataRow.name);
        if (isHTML) {
            return (<div dangerouslySetInnerHTML={{__html: dataRow.name}}/>);
        }
        return setFirstLetterToUppercase(dataRow.name);
    };

    const getDataTableJSX = (sortedProperties: {
        title: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
        values: any[] | undefined;
    }, featureId: React.Key | null | undefined) => {
        return (
            <AccordionTab header={sortedProperties.title} key={featureId}>
                <div className="m-0">
                    <DataTable value={sortedProperties.values} key={featureId + '_dataTable'} autoLayout={true}>
                        <Column field="name" header={t('popupNenner')} style={{width: '40%'}}
                            body={htmlTemplate}></Column>
                        <Column field="value" header={t('popupValue')} style={{width: '60%'}} align={'left'}
                            body={(dataRow: any) => {
                                return getFormatedDataRow(dataRow);
                            }}
                        ></Column>
                    </DataTable></div>
            </AccordionTab>);
    };

    const getDataTable = (parentLayer: {
        originalname: any;
        get: (arg0: string) => any; }, f: { getProperties: () => any; getId: () => any; },) => {
        const layerId = parentLayer.get('id');
        const sortedProperties = sortThroughProperties(layerId, f.getProperties());
        const featureId = f.getId();
        const layerName = parentLayer.get('originalname') ? parentLayer.get('originalname')  : layerId;

        if (Object.keys(sortedProperties).length > 0) {
            const dataTable = getDataTableJSX(sortedProperties, featureId);
            // console.log(parentLayer,f)
            const fArray = {feature: f, parentLayerId: layerName.toLowerCase(), layerId: layerId};
            return ({fArray: fArray, datatable: dataTable});
        }
        return null;
    };

    const getRasterDataTable = (properties: any, parentLayerId: string, featureId: string) => {
        const sortedProperties = sortThroughProperties(parentLayerId.toLowerCase(), properties);

        if (properties.type === 'Local Earthquake') {
            setSeismicEventId(properties.eventid);
        } else {
            setSeismicEventId('');
        }

        if (Object.keys(sortedProperties).length > 0) {
            const dataTable = getDataTableJSX(sortedProperties, featureId);
            return ({datatable: dataTable});
        }
        return null;
    };

    const poiFeatureAtPixel = (pixel: any) => {

        return new Promise((resolve, reject) => {
            map.forEachFeatureAtPixel(pixel, function (f: any, parentLayer: any) {
                if (f.get('type') === 'Local Earthquake') {
                    setSeismicEventId(f.get('eventid'));
                } else {
                    setSeismicEventId('');
                }

                if (f.get('layerKind') === 'poiLayer')       {
                    // console.log(f)
                    if (f.get('grafanaLink') !== '' && f.get('grafanaLink') !== null) {
                        setGrafanaLink(f.get('grafanaLink'));
                    }

                    resolve(true);
                }
            });
            resolve(false);
        });
    };

    const forEachFeatureAtPixel = (pixel: any) => {
        dataTableArray = [];

        return new Promise((resolve) => {
            const fArray: any = [];
            map.forEachFeatureAtPixel(pixel, function (f: any, parentLayer: any) {
                if (f.getId()) {

                    const splitted = f.getId().split('.');
                    const layerId = splitted[0];
                    if ((!f.get('layerKind') && f.get('layerKind') !== 'poiLayer') && refFeatureInfo.current[layerId.toLowerCase()]) {

                        const data = getDataTable(parentLayer, f);
                        //  console.log(data,f)
                        if (data !== null) {
                            fArray.push(data.fArray);
                            dataTableArray.push(data.datatable as JSX.Element);
                        }
                    }
                }
            });
            setFeatureArray(fArray);
            refFeature.current = fArray;
            resolve(dataTableArray);
        });
    };

    const isJsonString = (str: string) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    const forEachLayerAtPixel = (e: any) => {
        let counter = 0;
        const viewResolution = map.getView().getResolution();
        const layerArray = map.getLayers();
        const rasterDataArray: any = [];
        return new Promise(resolve => {
            layerArray.forEach(function (layer: any) {
                if (layer.get('visible') && !layer.get('baseLayer') && layer.get('type') === 'raster') {
                    const url = layer.getSource().getFeatureInfoUrl(
                        e.coordinate,
                        viewResolution,
                        'EPSG:3857',
                        {'INFO_FORMAT': 'application/json'}
                    );

                    if (url) {

                        fetch(url)
                            .then((response) => response.text())
                            .then((json) => {
                                counter++;
                                if (isJsonString(json)) {
                                    const parsedJson = JSON.parse(json);

                                    if (parsedJson && parsedJson.features.length > 0) {
                                        const data = getRasterDataTable(parsedJson.features[0].properties, layer.get('name'), layer.get('id'));
                                        if (data !== null && refFeatureInfo.current[layer.get('name').toLowerCase()]) {
                                            rasterDataArray.push(data.datatable as JSX.Element);
                                        }
                                    }
                                }
                                if (counter === layerArray.getArray().length) {
                                    resolve(rasterDataArray);
                                }
                            });
                    } else {
                        counter++;
                    }
                } else {
                    counter++;
                }
                // console.log(counter, layerArray.getArray().length)
                if (counter === layerArray.getArray().length) {
                    resolve(rasterDataArray);
                }
            }
            );

        });
    };

    const highlightFeature = () => {
        const featureLength = refFeature.current.length;
        if (featureLength > 0 && activeIndex !== null && activeIndex < featureLength) {

            const featureData = refFeature.current[activeIndex];
            const feature = featureData.feature;
            const highlightedStyle = refLayerStyles.current[featureData.parentLayerId][featureData.layerId].highlight;
            const defaultStyle = refLayerStyles.current[featureData.parentLayerId][featureData.layerId].default;
            parseStyle(highlightedStyle, feature);
            resetFeatureStyle(feature, defaultStyle);
        }
    };

    const removePopup = () => {
        if (refFormerFeature.current !== null && refFormerFeature.current.style) {
            parseStyle(refFormerFeature.current.style, refFormerFeature.current.feature);
        }
        popupRef.current.setPosition(undefined);
        closer?.blur();
        setFormerFeature({});
    };

    const placePopup = (coordinate: any) => {
        popupRef.current.setPosition(coordinate);
        map.addOverlay(popupRef.current);
    };

    const resetFeatureStyle = (feature: React.SetStateAction<null>, defaultStyle: any) => {
        if (refFormerFeature.current !== null && refFormerFeature.current.style) {
            parseStyle(refFormerFeature.current.style, refFormerFeature.current.feature);
        }
        setFormerFeature({feature: feature, style: defaultStyle});
    };

    const mapClickEvent = async (e: any) => {
        setGrafanaLink('');
        setAccordionContent([]);
        const pixelFeaturesDatatables: any = await forEachFeatureAtPixel(e.pixel);
        const rasterFeaturesDatatables: any = await forEachLayerAtPixel(e);
        const poiFeatureFound = await poiFeatureAtPixel(e.pixel);

        const mergedDataTables = pixelFeaturesDatatables.concat(rasterFeaturesDatatables);
        if (mergedDataTables.length > 0 || (poiFeatureFound && refGrafana.current !== '')) {
            const mapSize = map.getSize();
            const pixelMarker = map.getPixelFromCoordinate(e.coordinate);

            let pC = '';

            if (pixelMarker[1] > (mapSize[1] / 2)) {
                pC += 'top';
            } else {
                pC += 'bottom';
            }

            pC += '-';

            if (pixelMarker[0] > (mapSize[0] / 2)) {
                pC += 'left';
            } else {
                pC += 'right';
            }

            setPopupClassName(pC);

            if (mergedDataTables.length > 0 && poiFeatureFound === false) {
                setAccordionContent(mergedDataTables);
                placePopup(e.coordinate);
            } else if (refGrafana.current !== '' && refGrafana.current !== null) {
                placePopup(e.coordinate);
            } else {
                removePopup();
            }
            highlightFeature();
        } else {
            removePopup();
        }

    };

    const getFormatedValues = async () => {
        const formatedV = await getValues2Format();
        return formatedV;
    };
    const navigateToGrafana = () => {
        navigate('#dashboards_' + refGrafana.current);
    };

    const navigateToSeismic = () => {
        showWaitAnimation();
        if (seismicEventId) {
            return fetch('https://devicescan.api.dmt-group.com/getSeismogramLink?event_id=' + seismicEventId
            ).then((result) => {
                return result.text();
            }).then((result: any) => {
                hideWaitAnimation();
                const wnd = window.open('about:blank', '_blank', '');
                if (wnd) {
                    wnd.document.write(result);
                    wnd.document.title = 'Seismogram';
                }
            }).catch(() => {
                hideWaitAnimation();
            });
        }
    };

    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;

        const popup = new Overlay({
            element: container ? container : undefined
        });

        setPopup(popup);
        popupRef.current = popup;
        map.on('click', async (evt: any) => {
            if(refPopup.current){
                await mapClickEvent(evt);
            }
        });

        map.on('contextmenu', (evt: any) => {
            evt.preventDefault();
            removePopup();
        });
        // @ts-ignore
        if (closer) {
            closer.onclick = function () {
                removePopup();
                return false;
            };
        }

        getFormatedValues().then(r => setFormatedValuesArray(r));
        return () => {
            if (map) {
                map.removeLayer(popup);
            }
        };
    }, [map]);

    useEffect(() => {
        refLayerName.current = layerNameArray;
    }, [layerNameArray]);


    useEffect(() => {
        refFormatedAttributes.current = formatedAttributesArray;
    }, [formatedAttributesArray]);

    useEffect(() => {
        refLayerStyles.current = layerStyleArray;
    }, [layerStyleArray]);

    useEffect(() => {
        refLayerAttribute.current = layerAttributeArray;
    }, [layerAttributeArray]);

    useEffect(() => {
        refFeature.current = featureArray;
    }, [featureArray]);

    useEffect(() => {
        refFormerFeature.current = formerFeature;
    }, [formerFeature]);

    useEffect(() => {
        refFeatureInfo.current = featureInfoArray;
    }, [featureInfoArray]);

    useEffect(() => {
        refGrafana.current = grafanaLink;
    }, [grafanaLink]);

    useEffect(() => {
        highlightFeature();
    }, [activeIndex]);

    useEffect(() => {
        refFormatedValues.current = formatedValuesArray;
    }, [formatedValuesArray]);

    useEffect(() => {
        refPopup.current = showPopup;
    }, [showPopup]);


    return (
        <div>
            <div id="popup" className={classNames('ol-popup', 'map-info', popupClassName)}>
                <div className="map-info-content">
                    {(refGrafana.current && refGrafana.current !== '') &&
                    <p className={'grafana-link'}>{t('gotToGrafanaBoard')} : <Button onClick={navigateToGrafana}
                        aria-label="Search"><FontAwesomeIcon
                            icon={faDashboard}/></Button></p>}
                    {(seismicEventId && seismicEventId !== '') &&
                    <p className={'seismic-link'}>{t('openSeismic')} : <Button onClick={navigateToSeismic}
                        aria-label="Search"><FontAwesomeIcon
                            icon={faExternalLink}/></Button></p>}
                    <Accordion className={'mr-2'} activeIndex={activeIndex}
                        onTabChange={(e) => setActiveIndex(e.index)}>
                        {accordionContent}
                    </Accordion>
                    <a href="#" id="popup-closer" className="ol-popup-closer"></a>
                    <div id="popup-content"></div>
                </div>
            </div>
        </div>
    );
};

export default Popup;
