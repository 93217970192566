/*
 * PlotEditTemplate.tsx
 * Author: fwunderlich
 * Date: 10.03.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useRef, useState} from 'react';
import PlotChannelSelectList from './PlotChannelSelectList';
import {useTranslation} from 'react-i18next';
import {Toast} from 'primereact/toast';
import PlotChart from './PlotChart';
import {Button} from 'primereact/button';
import {createPlotTemplate, getPlotTemplates, updatePlotTemplate, deletePlotTemplate} from './PlotFunctions';
import {Dropdown} from 'primereact/dropdown';
import Input from '../global/Input';
import ViewButtons from '../global/ViewButtons';
import CustomDialog, {CustomDialogHeaderType} from '../global/CustomDialog';
import UserEditForm from '../userManagement/UserEditForm';
import {Form} from 'react-router-dom';
import {showMessageOnError} from '../global/CustomToast';
import {Checkbox} from 'primereact/checkbox';
import {getUserFromLocalStorage} from '../functions/Global';
import {ConfirmDialog} from 'primereact/confirmdialog';
import {Card} from 'primereact/card';
import TimeRangePicker from '../global/TimeRangePicker';
import {useForm} from 'react-hook-form';

type Props = {
    label: string;
    userOnly: boolean;
    disableUserOnly: boolean;
    onSave: (label: string, checkd: boolean) => void;
}

const PlotEditTemplate = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common', 'measurementData']);

    const [label, setLabel] = useState<string>('');
    const [userOnly, setUserOnly] = useState<boolean>(true);

    const defaultValues = {
        name: props.label || ''
    };

    const {control, formState: {errors}, handleSubmit, reset, getValues, setValue, watch} = useForm({defaultValues});

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    useEffect(() => {
        setLabel(props.label);
        setUserOnly(props.userOnly);
    }, []);

    const submit = () => {
        props.onSave(label, userOnly);
    };

    return (
        <>
            <form id="formDialog" onSubmit={handleSubmit(submit)}>
                <Input
                    name="name"
                    label={t('measurementData:attributes.templateName')}
                    edit={true}
                    value={label}
                    validationControl={control}
                    validationRules={{required: t('measurementData:attributes.templateName') + ' ' + t('input:required')}}
                    validationErrorMessage={getFormErrorMessage}
                    onChange={(e) => {
                        setLabel(e.target.value);
                    }}/>
            </form>
            <div className="col-12">
                <Checkbox inputId="cb" className="mr-2" checked={userOnly} onChange={(e) => {
                    setUserOnly(e.checked);
                }}
                disabled={props.disableUserOnly}
                tooltip={props.disableUserOnly ? t('measurementData:tooltips.changeUserToolTip') : ''}/>
                <label htmlFor="cb"
                    className="p-checkbox-label inline-block">{t('measurementData:attributes.userOnly')}</label>
            </div>
        </>
    );
};

export default PlotEditTemplate;
