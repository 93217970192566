/*
 * BillingItemTypeForm.tsx
 * Author: lnappenfeld
 * Date: 15.08.2024
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import Input from '../global/Input';
import {Nullable} from '../functions/Global';
import {minValidation} from '../../functions/functionLibrary';
import {BillingItemType, createBillingItemType, updateBillingItemType} from '../functions/Billing';
import {BillingItemMainTypeSelectList} from './BillingItemMainTypeSelectList';
import moment from 'moment';

type Props = {
    projectId: string,
    billingItemType: Nullable<BillingItemType>,
    onFinished: (success: boolean) => void
}

export const BillingItemTypeForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const billingItemTypeId = props.billingItemType ? props.billingItemType.id : null;

    const defaultValues = {
        'billing_item_main_type_id': props.billingItemType ? props.billingItemType.billing_item_main_type_id : null,
        'price': props.billingItemType ? props.billingItemType.price : null,
        'timestamp': props.billingItemType ? new Date(props.billingItemType.valid_from_date_utc) : new Date(),
    };

    const {control, formState: {errors}, handleSubmit, reset, getValues, watch, setValue, trigger} = useForm({defaultValues});

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    const saveData = (billingItemType: any) => {
        const projectId = {'project_id': props.projectId};
        billingItemType = {...projectId, ...billingItemType};

        // Der Zeitstempel des Datepickers ist aktuell die Berliner Zeit. Damit der Wert in der Datenbank in UTC
        // gespeichert wird, wird dieser hier umgewandelt.
        billingItemType['timestamp'] = moment(billingItemType['timestamp']).utcOffset(0, true).format();

        if (billingItemTypeId !== null) {
            const _billingItemTypeId = {'id': billingItemTypeId};
            billingItemType = {..._billingItemTypeId, ...billingItemType};

            updateBillingItemType(billingItemType).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), t('errorMessages:' + result.error));
                } else {
                    showMessageOnSuccess(t('success'), t('billing:toasts.billingItemTypeUpdated'));
                    props.onFinished(true);
                }
            });
        } else {
            createBillingItemType(billingItemType).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), t('errorMessages:' + result.error));
                } else {
                    showMessageOnSuccess(t('success'), t('billing:toasts.billingItemTypeCreated'));
                    props.onFinished(true);
                }
            });
        }

    };

    return (
        <form id='billingItemTypeDialog' onSubmit={handleSubmit(saveData)}>
            <div className='card'>
                <BillingItemMainTypeSelectList
                    projectId={props.projectId}
                    label={t('type')}
                    name={'billing_item_main_type_id'}
                    validationControl={control}
                    validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: t('type') + ' ' + t('input:required')}}
                />
                <Input
                    edit={true} type={'number'} label={t('billing:attributes.price')} name={'price'}
                    minFractionDigits={2} maxFractionDigits={2} useGrouping={true} mode={'currency'} currency={'EUR'}
                    onChange={(event: any) => {
                        setValue('price', event.value);
                    }}
                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                    validationRules={{...{required: t('price') + ' ' + t('input:required')}, ...minValidation(t, 0)}}
                />
                <Input
                    edit={true} className="w-full" type={'date'} name={'timestamp'}
                    label={t('billing:attributes.billing_item_type_timestamp')}
                    options={{showTime: false, showButtonBar: false, showIcon: true}}
                    minDate={new Date()} // heute
                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                    validationRules={t('billing:attributes.beginning') + ' ' + t('input:required')}
                />
            </div>
        </form>
    );
};