import React, {useState, useEffect, useRef} from 'react';
import LayersMapOl from '../components/geoMonitoring/layer/LayersMapOl';
import MapOl from '../components/geoMonitoring/map/MapOl';
import {Point} from 'ol/geom';
import PoiLayer from '../components/geoMonitoring/layer/PoiLayer';
import BaseLayers from '../components/geoMonitoring/layer/BaseLayers';
import OverlayLayer from '../components/geoMonitoring/layer/OverlayLayer';
import {Interactions, PoiClusterInteraction} from '../components/geoMonitoring/interactions';
import {useAppSelector, useAppDispatch} from '../redux/hooks';
import {
    resetData,
    setBaseLayer,
    setPermaLinkLayer,
    setWorkspace,
    isPermaLinkView,
    setBaseWorkspace
} from '../redux/GeomonitoringSlice';
import ConfigLayers from '../components/geoMonitoring/layer/ConfigLayers';
import geoSettings from '../config/geoSettings';
import {transformCoords} from '../services/SpatialService';

type Props = {
    project: any,
    configActive: boolean,
    onConfigButtonClick: any
    onConfigClick: any
    alarm: any,
    show: boolean
}

const GeoMonitoring = (props: Props): JSX.Element => {
    const [center, setCenter] = useState([0, 0]);
    const [zoom, setZoom] = useState(9);
    const projectId = props.project.id;
    const refZoom = useRef(zoom);
    const dispatch = useAppDispatch();
    let mapSet = false;

    const transformCoordsCp = (project: any) => {
        const location = project.location;
        const projectEpsg = project.location_epsg_code ? project.location_epsg_code : 4326;
        let transformCoordsCenter = location.coordinates;
        if (projectEpsg === 3587) {
            return transformCoordsCenter;
        }
        if (projectEpsg !== 4326) {
            transformCoordsCenter = transformCoords(location.coordinates, projectEpsg, 4326);
        }
        return transformCoords(transformCoordsCenter, 4326, 3857);
    };

    const getMapCenter = () => {
        const project = props.project;
        const location = project.location;
        if (location && location.coordinates) {
            const coords = transformCoordsCp(project);
            const centerPoint = new Point(coords);
            let zoomLevel = parseFloat(project.location_zoom) > 17 ? 17 : parseFloat(project.location_zoom);
            if(zoomLevel===zoom){
                zoomLevel=zoomLevel+0.1;
            };
            //const zoomLevel = parseFloat(project.location_zoom) > 17 ? 17 : parseFloat(project.location_zoom);
            const centerCoords = centerPoint.getCoordinates();
            setZoom(zoomLevel);
            setCenter(centerCoords);
        }
    };


    useEffect(() => {
        if (!mapSet) {
            //   console.log('recreateData')
            const baseWorkspace = props.project.baselayer_workspace !== null ? props.project.baselayer_workspace : geoSettings.wmsWorkspace;
            dispatch(resetData());
            if (props.project.spatialBookmark) {
                dispatch(setPermaLinkLayer(props.project.spatialBookmark.link.visibleLayerIds));
                dispatch(isPermaLinkView(props.project.spatialBookmark.is_permalink));
            }
            dispatch(setWorkspace(props.project.geoserver_workspace));
            dispatch(setBaseLayer(props.project.base_layer));
            dispatch(setBaseWorkspace(baseWorkspace));
            getMapCenter();
            mapSet = true;
        }

        // return ()=> { dispatch(resetData())};

    }, [props.project]);

    useEffect(() => {
        refZoom.current = zoom;
    }, [zoom]);

    /* Überprüfen, ob props notwendig, wenn dispatch*/
    return (<div style={{display: props.show ? 'inline' : 'none'}}>
        {/* Geo Monitoring / Karten Darstellung*/}
        <MapOl key={props.project.id + '_maps'} zoom={refZoom.current} center={center} propElem={props}>
            <LayersMapOl key={props.project.id + '_layerMaps'}>
                <BaseLayers projectId={projectId} mapDataWorkBench={props.project.geoserver_workspace}
                            baseLayer={props.project.base_layer}/>
                <PoiLayer zoomMap={refZoom.current} projectId={projectId} alarm={props.alarm}/>
                <OverlayLayer projectId={projectId}/>
                <ConfigLayers projectId={projectId}/>
            </LayersMapOl>
            {/*<Controls key={props.project.id + '_controlsMaps'}>*/}
            {/*    <ToolkitControl projectId={props.project.id} projectBaseLayer={props.project.base_layer}/>*/}
            {/*    <ScaleLineControl/>*/}
            {/*</Controls>*/}
            <Interactions key={props.project.id + '_interactionsMaps'}>
                <PoiClusterInteraction/>
            </Interactions>
        </MapOl>


    </div>);
};
export default GeoMonitoring;
