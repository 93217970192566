import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { showMessageOnSuccess } from '../../global/CustomToast';
import { FileUpload } from 'primereact/fileupload';
import {
    uploadSubPlantImage,
    deleteSubPlantImage,
} from '../functions/DetailsService';
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../global/CustomDialog';

type UploadImageProps = {
    projectId: string;
    modal: boolean;
    imageData: any;
    plantDetails: any;
    hidePopup: () => void;
};
const UploadImage = (props: UploadImageProps) => {
    const { t } = useTranslation(['common']);

    const [imageDiv, setImageDiv] = useState<boolean>(false);
    const [imageFile, setImageFile] = useState<any>();
    const [fileName, setFileName] = useState<any>('');
    const [errors, setErrors] = useState<any>([]);

    const fileUploadHandler = ({ files }: any) => {
        setImageFile(files);
        const file_name = files[0].name;
        const file = files[0];
        const reader = new FileReader();
        reader.onloadend = function () {
            setFileName(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const uploadImage = () => {
        const error: any = {};
        if (fileName) {
            const imageData = {
                project_id: props.projectId,
                name: props.plantDetails[0]['name'],
                file_type_id: 0,
                id_infrastructure: props.plantDetails[0]['id'],
                element_id: props.plantDetails[0]['id'],
                data: fileName.split(',')[1],
            };

            uploadSubPlantImage(imageData).then((resp) => {
                if (resp) {
                    props.hidePopup();
                    showMessageOnSuccess(
                        'Image Uploaded',
                        t('conditionMonitoring:image_success')
                    );
                }
            });
        } else {
            error['file'] = t('conditionMonitoring:image_error');
            setErrors(error);
        }
    };
    useEffect(() => {
        if (props.imageData.length > 0) {
            setImageDiv(true);
        }
    }, []);

    return (
        <>
            <CustomDialog onHide={() => props.hidePopup()} onCancel={() => props.hidePopup()} visible={props.modal} header={'Component Image'} showFooter={false} style={{width: '900px'}}>
                <div className="grid">
                    <div className="col-10">
                        <FileUpload
                            chooseLabel={t('conditionMonitoring:browse_image')}
                            mode="advanced"
                            auto
                            customUpload
                            uploadHandler={fileUploadHandler}
                            accept="image/*"
                            maxFileSize={1000000}
                            style={{ width: '100%' }}
                            className="mb-5"
                        />
                        <p style={{ color: 'red' }}>{errors['file']}</p>
                    </div>
                    <div className="col-2">
                        <div className="col text-center">
                            <Button
                                type="submit"
                                icon="pi pi-check"
                                className="p-button-raised p-button-primary"
                                label={t('conditionMonitoring:save_image')}
                                style={{marginTop: '40px'}}
                                onClick={() => {
                                    uploadImage();
                                }}
                            />
                        </div>


                    </div>

                </div>

                <div className="row">
                    {imageDiv && (
                        <>
                            <h3>{t('conditionMonitoring:available_images')}</h3>
                            <div className="grid">
                                {props.imageData.map((response: any, i: number) => {
                                    return (
                                        <div className="col-md-4 m-3">
                                            <Image
                                                src={
                                                    'data:image/png;base64,' +
                                                    Buffer.from(response['data'], 'binary').toString(
                                                        'base64'
                                                    )
                                                }
                                                height="80px"
                                                width="80px"
                                                preview
                                            />
                                            <i
                                                className="pi pi-trash delete-image"
                                                onClick={() => {
                                                    const data = {
                                                        project_id: response['project_id'],
                                                        element_id: props.plantDetails[0]['id'],
                                                        file_id: response['id'],
                                                    };
                                                    deleteSubPlantImage(data).then((resp) => {
                                                        if (resp) {
                                                            props.hidePopup();
                                                            showMessageOnSuccess(
                                                                t('conditionMonitoring:image_deleted_top'),
                                                                t('conditionMonitoring:image_deleted')
                                                            );
                                                        }
                                                    });
                                                }}
                                            ></i>
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                </div>

            </CustomDialog>

        </>
    );
};

export default UploadImage;
