/*
 * LogicalChannelFunctionField.tsx
 * Author: amusiol
 * Date: 01.12.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import {LogicalChannelFunction} from '../functions/Metrology';
import Input from '../global/Input';
import {useTranslation} from 'react-i18next';
import ChannelSelectList from './ChannelSelectList';
import {checkProjectType, ProjectType} from '../../functions/functionLibrary';
import {Nullable} from '../functions/Global';
import {OperatingStateSelectList} from './simon2/OperatingStateSelectList';

type Props = {
    projectId: string,
    name: string,
    value?: string,
    type: string, // text, number, ...
    definition: LogicalChannelFunction,
    control: any,
    getFormErrorMessage: any,
    setValue?: (e: any) => void,
}
export const LogicalChannelFunctionField = (props: Props) => {

    const {t} = useTranslation(['common']);
    const [channelSelectListType, setChannelSelectListType] = useState<Nullable<string>>(null);
    useEffect(() => {
        if (checkProjectType(ProjectType.MonitoringEverything)) {
            setChannelSelectListType('condition_monitoring');
        } else if (checkProjectType(ProjectType.GeoMonitoring)) {
            setChannelSelectListType('measurement');
        } else if (checkProjectType(ProjectType.ConditionMonitoring)) {
            setChannelSelectListType('condition_monitoring');
        }
    }, []);

    return (
        <>
            { props.definition.channel_type_id && channelSelectListType &&
                <ChannelSelectList
                    type={channelSelectListType || 'measurement'}
                    channelTypeId={props.definition.channel_type_id}
                    projectId={props.projectId}
                    label={t('functions:fields.' + props.definition.field.replaceAll(' ', '_'))}
                    tooltip={props.definition.tooltip
                        ? t('functions:tooltips.' + props.definition.tooltip.replaceAll(' ', '_'))
                        : ''}
                    name={'logical_channel_settings.' + props.name}
                    showClear={props.name === 'fp2' ? false : true}
                    validationControl={props.control}
                    validationErrorMessage={props.getFormErrorMessage}
                    validationRules={{required: props.name === 'fp2'}}
                />
            }
            { props.definition.operating_state_id &&
                <OperatingStateSelectList
                    projectId={props.projectId}
                    label={t('functions:fields.' + props.definition.field.replaceAll(' ', '_'))}
                    tooltip={props.definition.tooltip
                        ? t('functions:tooltips.' + props.definition.tooltip.replaceAll(' ', '_'))
                        : ''}
                    name={'logical_channel_settings.' + props.name}
                    showClear={true}
                    validationControl={props.control}
                    validationErrorMessage={props.getFormErrorMessage}
                    validationRules={{required: props.name === 'fp2'}}
                />
            }
            { !props.definition.channel_type_id && !props.definition.operating_state_id &&
                <Input
                    label={t('functions:fields.' + props.definition.field.replaceAll(' ', '_'))}
                    tooltip={props.definition.tooltip
                        ? t('functions:tooltips.'+props.definition.tooltip.replaceAll(' ', '_'))
                        : ''}
                    edit={true} type={props.type} maxFractionDigits={5}
                    onChange={(e: any) => {
                        if (props.type === 'number' && props.setValue) props.setValue(e.value);
                    }}
                    name={'logical_channel_settings.' + props.name}
                    validationControl={props.control}
                    validationErrorMessage={props.getFormErrorMessage}
                    validationRules={{required: false}}
                />
            }
        </>
    );
};