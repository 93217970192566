/*
 * ReportTypeSelectList.tsx
 * Author: lnappenfeld
 * Date: 25.10.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import Input from './../global/Input';
import {getReportTypes} from '../functions/Reporting';

type Props = {
    label: string,
    value?: string,
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
}
const ReportTypeSelectList = (props: Props): JSX.Element => {

    // Initialisiere mit undefined, damit das Skeletonloading funktioniert
    const [list, setList] = useState();

    useEffect(() => {
        getReportTypes().then(result => {
            const tmp: any = [];
            result.forEach((key: any) => {
                if (key.id !== 1) {
                    tmp.push({
                        value: key.id.toString(),
                        label: key.name,
                    });
                }
            });
            setList(tmp);
        });
    }, []);

    return (
        <Input
            name={'report_type_id'}
            edit={true}
            label={props.label}
            value={props.value}
            dropdownOptions={list} type={'dropdown'}
            onChange={(e: any) => {
                if (props.setValue) props.setValue(e.value);
                if (props.onChange) props.onChange(e);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};
export default ReportTypeSelectList;
