/*
 * GlobalMonthBillingOverview.tsx
 * Author: lnappenfeld
 * Date: 15.08.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    BillingItemSumMonthProProject,
    getBillingItemSumMonthProProject
} from '../functions/Billing';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {getGroupedItems} from '../../functions/functionLibrary';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChartLine, faUpRightFromSquare} from '@fortawesome/free-solid-svg-icons';
import settings from '../../config/settings';
import {filterTriStateCheckBox} from '../global/filter';

type Props = {
    reload: boolean,
    setReload: (reload: boolean) => void,
}

export const GlobalMonthBillingOverview = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [billingItemSumMonthProProject, setBillingItemSumMonthProProject] = useState<BillingItemSumMonthProProject[]>([]);

    // arrays for filter dropdowns
    const [filterDates, setFilterDates] = useState<any[]>([]);


    useEffect(() => {
        _getBillingItemSumMonthProProject();
    }, []);

    useEffect(() => {
        if (props.reload) {
            props.setReload(false);
            _getBillingItemSumMonthProProject();
        }
    }, [props.reload]);

    const _getBillingItemSumMonthProProject = () => {
        getBillingItemSumMonthProProject().then(result => {
            setBillingItemSumMonthProProject(result);
            setDataLoaded(true);

            // filter dropdown values
            setFilterDates(getGroupedItems(result, 'date_month', 'date_month'));
        });
    };

    const filterCheckboxIsEnabled = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), t('enabled'), t('disabled'));
    };

    const columns: ColumnObject[] = [
        {
            field: 'is_project_active', header: t('active'), style: {maxWidth: '100px'},
            filter: true, filterElement: filterCheckboxIsEnabled,
        }, {
            field: 'project_name', header: t('common:project'), style: {maxWidth: 400},
            filter: true,
            body: (item: BillingItemSumMonthProProject) => {
                return (<u>
                    <a className='cell-function' href={'project/' + item.project_id + '/#billing_3'}>
                        {/* <FontAwesomeIcon icon={faUpRightFromSquare} className='ml-2'/>&nbsp;*/}
                        {item.project_name}
                    </a>
                </u>);
            }
        }, {
            field: 'project_timestamp_start', header: t('billing:attributes.project_timestamp_start'), type: 4, dateFormat: 'DD.MM.YYYY',
            filter: true, filterField: 'project_timestamp_start'
        }, {
            field: 'project_timestamp_end', header: t('billing:attributes.project_timestamp_end'), type: 4, dateFormat: 'DD.MM.YYYY',
            filter: true, filterField: 'project_timestamp_end'
        }, {
            field: 'date_month', header: t('common:month'), type: 4, dateFormat: 'MMMM YYYY',
            filter: true, filterField: 'date_month', filterDropdownValues: filterDates
        }, {
            field: 'sum_total', header: t('billing:attributes.sum'), type: 1, currency: 'EUR',
            filter: true
        }
    ];

    const filters = {
        'is_project_active': {
            operator: FilterOperator.AND,
            constraints: [{value: true, matchMode: FilterMatchMode.EQUALS}]
        },
        'project_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'project_timestamp_start': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'project_timestamp_end': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'sum_total': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'date_month': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        }
    };

    return (
        <CustomDataTable
            id='globalMonthBillingOverview'
            columns={columns}
            filters={filters}
            editable={false}
            sortable={true}
            value={billingItemSumMonthProProject}
            ableToUpdate={true}
            dataloaded={dataLoaded}
            paginator={true}
            showExportCSV={true}
            showClearFiltersButton={true}
        />
    );
};