/*
 * NotificationWizardChannelOverview.tsx
 * Author: lnappenfeld
 * Date: 09.12.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {useTranslation} from 'react-i18next';
import {Channel, getChannelTypes} from '../functions/Metrology';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {getTimezones} from '../functions/Global';
import {getGroupedItems} from '../../functions/functionLibrary';

type Props = {
    projectId: string
    channels: Channel[] | []
    selectedChannels: Channel[] | [],
    setSelectedChannels: (channels: Channel[] | []) => void
    onFinished: (success: boolean) => any
}
export const NotificationWizardChannelOverview = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    // arrays for filter dropdowns
    const [channelTypes, setChannelTypes] = useState<any[]>([]);
    const [timezones, setTimezones] = useState<any[]>([]);
    const [sensors, setSensors] = useState<any[]>([]);
    const [locations, setLocations] = useState<any[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    useEffect(() => {
        getChannelTypes(props.projectId).then(result => {
            const items: any = [];
            result.forEach((key: any) => {
                items.push({
                    value: key.id,
                    name: key.name,
                });
            });
            setChannelTypes(items);
        });
        getTimezones().then(result => {
            const items: any = [];
            result.forEach((key: any) => {
                items.push({
                    value: key.label,
                    name: key.value,
                });
            });
            setTimezones(items);
        });

        setSensors(getGroupedItems(props.channels, 'sensor_id', 'sensor_name'));
        setLocations(getGroupedItems(props.channels, 'location_id', 'location_name'));
    }, []);

    const channelsColumns: ColumnObject[] = [
        {field: 'channel_name', header: t('name'), filter: true, filterField: 'channel_name'},
        {field: 'sensor_name', header: t('sensor'), filter: true, filterField: 'sensor_id',
            filterDropdownValues: sensors},
        {field: 'location_name', header: t('location'), filter: true, filterField: 'location_id',
            filterDropdownValues: locations},
        {field: 'channel_type_name', header: t('type'), filter: true, filterField: 'channel_type_id',
            filterDropdownValues: channelTypes},
        {field: 'timezone', header: t('timezone'), filter: true, filterField: 'timezone',
            filterDropdownValues: timezones},
    ];

    const filters = {
        'channel_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'location_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'sensor_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'channel_type_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'timezone': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        }
    };

    return (
        <form id='formDialog' onSubmit={(event) => {
            event.preventDefault();
            if (props.selectedChannels && props.selectedChannels.length > 0) {
                props.onFinished(true);
            } else {
                props.onFinished(false);
            }
        }}>
            <CustomDataTable
                id='channels'
                selectionMode= 'multiple'
                columns={channelsColumns}
                editable={true} sortable={true} value={props.channels}
                selection={props.selectedChannels}
                onSelectionChange={(e: any) => {
                    if (props.setSelectedChannels) props.setSelectedChannels(e.value);
                }}
                headerTitle={t('channels')}
                ableToDelete={false}
                ableToUpdate={false}
                filters={filters}
                dataloaded={true}
            />
        </form>
    );
}
