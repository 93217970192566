import React, {useEffect, useState} from 'react';
import Input from '../global/Input';
import LocationSelectList, {LocationSelectListType} from '../global/LocationSelectList';
import CustomDialog from '../global/CustomDialog';
import {Button} from 'primereact/button';
import {useTranslation} from 'react-i18next';

type PropsSensor = {
    projectId:string,
    fields: any,
    fieldIndex: string,
    countIndex: number,
    //channelsComponents: any,
    setSelectedSensor: any,
    setFormValues: any,
    wizardType: string,
    sensorList:any,
    projectLocations: any,
    defaultValues: any,
    setRefreshComponents: any,
    setDefaultValues: any
}
export const WizardSensorEditForm = (propsSensor: PropsSensor) => {
    const {t} = useTranslation(['common']);
    const [openDialog, setOpenDialog] = useState(false);
    const [dummy, setDummy] = useState<any>();
    const borderDefaultColor = 'lightgray';
    const [borderColor, setBorderColor] = useState(borderDefaultColor);
    useEffect(() => {
        setBorderColor(borderDefaultColor);
        if (propsSensor.fields.active)
            setBorderColor('#6ebd00');
    }, [propsSensor.fields.active]);
    const mainFormFields = (<>
        {/*<Input
            edit={true}
            className={'p-checkbox-box p-highlight p-checkbox-icon p-c pi pi-check'}
            type={'checkbox'}
            value={propsSensor.fields.active}
            label={t('sensor.anlegen')}
            onChange={(e) => {
                console.log(e);
                propsSensor.setFormValues(propsSensor.fieldIndex, 'active', e.target.value, propsSensor.countIndex);
                setDummy(e.target.value);
            }}
            name={'sensor_' + propsSensor.countIndex}/>*/}
        <Input
            name={'sensor_type_id'}
            onChange={(e) => {
                if (propsSensor.countIndex === 0 && propsSensor.wizardType === 'sensor')
                    propsSensor.setSelectedSensor(e.value);
                propsSensor.setFormValues(propsSensor.fieldIndex, 'sensor_type_id', e.value, propsSensor.countIndex);
            }}
            edit={true} disabled={true}
            label={t('sensor') + ' (' + (propsSensor.countIndex + 1) + ')'}
            dropdownOptions={propsSensor.sensorList}
            type={'dropdown'}
            value={propsSensor.fields.sensor_type_id}/>
        <Input
            edit={true}
            label={t('name')}
            name={'name'}
            onChange={(e) => {
                propsSensor.setFormValues(propsSensor.fieldIndex, 'name', e.target.value, propsSensor.countIndex);
                setDummy(e.target.value);
            }}
            value={propsSensor.fields.name}/>
        <LocationSelectList
            type={LocationSelectListType.MetrologyWizard}
            locations={propsSensor.projectLocations}
            value={propsSensor.fields.location_id}
            onChange={(e) => {
                propsSensor.setFormValues(propsSensor.fieldIndex, 'location_id', e.value, propsSensor.countIndex);
                setDummy(e.value);
            }}
            projectId={propsSensor.projectId} label={t('location')}/>
    </>);
    const extendedFormFields = (<>
        <Input
            edit={true} label={t('description')} name={'description'} type={'textarea'}
            value={propsSensor.fields.description}
            onChange={(e) => {
                propsSensor.setFormValues(propsSensor.fieldIndex, 'description', e.target.value, propsSensor.countIndex);
                setDummy(e.target.value);
            }}/>
        <Input
            edit={true} label={t('active')} name={'is_active'} type={'checkbox'}
            value={propsSensor.fields.is_active}
            onChange={(e) => {
                propsSensor.setFormValues(propsSensor.fieldIndex, 'is_active', e.target.value, propsSensor.countIndex);
                setDummy(e.target.value);
            }}
        />
        <Input
            edit={true} label={t('visible')} name={'is_visible'} type={'checkbox'}
            value={propsSensor.fields.is_visible}
            onChange={(e) => {
                propsSensor.setFormValues(propsSensor.fieldIndex, 'is_visible', e.target.value, propsSensor.countIndex);
                setDummy(e.target.value);
            }}
        />
        <Input
            edit={true}
            label={t('qrcode')}
            name={'qrcode'}
            value={propsSensor.fields.qrcode}
            onChange={(e) => {
                propsSensor.setFormValues(propsSensor.fieldIndex, 'qrcode', e.target.value, propsSensor.countIndex);
                setDummy(e.target.value);
            }}/>
    </>);
    // todo: if condition monitoring add sensor settings --element_id --sensitivity ??qr_code
    return (
        <div className={'sensor col-4'}>
            {
                <CustomDialog
                    showFooter={false}
                    visible={openDialog}
                    header={t('Metrology:editDialog.header')}
                    onCancel={() => setOpenDialog(false)}>
                    <div className={'grid'}>
                        {mainFormFields}
                        {extendedFormFields}
                    </div>
                </CustomDialog>
            }
            <div className={'card border-1'} style={{borderColor: borderColor}}>
                {mainFormFields}
                <Button
                    key={'button' + propsSensor.countIndex} type={'button'}
                    value={'more'} label={'weitere Einstellungen'}
                    onClick={() => {
                        setOpenDialog(true);
                    }}/>
                &nbsp;
                <Button
                    key={'addButton' + propsSensor.countIndex} type={'button'}
                    value={'add'} label={'Copy Sensor'}
                    onClick={() => {
                        // Copy Sensor Data
                        const newSensor =JSON.parse(JSON.stringify(propsSensor.fields));
                        propsSensor.setRefreshComponents(true);
                        const tmp = propsSensor.defaultValues;
                        tmp.sensors.push(newSensor);
                        propsSensor.setDefaultValues({...tmp});
                    }}/>
            </div>
        </div>
    );
};