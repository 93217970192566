import React, {useEffect, useRef, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import {classNames} from 'primereact/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from 'react-i18next';
import {getUserFromLocalStorage} from "../../components/functions/Global";
import {logoutMeFromGrafana} from '../../components/functions/UserManagement';
import settings from '../../config/settings';
import {showMessageOnError} from '../../components/global/CustomToast';
import {hideWaitAnimation, showWaitAnimation} from '../../components/global/CustomWaitAnimation';
import packageJson from './../../../package.json';
import preval from 'preval.macro';

const UserMenus = (props: any) => {
    const {t} = useTranslation(['common']);
    const menuRef = useRef(null);

    const buildDatetime = preval`module.exports = new Date().toLocaleDateString();`;

    const isSlim = () => {
        return props.menuMode === 'slim';
    };

    const isStatic = () => {
        return props.menuMode === 'static';
    };

    const isSidebar = () => {
        return props.menuMode === 'sidebar';
    };

    const isMobile = () => {
        return window.innerWidth <= 991;
    };


    useEffect(() => {
        if (props.colorScheme) {
            onColorSchemeChange(props.colorScheme);
        }
    }, [props.colorScheme]);

    const onColorSchemeChange = (scheme: string) => {
        changeStyleSheetUrl('layout-css', 'layout-' + scheme + '.css', 1);
        changeStyleSheetUrl('theme-css', 'theme-' + scheme + '.css', 1);
        localStorage.setItem('Theme', scheme);
    };

    const changeStyleSheetUrl = (id: any, value: any, from: any) => {
        const element = document.getElementById(id) as HTMLInputElement;
        if (element !== null) {
            const urlTokens = (element.getAttribute('href') as String).split('/');
            if (from === 1) {
                // which function invoked this function - change scheme
                urlTokens[urlTokens.length - 1] = value;
            } else if (from === 2) {
                // which function invoked this function - change color
                urlTokens[urlTokens.length - 2] = value;
            }

            const newURL = urlTokens.join('/');

            replaceLink(element, newURL);
        }
    };

    const replaceLink = (linkElement: any, href: string, callback?: any) => {
        const id = linkElement.getAttribute('id');
        const cloneLinkElement = linkElement.cloneNode(true);

        cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', id + '-clone');

        linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

        cloneLinkElement.addEventListener('load', () => {
            linkElement.remove();
            const _linkElement = document.getElementById(id);
            _linkElement && _linkElement.remove();
            cloneLinkElement.setAttribute('id', id);

            if (callback) {
                callback();
            }
        });
    };

    const [username, setUserName] = useState('');

    useEffect(() => {
        setUserName(getUserFromLocalStorage().username);
    }, [localStorage.getItem('user')])


    return (
        <>
            {!isMobile() && (isStatic() || isSlim() || isSidebar()) && (
                <div
                    className={classNames('layout-inline-menu', {'layout-inline-menu-active': props.activeInlineProfile})}>
                    <button className="layout-inline-menu-action p-link" onClick={props.onChangeActiveInlineMenu}>
                        <FontAwesomeIcon className='user-icon' icon={faUser}/>
                        <span className="layout-inline-menu-text">{username}</span>
                        <i className="layout-inline-menu-icon pi pi-angle-down"></i>
                    </button>
                    <CSSTransition
                        nodeRef={menuRef}
                        classNames="p-toggleable-content"
                        timeout={{enter: 1000, exit: 450}}
                        in={props.activeInlineProfile}
                        unmountOnExit>
                        <ul ref={menuRef} className="layout-inline-menu-action-panel">
                            <li className="layout-inline-menu-action-item">
                                <button className="p-link" onClick={() => {
                                    showWaitAnimation();
                                    logoutMeFromGrafana().then(() => {
                                        const currentUrl = window.location.href;
                                        const keycloakIdToken = localStorage.getItem('keycloak-id-token');
                                        window.location.href = settings.logoutUrl
                                            + '?id_token_hint=' + keycloakIdToken
                                            + '&post_logout_redirect_uri=' + currentUrl;
                                        hideWaitAnimation();
                                    });
                                }}>
                                    <i className="pi pi-power-off pi-fw"></i>
                                    <span>{t('logout')}</span>
                                </button>
                            </li>
                            <li className="layout-inline-menu-action-item">
                                <button className="p-link" onClick={() => {
                                    window.location.hash = '#usersettings';
                                }}>
                                    <i className="pi pi-cog pi-fw"></i>
                                    <span>{t('settings')}</span>
                                </button>
                            </li>
                            <li className="layout-inline-menu-action-item">
                                <button className="p-link" onClick={() => {

                                    let cs = 'dark';

                                    switch (props.colorScheme) {
                                        case 'dark':
                                            cs = 'light';
                                            break;
                                    }

                                    props.setColorScheme(cs);
                                }}>
                                    <i className={'pi ' + (props.colorScheme === 'dark' ? 'pi-sun' : 'pi-moon') + ' pi-fw'}></i>
                                    <span>{props.colorScheme === 'dark' ? t('lightmode') : t('darkmode')}</span>
                                </button>
                            </li>
                            <li className="layout-inline-menu-action-item">
                                <hr className={'-m-1 mt-1 mb-1'}></hr>
                            </li>
                            <li className="layout-inline-menu-action-item -mb-1" style={{cursor: 'default'}}>
                                <button className="p-link" style={{pointerEvents: 'none'}}>
                                    <i className="pi pi-question-circle pi-fw"></i>
                                    <span>{'v' + packageJson.version + ' (' + buildDatetime + ')'}</span>
                                </button>
                            </li>
                        </ul>
                    </CSSTransition>
                </div>
            )}
        </>
    );
};

export default UserMenus;
