import {TriStateCheckbox} from 'primereact/tristatecheckbox';
import React from 'react';

export function filterTriStateCheckBox(options:any, text1:string, text2:string, text3:string){
    const label = options.value === null ? text1 : (options.value ? text2 : text3);
    return (
        <div className="field-checkbox m-0">
            <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)}/>
            <label>{label}</label>
        </div>
    );
}