import React, {useEffect, useState} from 'react';
import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';
import Input from '../global/Input';

type Props = {
    type?: string, // ['role', 'templates']
    label: string,
    projectId: string,
    value?: any,
    setValue?: (e: any) => void,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    setTemplateRoles?: (e: any) => void,
    showClear?: boolean
}
const RolesSelectList = (props: Props): JSX.Element => {

    // Initialisiere mit undefined, damit das Skeletonloading funktioniert
    const [list, setList] = useState();

    useEffect(() => {
        if ( props.type && props.type === 'templates') {
            getTemplateRoles();
        } else {
            getRoles();
        }
    }, []);

    const getRoles = () => {
        keycloakfetch.get(settings.apiUrl + '/getRoles?project_id=' + props.projectId).then(result => {
            const tmp: any = [];
            result.forEach((key: any) => {
                tmp.push({
                    value: key.id,
                    label: key.name,
                });
            });
            setList(tmp);
        });
    };

    const getTemplateRoles = () => {
        keycloakfetch.get(settings.apiUrl + '/getTemplateRoles?project_id=' + props.projectId).then(result => {
            if (props.setTemplateRoles) props.setTemplateRoles(result);
            const tmp: any = [];
            result.forEach((key: any) => {
                tmp.push({
                    value: key.id,
                    label: key.name,
                });
            });
            setList(tmp);
        });
    };

    return (
        <Input
            name={'role_id'}
            edit={true}
            label={props.label}
            value={props.value}
            showClear={props.showClear}
            dropdownOptions={list}
            type='dropdown'
            onChange={(e: any) => {
                if (props.setValue) props.setValue(e.value);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};

export default RolesSelectList;