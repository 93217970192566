/*
 * IntegerSelectList.tsx
 * Author: lnappenfeld
 * Date: 18.08.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState, useEffect} from 'react';
import Input from '../global/Input';

type Props = {
    min: number,
    max: number,
    label: string,
    value?: number,
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    name?: string,
    disabled?: boolean,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
}

const IntegerSelectList = (props: Props): JSX.Element => {

    const [options, setOptions] = useState<any[]>([]);

    // set value in parent component
    // useEffect(() => {
    //     const option = options.filter((x: any) => {
    //         return x.value === props.value;
    //     })[0];
    //     if (props.setValue) props.setValue(option);
    //     if (props.onChange) props.onChange(option);
    // }, []);

    useEffect(() => {
        const tmpOptions = [];
        for (let i = props.min; i <= props.max; i++) {
            tmpOptions.push({value: i.toString(), label: i.toString()});
        }

        setOptions(tmpOptions);
    }, []);

    return (
        <Input
            name={props.name ? props.name : 'integer_value'}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            edit={true}
            label={props.label}
            value={props.value}
            dropdownOptions={options}
            type={'dropdown'}
            onChange={(e: any) => {
                const option = options.filter((x: any) => {
                    return x.value === e.value;
                })[0];
                if (props.setValue) props.setValue(option);
                if (props.onChange) props.onChange(option);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );

};

export default IntegerSelectList;

