/*
 * OperatingStateConditionOperatorSelectList.tsx
 * Author: lnappenfeld
 * Date: 20.06.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import Input from '../global/Input';
import {operatingStateConditionOperatorOptions} from '../functions/ConditionMonitoring';

type Props = {
    label: string,
    value?: number | null,
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    name: string,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    disabled?: boolean,
    showClear?: boolean,
}

export const OperatingStateConditionOperatorSelectList = (props: Props): JSX.Element => {

    const [options, setOptions] = useState(operatingStateConditionOperatorOptions);

    useEffect(() => {
        if (props.name === 'op2') {
            // Entferne das letzte Element aus der Liste ({value: 3, label: '='}).
            // Das Gleichheitszeichen soll man nur beim ersten Operator (op1) auswählen können.
            const tmp = [...operatingStateConditionOperatorOptions];
            tmp.pop();
            setOptions(tmp);
        } else {
            setOptions(operatingStateConditionOperatorOptions);
        }
    }, []);

    return (
        <Input
            name={props.name}
            edit={true}
            showClear={typeof props.showClear === 'boolean' ? props.showClear : false}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            label={props.label}
            value={props.value}
            dropdownOptions={options} type={'dropdown'}
            onChange={(e: any) => {
                if (props.setValue) props.setValue(e.value);
                if (props.onChange) props.onChange(e);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};
