/*
 * ControlDeviceOverview.tsx
 * Author: lnappenfeld
 * Date: 10.03.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    ControlDevice, getControlDevices, getControlDeviceTypes,
} from '../functions/Metrology';
import {checkPermission, getGroupedItems} from '../../functions/functionLibrary';
import {filterTriStateCheckBox} from '../global/filter';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {permissions} from '../../config/permissions';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {hideWaitAnimation, showWaitAnimation} from '../global/CustomWaitAnimation';
import {ControlDeviceControlForm} from './ControlDeviceControlForm';

type Props = {
    projectId: string,
    reload: boolean,
    setReload: (reload: boolean) => void
    setSelectedControlDevices: (data: ControlDevice[]) => void
    selectedControlDevices: ControlDevice[],
    setEditControlDevice: (data: boolean) => void,
}

export const ControlDeviceOverview = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [controlDevices, setControlDevices] = useState<any[]>([]);

    // // Lokale States für die Untertabelle einer expandedRow, muss so gelöst werden, weil die props an das
    // // 'expandedElement' nur einmal initial übergeben werden können, aber keine Aktualisierten props
    // const [selectedControlDevices, setSelectedControlDevices] = useState<ControlDevice[]>([]);

    // arrays for filter dropdowns
    const [controlDeviceTypes, setControlDeviceTypes] = useState<any[]>([]);
    const [locations, setLocations] = useState<any[]>([]);
    const [projectGroups, setProjectGroups] = useState<any[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [expandId, setExpandId] = useState<string>('');

    const _setControlDeviceTypes = () => {
        getControlDeviceTypes(props.projectId).then(result => {
            const items: any = [];
            result.forEach((key: any) => {
                items.push({
                    value: key.id,
                    name: key.name,
                });
            });
            setControlDeviceTypes(items);
        });
    };

    const _setControlDevices = () => {
        showWaitAnimation();
        getControlDevices(props.projectId).then(result => {
            for (let i = 0; i < result.length; i++) {
                if (result[i].is_active) {
                    const controlDevice = {...result[i]};
                    delete controlDevice.expandableElement;
                    result[i]['expandableElement'] = (
                        <ControlDeviceControlForm
                            // key={'cos'+i}
                            projectId={props.projectId}
                            controlDevices={[controlDevice]}
                        />
                    );
                }
            }
            if (result.error === undefined) {
                setControlDevices(result);
                setDataLoaded(true);
                setLocations(getGroupedItems(result, 'location_id', 'location_name'));
                setProjectGroups(getGroupedItems(result, 'project_group_id', 'project_group_name'));
            }
            hideWaitAnimation();
        });
    };

    useEffect(() => {
        _setControlDeviceTypes();
        _setControlDevices();

        const hash: string[] = window.location.hash.split('_');

        if (hash.length === 3) {
            setExpandId(hash[2]);
        }
        return () => props.setSelectedControlDevices([]);
    }, []);

    useEffect(() => {
        // Lade neu, z.B. wenn Elemente gelöscht wurden
        if (props.reload) {
            _setControlDevices();
            if (props.setReload) props.setReload(false);
        }
    }, [props.reload]);


    // useEffect(() => {
    //     if (props.selectedControlDevices) setSelectedControlDevices(props.selectedControlDevices);
    // }, [props.selectedControlDevices]);

    const filterCheckboxIsEnabled = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), t('enabled'), t('disabled'));
    };

    const columns: ColumnObject[] = [
        {
            field: 'is_active', header: t('active'), style: {maxWidth: '100px'},
            filter: true, filterElement: filterCheckboxIsEnabled,
        }, {
            field: 'control_device_id', header: t('metrologyManagement:attributes.controlDeviceId'),
            filter: false, display: false
        },{
            field: 'control_device_name', header: t('name'),
            filter: true, filterField: 'control_device_name'
        }, {
            field: 'control_device_type_id', header: t('typeId'), style: {maxWidth: '100px'},
            filter: false, display: false
        },{
            field: 'control_device_type_name', header: t('type'),
            filter: true, filterField: 'control_device_type_id', filterDropdownValues: controlDeviceTypes
        }, {
            field: 'project_group_id', header: t('projectManagement:attributes.projectGroupId'),
            filter: false, display: false
        },{
            field: 'project_group_name', header: t('projectManagement:attributes.projectGroup'),
            filter: true, filterField: 'project_group_id', filterDropdownValues: projectGroups
        }, {
            field: 'location_id', header: t('locations:attributes.locationId'),
            filter: false, display: false
        },{
            field: 'location_name', header: t('location'),
            filter: true, filterField: 'location_id', filterDropdownValues: locations
        }, {
            field: 'description', header: t('description'),
            filter: true, filterField: 'description', display: false
        }, {
            field: 'ip', header: t('ip'),
            filter: true, filterField: 'ip'
        }, {
            field: 'last_control_action_at_utc', header: t('changedAtUtc'),
            filter: false
        }];

    const filters = {
        'is_active': {
            operator: FilterOperator.AND,
            constraints: [{value: true, matchMode: FilterMatchMode.EQUALS}]
        },
        'control_device_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'control_device_type_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'project_group_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'location_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'description': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'ip': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        }
    };

    return (
        <>
            <CustomDataTable
                id="controlDevices"
                selectionMode={'multiple'}
                expandable={true}
                onRowCollapse={() => {
                    hideWaitAnimation();
                }}
                columns={columns}
                editable={checkPermission(permissions.controlDevices)}
                sortable={true}
                displayColumnFilter={true}
                value={controlDevices}
                paginator={controlDevices.length > 100}
                selection={props.selectedControlDevices}
                onSelectionChange={(e: any) => {
                    if (props.setSelectedControlDevices) props.setSelectedControlDevices(e.value);
                }}

                onEditClick={(data: ControlDevice) => {
                    if (props.setSelectedControlDevices) props.setSelectedControlDevices([data]);
                    if (props.setEditControlDevice) props.setEditControlDevice(true);
                }}
                ableToDelete={checkPermission(permissions.controlDevices)}
                ableToUpdate={checkPermission(permissions.controlDevices)}
                filters={filters}
                dataloaded={dataLoaded}
                expandedRows={controlDevices.filter((item) => {
                    return item.control_device_id === expandId;
                })}
                scrollToFirstExpaned={expandId !== ''}
                showExportCSV={true}
                showClearFiltersButton={true}
            />
        </>
    );
};
