/*
 * OperatingStateConditionForm.tsx
 * Author: lnappenfeld
 * Date: 16.06.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */


import React from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {
    createOperatingStateCondition,
    OperatingState,
    OperatingStateCondition,
    updateOperatingStateCondition
} from '../functions/ConditionMonitoring';
import { Nullable } from '../functions/Global';
import {OperatingStateSelectList} from './simon2/OperatingStateSelectList';
import {BooleanSelectList} from '../global/BooleanSelectList';
import Input from '../global/Input';
import {OperatingStateConditionOperatorSelectList} from './OperatingStateConditionOperatorSelectList';
import ChannelSelectList from './ChannelSelectList';
import { v4 as uuidv4 } from 'uuid';

export enum OSCFormType {
    Default = 1, // Erstelle oder ändere die Betriebszustandsbedingung eines existierenden Betriebszustands
    NewOperatingState = 2 // Erstelle eine Betriebszustandsbedingung zu einem noch nicht exisitieren Betriebszustands
}

type Props = {
    type: OSCFormType,
    projectId: string,
    operatingStateCondition: Nullable<OperatingStateCondition>,
    onFinished: (success: boolean) => void
    // Damit die OperatingStateSelectList vorausgefüllt wird
    selectedOperatingState?: Nullable<OperatingState>
    // For Type 'NewOperatingState'
    addOperatingStateCondition?: (osc: OperatingStateCondition) => void
    updateOperatingStateCondition?: (osc: OperatingStateCondition) => void
}

export const OperatingStateConditionForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const operatingStateConditionId = props.operatingStateCondition ? props.operatingStateCondition.operating_state_condition_id : null;

    const defaultValues = {
        'operating_state_id': props.selectedOperatingState
            ? props.selectedOperatingState.operating_state_id : props.operatingStateCondition
                ? props.operatingStateCondition.operating_state_id : null,
        'channel_id': props.operatingStateCondition ? props.operatingStateCondition.channel_id : null,
        'op1': props.operatingStateCondition ? props.operatingStateCondition.op1 : null,
        'value1': props.operatingStateCondition ? props.operatingStateCondition.value1 : null,
        'op2': props.operatingStateCondition ? props.operatingStateCondition.op2 : null,
        'value2': props.operatingStateCondition ? props.operatingStateCondition.value2 : null,
        'op3': props.operatingStateCondition ? props.operatingStateCondition.op3 : 0,
        'condition_type': 1 // Bisher wird nur noch Bedingungstyp 1: 'Werte innerhalb der Grenzen' unterstützt
    };

    const {control, formState: {errors}, handleSubmit, getValues, setValue, watch} = useForm({defaultValues});

    watch('op1');
    watch('op2');
    watch('value1');

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    const saveData = (operatingStateCondition: any) => {
        const projectId = {'project_id': props.projectId};
        operatingStateCondition = {...projectId, ...operatingStateCondition};

        if (props.type === OSCFormType.NewOperatingState) {
            // Macht erstmal nur ein Änderung in der temporären Tabelle im Dialog OperatingStateForm.tsx
            if (operatingStateConditionId !== null) {
                // Aktualisiere die in der Tabelle OperatingStateForm > OperatingStateConditionOverview geänderten Betriebszustandsbedingung
                const _operatingStateConditionId = {'operating_state_condition_id': operatingStateConditionId};
                operatingStateCondition = {..._operatingStateConditionId, ...operatingStateCondition};
                if (props.updateOperatingStateCondition) {
                    props.updateOperatingStateCondition(operatingStateCondition);
                }
            } else {
                // Füge die neue Betriebszustandsbedingung der Tabelle in OperatingStateForm > OperatingStateConditionOverview hinzu
                const generatedUUID = uuidv4();
                operatingStateCondition = {...{'operating_state_condition_id': generatedUUID}, ...operatingStateCondition};
                if (props.addOperatingStateCondition) {
                    props.addOperatingStateCondition(operatingStateCondition);
                }
            }
            props.onFinished(true);
        } else {
            // Macht einen normalen CREATE oder UPDATE in operating_state_condition
            if (operatingStateConditionId !== null) {
                const _operatingStateConditionId = {'operating_state_condition_id': operatingStateConditionId};
                operatingStateCondition = {..._operatingStateConditionId, ...operatingStateCondition};

                updateOperatingStateCondition(operatingStateCondition).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.operatingStateConditionUpdated'));
                        props.onFinished(true);
                    }
                });
            } else {
                createOperatingStateCondition(operatingStateCondition).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.operatingStateConditionCreated'));
                        props.onFinished(true);
                    }
                });
            }
        }
    };

    return (
        <form id='formOperatingStateCondition' onSubmit={handleSubmit(saveData)}>
            {props.type !== OSCFormType.NewOperatingState &&
            <div className={'card'}>
                <div className={'grid'}>
                    <div className={'col-12'}>
                        <OperatingStateSelectList
                            projectId={props.projectId}
                            disabled={!!operatingStateConditionId || !!props.selectedOperatingState}
                            label={t('metrologyManagement:attributes.operatingState')}
                            name={'operating_state_id'}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('metrologyManagement:attributes.operatingState') + ' ' + t('input:required')}}
                        />
                    </div>
                </div>
            </div>}
            <div className={'card'}>
                <div className={'grid'}>
                    <div className={'col-2'}>
                        <Input
                            edit={true} name={''} label={t('Bedingungstyp')} disabled={true}
                            value={'Innerhalb der Grenzen'}
                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: false}}
                        />
                    </div>
                    <div className={'col-1'}>
                        <BooleanSelectList
                            label={t('metrologyManagement:attributes.op3')}
                            name={'op3'}
                            onChange={e => setValue('op3', e.value)}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('metrologyManagement:attributes.op3') + ' ' + t('input:required')}}
                        />
                    </div>
                    <div className={'col-2'}>
                        <Input
                            edit={true} label={t('metrologyManagement:attributes.value1')} name={'value1'}
                            type={'number'} maxFractionDigits={3}
                            value={getValues('value1')}
                            onChange={(e: any) => {
                                setValue('value1', e.value);
                            }}
                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('metrologyManagement:attributes.value1') + ' ' + t('input:required')}}
                        />
                    </div>
                    <div className={'col-1'}>
                        <OperatingStateConditionOperatorSelectList
                            label={t('metrologyManagement:attributes.op1')}
                            name={'op1'} showClear={true}
                            onChange={(e) => {
                                setValue('op1', e.value);
                                if (e.value === null) {
                                    setValue('value1', null);
                                    setValue('op2', null);
                                    setValue('value2', null);
                                }
                            }}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('metrologyManagement:attributes.op1') + ' ' + t('input:required')}}
                        />
                    </div>
                    <div className={'col-2'}>
                        <ChannelSelectList
                            type={'operating_state_condition'}
                            projectId={props.projectId}
                            label={t('channel')}
                            name={'channel_id'}
                            onChange={e => setValue('channel_id', e.value)}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('channel') + ' ' + t('input:required')}}
                        />
                    </div>
                    <div className={'col-1'}>
                        <OperatingStateConditionOperatorSelectList
                            label={t('metrologyManagement:attributes.op2')}
                            name={'op2'} showClear={true}
                            disabled={getValues('op1') === null || getValues('value1') === null}
                            onChange={(e) => {
                                setValue('op2', e.value);
                                if (e.value === null) {
                                    setValue('value2', null);
                                }
                            }}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: false}}
                        />
                    </div>
                    <div className={'col-2'}>
                        <Input
                            edit={true} label={t('metrologyManagement:attributes.value2')} name={'value2'}
                            type={'number'} maxFractionDigits={3} disabled={getValues('op2') === null}
                            value={getValues('value2')}
                            onChange={(e: any) => {
                                setValue('value2', e.value);
                            }}
                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: getValues('op2') !== null
                                ? t('metrologyManagement:attributes.value2') + ' ' + t('input:required')
                                : false
                            }}
                        />
                    </div>
                </div>
            </div>
        </form>
    );
};