/*
 * WeatherDataDialog.tsx
 * Author: lnappenfeld
 * Date: 26.10.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CustomDialog from '../global/CustomDialog';
import {WeatherData} from './WeatherData';
import {Nullable} from '../functions/Global';

type Props = {
    projectId: Nullable<string>,
    locationId: Nullable<string>,
    showDialog: boolean,
    setShowDialog: (val: boolean) => void,
}

export const WeatherDataDialog = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    return (
        <CustomDialog
            onHide={() => {
                props.setShowDialog(false);
            }}
            onCancel={() => {
                props.setShowDialog(false);
            }}
            visible={props.showDialog}
            maximized={false}
            showFooter={false}

            header={t('measurementData:global.WeatherData')}
        >
            <WeatherData projectId={props.projectId} locationId={props.locationId}/>
        </CustomDialog>
    );
};