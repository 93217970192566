/*
 * CharacteristicMonitoringTaskForm.tsx
 * Author: lnappenfeld
 * Date: 21.02.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import Input from '../../global/Input';
import {useForm} from 'react-hook-form';
import {Nullable} from '../../functions/Global';
import {showMessageOnError, showMessageOnSuccess} from '../../global/CustomToast';
import {
    CharacteristicMonitoringTask,
    createCharacteristicMonitoringTask,
    getCharacteristicMonitoringTaskTypes,
    getNotificationsForCharacteristicMonitoringTask,
    getNotificationTaskCharacteristicMonitoringTaskMap,
    Notification,
    updateCharacteristicMonitoringTask
} from '../../functions/Notification';
import ChannelSelectList from '../../metrology/ChannelSelectList';
import CharacteristicMonitoringTaskTypeSelectList from './CharacteristicMonitoringTaskTypeSelectList';
import MonitoringAppSelectList from './MonitoringAppSelectList';
import {
    lessValidation,
    greaterValidation,
    greaterArrayValidation,
    lessArrayValidation
} from '../../../functions/functionLibrary';
import {ElementTreeSelectList} from '../../metrology/ElementTreeSelectList';
import {Channel, getChannel, getElementsAsTree} from '../../functions/Metrology';
import {getMonitoringApps} from '../../functions/ConditionMonitoring';
import {hideWaitAnimation, showWaitAnimation} from '../../global/CustomWaitAnimation';
import {ChannelSelectTableDialog, ChannelSelectTableDialogType} from '../ChannelSelectTableDialog';


type Props = {
    projectId: any,
    characteristicMonitoringTask: Nullable<CharacteristicMonitoringTask>,
    onFinished: (success: boolean) => void
}

const CharacteristicMonitoringTaskForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [elements, setElements] = useState<any>([]);

    // Für den ChannelSelectTableDialog
    const [selectedChannel, setSelectedChannel] = useState<Nullable<Channel>>(null);
    const [showChannelSelectTableDialog, setShowSelectChannelTableDialog] = useState<boolean>(false);

    // Für das Mapping/Filtern zwischen Channel und CharacteristicMonitoringTaskTypes
    const [characteristicMonitoringTaskTypes, setCharacteristicMonitoringTaskTypes] = useState<any[]>([]);
    const [selectableCharacteristicMonitoringTaskTypes, setSelectableCharacteristicMonitoringTaskTypes] = useState<any[]>([]);

    // Für das Mapping/Filtern zwischen Element und MonitoringApps
    const [monitoringApps, setMonitoringApps] = useState<any[]>([]);
    const [selectableMonitoringApps, setSelectableMonitoringApps] = useState<any[]>([]);

    // Für die Darstellung, in welchen Benachrichtigungsaufgaben diese Kennwertüberwachungsaufgabe berücksichtigt wird
    const [notifications, setNotifications] = useState<Nullable<Notification[]>>(null);
    const [selectedNotificationIds, setSelectedNotificationIds] = useState<Nullable<string[]>>(null);


    const characteristicMonitoringTaskId = props.characteristicMonitoringTask ? props.characteristicMonitoringTask.id : null;

    const defaultValues = {
        'characteristic_monitoring_task_name': props.characteristicMonitoringTask ? props.characteristicMonitoringTask.characteristic_monitoring_task_name : null,
        'characteristic_monitoring_task_type_id': props.characteristicMonitoringTask ? props.characteristicMonitoringTask.characteristic_monitoring_task_type_id : null,
        'monitoring_app_id': props.characteristicMonitoringTask ? props.characteristicMonitoringTask.monitoring_app_id : null,
        'element_id': props.characteristicMonitoringTask ? props.characteristicMonitoringTask.element_id : null,
        'channel_id': props.characteristicMonitoringTask ? props.characteristicMonitoringTask.channel_id : null,
        'description': props.characteristicMonitoringTask ? props.characteristicMonitoringTask.description : null,
        'is_active': props.characteristicMonitoringTask ? props.characteristicMonitoringTask.is_active : true,
        'good_from': props.characteristicMonitoringTask ? props.characteristicMonitoringTask.good_from : null,
        'good_to': props.characteristicMonitoringTask ? props.characteristicMonitoringTask.good_to : null,
        'pre_alarm_from': props.characteristicMonitoringTask ? props.characteristicMonitoringTask.pre_alarm_from : null,
        'pre_alarm_to': props.characteristicMonitoringTask ? props.characteristicMonitoringTask.pre_alarm_to : null,
        'main_alarm_from': props.characteristicMonitoringTask ? props.characteristicMonitoringTask.main_alarm_from : null,
        'main_alarm_to': props.characteristicMonitoringTask ? props.characteristicMonitoringTask.main_alarm_to : null,
        'flag_auto_reset_after_minutes': props.characteristicMonitoringTask ? props.characteristicMonitoringTask.flag_auto_reset_after_minutes : 0,
    };

    const {control, formState: {errors}, handleSubmit, reset, setValue, getValues, watch, trigger} = useForm({defaultValues});

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };
    watch('is_active');
    watch('pre_alarm_from');
    watch('pre_alarm_to');
    watch('main_alarm_from');
    watch('main_alarm_to');
    watch('element_id');

    useEffect(() => {
        (async () => {
            showWaitAnimation();
            await _setSelectedChannel();
            const _elements: any = await _setElements();
            const _cmtTypes: any  = await _setCharacteristicMonitoringTaskTypes();
            const _monitoringApps: any  = await _setMonitoringApps();

            if (props.characteristicMonitoringTask) {
                updateCharacteristicMonitoringTaskTypeSelectList(props.characteristicMonitoringTask.channel_id, _cmtTypes);
                updateMonitoringAppSelectList(props.characteristicMonitoringTask.element_id, _elements, _monitoringApps);
                await _setNotificationTaskCharacteristicMonitoringTaskMap(props.characteristicMonitoringTask.element_id);
            } else {
                const _selectableMonitoringApps = [];
                for (const monitoringApp of _monitoringApps) {
                    if (monitoringApp.monitoring_app_type_id === 1) { // Schwingungsdiagnose
                        _selectableMonitoringApps.push(monitoringApp);
                    }
                }
                setSelectableMonitoringApps(_selectableMonitoringApps);
            }
            hideWaitAnimation();
        })();
    }, []);

    const _setSelectedChannel = async () => {
        const channelId = props.characteristicMonitoringTask ? props.characteristicMonitoringTask.channel_id : null;
        if (channelId)
            getChannel(props.projectId, channelId).then((result: any) => {
                setSelectedChannel(result);
            });
    };

    const _setElements = async () => {
        return await getElementsAsTree(props.projectId, 'sensor').then(result => {
            setElements(result);
            return result;
        });
    };

    const _setCharacteristicMonitoringTaskTypes = async () => {
        return await getCharacteristicMonitoringTaskTypes(props.projectId).then(result => {
            const tmp: any = [];
            result.forEach((key: any) => {
                tmp.push({
                    value: key.id,
                    label: key.name,
                });
            });
            setCharacteristicMonitoringTaskTypes(tmp);
            setSelectableCharacteristicMonitoringTaskTypes(tmp);
            return tmp;
        });
    };

    const _setMonitoringApps = async () => {
        return await getMonitoringApps(props.projectId).then(result => {
            const tmp: any = [];
            result.forEach((obj: any) => {
                const {...props} = obj;
                tmp.push({
                    value: obj.monitoring_app_id,
                    label: obj.monitoring_app_type_name + ' (' + obj.element_name + ')',
                    ...props
                });
            });
            setMonitoringApps(tmp);
            setSelectableMonitoringApps(tmp);
            return tmp;
        });
    };

    const areArraysIdentical = (arr1: Nullable<any[]>, arr2: Nullable<any[]>) => {
        if (arr1 === null || arr2 === null) {
            return true;
        }
        if (arr1.length !== arr2.length) {
            return false;
        }
        const idSet1 = new Set(arr1.map(obj => obj.id));
        return arr2.every(obj => idSet1.has(obj.id));
    };

    const _setNotificationTaskCharacteristicMonitoringTaskMap = async (elementId: string) => {
        const previousNotifications = notifications;
        await getNotificationsForCharacteristicMonitoringTask(props.projectId, elementId).then(_notifications => {
            const notifications: any = [];
            _notifications.forEach((key: Notification) => {
                notifications.push({
                    // key: key.id,
                    value: key.id,
                    label: key.name + ' (' + key.notification_type_name + ')',
                });
            });
            setNotifications(notifications);

            if (characteristicMonitoringTaskId) {
                // Fall: Editiere eine KennwertÜberwachungsaufgabe
                // Lese dazu die Benachrichtigungsaufgaben aus notification_task_monitoring_task aus und selektiere
                // diese in der MultiSelectList
                getNotificationTaskCharacteristicMonitoringTaskMap(props.projectId, null, characteristicMonitoringTaskId).then(ntmtMap => {
                    const _selectedNotificationIds: any[] = [];
                    notifications.forEach((notification: any) => {
                        const foundObject = ntmtMap.find((ntmt: any) => notification.value === ntmt.notification_task_id);
                        if (foundObject) {
                            _selectedNotificationIds.push(notification.value);
                        }
                    });

                    // Wenn ein Knoten ausgewählt wird, an dem ganz anderen Benachrichtigungsregeln hängen, werden
                    // wieder wie unten, standardmäßig alle möglichen Benachrichtigungsregeln selektiert
                    const areIdentical = areArraysIdentical(previousNotifications, notifications);
                    if (!areIdentical) {
                        notifications.forEach((notification: any) => {
                            _selectedNotificationIds.push(notification.value);
                        });
                    }
                    setSelectedNotificationIds(_selectedNotificationIds);

                });
            } else {
                // Fall: Erstelle eine neue KennwertÜberwachungsaufgabe
                // Selektiere automatisch alle möglichen Benachrichtigungen, damit die KennwertÜberwachungsaufgabe
                // standardmäßig von den Benachrichtigungsregeln berücksichtigt werden
                const _selectedNotificationIds: any[] = [];
                notifications.forEach((notification: any) => {
                    _selectedNotificationIds.push(notification.value);
                });
                setSelectedNotificationIds(_selectedNotificationIds);
            }
        });
    };

    const updateCharacteristicMonitoringTaskTypeSelectList = (channelId: string, cmtTypes = []) => {
        // 0	"Überwacht Betriebsdaten"
        // 1	"Überwacht den RMSa-Wert von Zeitsignaldaten"
        // 2	"Überwacht den RMSa-Wert von Spektraldaten"
        // 3	"Überwacht den RMSa-Wert von Spektraldaten aus Tagesmittelwertspektren"
        // 4	"Überwacht den RMSa-Wert von Spektraldaten aus Laufzeitgemittelten Spektren"
        // 5	"Überwacht den RMSv-Wert von Zeitsignaldaten"
        // 6	"Überwacht den RMSv-Wert von Spektraldaten"
        // 7	"Überwacht den RMSv-Wert von Spektraldaten aus Tagesmittelwertspektren"
        // 8	"Überwacht den RMSv-Wert von Spektraldaten aus Laufzeitgemittelten Spektren"
        // 9	"Überwacht den RMSd-Wert von Zeitsignaldaten"
        // 10	"Überwacht den RMSd-Wert von Spektraldaten"
        // 11	"Überwacht den RMSd-Wert von Spektraldaten aus Tagesmittelwertspektren"
        // 12	"Überwacht den RMSd-Wert von Spektraldaten aus Laufzeitgemittelten Spektren"

        // Wenn characteristicMonitoringTaskTypes übergeben wurde, nehme die, ansonsten verwende die useState-Variable
        let _cmtTypes = characteristicMonitoringTaskTypes;
        if (cmtTypes.length > 0) _cmtTypes = cmtTypes;

        // Filter die CharacteristicMonitoringTaskTypeSelectList abhängig vom ChannelTyp
        // dataType = 1: Gibt nur die channel_type_id des Channels zurück
        getChannel(props.projectId, channelId, 1).then(channel => {
            const selectableCMTTypes = [];
            switch (channel.channel_type_id) {
                case 202: // ZEIT
                case 205: // HKZT
                {
                    const selectableCMTTypeIds = [1,5,9];
                    for (const cmtType of _cmtTypes) {
                        if (selectableCMTTypeIds.includes(cmtType.value)) {
                            selectableCMTTypes.push(cmtType);
                        }
                    }
                    break;
                }
                case 201: // MEDA
                case 204: // HKMD
                case 206: // MDFF
                case 207: // MDOA
                case 208: // HKFF
                case 209: // HKOA
                {
                    const selectableCMTTypeIds = [2,3,4,6,7,8,10,11,12];
                    for (const cmtType of _cmtTypes) {
                        if (selectableCMTTypeIds.includes(cmtType.value)) {
                            selectableCMTTypes.push(cmtType);
                        }
                    }
                    break;
                }
                default: // BEDA, HKBD, und alle anderen werden als Betriebsdaten angesehen
                {
                    const selectableCMTTypeIds = [0];
                    for (const cmtType of _cmtTypes) {
                        if (selectableCMTTypeIds.includes(cmtType.value)) {
                            selectableCMTTypes.push(cmtType);
                        }
                    }
                    break;
                }
            }

            setSelectableCharacteristicMonitoringTaskTypes(selectableCMTTypes);
        });
    };

    // Gibt alle Eltern-Elemente und das Element mit der targetId zurück
    const findParentElements = (tree: any, targetId: string, parents: any = []) => {
        for (const node of tree) {
            if (node.element_id === targetId) {
                // Ziel-Knoten gefunden, füge ihn zu den Parent-Elementen hinzu und beende die Rekursion
                return [...parents, node];
            } else if (node.children.length > 0) {
                // Rekursiv nach dem Ziel-Knoten in den Kind-Knoten suchen und die Parent-Elemente aktualisieren
                const found: any = findParentElements(node.children, targetId, [...parents, node]);
                if (found) return found;
            }
        }

        // Wenn der Ziel-Knoten nicht gefunden wurde, gibt es keinen passenden Pfad im Baum
        return null;
    };

    const updateMonitoringAppSelectList = (elementId: string, _elements = [], _monitoringApps = []) => {
        // Wenn _elements übergeben wurde, nehme die, ansonsten verwende die useState-Variable
        let __elements = elements;
        if (_elements.length > 0) __elements = _elements;

        // Finde alle Parentelemente des übgebenen Elements
        const parentIds: any = [];
        const parentElements = findParentElements(__elements, elementId);
        for (const element of parentElements) {
            parentIds.push(element.element_id);
        }

        console.log('parentElements: ', parentElements);
        console.log('parentIds: ', parentIds);

        // Wenn monitoringApps übergeben wurden, nehmen die, ansonsten verwende die useState-Variable
        let __monitoringApps = monitoringApps;
        if (_monitoringApps.length > 0) __monitoringApps = _monitoringApps;

        // Füge nur die MonitoringApps hinzu, die an der vom Benutzer ausgewählten Komponente selbst und den Parent-Komponenten hängen
        const _selectableMonitoringApps = [];
        if (props.characteristicMonitoringTask) {
            for (const monitoringApp of __monitoringApps) {
                if (parentIds.includes(monitoringApp.element_id)) {
                    _selectableMonitoringApps.push(monitoringApp);
                }
            }
        } else {
            // Erstelle neue Kennwertüberwachungsaufgabe: Füge nur die MonitoringApps vom Typ Schwingungsdiagnose hinzu
            for (const monitoringApp of __monitoringApps) {
                if (monitoringApp.monitoring_app_type_id === 1 && parentIds.includes(monitoringApp.element_id)) {
                    _selectableMonitoringApps.push(monitoringApp);
                }
            }
        }

        setSelectableMonitoringApps(_selectableMonitoringApps);
    };

    const onElementIdChange = async (event: any) => {
        const elementId = event.value;
        setValue('element_id', elementId);

        // Aktualisiere die MonitoringAppSelectList
        updateMonitoringAppSelectList(elementId);

        // Setze die monitoring_app zurück, damit keine Fehlkonfiguration möglich ist
        setValue('monitoring_app_id', null);

        // Muss vorher einmal geleert werden, damit die MultiSelectList richtig angezeigt wird...
        setSelectedNotificationIds([]);
        // Aktualisiere die options und die selektierten Daten der MultiSelectList
        await _setNotificationTaskCharacteristicMonitoringTaskMap(elementId);
    };

    const saveCharacteristicMonitoringTask = (data: any) => {
        // Rename characteristic_monitoring_task_name to name
        data['name'] = data['characteristic_monitoring_task_name'];
        delete data['characteristic_monitoring_task_name'];

        // Add selected notification_ids
        data['notification_ids'] = selectedNotificationIds;

        if (characteristicMonitoringTaskId) {
            const dataAddId = {'characteristic_monitoring_task_id': characteristicMonitoringTaskId};
            data = {...dataAddId, ...data};
            updateCharacteristicMonitoringTask(props.projectId, data).then(result => {
                if (!result.error) {
                    showMessageOnSuccess(t('success'), t('alarmManagement:toasts.characteristicMonitoringTaskUpdated'));
                    props.onFinished(true);
                } else {
                    showMessageOnError(t('error'), result.error);
                }
            });
        } else {
            createCharacteristicMonitoringTask(props.projectId, data).then(result => {
                if (!result.error) {
                    showMessageOnSuccess(t('success'), t('alarmManagement:toasts.characteristicMonitoringTaskCreated'));
                    props.onFinished(true);
                } else {
                    showMessageOnError(t('error'), result.error);
                }
            });
        }

    };

    return (<>
        <form id='formCharacteristicMonitoringTask' onSubmit={handleSubmit(saveCharacteristicMonitoringTask)}>
            <div className='grid card'>
                <div className='col-12 lg:col-6'>
                    <Input
                        edit={true} label={t('name')} name={'characteristic_monitoring_task_name'}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('name') + ' ' + t('input:required')}}
                    />
                    <div className={'grid'}>
                        <div className={'col-9'}>
                            <Input
                                edit={true} label={t('description')} name={'description'} type={'textarea'}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: false}}
                            />
                        </div>
                        <div className={'col-3'}>
                            <Input
                                edit={true} label={t('active')} name={'is_active'} type={'checkbox'}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: false}}
                            />
                        </div>
                    </div>
                    <ElementTreeSelectList
                        type={'sensor'}
                        projectId={props.projectId}
                        list={elements}
                        disabled={!!props.characteristicMonitoringTask}
                        value={getValues('element_id')}
                        label={t('metrologyManagement:attributes.sensorElementName')}
                        name={'element_id'}
                        onChange={onElementIdChange}
                        validationControl={control}
                        validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('metrologyManagement:attributes.sensorElementName') + ' ' + t('input:required')}}
                    />
                    <Input
                        edit={true} label={t('channel')} name={'channel_id'} type={'button'}
                        value={selectedChannel ? selectedChannel.channel_name + ' [' + selectedChannel.unit + ']' : ''}
                        onClick={() => {setShowSelectChannelTableDialog(true);}}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('channel') + ' ' + t('input:required')}}
                    />
                    {characteristicMonitoringTaskTypes &&
                    <CharacteristicMonitoringTaskTypeSelectList
                        projectId={props.projectId}
                        disabled={getValues('channel_id') === null}
                        list={selectableCharacteristicMonitoringTaskTypes}
                        label={t('alarmManagement:attributes.characteristicMonitoringTaskType')}
                        name={'characteristic_monitoring_task_type_id'}
                        onChange={e => setValue('characteristic_monitoring_task_type_id', e.value)}
                        validationControl={control}
                        validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('alarmManagement:attributes.characteristicMonitoringTaskType') + ' ' + t('input:required')}}
                    />}
                    <MonitoringAppSelectList
                        projectId={props.projectId}
                        label={t('alarmManagement:attributes.monitoring_app')}
                        name={'monitoring_app_id'}
                        tooltip={t('alarmManagement:tooltips.kuwaMonitoringAppSelectList')}
                        disabled={!!props.characteristicMonitoringTask}
                        list={selectableMonitoringApps}
                        onChange={e => setValue('monitoring_app_id', e.value)}
                        validationControl={control}
                        validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('alarmManagement:attributes.monitoring_app') + ' ' + t('input:required')}}
                    />
                </div>
                <div className='col-12 lg:col-6'>
                    <Input
                        // Hochalarm
                        edit={true} label={t('alarmManagement:attributes.main_alarm_from')}
                        tooltip={t('alarmManagement:tooltips.main_alarm_from')}
                        name={'main_alarm_from'} type={'number'} maxFractionDigits={2}
                        onChange={(e: any) => {
                            setValue('main_alarm_from', e.value);
                        }}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{
                            ...{required: false},
                            // Dieser Wert muss größer sein als alle anderen
                            ...greaterArrayValidation(t, [
                                getValues('pre_alarm_from'),    // Hochvoralarm
                                getValues('pre_alarm_to'),      // Tiefvoralarm
                                getValues('main_alarm_to')      // Tiefalarm
                            ])
                        }}
                    />
                    <Input
                        // Hochvoralarm
                        edit={true} label={t('alarmManagement:attributes.pre_alarm_from')}
                        tooltip={t('alarmManagement:tooltips.pre_alarm_from')}
                        name={'pre_alarm_from'} type={'number'} maxFractionDigits={2}
                        onChange={(e: any) => {
                            setValue('pre_alarm_from', e.value);
                        }}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{
                            ...{required: false},
                            // Dieser Wert muss kleiner als Hochalarm, aber größer als Tiefvoralarm und Tiefalarm sein
                            ...lessValidation(t, getValues('main_alarm_from')),   // Hochalarm
                            ...greaterArrayValidation(t, [
                                getValues('pre_alarm_to'),  // Tiefvoralarm
                                getValues('main_alarm_to')  // Tiefalarm
                            ])
                        }}
                    />
                    <Input
                        // Tiefvoralarm
                        edit={true} label={t('alarmManagement:attributes.pre_alarm_to')}
                        tooltip={t('alarmManagement:tooltips.pre_alarm_to')}
                        name={'pre_alarm_to'} type={'number'} maxFractionDigits={2}
                        onChange={(e: any) => {
                            setValue('pre_alarm_to', e.value);
                        }}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{
                            ...{required: false},
                            // Dieser Wert muss kleiner als Hochalarm und Tiefvoralarm, aber größer als Tiefalarm sein
                            ...lessArrayValidation(t, [
                                getValues('main_alarm_from'),   // Hochalarm
                                getValues('pre_alarm_from')     // Tiefvoralarm
                            ]),
                            ...greaterValidation(t, getValues('main_alarm_to'))     // Tiefalarm
                        }}
                    />
                    <Input
                        // Tiefalarm
                        edit={true} label={t('alarmManagement:attributes.main_alarm_to')}
                        tooltip={t('alarmManagement:tooltips.main_alarm_to')}
                        name={'main_alarm_to'} type={'number'} maxFractionDigits={2}
                        onChange={(e: any) => {
                            setValue('main_alarm_to', e.value);
                        }}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{
                            ...{required: false},
                            // Dieser Wert muss kleiner sein als alle anderen
                            ...lessArrayValidation(t, [
                                getValues('main_alarm_from'),   // Hochalarm
                                getValues('pre_alarm_from'),    // Tiefvoralarm
                                getValues('pre_alarm_to')       // Tiefalarm
                            ])
                        }}
                    />
                    <Input
                        edit={true} label={t('alarmManagement:attributes.flag_auto_reset_after_minutes')}
                        name={'flag_auto_reset_after_minutes'} type={'number'} maxFractionDigits={0}
                        onChange={(e: any) => {
                            setValue('flag_auto_reset_after_minutes', e.value);
                        }}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: false}}
                        // validationRules={{required: t('alarmManagement:attributes.flag_auto_reset_after_minutes') + ' ' + t('input:required')}}
                    />
                    { getValues('element_id') &&
                        <Input
                            edit={true}
                            name={'notification_ids'}
                            label={t('notifications')}
                            tooltip={t('alarmManagement:tooltips.characteristicMonitoringTaskNotificationTasks')}
                            value={selectedNotificationIds}
                            onChange={(event: any) => {
                                setSelectedNotificationIds(event.value);
                            }}
                            scrollHeight={'500px'}
                            dropdownOptions={notifications}
                            type={'multiselect'}
                            selectionMode={'checkbox'}
                        />
                    }
                </div>
            </div>
        </form>
        <ChannelSelectTableDialog
            type={ChannelSelectTableDialogType.Default}
            projectId={props.projectId}
            visible={showChannelSelectTableDialog}
            setVisible={setShowSelectChannelTableDialog}
            selectedChannel={selectedChannel}
            onFinish={(channel: Channel) => {
                setValue('channel_id', channel.channel_id);
                // Filter die CharacteristicMonitoringTaskTypeSelectList abhängig vom ChannelTyp
                updateCharacteristicMonitoringTaskTypeSelectList(channel.channel_id);

                setSelectedChannel(channel);
                setShowSelectChannelTableDialog(false);
                // Damit der Validationtext verschwindet, falls vorher einer da war.
                // trigger();
            }}
        />
    </>);
};

export default CharacteristicMonitoringTaskForm;

