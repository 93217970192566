/*
 * ExportMeasurementDataOverview.tsx
 * Author: lnappenfeld
 * Date: 06.03.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from 'primereact/button';
import {confirmDialog} from 'primereact/confirmdialog';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {hideWaitAnimation, showWaitAnimation} from '../global/CustomWaitAnimation';
import {exportData} from '../functions/Data';
import PlotTemplate, {PlotTemplateType} from './PlotTemplate';
import {getUserFromLocalStorage, Nullable} from '../functions/Global';
import ActionSelectList from '../userManagement/ActionSelectList';


type Props = {
    projectId: string
}

export const ExportMeasurementDataOverview = (props: Props): JSX.Element => {

    const now = new Date();
    const minusSevenDays = new Date(now);
    minusSevenDays.setDate(now.getDate() - 7);

    const {t} = useTranslation(['common']);

    const [actionId, setActionId] = useState<Nullable<string>>(null);
    const [actionsLoaded, setActionsLoaded] = useState<boolean>(false);
    const [selectedTemplate, setSelectedTemplate] = useState<any>({
        plotOptions: [],
        from: minusSevenDays,
        to: now,
        combineCharts: 0,
        minMaxFromChannel: false,
        combineValues: true
    });

    const _exportData = () => {
        const languageId = getUserFromLocalStorage().language_id;
        const from = selectedTemplate.from;
        const to = selectedTemplate.to;
        const combineChannelsType = selectedTemplate.combineCharts;

        const channelIds: string[] = [];
        for (const plotOption of selectedTemplate.plotOptions) {
            for (const channelId of plotOption.channel_ids) {
                channelIds.push(channelId);
            }
        }

        confirmDialog({
            message: t('measurementData:dialogs.exportAsCSV.message'),
            header: t('measurementData:dialogs.exportAsCSV.header'),
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: t('yes'),
            rejectLabel: t('no'),
            accept: () => {
                if (actionId) {
                    showWaitAnimation();
                    exportData(props.projectId, channelIds, combineChannelsType, from, to, languageId, actionId).then((result: any) => {
                        if (result.error) {
                            showMessageOnError(t('error'), result.error);
                        } else {
                            showMessageOnSuccess(t('success'), t('measurementData:toasts.emailSent'));
                        }
                        hideWaitAnimation();
                    });
                }
            },
        });
    };

    const onSelectedTemplateChanged = (template: any) => {
        setSelectedTemplate(template);
    };

    const onActionIdChanged = (e: any) => {
        setActionId(e.value);
        setActionsLoaded(true);
    };

    useEffect(() => {
        console.log('actionId: ', actionId)
    }, [actionId]);

    useEffect(() => {
        console.log('actionsLoaded: ', actionsLoaded)
    }, [actionsLoaded]);

    return (
        <>
            <div className="card grid grid-nogutter no-field-margin">
                <PlotTemplate
                    projectId={props.projectId}
                    loadInitial={true}
                    onSelectedTemplateChanged={onSelectedTemplateChanged}
                    type={PlotTemplateType.Exporting}
                />
            </div>
            <div className="card w-full">
                <ActionSelectList
                    projectId={props.projectId}
                    name={'action_ids'}
                    label={t('userManagement:global.action')}
                    tooltip={actionId === null && actionsLoaded ? t('measurementData:tooltips.exportDataNoAction') : ''}
                    value={actionId}
                    disabled={actionId !== null}
                    onChange={onActionIdChanged}
                    onlyCurrentUserEmail={false}
                />
                <div className="w-100 flex">
                    <Button
                        type={'button'}
                        disabled={actionId === null || selectedTemplate.plotOptions.length <= 0}
                        className={'p-button p-button-outlined p-button-rounded mt-3 mx-auto'}
                        onClick={_exportData}>
                        <><i className="pi pi-file mr-2"/>{t('measurementData:buttons.exportAsCSV')} </>
                    </Button>
                </div>
            </div>
        </>
    );
};
