import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';
import {updateAccessToken} from '../../services/KeycloakService';
import moment from 'moment';
import {getUserFromLocalStorage, Nullable} from './Global';

export type Report = {
    'id': string,
    'name': string,
    'actions': string[],
    'configuration': any,
    'changed_at_utc': string,
    'changed_by_user_id': string,
    'description': Nullable<string>,
    'email_message': Nullable<string>,
    'email_subject': string,
    'evaluation_period': string,
    'evaluation_period_daytime_from': Nullable<string>,
    'evaluation_period_daytime_to': Nullable<string>,
    'evaluation_period_text': string,
    'execution_interval': Nullable<string>,
    'save_in_nextcloud': boolean,
    'send_as_pdf': boolean,
    'generate_csv': boolean,
    'generate_xls': boolean,
    'interval': string,
    'is_enabled': boolean,
    'last_report': Nullable<string>,
    'null_measurement': string,
    'project': string,
    'project_id': string,
    'report_template_id': string,
    'report_template_name': string,
    'report_type_id': number,
    'report_type_name': string,
    'timezone': string,
    'y_axis_max': Nullable<number>,
    'y_axis_min': Nullable<number>,
    'y_axis_mode': Nullable<string>,
}

export type ReportTemplate = {
    'id': string,
    'name': string,
    'configuration': any,
    'changed_at_utc': string,
    'changed_by_user_id': string,
    'changed_by_username': string,
    'template_is_used_in_report': boolean
}

export type ReportFile = {
    'id': string,
    'file_name': string,
    'report_id': string,
    'report_template_id': string,
    'timestamp': string,
}

export async function getReportTypes()  {
    let retValue:any = [];
    await keycloakfetch.get(settings.apiUrl + '/getReportTypes').then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getReports(projectId: string, reportTemplateId: string | null)  {
    let retValue:any = [];
    let additionalParams = '';
    if (reportTemplateId !== null) additionalParams = '&report_template_id=' + reportTemplateId;
    await keycloakfetch.get(settings.apiUrl + '/getReports?project_id=' + projectId + additionalParams).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function updateReport(data:any)  {
    let retValue:any = [];
    await keycloakfetch.post(settings.apiUrl + '/updateReport', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createReport(data:any)  {
    let retValue:any = [];
    await keycloakfetch.put(settings.apiUrl + '/createReport', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

/**
 * Lösche einen oder mehrere Reports
 * @param projectId
 * @param reportIds
 */
export async function deleteReports(projectId: string, reportIds: string[])  {
    let retValue:any = [];
    await keycloakfetch.delete(settings.apiUrl + '/deleteReports', {
        'project_id': projectId,
        'report_ids': reportIds
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getReportActions(projectId: string, reportId: string)  {
    let retValue:any = [];
    await keycloakfetch.get(settings.apiUrl + '/getReportActions?project_id=' + projectId + '&report_id=' + reportId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

// ---------------------------------------------------------------------------------------------------------------------

export async function getReportTemplates(projectId:string)  {
    let retValue:any = [];
    await keycloakfetch.get(settings.apiUrl + '/getReportTemplates?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function updateReportTemplate(data:any)  {
    let retValue:any = [];
    await keycloakfetch.post(settings.apiUrl + '/updateReportTemplate', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createReportTemplate(data:any)  {
    let retValue:any = [];
    await keycloakfetch.put(settings.apiUrl + '/createReportTemplate', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

/**
 * Lösche einen oder mehrere Reportvorlagen
 * @param projectId
 * @param reportTemplateIds
 */
export async function deleteReportTemplates(projectId:string, reportTemplateIds: string[])  {
    let retValue:any = [];
    await keycloakfetch.delete(settings.apiUrl + '/deleteReportTemplates', {
        'project_id': projectId,
        'report_template_ids': reportTemplateIds
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function duplicateReportTemplate(projectId: string, reportTemplateId: string)  {
    let retValue:any = [];
    await keycloakfetch.put(settings.apiUrl + '/duplicateReportTemplate', {
        'project_id': projectId,
        'report_template_id': reportTemplateId
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

// ---------------------------------------------------------------------------------------------------------------------

export async function getReportFiles(projectId:string, reportId: string | null)  {
    let retValue:any = [];
    let additionalParams = '';
    if (reportId !== null) additionalParams = '&report_id=' + reportId;
    await keycloakfetch.get(settings.apiUrl + '/getReportFiles?project_id=' + projectId + additionalParams).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getReportFileID(projectId:string, fileId: string, fileType:string)  {
    let retValue:any = [];
    let additionalParams = '&report_file_id=' + fileId;
    additionalParams += '&file_type=' + fileType;
    await keycloakfetch.get(settings.apiUrl + '/getReportFileId?project_id=' + projectId + additionalParams).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getReportFile(reportFileId:string)  {
    let retValue:any = [];
    await keycloakfetch.get(settings.apiUrl + '/getReportFile?report_file_id=' + reportFileId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getReportFileAsBuffer(reportFileId:string)  {
    let retValue:any = [];
    await keycloakfetch.get(settings.apiUrl + '/getReportFileAsBuffer').then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

// export async function updateReportTemplate(projectId:string, template:any){
//     let retValue:any=[];
//     await keycloakfetch.post(settings.apiUrl + '/updateReportTemplate', template).then(result => {
//         if(result)
//             retValue = result;
//     });
//     return retValue;
// }

// ---------------------------------------------------------------------------------------------------------------------

export async function getEvaluationPeriods()  {
    let retValue:any = [];
    const languageId = getUserFromLocalStorage().language_id;
    const params = '?language_id=' + languageId;
    await keycloakfetch.get(settings.apiUrl + '/getEvaluationPeriods' + params).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getExecutionIntervalFrequencies()  {
    let retValue:any = [];
    const languageId = getUserFromLocalStorage().language_id;
    const params = '?language_id=' + languageId;
    await keycloakfetch.get(settings.apiUrl + '/getExecutionIntervalFrequencies' + params).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getExecutionIntervalWeekdays()  {
    let retValue:any = [];
    const languageId = getUserFromLocalStorage().language_id;
    const params = '?language_id=' + languageId;
    await keycloakfetch.get(settings.apiUrl + '/getExecutionIntervalWeekdays' + params).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}


// ---------------------------------------------------------------------------------------------------------------------


export async function createReportPreview(data:any)  {
    return await updateAccessToken().then(async () => {
        return await new Promise(async function (resolve, reject) {
            return resolve(fetch(settings.apiUrl + '/createReportPreview', {
                method: 'POST',
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('keycloak-token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }).then(async (res) => {

                if (res.status !== 200) {
                    return await res.json();
                }

                const blob = await res.blob();
                const href = await URL.createObjectURL(blob);

                const timestamp = moment();
                const fileName = data.name.replace(/\s+/g,'') + '-' + timestamp.format('YYYY[_]MM[_]DD[-]hh[_]mm') + '.docx';

                Object.assign(document.createElement('a'), {
                    href,
                    download: fileName,
                }).click();

                return {};
            })
            );
        });
    });

}
