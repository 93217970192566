import React from 'react';
import {OnlyOfficeEditor} from './OnlyOfficeEditor';
import CustomDialog from './CustomDialog';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMaximize} from '@fortawesome/free-solid-svg-icons';
import {Button} from 'primereact/button';

type Props = {
    setShowOnlyOfficeDialog: any,
    fileId: string,
    projectId: string,
    urlToOpenFile: string,
    urlToSaveFile: string,
    fileType: string,
    plugins: boolean,
}
export const OnlyOfficeDialog = (props: Props): JSX.Element => {
    return (
        <div>
            <style>
                {`.only-office-dialog{max-height:100% !important;}`}
            </style>
            <CustomDialog
                className={'h-full w-full only-office-dialog'}
                onHide={() => {
                    props.setShowOnlyOfficeDialog(false);
                }}
                onCancel={() => {
                    props.setShowOnlyOfficeDialog(false);
                }}
                visible={true}
                maximized={true}
                showFooter={false}
                header={'OnlyOffice'}
                customIcons={<Button
                    style={{
                        padding: '0px 10px',
                        backgroundColor: 'rgba(0,0,0,0)',
                        border: '0px',
                        fontSize: '16px'}}
                    onClick={() => {
                        const frame = document.querySelector('iframe[name="frameEditor"]');
                        if (frame) {
                            frame.requestFullscreen().then(r => {});
                        }
                    }}>
                    <FontAwesomeIcon className='sidebar-icon' icon={faMaximize}/>
                </Button>}>
                <OnlyOfficeEditor
                    fileType={props.fileType}
                    urlToOpenFile={props.urlToOpenFile}
                    urlToSaveFile={props.urlToSaveFile}
                    fileId={props.fileId}
                    plugins={props.plugins}
                    projectId={props.projectId}/>
            </CustomDialog>
        </div>
    );
};
