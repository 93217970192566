import React, {useState, useEffect, useRef} from 'react';
import CustomDataTable from '../global/CustomDataTable';
import {useTranslation} from 'react-i18next';
import {getActionTypes, getNotificationTemplates, getNotificationTypes} from '../functions/Notification';
import {checkPermission} from '../../functions/functionLibrary';
import {permissions} from '../../config/permissions';
import {FilterMatchMode, FilterOperator} from 'primereact/api';

type Props = {
    projectId: string,
    reloadTemplates: boolean,
    setReloadTemplates: (data: boolean) => void
    notificationId?: string,
    editTemplate?: (data: any) => void,
    selectedTemplate?: any,
    setSelectTemplate?: (data: any) => void
}

export const NotificationTemplatesOverview = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common', 'notifications']);

    const [templates, setTemplates] = useState<any[]>([]);
    // arrays for filter dropdowns
    const [notificationTypes, setNotificationTypes] = useState<any[]>([]);
    const [actionTypes, setActionTypes] = useState<any[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const _setActionTypes = () => {
        getActionTypes().then(result => {
            setActionTypes(result);
        });
    };

    const _setNotificationTypes = () => {
        getNotificationTypes(props.projectId).then(result => {
            setNotificationTypes(result);
        });
    };

    useEffect(() => {
        _setTemplates();
        _setNotificationTypes();
        _setActionTypes();
    }, []);

    useEffect(() => {
        if (props.reloadTemplates) {
            _setTemplates();
            _setNotificationTypes();
            _setActionTypes();
            props.setReloadTemplates(false);
        }
    }, [props.reloadTemplates]);

    const _setTemplates = () => {
        if (typeof props.notificationId === 'undefined')
            getNotificationTemplates(props.projectId).then(result => {
                setTemplates(result);
                setDataLoaded(true);
            });
        else
            getNotificationTemplates(props.projectId, props.notificationId).then(result => {
                setTemplates(result);
                setDataLoaded(true);
            });
    };
    const templateColumns = [
        {
            field: 'name',
            header: t('name'),
            filter: true
        },
        {
            field: 'subject',
            header: t('subject'),
            filter: true
        },
        {
            field: 'text_content',
            header: t('content'),
            hidden: false,
            body: (rowData: any) => {
                return rowData['text_content'] && rowData['text_content'] !== ''
                    ? (rowData['text_content']).replace(/(\r\n|\n|\r)/gm, '').substring(0, 50) + '...'
                    : '';
            }
        },
        {
            field: 'action_type_id',
            header: t('alarmManagement:attributes.actionTypeId'),
            style: {maxWidth: '100px'},
            filter: false,
            display: false
        },
        {
            field: 'action_type_name',
            header: t('userManagement:attributes.recipientType'),
            filter: true,
            filterField: 'action_type_id',
            filterDropdownValues: actionTypes
        },
        {
            field: 'notification_type_id',
            header: t('alarmManagement:attributes.alarmTypeId'),
            style: {maxWidth: '100px'},
            filter: false,
            display: false
        },
        {
            field: 'notification_type_name',
            header: t('alarmManagement:attributes.alarmType'),
            filter: true,
            filterField: 'notification_type_id',
            filterDropdownValues: notificationTypes
        }
    ];

    const filters = {
        'name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'subject': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'action_type_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'notification_type_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
    };

    // todo: deaktiviert, bis wir ProjektTemplates haben
    /* const deleteTemplate = (data: any) => {
    }; */

    return (
        <CustomDataTable
            id='notificationTemplates'
            columns={templateColumns}
            editable={true}
            sortable={true}
            displayColumnFilter={true}
            value={templates}
            filters={filters}
            selection={props.selectedTemplate}
            onRowClick={props.setSelectTemplate}
            // todo: deaktiviert, bis wir ProjektTemplates haben
            // onRemoveClick={deleteTemplate}
            onEditClick={props.editTemplate}
            // todo: deaktiviert, bis wir ProjektTemplates haben
            ableToDelete={false}
            ableToUpdate={checkPermission(permissions.editNotificationTemplates)}
            dataloaded={dataLoaded}
            showExportCSV={true}
            showClearFiltersButton={true}
        />
    );
};

