import React, {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import MapContext from '../map/MapContext';
import {useTranslation} from 'react-i18next';
import {LegendControl, ToggleMapControl, BookmarkPermaControl} from './index';
import GeocodingControl from './GeocodingControl';
import {Control} from 'ol/control';

interface propsObj {
    [key: string]: any
}

type controlProps = {
    projectId: any
    projectBaseLayer: any
}
const ToolkitControl: FunctionComponent<controlProps> = (props) => {
    // @ts-ignore
    const {map} = useContext(MapContext);
    const {t} = useTranslation(['geomonitoring']);

    const [showToolkit, setShowToolkit] = useState<boolean>(false);
    const [delayHandler, setDelayHandler] = useState<any>(null);

    const createBaseMapButton = () => {
        const buttonToolkit = document.createElement('button');
        buttonToolkit.id = 'buttonToolkit';
        buttonToolkit.title = t('toolkit');
        buttonToolkit.innerHTML = '<span style="color: black"><i class="fas fa-grip-horizontal"/></span>';
        buttonToolkit.addEventListener('click', () => {
            setShowToolkit((prevState => {
                return !prevState;
            }));
        }, false);
        return buttonToolkit;
    };

    const getControls = () => {
        const buttonBaseMap = createBaseMapButton();

        const element = document.createElement('div');
        element.className = 'ctrl-map ol-unselectable ol-control';
        element.id = 'olControls';
        element.appendChild(buttonBaseMap);
        const location = new Control({
            element: element
        });

        return [location];
    };


    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        const controls = getControls();
        map.addControl(controls[0]);
        return () => {
            map.controls.remove(controls);
        };
    }, [map]);


    return (
        <div className={'toolkit-controls'}>
            <div id={'olToolkitControls'}
                 className={'toolkit-ctrl-map ol-unselectable ol-control' + (showToolkit ? ' open' : '')}
                 onMouseEnter={() => {
                     clearTimeout(delayHandler);
                 }}
                 onMouseLeave={() => {
                     setDelayHandler(setTimeout(() => {
                         setShowToolkit(false);
                     }, 500));
                 }}>
                <ToggleMapControl baseLayer={props.projectBaseLayer}/>
                <LegendControl projectId={props.projectId}/>
                <GeocodingControl projectId={props.projectId}/>
                <BookmarkPermaControl projectId={props.projectId}/>
            </div>
        </div>
    );
};

export default ToolkitControl;
