import settings from '../config/settings';
import geoSettings from "../config/geoSettings";
import {ImageWMS, TileWMS} from 'ol/source';
import TileLayer from 'ol/layer/Tile';
import OpenLayersParser from 'geostyler-openlayers-parser';
import React from 'react';
import keycloakfetch from './keycloakfetch';
import ImageLayer from "ol/layer/Image";

export const getUrlBasedByLayer = (workspace, layerKind, mapDataWorkBench) => {
    let url = '';
    switch (layerKind) {
        case 'rasterData':
            url = geoSettings.geoserverRestPath + 'workspaces/' + workspace + '/wmslayers.json';
            break;
        case 'coverageData':
            url = geoSettings.geoserverRestPath + 'workspaces/' + workspace + '/coverages.json';
            break;
        default:
            url = geoSettings.geoserverRestPath + 'workspaces/' + mapDataWorkBench + '/featuretypes.json';
            break;
    }
    return url;
};

export const setPermaLayerVisible = (layerVisibility, isPermalink, permaArray, layerId) => {
    if (isPermalink) {
        if (permaArray.includes(layerId)) {
            return true;
        }
        return false;
    }
    return layerVisibility;
};


export const callBaseLayerMap = (workspace) => {
    let url = getUrlBasedByLayer(workspace, 'rasterData', null);
    const requestOptions = {
        method: 'GET',
        headers: geoSettings.geoserverAuthentification,
    };

    return new Promise((resolve, reject) => {
        fetch(url, requestOptions)
            .then(async res => {
                if (res.ok) {
                    return res.json();
                } else {
                    let errorMessage = '';
                    const reader = res.body.getReader();
                    // read() returns a promise that resolves when a value has been received
                    await reader.read().then(function pump({done, value}) {
                        if (done) {
                            // Do something with last chunk of data then exit reader
                            return;
                        }
                        // Otherwise do something here to process current chunk
                        // Uint8Array in Text konvertieren
                        errorMessage = new TextDecoder('utf-8').decode(value);

                        // Read some more, and call this function again
                        return reader.read().then(pump);
                    });
                    throw new Error(errorMessage);
                }
            })
            .then(
                (result) => {
                    resolve({
                        success: true,
                        data: result
                    });
                },
                (error) => {
                    reject({
                        success: false,
                        info: 'GET from Geoserver failed: ' + error
                    });
                });
    });

    // return new Promise((resolve, reject) => {
    //     fetch(url, requestOptions)
    //         .then(res => res.json())
    //         .then(
    //             (result) => {
    //                 resolve({
    //                     success: true,
    //                     data: result
    //                 });
    //             },
    //             (error) => {
    //                 console.log(error)
    //                 reject({
    //                     success: false,
    //                     info: 'GET failed: ' + error
    //                 });
    //             });
    // });
};

export const parseStyle = (styleToParse, parseObject) => {
    const parser = new OpenLayersParser();
    parser
        .writeStyle(styleToParse)
        .then((olStyle) => parseObject.setStyle(olStyle.output))
        .catch((error) => console.log(error));
};

export const getWmsLayer = (layerDesignation, workspace, visible, type, baseLayer, layerId, styleId, styleId4Raster, layerIndex, opacity) => {
    let newId = ((styleId !== null) && (typeof styleId !== 'undefined')) ? styleId : layerId;
    let lowerId = newId.toLowerCase();
    let baseUrl = geoSettings.geoserverPath + workspace + '/wms';

    return new ImageLayer({
        name: layerDesignation,
        id: lowerId,
        baseLayer: baseLayer,
        visible: visible,
        type: type,
        zIndex: layerIndex,
        opacity: 0.01*parseInt(opacity),
        source: new ImageWMS({
            url: baseUrl,
            params: {
                'LAYERS': workspace + ':' + layerDesignation,
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            //transition: 0,
            ratio:1,
            opacity: 0.01*parseInt(opacity),

        })
    });
};
export const getWMSBaseLayer = (layerDesignation, workspace, visible, type, baseLayer, layerId, styleId, styleId4Raster, layerIndex, opacity) => {
    let newId = ((styleId !== null) && (typeof styleId !== 'undefined')) ? styleId : layerId;
    let lowerId = newId.toLowerCase();
    let baseUrl = geoSettings.geoserverPath + workspace + '/wms';
    // console.log('wmslayerdata',newId,styleId4Raster, styleId)

    //styleId=(styleId==='dbnetz_400_3501_tbw_sfs1733km1000_pt')?'DBNETZ_400_3501_tbw_sfs1733km1000_pt':styleId;

    return new TileLayer({
        name: layerDesignation,
        id: lowerId,
        baseLayer: baseLayer,
        visible: visible,
        type: type,
        zIndex: layerIndex,
        opacity: 0.01*parseInt(opacity),
        source: new TileWMS({
            url: baseUrl,
            params: {
                'LAYERS': workspace + ':' + layerDesignation,
                'VERSION': '1.3.0',
                'FORMAT': 'image/png',
                'STYLES': styleId4Raster == null ? null : styleId,
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0,
            opacity: 0.01*parseInt(opacity),

        })
    });
};


export const generateImageForLegend = (geoserverWorkspace, mainLayerId, legendArray, styleIdUnformated, styleId, layerId, externLegendPic, baseWmsWorkspace) => {
    let url = geoSettings.geoserverPath + geoserverWorkspace + '/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&TRANSPARENT=true&LAYER=' + geoserverWorkspace + ':' + mainLayerId + '&LEGEND_OPTIONS=forceLabels:on';

    if (styleId !== layerId && styleId !== null) {
        url = url + '&STYLE=' + baseWmsWorkspace + ':' + styleIdUnformated;
    }

    if (legendArray && legendArray.url) {

        url = legendArray.url;
        if (legendArray.extern === 'large') {
            return (alert('Die Legende ist zu groß für die Darstellung. Bitte Admin kontaktieren.'));
            //return '<a href="javascript:Ext.create(\'toolbox.base.components.LegendWindow\', {title:\'' + mainLayerId + '\', url:\'' + url + '\'});">' + translate('fieldLabel.legend') + '</a>';
        }
    }
    if (legendArray && !legendArray.url && legendArray.extern !== null) {
        return false;
    }
    if (typeof externLegendPic == 'string') {
        url = externLegendPic;
    }
    // console.log(url
    //  addLegendToDiv(node.visibility, node.legendTitle, styleId, image, node.divIndex)
    return <img src={url} className="legendBackgroundImg" alt={'legendpic'}/>;
};

export const getBaseWMSLayers = (layerArray, baseLayer, isPermaLink, permaLinkArray, baseWmsWorkspace) => {
    let basisLayer = baseLayer !== null ? baseLayer.toLowerCase() : null;
    return new Promise(async function (resolve, reject) {
        try {
            let wmsArray = [];
            for (const layer of layerArray) {
                let visible = false;
                let layerId = layer.name.toLowerCase();
                let baseLayer = (basisLayer !== null && basisLayer !== geoSettings.activeBaseLayer) ? basisLayer : geoSettings.activeBaseLayer;
                if (layerId === baseLayer) {
                    visible = true;
                }
                const layerVisibility = setPermaLayerVisible(visible, isPermaLink, permaLinkArray, layerId);
                let wmsLayer = getWMSBaseLayer(layer.name, baseWmsWorkspace, layerVisibility, 'raster', true, layerId, null, null, null,100);
                wmsArray.push(wmsLayer);
                if (wmsArray.length === layerArray.length) {
                    resolve({success: true, layers: wmsArray});
                }
            }

        } catch (e) {
            reject(e);
        }
    });
};

export const getLegendControllArray = (currentChanged, changedLayer) => {
    let copyOldChangedValues = JSON.parse(JSON.stringify(currentChanged));
    if (changedLayer.length > 0) {
        copyOldChangedValues.forEach((formerElem) => {
            const found = changedLayer.find((newElem) => formerElem.id === newElem.id);
            if (found) {
                copyOldChangedValues = copyOldChangedValues.filter((item) => item.id !== formerElem.id)
            }
        });
    }
    return [...copyOldChangedValues, ...changedLayer];
}

const callFeatureInfo = (layerKind, layerName, workBench, baseWmsWorkspace) => {
    let featureUrl = geoSettings.geoserverRestPath + 'workspaces/' + workBench;
    let jsonKey = 'featureType';

    switch (layerKind) {
        case 'vectorData':
            featureUrl = featureUrl + '/featuretypes/' + layerName + '.json';
            jsonKey = 'featureType';
            break;
        case 'rasterData':
            featureUrl = featureUrl + '/wmslayers/' + layerName + '.json';
            jsonKey = 'wmsLayer';
            break;
        case 'baseData':
            featureUrl = geoSettings.geoserverRestPath + 'workspaces/' + baseWmsWorkspace + '/wmslayers/' + layerName + '.json';
            jsonKey = 'wmsLayer';
            break;
        case 'coverageData':
            featureUrl = featureUrl + '/coverages/' + layerName + '.json';
            jsonKey = 'coverage';
            break;
    }
    return {url: featureUrl, key: jsonKey};
};

export const callFeatureTypes = (layername, layerKind, workBench, baseWmsWorkspace) => {
    const features = callFeatureInfo(layerKind, layername, workBench, baseWmsWorkspace);
    if (!features) return false;
    const requestOptions = {
        method: 'GET',
        headers: geoSettings.geoserverAuthentification,
    };

    return new Promise(function (resolve, reject) {
        try {
            fetch(features.url, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        resolve({

                            success: true,
                            data: result[features.key]
                        });
                    },
                    (error) => {
                        reject({
                            success: false,
                            info: 'GET failed: ' + error
                        });
                    });
        } catch (e) {
            reject(false);
        }

    });
};

export const getAbstractValues = (abstract, altName, layerKind) => {
    const abstractParts = abstract ? abstract.split('\n') : null;
    const language = window.navigator.language.slice(0, 2);

    let name = altName;
    let layerVisible = false;
    let featureInfo = true;
    let wmsState = null;
    let attributes = [];
    let editAttributes = [];
    let counter = 0;
    let graphTitle = '';
    let seperator = null;
    let legend = null;
    let functionSource = null;
    let analyseLayer = false;
    let functionLayer = false;
    let editable = false;
    let datePrefix = null;
    let dateFormat = null;
    let externLegendPic = null;
    let transformAttributes = false;
    let treeName = '';
    let metaData = '';
    let opacityWMS = 100;


    return new Promise(function (resolve) {
        if (layerKind === 'baseData') {
            //  resolve(false);
        }

        if (abstractParts === null) {
            resolve({
                layerName: name,
                layerVisible: layerVisible,
                layerAttributes: attributes,
                layerInfo: featureInfo,
                callAsWms: wmsState,
                layerKind: wmsState === true ? 'rasterData' : layerKind,
                graphTitle: graphTitle,
                seperator: seperator,
                legend: legend,
                analyseLayer: analyseLayer,
                functionLayer: functionLayer,
                functionSource: functionSource,
                editable: editable,
                editAttributes: editAttributes,
                date: {prefix: datePrefix, dateFormat: dateFormat},
                treeName: '',
                metaData: '',
                opacityWMS: 100

            });
        }

        abstractParts?.forEach(function (lang) {
            counter++;
            const abstractArray = lang.split(':');
            if ((typeof abstractArray[1] !== 'undefined') && (abstractArray.length > 0)) {
                //  console.log(name,abstractArray)
                const abstractValue = abstractArray[1].trim();
                switch (abstractArray[0]) {
                    case language: {
                        // console.log(language,abstractValue,decodeURIComponent(escape(window.atob(abstractValue))) )
                        name = decodeURIComponent(escape(window.atob(abstractValue)));
                        break;
                    }
                    case 'nodeName': {
                        //  console.log(abstractValue,decodeURIComponent(escape(window.atob(abstractValue))) )
                        treeName = decodeURIComponent(escape(window.atob(abstractValue)));
                        break;
                    }
                    case 'metadata': {
                        //  console.log(abstractValue,decodeURIComponent(escape(window.atob(abstractValue))) )
                        metaData = decodeURIComponent(escape(window.atob(abstractValue)));
                        break;
                    }
                    case 'layerVisible':
                        layerVisible = (abstractValue === true || abstractValue === 'true');
                        break;
                    case 'attr':
                        attributes = abstractValue.split(',');
                        break;
                    case 'featureInfo':
                        // @ts-ignore
                        featureInfo = (abstractValue === true || abstractValue === 'true');
                        break;
                    case 'wms':
                        // @ts-ignore
                        wmsState = (abstractValue === true || abstractValue === 'true' || abstractValue === 'true');
                        break;
                    case 'graphTitle':
                        graphTitle = decodeURIComponent(escape(window.atob(abstractValue)));
                        break;
                    case 'graphPrefix':
                        seperator = abstractValue;
                        break;
                    case 'legend':
                        legend = abstractValue;
                        break;
                    case 'analyseLayer':
                        analyseLayer = abstractValue;
                        break;
                    case 'functions':
                        functionLayer = abstractValue;
                        break;
                    case 'functionsSource':
                        functionSource = abstractValue;
                        break;
                    case 'editable':
                        editable = abstractValue;
                        break;
                    case 'editAttr':
                        editAttributes = abstractValue.split(',');
                        break;
                    case 'opacitywms':
                        opacityWMS = abstractValue;
                        break;
                    case 'externLegendPic':
                        externLegendPic = abstractArray[1] + ':' + abstractArray[2];
                        break;
                    case 'transformAttributes':
                        transformAttributes = (abstractValue === true || abstractValue === 'true');
                        break;
                    case 'prefixAndDateFormat':
                        const valueDate = abstractValue.split(',');
                        if (valueDate.length === 2) {
                            datePrefix = valueDate[0];
                            dateFormat = valueDate[1];
                        }

                        break;
                }
            }

            if (counter === abstractParts?.length) {
                const altTitelName = altName ? toCamelCase(altName.split('XYX').join(' ')) : null;
                name = (typeof name === 'undefined' || name === null) ? altTitelName : name;
                resolve({
                    layerName: name,
                    layerVisible: layerVisible,
                    layerAttributes: attributes,
                    layerInfo: featureInfo,
                    callAsWms: wmsState,
                    layerKind: wmsState === true ? 'rasterData' : layerKind,
                    graphTitle: graphTitle,
                    seperator: seperator,
                    legend: legend,
                    analyseLayer: analyseLayer,
                    functionLayer: functionLayer,
                    functionSource: functionSource,
                    editable: editable,
                    editAttributes: editAttributes,
                    externLegendPic: externLegendPic,
                    transformAttributes: transformAttributes,
                    date: {prefix: datePrefix, dateFormat: dateFormat},
                    treeName: treeName !== '' ? {string: treeName, base64: true} : '',
                    metaData: metaData !== '' ? {string: metaData, base64: true} : '',
                    opacity:opacityWMS
                });
            }
        });
    });

};
export const setValIfUndefined = (condititon, val1, val2) => {
    return condititon ? val1 : val2;
};
export const setTreeValIfUndefined = (condititon, val1Key, val1, val2) => {
    console.log(condititon, val1Key, val1, val2, val1[val1Key]);
    return condititon ? val1[val1Key] : val2;
};

export const isNotUndefined = (val) => {
    if (typeof val == 'undefined') {
        return false;
    }
    return true;
};


export const toCamelCase = (string) => {
    if (!string && typeof string == 'undefined') return string;
    let stringCamelCase = string.charAt(0).toLowerCase() + string.substring(1);
    stringCamelCase = string.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, function (match) {
        return match.charAt(match.length - 1).toUpperCase();
    });

    if (string === string.toUpperCase()) {
        return string;
    } else {
        return stringCamelCase;
    }
};

export const fetchChannels = async (projectId, locationId) => {
    let retValue = [];
    await keycloakfetch.get(settings.apiUrl + '/getChannelIdsByLocationId?project_id=' + projectId + '&location_id=' + locationId).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
};
