import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';
import {Nullable} from './Global';
import {stat} from 'fs';
import {ChannelCalibration} from '../metrology/ChannelCalibrationForm';


export interface ControlDevice {
    'control_device_id': string,
    'project_id': string,
    'control_device_type_id': number,
    'control_device_name': string,
    'name': string,
    'description': Nullable<string>,
    'ip': string,
    'is_active': boolean,
    'location_id'?: Nullable<string>,
    'location_name'?: Nullable<string>,
    'project_group_id'?: Nullable<string>,
    'project_group_name'?: Nullable<string>,
    'last_rpm'?: Nullable<number>,
}

export interface MeasuringManager {
    'measuring_manager_id': string,
    'project_id': string,
    'measuring_manager_name': string,
    'description': Nullable<string>,
    'identifier': string,
    'location_id'?: Nullable<string>,
    'location_name'?: Nullable<string>,
    'element_id'?: Nullable<string>,
    'element_name'?: Nullable<string>,
}

export interface MeasuringDevice {
    'measuring_device_id': string,
    'measuring_manager_id'?: string,
    'measuring_manager_name'?: string,
    'project_id': string,
    'measuring_device_type_id': number,
    'measuring_device_name': string,
    'description': string,
    'is_visible': boolean,
    'count': number,
    'location_id': string,
    'qrcode': string,
    parameter?: string,
    ip?: string,
    port?: string
}

export interface MeasuringDeviceTypeSensorTypeMap {
    'measuring_device_type_id': number,
    'sensor_type_id': number,
}

export interface Sensor {
    'sensor_id': string,
    'sensor_name': string,
    'sensor_type_id': number,
    'sensor_type_name'?: string,
    'qrcode'?: Nullable<string>,
    'epsg_code'?: Nullable<string>,
    'description': Nullable<string>,
    'icon'?: Nullable<string>,
    'is_active': boolean,
    'is_component_required'?: boolean,
    'is_visible': boolean,
    'is_permanently_visible': boolean,
    'measuring_device_id'?: Nullable<string>,
    'measuring_device_name'?: Nullable<string>,
    'location_id'?: Nullable<string>,
    'location_name'?: Nullable<string>,
    'project_id': string,
    'element_id'?: Nullable<string>,
    'sensitivity'?: Nullable<number>
    'sensitivity_unit'?: Nullable<string>
    'count'?: number,
    'channel_address'?: Nullable<number>
}

export interface SensorType {
    'id': number,
    'name': string,
    'icon'?: Nullable<string>,
    'channel_address': Nullable<number>,
    'epsg_code'?: Nullable<number>,
    'is_component_required'?: boolean,
    'is_measuring_device_required'?: boolean,
}

export interface SensorTypeChannelTypeMap {
    'sensor_type_id': number,
    'channel_type_id': number,
}

export interface Channel {
    'channel_id': string,
    'channel_name': string,
    'channel_type_id': number,
    'channel_type_name'?: string,
    'chart_min': Nullable<number>,
    'chart_max': Nullable<number>,
    'chart_round': Nullable<number>,
    'data_type'?: Nullable<number>,
    'datalink_id'?: Nullable<string>,
    'datalink_name'?: Nullable<string>,
    'description': Nullable<string>,
    'icon'?: Nullable<string>,
    'is_active': boolean,
    'is_logical': boolean,
    'is_operating_data_required'?: boolean,
    'is_visible': boolean,
    'location_id'?: Nullable<string>,
    'location_name'?: Nullable<string>,
    'project_group_id'?: Nullable<string>,
    'project_group_name'?: Nullable<string>,
    'unit': Nullable<string>,
    'sensor_id'?: Nullable<string>,
    'sensor_name'?: Nullable<string>,
    'project_id': string,
    'timezone': string,
    'media_link': Nullable<string>,
    'max_allowed_data_age_in_hours': Nullable<number>,
    'count': number,
    'port'?: Nullable<string>,
    'logical_channel_settings'?: Nullable<LogicalChannel>,
}

export interface LogicalChannel {
    'channel_id'?: string,
    'operating_state_id'?: Nullable<string>,
    'operating_state_name'?: Nullable<string>,
    // lead_time
    // Zeit, die der Betriebszustand vor Start der Messung angelegen haben muss, um eine gültige Messung zu beginnen.
    'lead_time'?: Nullable<number>,
    // follow_up_time
    // Zeit, die der Betriebszustand nach Start der Messung angelegen haben muss, um eine gültige Messung durchzuführen.
    'follow_up_time'?: Nullable<number>,
    // recording_time
    // Durchschnittliche Messdauer, aber nicht in [ms], bei HKZT ist der Wert so und so lang, bei HKMD anders.
    'recording_time'?: Nullable<number>,
    // measuring_interval
    // Zeitabstand [ms], in dem die Messung wiederholt wird.
    'measuring_interval'?: Nullable<number>,
    // sampling_rate
    // Ehemals FP5: Abtastrate von Betriebs- und Zeitdatenkanäle.
    'sampling_rate'?: Nullable<number>,
    // scaling_factor
    // Ehemals FP2: Skalierungsfaktor für Betriebsdatenkanäle.
    'scaling_factor'?: Nullable<number>,
    // offset
    // Ehemals FP3: Offset für Betriebsdatenkanäle.
    'offset'?: Nullable<number>;
    // cutoff_frequency
    // Ehemals FP5: Grenzfrequenz/Eckfrequenz für Spektraldatenkanäle.
    'cutoff_frequency'?: Nullable<number>,
    // number_of_values
    // Ehemals FP6: Anzahl der Werte/Samples/Punkte.
    'number_of_values'?: Nullable<number>,
    // saving_condition_rmsa
    // Ehemals FP7: Soll Daten speichern, wenn der RMSa-Wert > threshold_rmsa ist.
    'saving_condition_rmsa'?: Nullable<boolean>,
    // threshold_rmsa
    // Ehemals FP8: RMSa-Schwellwert für 'saving_condition_rmsa'.
    'threshold_rmsa'?: Nullable<number>,
    // saving_condition_rmsv
    // Ehemals FP7: Soll Daten speichern, wenn der RMSv-Wert > threshold_rmsv ist.
    'saving_condition_rmsv'?: Nullable<boolean>,
    // threshold_rmsv
    // Ehemals FP8: RMSv-Schwellwert für saving_condition_rmsv'.
    'threshold_rmsv'?: Nullable<number>,
    // saving_condition_rmsd
    // Ehemals FP7: Soll Daten speichern, wenn der RMSd-Wert > rmsd_threshold ist.
    'saving_condition_rmsd'?: Nullable<boolean>,
    // threshold_rmsd
    // Ehemals FP8: RMSd-Schwellwert für 'saving_condition_rmsd'.
    'threshold_rmsd'?: Nullable<number>,
    // duration_in_seconds
    // Ehemals FP15: Aufnahmedauer für einen Datensatz von Zeitsignal- und Hüllkurvenzeitsignaldatenkanäle.
    'duration_in_seconds'?: Nullable<number>,
    // signal_type
    // Ehemals FP19: Wertetyp/RMS-Typ von Betriebsdatenkanäle.
    // 0: Rohsignal; 1: Effektivwert a; 2: Effektivwert v
    'signal_type': Nullable<number>,
    // save_data
    // Für Betriebsdaten (beda_or_meda=BEDA).
    // False: Die Betriebsdaten werden nicht in der Datenbank abgespeichert.
    // True: Die Betriebsdaten werden in der Datenbank abgespeichert.
    'save_data'?: Nullable<boolean>,
    // synchronous_measuring
    // Flag zur Synchronmessung.
    'synchronous_measuring'?: Nullable<boolean>,
    // continually_measuring
    // Flag zum kontinuierlichen Messen: 0: kont. Messen inaktiv, 1: kont. Messen aktiv
    'continually_measuring'?: Nullable<boolean>,
    // measuring_data_output
    // Flag zum Ausgabeformat der Messdaten: 0: Datenbank, 1: CSV, 2: Datenbank und CSV, 3: Nirvana
    'measuring_data_output'?: Nullable<number>,
    // output_path
    // Ausgabepfad für Messdatenausgabe via CSV.
    'output_path'?: Nullable<string>,
    fp1?: Nullable<string>,
    fp2?: Nullable<string>,
    fp3?: Nullable<string>,
    fp4?: Nullable<string>,
    fp5?: Nullable<string>,
    fp6?: Nullable<string>,
    fp7?: Nullable<string>,
    fp8?: Nullable<string>,
    fp9?: Nullable<string>,
    fp10?: Nullable<string>,
    fp11?: Nullable<string>,
    fp12?: Nullable<string>,
    fp13?: Nullable<string>,
    fp14?: Nullable<string>,
    fp15?: Nullable<string>,
    fp16?: Nullable<string>,
    fp17?: Nullable<string>,
    fp18?: Nullable<string>,
    fp19?: Nullable<string>,
    fp20?: Nullable<string>,
    fp21?: Nullable<string>,
    fp22?: Nullable<string>,
    fp23?: Nullable<string>,
    fp24?: Nullable<string>,
    fp25?: Nullable<string>,
    fp26?: Nullable<string>,
    fp27?: Nullable<string>,
    fp28?: Nullable<string>,
    fp29?: Nullable<string>,
    fp30?: Nullable<string>,
    fp31?: Nullable<string>,
    fp32?: Nullable<string>,
    fp33?: Nullable<string>,
    fp34?: Nullable<string>,
    fp35?: Nullable<string>,
    fp36?: Nullable<string>,
    fp37?: Nullable<string>,
    fp38?: Nullable<string>,
    fp39?: Nullable<string>,
    fp40?: Nullable<string>
}

export interface ChannelType {
    'id': number,
    'name': string,
    'data_type': string,
    'measuring_unit': string,
    'measuring_data_table': string,
    'chart_min': number,
    'chart_max': number,
    'chart_round': number,
    'is_logical': boolean,
    'is_operating_data_required': boolean
}

export interface LogicalChannelFunction {
    'field': string,
    'value': string,
    'input_type'?: string,
    'channel_type_id'?: number
    'operating_state_id'?:boolean
    'tooltip'?: string,
}

// ---------------------------------------------------------------------------------------------------------------------
// Global
// ---------------------------------------------------------------------------------------------------------------------

export async function createMetrologyFromWizard(data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/createMetrologyFromWizard', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createMetrologyFromCSV(data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/createMetrologyFromCSV', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getWeatherStatus(projectId: string, locationId: Nullable<string> = null) {
    let retValue: any = [];
    let additionalParams = '';
    if (locationId) additionalParams += '&location_id=' + locationId
    await keycloakfetch.get(settings.apiUrl + '/getWeatherStatus?project_id=' + projectId + additionalParams).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function updateStatus(data: any) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/updateStatus', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

// ---------------------------------------------------------------------------------------------------------------------
// Channels
// ---------------------------------------------------------------------------------------------------------------------

export async function getChannelTypes(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getChannelTypes?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getLogicalChannelFunctions(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getLogicalChannelFunctions?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getChannels(projectId: string, channelTypeIds: number[] = [], sensorId: Nullable<string> = null, type: Nullable<string> = null) {
    let retValue: any = [];
    let urlStr = '/getChannels?project_id=' + projectId;
    if (channelTypeIds.length > 0)
        urlStr += '&channel_type_ids=' + channelTypeIds;
    if (sensorId)
        urlStr += '&sensor_id=' + sensorId;
    if (type)
        urlStr += '&type=' + type;
    await keycloakfetch.get(settings.apiUrl + urlStr).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getChannelsAsTree(projectId: string, type: Nullable<string> = null, channelId: Nullable<string> = null, channelTypeId: Nullable<number> = null) {
    let retValue: any = [];
    let urlStr = '/getChannelsAsTree?project_id=' + projectId;
    if (type !== null)
        urlStr += '&type=' + type;
    if (channelId !== null)
        urlStr += '&channel_id=' + channelId;
    if (channelTypeId !== null)
        urlStr += '&channel_type_id=' + channelTypeId;
    await keycloakfetch.get(settings.apiUrl + urlStr).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getElementTree(projectId: string) {
    let retValue: any = [];
    const urlStr = '/getElementTree?project_id=' + projectId;
    await keycloakfetch.get(settings.apiUrl + urlStr).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

/**
 * Zweite Variante den Maschinenbaum als Baum zu bekommen
 * @param projectId
 */
export async function getElementsAsTree(projectId: string, dataType: string = '') {
    let retValue: any = [];
    let urlStr = '/getElementsAsTree?project_id=' + projectId;
    if (dataType !== '')
        urlStr += '&data_type=' + dataType;

    await keycloakfetch.get(settings.apiUrl + urlStr).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getChannel(projectId: string, channelId: string, dataType: Nullable<number> = null) {
    let retValue: any = [];
    let paramStr = '?project_id=' + projectId + '&channel_id=' + channelId;
    if (dataType !== null)
        paramStr += '&data_type=' + dataType;
    await keycloakfetch.get(settings.apiUrl + '/getChannel' + paramStr).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getChannelsBySensorId(projectId: string, sensorId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getChannels?project_id=' + projectId + '&sensor_id=' + sensorId).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getChannelsById(projectId: string, channelIds: string[]) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getChannelsById?project_id=' + projectId + '&channel_ids=' + channelIds.join(',')).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function updateChannel(channel: Channel) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/updateChannel', channel).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createChannel(channel: Channel) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/createChannel', channel).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function deleteChannel(projectId: string, channelIds: string[]) {
    let retValue: any = [];
    await keycloakfetch.delete(settings.apiUrl + '/deleteChannel', {
        'project_id': projectId,
        'channel_ids': channelIds
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getChannelsForNotification(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getChannelsForNotification?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getDataLinks(projectId: string) {
    const retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getDataLinks?project_id=' + projectId).then((result) => {
        if (result)
            result.forEach((key: any) => {
                retValue.push({
                    value: key.id,
                    label: key.name,
                });
            });
    });
    return retValue;
}

export async function getDataLinkPorts(projectId: string, dataLinkId: string, channelId: (string | null) = null) {
    const retValue: any = [];
    let additionParams = '';
    if (channelId)
        additionParams = '&channel_id=' + channelId;
    await keycloakfetch.get(settings.apiUrl + '/getFreePorts?project_id=' + projectId + '&datalink_id=' + dataLinkId + additionParams
    ).then((result) => {
        if (result)
            result.forEach((key: any) => {
                retValue.push({
                    value: key.port,
                    label: key.port,
                });
            });
    });
    return retValue;
}

export async function createMultipleChannelCalibrations(data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/createMultipleChannelCalibrations', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createGrafanaPanel(data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/createGrafanaPanel', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

// ---------------------------------------------------------------------------------------------------------------------
// ChannelCalibrations
// ---------------------------------------------------------------------------------------------------------------------
export async function getLastChannelCalibration(projectId: string, channelId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getLastChannelCalibration?project_id=' + projectId + '&channel_id=' + channelId).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getChannelCalibrations(projectId: string, channelId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getChannelCalibrations?project_id=' + projectId + '&channel_id=' + channelId).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createChannelCalibration(calibration: ChannelCalibration) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/createChannelCalibration', calibration).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function updateChannelCalibration(calibration: ChannelCalibration) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/updateChannelCalibration', calibration).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function deleteChannelCalibration(projectId: string, calibrations: ChannelCalibration[]) {
    let retValue: any = [];
    await keycloakfetch.delete(settings.apiUrl + '/deleteChannelCalibration', {
        'project_id': projectId,
        'calibrations': calibrations
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

// ---------------------------------------------------------------------------------------------------------------------
// Sensors
// ---------------------------------------------------------------------------------------------------------------------

// projectId for permission check
export async function getSensorTypes(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getSensorTypes?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getSensor(projectId: string, sensorId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getSensor?project_id=' + projectId
        + '&sensor_id=' + sensorId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getSensors(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getSensors?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getSensorsAsTree(projectId: string, type: Nullable<string> = null) {
    let retValue: any = [];
    let urlStr = '/getSensorsAsTree?project_id=' + projectId;
    if (type !== null)
        urlStr += '&type=' + type;
    await keycloakfetch.get(settings.apiUrl + urlStr).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function updateSensor(data: any) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/updateSensor', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createSensor(data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/createSensor', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function deleteSensor(projectId: string, sensorIds: string[]) {
    let retValue: any = [];
    await keycloakfetch.delete(settings.apiUrl + '/deleteSensor', {
        'project_id': projectId,
        'sensor_ids': sensorIds
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

// ---------------------------------------------------------------------------------------------------------------------
// Mappers
// ---------------------------------------------------------------------------------------------------------------------

export async function getMeasuringDeviceTypeSensorTypeMap(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getMeasuringDeviceTypeSensorTypeMap?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getSensorTypeChannelTypeMap(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getSensorTypeChannelTypeMap?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

// ---------------------------------------------------------------------------------------------------------------------
// MeasuringDevices
// ---------------------------------------------------------------------------------------------------------------------

export async function getMeasuringDeviceTypes(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getMeasuringDeviceTypes?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getMeasuringDevice(projectId: string, deviceId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getMeasuringDevice?project_id=' + projectId + '&measuring_device_id=' + deviceId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getMeasuringDevices(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getMeasuringDevices?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function updateMeasuringDevice(data: any) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/updateMeasuringDevice', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createMeasuringDevice(data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/createMeasuringDevice', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function duplicateMeasuringDevice(data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/duplicateMeasuringDevice', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function deleteMeasuringDevice(projectId: string, measuringDeviceIds: string[]) {
    let retValue: any = [];
    await keycloakfetch.delete(settings.apiUrl + '/deleteMeasuringDevice', {
        'project_id': projectId,
        'measuring_device_ids': measuringDeviceIds
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

// ---------------------------------------------------------------------------------------------------------------------
// MeasuringManagers
// ---------------------------------------------------------------------------------------------------------------------

export async function getMeasuringManager(projectId: string, measuringManagerId: string | undefined) {
    let retValue: any = [];
    const paramStr = '?project_id=' + projectId + '&measuring_manager_id=' + measuringManagerId;
    await keycloakfetch.get(settings.apiUrl + '/getMeasuringManager' + paramStr).then(result => {
        if (result)
            retValue = result[0];
    });
    return retValue;
}

export async function getMeasuringManagers(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getMeasuringManagers?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function updateMeasuringManager(data: any) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/updateMeasuringManager', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createMeasuringManager(data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/createMeasuringManager', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function deleteMeasuringManagers(projectId: string, measuringManagerIds: string[]) {
    let retValue: any = [];
    await keycloakfetch.delete(settings.apiUrl + '/deleteMeasuringManagers', {
        'project_id': projectId,
        'measuring_manager_ids':measuringManagerIds
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

// ---------------------------------------------------------------------------------------------------------------------
// Mapping tables
// ---------------------------------------------------------------------------------------------------------------------

export async function getMeasuringDeviceSensorMap() {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getMeasuringDeviceSensorMap').then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getSensorChannelMap() {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getSensorChannelMap').then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

// ---------------------------------------------------------------------------------------------------------------------
// ControlDevices
// ---------------------------------------------------------------------------------------------------------------------

// projectId for permission check
export async function getControlDeviceTypes(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getControlDeviceTypes?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getControlDevices(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getControlDevices?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function updateControlDevice(data: any) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/updateControlDevice', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createControlDevice(data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/createControlDevice', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function deleteControlDevice(projectId: string, controlDeviceIds: string[]) {
    let retValue: any = [];
    await keycloakfetch.delete(settings.apiUrl + '/deleteControlDevices', {
        'project_id': projectId,
        'control_device_ids': controlDeviceIds
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getFuRpm(projectId: string, controlDeviceId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getFuRpm?project_id=' + projectId + '&control_device_id=' + controlDeviceId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function startFu(projectId: string, controlDeviceIds: string[], speed: number) {
    let retValue: any = [];
    const body = {
        'project_id': projectId,
        'control_device_ids': controlDeviceIds,
        'speed': speed
    };
    await keycloakfetch.post(settings.apiUrl + '/startFu', body).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function stopFu(projectId: string, controlDeviceIds: string[]) {
    let retValue: any = [];
    const body = {
        'project_id': projectId,
        'control_device_ids': controlDeviceIds,
    };
    await keycloakfetch.post(settings.apiUrl + '/stopFu?', body).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}
