/*
 * ActionSelectList.tsx
 * Author: lnappenfeld
 * Date: 05.05.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import Input from '../global/Input';
import {getActions} from '../functions/Notification';
import {useTranslation} from 'react-i18next';
import {getUserFromLocalStorage, Nullable} from '../functions/Global';

type Data = {
    value: string,
    label: string
}

type Props = {
    projectId: string,
    label: string,
    value?: Nullable<string>,
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    name?: string,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    disabled?: boolean,
    showClear?: boolean,
    tooltip?: string,
    onlyCurrentUserEmail?: boolean // Es soll nur die Aktion angezeigt werden, die die E-Mail-Adresse des Benutzer hat
}
const ActionSelectList = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common', 'actions']);
    const [list, setList] = useState<Data[]>();

    useEffect(() => {
        getActions(props.projectId, t).then(result => {
            const tmp: any = [];
            if (props.onlyCurrentUserEmail) {
                // Zeige nur die E-Mail-Action des aktuellen Benutzers
                const userEmail = getUserFromLocalStorage().email;
                for (let i=0; i<result.length; i++) {
                    if (result[i].recipient_address === userEmail) {
                        tmp.push({
                            value: result[i].action_id,
                            label: result[i].action_name,
                        });
                        if (props.onChange) props.onChange({value: result[i].action_id});
                        break;
                    }
                }
                // Wenn keine Empfängeraktion mit der User-Email-Adresse exisitert, wird onChange mit dem value: null
                // aufgerufen, damit der Tooltip in ExportMeasurementDataOverview korrekt angezeigt wird.
                if (tmp.length === 0) {
                    if (props.onChange) props.onChange({value: null});
                }
            } else {
                // Zeige alle Actions
                result.forEach((key: any) => {
                    tmp.push({
                        value: key.action_id,
                        label: key.action_name,
                    });
                });
            }
            setList(tmp);
        });
    }, []);

    return (
        <Input
            name={props.name ? props.name : 'action_id'}
            edit={true}
            showClear={typeof props.showClear === 'boolean' ? props.showClear : false}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            tooltip={props.tooltip}
            label={props.label}
            value={props.value}
            dropdownOptions={list} type={'dropdown'}
            onChange={(e: any) => {
                if (props.setValue) props.setValue(e.value);
                if (props.onChange) props.onChange(e);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};
export default ActionSelectList;
