// -------------------------------------------------------------------------------------------------------
// Safeguard Production
/*
export const keycloakConfig = {
    "realm": "safeguard",
    "url": "https://security.safeguard.dmt-group.com",
    "clientId": "web-app",
};

const settings = {
    pyApiUrl:'https://ki.safeguard.api.dmt-group.com', //'http://10.0.116.90:1965',
    grafanaUrl: 'https://grafana.safeguard.dmt-group.com',
    nextcloudUrl: 'https://filedata.safeguard.dmt-group.com',
     apiUrl: process.env.NODE_ENV === 'development' ? 'http://localhost:1962' : 'https://api.safeguard.dmt-group.com',
    // apiUrl: 'https://api.safeguard.dmt-group.com',
    keycloakAuthUrl: keycloakConfig.url + '/realms/' + keycloakConfig.realm + '/protocol/openid-connect/token',
    logoutUrl: keycloakConfig.url + '/realms/' + keycloakConfig.realm + '/protocol/openid-connect/logout',
    logoutRedirect: process.env.NODE_ENV === 'development' ? 'http://localhost:1841/' : 'https://app.safeguard.dmt-group.com/',
    ooUrl: process.env.NODE_ENV === 'development' ? 'http://10.0.116.81:81' : 'https://office.safeguard.dmt-group.com',
    ooApiUrl: 'https://api.safeguard.dmt-group.com',
}

export const safeguardGlobalRoleIds = {
    "superuser": "373e2c84-59ef-4f6a-9d96-c88fef9f70e0",
    "admin": "bef4b0b6-2c04-432c-937d-b7790e88a4bc",
    "observer": "4c2791c6-200c-4a4b-aaab-54edc584f1f2",
    "projectCreator": "e52d45ae-7be6-4fc8-bbd3-e602f4c94c74"
}
*/

// -------------------------------------------------------------------------------------------------------
// Entwicklungsserver 2024
/*
export const keycloakConfig = {
    "realm": "safeguard",
    "url": "https://security.dev.dmt-group.com",
    "clientId": "web-app",
};

const settings = {
    pyApiUrl:'http://10.0.116.90:1965',
    grafanaUrl: 'https://grafana.dev.dmt-group.com',
    nextcloudUrl: 'https://filedata.dev.dmt-group.com',
    apiUrl: process.env.NODE_ENV === 'development' ? 'http://localhost:1962' : 'https://safeguard.api.dev.dmt-group.com',
    // apiUrl: 'https://safeguard.api.dev.dmt-group.com',
    keycloakAuthUrl: keycloakConfig.url + '/realms/' + keycloakConfig.realm + '/protocol/openid-connect/token',
    logoutUrl: keycloakConfig.url + '/realms/' + keycloakConfig.realm + '/protocol/openid-connect/logout',
    logoutRedirect: process.env.NODE_ENV === 'development' ? 'http://localhost:1841/' : 'https://safeguard.dev.dmt-group.com',
    ooUrl: process.env.NODE_ENV === 'development' ? 'http://10.0.116.87:81' : 'https://office.dev.dmt-group.com',
    ooApiUrl: 'https://safeguard.api.dev.dmt-group.com',
}


export const safeguardGlobalRoleIds = {
    "superuser": "373e2c84-59ef-4f6a-9d96-c88fef9f70e0",
    "admin": "bef4b0b6-2c04-432c-937d-b7790e88a4bc",
    "observer": "4c2791c6-200c-4a4b-aaab-54edc584f1f2",
    "projectCreator": "e52d45ae-7be6-4fc8-bbd3-e602f4c94c74"
}

*/

// -------------------------------------------------------------------------------------------------------
// Entwicklungsserver Maschinenraum

export const keycloakConfig = {
    "realm": "safeguard",
    "url": "https://security.dmt-group.dev",
    "clientId": "web-app",
};

const settings = {
    pyApiUrl:'http://10.0.116.90:1965',
    grafanaUrl: 'https://grafana.dmt-group.dev',
    nextcloudUrl: 'https://nextcloud.safeguard.dmt-group.dev',
    apiUrl: process.env.NODE_ENV === 'development' ? 'http://localhost:1962' : 'https://api.safeguard.dmt-group.dev',
    // apiUrl: 'https://api.safeguard.dmt-group.dev',
    keycloakAuthUrl: keycloakConfig.url + '/realms/' + keycloakConfig.realm + '/protocol/openid-connect/token',
    logoutUrl: keycloakConfig.url + '/realms/' + keycloakConfig.realm + '/protocol/openid-connect/logout',
    logoutRedirect: process.env.NODE_ENV === 'development' ? 'http://localhost:1841/' : 'https://safeguard.dmt-group.dev',
    ooUrl: process.env.NODE_ENV === 'development' ? 'http://10.0.116.87:81' : 'https://office.dmt-group.dev',
    ooApiUrl: 'https://api.safeguard.dmt-group.dev',
}


export const safeguardGlobalRoleIds = {
    "superuser": "373e2c84-59ef-4f6a-9d96-c88fef9f70e0",
    "admin": "bef4b0b6-2c04-432c-937d-b7790e88a4bc",
    "observer": "4c2791c6-200c-4a4b-aaab-54edc584f1f2",
    "projectCreator": "e52d45ae-7be6-4fc8-bbd3-e602f4c94c74"
}


export default settings;
