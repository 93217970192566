
import React, {useEffect, useState} from 'react';
import Input from '../global/Input';
import {
    getChannelTypes,
    getMeasuringDeviceTypes,
    getSensorTypes
} from '../functions/Metrology';
import {useTranslation} from 'react-i18next';

type Props = {
    projectId: string,
    type:string,
    onChange?: (e: any) => void,
    value?:string,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    disabled?: boolean,
    list?: any
}
const TypesSelectList = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);
    // Initialisiere mit undefined, damit das Skeletonloading funktioniert
    const [list, setList] = useState<any>();
    const [types, setTypes] = useState<[]>([]);
    const [labelKey, setLabelKey] = useState('unknown');

    const makeLists = (result:any)=>{
        const tmp:any = [];
        const tmpObj: any = {};
        result.forEach((key: any) => {
            if ('channel' === props.type && key.measuring_unit !== null)
                tmp.push({value: key.id, label: key.name+ ' ['+key.measuring_unit+']'});
            else
                tmp.push({value: key.id, label: key.name});
            tmpObj[key.id] = key;
        });
        setList(tmp);
        setTypes(tmpObj);
    };

    useEffect(() =>{
        switch (props.type) {
            case 'channel':
                setLabelKey('metrologyManagement:attributes.channelType');
                if (!props.list) {
                    getChannelTypes(props.projectId).then(result => {
                        makeLists(result);
                    });
                } else {
                    makeLists(props.list);
                }
                break;
            case 'sensor':
                setLabelKey('metrologyManagement:attributes.sensorType');
                if (!props.list) {
                    getSensorTypes(props.projectId).then(result => {
                        makeLists(result);
                    });
                } else {
                    makeLists(props.list);
                }
                break;
            case 'measuring_device':
                setLabelKey('metrologyManagement:attributes.measuringDeviceType');
                if (!props.list) {
                    getMeasuringDeviceTypes(props.projectId).then(result => {
                        makeLists(result);
                    });
                } else {
                    makeLists(props.list);
                }
                break;
        }
    },[props.list]);

    return (
        <Input
            name={props.type + '_type_id'}
            onChange={(e) => {
                if (props.onChange) props.onChange(types[e.value]);
            }}
            edit={true}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            label={t(labelKey)}
            dropdownOptions={list}
            type={'dropdown'}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};
export default TypesSelectList;
