/*
 * UserEditForm.tsx
 * Author: amusiol
 * Date: 22.02.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useRef, useState, useContext} from 'react';
import RolesSelectList from './RolesSelectList';
import LanguageSelectList from '../global/LanguageSelectList';
import {Trans, useTranslation} from 'react-i18next';
import Input from '../global/Input';
import functionLibrary from '../../functions/functionLibrary';
import {useForm} from 'react-hook-form';
import {checkUserLogin, createUser, isAdminRole, isSuperuserRole, updateUser, User} from '../functions/UserManagement';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {parseJwt} from '../../services/KeycloakService';
import {showReloadPageDialog} from '../global/ReloadPageDialog';
import TimezoneSelectList from '../global/TimezoneSelectList';
import {confirmDialog} from 'primereact/confirmdialog';
import {hideWaitAnimation, showWaitAnimation} from '../global/CustomWaitAnimation';


type Props = {
    userData: User,
    projectId: string,
    onFinished: (success: boolean) => void
    setVisible: any
    type: string // 'user' or 'admin'
}

/**
 * Form for editing and creating of users
 * @param props
 * @constructor
 */
const UserEditForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common', 'users', 'input']);

    const userId = (props.userData && typeof props.userData.id !== 'undefined') ? props.userData.id : null;
    const disabled = (props.userData && typeof props.userData.id !== 'undefined');

    const checkAndSetSettings = (setting: string, value: any) => {
        if (value.length > 3) {
            checkUserLogin( setting, value).then((result) => {
                if (result.userExists === 'true')
                    showMessageOnError(t('error'), setting + ' vorhanden');
            });
        }
    };

    const defaultValues = {
        'first_name': props.userData ? props.userData.first_name : '',
        'last_name': props.userData ? props.userData.last_name : '',
        'email': props.userData ? props.userData.email : '',
        'enabled': props.userData ? props.userData.enabled : true,
        'username': props.userData ? props.userData.username : '',
        'sms': props.userData ? (props.userData.sms || '') : '',
        'company': props.userData ? (props.userData.company || '') : '',
        'language_id': props.userData ? (props.userData.language_id || navigator.language || 'de') : (navigator.language || 'de'),
        'role_id': props.userData ? props.userData.role_id : '',
        'password': '',
        'timezone': props.userData ? (props.userData.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Berlin') : (Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Berlin'),
    };

    const {control, formState: {errors}, handleSubmit} = useForm({defaultValues});

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    const _updateUser = (userId: string, data: any) => {
        const projectId = props.type === 'admin' ? 'none' : props.projectId;
        showWaitAnimation();
        updateUser(projectId, userId, props.type === 'admin', data).then(result => {
            hideWaitAnimation();
            if (result.error) {
                showMessageOnError(t('error'), result.error);
            } else {
                showMessageOnSuccess( t('success'), props.type === 'admin' ? t('userManagement:toasts.adminUpdated') : t('userManagement:toasts.userUpdated'));
                props.setVisible(false);

                // Wenn man sich selbst über die Benutzerliste eines Projektes oder über die Adminsliste editiert
                // und dann editProfile aufruft, sieht man noch die alten Angaben, da der localStorage nicht
                // aktualisert wurde. Außerdem müssen die Rechte neu geladen werden, wenn man seine eigene Rolle
                // ändert. Deswegen wird hier ein reload veranlasst.
                const token = localStorage.getItem('keycloak-token') || '';
                const tokenPayload = parseJwt(token);
                const tokenUserId = tokenPayload.sub;
                if (userId === tokenUserId) {
                    showReloadPageDialog(t, t('reloadPage.msgOwnUserChanged'));
                } else {
                    props.onFinished(true);
                }
            }
        });
    };

    const getAdminWarnMessage = (
        <React.Fragment>
            <Trans>
                <p>{t('userManagement:dialogs.addAdminDialog.warnMessage')}</p>
            </Trans>
        </React.Fragment>
    );

    const saveData = (data: any) => {
        if (userId !== null) {

            // Wenn ein Benutzer 'Superuser' oder 'Administrator' werden soll, werden alle Verknüpfungen zu den Projekten aufgelöst
            // und der Warnhinweis im Dialog angezeigt. Bei Projektersteller und Beobachter bleiben die Verknüpfungen
            if (isSuperuserRole(data.role_id) || isAdminRole(data.role_id)) {
                confirmDialog({
                    message: getAdminWarnMessage,
                    header: t('warning'),
                    icon: 'pi pi-exclamation-triangle',
                    acceptLabel: t('yes'),
                    rejectLabel: t('no'),
                    accept: () => {
                        _updateUser(userId, data);
                    },
                });
            } else {
                _updateUser(userId, data);
            }
        } else {
            showWaitAnimation();
            createUser(props.projectId, data).then(result => {
                hideWaitAnimation();
                if (result.error) {
                    showMessageOnError(t('error'), result.error);
                } else {
                    showMessageOnSuccess( t('success'), props.type === 'admin' ? t('userManagement:toasts.adminCreated') : t('userManagement:toasts.userCreated'));
                    props.onFinished(true);
                    props.setVisible(false);
                }
            });
        }
    };

    return (
        <form id='formDialog' onSubmit={handleSubmit(saveData)}>
            <div className={'card'}>
                <div className='grid'>
                    <div className='col-12 lg:col-6'>
                        <Input
                            edit={true} label={t('userManagement:attributes.firstname')} name={'first_name'}
                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('userManagement:attributes.firstname') + ' ' + t('input:required')}}
                        />
                        <Input
                            edit={true} label={t('userManagement:attributes.lastname')} name={'last_name'}
                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('userManagement:attributes.lastname') + ' ' + t('input:required')}}
                        />
                        <Input
                            edit={true} label={t('userManagement:attributes.username')} disabled={disabled} name={'username'}
                            onChange={(e: any) => checkAndSetSettings('username', e.target.value)}
                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: (userId === null) ? (t('user.username') + ' ' + t('input:required')) : false}}
                        />
                        <Input
                            edit={true} label={t('userManagement:attributes.password')} disabled={disabled} name={'password'}
                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: (userId === null) ? (t('userManagement:attributes.password') + ' ' + t('input:required')) :false}}
                        />
                        <Input
                            edit={true} label={'Email'} disabled={disabled} name={'email'}
                            tooltip={t('userManagement:tooltips.inputEmail')}
                            onChange={(e: any) => checkAndSetSettings('email', e.target.value)}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={functionLibrary.emailValidation(t)}
                        />
                        <RolesSelectList
                            label={t('userManagement:global.role')}
                            projectId={props.type === 'admin' ? 'none' : props.projectId}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('role') + ' ' + t('input:required')}}
                        />
                    </div>
                    <div className='col-12 lg:col-6'>
                        <Input
                            edit={true} label={'SMS'} name={'sms'}
                            validationControl={control} validationRules={{required: false}}
                        />
                        <Input
                            edit={true} label={t('userManagement:attributes.company')} name={'company'}
                            validationControl={control} validationRules={{required: false}}
                        />
                        <LanguageSelectList
                            label={t('language')}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('language') + ' ' + t('input:required')}}
                        />
                        <TimezoneSelectList
                            label={t('timezone')}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('timezone') + ' ' + t('input:required')}}
                        />
                        <Input
                            edit={true} label={t('userManagement:attributes.ableToLogin')} name={'enabled'}
                            type={'checkbox'} validationControl={control} validationRules={{required: false}}
                        />
                    </div>
                </div>
            </div>
        </form>
    );

};

export default UserEditForm;
