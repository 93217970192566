import keycloakfetch from '../../../functions/keycloakfetch';
import settings from '../../../config/settings';

export async function getMonitoringAppAreaConfiguration(projectId: string, monitoringAppId: string, monitoringAppTypeId: string) {
    const params = new URLSearchParams({
        'project_id': projectId,
        'monitoring_app_id': monitoringAppId,
        'monitoring_app_type_id': monitoringAppTypeId
    });
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getMonitoringAppAreaConfiguration?' + params).then(result => {
        if (result)
            retValue =  result;
    });
    const responseArr : any= [];
    retValue['area_monitoring_tasks'].map((val: any, index: number) => {
        const json = {
            'number': index + 1,
            'area_type_id': val['area_type_id'],
            'area_monitoring_task_id': val['area_monitoring_task_id'],
            'area_type_name': val['area_type_name'],
            'area_type_tag': val['area_type_tag'],
            'channel_id': val['channel_id'],
            'channel_name': val['channel_name'] !== null &&  val['channel_unit'] !== null ? val['channel_name'] + ' [' + val['channel_unit'] + ']' : val['channel_name'],
            'monitoring_app_task_id': val['monitoring_app_task_id'],
            'unit': val['channel_unit'],
            'x1': val['good_x1'],
            'x2': val['good_x2'],
            'y1': val['good_y1'],
            'y2': val['good_y2'],
            'operating_state_id': val['operating_state_id'],
            'operating_state_name': val['operating_state_name']
        };

        responseArr.push(json);
    });

    const updatedJSON = {
        responseArr,
        'operating_state_id': retValue['operating_state_id'],
        'operating_state_name': retValue['operating_state_name']
    };
    return updatedJSON;
}

export async function getMonitoringAppParameter(projectId: string, monitoringAppId: string) {
    const params = new URLSearchParams({
        'project_id': projectId,
        'monitoring_app_id': monitoringAppId,
    });
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getMonitoringAppParameterEE?' + params).then(result => {
        if (result)
            retValue = result;
    });
    const polynomfunctions: any = [];
    const thresholds: any = [];

    retValue['polynomfunctions'].map((val: any)=>{
        const json = {
            'count': val['count'],
            'function': val['function'],
            'max': val['max'],
            'min': val['min'],
            'monitoring_app_parameter_type_id_function': val['monitoring_app_parameter_type_id_function'],
            'monitoring_app_parameter_type_id_max': val['monitoring_app_parameter_type_id_max'],
            'monitoring_app_parameter_type_id_min': val['monitoring_app_parameter_type_id_min'],
            'name': val['name'],
            'unit': val['unit']
        };
        polynomfunctions.push(json);
    });

    retValue['thresholds'].map((val: any)=>{
        const json = {
            'channel_id': val['channel_id'],
            'channel_name': val['channel_name'] !== null &&  val['channel_unit'] !== null ? val['channel_name'] + ' [' + val['channel_unit'] + ']' : val['channel_name'],
            'channel_unit': val['channel_unit'],
            'monitoring_app_parameter_type_id_channel_id': val['monitoring_app_parameter_type_id_channel_id'],
            'monitoring_app_parameter_type_id_qmax': val['monitoring_app_parameter_type_id_qmax'],
            'monitoring_app_parameter_type_id_qmin': val['monitoring_app_parameter_type_id_qmin'],
            'qmax': val['qmax'],
            'qmin': val['qmin']
        };
        thresholds.push(json);
    });

    const newJson = {
        polynomfunctions,
        thresholds
    };

    return newJson;
}

export async function getOperatingStates(projectId: string) {
    const params = new URLSearchParams({
        'project_id': projectId,
    });
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getOperatingStates?' + params).then(result => {
        if (result)
            retValue = result;
    });

    return retValue;
}

export async function setMonitoringAppParameterAndAreaConfiguration(data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/setMonitoringAppParameterAndAreaConfiguration', data).then(result => {
        if (result)
            retValue = result;
    });

    return retValue;
}

export async function getCMCorrelatedValues(projectId: string, channelXId: string, channelYId: string, timestampFrom: string, timestampTo: string, interval: string){
    let retValue: any = [];
    const params = new URLSearchParams({
        'project_id': projectId,
        'channel_x_id': channelXId,
        'channel_y_id': channelYId,
        'timestamp_from': timestampFrom,
        'timestamp_to': timestampTo,
        'interval': interval
    });
    await keycloakfetch.get(settings.apiUrl + '/getCMCorrelatedValues?' + params ).then(result => {
        if (result)
            retValue = result;
    });

    return retValue;
}