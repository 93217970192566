import React, {useEffect, useState} from 'react';
import CustomDialog from '../../global/CustomDialog';
import CustomDataTable, {ColumnObject} from '../../global/CustomDataTable';
import {showMessageOnError, showMessageOnSuccess} from '../../global/CustomToast';
import CreateConfiguration from './CreateConfiguration';
import {
    getAllSVGDiagrams, getBoxValueData,
    getDoppelfilterDetails, getMappedSVGDiagram, getStatusColor,
    getSVGDiagram,
    updateSVGConfiguration
} from '../functions/DoppelfilterConfigurations';
import Input from '../../global/Input';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencil, faTrash} from '@fortawesome/free-solid-svg-icons';
import {Buffer} from 'buffer';
import {useForm} from 'react-hook-form';
import SVGComponent from './SVGComponent';
import {useTranslation} from 'react-i18next';
declare global {
    interface Window { MyNamespace: any; }
}
type ConfigurationDialogueProps = {
    projectId: string
    colorTheme: string
    modal: boolean,
    onHide: ()=>void
}
const ConfigurationDialogue = (props: ConfigurationDialogueProps)=> {
    const { t } = useTranslation(['common']);
    const [details, setDetails] = useState<any>([]);
    const [value, setValue] = useState(0);
    const [createPointDialogue, setCreatePointDialogue] = useState<boolean>(false);
    const [formType, setFormType] = useState<string>('');
    const [rowValue, setRowValue ] = useState<any>();
    const [image, setImage] = useState<string>('');
    const [imageText, setImageText] = useState<any>('');
    const [row, setRow] = useState<any>([]);
    const [componentId, setComponentId] = useState<string>('');
    const [currentActive, setCurrentActive] = useState<number>(0);
    const [sendToApi, setSendToApi] = useState<boolean>(false);
    const [channelName, setChannelName] = useState<string>('');
    const [values, setValues] = useState<any>([]);
    const monitoringAppId: any = localStorage.getItem('monitoringAppId') ? localStorage.getItem('monitoringAppId') : '';
    const monitoringAppTypeId: any = localStorage.getItem('monitoringAppTypeId') ? localStorage.getItem('monitoringAppTypeId') : '';
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const clearParamOfPoints = (points: any) => {
        points['chart_min'] = null;
        points['chart_max'] = null;
        points['channel_id'] = null;
        points['tag_name'] = '---';
        points['main_alarm_from'] = null;
        points['main_alarm_to'] = null;
        if (points['number'] === 20) {
            const box = document.querySelector('#Custom_Fill_Rect') as HTMLElement;
            if (box) {
                box.style.fill = 'gray';
                box.style.height = '28';
            }
            const element = document.querySelector('#svg-content1 #Custom_Fill_Text') as HTMLElement;
            const element1 = document.querySelector('#svg-content1 #tspan6169-9')as HTMLElement;
            if (element) {
                element.innerHTML = '';
            }
            if (element1) {
                element1.innerHTML = '';
            }
        }
        else {
            const box = document.querySelector(
                '#' + 'Custom_Box' + points['number'] + '>rect'
            ) as HTMLElement;
            if (box) {
                box.style.fill = 'gray';
            }
            let text = document.getElementById('Custom_Box' + points['number'] + '_Custom_Text');
            if (text) {
                text.style.pointerEvents = 'none';
                text.innerHTML = ''  ;
            }
            text = document.getElementById('Custom_Box' + points['number'] + '_Custom_Text0');
            if (text) {
                text.style.pointerEvents = 'none';
                text.textContent = '';
            }
        }
    };

    const columns: ColumnObject[] = [
        {field: 'number', header:t('conditionMonitoring:number'),
            filter: false, style: {maxWidth: '60px'}
        },
        {
            field: 'box_detail', header: t('conditionMonitoring:box_detail'),
            filter: false,
        },{
            field: 'tag_name', header: t('conditionMonitoring:channel_name'),
            filter:false, style:{maxWidth: '140px'}, body: (points: any)=>{
                return (<u className="cell-function update-channels" onClick={(e)=>{
                    e.stopPropagation();
                }}>
                    <FontAwesomeIcon icon={faPencil} className='ml-2' onClick={(e)=>{
                        setFormType('edit');
                        setCreatePointDialogue(true);
                        setChannelName(points['tag_name']);
                        setRowValue(points);
                    }}/>&nbsp;
                    {points['tag_name'] !== '---' && <FontAwesomeIcon icon={faTrash} className='ml-2' onClick={(e)=>{
                        clearParamOfPoints(points);
                        setValue(value + 1);
                        return;
                    }}/> }&nbsp; &nbsp;

                    {points.tag_name}</u> );
            }
        },{field: 'chart_min', header:t('conditionMonitoring:display_min'),
            filter: false, style: {maxWidth: '120px'}, editCell(e: any) {updatedNumberColumns(e['number'], 'chart_min', e['chart_min'], e['tag_name']);} ,editCellClassName:'table_input'
        },
        {field: 'chart_max', header:t('conditionMonitoring:display_max'),
            filter: false, style: {maxWidth: '120px'}, editCell(e: any) {updatedNumberColumns(e['number'], 'chart_max', e['chart_max'], e['tag_name']);},editCellClassName:'table_input'
        },
        {field: 'main_alarm_from', header:t('conditionMonitoring:alert_min'),
            filter: false, style: {maxWidth: '120px'},editCell(e: any) {updatedNumberColumns(e['number'], 'main_alarm_from', e['main_alarm_from'], e['tag_name']);}, editCellClassName:'table_input'
        },
        {field: 'main_alarm_to', header:t('conditionMonitoring:alert_max'),
            filter: false, style: {maxWidth: '120px'}, editCell(e: any) {updatedNumberColumns(e['number'], 'main_alarm_to', e['main_alarm_to'], e['tag_name']);},editCellClassName:'table_input'
        }
    ];

    const [dropdownOptions, setDropdownOptions] = useState<any>([{}]);

    const updatedNumberColumns = (rowVal: number, columnName: string, updatedValue: number, tagName:any)=>{
        if (tagName !== '---') {
            details.map((val: any) => {
                if (val['number'] === rowVal) {
                    val[columnName] = updatedValue;
                }
                return;
            });
        }
        else {
            showMessageOnError(t('error'), t('conditionMonitoring:error_channel_name'));
        }

        setSendToApi(true);
    };
    const setColor = (svgText: string) => {
        let tmp = svgText;
        if (svgText) {
            if (props.colorTheme === 'dark') {
                tmp = tmp.replaceAll('#000000', 'white');
            }
        }
        return tmp;
    };


    const setActive = (id: string) => {
        if (id === 'Custom_Box19') {
            const firstCircle = document.querySelector('#Custom_Box19') as HTMLElement;
            const secondCircle = document.querySelector('#Custom_Box19 > ellipse') as HTMLElement;
            if (firstCircle && secondCircle) {
                secondCircle.style.fill = secondCircle.style.fill === 'blue' ? '#ffffff' : '#ffffff';
                firstCircle.style.fillOpacity = firstCircle.style.fillOpacity === '0.00769228' ? '1' : '0.00769228';
                secondCircle.style.fillOpacity = secondCircle.style.fillOpacity === '0.00769228' ? '1' : '0.00769228';
            }
            return;
        }
        if (id === 'Custom_Box20') {
            const lastBox = document.querySelector(
                '#' + 'Custom_Fill_' + 'Rect'
            )as HTMLElement;
            if (lastBox) {
                lastBox.style.fill = lastBox.style.fill === 'blue' ? 'green': 'blue';
                if (lastBox.style.fill === 'blue') return;
            }
            return;
        }

        const box = document.querySelector(
            '#' + id + '>rect'
        ) as HTMLElement;
        if (box) {
            box.style.fill = box.style.fill === 'blue' ? 'gray' : 'blue';
            if (box.style.fill === 'blue') return;
        }
        const text = document.getElementById(id + '_Custom_Text');
        if (text === null) return;
        if (text?.textContent?.length === 0) return;
        if (text?.innerHTML.length > 1) {
            box.style.fill = 'green';
        } else if (text?.innerHTML.length === 1) {
            box.style.fill = 'gray';
        }
    };

    const {
        handleSubmit
    } = useForm<any>({ });
    const saveData = () => {
        let isValueOk = true;
        const valArr: any = [];
        details.map((val: any)=>{
            if (val['chart_max']) {
                if (Number(val['chart_min']) >= Number(val['chart_max'])) {
                    isValueOk = false;
                    showMessageOnError(t('error'), t('conditionMonitoring:doppelfilter_error'));
                    valArr.push(val);
                }
            }
            if (val['main_alarm_to']) {
                if (Number(val['main_alarm_from']) >= Number(val['main_alarm_to'])) {
                    isValueOk = false;
                    showMessageOnError(t('error'), t('conditionMonitoring:doppelfilter_error'));
                    valArr.push(val);
                }
                if (Number(val['main_alarm_to']) === 0) {
                    isValueOk = false;
                    showMessageOnError(t('error'), t('conditionMonitoring:doppelfilter_error'));
                    valArr.push(val);
                }
            }
            setRow(valArr);
        });
        if (isValueOk) {
            const data:any = [];
            details.map((val:any) => {
                const json = {
                    'monitoring_app_id': monitoringAppId,
                    // 'schematic_illustration_box_id': val['schematic_illustration_box_id'],
                    'monitoring_app_task_id': val['monitoring_app_task_id'],
                    'schematic_illustration_box_type_id': val['schematic_illustration_box_type_id'],
                    'tag': val['tag'],
                    'channel_id': val['channel_id'],
                    'chart_min': val['chart_min'] !== '' ? Number(val['chart_min']) : null,
                    'chart_max': val['chart_max'] !== '' ? Number(val['chart_max']) : null,
                    'characteristic_monitoring_task_id': val['characteristic_monitoring_task_id'],
                    'main_alarm_from': val['main_alarm_to'] !== '' ? Number(val['main_alarm_to']) : null,
                    'main_alarm_to': val['main_alarm_from'] !== '' ? Number(val['main_alarm_from']) : null,
                };
                data.push(json);
            });
            const finalData = {
                'project_id':props.projectId,
                'element_id':componentId,
                'monitoring_app_id':monitoringAppId,
                'schematic_illustration_type_id':image,
                'data': data
            };
            updateSVGConfiguration(finalData).then(resp =>{
                if (resp['success']) {
                    props.onHide();
                    showMessageOnSuccess(t('success'), t('conditionMonitoring:doppelfilter_configSuccess'));
                }
                else {
                    showMessageOnError(t('error'), t('conditionMonitoring:doppelfilter_configError'));
                }
            });
        }

    };

    useEffect(()=>{
        setFormType('create');
        const optionsArr: any = [];
        const url = location.hash.split('_');

        setComponentId(url[1]);

        getMappedSVGDiagram(props.projectId, url[1], monitoringAppTypeId).then(async resp=>{
            const options = {
                value: resp[0]['schematic_illustration_type_id'], label: resp[0]['name']
            };
            setImage(options.value);
            let tagArr: any = [];
            const res = await getDoppelfilterDetails(props.projectId, monitoringAppId, monitoringAppTypeId, resp[0]['schematic_illustration_type_id'], url[1]);
            const channelIds: any = [];
            res.map((val: any)=>{
                if (val['channel_id'] !== null) {
                    channelIds.push(val['channel_id']);
                }
            });
            const data: any = {
                'project_id': props.projectId,
                'monitoring_app_id': monitoringAppId,
                'channel_ids': channelIds
            };
            const statusColor = await getStatusColor(data);
            const boxData = await getBoxValueData(data);
            for (let i=1; i <=20; i++) {
                if (statusColor['data'][i] !== undefined && boxData['data'][i] !== undefined ) {
                    tagArr.push({
                        'boxId': Number(i),
                        'channelId': statusColor['data'][i]['channel_id'],
                        'upperText': statusColor['data'][i]['channel_name'].length > 10 ? statusColor['data'][i]['channel_name'].substr(0, 11) + '...' : statusColor['data'][i]['channel_name'],
                        'fullUpperText' : statusColor['data'][i]['channel_name'],
                        'lowerText': boxData['data'][i]['chart_round'] > 0 ? Number(boxData['data'][i]['value']).toFixed(boxData['data'][i]['chart_round']) : boxData['data'][i]['value'] ? boxData['data'][i]['value'] : '',
                        'unit': boxData['data'][i]['unit'] ? boxData['data'][i]['unit'] : '',
                        'boxColor': 'green',
                        'defaultColor' : 'green'
                    });
                }
                else {
                    tagArr.push({
                        'boxId': Number(i),
                        'channelId': statusColor['data'][i] !== undefined ? statusColor['data'][i]['channel_id'] : '',
                        'upperText': statusColor['data'][i] !== undefined ? statusColor['data'][i]['channel_name'] : '',
                        'lowerText':'',
                        'unit': '',
                        'boxColor': 'gray',
                        'defaultColor': 'gray'
                    });
                }
            }

            tagArr = tagArr.sort((a:any,b:any) => a['boxId'] - b['boxId']);
            setImageText(setColor(Buffer.from(resp[0]['data']).toString()));
            setValues(tagArr);

            getDoppelfilterDetails(props.projectId, monitoringAppId, monitoringAppTypeId, options.value, url[1]).then(resp=>{
                setDetails(resp);
                setDataLoaded(true);
            });
        });

        getAllSVGDiagrams(props.projectId, monitoringAppTypeId).then(resp => {
            resp.map((val: any) => {
                optionsArr.push({
                    value: val['id'], label: val['name']
                });
            });
            setDropdownOptions(optionsArr);
        });
    },[]);
    return (<>
        <CustomDialog header={t('conditionMonitoring:doppelfilter_image_detail')} onCancel={()=> props.onHide()} visible={props.modal} maximized={true} showFooter={true} formName={'configurationForm'}>

            <form id='configurationForm' onSubmit={(e) => {
                e.preventDefault();
                const element: any = e.target;
                if (element.id !== 'configurationForm') return;
                saveData();
                return;
            }}>
                <div className="col-12 md:col-6">
                    <Input
                        edit={true}
                        placeholder={'Please select image'}
                        label={t('conditionMonitoring:select_doppelfilter_image')}
                        name={'filter_image'}
                        dropdownOptions={dropdownOptions}
                        value={image}
                        type={'dropdown'}
                        onChange={
                            (e)=>{
                                setImage(e.value);
                                getSVGDiagram(props.projectId, e.value).then(resp=>{
                                    const image = setColor(Buffer.from(resp[0]['data']).toString());
                                    setImageText(image);
                                    const el =  document.getElementById('svg-content1');
                                    if (el) {
                                        el.innerHTML = image;
                                        const lastBox = document.querySelector(
                                            '#' + 'Custom_Fill_' + 'Rect'
                                        )as HTMLElement;
                                        if (lastBox) {
                                            lastBox.style.fill = 'gray';
                                        }
                                        const element = document.getElementById('Custom_Fill_Text0');
                                        const element1 = document.getElementById('tspan6169');
                                        if (element) {
                                            element.innerHTML = '';
                                        }
                                        if (element1) {
                                            element1.innerHTML = '';
                                        }

                                        Array.from(document.querySelectorAll('#svg-content1 [id^=Custom_Box]').values()).map((box: any, index: number) => {
                                            box.style.cursor = 'pointer';
                                            const element = document.querySelector(
                                                '#' + box.id + '>rect'
                                            ) as HTMLElement;
                                            if (element)
                                                element.style.fill = 'gray';
                                            let text = document.getElementById(box.id + '_Custom_Text');
                                            if (text) {
                                                text.style.pointerEvents = 'none';
                                                text.innerHTML = '';
                                            }
                                            text = document.getElementById(box.id + '_Custom_Text0');
                                            if (text) {
                                                text.style.pointerEvents = 'none';
                                                text.textContent = '';
                                            }

                                        });
                                    }
                                });
                                getDoppelfilterDetails(props.projectId, monitoringAppId, monitoringAppTypeId, e.value, componentId).then(resp=>{
                                    setDetails(resp);
                                    setDataLoaded(true);
                                });
                            }
                        }
                        validationRules={{
                            required: false,
                        }}
                    />
                </div>

                {createPointDialogue && <CreateConfiguration projectId={props.projectId} modal={createPointDialogue} onHide={()=>{
                    {
                        setCreatePointDialogue(false);
                        setRowValue([]);
                    }}} formType={formType} rowValue={rowValue} channelName={channelName} details={details}
                onReturn={async (value: any)=>{
                    details.map((val: any) =>{
                        if (val['number'] === value['number']) {
                            val['tag_name'] = value['channel_name'];
                            val['channel_id'] = value['channel_id'];
                        }
                        return;
                    });
                    setCreatePointDialogue(false);
                    const data: any = {
                        'project_id': props.projectId,
                        'monitoring_app_id': monitoringAppId,
                        'channel_ids': [value['channel_id']]
                    };
                    const boxValue = await getBoxValueData(data);
                    const boxValueResponse = boxValue['data'][+Object.keys(boxValue['data'])];
                    if (boxValueResponse !== undefined) {
                        if (rowValue['number'] === 20) {
                            const box = document.querySelector('#Custom_Fill_Rect') as HTMLElement;
                            if (box) {
                                box.style.fill = 'green';
                                box.style.height = (boxValueResponse['value'] * 0.28).toString();
                            }
                            const element = document.querySelector('#svg-content1 #Custom_Fill_Text') as HTMLElement;
                            const element1 = document.querySelector('#svg-content1 #tspan6169-9')as HTMLElement;
                            if (element) {
                                element.innerHTML = boxValueResponse['value'].toFixed(2) + ' ' + value['unit'];
                            }
                            if (element1) {
                                element1.innerHTML = value['channel_name'].length > 10 ? value['channel_name'].substr(0, 14) + '...' : value['channel_name'];
                            }
                        }
                        else {
                            const box = document.querySelector(
                                '#' + 'Custom_Box' + rowValue['number'] + '>rect'
                            ) as HTMLElement;
                            if (box) {
                                box.style.fill = 'green';
                            }
                            let text = document.getElementById('Custom_Box' + rowValue['number'] + '_Custom_Text');
                            if (text) {
                                text.style.pointerEvents = 'none';
                                text.innerHTML = boxValueResponse['value'].toFixed(2) + ' ' + value['unit'] ;
                            }
                            text = document.getElementById('Custom_Box' + rowValue['number'] + '_Custom_Text0');
                            if (text) {
                                text.style.pointerEvents = 'none';
                                text.textContent = value['channel_name'].length > 10 ? value['channel_name'].substr(0, 11) + '...' : value['channel_name'];
                            }
                        }
                    }
                    else {
                        if (rowValue['number'] === 20) {
                            const box = document.querySelector('#Custom_Fill_Rect') as HTMLElement;
                            if (box) {
                                box.style.fill = 'gray';
                            }
                            const element = document.querySelector('#svg-content1 #Custom_Fill_Text') as HTMLElement;
                            const element1 = document.querySelector('#svg-content1 #tspan6169-9')as HTMLElement;
                            if (element) {
                                element.innerHTML = '';
                            }
                            if (element1) {
                                element1.innerHTML = value['channel_name'].length > 10 ? value['channel_name'].substr(0, 14) + '...' : value['channel_name'];
                            }
                        }
                        else {
                            const box = document.querySelector(
                                '#' + 'Custom_Box' + rowValue['number'] + '>rect'
                            ) as HTMLElement;
                            if (box) {
                                box.style.fill = 'gray';
                            }
                            let text = document.getElementById('Custom_Box' + rowValue['number'] + '_Custom_Text');
                            if (text) {
                                text.style.pointerEvents = 'none';
                                text.innerHTML = '';
                            }
                            text = document.getElementById('Custom_Box' + rowValue['number'] + '_Custom_Text0');
                            if (text) {
                                text.style.pointerEvents = 'none';
                                text.textContent = value['channel_name'].length > 10 ? value['channel_name'].substr(0, 11) + '...' : value['channel_name'];
                            }
                        }
                    }
                }}

                />}

                <div className="grid justify-content-center mt-4">
                    <div className="col-3">
                        <div className="card h-full">
                            {/* <div id='svg-content1'  style={{textAlign: 'center'}}/>*/}
                            <SVGComponent divId={'svg-content1'} svgImage={imageText} elementClicked={(index: number)=>{}}
                                values={values} onLastBoxClick={()=>{}}
                            />
                        </div>
                    </div>

                    <div className="col-8">
                        <div className="card h-full">
                            {image &&
                                <CustomDataTable id={'point-table'} columns={columns} editable={true} sortable={true} value={details} ableToUpdate={false}
                                    editMode={'cell'} selectionMode={'single'} selection={row} dataloaded={dataLoaded} onRowClick={(e)=>{
                                        setRow(!e.data);
                                        if (currentActive === e.data['number']) {
                                            // if (e.originalEvent.target.className.includes('p-editable-column')) {
                                            //     return;
                                            // }
                                            if (e.originalEvent.cancelable === true) {
                                                return;
                                            }
                                            setActive('Custom_Box' + e.data['number']);
                                            setCurrentActive(0);
                                        } else {
                                            setActive('Custom_Box' + currentActive);
                                            setCurrentActive(e.data['number']);
                                            setActive('Custom_Box' + e.data['number']);
                                        }
                                    }}
                                /> }
                        </div>

                    </div>
                </div>


            </form>
           
        </CustomDialog>
    </>);
};


export default ConfigurationDialogue;
