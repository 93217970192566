/*
 * Scanner.tsx copy of Cesium.tsx
 * Author: fwunderlich
 * Date: 26.04.2024
 *
 * Copyright: DMT GmbH & Co. KG
 */
import React, {useState, useEffect, useRef, createRef} from 'react';
import {Button} from 'primereact/button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMaximize} from '@fortawesome/free-solid-svg-icons';

type IFrameProps = {
    url: any,
    show: boolean
}

const Scanner = (props: IFrameProps): JSX.Element => {
    const componentRef: any = useRef();
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        setHeight(componentRef.current.offsetHeight);
        setWidth(componentRef.current.offsetWidth);
    });

    useEffect(() => {
        if (height > 0 && width > 0) {
            var iframeElement: any = document.querySelector('iframe');

            iframeElement.style.width = width + 'px';
            iframeElement.style.height = height + 'px';
        }
    }, [height, width]);

    return (<div ref={componentRef} className={'iframe-wrapper scanner'} style={{display: props.show ? 'inline' : 'none'}}>
            <div className={'scanner-buttons'}>
                <Button
                    style={{float: 'right'}} className={'p-button m-1'}
                    onClick={() => {
                        // set iframe to fullscreen
                        if (iframeRef && iframeRef.current) {
                            const elem = iframeRef.current;
                            // @ts-ignore
                            elem.requestFullscreen().catch((e) => {
                                console.log('error: ', e);
                            });
                        }
                    }}
                > <FontAwesomeIcon className="sidebar-icon" icon={faMaximize}/>
                </Button>
            </div>
            <iframe
                ref={iframeRef}
                allowFullScreen={true}
                style={{'borderRadius': '6px'}}
                src={props.url}
                width="100%"
                height="100%"
                id={'scanner_iframe'}
                className=""
                frameBorder="0"
                onLoad={(e) => {
                    // props.removeGrafanaElements();
                }}
            />
        </div>
    );
};

export default Scanner;
