/*
 * PlotOverview.tsx
 * Author: fwunderlich
 * Date: 22.12.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from 'primereact/button';
import Input from '../global/Input';
import {getUserFromLocalStorage} from '../functions/Global';
import {TabPanel, TabView} from 'primereact/tabview';
import PlotTemplate, {PlotTemplateType} from './PlotTemplate';
import {Card} from 'primereact/card';
import {classNames} from 'primereact/utils';
import {channelsForMeasurement, getPlotOptions} from './PlotFunctions';
import {channel} from 'diagnostics_channel';
import {getChannelsBySensorId} from '../functions/Metrology';
import PlotChart from './PlotChart';

type Props = {
    projectId: string
};

const closableTab = false;

const PlotOverview = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common', 'metrologyManagement', 'customDataTable']);

    const now = new Date();
    const minusSevenDays = new Date(now);
    minusSevenDays.setDate(now.getDate() - 7);
    const [plotView, setPlotView] = useState<number>(1);
    const [gridColumn, setGridColumn] = useState<string>(' col-12');

    const [selectedTemplate, setSelectedTemplate] = useState<any>({
        plotOptions: [],
        from: minusSevenDays,
        to: now,
        combineCharts: 0,
        minMaxFromChannel: false,
        combineValues: true
    });

    const [searchPlotname, setSearchPlotname] = useState<string>('');
    const [activePlotIndex, setActivePlotIndex] = useState<number>(0);
    const [plotCards, setPlotCards] = useState<any>(undefined);
    const [disableMoveTab, setDisableMoveTab] = useState<boolean>(false);

    const [rerenderPlotCard, setRerenderPlotCard] = useState<boolean>(false);

    const [urlSensorId, setUrlSensorId] = useState<string>('');
    const [channelHash, setChannelHash] = useState<any>(undefined);
    const [list, setList] = useState<any>(undefined);
    const [loadInitial, setLoadInitial] = useState<boolean>(false);
    const [showSkeleton, setShowSkeleton]=useState(true);
    const [plotOptions, setPlotOptions] = useState<any>(undefined);

    useEffect(() => {
        let urlSensorIdTmp = '';
        if (window.location.hash.indexOf('_') > -1) {
            urlSensorIdTmp = window.location.hash.substring(window.location.hash.indexOf('_') + 1, window.location.hash.length);
        }

        if (urlSensorIdTmp !== '') {
            setUrlSensorId(urlSensorIdTmp);
        } else {
            const pv = getUserFromLocalStorage().settings[props.projectId] ? getUserFromLocalStorage().settings[props.projectId]['plotView'] : 1;
            setPlotView(pv);
            setLoadInitial(true);
        }

        const asyncFunc = async () => {
            await channelsForMeasurement(props.projectId, setChannelHash, undefined);
        };
        asyncFunc();
    }, []);

    // useEffect(() => {
    //     if (urlSensorId !== '') {
    //         const asyncFunc = async () => {
    //             await channelsForMeasurement(props.projectId, setChannelHash, undefined);
    //         };
    //         asyncFunc();
    //     }
    // }, [urlSensorId]);

    useEffect(() => {
        if (channelHash !== undefined && urlSensorId !== '') {
            const asyncFunc = async () => {

                const channels = await getChannelsBySensorId(props.projectId, urlSensorId);

                let values: any = {};

                for (let i = 0; i < channels.length; i++) {
                    const res = getChannelValue(channels[i].channel_id);
                    values = {...values, ...res};
                }

                setList(values);

                const options = getPlotOptions(values, channelHash);

                const tmp = {...selectedTemplate};

                tmp.plotOptions = options;
                tmp.combineCharts = 1;

                setSelectedTemplate(tmp);
                setPlotOptions(options);
            };
            asyncFunc();
        }
    }, [channelHash]);

    useEffect(() => {
        setPlotCards([]);
        setShowSkeleton(true);
        if (selectedTemplate.plotOptions.length > 0) {
            setPlotCards(getPlotCards());
        }
    }, [selectedTemplate]);

    useEffect(() => {
        setGridColumn(getGridColumn());
    }, [plotView]);

    /*
    useEffect(() => {
        console.log('search', searchPlotname);
    }, [searchPlotname]);
     */

    /*
    useEffect(() => {
        console.log('list', list);
    }, [list]);
     */

    const getChannelValue = (Id: string) => {
        let result: any = {};

        const ch = channelHash[Id];

        result[Id] = ch;

        result[Id].checked = false;
        result[Id].partialChecked = true;

        if (result[Id].isChannel) {
            result[Id].checked = true;
            result[Id].partialChecked = false;
        }

        if (ch.parentId) {
            const res = getChannelValue(ch.parentId);
            result = {...result, ...res};
        }

        return result;
    };

    const getGridColumn = (): string => {
        let result = '';

        switch (plotView) {
            case 1: {
                result = ' col-12';
                break;
            }
            case 2: {
                result = selectedTemplate.plotOptions.length >= 2 ? ' col-6' : ' col-12';
                break;
            }
            case 3: {
                result = selectedTemplate.plotOptions.length >= 3 ? ' col-4' : selectedTemplate.plotOptions.length >= 2 ? ' col-6' : ' col-12';
                break;
            }
            case 4: {
                result = selectedTemplate.plotOptions.length >= 4 ? ' col-3' : selectedTemplate.plotOptions.length >= 3 ? ' col-4' : selectedTemplate.plotOptions.length >= 2 ? ' col-6' : ' col-12';
                break;
            }
        }

        return result;
    };

    const moveTab = (left: boolean) => {
        setDisableMoveTab(true);
        // if not left, then right
        const tmp = {...selectedTemplate};
        const newActivePlotIndex = activePlotIndex + (left ? -1 : 1);
        move(tmp, activePlotIndex, newActivePlotIndex);

        setTimeout(() => {
            setSelectedTemplate(tmp);
            setActivePlotIndex(newActivePlotIndex);
            setDisableMoveTab(false);
        }, 200);
    };

    const move = (input: any, from: number, to: number) => {
        const elm = input.plotOptions.splice(from, 1)[0];

        input.plotOptions.splice(to, 0, elm);
    };

    const closeTab = (e: any) => {
        const tmp = JSON.parse(JSON.stringify(selectedTemplate));

        tmp.plotOptions.splice(e.index, 1);

        let values = {};

        for (let i = 0; i < tmp.plotOptions.length; i++) {
            for (let j = 0; j < tmp.plotOptions[i].channel_ids.length; j++) {
                const res = getChannelValue(tmp.plotOptions[i].channel_ids[j]);
                values = {...values, ...res};
            }
        }

        setList(values);

        setSelectedTemplate(tmp);
    };

    const getPlotCards = () => {
        setRerenderPlotCard(!rerenderPlotCard);

        switch (selectedTemplate.combineCharts) {
            case 0: {
                // combine nothing
                return selectedTemplate.plotOptions.map((options: any, index: number) => {
                    if (!options.graph_type) {
                        options.graph_type = options.graph_types && options.graph_types.length > 0 ? options.graph_types[0] : '';
                    }

                    const headerText = options.sensor_name;

                    return (<TabPanel closable={closableTab}
                                      key={index}
                                      className={classNames((headerText.toLowerCase().indexOf(searchPlotname.toLowerCase()) >= 0 || (options.type && options.type.toLowerCase().indexOf(searchPlotname.toLowerCase()) >= 0)) ? '' : 'hidden', 'mt-2')}
                                      header={headerText}>{
                        options.channel_ids.map((channel_id: string, index: number) => {
                            return (<><PlotChart
                                key={'pc_' + index}
                                projectId={props.projectId}
                                title={options.sensor_name + ' (' + options.type + ')'}
                                channelIds={[channel_id]}
                                // type={options.type}
                                graphTypes={['lines']}
                                index={index}
                                // firstIndex={index === 0}
                                // lastIndex={index === selectedTemplate.plotOptions.length - 1}
                                fromDate={selectedTemplate.from}
                                toDate={selectedTemplate.to}
                                showTimeRangePicker={false}
                                minMaxFromChannel={selectedTemplate.minMaxFromChannel}
                                combineValues={selectedTemplate.combineValues}
                                selectedGraphType={'lines'}
                                rerender={rerenderPlotCard}
                                showSkeleton={showSkeleton}
                                onChangeGraphType={(graphType, index) => {
                                    const tmp = selectedTemplate;

                                    tmp.plotOptions[index].graph_type = graphType;

                                    setSelectedTemplate(tmp);
                                }}/>
                                <br/></>);
                        })
                    }
                    </TabPanel>);
                });
            }

            case 1: {
                // combine sensors
                return selectedTemplate.plotOptions.map((options: any, index: number) => {
                    if (!options.graph_type) {
                        options.graph_type = options.graph_types && options.graph_types.length > 0 ? options.graph_types[0] : '';
                    }

                    const headerText = options.sensor_name;
                    return (<TabPanel closable={closableTab}
                                      key={index}
                                      className={classNames((headerText.toLowerCase().indexOf(searchPlotname.toLowerCase()) >= 0 || (options.type && options.type.toLowerCase().indexOf(searchPlotname.toLowerCase()) >= 0)) ? '' : 'hidden', 'mt-2')}
                                      header={headerText}>
                        <PlotChart
                            key={'pc_' + index}
                            projectId={props.projectId}
                            title={options.sensor_name + ' (' + options.type + ')'}
                            channelIds={options.channel_ids}
                            // type={options.type}
                            graphTypes={options.graph_types}
                            index={index}
                            // firstIndex={index === 0}
                            // lastIndex={index === selectedTemplate.plotOptions.length - 1}
                            fromDate={selectedTemplate.from}
                            toDate={selectedTemplate.to}
                            showTimeRangePicker={false}
                            minMaxFromChannel={selectedTemplate.minMaxFromChannel}
                            combineValues={selectedTemplate.combineValues}
                            selectedGraphType={options.graph_type}
                            rerender={rerenderPlotCard}
                            showSkeleton={showSkeleton}
                            onChangeGraphType={(graphType, index) => {
                                const tmp = selectedTemplate;

                                tmp.plotOptions[index].graph_type = graphType;

                                setSelectedTemplate(tmp);
                            }}/>
                    </TabPanel>);
                });
            }

            case 2: {
                // combine all
                const channelIds: string[] = [];
                let graphTypes: any[] = [];

                const tmp = selectedTemplate;

                // tmp.sort((a, b) => a.sensor_name.localeCompare(b.sensor_name, 'en', {numeric: true}));

                for (let i = 0; i < tmp.plotOptions.length; i++) {
                    const item = tmp.plotOptions[i];
                    // console.log(item.graph_types);

                    if (i === 0) {
                        graphTypes.push(...item.graph_types);
                    } else {
                        for (let j = graphTypes.length - 1; j >= 0; j--) {
                            const gt = graphTypes[j];

                            if (item.graph_types) {
                                if (item.graph_types.indexOf(gt) < 0) {
                                    graphTypes = graphTypes.splice(gt, 1);
                                }
                            }
                        }
                    }

                    channelIds.push(...item.channel_ids);
                }

                if (graphTypes.length === 0) {
                    graphTypes.push('lines');
                }

                return (<TabPanel closable={closableTab}
                                  key={'0'}
                                  className={classNames('mt-2')}
                                  header={t('measurementData:attributes.combined')}>
                    <PlotChart
                        key={'pc_0'}
                        projectId={props.projectId}
                        title={t('measurementData:attributes.combined')}
                        channelIds={channelIds}
                        // type={'lines'}
                        graphTypes={graphTypes}
                        index={0}
                        fromDate={selectedTemplate.from}
                        toDate={selectedTemplate.to}
                        showTimeRangePicker={false}
                        minMaxFromChannel={selectedTemplate.minMaxFromChannel}
                        combineValues={selectedTemplate.combineValues}
                        selectedGraphType={selectedTemplate.plotOptions.length > 0 ? selectedTemplate.plotOptions[0].graph_type : 'lines'}
                        rerender={rerenderPlotCard}
                        showSkeleton={showSkeleton}
                        onChangeGraphType={(graphType, index) => {
                            const tmp = selectedTemplate;

                            for (let i = 0; i < tmp.plotOptions.length; i++) {
                                tmp.plotOptions[i].graph_type = graphType;
                            }

                            setSelectedTemplate(tmp);
                        }}/>
                </TabPanel>);
            }
        }
        return null;
    };

    return (
        <>
            <div className="card grid grid-nogutter no-field-margin">
                <PlotTemplate
                    projectId={props.projectId}
                    loadInitial={loadInitial}
                    selectedChannels={list}
                    plotOptions={plotOptions}
                    onSelectedTemplateChanged={(template: any) => {
                        setShowSkeleton(true);
                        setSelectedTemplate(template);
                    }}
                    type={PlotTemplateType.Charts}
                />
            </div>
            <div className="card">
                <div>
                    {selectedTemplate.plotOptions.length > 0 && <div className="grid justify-content-end w-full">
                        <div>
                            <Input name="searchPlotname" edit={true} value={searchPlotname}
                                   placeholder={t('customDataTable:search.placeholder')} onChange={(e) => {
                                setSearchPlotname(e.target.value);
                                setActivePlotIndex(0);
                            }}/>
                        </div>
                    </div>}
                    {selectedTemplate.plotOptions.length > 0 &&
                    <div className="mt-1 mr-1" style={{'float': 'right', 'position': 'relative', 'zIndex': 99}}>
                        {selectedTemplate.combineCharts !== 2 && <>
                            <Button className="p-button-sm p-button ml-1" label="<"
                                    tooltip={disableMoveTab || activePlotIndex === 0 ? '' : t('measurementData:tooltips.sortLeft')}
                                    tooltipOptions={{'position': 'left'}}
                                    disabled={disableMoveTab || activePlotIndex === 0}
                                    onClick={() => {
                                        moveTab(true);
                                    }}/>
                            <Button className="p-button-sm p-button ml-1" label=">"
                                    tooltip={disableMoveTab || activePlotIndex === selectedTemplate.plotOptions.length - 1 ? '' : t('measurementData:tooltips.sortRight')}
                                    tooltipOptions={{'position': 'left'}}
                                    disabled={disableMoveTab || activePlotIndex === selectedTemplate.plotOptions.length - 1}
                                    onClick={() => {
                                        moveTab(false);
                                    }}
                            />
                        </>}
                    </div>}
                    {selectedTemplate.plotOptions.length > 0 &&
                    <TabView activeIndex={activePlotIndex}
                             onTabChange={(e) => {
                                 setActivePlotIndex(e.index);
                             }}
                             onTabClose={(e: any) => {
                                 closeTab(e);
                             }}
                    >
                        {plotCards}
                    </TabView>}
                    {selectedTemplate.plotOptions.length === 0 &&
                    <div className=" w-full">{t('metrologyManagement:selectChannel')}</div>}
                </div>
            </div>
        </>
    );
};

export default PlotOverview;
