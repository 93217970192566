/*
 * MeasuringTypeSelectList.tsx
 * Author: lnappenfeld
 * Date: 19.06.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import Input from '../../global/Input';
import {useTranslation} from 'react-i18next';

type Data = {
    value: number,
    label: string
}

type Props = {
    label: string,
    value?: number | null,
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    name?: string,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    disabled?: boolean,
    showClear?: boolean,
}

export const MeasuringTypeSelectList = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const options = [
        {value: 1, label: t('metrologyManagement:dropdowns.measuringType.defaultMeasuring')},
        {value: 2, label: t('metrologyManagement:dropdowns.measuringType.synchronousMeasuring')},
        {value: 3, label: t('metrologyManagement:dropdowns.measuringType.continuallyMeasuring')},
    ] as Data[];

    return (
        <Input
            name={props.name ? props.name : 'measuring_type'}
            edit={true}
            showClear={typeof props.showClear === 'boolean' ? props.showClear : false}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            label={props.label}
            value={props.value}
            dropdownOptions={options} type={'dropdown'}
            onChange={(e: any) => {
                if (props.setValue) props.setValue(e.value);
                if (props.onChange) props.onChange(e);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};
