import React, {useEffect, useState} from 'react';
import Input from '../global/Input';
import DataLinkPortSelectList from './DataLinkPortSelectList';
import TimezoneSelectList from '../global/TimezoneSelectList';
import CustomDialog from '../global/CustomDialog';
import {Button} from 'primereact/button';
import {useTranslation} from 'react-i18next';

type PropsChannel = {
    projectId: string,
    fields: any,
    manager: string,
    fieldIndex: string,
    countIndexSensor: number,
    countIndex: number
    setFormValues: any,
    projectLocations: any,
    channelList: any,
    setSelectedChannel: any,
    wizardType: string,
    projectDataLink: string,
    defaultValues: any,
    setRefreshComponents: any,
    setDefaultValues: any
}
export const WizardChannelEditForm = (propsChannel: PropsChannel) => {
    const {t} = useTranslation(['common']);
    const [openDialog, setOpenDialog] = useState(false);
    const [dummy, setDummy] = useState<any>();
    const borderDefaultColor = 'lightgray';
    const [borderColor, setBorderColor] = useState(borderDefaultColor);
    const setFormValuesChannel = (name: string, value: any) => {
        propsChannel.setFormValues(propsChannel.fieldIndex, name, value, propsChannel.countIndexSensor, propsChannel.countIndex);
    };
    useEffect(() => {
        setBorderColor(borderDefaultColor);
        if (propsChannel.fields.port !== '')
            setBorderColor('#6ebd00');
        else if (propsChannel.fields.active)
            setBorderColor('#6ebd00');
    }, [propsChannel.fields.active, propsChannel.fields.port]);
    useEffect(() => {
        setBorderColor(borderDefaultColor);
        if (propsChannel.fields.port !== '')
            setBorderColor('#6ebd00');
        else if (propsChannel.projectDataLink === '')
            setBorderColor('#6ebd00');

    }, [ propsChannel.projectDataLink]);

    // @ts-ignore
    const mainFormFields = (<>
        <div className={'col-6'} key={'mainFields1'+propsChannel.countIndexSensor+''+propsChannel.countIndex}>
            <Input
                name={'channel_type_id'}
                onChange={(e) => {
                    if (propsChannel.countIndex === 0) {
                        propsChannel.setSelectedChannel(e.value);
                    }
                    setFormValuesChannel('channel_type_id', e.value);
                }}
                edit={true} disabled={propsChannel.wizardType !== 'channel'}
                label={t('channel') + ' (' + (propsChannel.countIndexSensor + 1) + '.' + (propsChannel.countIndex + 1) + ')'}
                dropdownOptions={propsChannel.channelList}
                type={'dropdown'}
                value={propsChannel.fields.channel_type_id}/>
            <Input
                key={'name'+propsChannel.countIndexSensor+''+propsChannel.countIndex}
                edit={true}
                label={t('name')}
                name={'name'}
                onChange={(e) => {
                    setFormValuesChannel('name', e.target.value);
                    setDummy(e.target.value);
                }}
                value={propsChannel.fields.name}/>
            <Input
                edit={true}
                label={t('unit')}
                name={'unit'}
                onChange={(e) => {
                    setFormValuesChannel('unit', e.target.value);
                    setDummy(e.target.value);
                }}
                value={propsChannel.fields.unit}
            />
        </div>
        <div className={'col-6'} key={'mainFields2'+propsChannel.countIndexSensor+''+propsChannel.countIndex}>
            {propsChannel.projectDataLink === '' &&
                <Input
                    edit={true}
                    className={'p-checkbox-box p-highlight p-checkbox-icon p-c pi pi-check'}
                    type={'checkbox'}
                    value={propsChannel.fields.active}
                    label={t('channel.anlegen')}
                    onChange={(e) => {
                        console.log(e);
                        setFormValuesChannel('active', e.target.value);
                        setDummy(e.target.value);
                    }}
                    name={'channel_' + propsChannel.countIndexSensor + '_' + propsChannel.countIndex}/>}
            {propsChannel.manager === 'portmaster' && <>
                {
                    propsChannel.projectDataLink !== '' &&
                    <DataLinkPortSelectList
                        projectId={propsChannel.projectId}
                        dataLinkId={propsChannel.projectDataLink}
                        channelId={null}
                        value={propsChannel.fields.port}
                        label={'port'}
                        onChange={(e) => {
                            setFormValuesChannel('port', e.value);
                            setDummy(e.value);
                        }}/>
                }
            </>}
            <Input
                edit={true} label={t('description')} name={'description'} type={'textarea'}
                value={propsChannel.fields.description}
                onChange={(e) => {
                    setFormValuesChannel('description', e.target.value);
                    setDummy(e.target.value);
                }}/>
        </div>
    </>);
    const extendedFormFields = (<>
        <div className={'col-6'}>
            <TimezoneSelectList
                label={t('timezone')}
                value={propsChannel.fields.timezone}
                onChange={(e) => {
                    setFormValuesChannel('timezone', e.target.value);
                    setDummy(e.target.value);
                }}
            />
            <Input
                edit={true}
                label={t('active')}
                name={'is_active'}
                type={'checkbox'}
                onChange={(e) => {
                    setFormValuesChannel('is_active', e.target.value);
                    setDummy(e.target.value);
                }}
                value={propsChannel.fields.is_active}/>
            <Input
                edit={true}
                label={t('visible')}
                name={'is_visible'}
                type={'checkbox'}
                onChange={(e) => {
                    setFormValuesChannel('is_visible', e.target.value);
                    setDummy(e.target.value);
                }}
                value={propsChannel.fields.is_visible}/>
        </div>
        <div className={'col-6'}>
            <Input
                edit={true}
                label={t('channels:maxAllowedDataAgeInHours')}
                name={'max_allowed_data_age_in_hours'}
                type={'number'}
                onChange={(e) => {
                    setFormValuesChannel('max_allowed_data_age_in_hours', e.value);
                }}
                value={propsChannel.fields.max_allowed_data_age_in_hours}/>
            <Input
                edit={true}
                label={t('channels:chartMin')}
                name={'chart_min'}
                type={'number'}
                value={propsChannel.fields.chart_min}
                onChange={(e) => {
                    setFormValuesChannel('chart_min', e.value);
                }}
            />
            <Input
                edit={true}
                label={t('channels:chartMax')}
                name={'chart_max'}
                type={'number'}
                value={propsChannel.fields.chart_max}
                onChange={(e) => {
                    setFormValuesChannel('chart_max', e.value);
                }}
            />
            <Input
                edit={true}
                label={t('channels:chartRound')}
                name={'chart_round'}
                type={'number'}
                value={propsChannel.fields.chart_round}
                onChange={(e) => {
                    setFormValuesChannel('chart_round', e.value);

                }}
            />
            <Input
                edit={true}
                label={'media_link'}
                name={'media_link'}
                value={propsChannel.fields.media_link}
                onChange={(e) => {
                    setFormValuesChannel('media_link', e.target.value);
                    setDummy(e.target.value);
                }}
            />
        </div>
    </>);
    // todo: if condition monitoring add logical channel settings
    return (<div key={'cdiv'+propsChannel.countIndexSensor+''+propsChannel.countIndex}>
        {
            <CustomDialog
                showFooter={false}
                visible={openDialog}
                header={t('Metrology:editDialog.header')}
                onCancel={() => setOpenDialog(false)}
                formName={'formMetrology'}>
                <div className={'grid'}>
                    {mainFormFields}
                    {extendedFormFields}
                </div>
            </CustomDialog>
        }
        <div className={'card border-1'}  key={'cbdiv'+propsChannel.countIndexSensor+''+propsChannel.countIndex} style={{borderColor: borderColor}}>
            <div className={'grid'}  key={'cbgdiv'+propsChannel.countIndexSensor+''+propsChannel.countIndex}>
                {mainFormFields}
            </div>
            <Button
                key={'button' +propsChannel.countIndexSensor+''+propsChannel.countIndex} type={'button'}
                value={'more'} label={'weitere Einstellungen'}
                onClick={() => {
                    setOpenDialog(true);
                }}/>
            &nbsp;
            <Button
                key={'addButton' + propsChannel.countIndexSensor+''+propsChannel.countIndex} type={'button'}
                value={'add'} label={'copy Channel'}
                onClick={() => {
                    // Copy Channel Data
                    // Copy Sensor Data
                    const newChannel = { ... propsChannel.fields};
                    newChannel.port = '';
                    propsChannel.setRefreshComponents(true);
                    const tmp = {... propsChannel.defaultValues};
                    tmp.sensors[propsChannel.countIndexSensor].channels.push(newChannel);
                    propsChannel.setDefaultValues(tmp);
                    console.log(propsChannel.defaultValues.sensors);
                }}/>
        </div>
    </div>);
};