/*
 * MonitoringTasksOverview.tsx
 * Author: lnappenfeld
 * Date: 20.01.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */
import React, {useEffect, useState} from 'react';
import CustomDataTable, {ColumnObject} from '../../global/CustomDataTable';
import {useTranslation} from 'react-i18next';
import {CustomConfirmDialog} from '../../global/CustomConfirmDialog';
import {checkPermission, getGroupedItems} from '../../../functions/functionLibrary';
import {permissions} from '../../../config/permissions';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {filterTriStateCheckBox} from '../../global/filter';
import {getMonitoringTasks, MonitoringTask} from '../../functions/Notification';
import {MonitoringTaskLimitsOverview, MonitoringTaskLimitsOverviewType} from './MonitoringTaskLimitsOverview';
import CustomDialog, {CustomDialogHeaderType} from '../../global/CustomDialog';
import MonitoringTaskForm from './MonitoringTaskForm';
import {hideWaitAnimation, showWaitAnimation} from '../../global/CustomWaitAnimation';

export enum MonitoringTaskOverviewType {
    Default = 0,
    NotificationWizard = 1,
}

type Props = {
    projectId: any,
    type: MonitoringTaskOverviewType,
    showHeader: boolean,
    monitoringTasks?: MonitoringTask[],
    elementId?: string
    reload?: boolean,
    setReload?: (reload: boolean )=> void
    selectedMonitoringTasks?: MonitoringTask[],
    setSelectedMonitoringTasks?: (data: MonitoringTask[]) => void
    editMonitoringTask?: boolean,
    setEditMonitoringTask?: (data: boolean) => void
}
export const MonitoringTasksOverview = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    const [monitoringTasks, setMonitoringTasks] = useState<any[]>(props.monitoringTasks ? props.monitoringTasks : []);

    const [selectedMonitoringTasks, setSelectedMonitoringTasks] = useState<MonitoringTask[]>([]);
    const [editMonitoringTask, setEditMonitoringTask] = useState<boolean>(false);

    // arrays for filter dropdowns
    const [elements, setElements] = useState<any[]>([]);
    const [channels, setChannels] = useState<any[]>([]);
    const rotationalSpeedModeOptions = [
        {value: 1, name: t('alarmManagement:attributes.rotationalSpeedModeFixed')},
        {value: 2, name: t('alarmManagement:attributes.rotationalSpeedModeOperatingState')},
    ];
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const _setMonitoringTasks = () => {
        const tmplist:any = [];
        showWaitAnimation();
        getMonitoringTasks(props.projectId).then(result => {
            for (let i = 0; i < result.length; i++) {
                // Füge die Unterkomponente nur hinzu, wenn die Überwachungsaufgabe auch Grenzwerte hat
                if (result[i].has_monitoring_task_limits)
                    result[i]['expandableElement'] = (
                        <div className={'card'}>
                            <MonitoringTaskLimitsOverview
                                type={MonitoringTaskLimitsOverviewType.MonitoringTaskTable}
                                projectId={props.projectId}
                                monitoringTaskId={result[i].monitoring_task_id}
                                showHeader={true}
                            />
                        </div>
                    );
                if (props.elementId === result[i].element_id)
                    tmplist.push(result[i]);
            }
            if (props.elementId) {
                setMonitoringTasks(tmplist);
                // filter dropdown values
                setElements(getGroupedItems(tmplist, 'element_id', 'element_name'));
                setChannels(getGroupedItems(tmplist, 'channel_id', 'channel_name'));
            } else {
                setMonitoringTasks(result);
                // filter dropdown values
                setElements(getGroupedItems(result, 'element_id', 'element_name'));
                setChannels(getGroupedItems(result, 'channel_id', 'channel_name'));
            }
            setDataLoaded(true);
            hideWaitAnimation();
        });
    };

    useEffect(() => {
        if (!props.monitoringTasks) {
            _setMonitoringTasks();
        } else {
            setElements(getGroupedItems(props.monitoringTasks, 'element_id', 'element_name'));
            setChannels(getGroupedItems(props.monitoringTasks, 'channel_id', 'channel_name'));
        }
    }, []);

    // useEffect(() => {
    //     console.log('elementId: ', props.elementId)
    //     _setMonitoringTasks();
    // }, [props.elementId]);

    useEffect(() => {
        // Lade neu, z.B. wenn Elemente gelöscht wurden
        if (props.reload) {
            _setMonitoringTasks();
            if (props.setReload) props.setReload(false);
        }
    }, [props.reload]);

    // Setze die lokalen States für den Dialog zum Anlegen eines NEUEN Channels
    // Könnte auch direkt an den Dialog übergeben werden, so muss aber nur auf die lokalen States geprüft werden
    useEffect(() => {
        if (props.editMonitoringTask) setEditMonitoringTask(props.editMonitoringTask);
    }, [props.editMonitoringTask]);

    useEffect(() => {
        if (props.selectedMonitoringTasks) setSelectedMonitoringTasks(props.selectedMonitoringTasks);
    }, [props.selectedMonitoringTasks]);

    const filterCheckboxIsEnabled = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), t('enabled'), t('disabled'));
    };

    const columns: ColumnObject[] = [{
        field: 'is_active', header: t('active'), style: {maxWidth: '100px'},
        filter: true, filterElement: filterCheckboxIsEnabled,
    },{
        field: 'monitoring_task_name', header: t('name'),
        filter: true, filterField: 'monitoring_task_name'
    },{
        field: 'element_name', header: t('metrologyManagement:attributes.sensorElementName'),
        filter: true, filterField: 'element_id', filterDropdownValues: elements
    },{
        field: 'channel_name', header: t('channel'),
        filter: true, filterField: 'channel_id', filterDropdownValues: channels
    },{
        field: 'rotational_speed_mode', header: t('alarmManagement:attributes.rotational_speed_mode'),
        filter: true, filterDropdownValues: rotationalSpeedModeOptions, body: (mt: MonitoringTask) => {
            // 1: Drehzahl wird von Komponente "fest" übernommen
            // 2: Drehzahl wird aus Drehzahlfenster des Drehzahlkanals des Betriebszustandes übernommen.
            switch (mt.rotational_speed_mode) {
                case 1:
                    return t('alarmManagement:attributes.rotationalSpeedModeFixed');
                case 2:
                    return t('alarmManagement:attributes.rotationalSpeedModeOperatingState');
                default:
                    return t('error');
            }
        }
    },{
        field: 'last_measuring', header: t('alarmManagement:attributes.last_measuring'), type: 4,
        filter: false
    }];

    const filters = {
        'is_active': {
            value: true,
            matchMode: FilterMatchMode.EQUALS
        },
        'monitoring_task_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'rotational_speed_mode': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'element_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'channel_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
    };

    const isDefaultType = () => {
        return props.type === MonitoringTaskOverviewType.Default;
    };

    const getSelectionMode = () => {
        switch (props.type) {
            case MonitoringTaskOverviewType.Default:
                return checkPermission(permissions.editNotification) ? {selectionMode: 'multiple'} : {};
            case MonitoringTaskOverviewType.NotificationWizard:
                return checkPermission(permissions.editNotification) ? {selectionMode: 'multiple'} : {};
            // TODO: (props.elementId) ? {selectionMode: 'multiple'} : {}
            default:
                return {};
        }
    };

    const dynamicSelectionMode = getSelectionMode();

    return (
        <>
            <CustomDataTable
                {...dynamicSelectionMode}
                id='monitoringTasks'
                expandable={true}
                columns={columns}
                editable={isDefaultType() && checkPermission(permissions.editNotification) ? true : false}
                sortable={true}
                value={monitoringTasks}
                paginator={monitoringTasks.length > 100}
                selection={selectedMonitoringTasks}
                onSelectionChange={(e: any) => {
                    if (props.setSelectedMonitoringTasks) props.setSelectedMonitoringTasks(e.value);
                }}
                onEditClick={(data: MonitoringTask) => {
                    if (props.setSelectedMonitoringTasks) props.setSelectedMonitoringTasks([data]);
                    setSelectedMonitoringTasks([data]);
                    setEditMonitoringTask(true);
                }}
                headerTitle={props.showHeader ? t('alarmManagement:global.monitoringTasks') : ''}
                ableToDelete={checkPermission(permissions.editMetrology)}
                ableToUpdate={checkPermission(permissions.editMetrology)}
                filters={filters}
                dataloaded={dataLoaded}
                showExportCSV={true}
            />
            {
                <CustomDialog
                    header={selectedMonitoringTasks.length > 0
                        ? t('alarmManagement:dialogs.editMonitoringTaskDialog.header')
                        : t('alarmManagement:dialogs.createMonitoringTaskDialog.header')
                    }
                    headerType={selectedMonitoringTasks.length > 0
                        ? CustomDialogHeaderType.Update
                        : CustomDialogHeaderType.Create
                    }
                    formName={'formMonitoringTask'}
                    visible={editMonitoringTask}
                    onCancel={() => {
                        if (props.setEditMonitoringTask) props.setEditMonitoringTask(false);
                        setEditMonitoringTask(false);
                    }}
                    onHide={() => {
                        if (props.setEditMonitoringTask) props.setEditMonitoringTask(false);
                        setEditMonitoringTask(false);
                    }}
                    style={{ width: '80vw'}} // , height: '65vw' }}
                >
                    <MonitoringTaskForm
                        projectId={props.projectId}
                        onFinished={() => {
                            if (props.setEditMonitoringTask) props.setEditMonitoringTask(false);
                            if (props.setSelectedMonitoringTasks) props.setSelectedMonitoringTasks([]);
                            setEditMonitoringTask(false);
                            setSelectedMonitoringTasks([]);
                            _setMonitoringTasks();
                        }}
                        monitoringTask={selectedMonitoringTasks.length > 0 ? selectedMonitoringTasks[0] : null}
                    />
                </CustomDialog>
            }
        </>
    );
};
