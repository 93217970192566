import React from 'react';
import {useTranslation} from 'react-i18next';
import CustomDialog, {CustomDialogHeaderType} from '../global/CustomDialog';
import SensorForm from './SensorForm';


type Props = {
    projectId: string,
    selectedSensors: any,
    setSelectedSensors: any,
    editSensor: boolean,
    setEditSensor: (data: boolean) => void,
    setReloadSensors: (data: boolean) => void,
}

export const SensorDialogEditForm = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);
    return (
        <CustomDialog
            header={props.selectedSensors.length > 0 ? t('metrologyManagement:dialogs.editSensorDialog.header') : t('metrologyManagement:dialogs.createSensorDialog.header')}
            headerType={props.selectedSensors.length > 0 ? CustomDialogHeaderType.Update : CustomDialogHeaderType.Create}
            formName={'formSensor'}
            className={'h-full w-4'}
            onCancel={() => {
                if (props.setEditSensor) props.setEditSensor(false);
                props.setEditSensor(false);
            }}
            onHide={() => {
                if (props.setEditSensor) props.setEditSensor(false);
                props.setEditSensor(false);
            }}
            visible={props.editSensor}
        >
            <SensorForm
                projectId={props.projectId}
                sensorId={props.selectedSensors.length > 0 ? props.selectedSensors[0].sensor_id : null}
                onFinished={()=>{
                    props.setEditSensor(false);
                    props.setSelectedSensors([]);
                    props.setReloadSensors(true);
                }}
            />
        </CustomDialog>
    );

};

