/*
 * ProjectTypeSelectList.tsx
 * Author: lnappenfeld
 * Date: 10.03.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState, useEffect} from 'react';
import Input from '../global/Input';
import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';
import {getPortMasters} from '../functions/Project';

type Props = {
    label: string,
    value?: string;
    setValue?: (e: any) => void,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    mayEditProject: boolean
}

const PortMasterSelectList = (props: Props):JSX.Element => {

    // Initialisiere mit undefined, damit das Skeletonloading funktioniert
    const [list, setList] = useState();

    useEffect(() => {
        getPortMasters().then(result => {
            const tmp: any = [];
            result.forEach((key: any) => {
                tmp.push({
                    value: key.id,
                    label: key.name,
                });
            });
            setList(tmp);

        });
    }, []);

    return (
        <Input
            edit={typeof props.mayEditProject === 'boolean' ? props.mayEditProject : true}
            label={props.label}
            //value={props.value}
            dropdownOptions={list}
            onChange={(e: any) => {
                if (props.setValue) props.setValue(e.value);
            }}
            scrollHeight={'350px'}
            name={'safeguard_portmaster'} type={'multiselect'}
            validationControl={props.validationControl} validationErrorMessage={props.validationErrorMessage}
            validationRules={{required: false}}
        />
    );
};

export default PortMasterSelectList;