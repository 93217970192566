/*
 * UserManagement.tsx
 * Author: lnappenfeld
 * Date: 14.11.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {checkPermission} from '../functions/functionLibrary';
import {permissions} from '../config/permissions';
import ViewButtons from '../components/global/ViewButtons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperPlane, faUser, faUserSecret, faUserTag} from '@fortawesome/free-solid-svg-icons';
import UsersComponents from '../components/userManagement/UsersComponents';
import {
    buttonAddAdmin,
    buttonAddUser,
    buttonCreateAction,
    buttonCreateAdmin,
    buttonCreateRole,
    buttonCreateUser,
    buttonMoveUsersToRole,
    buttonRemoveActions, buttonRemoveGlobalUsers,
    buttonRemoveUsersFromProject,
    makeButtonItem
} from '../components/global/viewButtonSelection';
import Roles from '../components/userManagement/Roles';
import Actions from '../components/userManagement/Actions';
import {Action} from '../components/functions/Notification';
import {Role} from '../components/functions/UserManagement';
import CustomDialog, {CustomDialogHeaderType} from '../components/global/CustomDialog';
import RoleForm from '../components/userManagement/RoleForm';
import ActionEditForm from '../components/userManagement/ActionEditForm';
import {MoveUsersToRoleForm} from '../components/userManagement/MoveUsersToRoleForm';
import LayoutMainContentTabMenu from '../components/global/LayoutMainContentTabMenu';
import {Nullable} from '../components/functions/Global';
import {ActionsOverview} from '../components/userManagement/ActionsOverview';
import {GlobalRolesOverview} from '../components/userManagement/GlobalRolesOverview';


type Props = {
    project: any
}

const UserManagement = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [isSuperuser, setIsSuperuser] = useState<boolean>(false);

    /** **/
    const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
    const [selectedGlobalUsers, setSelectedGlobalUsers] = useState<any[]>([]);

    const [editUserVisible, setEditUserVisible] = useState<boolean>(false);
    const [addUsersVisible, setAddUsersVisible] = useState<boolean>(false);
    const [showDeleteUsersDialog, setShowDeleteUsersDialog] = useState<boolean>(false);
    const [showMoveUserToRoleDialog, setShowMoveUsersToRoleDialog] = useState<boolean>(false);
    const [mayEditUM, setMayEditUM] = useState<boolean>(false);
    const [maySeeAllUsers, setMaySeeAllUsers] = useState<boolean>(false);
    const [reloadUsers, setReloadUsers] = useState<boolean>(false);

    /** **/
    // Kein Multiselect vorgesehen
    const [selectedRole, setSelectedRole] = useState<Nullable<Role>>(null);
    const [reloadRoles, setReloadRoles] = useState<boolean>(false);
    const [showRoleDialog, setShowRoleDialog] = useState<boolean>(false);

    /** **/
    const [selectedActions, setSelectedActions] = useState<Action[]>([]);
    const [reloadNotificationActions, setReloadNotificationActions] = useState<boolean>(true);
    const [showActionDialog, setShowActionDialog] = useState<boolean>(false);
    const [showDeleteActionsDialog, setShowDeleteActionsDialog] = useState<boolean>(false);

    const [viewButtonsRerender, setViewButtonsRerender] = useState<boolean>(true);

    useEffect(() => {
        if (checkPermission(permissions.editUM)) {
            setMayEditUM(true);
        }
        if (checkPermission(permissions.seeAllUsers)) {
            setMaySeeAllUsers(true);
        }
        if (checkPermission(permissions.isSuperuser)) {
            setIsSuperuser(true);
        }
    }, []);

    const editUser = () => {
        setEditUserVisible(true);
        setSelectedUsers([]);
        setSelectedGlobalUsers([]);
    };

    // Add user who is not already added to this project
    const addUser = () => {
        setAddUsersVisible(true);
    };

    const menuItems = [
        {
            id: 'users',
            label: t('userManagement:global.users'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faUser}/>,
            component: <UsersComponents
                type={'user'}
                projectId={props.project ? props.project.id : null}
                reloadUsers={reloadUsers}
                setReloadUsers={setReloadUsers}
                mayEditUM={mayEditUM}
                editUserVisible={editUserVisible}
                setEditUserVisible={setEditUserVisible}
                addUsersVisible={addUsersVisible}
                setAddUsersVisible={setAddUsersVisible}
                showDeleteUsersDialog={showDeleteUsersDialog}
                setShowDeleteUsersDialog={setShowDeleteUsersDialog}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                setShowActionDialog={setShowActionDialog}
                setSelectedAction={(action) => setSelectedActions([action])}
                setSelectedRole={setSelectedRole}
                setShowRoleDialog={setShowRoleDialog}
            />,
            visible: props.project !== null,
            buttons: (
                <ViewButtons title={t('')} items={[
                    makeButtonItem(buttonRemoveUsersFromProject, t, () => {
                        setShowDeleteUsersDialog(true);
                    }, mayEditUM, !(selectedUsers && selectedUsers.length > 0)),
                    makeButtonItem(buttonMoveUsersToRole, t, () => {
                        setShowMoveUsersToRoleDialog(true);
                    }, mayEditUM, !(selectedUsers && selectedUsers.length > 0)),
                    makeButtonItem(buttonCreateUser, t, editUser, mayEditUM),
                    makeButtonItem(buttonAddUser, t, addUser, maySeeAllUsers),
                    makeButtonItem(buttonCreateRole, t, () => {
                        setShowRoleDialog(true);
                    }, mayEditUM),
                    makeButtonItem(buttonCreateAction, t, () => {
                        setSelectedActions([]);
                        setShowActionDialog(true)
                    }, mayEditUM)
                ]} rerender={viewButtonsRerender}
                />
            )
        },
        {
            id: 'roles',
            label: t('userManagement:global.roles'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faUserTag}/>,
            component: <Roles
                project={props.project}
                mayEditUM={mayEditUM}
                selectedRole={selectedRole}
                setSelectedRole={setSelectedRole}
                reloadRoles={reloadRoles}
                setReloadRoles={setReloadRoles}
                showRoleDialog={showRoleDialog}
                setShowRoleDialog={setShowRoleDialog}
            />,
            visible: props.project !== null,
            buttons: (
                <ViewButtons title={t('')} items={[
                    makeButtonItem(buttonCreateRole, t, () => {
                        setShowRoleDialog(true);
                    }, mayEditUM)
                ]} rerender={viewButtonsRerender}
                />
            )
        },
        {
            id: 'actions',
            label: t('userManagement:global.actions'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faPaperPlane}/>,
            component: <Actions
                projectId={props.project ? props.project.id : null}
                reloadNotificationActions={reloadNotificationActions}
                setReloadNotificationActions={setReloadNotificationActions}
                showActionDialog={showActionDialog}
                setShowActionDialog={setShowActionDialog}
                selectedActions={selectedActions}
                setSelectedActions={setSelectedActions}
                showDeleteActionsDialog={showDeleteActionsDialog}
                setShowDeleteActionsDialog={setShowDeleteActionsDialog}
                setReloadUsers={setReloadUsers}
            />,
            visible: props.project !== null,
            buttons: (
                <ViewButtons title={t('')} items={[
                    makeButtonItem(buttonRemoveActions, t, () => {
                        setShowDeleteActionsDialog(true);
                    }, mayEditUM, !(selectedActions && selectedActions.length > 0)),
                    makeButtonItem(buttonCreateAction, t, () => {
                        setSelectedActions([]);
                        setShowActionDialog(true);
                    }, mayEditUM)
                ]} rerender={viewButtonsRerender}
                />
            )
        },
        {
            id: 'admins',
            label: t('userManagement:global.globalUsers'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faUserSecret}/>,
            component: <UsersComponents
                type={'admin'}
                projectId={props.project ? props.project.id : null}
                reloadUsers={reloadUsers}
                setReloadUsers={setReloadUsers}
                mayEditUM={mayEditUM}
                editUserVisible={editUserVisible}
                setEditUserVisible={setEditUserVisible}
                addUsersVisible={addUsersVisible}
                setAddUsersVisible={setAddUsersVisible}
                showDeleteUsersDialog={showDeleteUsersDialog}
                setShowDeleteUsersDialog={setShowDeleteUsersDialog}
                selectedUsers={selectedGlobalUsers}
                setSelectedUsers={setSelectedGlobalUsers}
            />,
            // [14.08.2024] Die globalen Benutzer sind nur noch für Superuser zusehen
            visible: isSuperuser,
            buttons: (
                <ViewButtons title={t('')} items={[
                    makeButtonItem(buttonRemoveGlobalUsers, t, () => {
                        setShowDeleteUsersDialog(true);
                    }, mayEditUM, !(selectedGlobalUsers && selectedGlobalUsers.length > 0)),
                    makeButtonItem(buttonCreateAdmin, t, editUser, mayEditUM),
                    makeButtonItem(buttonAddAdmin, t, addUser, mayEditUM),
                ]} rerender={viewButtonsRerender}
                />
            )
        },
        {
            id: 'globalRoles',
            label: t('userManagement:global.globalRoles'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faUserTag}/>,
            component: <GlobalRolesOverview/>,
            // [14.08.2024] Die globalen Rollen sind nur noch für Superuser zusehen
            visible: props.project === null && isSuperuser
        },
    ];

    return (
        <>
            {/* 0: Users, 3: Admins*/}
            <LayoutMainContentTabMenu items={menuItems} activeIndex={props.project ? 0 : 3} onTabChange={() => {
                setViewButtonsRerender(!viewButtonsRerender);
            }}/>
            {/** All usable dialogs in this view **/}
            <CustomDialog
                onHide={() => {
                    setShowMoveUsersToRoleDialog(false);
                }}
                visible={showMoveUserToRoleDialog}
                header={t('userManagement:dialogs.moveUsersToRoleDialog.header')}
                onCancel={() => {
                    setShowMoveUsersToRoleDialog(false);
                }}
                formName={'moveUsersToRoleDialog'}
            >
                <MoveUsersToRoleForm
                    selectedUsers={selectedUsers}
                    projectId={props.project ? props.project.id : null}
                    onReady={() => {
                        setSelectedUsers([]);
                        setShowMoveUsersToRoleDialog(false);
                        setReloadUsers(true);
                    }}
                />
            </CustomDialog>
            {showRoleDialog && // Enforce mounting
            <CustomDialog
                onHide={() => {
                    setShowRoleDialog(false);
                }}
                visible={showRoleDialog}
                header={selectedRole ? t('userManagement:dialogs.editRoleDialog.header') : t('userManagement:dialogs.createRoleDialog.header')}
                headerType={selectedRole ? CustomDialogHeaderType.Update : CustomDialogHeaderType.Create}
                onCancel={() => {
                    setSelectedRole(null);
                    setShowRoleDialog(false);
                }}
                formName={'formDialog'}
            >
                <RoleForm
                    projectId={props.project ? props.project.id : null}
                    role={selectedRole}
                    onReady={() => {
                        setSelectedRole(null);
                        setShowRoleDialog(false);
                        setReloadRoles(true);
                    }}
                />
            </CustomDialog>
            }
            {/* Muss auf die oberste Ebene, weil es nicht nur in ActionsOverview, sondern auch in UserComponents benötigt wird,*/}
            {/* wenn von dort aus eine neue Aktion angelegt oder eine bestehende verändert wird*/}
            { props.project &&
                <CustomDialog
                    header={selectedActions.length > 0 && selectedActions[0].action_id ? t('userManagement:dialogs.editActionDialog.header') : t('userManagement:dialogs.createActionDialog.header')}
                    headerType={selectedActions.length > 0 && selectedActions[0].action_id ? CustomDialogHeaderType.Update : CustomDialogHeaderType.Create}
                    onCancel={() => {
                        setShowActionDialog(false);
                    }}
                    visible={showActionDialog}
                    onHide={() => setShowActionDialog(false)}
                    formName={'formEditAction'}
                    style={{ width: '400px'}}
                >
                    <ActionEditForm
                        projectId={props.project.id}
                        onFinished={() => {
                            setReloadNotificationActions(true);
                            setReloadUsers(true); // Falls eine Aktion von der Userliste angelegt wurden
                        }}
                        actionData={selectedActions.length > 0 ? selectedActions[0] : null}
                        setVisible={setShowActionDialog}
                    />
                </CustomDialog>
            }
        </>
    );
};

export default UserManagement;
