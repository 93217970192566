/*
 * PlotComponent.tsx
 * Author: fwunderlich
 * Date: 02.05.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import PlotChart from '../plot/PlotChart';
import PlotChannelSelectList from '../plot/PlotChannelSelectList';
import {useTranslation} from 'react-i18next';
import {TabPanel, TabView} from 'primereact/tabview';
import {classNames} from 'primereact/utils';

type Props = {
    projectId: string
}

export const PlotComponent = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common', 'measurementData']);

    const [channelValues, setChannelValues] = useState<any>({});
    const [plotOptions, setPlotOptions] = useState<any[]>([]);
    const [plotCards, setPlotCards] = useState<any>(undefined);

    const [fromDate, setFromDate] = useState<Date>(new Date('2020-01-06 00:00:00'));
    const [toDate, setToDate] = useState<Date>(new Date('2020-01-06 23:59:59'));

    // useEffect(() => {
    //     // useEffect ONLY FOR DEVELOPMENT
    //     setPlotOptions([{
    //         'channel_ids': [
    //             '00000000-0000-0000-0000-0000000008c2'
    //         ],
    //         'sensor_name': 'KS23',
    //         'type': 'Unbekannt',
    //         'graph_types': [
    //             'lines'
    //         ],
    //         'checked': true,
    //         'graph_type': 'lines'
    //     }]);
    // }, []);

    useEffect(() => {
        setPlotCards(getPlotCards());
    }, [plotOptions]);

    const getPlotCards = () => {
        console.log(plotOptions);
        return plotOptions.map((options: any, index: number) => {
            if (!options.graph_type) {
                options.graph_type = options.graph_types && options.graph_types.length > 0 ? options.graph_types[0] : '';
            }

            const headerText = options.sensor_name;

            return (<TabPanel
                key={index}
                className={classNames('mt-2')}
                header={headerText}>{
                options.channel_ids.map((channel_id: string, index: number) => {
                    return (<>
                        <PlotChart key={'pc_' + index} projectId={props.projectId} channelIds={[channel_id]}
                                   title={options.sensor_name + ' (' + options.type + ')'}
                                   titleAddChannelName={true}
                                   fromDate={fromDate} toDate={toDate}
                                   combineValues={true} index={index}
                                   showTimeRangePicker={true} selectedGraphType={'lines'}
                                   minMaxFromChannel={false} edit={true}
                                   chartHeight={550}
                        />
                        <br/></>);
                })
            }
            </TabPanel>);
        });

        return null;
    };

    return (
        <div>
            <PlotChannelSelectList projectId={props.projectId} label={t('common:sensors')} values={channelValues}
                                   onChange={(plotOptions, rawData) => {
                                       setPlotOptions(plotOptions);
                                       setChannelValues(rawData);
                                   }}/>
            <TabView>
                {plotCards}
            </TabView>
        </div>);
};

export default PlotComponent;
