/*
 * MonitoringTaskForm.tsx
 * Author: lnappenfeld
 * Date: 20.07.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Input from '../../global/Input';
import {useForm} from 'react-hook-form';
import {Nullable} from '../../functions/Global';
import {showMessageOnError, showMessageOnSuccess} from '../../global/CustomToast';
import {
    createMonitoringTask,
    getMonitoringTaskLimits,
    getNotificationsForMonitoringTask,
    getNotificationTaskMonitoringTaskMap,
    MonitoringTask,
    MonitoringTaskLimit,
    Notification,
    updateMonitoringTask
} from '../../functions/Notification';
import ChannelSelectList from '../../metrology/ChannelSelectList';

import {ElementTreeSelectList} from '../../metrology/ElementTreeSelectList';
import {Channel, getChannel, getElementsAsTree} from '../../functions/Metrology';
import {hideWaitAnimation, showWaitAnimation} from '../../global/CustomWaitAnimation';
import {OperatingStateSelectList} from '../../metrology/simon2/OperatingStateSelectList';
import {MonitoringTaskRotationalSpeedTypeSelectList} from './MonitoringTaskRotationalSpeedTypeSelectList';
import {MonitoringTaskLimitsOverview, MonitoringTaskLimitsOverviewType} from './MonitoringTaskLimitsOverview';
import {ChannelSelectTableDialog, ChannelSelectTableDialogType} from '../ChannelSelectTableDialog';


type Props = {
    projectId: any,
    monitoringTask: Nullable<MonitoringTask>,
    onFinished: (success: boolean) => void
}

const MonitoringTaskForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [elements, setElements] = useState<any>([]);
    const [selectedChannel, setSelectedChannel] = useState<Nullable<Channel>>(null);

    // Für die Darstellung, in welchen Benachrichtigungsaufgaben diese Überwachungsaufgabe berücksichtigt wird
    const [notifications, setNotifications] = useState<Nullable<Notification[]>>(null);
    const [selectedNotificationIds, setSelectedNotificationIds] = useState<Nullable<string[]>>(null);

    const [showChannelSelectTableDialog, setShowSelectChannelTableDialog] = useState<boolean>(false);

    const [monitoringTaskLimits, setMonitoringTaskLimits] = useState<MonitoringTaskLimit[]>( []);

    const monitoringTaskId = props.monitoringTask ? props.monitoringTask.monitoring_task_id : null;

    const defaultValues = {
        'monitoring_task_name': props.monitoringTask ? props.monitoringTask.monitoring_task_name : null,
        'element_id': props.monitoringTask ? props.monitoringTask.element_id : null,
        'channel_id': props.monitoringTask ? props.monitoringTask.channel_id : null,
        'operating_state_id': props.monitoringTask ? props.monitoringTask.operating_state_id : null,
        'description': props.monitoringTask ? props.monitoringTask.description : null,
        'is_active': props.monitoringTask ? props.monitoringTask.is_active : true,
        'rotational_speed_mode': props.monitoringTask ? props.monitoringTask.rotational_speed_mode : 1,
        'rotational_speed_source_id': props.monitoringTask ? props.monitoringTask.rotational_speed_source_id : null,
        'rotational_speed_value': props.monitoringTask ? props.monitoringTask.rotational_speed_value : null,
        'rotational_speed_width': props.monitoringTask ? props.monitoringTask.rotational_speed_width : null
    };

    const {control, formState: {errors}, handleSubmit, reset, setValue, getValues, watch, trigger} = useForm({defaultValues});

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    watch('is_active');
    watch('rotational_speed_mode');
    watch('channel_id');

    const _setSelectedChannel = async () => {
        const channelId = props.monitoringTask ? props.monitoringTask.channel_id : null;
        if (channelId)
            getChannel(props.projectId, channelId).then((result: any) => {
                setSelectedChannel(result);
            });
    };

    const _setMonitoringTaskLimits = async () => {
        if (monitoringTaskId)
            getMonitoringTaskLimits(props.projectId, monitoringTaskId).then((result: any) => {
                setMonitoringTaskLimits(result);
            });
    };

    const areArraysIdentical = (arr1: Nullable<any[]>, arr2: Nullable<any[]>) => {
        if (arr1 === null || arr2 === null) {
            return true;
        }
        if (arr1.length !== arr2.length) {
            return false;
        }
        const idSet1 = new Set(arr1.map(obj => obj.id));
        return arr2.every(obj => idSet1.has(obj.id));
    };

    const _setNotificationTaskMonitoringTaskMap = async (elementId: string) => {
        const previousNotifications = notifications;
        await getNotificationsForMonitoringTask(props.projectId, elementId).then(_notifications => {
            const notifications: any = [];
            _notifications.forEach((key: Notification) => {
                notifications.push({
                    // key: key.id,
                    value: key.id,
                    label: key.name + ' (' + key.notification_type_name + ')',
                });
            });
            setNotifications(notifications);

            if (monitoringTaskId) {
                // Fall: Editiere eine Überwachungsaufgabe
                // Lese dazu die Benachrichtigungsaufgaben aus notification_task_characteristic_monitoring_task aus und selektiere
                // diese in der MultiSelectList
                getNotificationTaskMonitoringTaskMap(props.projectId, null, monitoringTaskId).then(ntmtMap => {
                    const _selectedNotificationIds: any[] = [];
                    notifications.forEach((notification: any) => {
                        const foundObject = ntmtMap.find((ntmt: any) => notification.value === ntmt.notification_task_id);
                        if (foundObject) {
                            _selectedNotificationIds.push(notification.value);
                        }
                    });

                    // Wenn ein Knoten ausgewählt wird, an dem ganz anderen Benachrichtigungsregeln hängen, werden
                    // wieder wie unten, standardmäßig alle möglichen Benachrichtigungsregeln selektiert
                    const areIdentical = areArraysIdentical(previousNotifications, notifications);
                    if (!areIdentical) {
                        notifications.forEach((notification: any) => {
                            _selectedNotificationIds.push(notification.value);
                        });
                    }
                    setSelectedNotificationIds(_selectedNotificationIds);

                });
            } else {
                // Fall: Erstelle eine neue Überwachungsaufgabe
                // Selektiere automatisch alle möglichen Benachrichtigungen, damit die Überwachungsaufgabe standardmäßig
                // von den Benachrichtigungsregeln berücksichtigt werden
                const _selectedNotificationIds: any[] = [];
                notifications.forEach((notification: any) => {
                    _selectedNotificationIds.push(notification.value);
                });
                setSelectedNotificationIds(_selectedNotificationIds);
            }
        });
    };

    useEffect(() => {
        (async () => {
            showWaitAnimation();
            await _setElements();
            await _setMonitoringTaskLimits();
            if (props.monitoringTask) {
                await _setNotificationTaskMonitoringTaskMap(props.monitoringTask.element_id);
            }
            await _setSelectedChannel();
            hideWaitAnimation();
        })();
    }, []);

    const _setElements = async () => {
        return await getElementsAsTree(props.projectId, 'sensor').then(result => {
            setElements(result);
            return result;
        });
    };

    const onChannelChange = (channel: Channel) => {
        setValue('channel_id', channel.channel_id);
        // @ts-ignore
        setValue('operating_state_id', channel.logical_channel_settings?.operating_state_id);
        if (channel.logical_channel_settings?.operating_state_id === null) {
            setValue('rotational_speed_source_id', null);
        }

        setSelectedChannel(channel);
        setShowSelectChannelTableDialog(false);
        // Damit der Validationtext verschwindet, falls vorher einer da war.
        // trigger();
    };

    const onElementIdChange = async (event: any) => {
        const elementId = event.value;
        setValue('element_id', elementId);

        // Muss vorher einmal geleert werden, damit die MultiSelectList richtig angezeigt wird...
        setSelectedNotificationIds([]);
        // Aktualisiere die options und die selektierten Daten der MultiSelectList
        await _setNotificationTaskMonitoringTaskMap(elementId);
    };

    const saveMonitoringTask = (data: any) => {
        // Rename monitoring_task_name to name
        data['name'] = data['monitoring_task_name'];
        delete data['monitoring_task_name'];

        data['monitoring_task_limits'] = monitoringTaskLimits;
        data['notification_ids'] = selectedNotificationIds;

        if (monitoringTaskId) {
            const dataAddId = {'monitoring_task_id': monitoringTaskId};
            data = {...dataAddId, ...data};
            showWaitAnimation();
            updateMonitoringTask(props.projectId, data).then(result => {
                hideWaitAnimation();
                if (!result.error) {
                    showMessageOnSuccess(t('success'), t('alarmManagement:toasts.monitoringTaskUpdated'));
                    props.onFinished(true);
                } else {
                    showMessageOnError(t('error'), result.error);
                }
            });
        } else {
            showWaitAnimation();
            createMonitoringTask(props.projectId, data).then(result => {
                hideWaitAnimation();
                if (!result.error) {
                    showMessageOnSuccess(t('success'), t('alarmManagement:toasts.monitoringTaskCreated'));
                    props.onFinished(true);
                } else {
                    showMessageOnError(t('error'), result.error);
                }
            });
        }
    };

    return (
        <>
            <form id='formMonitoringTask' onSubmit={handleSubmit(saveMonitoringTask)}>
                <div className='grid card'>
                    <div className='col-12 lg:col-6'>
                        <Input
                            edit={true} label={t('name')} name={'monitoring_task_name'}
                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('name') + ' ' + t('input:required')}}
                        />
                        <div className={'grid'}>
                            <div className={'col-10'}>
                                <Input
                                    edit={true} label={t('description')} name={'description'} type={'textarea'}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                />
                            </div>
                            <div className={'col-2'}>
                                <Input
                                    edit={true} label={t('active')} name={'is_active'} type={'checkbox'}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                />
                            </div>
                        </div>
                        <ElementTreeSelectList
                            type={'sensor'}
                            projectId={props.projectId}
                            list={elements}
                            value={getValues('element_id')}
                            label={t('metrologyManagement:attributes.sensorElementName')}
                            name={'element_id'}
                            onChange={onElementIdChange}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('metrologyManagement:attributes.sensorElementName') + ' ' + t('input:required')}}
                        />
                        <Input
                            edit={true} label={t('channel')} name={'channel_id'} type={'button'}
                            value={selectedChannel ? selectedChannel.channel_name + ' [' + selectedChannel.unit + ']' : ''}
                            onClick={() => {setShowSelectChannelTableDialog(true);}}
                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('channel') + ' ' + t('input:required')}}
                        />
                    </div>
                    <div className='col-12 lg:col-6'>
                        <MonitoringTaskRotationalSpeedTypeSelectList
                            label={t('alarmManagement:attributes.rotational_speed_mode')}
                            name={'rotational_speed_mode'}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('alarmManagement:attributes.rotational_speed_mode') + ' ' + t('input:required')}}
                        />
                        {getValues('rotational_speed_mode') === 1 && <>
                            <Input
                                edit={true} label={t('alarmManagement:attributes.rotational_speed_value')}
                                tooltip={t('alarmManagement:tooltips.rotational_speed_value')}
                                name={'rotational_speed_value'} type={'number'} maxFractionDigits={1} disabled={true}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: false}}
                            />
                            <Input
                                edit={true} label={t('alarmManagement:attributes.rotational_speed_width')}
                                tooltip={t('alarmManagement:tooltips.rotational_speed_width')}
                                name={'rotational_speed_width'} type={'number'} maxFractionDigits={1}
                                onChange={(e: any) => {
                                    setValue('rotational_speed_width', e.value);
                                }}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: t('alarmManagement:attributes.rotational_speed_width') + ' ' + t('input:required')}}
                            />
                        </>}
                        {getValues('rotational_speed_mode') === 2 && <>
                            <OperatingStateSelectList
                                projectId={props.projectId}
                                disabled={true}
                                label={t('metrologyManagement:attributes.operatingState')}
                                tooltip={t('alarmManagement:tooltips.monitoringTaskOperatingStateId')}
                                name={'operating_state_id'}
                                validationControl={control}
                                validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: false}}
                            />
                            <ChannelSelectList
                                type={'monitoring_task'}
                                disabled={!getValues('channel_id')}
                                channelId={getValues('channel_id')}
                                projectId={props.projectId}
                                label={t('alarmManagement:attributes.rotational_speed_source_id')}
                                tooltip={t('alarmManagement:tooltips.rotational_speed_source_id')}
                                name={'rotational_speed_source_id'}
                                onChange={(event: any) => {
                                    setValue('rotational_speed_source_id', event.value);
                                }}
                                validationControl={control}
                                validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: t('alarmManagement:attributes.rotational_speed_source_id') + ' ' + t('input:required')}}
                            />
                        </>}
                        { getValues('element_id') &&
                            <Input
                                edit={true}
                                name={'notification_ids'}
                                label={t('notifications')}
                                tooltip={t('alarmManagement:tooltips.monitoringTaskNotificationTasks')}
                                value={selectedNotificationIds}
                                onChange={(event: any) => {
                                    console.log('onChange: ', event.value)
                                    setSelectedNotificationIds(event.value);
                                }}
                                scrollHeight={'500px'}
                                dropdownOptions={notifications}
                                type={'multiselect'}
                                selectionMode={'checkbox'}
                            />
                        }
                    </div>
                </div>
                <div className={'card'}>
                    <MonitoringTaskLimitsOverview
                        type={MonitoringTaskLimitsOverviewType.MonitoringTaskForm}
                        projectId={props.projectId}
                        showHeader={true}
                        monitoringTaskId={monitoringTaskId}
                        monitoringTaskLimits={monitoringTaskLimits}
                        setMonitoringTaskLimits={setMonitoringTaskLimits}
                    />
                </div>
            </form>
            <ChannelSelectTableDialog
                type={ChannelSelectTableDialogType.SpectralData}
                projectId={props.projectId}
                visible={showChannelSelectTableDialog}
                setVisible={setShowSelectChannelTableDialog}
                selectedChannel={selectedChannel}
                onFinish={onChannelChange}
            />
        </>
    );
};

export default MonitoringTaskForm;

