/*
 * ChannelSelectList.tsx
 * Author: lnappenfeld
 * Date: 06.05.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import Input from '../global/Input';
import {getChannelsAsTree, getChannelsForNotification} from '../functions/Metrology';
import {Nullable} from '../functions/Global';

type Props = {
    type: string,
    projectId: string,
    label: string,
    list?: any, /* {value, label} */
    value?: string,
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    name?: string,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    disabled?: boolean,
    showClear?: boolean
    tooltip?: string
    channelId?: Nullable<string> // Erstmal nur für type: monitoring_task
    channelTypeId?: Nullable<number> // Es werden Channels nur von einem bestimmten Typ in der Liste angezeigt
}
const ChannelSelectList = (props: Props): JSX.Element => {

    const [list, setList] = useState(props.list);

    useEffect(() => {
        setList(props.list);
        if (typeof props.list === 'undefined')
            switch (props.type) {
                case 'notification':
                    channelsForNotification();
                    break;
                case 'measurement':
                    channelsForMeasurement();
                    break;
                case 'condition_monitoring':
                    channelsForConditionMonitoring();
                    break;
                case 'operating_state_condition':
                    channelsForOperatingStateCondition();
                    break;
                // case 'monitoring_task':
                //     channelsForMonitoringTask();
                //     break;
            }
    }, []);

    useEffect(() => {
        if (props.channelId && props.type === 'monitoring_task')
            channelsForMonitoringTask();
    }, [props.channelId]);

    const channelsForNotification = () => {
        getChannelsForNotification(props.projectId).then(result => {
            setList(result);
        });
    };

    // Mit Gruppen als oberste Ebene
    // Gruppen > Sensoren > Kanäle
    const channelsForMeasurement = () => {
        if (props.channelTypeId && props.channelTypeId > 0) {
            getChannelsAsTree(props.projectId, null, null, props.channelTypeId).then(result => {
                setList(result);
            });
        } else {
            getChannelsAsTree(props.projectId).then(result => {
                setList(result);
            });
        }

    };

    // Mit Messgeräten als oberste Ebene
    // Messgeräte > Sensoren > Kanäle
    const channelsForConditionMonitoring = () => {
        getChannelsAsTree(props.projectId, 'condition_monitoring').then(result => {
            setList(result);
        });
    };

    // Mit Messgeräten als oberste Ebene, aber nur Kanäle die nicht vom Typ
    // ZEIT, HKZT, MEDA, HKMD, MDFF, MDOA, HKFF oder HKOA sind.
    // Messgeräte > Sensoren > Kanäle
    const channelsForOperatingStateCondition = () => {
        getChannelsAsTree(props.projectId, 'operating_state_condition').then(result => {
            setList(result);
        });
    };

    // Mit Messgeräten als oberste Ebene, aber nur Betriebsdatenkanäle,
    // die in einer Bedingung des Betriebszustand sind der den Kanal triggert,
    // der mit dieser Überwachungsaufgabe überwacht werden soll
    const channelsForMonitoringTask = () => {
        getChannelsAsTree(props.projectId, 'monitoring_task', props.channelId).then(result => {
            setList(result);
        });
    };


    return (
        <Input
            name={props.name ? props.name : 'channel_id'}
            edit={true}
            label={props.label}
            value={props.value}
            scrollHeight={'500px'}
            tooltip={props.tooltip}
            showClear={typeof props.showClear === 'boolean' ? props.showClear : false}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            dropdownOptions={list} type={'treeSelect'}
            onChange={(e: any) => {
                if (props.setValue) props.setValue(e.value);
                if (props.onChange) props.onChange(e);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};
export default ChannelSelectList;
