import React, {useEffect, useState} from 'react';
import CustomDataTable from '../global/CustomDataTable';
import {
    deleteNotificationRange,
    getNotificationRanges,
    NotificationRange,
    resetNotificationRangeFlag
} from '../functions/Notification';
import {useTranslation} from 'react-i18next';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {showMessageOnError, showMessageOnSuccess, showMessageOnWarn} from '../global/CustomToast';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {permissions} from '../../config/permissions';
import {checkPermission, getGroupedItems} from '../../functions/functionLibrary';
import {faRefresh} from '@fortawesome/free-solid-svg-icons';
import {Nullable} from '../functions/Global';
import CustomDialog, {CustomDialogHeaderType} from '../global/CustomDialog';
import NotificationRangeEditForm, {Type} from './NotificationRangeEditForm';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {getChannelTypes} from '../functions/Metrology';


type Props = {
    projectId: string,
    notificationId?: Nullable<string>,
    setSelectedNotificationRanges?: (data: NotificationRange[]) => void
    selectedNotificationRanges?: NotificationRange[],
    headerTitle?: string,
    reloadNotificationRanges?: boolean
    setReloadNotificationRanges?: (data: boolean) => void,
    notificationRangeDialogType?: Type,
    setNotificationRangeDialogType?: (type: Type) => void,
    editNotificationRange?: boolean,
    setEditNotificationRange?: (data: boolean) => void,
}
export const NotificationRangesOverview = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    const [notificationRanges, setNotificationRanges] = useState<NotificationRange[]>([]);
    // Lokale States für die Untertabelle einer expandedRow, muss so gelöst werden, weil die props an das
    // 'expandedElement' nur einmal initial übergeben werden können, aber keine Aktualisierten props
    const [selectedNotificationRanges, setSelectedNotificationRanges] = useState<NotificationRange[]>([]);
    const [editNotificationRange, setEditNotificationRange] = useState<boolean>(false);
    const [notificationRangeDialogType, setNotificationRangeDialogType] = useState<Type>(Type.Single);

    // arrays for filter dropdowns
    const [channelTypes, setChannelTypes] = useState<any[]>([]);
    const [channels, setChannels] = useState<any[]>([]);
    const [sensors, setSensors] = useState<any[]>([]);
    const [locations, setLocations] = useState<any[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const _setStates = (values: any[]) => {
        // data table values
        setNotificationRanges(values);
        setDataLoaded(true);
        // filter dropdown values
        setChannels(getGroupedItems(values, 'channel_id', 'channel_name'));
        setChannelTypes(getGroupedItems(values, 'channel_type_id', 'channel_type_name'));
        setSensors(getGroupedItems(values, 'sensor_id', 'sensor_name'));
        setLocations(getGroupedItems(values, 'location_id', 'location_name'));
    };

    const _setNotificationRanges = () => {
        if (props.notificationId) {
            getNotificationRanges(props.projectId, props.notificationId).then(result => {
                _setStates(result);
            });
        } else {
            getNotificationRanges(props.projectId).then(result => {
                _setStates(result);
            });
        }
    };

    // Setze die lokalen States für den Dialog zum Anlegen einer NEUEN NotificationAction
    // Könnte auch direkt an den Dialog übergeben werden, so muss aber nur auf die lokalen States geprüft werden
    useEffect(() => {
        if (props.editNotificationRange) setEditNotificationRange(props.editNotificationRange);
    }, [props.editNotificationRange]);
    useEffect(() => {
        if (props.selectedNotificationRanges) setSelectedNotificationRanges(props.selectedNotificationRanges);
    }, [props.selectedNotificationRanges]);
    useEffect(() => {
        if (props.notificationRangeDialogType) setNotificationRangeDialogType(props.notificationRangeDialogType);
    }, [props.notificationRangeDialogType]);


    useEffect(() => {
        _setNotificationRanges();
        if (props.setSelectedNotificationRanges) props.setSelectedNotificationRanges([]);
    }, []);

    useEffect(() => {
        // Lade neu, wenn Elemente gelöscht wurden
        if (props.reloadNotificationRanges) {
            _setNotificationRanges();
            if (props.setReloadNotificationRanges) props.setReloadNotificationRanges(false);
        }
        return setNotificationRanges([]);
    }, [props.reloadNotificationRanges]);

    const columns = [
        {
            field: 'notification_id', header: t('alarmManagement:attributes.notificationId'),
            filter: false, display: false
        }, {
            field: 'notification_name', header: t('name'), filter: true, filterField: 'notification_name'
        }, {
            field: 'channel_id', header: t('metrologyManagement:attributes.channelId'),
            filter: false, display: false
        }, {
            field: 'channel_name', header: t('channel'), filter: true,
            filterField: 'channel_id', filterDropdownValues: channels
        }, {
            field: 'channel_type_name', header: t('metrologyManagement:attributes.channelType'), filter: true,
            filterField: 'channel_type_id', filterDropdownValues: channelTypes
        }, {
            field: 'sensor_name', header: t('sensor'), filter: true,
            filterField: 'sensor_id', filterDropdownValues: sensors
        }, {
            field: 'location_name', header: t('location'), filter: true,
            filterField: 'location_id', filterDropdownValues: locations
        }, {
            field: 'flag', header: t('alarmManagement:attributes.flag'),
            body: checkPermission(permissions.editNotification) ? (range: any) => {
                return (
                    range['flag'] ?
                        <span className='cell-function' onClick={(e) => {
                            e.stopPropagation();
                            // eslint-disable-next-line new-cap
                            CustomConfirmDialog({
                                message: t('alarmManagement:dialogs.resetNotificationRangeFlagDialog.message'),
                                header: t('confirmation'),
                                translation: t,
                                onConfirm: () => {
                                    resetNotificationRangeFlag(props.projectId, range.id).then(result => {
                                        if (result.error) {
                                            showMessageOnError(t('error'), result.error);
                                        } else if (result.nothingToDo) {
                                            showMessageOnWarn(t('warning'), result.nothingToDo);
                                        } else {
                                            _setNotificationRanges();
                                            showMessageOnSuccess(t('success'), t('alarmManagement:toasts.notificationRangeFlagReset'));
                                        }
                                    });
                                }
                            });
                        }}>
                            <span style={{display: 'inline-block', marginRight: '10px'}} title={t('alarmManagement:notificationRange.resetFlagTooltip')}>
                                <FontAwesomeIcon icon={faRefresh} aria-disabled={true}/>
                            </span><u>{t('yes')}</u>
                        </span>
                        :
                        <>
                            <span style={{display: 'inline-block', marginRight: '25px'}} >
                            </span>{t('no')}</>
                );
            } : null
        },
        {field: 'minimum', header: t('alarmManagement:attributes.minimum'), style: {maxWidth: '100px'}},
        {field: 'maximum', header: t('alarmManagement:attributes.maximum'), style: {maxWidth: '100px'}},
        {field: 'flag_set_value', header: t('alarmManagement:attributes.flag_set_value'), style: {maxWidth: '100px'}},
        {field: 'flag_set_value_date', header: t('alarmManagement:attributes.flag_set_value_date'), type: 4},
        {field: 'flag_set_when_utc', header: t('alarmManagement:attributes.flag_set_when_utc'), type: 4, display: false},
        {field: 'flag_auto_reset_after_minutes', header: t('alarmManagement:attributes.flag_auto_reset_after_minutes'), display: false},
        {field: 'flag_changed_by_user_name', header: t('changedByUsername'), display: false}
    ];

    if (props.notificationId) {
        delete columns[0]; // notification_id
        delete columns[1]; // notification_name
    }

    const filters = {
        'notification_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'channel_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'sensor_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'location_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'channel_type_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        }
    };

    return (
        <>
            <CustomDataTable
                id='notification-ranges'
                selectionMode={(props.notificationId) ? 'single' : 'multiple'}
                selection={selectedNotificationRanges}
                onSelectionChange={(e: any) => {
                    if (props.setSelectedNotificationRanges) props.setSelectedNotificationRanges(e.value);
                    // setSelectedNotificationRanges(e.data);
                }}
                columns={columns}
                headerTitle={props.headerTitle ? props.headerTitle : ''}
                onEditClick={(data) => {
                    if (props.setSelectedNotificationRanges) props.setSelectedNotificationRanges([data]);
                    if (props.setNotificationRangeDialogType) props.setNotificationRangeDialogType(Type.Single);
                    setSelectedNotificationRanges([data]);
                    setNotificationRangeDialogType(Type.Single);
                    setEditNotificationRange(true);
                }}
                onRemoveClick={(data: any) => {
                    CustomConfirmDialog({
                        message: t('alarmManagement:dialogs.deleteNotificationRangeDialog.message'),
                        header: t('confirmation'),
                        translation: t,
                        onConfirm: () => {
                            deleteNotificationRange(props.projectId, [data.id]).then(result => {
                                if (result.error) {
                                    showMessageOnError(t('error'), result.error);
                                } else {
                                    _setNotificationRanges();
                                    showMessageOnSuccess(t('success'), t('alarmManagement:toasts.notificationRangeDeleted'));
                                }
                            });
                        }
                    });
                }}
                filters={filters}
                editable={true}
                sortable={true}
                displayColumnFilter={true}
                value={notificationRanges}
                ableToDelete={checkPermission(permissions.editNotification)} // && props.deleteNotificationRange}
                ableToUpdate={checkPermission(permissions.editNotification)}
                dataloaded={dataLoaded}
                showExportCSV={true}
                showClearFiltersButton={true}
            />
            {
                <CustomDialog
                    header={selectedNotificationRanges.length > 0 ? t('alarmManagement:dialogs.editNotificationRangeDialog.header') : t('alarmManagement:dialogs.createNotificationRangeDialog.header')}
                    headerType={selectedNotificationRanges.length > 0 ? CustomDialogHeaderType.Update : CustomDialogHeaderType.Create}
                    formName={'formDialog'}
                    visible={editNotificationRange}
                    onCancel={() => {
                        if (props.setEditNotificationRange) props.setEditNotificationRange(false);
                        setEditNotificationRange(false);
                    }}
                    onHide={() => {
                        if (props.setEditNotificationRange) props.setEditNotificationRange(false);
                        setEditNotificationRange(false);
                    }}
                >
                    <NotificationRangeEditForm
                        projectId={props.projectId}
                        onFinished={() => {
                            if (props.setEditNotificationRange) props.setEditNotificationRange(false);
                            if (props.setSelectedNotificationRanges) props.setSelectedNotificationRanges([]);
                            setEditNotificationRange(false);
                            setSelectedNotificationRanges([]);
                            _setNotificationRanges();
                        }}
                        notificationRangeData={selectedNotificationRanges.length > 0 ? selectedNotificationRanges : []}
                        type={notificationRangeDialogType}
                    />
                </CustomDialog>
            }
        </>
    );
};
