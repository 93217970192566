/*
 * ControlDeviceControlForm.tsx
 * Author: lnappenfeld
 * Date: 20.03.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ViewButtons from '../global/ViewButtons';
import {ControlDevice, getFuRpm, startFu, stopFu} from '../functions/Metrology';
import {hideWaitAnimation, showWaitAnimation} from '../global/CustomWaitAnimation';
import {showMessageOnError, showMessageOnSuccess, showProgressToast} from '../global/CustomToast';
import {Knob} from 'primereact/knob';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {ViewButton} from '../global/ViewButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRotateLeft, faRotateRight} from '@fortawesome/free-solid-svg-icons';
import {Button} from 'primereact/button';
import Input from '../global/Input';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {checkPermission} from '../../functions/functionLibrary';
import {permissions} from '../../config/permissions';
import {ProgressBar} from 'primereact/progressbar';
import CustomDialog from '../global/CustomDialog';

enum DirectionOfRotation {
    Stopped,
    Left,
    Right
}

type Props = {
    projectId: string
    controlDevices: ControlDevice[]
}

const DISABLE_BUTTON_TIME_IN_SECONDS = 3;
const MIN_RPM = 600;
const MAX_RPM = 1500;
const PERCENT_SUBTRACTION = 20;
const DEVICE_TIMEOUT = 150;

export const ControlDeviceControlForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);
    const interval = useRef(null);

    const [currentFuRpm, setCurrentFuRpm] = useState<number>(0);
    const [currentDirectionOfRotation, setCurrentDirectionOfRotation] = useState<DirectionOfRotation>(DirectionOfRotation.Stopped);
    const [currentFuRpmPercent, setCurrentFuRpmPercent] = useState<number>(0);
    const [newFuRpm, setNewFuRpm] = useState<number>(MIN_RPM);
    const [localMode, setLocalMode] = useState<boolean>(false);
    // const [newFuRpmPercent, setNewFuRpmPercent] = useState<number>(Number(Number(MIN_RPM / MAX_RPM * 100).toFixed(2)) - PERCENT_SUBTRACTION);
    const [newDirectionOfRotation, setNewDirectionOfRotation] = useState<DirectionOfRotation>(DirectionOfRotation.Right);
    const [disableButtons, setDisableButtons] = useState<boolean>(false);
    const [dialogContent, setDialogContent] = useState<any>(undefined);

    const [value, setValue] = useState(30);
    const [startInterval, setStartInterval] = useState<boolean>(false);
    const [loaded, setLoaded] = useState(false);

    const _setCurrentFuRpm = async () => {
        if (props.controlDevices.length > 1) {
            showWaitAnimation();
            for (let i = 0; i < props.controlDevices.length; i++) {
                const controlDevice = props.controlDevices[i];
                await getFuRpm(props.projectId, controlDevice.control_device_id).then(result => {
                    console.log('result: ', result);
                    if (result.error) {
                        showMessageOnError(t('error'), result.error);
                    } else {
                        const rpm = result.rpm;
                        const localMode = result.localMode;

                        console.log('rpm: ', rpm);
                        console.log('localMode: ', localMode);
                        props.controlDevices[i]['last_rpm'] = rpm;
                        setLocalMode(localMode);
                    }
                });
            }
            setLoaded(true);
            hideWaitAnimation();

            setNewFuRpm(MIN_RPM);
            // setNewFuRpmPercent(Number(Number(MIN_RPM / MAX_RPM * 100).toFixed(2)) - PERCENT_SUBTRACTION);
            setCurrentDirectionOfRotation(DirectionOfRotation.Right);
        } else {
            if (props.controlDevices[0].control_device_type_id === 1 || props.controlDevices[0].control_device_type_id === 2) { // Frequenzumrichter
                if (props.controlDevices[0].is_active) { // should be true
                    showWaitAnimation();
                    getFuRpm(props.projectId, props.controlDevices[0].control_device_id).then(result => {
                        console.log('result: ', result);
                        if (result.error) {
                            showMessageOnError(t('error'), result.error);
                        } else {
                            // 40% entsprechen 900 U/min und 80 % entsprechen 1500 U/min
                            // und nicht 60% = 900 U/min und 100% = 1500 U/min
                            // Deswegen werden 20% abgezogen
                            let percentSubtraction = 0;
                            if (result.rpm >= MIN_RPM) {
                                percentSubtraction = PERCENT_SUBTRACTION;
                            }
                            setCurrentFuRpm(result.rpm);
                            // setCurrentFuRpmPercent(Number(Number(result.rpm / MAX_RPM * 100).toFixed(2)) - percentSubtraction);
                            setCurrentFuRpmPercent(Number(Number(result.rpm / MAX_RPM * 100).toFixed(2)));
                            setNewFuRpm(MIN_RPM);
                            // setNewFuRpmPercent(Number(Number(MIN_RPM / MAX_RPM * 100).toFixed(2)) - PERCENT_SUBTRACTION);
                            // setNewFuRpmPercent(Number(Number(MIN_RPM / MAX_RPM * 100).toFixed(2)));
                            if (result.rpm < 0) {
                                setCurrentDirectionOfRotation(DirectionOfRotation.Left);
                                setNewDirectionOfRotation(DirectionOfRotation.Left);
                            } else if (result.rpm > 0) {
                                setCurrentDirectionOfRotation(DirectionOfRotation.Right);
                                setNewDirectionOfRotation(DirectionOfRotation.Right);
                            } else {
                                setCurrentDirectionOfRotation(DirectionOfRotation.Stopped);
                                setNewDirectionOfRotation(DirectionOfRotation.Right);
                            }

                            setLocalMode(result.localMode);
                        }
                        hideWaitAnimation();
                    });
                } else {
                    showMessageOnError(t('error'), t('metrologyManagement:toasts.getcurrentFuRpmDisabled'));
                }
            }
        }
    };

    useEffect(() => {
        _setCurrentFuRpm();
    }, []);

    useEffect(() => {
        console.log('localMode', localMode);
    }, [localMode]);

    const columns: ColumnObject[] = [
        {
            field: 'is_active', header: t('active'), style: {maxWidth: '100px'},
            filter: false
        }, {
            field: 'control_device_name', header: t('name'),
            filter: false
        }, {
            field: 'control_device_type_name', header: t('type'),
            filter: false
        }, {
            field: 'project_group_name', header: t('projectManagement:attributes.projectGroup'),
            filter: false
        }, {
            field: 'location_name', header: t('location'),
            filter: false
        }, {
            field: 'ip', header: t('ip'),
            filter: false
        }, {
            field: 'last_rpm', header: t('last_rpm'),
            filter: false
        }];

    useEffect(() => {
        if (startInterval) {
            let _value = DEVICE_TIMEOUT;
            // @ts-ignore
            interval.current = setInterval(() => {
                _value = _value - 1;

                if (_value <= 0) {
                    clearIntervallFunc();
                }

                setValue(_value);
            }, 1000);
            return () => {
                clearIntervallFunc();
            };
        }
    }, [startInterval]);

    useEffect(() => {
        console.log('value: ', value);
    }, [value]);

    const clearIntervallFunc = () => {
        // @ts-ignore
        clearInterval(interval.current);
        interval.current = null;
        setStartInterval(false);
        setValue(DEVICE_TIMEOUT);
    };

    const getRpmFunc = () => {
        getFuRpm(props.projectId, props.controlDevices[0].control_device_id).then(result => {
            console.log('result: ', result);
            if (result.error) {
                showMessageOnError(t('error'), result.error);
            } else {
                // 40% entsprechen 900 U/min und 80 % entsprechen 1500 U/min
                // und nicht 60% = 900 U/min und 100% = 1500 U/min
                // Deswegen werden 20% abgezogen
                let percentSubtraction = 0;
                if (result.rpm >= MIN_RPM) {
                    percentSubtraction = PERCENT_SUBTRACTION;
                }
                setCurrentFuRpm(result.rpm);
                setCurrentFuRpmPercent(Number(Number(result.rpm / MAX_RPM * 100).toFixed(2)) - percentSubtraction);
                if (result.rpm < 0) {
                    setCurrentDirectionOfRotation(DirectionOfRotation.Left);
                } else if (result.rpm > 0) {
                    setCurrentDirectionOfRotation(DirectionOfRotation.Right);
                } else {
                    setCurrentDirectionOfRotation(DirectionOfRotation.Stopped);
                }
                setLocalMode(result.localMode);
            }
            hideWaitAnimation();
        });
    };


    const colClassName = props.controlDevices.length === 1 ? 'col-12 lg:col-4' : 'col-12 lg:col-6';

    const getInfoTextForControlDevice = (resultArray: any[]) => {
        return (<div><CustomDataTable id={'1'} columns={[
            {field: 'name', header: 'Name'},
            {field: 'status', header: 'Status', style: {'minWidth': '500px'}}
        ]} editable={false} sortable={false} hideGlobalFilter={true} value={resultArray.map(item => {
            return {name: item.name, status: t('metrologyManagement:fuStatus.' + item.type)};
        })}/>
        </div>);
    };

    return (
        <div className={'row'}>
            {props.controlDevices.length > 1 && loaded &&
            <div className={'card'}>
                <CustomDataTable
                    id="controlDevices"
                    style={{'maxHeight': '400px', 'overflow': 'auto'}}
                    selectionMode={'single'}
                    expandable={false}
                    columns={columns}
                    editable={false}
                    sortable={true}
                    value={props.controlDevices}
                    paginator={props.controlDevices.length > 100}
                    ableToDelete={false}
                    ableToUpdate={false}
                    filters={[]}
                    headerTitle={'Geräte die gesteuert werden sollen'}
                    dataloaded={true}
                /></div>}
            <div className={'grid mt-1 pb-3'}>
                {props.controlDevices.length === 1 &&
                <div className={'grid grid-nogutter w-full'}><h3>{props.controlDevices[0].control_device_name}</h3>
                    {localMode && <div className={'m-auto'}>{t('metrologyManagement:fuStatus.localMode')}</div>}
                </div>}
                {props.controlDevices.length === 1 &&
                <div className={colClassName}>
                    <div className={'card grid h-full m-1'}>
                        <div className="col">
                            <h4 style={{textDecoration: 'underline'}}>Aktuelle Drehzahl</h4>
                            {/* <h5>RPM: {currentFuRpm}</h5>*/}
                            <Input
                                edit={true} label={t('RPM')} name={'newFuRpm'} type={'number'}
                                value={Math.abs(currentFuRpm)}
                                disabled={true}
                            />
                            {currentDirectionOfRotation !== DirectionOfRotation.Stopped &&
                            <div className="grid">
                                <div className="col col-auto">
                                    <h5>Drehrichtung: {currentDirectionOfRotation === DirectionOfRotation.Left
                                        ? t('metrologyManagement:tooltips:directionLeft')
                                        : t('metrologyManagement:tooltips:directionRight')}</h5>
                                </div>
                                <div className="col col-auto ml-1">
                                    <Button
                                        style={{float: 'right'}}
                                        className={'p-button'}
                                        disabled={true}
                                        tooltip={currentDirectionOfRotation === DirectionOfRotation.Left
                                            ? t('metrologyManagement:tooltips:directionLeft')
                                            : t('metrologyManagement:tooltips:directionRight')}
                                        tooltipOptions={{position: 'top'}}
                                    >
                                        {currentDirectionOfRotation === DirectionOfRotation.Left &&
                                        <FontAwesomeIcon className="sidebar-icon" icon={faRotateLeft}/>}
                                        {currentDirectionOfRotation === DirectionOfRotation.Right &&
                                        <FontAwesomeIcon className="sidebar-icon" icon={faRotateRight}/>}
                                    </Button>
                                </div>
                            </div>}
                        </div>
                        {/* <div className="col">*/}
                        {/*    <Knob value={currentFuRpmPercent} valueTemplate={'{value}%'} onChange={(e) => {*/}
                        {/*        setCurrentFuRpmPercent(e.value);*/}
                        {/*        setCurrentFuRpm(e.value * MAX_RPM / 100);*/}
                        {/*    }} disabled={true} size={150}/>*/}
                        {/* </div>*/}
                    </div>
                </div>}
                <div className={colClassName}>
                    <div className={'card grid h-full m-1'}>
                        <div className="col">
                            <h4 style={{textDecoration: 'underline'}}>Neue Drehzahl</h4>
                            {/* <h5>RPM: {newFuRpmPercent * 1500 / 100}</h5>*/}
                            <Input
                                edit={true} label={t('RPM')} name={'newFuRpm'} type={'number'} value={newFuRpm}
                                min={MIN_RPM} max={MAX_RPM} onChange={(e) => {
                                let value = e.value;
                                if (e.value > MAX_RPM) {
                                    value = MAX_RPM;
                                } else if (e.value < MIN_RPM) {
                                    value = MIN_RPM;
                                }
                                // 40% entsprechen 900 U/min und 80 % entsprechen 1500 U/min
                                // und nicht 60% = 900 U/min und 100% = 1500 U/min
                                // Deswegen werden 20% abgezogen
                                // setNewFuRpmPercent(Number(Number(value / MAX_RPM * 100).toFixed(2)) - PERCENT_SUBTRACTION);
                                // setNewFuRpmPercent(Number(Number(value / MAX_RPM * 100).toFixed(2)));
                                setNewFuRpm(value);
                            }} tooltip={'Minimaldrehzahl: 600 U/min; Maximaldrehzahl: 1500 U/min'}
                            />
                            <div className="grid">
                                <div className="col col-auto">
                                    <h5>Drehrichtung: {newDirectionOfRotation === DirectionOfRotation.Left
                                        ? t('metrologyManagement:tooltips:directionLeft')
                                        : t('metrologyManagement:tooltips:directionRight')}</h5>
                                </div>
                                <div className="col col-auto ml-1">
                                    {currentFuRpm === 0 &&
                                    <Button
                                        style={{float: 'right'}}
                                        className={'p-button'}
                                        tooltip={newDirectionOfRotation === DirectionOfRotation.Left
                                            ? t('metrologyManagement:tooltips:directionLeft')
                                            : t('metrologyManagement:tooltips:directionRight')}
                                        tooltipOptions={{position: 'top'}}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            if (newDirectionOfRotation === DirectionOfRotation.Left) {
                                                setNewDirectionOfRotation(DirectionOfRotation.Right);
                                            } else {
                                                setNewDirectionOfRotation(DirectionOfRotation.Left);
                                            }

                                        }}
                                    >
                                        {newDirectionOfRotation === DirectionOfRotation.Left &&
                                        <FontAwesomeIcon className="sidebar-icon" icon={faRotateLeft}/>}
                                        {newDirectionOfRotation === DirectionOfRotation.Right &&
                                        <FontAwesomeIcon className="sidebar-icon" icon={faRotateRight}/>}
                                    </Button>}
                                </div>
                            </div>
                        </div>
                        {/* <div className="col">*/}
                        {/*    <Knob value={newFuRpmPercent} valueTemplate={'{value}%'} onChange={(e) => {*/}
                        {/*        setNewFuRpmPercent(e.value);*/}
                        {/*        setNewFuRpm((e.value + PERCENT_SUBTRACTION) * MAX_RPM / 100);*/}
                        {/*    }} size={150} disabled={true}/>*/}
                        {/* </div>*/}
                    </div>
                </div>
                <div className={colClassName}>
                    <div className={'card grid h-full m-1'}>
                        <div className="col">
                            {props.controlDevices.length === 1 &&
                            <ViewButton
                                key={'butt_' + t('metrologyManagement:buttons.syncRpm')}
                                className={'p-button'}
                                label={t('metrologyManagement:buttons.syncRpm')}
                                onClick={() => {
                                    if (props.controlDevices[0].control_device_type_id === 1 || props.controlDevices[0].control_device_type_id === 2) { // Frequenzumrichter
                                        if (props.controlDevices[0].is_active) { // should be true
                                            showWaitAnimation();
                                            // setDisableButtons(true);
                                            setTimeout(() => {
                                                setDisableButtons(false);
                                            }, DISABLE_BUTTON_TIME_IN_SECONDS * 1000);
                                            getRpmFunc();
                                        } else {
                                            showMessageOnError(t('error'), t('metrologyManagement:toasts.getcurrentFuRpmDisabled'));
                                        }
                                    }
                                }}
                                icon={'pi pi-sync'}
                            />}
                        </div>

                        <div className={'col col-auto'} style={{textAlign: 'right'}}>
                            {checkPermission(permissions.turnOnDevices) && currentFuRpm === 0 && props.controlDevices.length === 1 &&
                            <ViewButton
                                key={'butt_' + t('metrologyManagement:buttons.startFu')}
                                className={'p-button-danger'}
                                label={t('metrologyManagement:buttons.startFu')}
                                onClick={() => {
                                    CustomConfirmDialog({
                                        message: t('metrologyManagement:dialogs.startFu.message'),
                                        header: t('confirmation'),
                                        translation: t,
                                        onConfirm: () => {
                                            showWaitAnimation();
                                            setDisableButtons(true);
                                            setTimeout(() => {
                                                setDisableButtons(false);
                                            }, DISABLE_BUTTON_TIME_IN_SECONDS * 1000);

                                            // Drehe Vorzeichen um bei Linksdrehung
                                            let signedFuRpm = newFuRpm;
                                            if (newDirectionOfRotation === DirectionOfRotation.Left) {
                                                signedFuRpm = -newFuRpm;
                                            }
                                            const controlDeviceIds: string[] = [];
                                            props.controlDevices.forEach((controlDevice: ControlDevice) => {
                                                controlDeviceIds.push(controlDevice.control_device_id);
                                            });
                                            startFu(props.projectId, controlDeviceIds, signedFuRpm).then(result => {
                                                if (result.error) {
                                                    showMessageOnError(t('error'), result.error);
                                                } else {
                                                    // showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.fuStarted'));
                                                    // disable Button
                                                    setDialogContent({
                                                        header: t('metrologyManagement:toasts.fuStarted'),
                                                        content: <div>{getInfoTextForControlDevice(result)}</div>
                                                    });

                                                    hideWaitAnimation();
                                                }
                                            });
                                        }
                                    });
                                }}
                                icon={'pi pi-play'}
                                disabled={disableButtons || localMode}
                            />}
                            {
                                (Math.abs(currentFuRpm) > 0 || props.controlDevices.length > 1) && // placeholder
                                <div style={{height: '47px'}}></div>
                            }
                            {
                                checkPermission(permissions.controlDevices) && (Math.abs(currentFuRpm) > 0 || props.controlDevices.length > 1) &&
                                <ViewButton
                                    key={'butt_' + t('metrologyManagement:buttons.resetFuSpeed')}
                                    className={'p-button-danger'}
                                    label={t('metrologyManagement:buttons.resetFuSpeed')}
                                    onClick={() => {
                                        CustomConfirmDialog({
                                            message: t('metrologyManagement:dialogs.resetFuSpeed.message'),
                                            header: t('confirmation'),
                                            translation: t,
                                            onConfirm: () => {
                                                showWaitAnimation();
                                                setDisableButtons(true);
                                                setTimeout(() => {
                                                    setDisableButtons(false);
                                                }, DISABLE_BUTTON_TIME_IN_SECONDS * 1000);

                                                // Drehe Vorzeichen um bei Linksdrehung
                                                let signedFuRpm = newFuRpm;
                                                if (newDirectionOfRotation === DirectionOfRotation.Left) {
                                                    signedFuRpm = -newFuRpm;
                                                }
                                                const controlDeviceIds: string[] = [];
                                                props.controlDevices.forEach((controlDevice: ControlDevice) => {
                                                    controlDeviceIds.push(controlDevice.control_device_id);
                                                });
                                                startFu(props.projectId, controlDeviceIds, signedFuRpm).then(result => {
                                                    try {
                                                        if (result.error) {
                                                            showMessageOnError(t('error'), result.error);
                                                        } else {
                                                            // showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.fuSpeedReset'));
                                                            setDialogContent({
                                                                header: t('metrologyManagement:toasts.fuSpeedReset'),
                                                                content:
                                                                    <div>{getInfoTextForControlDevice(result)}</div>
                                                            });
                                                            getRpmFunc();
                                                        }
                                                    } catch (err) {
                                                        showMessageOnError(t('error'), 'an error occured');
                                                    }
                                                    hideWaitAnimation();
                                                });
                                            }
                                        });
                                    }}
                                    icon={'pi pi-sliders-h'}
                                    disabled={disableButtons || localMode}
                                />
                            }
                            {
                                checkPermission(permissions.turnOffDevices) && (Math.abs(currentFuRpm) > 0 || props.controlDevices.length > 1) &&
                                <ViewButton
                                    key={'butt_' + t('metrologyManagement:buttons.stopFu')}
                                    className={'p-button-danger'}
                                    label={t('metrologyManagement:buttons.stopFu')}
                                    onClick={() => {
                                        CustomConfirmDialog({
                                            message: t('metrologyManagement:dialogs.stopFu.message'),
                                            header: t('confirmation'),
                                            translation: t,
                                            onConfirm: () => {
                                                showWaitAnimation();
                                                setDisableButtons(true);
                                                setTimeout(() => {
                                                    setDisableButtons(false);
                                                }, DISABLE_BUTTON_TIME_IN_SECONDS * 1000);

                                                const controlDeviceIds: string[] = [];
                                                props.controlDevices.forEach((controlDevice: ControlDevice) => {
                                                    controlDeviceIds.push(controlDevice.control_device_id);
                                                });

                                                // const toast = showProgressToast(value);
                                                // toast.current.show({
                                                //     severity: 'warn',
                                                //     sticky: true,
                                                //     content: (
                                                //         <div className="flex flex-column"
                                                //              style={{flex: '1'}}>
                                                //             <div className="text-center">
                                                //                 <i className="pi pi-exclamation-triangle"
                                                //                    style={{fontSize: '2rem'}}/>
                                                //                 <h4>Lüfter wurde gestoppt und für 30 Sekunden
                                                //                     gesperrt.</h4>
                                                //                 <ProgressBar
                                                //                     mode="indeterminate"/>
                                                //             </div>
                                                //         </div>
                                                //     )
                                                // });
                                                stopFu(props.projectId, controlDeviceIds).then(result => {
                                                    try {
                                                        if (result.error) {
                                                            showMessageOnError(t('error'), result.error);
                                                        } else {
                                                            // showProgressToast();
                                                            // showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.fuStopped'));
                                                            setStartInterval(true);

                                                            setDialogContent({
                                                                header: t('metrologyManagement:toasts.fuStopped'),
                                                                content:
                                                                    <div>{getInfoTextForControlDevice(result)}</div>
                                                            });
                                                            getRpmFunc();
                                                        }
                                                    } catch (err) {
                                                        showMessageOnError(t('error'), 'an error occured');
                                                    }
                                                    hideWaitAnimation();
                                                });
                                            }
                                        });
                                    }}
                                    icon={'pi pi-stop-circle'}
                                    disabled={disableButtons}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            {startInterval && <div className="text-center">
                <div
                    className="mb-1">{t('metrologyManagement:global.controlDevice') + ' ' + t('for') + ' ' + value + ' ' + (value === 1 ? t('second') : t('seconds')) + ' ' + t('blocked') + '.'}</div>
                <ProgressBar showValue={false} value={((value / DEVICE_TIMEOUT * 100))}/></div>}
            {
                dialogContent !== undefined && <CustomDialog onClick={() => {
                    setDialogContent(undefined);
                }} onCancel={() => {
                    setDialogContent(undefined);
                }} visible={dialogContent !== undefined}
                                                             header={dialogContent.header}>
                    {dialogContent.content}
                </CustomDialog>
            }
        </div>
    );
};
