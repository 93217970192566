import React, { useEffect, useState } from 'react';
import Alerts from '../alerts-table/Alerts';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {Button} from 'primereact/button';
import EnergyEfficiencyConfiguration from './EnergyEfficiencyConfiguration';
import PolynomialGraph from './PolynomialGraph';
import { useTranslation } from 'react-i18next';
import {Calendar} from 'primereact/calendar';
import {getMonitoringAppAreaConfiguration, getMonitoringAppParameter} from '../functions/EnergyEfficiencyConfiguration';
import {getMultipleOperationalData} from '../functions/DoppelfilterConfigurations';
import axios from 'axios';
import {BiFullscreen} from 'react-icons/bi';
import {useFullScreenHandle} from 'react-full-screen';
import { FullScreen, FullScreenHandle } from 'react-full-screen';

type EnergyProps = {
    projectId: string,
    colorTheme: string,
    setCustomBreadcrumb: any
}
const EnergyEfficiency = (props: EnergyProps) => {
    const { t } = useTranslation(['common']);
    const monitoringAppId: any = localStorage.getItem('monitoringAppId') ? localStorage.getItem('monitoringAppId') : '';
    const monitoringAppTypeId: any = localStorage.getItem('monitoringAppTypeId') ? localStorage.getItem('monitoringAppTypeId') : '';
    const [allChannels, setAllChannels] = useState<any>([]);
    const [graphData, setGraphData] = useState<any>([]);
    const [startTime, setStartTime] = useState<string>(() => {
        const date = new Date();
        date.setDate(date.getDate() - 4);
        date.setHours(0, 0, 0, 0);
        const user = JSON.parse(localStorage.getItem('user') as string);
        const options:any = {
            timeZone: user['timezone'],
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false // Use 24-hour format
        };

        return date.toLocaleString('en-US', options);
    });
    const [endTime, setEndTime] = useState<string>(() => {
        const date = new Date();
        date.setHours(23, 59, 0, 0);
        const user = JSON.parse(localStorage.getItem('user') as string);
        const options:any = {
            timeZone: user['timezone'],
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false // Use 24-hour format
        };

        return date.toLocaleString('en-US', options);

    });
    const [configuration, setConfiguration] = useState<boolean>(false);

    const colors = ['#FF530D', '#FFEE58', '#3F51B5', '#8D6E63', '#558B2F'];

    const graph = {
        chart: {
            zoomType: 'x',
            height: 450,
        },
        title: {
            text: `${t('conditionMonitoring:energy_eff_graph')}`,
        },
        credits: {
            enabled: false,
        },
        subtitle: {
            text: '',
        },
        tooltip: {
            valueDecimals: 2,
        },

        xAxis: {
            type: 'datetime',
            label: {
                format: '%H:%M:%S.%L',
            },
        },
        colors: colors,
        yAxis: allChannels.map((x: any) => {
            return {
                title: { text: x['name']},
            };
        }),
        series: allChannels.map((x: any, index: number) => {
            return {
                yAxis: index,
                lineWidth: 0.5,
                name: x['name'],
                data: graphData[index],
            };
        }),
    };
    const handle = useFullScreenHandle();

    const apiCalls = async () => {
        const areaConfiguration = await getMonitoringAppAreaConfiguration(props.projectId, monitoringAppId, monitoringAppTypeId);
        const monitoringAppParam = await  getMonitoringAppParameter(props.projectId, monitoringAppId);

        const channel1 = {'name': monitoringAppParam['thresholds'][0]['channel_name'], 'channel_id': monitoringAppParam['thresholds'][0]['channel_id']};
        const channel2 = {'name': areaConfiguration['responseArr'][0]['channel_name'], 'channel_id': areaConfiguration['responseArr'][0]['channel_id']};
        const channel3 = {'name': areaConfiguration['responseArr'][1]['channel_name'], 'channel_id': areaConfiguration['responseArr'][1]['channel_id']};
        const channel4 = {'name': areaConfiguration['responseArr'][2]['channel_name'], 'channel_id': areaConfiguration['responseArr'][2]['channel_id']};

        const finalChannels = [channel1, channel2, channel3, channel4];

        const filteredArray = finalChannels.filter(item => item.name !== null);
        setAllChannels(filteredArray);

        if (filteredArray[0].name !== '') {
            const reqs = filteredArray.map((pointValue: any) =>
                getMultipleOperationalData(props.projectId, pointValue['channel_id'], startTime, endTime)
            );
            const responses: any= await axios.all(reqs);

            const newData = responses.map((response: any) => {
                const apiData: any = [];
                if (response[0]) {
                    response[0]['json_agg'].forEach((val: any) => {
                        apiData.push([new Date(val['f1']).getTime(), +val['f2'].toFixed(2)]);
                    });
                }

                return apiData;
            });
            setGraphData([...newData]);
            // const getChannelsData = await getMultipleOperationalData(props.projectId, filteredArray, '2023-08-14T00:00:00Z', endTime);
            //
            // const channelArray = [channel1, channel2, channel3, channel4];
            // const filteredChannelArray = channelArray.filter(item => item.name !== null && item.channel_id !== null);
            //
            // console.log('getChannelsData ===> ', getChannelsData);
            // console.log('filteredChanne}lArray ===> ', filteredChannelArray);
        }
    };

    useEffect(() => {
        apiCalls();
        props.setCustomBreadcrumb([
            {id: 1, label: 'Condition Monitoring', command:()=>{window.location.hash = '#conditionomonitoring';}},
            {id: 2, label: localStorage.getItem('plants'), command:()=>{window.location.hash = '#conditionomonitoring_' + localStorage.getItem('plantId');}},
            {id: 3, label: localStorage.getItem('component'), command:()=>{window.location.hash = '#conditionomonitoring_' + localStorage.getItem('componentId') + '_detail';}},
            {id: 4, label: t('conditionMonitoring:energy_efficiency'), command:()=>{}}
        ]);
    }, [startTime, endTime]);

    return (
        <>
            <div className="content">
                <div className="grid my-2">

                    <div className='col-6'>
                        <div className="card plot h-full">
                            <Button
                                icon='pi pi-pencil'
                                className="p-button-rounded p-button-secondary p-button-text filter-icon"
                                onClick={() => {
                                    setConfiguration(true);
                                }}
                            />
                            <Button
                                icon='pi pi-refresh'
                                className="p-button-rounded p-button-secondary p-button-text filter-icon"
                                onClick={() => {
                                }}
                            />
                            <div className='mt-6'>
                                <PolynomialGraph projectId={props.projectId} monitoringAppId={monitoringAppId} monitoringAppTypeId={monitoringAppTypeId} updateGraph={configuration}/>
                            </div>

                        </div>
                    </div>


                    <div className='col-6'>
                        <div className="card plot h-full">
                            <div className="grid align-items-center justify-content-center">
                                <label htmlFor="range" className="time-select m-3 mb-2">
                                    {t('conditionMonitoring:from')}
                                </label>
                                <Calendar
                                    className="pl-2"
                                    value={new Date(startTime)}
                                    onChange={(e: any) => {
                                        setStartTime(e.target.value);
                                    }}
                                    showTime
                                    dateFormat="dd.mm.yy"
                                    hideOnDateTimeSelect
                                    maxDate={new Date()}
                                />
                                <label htmlFor="range" className="time-select m-3 mb-2">
                                    {t('conditionMonitoring:to')}
                                </label>
                                <Calendar
                                    className="pl-2"
                                    value={new Date(endTime)}
                                    onChange={(e:any) => {
                                        setEndTime(e.target.value);
                                    }}
                                    showTime
                                    dateFormat="dd.mm.yy"
                                    hideOnDateTimeSelect
                                    minDate={new Date(startTime)}
                                />
                                <div className="text-right ml-4">
                                    <Button
                                        style={{top: '3px'}}
                                        onClick={handle.enter}
                                        icon={<BiFullscreen />}
                                        className="p-button p-component p-button-rounded p-button-secondary p-button-text filter-icon p-button-icon-only"
                                    ></Button>
                                </div>
                            </div>

                            <FullScreen handle={handle}>
                                <div className=' w-full mt-3 plot'>
                                    <HighchartsReact highcharts={Highcharts} options={graph} />
                                </div>
                            </FullScreen>
                        </div>
                    </div>

                </div>

                {configuration && <EnergyEfficiencyConfiguration projectId={props.projectId} modal={configuration} onHide={()=>{setConfiguration(false); }} />}
                <Alerts projectId={props.projectId} alarmOrigin={3} colorTheme={props.colorTheme} />
            </div>
        </>
    );
};

export default EnergyEfficiency;
