/*
 * Input.tsx
 * Author: fwunderlich
 * Date: 01.03.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import {Checkbox} from 'primereact/checkbox';
import {useTranslation} from 'react-i18next';

import {Controller} from 'react-hook-form';
import {classNames} from 'primereact/utils';
import {InputNumber} from 'primereact/inputnumber';
import {SelectButton} from 'primereact/selectbutton';
import {InputSwitch} from 'primereact/inputswitch';
import {TreeSelect, TreeSelectDisplayType} from 'primereact/treeselect';
import {Calendar} from 'primereact/calendar';
import {Tooltip} from 'primereact/tooltip';
import {Button} from 'primereact/button';
import {getUserFromLocalStorage, Nullable} from '../functions/Global';
import {Skeleton} from 'primereact/skeleton';
import {addLocale} from 'primereact/api';

type Props = {
    edit: boolean,
    label?: string,
    name: string,
    className?: string,
    classNameInput?: string,
    disabled?: boolean,
    dropdownOptions?: any,
    selectButtonOptions?: any,
    id?: string,
    type?: string,
    value?: any,
    options?: any,
    onBlur?: (e: any) => void,
    onFocus?: (e: any) => void,
    onShow?: () => void,
    onHide?: () => void,
    onChange?: (e: any) => void,
    onClick?: (e: any) => void,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    scrollHeight?: string,
    showClear?: boolean,
    selectionMode?: any, // ["single", "multiple", "checkbox"]
    minFractionDigits?: number, // Min fractions digits for InputNumber
    maxFractionDigits?: number, // Max fractions digits for InputNumber
    min?: number, // Min-value for InputNumber
    max?: number, // Max-value for InputNumber
    step?: number, // Step for InputNumber
    useGrouping?: boolean, // Whether to use grouping separators, such as thousands separators for InputNumber
    mode?: string, // Mode for InputNumber (Default is decimal) ['decimal', 'currency']
    currency?: string, // Currency for InputNumber ['EUR', 'USD', 'JPY', ...]
    tooltip?: string,
    placeholder?: string,
    display?: string // ['comma', chip']
    minDate?: Nullable<Date> // Min-value for Calender
    maxDate?: Nullable<Date> // Max-value for Calender
}

const Input = (props: Props): JSX.Element => {
    const {t} = useTranslation('input');

    const [showSkeleton, setShowSkeleton] = useState<boolean>(true);
    const [userLocale, setUserLocale] = useState('en');

    // Add locale für Calender
    addLocale('de', {
        firstDayOfWeek: 1,
        showMonthAfterYear: false,
        dayNames: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
        dayNamesShort: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
        dayNamesMin: ['S', 'M', 'D', 'M', 'D', 'F', 'S'],
        monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        today: 'Heute',
        clear: 'Löschen'
    });

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    let component = <></>;

    useEffect(() => {
        const language = getUserFromLocalStorage().language_id;
        setUserLocale(language);
    }, []);

    // useEffect(() => {
    //     console.log('label: ', props.label);
    //     console.log('input: ', props.value);
    // }, [props.value]);

    useEffect(() => {
        if (props.dropdownOptions != null) { // Prüfe auf null und undefinded
            setShowSkeleton(false);
        }
    }, [props.dropdownOptions]);

    let displayStr: TreeSelectDisplayType = 'comma';
    if (props.type === 'treeSelect') {
        if (props.selectionMode === 'multiple' || props.selectionMode === 'checkbox') {
            displayStr = 'chip';
        }
    }

    const groupedItemTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <i className="pi pi-list"></i><span>&nbsp;</span>
                <label><b>{option.label}</b></label>
            </div>
        );
    };

    const className = 'custom-inputfield mt-3' + (props.classNameInput ? ' ' + props.classNameInput : '');
    // Letztes Element hinter den Punkten oder props.name selbst
    const tooltipName = props.name.split('.').pop() || props.name;
    const inputLabel = props.label ?
        (<div className='flex'><h5
            className='input-label'>{props.label + (props.validationControl && props.validationRules.required ? '*' : '')}</h5>
        {props.tooltip &&
                <>
                    <Tooltip target={'.' + tooltipName} content={props.tooltip}/>
                    <i className={'pi pi-info-circle pl-2 my-auto ' + tooltipName}/>
                </>}
        </div>) : null;

    if (showSkeleton && (props.type === 'dropdown' || props.type === 'multiselect' || props.type === 'groupeddropdown' || props.type === 'treeSelect')) {
        // Skeletonloading brauchen wir bisher eigentlich nur für Dropdowns (also Listen die gefüllt werden)
        component = (
            <div className='field'>
                {inputLabel}
                <Skeleton className={className + ' w-full'} height="2.35rem"/>
            </div>
        );
    } else if (props.edit) {
        switch (props.type) {
            case 'number':
                component = props.validationControl ?
                    (<div className='field'>
                        {inputLabel}
                        <Controller
                            name={props.name} control={props.validationControl}
                            rules={props.validationRules || {required: t('required')}}
                            render={({field, fieldState}) => (
                                <InputNumber
                                    id={field.name}
                                    className={className + ' w-full ' + classNames({'p-invalid': fieldState.invalid})}
                                    inputId="locale-german" locale="de-DE"
                                    mode={props.mode ? props.mode : 'decimal'}
                                    currency={props.currency ? props.currency : 'EUR'}
                                    useGrouping={typeof props.useGrouping === 'boolean' ? props.useGrouping : true}
                                    minFractionDigits={props.minFractionDigits ? props.minFractionDigits : 0}
                                    maxFractionDigits={props.maxFractionDigits ? props.maxFractionDigits : 0}
                                    min={props.min} max={props.max} step={props.step} showButtons={!!props.step}
                                    buttonLayout={'horizontal'}
                                    incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                    disabled={props.disabled}
                                    value={props.value || (field.value === null ? null : field.value)}
                                    onBlur={(e: any) => {
                                        if (props.onBlur) {
                                            props.onBlur(e);
                                        }
                                    }}
                                    onChange={(e: any) => {
                                        field.onChange(e);
                                        if (props.onChange) {
                                            props.onChange(e);
                                        }
                                    }}
                                />
                            )}
                        />
                        {props.validationErrorMessage ? props.validationErrorMessage(props.name) : null}

                    </div>)
                    : (<div className='field'>
                        {inputLabel}
                        <InputNumber
                            className={className + ' w-full'}
                            inputId="locale-german" locale="de-DE"
                            mode={props.mode ? props.mode : 'decimal'}
                            currency={props.currency ? props.currency : ''}
                            useGrouping={typeof props.useGrouping === 'boolean' ? props.useGrouping : true}
                            minFractionDigits={props.minFractionDigits ? props.minFractionDigits : 0}
                            maxFractionDigits={props.maxFractionDigits ? props.maxFractionDigits : 0}
                            min={props.min} max={props.max} step={props.step} showButtons={!!props.step}
                            buttonLayout={'horizontal'}
                            incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                            disabled={props.disabled}
                            name={props.name}
                            value={props.value}
                            onBlur={(e: any) => {
                                if (props.onBlur) {
                                    props.onBlur(e);
                                }
                            }}
                            onChange={(e: any) => {
                                console.log(e);
                                if (props.onChange) {
                                    props.onChange(e);
                                }
                            }}/>

                    </div>);
                break;

            case 'date':
                component = props.validationControl ?
                    (<div className='field'>
                        {inputLabel}
                        <Controller
                            name={props.name} control={props.validationControl}
                            rules={props.validationRules || {required: t('required')}}
                            render={({field, fieldState}) => (
                                <Calendar
                                    id={field.name}
                                    className={className + ' w-full ' + classNames({'p-invalid': fieldState.invalid})}
                                    showTime dateFormat='dd.mm.yy' locale={userLocale}
                                    minDate={props.minDate} maxDate={props.maxDate}
                                    disabled={props.disabled}
                                    value={props.value || field.value}
                                    onHide={(e: any) => {
                                        if (props.onBlur) {
                                            props.onBlur(e);
                                        }
                                    }}
                                    onChange={(e: any) => {
                                        console.log(e.value);
                                        field.onChange(e);
                                        if (props.onChange) {
                                            props.onChange(e);
                                        }
                                    }}
                                    {...props.options}
                                />)}
                        />
                        {props.validationErrorMessage ? props.validationErrorMessage(props.name) : null}
                    </div>)
                    : (<div className='field'>
                        {inputLabel}
                        <Calendar
                            className={className + ' w-full'}
                            showTime dateFormat='dd.mm.yy' locale={userLocale}
                            minDate={props.minDate} maxDate={props.maxDate}
                            disabled={props.disabled}
                            name={props.name}
                            value={props.value}
                            onHide={(e: any) => {
                                if (props.onBlur) {
                                    props.onBlur(e);
                                }
                            }}
                            onChange={(e: any) => {
                                if (props.onChange) {
                                    props.onChange(e);
                                }
                            }}
                            {...props.options}
                        />
                    </div>);
                break;

            case 'dropdown':
                component = props.validationControl
                    ? (
                        <div className='field'>
                            {inputLabel}
                            <Controller
                                name={props.name} control={props.validationControl}
                                rules={props.validationRules || {required: t('required')}}
                                render={({field, fieldState}) =>
                                    (<Dropdown
                                        {...props.options}
                                        id={field.name}
                                        className={className + ' w-full ' + classNames({'p-invalid': fieldState.invalid})}
                                        disabled={props.disabled} name={field.name}
                                        scrollHeight={props.scrollHeight || '200px'} filter
                                        showClear={props.showClear}
                                        options={props.dropdownOptions}
                                        value={props.value || field.value}
                                        onBlur={(e: any) => {
                                            if (props.onBlur) {
                                                props.onBlur(e);
                                            }
                                        }}
                                        onChange={(e: any) => {
                                            if (typeof e.value === 'undefined') e.value = null; // possible onClear

                                            field.onChange(e.value);
                                            if (props.onChange) {
                                                props.onChange(e);
                                            }
                                        }}
                                    />
                                    )}
                            />
                            {props.validationErrorMessage ? props.validationErrorMessage(props.name) : null}

                        </div>
                    )
                    : (<div className='field'>
                        {inputLabel}
                        <Dropdown
                            {...props.options}
                            className={className + ' w-full'} disabled={props.disabled} name={props.name}
                            options={props.dropdownOptions} value={props.value}
                            scrollHeight={props.scrollHeight || '200px'} filter
                            showClear={props.showClear}
                            onBlur={(e: any) => {
                                if (props.onBlur) {
                                    props.onBlur(e);
                                }
                            }}
                            onChange={(e: any) => {
                                if (typeof e.value === 'undefined') e.value = null; // possible onClear

                                if (props.onChange) {
                                    props.onChange(e);
                                }
                            }}/>
                    </div>);
                break;

            case 'multiselect':
                component = props.validationControl
                    ? (<div className='field'>
                        {inputLabel}
                        <Controller
                            name={props.name} control={props.validationControl}
                            rules={props.validationRules || {required: t('required')}}
                            render={({field, fieldState}) => (
                                <MultiSelect
                                    id={field.name}
                                    className={className + ' w-full ' + classNames({'p-invalid': fieldState.invalid})}
                                    disabled={props.disabled}
                                    name={field.name}
                                    scrollHeight={props.scrollHeight || '200px'} filter
                                    showClear={false}
                                    optionLabel={'label'}
                                    optionDisabled={'disabled'}
                                    display={'chip'}
                                    options={props.dropdownOptions}
                                    value={props.value || field.value}
                                    onBlur={(e: any) => {
                                        if (props.onBlur) {
                                            props.onBlur(e);
                                        }
                                    }}
                                    onChange={(e: any) => {
                                        field.onChange(e.value);
                                        if (props.onChange) {
                                            props.onChange(e);
                                        }
                                    }}
                                    onShow={() => {
                                        if (props.onShow) {
                                            props.onShow();
                                        }
                                    }}
                                    onHide={() => {
                                        if (props.onHide) {
                                            props.onHide();
                                        }
                                    }}
                                />
                            )}
                        />
                        {props.validationErrorMessage ? props.validationErrorMessage(props.name) : null}

                    </div>)
                    : (<div className='field'>
                        {inputLabel}
                        <MultiSelect
                            className={className + ' w-full'}
                            disabled={props.disabled}
                            name={props.name}
                            options={props.dropdownOptions}
                            value={props.value}
                            optionLabel={'label'}
                            display={'chip'}
                            scrollHeight={props.scrollHeight || '200px'} filter
                            showClear={props.showClear}
                            onBlur={(e: any) => {
                                if (props.onBlur) {
                                    props.onBlur(e);
                                }
                            }}
                            onChange={(e: any) => {
                                if (props.onChange) {
                                    props.onChange(e);
                                }
                            }}
                            onShow={() => {
                                if (props.onShow) {
                                    props.onShow();
                                }
                            }}
                            onHide={() => {
                                if (props.onHide) {
                                    props.onHide();
                                }
                            }}
                        />
                    </div>);
                break;

            case 'groupeddropdown':
                component = props.validationControl ? (<div className='field'>
                    {inputLabel}
                    <Controller
                        name={props.name} control={props.validationControl}
                        rules={props.validationRules || {required: t('required')}}
                        render={({field, fieldState}) =>
                            (<Dropdown id={field.name} {...field}
                                className={className + ' w-full ' + classNames({'p-invalid': fieldState.invalid})}
                                disabled={props.disabled} name={field.name}
                                scrollHeight={props.scrollHeight || '200px'} filter
                                options={props.dropdownOptions} value={props.value || field.value}
                                optionLabel="label" optionGroupLabel="label" optionGroupChildren="items"
                                optionGroupTemplate={groupedItemTemplate}
                                onBlur={(e: any) => {
                                    if (props.onBlur) {
                                        props.onBlur(e);
                                    }
                                }}
                                onChange={(e: any) => {
                                    field.onChange(e.value);
                                    if (props.onChange) {
                                        props.onChange(e);
                                    }
                                }}/>)}/>
                    {props.validationErrorMessage ? props.validationErrorMessage(props.name) : null}

                </div>) :
                    (<div className='field'>
                        <Dropdown
                            disabled={props.disabled} value={props.value} options={props.dropdownOptions}
                            scrollHeight={props.scrollHeight || '200px'} filter
                            onBlur={(e: any) => {
                                if (props.onBlur) {
                                    props.onBlur(e);
                                }
                            }}
                            onChange={(e: any) => {
                                if (props.onChange) {
                                    props.onChange(e);
                                }
                            }} optionLabel="label" optionGroupLabel="label" optionGroupChildren="items"
                            optionGroupTemplate={groupedItemTemplate} className={className + ' w-full'}/>
                        {props.label && <h5 className='input-label'>{props.label}</h5>}</div>);
                break;

            case 'inputswitch':
                component = props.validationControl
                    ? (<div className='field'>
                        <h5>ValidationControl not working</h5>
                        {/* <Controller*/}
                        {/*    name={props.name} control={props.validationControl}*/}
                        {/*    rules={props.validationRules || {required: t('required')}}*/}
                        {/*    render={({field, fieldState}) => (*/}
                        {/*        <InputSwitch*/}
                        {/*            id={field.name} {...field}*/}
                        {/*            className={className  + classNames({'p-invalid': fieldState.invalid})}*/}
                        {/*            disabled={props.disabled}*/}
                        {/*            name={field.name}*/}
                        {/*            checked={field.value}*/}
                        {/*            onChange={(e: any) => {*/}
                        {/*                if (props.onChange) {*/}
                        {/*                    props.onChange(e);*/}
                        {/*                }*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*    )}*/}
                        {/* />*/}
                        {/* {props.validationErrorMessage ? props.validationErrorMessage(props.name) : null}*/}
                        {/* {inputLabel}*/}
                    </div>)
                    : (<div className='field'>
                        {inputLabel}
                        <InputSwitch
                            className={'mt-3'}
                            disabled={props.disabled}
                            name={props.name}
                            checked={props.value}
                            onBlur={(e: any) => {
                                if (props.onBlur) {
                                    props.onBlur(e);
                                }
                            }}
                            onChange={(e: any) => {
                                if (props.onChange) {
                                    props.onChange(e);
                                }
                            }}/>
                    </div>);
                break;
            case 'textarea':
                component = props.validationControl ? (<div className='field'>
                    {inputLabel}
                    <Controller
                        name={props.name} control={props.validationControl}
                        rules={props.validationRules || {required: t('required')}}
                        render={({field, fieldState}) =>
                            (<InputTextarea id={field.name} {...field}
                                className={className + ' w-full ' + classNames({'p-invalid': fieldState.invalid})}
                                disabled={props.disabled} name={props.name}
                                autoResize={true}
                                value={props.value || (field.value === null ? '' : field.value)}
                                onBlur={(e: any) => {
                                    if (props.onBlur) {
                                        props.onBlur(e);
                                    }
                                }}
                                onChange={(e: any) => {
                                    // Wenn das Inputfeld vom User komplett geleert wird, bleibt ein leerer String zurück.
                                    // Das würde dann so in die Datenbank geschrieben werden. Damit Null-Werte gespeichert
                                    // werden, wird der Wert auf null gesetzt.
                                    let _e = e;
                                    if (e.target.value === '') {
                                        _e = {
                                            ...e,
                                            target: {
                                                value: null
                                            }
                                        };
                                    }
                                    field.onChange(_e);
                                    if (props.onChange) {
                                        props.onChange(_e);
                                    }
                                }}/>)}/>
                    {props.validationErrorMessage ? props.validationErrorMessage(props.name) : null}
                </div>) :
                    (<div className='field'>
                        {inputLabel}
                        <InputTextarea className={className + ' w-full'} disabled={props.disabled} name={props.name}
                            autoResize={true}
                            value={props.value}
                            onBlur={(e: any) => {
                                if (props.onBlur) {
                                    props.onBlur(e);
                                }
                            }}
                            onChange={(e: any) => {
                                // Wenn das Inputfeld vom User komplett geleert wird, bleibt ein leerer String zurück.
                                // Das würde dann so in die Datenbank geschrieben werden. Damit Null-Werte gespeichert
                                // werden, wird der Wert auf null gesetzt.
                                let _e = e;
                                if (e.target.value === '') {
                                    _e = {
                                        ...e,
                                        target: {
                                            value: null
                                        }
                                    };
                                }
                                if (props.onChange) {
                                    props.onChange(_e);
                                }
                            }}/>

                    </div>);
                break;
            case 'checkbox':
                component = props.validationControl ? (<div className={'field mt-4 col-12'}>
                    <Controller
                        name={props.name} control={props.validationControl}
                        rules={props.validationRules || {required: t('required')}}
                        render={({field, fieldState}) =>
                            (<Checkbox
                                inputId={field.name}
                                className={className + ' mt-2 ' + classNames({'p-invalid': fieldState.invalid})}
                                disabled={props.disabled}
                                checked={field.value}
                                onBlur={(e: any) => {
                                    if (props.onBlur) {
                                        props.onBlur(e);
                                    }
                                }}
                                onChange={(e: any) => {
                                    field.onChange(e.checked);
                                    if (props.onChange) {
                                        props.onChange(e);
                                    }
                                }}
                            />)}
                    />
                    {props.label &&
                            <label
                                className={'m-auto pl-2'}
                                style={{'fontWeight': 'bold'}}>{props.label + (props.validationControl && props.validationRules.required ? '*' : '')}</label>}
                    {props.tooltip && <>
                        <Tooltip target={'.' + tooltipName} content={props.tooltip}/>
                        <i className={'pi pi-info-circle pl-2 my-auto ' + tooltipName}/>
                    </>}

                </div>) :
                    (<div className={'field mt-4 col-12'}>
                        <Checkbox
                            className={className + ' mt-2'}
                            inputId={props.name}
                            disabled={props.disabled}
                            checked={props.value}
                            onBlur={(e: any) => {
                                if (props.onBlur) {
                                    props.onBlur(e);
                                }
                            }}
                            onChange={(e: any) => {
                                if (props.onChange) props.onChange({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.checked
                                    }
                                });
                            }}/>
                        {props.label &&
                            <label className={'m-auto pl-2'} style={{'fontWeight': 'bold'}}>{props.label}</label>}
                    </div>);
                break;
            case 'selectButton':
                component = props.validationControl
                    ? (<div className={'field mt-2'}>
                        {props.label &&
                            <h5>{props.label + (props.validationControl && props.validationRules.required ? '*' : '')}</h5>}
                        <Controller
                            name={props.name}
                            control={props.validationControl}
                            rules={props.validationRules || {required: t('required')}}
                            render={({field, fieldState}) =>
                                (<SelectButton
                                    className={className + ' w-full-2 ' + classNames({'p-invalid': fieldState.invalid})}
                                    disabled={props.disabled}
                                    value={field.value}
                                    options={props.selectButtonOptions}
                                    optionLabel="name"
                                    onBlur={(e: any) => {
                                        if (props.onBlur) {
                                            props.onBlur(e);
                                        }
                                    }}
                                    onChange={(e: any) => {
                                        field.onChange(e.checked);
                                        if (props.onChange) {
                                            props.onChange(e);
                                        }
                                    }}
                                />)}
                        />
                    </div>)
                    : (<div className={'field mt-2'}>
                        {props.label && <h5>{props.label}</h5>}
                        <SelectButton
                            className={className + ' mt-2'}
                            disabled={props.disabled}
                            value={props.value}
                            options={props.selectButtonOptions}
                            optionLabel="name"
                            onBlur={(e: any) => {
                                if (props.onBlur) {
                                    props.onBlur(e);
                                }
                            }}
                            onChange={(e: any) => {
                                if (props.onChange) props.onChange(e);
                            }}
                        />
                    </div>);
                break;
            case 'treeSelect':
                component = props.validationControl
                    ? (<div className='field'>
                        {inputLabel}
                        <Controller
                            name={props.name} control={props.validationControl}
                            rules={props.validationRules || {required: t('required')}}
                            render={({field, fieldState}) =>
                                (<div className={'relative'}>
                                    <TreeSelect
                                        className={className + ' w-full ' + classNames({'p-invalid': fieldState.invalid})}
                                        disabled={props.disabled}
                                        value={field.value}
                                        options={props.dropdownOptions}
                                        selectionMode={props.selectionMode}
                                        display={displayStr}
                                        onBlur={(e: any) => {
                                            if (props.onBlur) {
                                                props.onBlur(e);
                                            }
                                        }}
                                        onChange={(e: any) => {
                                            field.onChange(e);
                                            if (props.onChange) {
                                                props.onChange(e);
                                            }
                                        }}
                                        scrollHeight={props.scrollHeight || '200px'} filter
                                    />
                                    {/* Den Clear-Button musste man selbst implementieren, weil PrimeReact dies bisher */}
                                    {/* (min. bis Version 9.6.0) nicht im TreeSelect anbietet */}
                                    {props.showClear && field.value &&
                                    <div className={'tree-select-close'}>
                                        <Button
                                            icon={'pi pi-times'}
                                            className={'p-button-text absolute'}
                                            aria-label={'Cancel'}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                const e = {value: null};

                                                field.onChange(e.value);
                                                if (props.onChange) props.onChange(e);
                                            }}
                                        />
                                    </div>}
                                </div>)}
                        />
                        {props.validationErrorMessage ? props.validationErrorMessage(props.name) : null}
                    </div>)
                    : (<div className={'field'}>
                        {inputLabel}
                        <TreeSelect
                            className={className + ' w-full'}
                            disabled={props.disabled}
                            value={props.value}
                            options={props.dropdownOptions}
                            selectionMode={props.selectionMode}
                            display={displayStr}
                            onBlur={(e: any) => {
                                if (props.onBlur) {
                                    props.onBlur(e);
                                }
                            }}
                            onChange={(e: any) => {
                                if (props.onChange) props.onChange(e);
                            }}
                            scrollHeight={props.scrollHeight || '200px'} filter
                        />
                        {/* Den Clear-Button musste man selbst implementieren, weil PrimeReact dies bisher */}
                        {/* (min. bis Version 9.6.0) nicht im TreeSelect anbietet */}
                        {props.showClear && props.value &&
                            <div className={'tree-select-close'}>
                                <Button
                                    icon={'pi pi-times'}
                                    className={'p-button-text absolute'}
                                    aria-label={'Cancel'}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        const e = {value: null};
                                        if (props.onChange) props.onChange(e);
                                    }}
                                />
                            </div>}
                    </div>);
                break;
            case 'button':
                component = props.validationControl
                    ? (
                        <div className='field'>
                            {inputLabel}
                            <Controller
                                name={props.name} control={props.validationControl}
                                rules={props.validationRules || {required: t('required')}}
                                render={({field, fieldState}) =>
                                    (<Button
                                        {...props.options}
                                        key={'butt_' + 1}
                                        id={field.name}
                                        style={{backgroundColor: 'rgba(68, 72, 109, 0.07)'}}
                                        className={className + ' w-full p-button-outlined' + classNames({'p-invalid': fieldState.invalid})}
                                        disabled={props.disabled}
                                        name={field.name}
                                        label={props.value}
                                        onClick={(event: any) => {
                                            event.preventDefault();
                                            if (props.onClick) props.onClick(event);
                                        }}
                                    />)
                                }
                            />
                            {props.validationErrorMessage ? props.validationErrorMessage(props.name) : null}

                        </div>
                    )
                    : (<div className='field'>
                        {inputLabel}
                        <Button
                            {...props.options}
                            key={'butt_' + 1}
                            style={{backgroundColor: 'rgba(68, 72, 109, 0.07)'}}
                            className={className + ' w-full p-button-outlined'}
                            disabled={props.disabled}
                            name={props.name}
                            label={props.value}
                            onClick={(event: any) => {
                                event.preventDefault();
                                if (props.onClick) props.onClick(event);
                            }}
                        />
                    </div>);
                break;
            default:
                component = props.validationControl ?
                    (<div className='field'>
                        {inputLabel}
                        <Controller
                            name={props.name} control={props.validationControl}
                            rules={props.validationRules || {required: t('required')}}
                            render={({field, fieldState}) =>
                                (<InputText id={field.name}
                                    {...field}
                                    className={className + ' w-full ' + classNames({'p-invalid': fieldState.invalid})}
                                    disabled={props.disabled}
                                    value={props.value || (field.value === null ? '' : field.value)}
                                    onBlur={(e: any) => {
                                        if (props.onBlur) {
                                            props.onBlur(e);
                                        }
                                    }}
                                    onChange={(e: any) => {
                                        // Wenn das Inputfeld vom User komplett geleert wird, bleibt ein leerer String zurück.
                                        // Das würde dann so in die Datenbank geschrieben werden. Damit Null-Werte gespeichert
                                        // werden, wird der Wert auf null gesetzt.
                                        let _e = e;
                                        if (e.target.value === '') {
                                            _e = {
                                                ...e,
                                                target: {
                                                    value: null
                                                }
                                            };
                                        }
                                        field.onChange(_e);
                                        if (props.onChange) {
                                            props.onChange(_e);
                                        }
                                    }}
                                />)}/>
                        {props.validationErrorMessage ? props.validationErrorMessage(props.name) : null}

                    </div>) : (<div className='field'>
                        {inputLabel}
                        <InputText
                            {...props}
                            className={className + ' w-full'}
                            disabled={props.disabled}
                            name={props.name}
                            value={props.value}
                            onBlur={(e: any) => {
                                if (props.onBlur) {
                                    props.onBlur(e);
                                }
                            }}
                            onChange={(e: any) => {
                                // Wenn das Inputfeld vom User komplett geleert wird, bleibt ein leerer String zurück.
                                // Das würde dann so in die Datenbank geschrieben werden. Damit Null-Werte gespeichert
                                // werden, wird der Wert auf null gesetzt.
                                let _e = e;
                                if (e.target.value === '') {
                                    _e = {
                                        ...e,
                                        target: {
                                            value: null
                                        }
                                    };
                                }
                                if (props.onChange) {
                                    props.onChange(_e);
                                }
                            }}/>

                    </div>);
                break;
        }
    } else {
        component = props.validationControl
            ? (<div className='field'>
                <Controller
                    name={props.name}
                    control={props.validationControl}
                    rules={props.validationRules || {required: t('required')}}
                    render={({field, fieldState}) =>
                        (<>
                            <h5 className='p-0 m-0 mt-3 mb-1'>{props.label}</h5>
                            <div className='ml-2'>{props.value || field.value || '-'}</div>
                        </>)}
                />
                {props.validationErrorMessage ? props.validationErrorMessage(props.name) : null}
            </div>)
            : (<div className='field'>
                <>
                    <div className='flex'><h5 className='p-0 m-0 mt-3 mb-1'>{props.label}</h5>
                        {props.tooltip &&
                            <>
                                <Tooltip target=".pi" content={props.tooltip}/>
                                <i className="pi pi-info-circle pl-2 my-auto"/>
                            </>}
                    </div>
                    <div className='ml-2'>{props.value || '-'}</div>
                </>
            </div>);
    }

    return (
        <div className='custom-input w-full relative'>
            {component}
        </div>
    );
};

export default Input;
