/*
 * SensorSelectList.tsx
 * Author: lnappenfeld
 * Date: 07.07.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import Input from '../global/Input';
import {getSensorsAsTree} from '../functions/Metrology';
import {checkProjectType, ProjectType} from '../../functions/functionLibrary';

type Props = {
    type?: string,
    projectId: string,
    label: string,
    list?: any, /* {value, label} */
    value?: string,
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    name?: string,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    disabled?: boolean
}
export const SensorSelectList = (props: Props): JSX.Element => {

    const [list, setList] = useState<any>(props.list);

    useEffect(() => {
        setList(props.list);
        if (typeof props.list === 'undefined')
            if (props.type) {
                switch (props.type) {
                    case 'condition_monitoring':
                        sensorsForConditionMonitoring();
                        break;
                    default:
                        sensorsForMeasurement();
                        break;
                }
            } else {
                if (checkProjectType(ProjectType.MonitoringEverything)) {
                    sensorsForConditionMonitoring();
                } else if (checkProjectType(ProjectType.GeoMonitoring)) {
                    sensorsForMeasurement();
                } else if (checkProjectType(ProjectType.ConditionMonitoring)) {
                    sensorsForConditionMonitoring();
                }
            }

    }, []);


    // Mit Gruppen als oberste Ebene
    // Gruppen > Sensoren
    const sensorsForMeasurement = () => {
        getSensorsAsTree(props.projectId).then(result => {
            setList(result);
        });
    };

    // Mit Messmanagern (wenn es min. zwei davon gibt) oder Messgeräten als oberste Ebene
    // (Messmanager) > Messgeräte > Sensoren
    const sensorsForConditionMonitoring = () => {
        getSensorsAsTree(props.projectId, 'condition_monitoring').then(result => {
            setList(result);
        });
    };

    const findSensorById = (root: any, sensorId: string) => {
        // Überprüfe, ob das aktuelle Element die gesuchte ID hat
        // console.log('sensorId: ', sensorId)
        // console.log('root.sensor_id: ', root.sensor_id)
        if (root.key === sensorId) {
            return root;
        }

        // Durchsuche die Kindknoten des aktuellen Elements
        if (root.children)
            for (let i = 0; i < root.children.length; i++) {
                const foundElement: any = findSensorById(root.children[i], sensorId);
                if (foundElement) {
                    // Das Element wurde gefunden, gib es zurück
                    return foundElement;
                }
            }

        // Das Element wurde nicht gefunden
        return null;
    };

    const onSensorChange = (e: any) => {
        let sensor = null;
        for (let i = 0; i < list.length; i++) {
            sensor = findSensorById(list[i], e.value);
            if (sensor) {
                // Der Sensor wurde gefunden, beende die Schleife
                break;
            }
        }
        // console.log(sensor);
        if (props.setValue) props.setValue(e.value);
        if (props.onChange) props.onChange(sensor);
    };

    return (
        <Input
            name={props.name ? props.name : 'sensor_id'}
            edit={true}
            label={props.label}
            value={props.value}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            scrollHeight={'500px'}
            dropdownOptions={list} type={'treeSelect'}
            onChange={onSensorChange}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};
