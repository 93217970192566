import React, {useState, useEffect, useRef} from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FullScreen, FullScreenHandle } from 'react-full-screen';

export type ChartProps = {
  measurePoint: any;
  graphdata: any;

  handle: FullScreenHandle;

  isLoading: boolean;

};

function Chart({
    measurePoint,
    graphdata,
    isLoading,
    handle,
}: ChartProps) {
    const chartRef = useRef<any>(null);

    const colors = ['#FF530D', '#FFEE58', '#3F51B5', '#8D6E63', '#558B2F'];

    const graph = {
        chart: {
            zoomType: 'x',
            height: 610,
        },
        credits: {
            enabled: false,
        },
        title: {
            text: `${localStorage.getItem('component')}`,
        },
        subtitle: {
            text: '',
        },
        tooltip: {
            valueDecimals: 2,
        },
        xAxis: {
            type: 'datetime',
            label: {
                format: '%H:%M:%S.%L',
            },
        },
        yAxis: measurePoint.map((x: any) => {
            return {
                title: { text: x['point']},
                min: x['min'] === '' ? null : x['min'],
                max: x['max']=== '' ? null : x['max'],
                endOnTick: x['max'] === null ? true : false,
                startOnTick: x['min'] === null ? true : false
            };
        }),
        colors: colors,
        series: measurePoint.map((x: any, index: number) => {
            return {
                yAxis: index,
                lineWidth: 0.5,
                name: x['point'],
                data: graphdata[index],
            };
        }),
    };

    useEffect(()=>{

    },[]);
    return (
        <FullScreen handle={handle}>
            <div className="w-full mt-3 plot">
                {isLoading ? (
                    <ProgressSpinner className="result-spinner" />
                ) : (
                    <HighchartsReact highcharts={Highcharts} options={graph} ref={chartRef}/>
                )}
            </div>
        </FullScreen>
    );
}

export default Chart;
