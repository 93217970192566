/*
 * DurationInSecondsInput.tsx
 * Author: lnappenfeld
 * Date: 07.06.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import Input from '../../global/Input';
import {useTranslation} from 'react-i18next';

type Props = {
    channelTypeId: number, // [200, 202, 203, 205]
    signalTypeId: number, // [0,1,2]
    channelAddress: number, // [0-15]
    samplingRate: number, // ...
    label: string,
    value?: string | null,
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    name?: string,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    disabled?: boolean,
}

export const DurationInSecondsInput = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [min, setMin] = useState<number>(1);
    const [max, setMax] = useState<number>(30);
    const [step, setStep] = useState<number>(1);

    const CHANNEL_ADDRESS_MIN = 0;
    const CHANNEL_ADDRESS_BETWEEN = 7.5;
    const CHANNEL_ADDRESS_MAX = 15;

    useEffect(() => {
        console.log('channelTypeId : ', props.channelTypeId);
        console.log('signalTypeId  : ', props.signalTypeId);
        console.log('channelAddress: ', props.channelAddress);
        console.log('samplingRate  : ', props.samplingRate);
        switch (props.channelTypeId) {
            case 202: // ZEIT
                if (props.channelAddress >= CHANNEL_ADDRESS_MIN && props.channelAddress < CHANNEL_ADDRESS_BETWEEN) {
                    switch (props.signalTypeId) {
                        case 0:
                            switch (props.samplingRate) {
                                case 5120:
                                    setMin(1);
                                    setMax(30);
                                    setStep(1);
                                    break;
                                case 512:
                                    setMin(10);
                                    setMax(300);
                                    setStep(10);
                                    break;
                                case 51.2:
                                    setMin(100);
                                    setMax(3000);
                                    setStep(100);
                                    break;
                            }
                            break;
                        case 1:
                        case 2:
                            switch (props.samplingRate) {
                                case 5120:
                                    setMin(10);
                                    setMax(1020);
                                    setStep(10);
                                    break;
                                case 512:
                                    setMin(100);
                                    setMax(10200);
                                    setStep(100);
                                    break;
                                case 51.2:
                                    setMin(1000);
                                    setMax(102000);
                                    setStep(1000);
                                    break;
                                default:
                                    // error
                                    console.error('Fehler: Falsche Abtastrate!');
                                    break;
                            }
                            break;
                        default:
                            // error
                            console.error('Fehler: Falscher SignalTyp!');
                            break;
                    }
                } else if (props.channelAddress > CHANNEL_ADDRESS_BETWEEN && props.channelAddress <= CHANNEL_ADDRESS_MAX) {
                    switch (props.signalTypeId) {
                        case 0:
                            switch (props.samplingRate) {
                                case 1280:
                                case 128:
                                    setMin(5);
                                    setMax(60);
                                    setStep(5);
                                    break;
                                case 12.8:
                                    setMin(10);
                                    setMax(320);
                                    setStep(10);
                                    break;
                            }
                            break;
                        case 1:
                        case 2:
                            switch (props.samplingRate) {
                                case 1280:
                                    setMin(10);
                                    setMax(250);
                                    setStep(10);
                                    break;
                                case 128:
                                    setMin(10);
                                    setMax(1020);
                                    setStep(10);
                                    break;
                                case 12.8:
                                    setMin(100);
                                    setMax(10200);
                                    setStep(100);
                                    break;
                                default:
                                    // error
                                    console.error('Fehler: Falsche Abtastrate!');
                                    break;
                            }
                            break;
                    }
                } else {
                    // error
                    console.error('Fehler: Falsche Kanaladresse!');
                }
                break;
            case 205: // HKZT
                if (props.channelAddress >= CHANNEL_ADDRESS_MIN && props.channelAddress < CHANNEL_ADDRESS_BETWEEN) {
                    switch (props.signalTypeId) {
                        case 0:
                        case 1:
                        case 2:
                            switch (props.samplingRate) {
                                case 5120:
                                    setMin(1);
                                    setMax(30);
                                    setStep(1);
                                    break;
                                case 512:
                                    setMin(10);
                                    setMax(300);
                                    setStep(10);
                                    break;
                                case 51.2:
                                    setMin(100);
                                    setMax(3000);
                                    setStep(100);
                                    break;
                                default:
                                    // error
                                    console.error('Fehler: Falsche Abtastrate!');
                                    break;
                            }
                            break;
                        default:
                            // error
                            console.error('Fehler: Falscher SignalTyp!');
                            break;
                    }
                } else {
                    // error
                    console.error('Fehler: Falsche Kanaladresse!');
                }
                break;
            default:
                console.error('Fehler: Falscher KanalTyp!');
                break;
        }
    }, [props.channelTypeId, props.signalTypeId, props.channelAddress, props.samplingRate]);

    return (
        <Input
            name={props.name ? props.name : 'sampling_rate'}
            edit={true}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            tooltip={t('metrologyManagement:tooltips.durationInSeconds')}
            label={props.label}
            value={props.value}
            type={'number'}
            min={min} max={max} step={step}
            onChange={(e: any) => {
                let value = e.value;
                if (value % step !== 0) {
                    // console.log('!== 0');
                    value = Math.floor(value/step)*step;
                    e.value = Math.floor(value/step)*step;
                }

                if (props.setValue) props.setValue(value);
                if (props.onChange) props.onChange(e);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};
