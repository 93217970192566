/*
 * MeasurementData.tsx
 * Author: lnappenfeld
 * Date: 30.06.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChartLine, faFileExport} from '@fortawesome/free-solid-svg-icons';
import {checkPermission} from '../functions/functionLibrary';
import {permissions} from '../config/permissions';
import ViewButtons from '../components/global/ViewButtons';
import LayoutMainContentTabMenu from '../components/global/LayoutMainContentTabMenu';
import {ExportMeasurementDataOverview} from '../components/plot/ExportMeasurementDataOverview';
import PlotOverview from '../components/plot/PlotOverview';

type Props = {
    projectId: string,
}

const MeasurementData = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const menuItems = [
        {
            id: 'plots',
            label: t('measurementData:global.plots'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faChartLine}/>,
            component: <PlotOverview projectId={props.projectId}/>,
            visible: checkPermission(permissions.seeMeasuringData),
            buttons: (
                <ViewButtons title={t('')} items={[]}/>
            )
        },

        {
            id: 'exportData',
            label: t('measurementData:global.exporting'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faFileExport}/>,
            component: <ExportMeasurementDataOverview projectId={props.projectId}/>,
            visible: checkPermission(permissions.seeMeasuringData),
            buttons: (
                <ViewButtons title={t('')} items={[]}/>
            )
        }

    ];

    return (
        <>
            <LayoutMainContentTabMenu items={menuItems} showCard={false}/>
        </>
    );
};

export default MeasurementData;
