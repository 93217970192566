/*
 * ProjectGroupSelectList.tsx
 * Author: lnappenfeld
 * Date: 04.08.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState, useEffect} from 'react';
import Input from '../global/Input';
import {getProjectGroups, Nullable} from '../functions/Global';

type Props = {
    projectId: string,
    label: string,
    name?: string,
    value?: Nullable<string>,
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    disabled?: boolean,
}

const ProjectGroupSelectList = (props: Props):JSX.Element => {

    // Initialisiere mit undefined, damit das Skeletonloading funktioniert
    const [list, setList] = useState();

    useEffect(() => {
        getProjectGroups(props.projectId).then(result => {
            const tmp: any = [];
            result.forEach((key: any) => {
                tmp.push({
                    value: key.id,
                    label: key.name,
                });
            });
            setList(tmp);

        });
    }, []);

    return (
        <Input
            name={props.name ? props.name : 'project_group_id'}
            edit={true}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            label={props.label}
            value={props.value}
            dropdownOptions={list}
            scrollHeight={'350px'}
            type={'dropdown'}
            onChange={(e: any) => {
                if (props.setValue) props.setValue(e.value);
                if (props.onChange) props.onChange(e);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};

export default ProjectGroupSelectList;