/*
 * NotificationActionMapForm.tsx
 * Author: lnappenfeld
 * Date: 05.05.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */


import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import Input from '../global/Input';
import {
    createNotificationAction,
    getActions,
    getNotifications,
    getNotificationsActions, getNotificationTemplates,
    NotificationActionMap, updateNotificationAction
} from '../functions/Notification';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {Nullable} from '../functions/Global';

type Data = {
    value: string,
    label: string,
    actionTypeId?: string,
    notificationTypeId?: string
}

type Props = {
    data: Nullable<any>,
    projectId: string,
    onFinished: (success: boolean) => void
}

const NotificationActionMapForm = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);
    const notificationId:any = (props.data !== null) ? props.data.notification_id : null;
    const actionId = (props.data !== null) ? props.data.action_id : null;

    const [notifications, setNotifications] = useState<Data[]>([]);
    const [actions, setActions] = useState<Data[]>([]);
    const [templates, setTemplates] = useState<Data[]>([]);
    const [notificationsActions, setNotificationsActions] = useState<NotificationActionMap[]>([]);

    const [selectableNotifications, setSelectableNotifications] = useState<Data[]>([]);
    const [selectableActions, setSelectableActions] = useState<Data[]>([]);
    const [selectableTemplates, setSelectableTemplates] = useState<Data[]>([]);

    const [initialized, setInitialized] = useState<boolean>(false);

    const notificationStatus = [
        {name: t('disabled'), value: false},
        {name: t('enabled'), value: true},
    ];

    const defaultValues = {
        'notification_id': notificationId,
        'action_id': props.data === null ? '' : props.data.action_id,
        'notification_template_id': props.data === null ? '' : props.data.notification_template_id,
        'is_enabled': props.data === null ? true : props.data.is_enabled,
    };

    const {control, formState: {errors}, handleSubmit, getValues, watch, setValue} = useForm({defaultValues});

    watch('is_enabled');

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    useEffect(() => {
        let isMounted = true;

        getNotifications(props.projectId).then(result => {
            const tmp: any = [];
            result.forEach((key: any) => {
                tmp.push({
                    value: key.id,
                    label: key.name,
                    notificationTypeId: key.notification_type_id
                });
            });
            if (isMounted) {
                setNotifications(tmp);
                setSelectableNotifications(tmp);
            }
        });

        getActions(props.projectId, t).then(result => {
            const tmp: any = [];
            result.forEach((key: any) => {
                tmp.push({
                    value: key.action_id,
                    label: key.action_name,
                    actionTypeId: key.action_type_id
                });
            });
            if (isMounted) {
                setActions(tmp);
                setSelectableActions(tmp);
            }
        });

        getNotificationsActions(props.projectId).then(result => {
            if (result && isMounted)
                setNotificationsActions(result);
        });

        getNotificationTemplates(props.projectId).then(result => {
            const tmp: any = [];
            result.forEach((key: any) => {
                tmp.push({
                    value: key.template_id,
                    label: key.name,
                    actionTypeId: key.action_type_id,
                    notificationTypeId: key.notification_type_id
                });
            });
            if (isMounted) {
                setTemplates(tmp);
                setSelectableTemplates(tmp);
            }
        });

        return () => { isMounted = false; }; // cleanup toggles value, if unmounted
    }, []);

    // setze auswählbare Templates manuell, wenn eine Zuordnung bearbeitet werden soll (nicht create)
    // wird nur einmal am Anfang ausgeführt
    useEffect(() => {
        if (!initialized && notificationId !== null &&
            notifications.length > 0 && actions.length > 0 && templates.length > 0  ) {
            updateSelectableTemplates(actionId, notificationId);
            setInitialized(true);
        }
    }, [notifications, actions, templates]);

    const onSubmit = (data: any) => {
        saveData(data);
    };

    const saveData = (data: any) => {
        if (notificationId !== null) {
            
            /** [31.05.2023]
             *  Man kann zur Zeit nur das Template aktualisieren, weil notification_id und action_id den primarykey
             *  bilden und jeweils die vorherige Id nicht an den Endpunkt gesendet wird. Deswegen sind die Dropdowns für
             *  die Notification und für die Action gesperrt. Hierzu muss diese Kompoenten und der entsprechende
             *  Endpunkt angepasst werden.
             */
            updateNotificationAction(props.projectId,data).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'),result.error);
                } else {
                    showMessageOnSuccess(t('success'), t('alarmManagement:toasts.notificationActionUpdated'));
                    props.onFinished(true);
                }
            });
        } else {
            createNotificationAction(props.projectId, data).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'),result.error);
                } else {
                    showMessageOnSuccess(t('success'), t('alarmManagement:toasts.notificationActionCreated'));
                    props.onFinished(true);
                }
            });
        }
    };

    const setValuesIfUndefined = () => {
        setValue('notification_id', '');
        setSelectableNotifications(notifications);
        setValue('action_id', '');
        setSelectableActions(actions);
        setValue('notification_template_id', '');
        setSelectableTemplates(templates);
    };

    const updateSelectableActions = (notificationId: string) => {
        const currentActions = [] as Data[];
        for (const action of actions) {
            let mappingExists = false;
            for (const notificationAction of notificationsActions) {
                if (notificationId === notificationAction.notification_id &&
                    action.value === notificationAction.action_id) {
                    mappingExists = true;
                    break;
                }
            }
            if (!mappingExists) {
                currentActions.push(action);
            }
        }
        setSelectableActions(currentActions);
    };

    const updateSelectableNotifications = (actionId: string) => {
        const currentNotifications = [] as Data[];
        for (const notification of notifications) {
            let mappingExists = false;
            for (const notificationAction of notificationsActions) {
                if (actionId === notificationAction.action_id &&
                    notification.value === notificationAction.notification_id) {
                    mappingExists = true;
                    break;
                }
            }
            if (!mappingExists) {
                currentNotifications.push(notification);
            }
        }
        setSelectableNotifications(currentNotifications);
    };

    // Zeige nur Templates zum passenden ActionType und NotificationType, falls bereits ausgewählt
    const updateSelectableTemplates = (localActionId: string | null, localNotificationId: any) => {
        const currentTemplates = [] as Data[];
        const action = actions.find(value => value.value === localActionId) as Data;
        const notification = notifications.find(value => value.value === localNotificationId) as Data;
        const template = templates.find(value => value.value === getValues('notification_template_id')) as Data;

        if (typeof action === 'undefined' && typeof notification === 'undefined') {
            return;
        } else if (typeof action === 'undefined') {
            for (const template of templates) {
                if (template.notificationTypeId === notification.notificationTypeId) {
                    currentTemplates.push(template);
                }
            }
            // setze template zurück, wenn ein template ausgewählt ist, und die notificationTypeId nicht mit der
            // vom Template übereinstimmt
            if (typeof template !== 'undefined') {
                if (template.notificationTypeId !== notification.notificationTypeId) {
                    setValue('notification_template_id', '');
                }
            }
        } else if (typeof notification === 'undefined') {
            for (const template of templates) {
                if (template.actionTypeId === action.actionTypeId) {
                    currentTemplates.push(template);
                }
            }
            // setze template zurück, wenn ein template ausgewählt ist, und die actionTypeId nicht mit der
            // vom Template übereinstimmt
            if (typeof template !== 'undefined') {
                if (template.actionTypeId !== action.actionTypeId) {
                    setValue('notification_template_id', '');
                }
            }
        } else {
            for (const template of templates) {
                if (template.actionTypeId === action.actionTypeId &&
                    template.notificationTypeId === notification.notificationTypeId) {
                    currentTemplates.push(template);
                }
            }
            // setze template zurück, wenn ein template ausgewählt ist, und die notificationTypeId oder die actionTypeId
            // nicht mit der vom Template übereinstimmt
            if (typeof template !== 'undefined') {
                if (template.actionTypeId !== action.actionTypeId || template.notificationTypeId !== notification.notificationTypeId) {
                    setValue('notification_template_id', '');
                }
            }
        }

        setSelectableTemplates(currentTemplates);
    };

    const onNotificationChanged = (e: {value: string}) => {
        const notificationId = e.value;
        // Check if dropdown was cleared
        if (notificationId === undefined) {
            setValuesIfUndefined();
        }
        updateSelectableActions(notificationId);
        updateSelectableTemplates(getValues('action_id'), notificationId);
    };

    const onActionChanged = (e: {value: string}) => {
        const actionId = e.value;
        // Check if dropdown was cleared
        if (actionId === undefined) {
            setValuesIfUndefined();
        }
        updateSelectableNotifications(actionId);
        updateSelectableTemplates(actionId, getValues('notification_id'));
    };

    return (
        <form id='formEditNotificationActionMap' onSubmit={handleSubmit(onSubmit)}>
            <div className={'card'}>
                <Input
                    name={'notification_id'}
                    scrollHeight={'450px'}
                    edit={true}
                    disabled={notificationId !== null}
                    showClear={getValues('notification_id') !== ''}
                    dropdownOptions={selectableNotifications}
                    type={'dropdown'}
                    label={t('notification')}
                    onChange={e => {
                        setValue('notification_id', e.value);
                        onNotificationChanged(e);
                    }}
                    validationControl={control}
                    validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: t('notification') + ' ' + t('input:required')}}
                />
                <Input
                    name={'action_id'}
                    scrollHeight={'450px'}
                    edit={true}
                    disabled={notificationId !== null}
                    showClear={getValues('action_id') !== ''}
                    dropdownOptions={selectableActions}
                    type={'dropdown'}
                    label={t('action')}
                    onChange={e => {
                        setValue('action_id', e.value);
                        onActionChanged(e);
                    }}
                    validationControl={control}
                    validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: t('notifications:action.name') + ' ' + t('input:required')}}
                />
                <Input
                    name={'notification_template_id'}
                    scrollHeight={'450px'}
                    edit={true}
                    // disabled={notificationId !== null}
                    // showClear={getValues('action_id') !== ''}
                    dropdownOptions={selectableTemplates}
                    type={'dropdown'}
                    label={t('template')}
                    onChange={e => {
                        setValue('notification_template_id', e.value);
                        // onActionChanged(e);
                    }}
                    validationControl={control}
                    validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: t('template') + ' ' + t('input:required')}}
                />
                {/* <NotificationTemplateSelectList*/}
                {/*    projectId={props.projectId}*/}
                {/*    name={'notification_template_id'}*/}
                {/*    label={t('template')}*/}
                {/*    onChange={e => setValue('notification_template_id', e.value)}*/}
                {/*    validationControl={control}*/}
                {/*    validationErrorMessage={getFormErrorMessage}*/}
                {/*    validationRules={{required: t('template') + ' ' + t('input:required')}}*/}
                {/* />*/}
                <Input
                    edit={true}
                    label={t('status')}
                    name={'is_enabled'}
                    type={'selectButton'}
                    value={getValues('is_enabled')}
                    onChange={e => setValue('is_enabled', e.value)}
                    selectButtonOptions={notificationStatus}
                />
            </div>
        </form>
    );

};

export default NotificationActionMapForm;
