/*
 * NullMeasurementSelectList.tsx
 * Author: lnappenfeld
 * Date: 18.08.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState, useEffect, useRef} from 'react';
import Input from '../global/Input';

type Props = {
    label: string,
    value?: number,
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    name?: string,
    disabled?: boolean,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
}

const NullMeasurementSelectList = (props: Props): JSX.Element => {

    const options = [
        {value: '0', label: 'Start der Messung'},
        {value: '1', label: 'Beginn des Auswertezeitraums'},
    ];

    // set value in parent component
    // useEffect(() => {
    //     const option = options.filter((x: any) => {
    //         return x.value === props.value;
    //     })[0];
    //     if (props.setValue) props.setValue(option);
    //     if (props.onChange) props.onChange(option);
    // }, []);

    return (
        <Input
            name={props.name ? props.name : 'null_measurement'}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            edit={true}
            label={props.label}
            value={props.value}
            dropdownOptions={options}
            type={'dropdown'}
            onChange={(e: any) => {
                const option = options.filter((x: any) => {
                    return x.value === e.value;
                })[0];
                if (props.setValue) props.setValue(option);
                if (props.onChange) props.onChange(option);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );

};

export default NullMeasurementSelectList;

