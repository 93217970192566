import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
// internationalization support
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import locale from './translations/locale.json';
import App from './App';
import {BrowserRouter as Router} from 'react-router-dom';
import store from './redux/store';
import { Provider } from 'react-redux';
import keycloakfetch from './functions/keycloakfetch';
import settings from './config/settings';
// init i18next

function makeLocale(obj: any, deObj: any, enObj: any, esObj: any) {
    for (const key in obj) {
        if (typeof deObj[key] === 'undefined') {
            deObj[key] = {};
            enObj[key] = {};
            esObj[key] = {};
        }
        if (typeof obj[key]['de'] === 'undefined') {
            [deObj[key], enObj[key], esObj[key]] = makeLocale(obj[key], deObj[key], enObj[key], esObj[key]);
        } else {
            // @ts-ignore
            deObj[key] = obj[key]['de'];
            // @ts-ignore
            enObj[key] = obj[key]['en'];
            // @ts-ignore
            esObj[key] = obj[key]['es'];
        }
    }
    return [deObj, enObj, esObj];
}

const [de, en, es] = makeLocale(locale, {}, {}, {});

i18next
    .use(initReactI18next)
    .init({
        lng: navigator.language, // First user browser language
        fallbackLng: 'en',
        resources: {
            de: de,
            en: en,
            es: es,
        },
        // ns: ['common', 'roles'],
        saveMissing: true,
        missingKeyHandler: (language, namespace, key, fallbackValue) => {
            // console.log('Fehlende Übersetzung:', key);
        },
    })
    .then();

const BaseName = '/';
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router basename={BaseName}>
                <App/>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
