import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';

export type Nullable<T> = T | null;

export async function getProjectGroups(projectId: string) {
    let retValue: any = [];
    const endpointStr = '/getProjectGroups?project_id=' + projectId;
    await keycloakfetch.get(settings.apiUrl + endpointStr).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getTimezones() {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getTimeZoneList').then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

/* *********************************************************************************************************************
 * Files
 **********************************************************************************************************************/

export async function getFile(projectId: Nullable<string>, fileId: Nullable<string>): Promise<any> {
    let retValue: any = {};
    if (fileId === null)
        return {data: null};

    await keycloakfetch.get(settings.apiUrl + '/getFile?project_id=' + projectId + '&file_id=' + fileId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getFiles(projectId: Nullable<string>): Promise<any> {
    let retValue: any = {};
    await keycloakfetch.get(settings.apiUrl + '/getFiles?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function insertFile(data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/insertFile', data).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function deleteFile(projectId: Nullable<string>, fileId: string) {
    let retValue: any = [];
    const data = {
        'project_id': projectId,
        'file_id': fileId
    };
    await keycloakfetch.delete(settings.apiUrl + '/deleteFile', data).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

/* *********************************************************************************************************************
 * Locations
 **********************************************************************************************************************/

export interface Location {
    'epsg_code': Nullable<number>,
    'epsg_code_name': Nullable<string>,
    'geo_position': any,
    'geo_position_x': Nullable<number>,
    'geo_position_y': Nullable<number>,
    'geo_position_z': Nullable<number>,
    'location_zoom': Nullable<number>,
    'is_visible': boolean,
    'location_description': Nullable<string>,
    'location_id': string,
    'location_name': string,
    'project_id': string,
    'project_group_id': string,
    'project_group_name': string,
    'transformation': Nullable<string>,
    'custom_link': string
}

export async function getEPSGCodes(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getEPSGCodes?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getLocation(projectId: string, locationId: string | null) {
    let retValue: any = {};
    await keycloakfetch.get(settings.apiUrl + '/getLocation?project_id=' + projectId + '&location_id=' + locationId).then(result => {
        if (result)
            retValue = result[0];
    });
    return retValue;
}

export async function getLocations(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getLocations?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getWeatherLocations(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getWeatherLocations?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function updateLocation(data: any) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/updateLocation', data).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createLocation(data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/createLocation', data).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function deleteLocation(projectId: string, locationIds: string[]) {
    let retValue: any = [];
    await keycloakfetch.delete(settings.apiUrl + '/deleteLocation', {
        'project_id': projectId,
        'location_ids': locationIds
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

/* *********************************************************************************************************************
 * Grafana Dashboards
 **********************************************************************************************************************/

export type GrafanaDashboard = {
    'project_id': string
    'dashboard_id': string
    'name': Nullable<string>
    'mobile': boolean
    'count': number
}

export async function getGrafanaDashboards(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getGrafanaDashboards?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

let user: any = null;

export function getUserFromLocalStorage() {
    if (user === null) {
        user = JSON.parse(localStorage.getItem('user') as string);
        if (user.settings === null) {
            user.settings = {};
        }
    }

    return user;
}

export async function setUserSettings(key: string, value: any, projectId?: string) {
    let tmp_user = getUserFromLocalStorage();

    if (projectId) {
        if (!tmp_user.settings[projectId]) {
            tmp_user.settings[projectId] = {};
        }

        tmp_user.settings[projectId][key] = value;
    } else {
        tmp_user.settings[key] = value;
    }

    await keycloakfetch.put(settings.apiUrl + '/updateUserSettings', tmp_user.settings).then(result => {
        if (result) {
            localStorage.setItem('user', JSON.stringify(tmp_user));

            user = JSON.parse(localStorage.getItem('user') as string);
        }
    });
}

export async function getSpatialBookmarks(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getSpatialBookmarks?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getSpatialBookmark(projectId: string, bookmarkId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getSpatialBookmark?project_id=' + projectId + '&bookmark_id=' + bookmarkId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

