import React, {useState, useEffect, useRef} from 'react';
import {classNames} from 'primereact/utils';
import {useNavigate, useParams} from 'react-router-dom';
import Topbar from './global/Topbar';
import Breadcrumb from './global/Breadcrumb';
import Menu from './global/Menu';
import PrimeReact from 'primereact/api';
import {Tooltip} from 'primereact/tooltip';
import {checkPermission, checkProjectType, ProjectType} from '../functions/functionLibrary';
import {permissions} from '../config/permissions';
import {Badge} from 'primereact/badge';
import ProjectManagement from './ProjectManagement';
import {getProject} from '../components/functions/Project';
import NotificationManagement from './NotificationManagement';
import {Metrology} from './Metrology';
import MeasurementData from './MeasurementData';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ConfirmDialog} from 'primereact/confirmdialog';
import {getAlarms} from '../components/functions/Notification';
import {getMyPermissions} from '../components/functions/UserManagement';
import {ProjectOverview} from '../components/projectManagement/ProjectOverview';
import {
    faBell,
    faChartLine,
    faCog,
    faDigitalTachograph,
    faFileInvoice,
    faFolderClosed,
    faHome,
    faRadiation,
    faUsersCog,
    faMapLocation,
    faDashboard,
    faCity,
    faFileExport,
    faCube,
    faWaveSquare,
    faTableCells,
    faClipboardQuestion,
    faGears, faFile, faFileInvoiceDollar
} from '@fortawesome/free-solid-svg-icons';
import CurrentUserSettings from '../components/userManagement/CurrentUserSettings';
import UserManagement from './UserManagement';
import GrafanaDashboard from './GrafanaDashboard';
import AlarmsOverview from '../components/notification/AlarmsOverview';
import GeoMonitoring from './GeoMonitoring';
import ConditionMonitoring from './ConditionMonitoring';
import ReportManagement from './ReportManagement';
import {
    getSpatialBookmark,
    getUserFromLocalStorage,
    setUserSettings
} from '../components/functions/Global';
import {showMessageOnError} from '../components/global/CustomToast';
import {hideWaitAnimation, showWaitAnimation} from '../components/global/CustomWaitAnimation';
import Cesium from './Cesium';
import Limits from './Limits';
import PlotComponent from '../components/conditionMonitoring/PlotComponent';
import PlotComponentSonagram from '../components/conditionMonitoring/PlotComponentSonagram';
import Wiki from './Wiki';
import {setAlarmArray} from '../redux/GeomonitoringSlice';
import {useAppDispatch} from '../redux/hooks';
import {SystemManagement} from './SystemManagement';
import {Nextcloud} from './Nextcloud';
import Scanner from './Scanner';
import {Billing} from './Billing';

type Props = {
    keycloakToken: any,
    currentUser: any,
    colorScheme?: string
}
let refreshMenu = false;
export const setRefreshMenu = () => {
    refreshMenu = true;
};
const App = (props: Props) => {
    // Primefaces
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const [menuMode, setMenuMode] = useState('sidebar');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [ripple, setRipple] = useState(true);
    const [sidebarStatic, setSidebarStatic] = useState(getUserFromLocalStorage().settings.menu || false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(getUserFromLocalStorage().settings.menu || false);
    const [pinActive, setPinActive] = useState(getUserFromLocalStorage().settings.menu || false);
    const [activeInlineProfile, setActiveInlineProfile] = useState(false);
    const [resetActiveIndex, setResetActiveIndex] = useState<boolean>(false);
    const copyTooltipRef = useRef<any>();
    const dispatch = useAppDispatch();

    // DMT
    const {t} = useTranslation(['common']);
    const keycloakToken = props.keycloakToken;
    const [selectedProject, setSelectedProject] = useState<any>(null);
    const [alarmBadges, setAlarmBadges] = useState<any[]>([]);
    const [availableAlarms, setAvailableAlarms] = useState([]);
    const [permissionsLoaded, setPermissionsLoaded] = useState<boolean>(false);
    const [customBreadcrumb, setCustomBreadcrumb] = useState<any>(null);
    // Wenn man nur einem Projekt zugewiesen ist, sollte man den Menupunkt 'Projekte' nicht sehen können
    const [hideMenuitemProjects, setHideMenuitemProjects] = useState<boolean>(false);

    const navigate = useNavigate();
    const params = useParams();
    PrimeReact.ripple = true;

    const theme = localStorage.getItem('Theme');

    const [colorScheme, setColorScheme] = useState(theme !== null && theme !== 'undefined' ? theme : 'dark');


    const hash_geomonitoring = '#geomonitoring';
    const hash_cesium = '#cesium';
    const hash_wiki = '#wiki';
    const hash_scanner = '#scanner';

    useEffect(() => {
        localStorage.removeItem('permissions');
        if (params.bookmarkId && params.projectId) {
            getSpatialBookmark(params.projectId, params.bookmarkId).then(bookmarks => {
                if (params.projectId)
                    getProject(params.projectId).then(project => {
                        if (project.error)
                            navigate('/accessdenied');
                        project.spatialBookmark = bookmarks;
                        setSelectedProject(project);
                    });
            });
        } else if (selectedProject === null && params.projectId) {
            getProject(params.projectId).then(project => {
                if (project.error)
                    navigate('/accessdenied');
                setSelectedProject(project);
            });
        } else if (selectedProject === null && location.hash === '') {
            navigate('/#projects');
        }
    }, []);

    useEffect(() => {
        if (selectedProject !== null) showWaitAnimation();
        if (window.location.hash === '#projects')
            setAvailableAlarms([]);

        let projectId: any = 'none';
        if (selectedProject !== null)
            projectId = selectedProject.id;
        _getMyPermissions(projectId).then(() => {
            if (projectId !== 'none') {
                _getAlarms(projectId);
            }
            const userSettings = {...props.currentUser};
            if (projectId && props.currentUser.settings && props.currentUser.settings[projectId])
                userSettings.settings = props.currentUser.settings[projectId];
            localStorage.setItem('user', JSON.stringify(userSettings));
            _setProjectToLocalStorage(selectedProject);
            hideWaitAnimation();
        });
    }, [selectedProject]);

    useEffect(() => {
        setAvailableMenuOptions(menuOptions);
    }, [alarmBadges]);

    let alarmInterval: any = null;
    useEffect(() => {
        // todo: replace with EventSource function
        if (availableAlarms.length > 0) {
            alarmInterval = setInterval(() => {
                _getAlarms(selectedProject.id);
            }, (60000));
        }
        return () => {
            if (alarmInterval !== null)
                clearInterval(alarmInterval);
        };
    }, [availableAlarms]);

    const _setProjectToLocalStorage = (project: any) => {
        // console.log('project', project);
        if (project === null) {
            localStorage.removeItem('project');
        } else {
            // Speicher erstmal nur den Projekttyp, bis weiteres gebraucht wird
            const _project = {
                'project_type_id': project.project_type_id
            };
            localStorage.setItem('project', JSON.stringify(_project));
        }
    };

    const _getAlarms = async (projectId: string) => {
        // todo: replace with EventSource function
        if (window.location.hash === '#projects') {
            dispatch(setAlarmArray([]));
            setAvailableAlarms([]);
            return [];
        }
        if (!checkPermission(permissions.seeAlarms)) {
            dispatch(setAlarmArray([]));
            setAvailableAlarms([]);
            return [];
        }

        let alarms: any = [];
        await getAlarms(projectId).then(alarm => {
            alarms = alarm;
            dispatch(setAlarmArray(alarm));
            setAvailableAlarms(alarm);
            let aW = 0;
            let aA = 0;
            alarm.forEach((obj: any) => {
                switch (obj.status) {
                    case 'TIMEOUT':
                        aW++;
                        break;
                    case 'OK':
                        break;
                    case 'WARNING':
                        aW++;
                        break;
                    default:
                        aA++;
                        break;
                }
            });

            const badgeList = [];

            if (aA > 0) {
                badgeList.push({value: aA, severity: 'danger'});
            }

            if (aW > 0) {
                badgeList.push({value: aW, severity: 'warning'});
            }

            setAlarmBadges(badgeList);

            // setAlarmBadge({value: aA, severity: 'danger'});
            // setAlarmBadge({value: aW, severity: 'warning'});
            // setAlarmBadge({value: aO, severity: 'success'});
        });
        return alarms;
    };

    const _getMyPermissions = async (projectId: string) => {
        await getMyPermissions(projectId).then(result => {
            if (result.error) {
                showMessageOnError(t('error'), result.error);
            } else {
                localStorage.setItem('permissions', JSON.stringify(result));
                setAvailableMenuOptions(menuOptions);
                setPermissionsLoaded(true);
            }
        });
    };

    const onRightMenuClick = () => {
        rightMenuClick = true;
    };

    const onRightMenuActiveChange = (active: any) => {
        setRightMenuActive(active);
    };

    let rightMenuClick: any;
    let configClick: any;
    let menuClick: any;
    let searchClick: boolean = false;
    let topbarItemClick: any;

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        setResetActiveIndex(true);
        setMenuActive(false);
    }, [menuMode]);

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!topbarItemClick) {
            setTopbarMenuActive(false);
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
                setResetActiveIndex(true);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                setOverlayMenuActive(false);
                setStaticMenuMobileActive(false);
            }

            hideOverlayMenu();
            unblockBodyScroll();
        }

        if (!rightMenuClick) {
            setRightMenuActive(false);
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        topbarItemClick = false;
        menuClick = false;
        configClick = false;
        rightMenuClick = false;
        searchClick = false;
    };

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    };

    const onMenuModeChange = (menuMode: any) => {
        setMenuMode(menuMode);
        setOverlayMenuActive(false);
    };

    const onRightMenuButtonClick = () => {
        rightMenuClick = true;
        setRightMenuActive(true);
    };

    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = (event: any) => {
        setConfigActive((prevState) => !prevState);
        configClick = true;
        event.preventDefault();
    };

    const onRippleChange = (e: any) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onMenuButtonClick = (event: any) => {
        menuClick = true;

        if (isOverlay()) {
            setOverlayMenuActive((prevState: any) => !prevState);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive((prevState) => !prevState);
        } else {
            setStaticMenuMobileActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const onTopbarItemClick = (event: any) => {
        topbarItemClick = true;
        setTopbarMenuActive((prevState) => !prevState);
        hideOverlayMenu();
        event.preventDefault();
    };

    useEffect(() => {
        setUserSettings('menu', sidebarStatic);
    }, [sidebarStatic]);

    const onToggleMenu = (event: any) => {
        menuClick = true;

        if (overlayMenuActive) {
            setOverlayMenuActive(false);
        }

        if (sidebarActive) {
            setSidebarStatic((prevState: any) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarMouseOver = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setSidebarActive(isDesktop());
            setTimeout(() => {
                setPinActive(isDesktop());
            }, 200);
        }
    };

    const onSidebarMouseLeave = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setTimeout(() => {
                setSidebarActive(false);
                setPinActive(false);
            }, 250);
        }
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onChangeActiveInlineMenu = (event: any) => {
        setActiveInlineProfile((prevState) => !prevState);
        event.preventDefault();
    };

    const onRootMenuItemClick = () => {
        setMenuActive((prevState) => !prevState);
    };

    const onMenuItemClick = (event: any) => {
        setCustomBreadcrumb(null);
        if (!event.item.items) {
            hideOverlayMenu();
            setResetActiveIndex(true);
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isOverlay = () => {
        return menuMode === 'overlay';
    };

    const isDesktop = () => {
        return window.innerWidth > 991;
    };

    /* const onInputClick = () => {
        searchClick = true;
    };

    const breadcrumbClick = () => {
        searchClick = true;
        setSearchActive(true);
    }; */

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const layoutClassName = classNames('layout-wrapper', {
        'layout-static': menuMode === 'static',
        'layout-overlay': menuMode === 'overlay',
        'layout-overlay-active': overlayMenuActive,
        'layout-slim': menuMode === 'slim',
        'layout-horizontal': menuMode === 'horizontal',
        'layout-active': menuActive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-sidebar': menuMode === 'sidebar',
        'layout-sidebar-static': menuMode === 'sidebar' && sidebarStatic,
        'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
        'p-ripple-disabled': !ripple
    });

    const menuOptions = [
        {
            id: 'projects',
            label: t('projects'),
            icon: <FontAwesomeIcon className="sidebar-icon" icon={faHome}/>,
            command: () => {
                if (alarmInterval !== null)
                    clearInterval(alarmInterval);
                setAvailableAlarms([]);
                setSelectedProject(null);
                setAlarmBadges([]);
                navigate('/#projects');
            },
            projectRequired: false,
            component:
                <ProjectOverview
                    keycloakToken={keycloakToken}
                    setSelectedProject={setSelectedProject}
                    setHideMenuitemProjects={setHideMenuitemProjects}
                />,
            permission: null,
            visible: () => {
                return !hideMenuitemProjects;
            }
        },
        {
            id: 'geomonitoring',
            label: 'Geo Monitoring', // t('map'),
            icon: <FontAwesomeIcon className="sidebar-icon" icon={faMapLocation}/>,
            command: () => {
                window.location.hash = hash_geomonitoring;
            },
            projectRequired: true,
            component: <></>,
            visible: () => {
                return (checkPermission(permissions.seeLayer) && selectedProject !== null && checkProjectType(ProjectType.GeoMonitoring) && selectedProject.location_id !== null);
            }
        },
        {
            id: 'conditionomonitoring',
            label: 'Condition Monitoring',
            icon: <FontAwesomeIcon className="sidebar-icon" icon={faCity}/>,
            command: () => {
                window.location.hash = '#conditionomonitoring';
            },
            projectRequired: true,
            component: <ConditionMonitoring
                projectId={selectedProject ? selectedProject.id : ''}
                colorTheme={colorScheme}
                setCustomBreadcrumb={setCustomBreadcrumb}
            />,
            visible: () => {
                return (selectedProject !== null && checkProjectType(ProjectType.ConditionMonitoring)); // && selectedProject.elements !== null);
            }
        },
        {
            id: 'limits',
            label: t('measurementData:global.limits'),
            icon: <FontAwesomeIcon className="sidebar-icon" icon={faWaveSquare}/>,
            command: () => {
                window.location.hash = '#limits';
            },
            projectRequired: true,
            component: <PlotComponent projectId={selectedProject ? selectedProject.id : ''}/>,
            visible: () => {
                return (selectedProject !== null && checkProjectType(ProjectType.ConditionMonitoring) && selectedProject.id === '9b8bb822-ec3b-4dad-a224-500ad9f7cefc'); // && selectedProject.elements !== null);
            }
        },
        {
            id: 'sonagram',
            label: t('measurementData:global.sonagram'),
            icon: <FontAwesomeIcon className="sidebar-icon" icon={faTableCells}/>,
            command: () => {
                window.location.hash = '#sonagram';
            },
            projectRequired: true,
            component: <PlotComponentSonagram projectId={selectedProject ? selectedProject.id : ''}/>,
            visible: () => {
                return (selectedProject !== null && checkProjectType(ProjectType.ConditionMonitoring) && selectedProject.id === '9b8bb822-ec3b-4dad-a224-500ad9f7cefc'); // && selectedProject.elements !== null);
            }
        },
        {
            id: 'dashboards',
            label: t('projectManagement:global.grafanaDashboards'),
            icon: <FontAwesomeIcon className="sidebar-icon" icon={faDashboard}/>,
            command: () => {
                window.location.hash = '#dashboards';
            },
            projectRequired: true,
            component: <GrafanaDashboard projectId={selectedProject ? selectedProject.id : {}}/>,
            visible: () => {
                return (selectedProject !== null && checkPermission(permissions.seeGrafanaDashboards) && selectedProject.grafana_dashboards !== null);
            }
        },
        {
            id: 'cesium',
            label: t('projectManagement:global.cesium'),
            icon: <FontAwesomeIcon className="sidebar-icon" icon={faCube}/>,
            command: () => {
                window.location.hash = hash_cesium;
            },
            projectRequired: true,
            component: <></>,
            visible: () => {
                return (
                    selectedProject !== null && checkProjectType(ProjectType.GeoMonitoring) &&
                    typeof selectedProject.cesium !== 'undefined' && selectedProject.cesium !== null &&
                    checkPermission(permissions.accessCesium)
                );
            }
        },
        {
            id: 'scanner',
            label: t('projectManagement:global.scanner'),
            icon: <FontAwesomeIcon className="sidebar-icon" icon={faCube}/>,
            command: () => {
                window.location.hash = hash_scanner;
            },
            projectRequired: true,
            component: <></>,
            visible: () => {
                return (
                    selectedProject !== null && checkProjectType(ProjectType.GeoMonitoring) &&
                    typeof selectedProject.scanner !== 'undefined' && selectedProject.scanner !== null &&
                    checkPermission(permissions.accessScanner)
                );
            }
        },
        {
            id: 'alarms',
            label: t('alarmManagement:global.alarms'),
            icon: <FontAwesomeIcon className="sidebar-icon" icon={faRadiation}/>,
            command: () => {
                window.location.hash = '#alarms';
            },
            projectRequired: true,
            component: <AlarmsOverview alarms={availableAlarms} project={selectedProject} key={'alarmsOverview'}/>,
            visible: () => {
                return (availableAlarms.length > 0 && checkProjectType(ProjectType.GeoMonitoring) && checkPermission(permissions.seeAlarms));
            },
            badge: <div>{alarmBadges.map(item => {
                return <Badge key={item.severity} value={item.value} severity={item.severity}/>;
            })}</div>
        },
        {
            id: 'measurementdata',
            label: t('measurementData:global.measurementData'),
            icon: <FontAwesomeIcon className="sidebar-icon" icon={faChartLine}/>,
            command: () => {
                window.location.hash = '#measurementdata';
            },
            projectRequired: true,
            component: <MeasurementData projectId={selectedProject ? selectedProject.id : ''}/>,
            visible: () => {
                // Menüpunkt 'Messdaten' werden vorerst (02.05.23) nur für GeoMonitoring und MonitoringEverything angezeigt,
                // da die Ansicht der Messdaten bei ConditionMonitoring unter Menüpunkt 'Conditionmonitoring' erfolgt
                return (
                    selectedProject !== null && checkPermission(permissions.seeMeasuringData) && checkProjectType(ProjectType.GeoMonitoring) &&
                    (selectedProject.safeguard_portmaster !== null /* todo: Bedingung für CM Daten */)
                );
            }
        },
        {
            id: 'wiki',
            label: t('projectManagement:global.wiki'),
            icon: <FontAwesomeIcon className="sidebar-icon" icon={faClipboardQuestion}/>,
            command: () => {
                window.location.hash = hash_wiki;
            },
            projectRequired: true,
            component: <></>,
            visible: () => {
                return (
                    selectedProject !== null &&
                    typeof selectedProject.wiki_url !== 'undefined' && selectedProject.wiki_url !== null &&
                    checkPermission(permissions.accessWiki)
                );
            }
        },
        {
            id: 'filedata',
            label: t('projectManagement:global.nextcloud'),
            icon: <FontAwesomeIcon className="sidebar-icon" icon={faFile}/>,
            command: () => {
                window.location.hash = '#filedata';
            },
            projectRequired: true,
            component: <Nextcloud nextcloudFolderId={selectedProject ? selectedProject.nextcloud : null}/>,
            visible: () => {
                return (
                    selectedProject !== null && selectedProject.nextcloud !== null &&
                    checkPermission(permissions.seeNextcloud)
                );
            }
        },
        {
            id: 'configuration',
            label: t('configuration'),
            icon: <FontAwesomeIcon className="sidebar-icon" icon={faCog}/>,
            projectRequired: true,
            // Innerhalb eines Projektes ist das Konfigurationsmenü immer sichtbar, außerhalb nur für Admins und
            // Projektersteller
            visible: () => {
                return (selectedProject !== null
                    ? true
                    : checkPermission(permissions.isAdmin) || checkPermission(permissions.createProject));
            },
            items: [
                {
                    id: 'project',
                    label: t('projectManagement:global.menuName'),
                    icon: <FontAwesomeIcon className="sidebar-icon" icon={faFolderClosed}/>,
                    command: () => {
                        window.location.hash = '#project';
                    },
                    projectRequired: true,
                    component: <ProjectManagement project={selectedProject} setProject={setSelectedProject}/>,
                    visible: () => {
                        return (selectedProject !== null
                            ? true
                            : checkPermission(permissions.isAdmin) || (checkPermission(permissions.createProject)));
                    }
                },
                {
                    id: 'userManagement',
                    label: t('userManagement:global.menuName'),
                    icon: <FontAwesomeIcon className="sidebar-icon" icon={faUsersCog}/>,
                    command: () => {
                        window.location.hash = '#userManagement';
                    },
                    projectRequired: true,
                    component: <UserManagement project={selectedProject}/>,
                    visible: () => {
                        // [14.08.2024] Die globalen Benutzer sind nur noch für Superuser zusehen
                        return (selectedProject !== null
                            ? checkPermission(permissions.isSuperuser) || checkPermission(permissions.seeUM)
                            : checkPermission(permissions.isSuperuser));
                    }
                },
                {
                    id: 'notifications',
                    label: t('alarmManagement:global.menuName'),
                    icon: <FontAwesomeIcon className="sidebar-icon" icon={faBell}/>,
                    command: () => {
                        // In CM-Projekten sind die beiden Tabreiter 'Kennwertüberwachungsaufgaben' und 'Überwachungsaufgaben'
                        // vorangestellt. Dann zeige die an. In GM-Projekten werden die Benachrichtigungsregeln initial angezeigt
                        window.location.hash = checkProjectType(ProjectType.ConditionMonitoring) ? '#notifications' : '#notifications_2';
                    },
                    projectRequired: true,
                    component: <NotificationManagement projectId={selectedProject ? selectedProject.id : ''}/>,
                    visible: () => {
                        return (selectedProject !== null && checkPermission(permissions.seeNotification));
                    }
                },
                {
                    id: 'reportManagement',
                    label: t('reportingManagement:global.menuName'),
                    icon: <FontAwesomeIcon className="sidebar-icon" icon={faFileInvoice}/>,
                    command: () => {
                        window.location.hash = '#reportManagement';
                    },
                    projectRequired: true,
                    component: <ReportManagement project={selectedProject}/>,
                    visible: () => {
                        return (selectedProject !== null && checkPermission(permissions.seeReports));
                    }
                },
                {
                    id: 'metrology',
                    label: t('metrologyManagement:global.menuName'),
                    icon: <FontAwesomeIcon className="sidebar-icon" icon={faDigitalTachograph}/>,
                    command: () => {
                        window.location.hash = '#metrology';
                    },
                    projectRequired: true,
                    component: <Metrology project={selectedProject ? selectedProject : ''}/>,
                    visible: () => {
                        return (selectedProject !== null && checkPermission(permissions.seeMetrology));
                    }
                },
                {
                    id: 'systemManagement',
                    label: t('systemManagement:global.menuName'),
                    icon: <FontAwesomeIcon className="sidebar-icon" icon={faGears}/>,
                    command: () => {
                        window.location.hash = '#systemManagement';
                    },
                    projectRequired: true,
                    component: <SystemManagement/>,
                    visible: () => {
                        // [14.08.2024] Die Systemeinstellungen sind nur noch für Superuser zusehen
                        return (selectedProject === null && checkPermission(permissions.isSuperuser));
                    }
                },
                {
                    id: 'billing',
                    label: t('billing:global.menuName'),
                    icon: <FontAwesomeIcon className="sidebar-icon" icon={faFileInvoiceDollar}/>,
                    command: () => {
                        window.location.hash = '#billing';
                    },
                    component: <Billing project={selectedProject}/>,
                    visible: () => {
                        return (selectedProject !== null
                            ? checkPermission(permissions.isSuperuser) || checkPermission(permissions.seeBilling)
                            : checkPermission(permissions.isSuperuser));
                    }
                }

            ]
        },
    ];


    const [availableMenuOptions, setAvailableMenuOptions] = useState(menuOptions);

    const getComponent = () => {
        let elm = null;
        let hash = window.location.hash;
        if (window.location.hash.indexOf('_') > -1)
            hash = window.location.hash.substring(0, window.location.hash.indexOf('_'));
        switch (hash) {
            case '#usersettings':
                elm = <CurrentUserSettings/>;
                break;
        }
        menuOptions.forEach((item: any) => {
            if (item.items) {
                item.items.forEach((item2: any) => {
                    if (item2.visible() && '#' + item2.id === hash) {
                        elm = item2.component;
                        return;
                    }
                });
            } else if (item.visible() && '#' + item.id === hash) {
                elm = item.component;
                return;
            }
        });
        return elm;
    };

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            {/* Add ConfirmDialog to DOM: Breaking change in PrimeReact 8.0.0, because of changes in the import package of
             the ReactDOM.render method of React version 18. See: https://github.com/primefaces/primereact/issues/2718*/}
            <ConfirmDialog/>
            {permissionsLoaded && <>
                <Tooltip
                    ref={copyTooltipRef}
                    target=".block-action-copy"
                    position="bottom"
                    content="Copied to clipboard"
                    event="focus"
                />
                <div className="layout-main ">
                    <Topbar
                        items={availableMenuOptions}
                        menuMode={menuMode}
                        colorScheme={props.colorScheme}
                        menuActive={menuActive}
                        topbarMenuActive={topbarMenuActive}
                        activeInlineProfile={activeInlineProfile}
                        onTopbarItemClick={onTopbarItemClick}
                        onMenuButtonClick={onMenuButtonClick}
                        onSidebarMouseOver={onSidebarMouseOver}
                        onSidebarMouseLeave={onSidebarMouseLeave}
                        onToggleMenu={onToggleMenu}
                        onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                        onMenuClick={onMenuClick}
                        onMenuItemClick={onMenuItemClick}
                        onRootMenuItemClick={onRootMenuItemClick}
                        resetActiveIndex={resetActiveIndex}
                    />
                    <Menu
                        colorScheme={colorScheme}
                        setColorScheme={setColorScheme}
                        model={availableMenuOptions}
                        onRootMenuItemClick={onRootMenuItemClick}
                        onMenuItemClick={onMenuItemClick}
                        onToggleMenu={onToggleMenu}
                        onMenuClick={onMenuClick}
                        menuMode={menuMode}
                        menuActive={menuActive}
                        sidebarActive={sidebarActive}
                        sidebarStatic={sidebarStatic}
                        pinActive={pinActive}
                        onSidebarMouseLeave={onSidebarMouseLeave}
                        onSidebarMouseOver={onSidebarMouseOver}
                        activeInlineProfile={activeInlineProfile}
                        onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                        resetActiveIndex={resetActiveIndex}
                        setCustomBreadcrumb={setCustomBreadcrumb}
                    />

                    <div className={'layout-main-content ' + window.location.hash.replace('#', '')}>
                        {selectedProject && selectedProject.cesium && checkPermission(permissions.accessCesium) &&
                        <Cesium
                            url={selectedProject ? selectedProject.cesium : null}
                            show={window.location.hash === hash_cesium}
                        />}
                        {selectedProject && selectedProject.wiki_url && checkPermission(permissions.accessWiki) &&
                        <Wiki
                            url={selectedProject ? selectedProject.wiki_url : null}
                            show={window.location.hash === hash_wiki}
                        />}
                        {selectedProject && selectedProject.scanner && checkPermission(permissions.accessScanner) &&
                        <Scanner
                            url={selectedProject ? selectedProject.scanner : null}
                            show={window.location.hash === hash_scanner}
                        />}
                        {selectedProject && selectedProject.location_id !== null && selectedProject.project_type_id !== 2 &&
                        <GeoMonitoring
                            alarm={availableAlarms}
                            project={selectedProject}
                            configActive={configActive}
                            onConfigButtonClick={onConfigButtonClick}
                            onConfigClick={onConfigClick}
                            show={window.location.hash === hash_geomonitoring}
                        />}
                        {getComponent()}
                    </div>

                    <Breadcrumb
                        project={selectedProject}
                        routes={availableMenuOptions}
                        onMenuButtonClick={onMenuButtonClick}
                        menuMode={menuMode}
                        sideBarStatic={sidebarStatic}
                        colorScheme={colorScheme}
                        customBreadcrumb={customBreadcrumb}
                    />
                </div>
            </>}
        </div>
    );
};

export default App;
