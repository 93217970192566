export const ElementTree = (props:any, parent:any) => {
    const _props:any = {
        data: props.data,
        displayExpandButton:true,
        expandButton:{
            label: ' + Expand All',
            className: 'p-button p-component p-mr-2'
        },
        displayCollapseButton:true,
        collapseButton:{
            label: ' - Collapse All',
            className: 'p-button p-component p-mr-2'
        },
        displaySearchField: true,
        openerButton:{
            label: '',
            className: 'p-tree-toggler p-link'
        },
        nodeOnClick: (id:string, info:any) =>{
            props.nodeOnClick(id, info);
        },
        nodeOnChangePosition: (parentId:string, newListCount:any) =>{
            props.nodeOnChangePosition(parentId, newListCount);
        },
        contextMenu: props.contextMenu,
        setSelectedNode: props.setSelectedNode,
        autoOpen: false,
        setLoader: props.setLoader,
    };
    const _treeInfo:any = [];
    const _parent = parent;
    const _childrenClass = 'p-treenode-children';
    const _componentClass = 'p-treenode';
    const _mainContainer:any = document.createElement('div');
    _mainContainer.className = 'p-tree p-component p-tree-selectable';
    const nodeEvents = () =>{
        const cnt = _mainContainer;
        let startMove:boolean = false;
        let startDrag:boolean = false;
        let copyNode:any = null;
        let copyDropPoint:any = null;
        let parentNode:any = null;
        let showCast:any = null;
        let startDragTime:any = null;
        const mouseDown = (e:any) => {
            const node = recognizeNode(e);
            if (node === null) return;
            if (node.classList.contains('p-treenode-droppoint') ) return;

            /* Start event */
            startDragTime = new Date().getTime();
            // Right Click
            if (e.button === 2) {
                e.preventDefault();
                e.target.oncontextmenu = () =>{return false;};
                let tmpTarget = e.target;
                do{
                    if(tmpTarget.getAttribute('key') !== null)
                        break;
                    else{
                        tmpTarget = tmpTarget.parentNode;
                    }
                }while (true);
                console.log(tmpTarget.getAttribute('key'));
                _props.setSelectedNode(tmpTarget.getAttribute('key'));
                _props.contextMenu.current.show(e);
            } else if (e.button === 0) {
                startMove = true;
                parentNode = node.parentNode;
                copyDropPoint = node.previousSibling;
                copyNode = node;
                showCast = document.createElement('span');
                cnt.append(showCast);
                showCast.innerHTML = node.getElementsByClassName('p-treenode-label')[0].innerHTML;
                // Eine neue css Klasse mit den Eigenschaften schreiben
                showCast.style.position = 'absolute';
                showCast.style.display = 'none';
                showCast.style.opacity = '.3';
                showCast.style.pointerEvents = 'none';
            }
        };

        const mouseMove = (e:any) =>{
            if (!startMove) return;
            if (new Date().getTime()-startDragTime <500) return;
            startDrag = true;
            showCast.style.display = 'block';
            showCast.style.top = (e.clientY - (showCast.offsetHeight/2)) + 'px';
            showCast.style.left = (e.clientX - (showCast.offsetWidth/2)) + 'px';
            const node = recognizeNode(e);
            if (node === null) return;
            if (node === copyNode) return;
            if (node.parentNode !== parentNode) return;
            
            /* Start event */
            Array.from(document.getElementsByClassName('p-treenode-droppoint')).forEach((el:any) => {el.classList.remove('p-treenode-droppoint-active');});
            if (node.classList.contains('p-treenode-droppoint')) {
                node.classList.add('p-treenode-droppoint-active');
            }
        };
        const mouseUp = (e:any) =>{
            startDragTime = null;
            startDrag = false;
            startMove = false;
            if (e.button === 2) return;
            const node = recognizeNode(e);
            if (node === null) return;
            if (parentNode !== node.parentNode) Array.from(document.getElementsByClassName('p-treenode-droppoint')).forEach((el:any) => {el.classList.remove('p-treenode-droppoint-active');});
            if (parentNode === null) return;

            /* Start event */
            node.classList.remove('p-treenode-droppoint-active');
            if (node.parentNode !== parentNode) return;
            if (node.classList.contains('p-treenode-droppoint')) {
                node.before(copyNode);
                copyNode.before(copyDropPoint);
                const newListOrder:any = [];
                let i:number = 0;
                node.parentNode.childNodes.forEach((el:any) =>{if (el.getAttribute('key') !== null) {newListOrder[i] = el.getAttribute('key'); i++;}});
                _props.nodeOnChangePosition(node.parentNode.parentNode.getAttribute('key'), newListOrder);
            } else if (!startDrag)
                _props.nodeOnClick(node.getAttribute('key'), _treeInfo[ node.getAttribute('key') ]);

            if (showCast !== null && showCast.isConnected)
                cnt.removeChild(showCast);
        };
        const mouseLeave = (e:any) => {
            if (parentNode === null) return;
            resetEvent();
        };
        const resetEvent = ()=>{
            startDragTime = null;
            startDrag = false;
            startMove = false;
            Array.from(document.getElementsByClassName('p-treenode-droppoint')).forEach((el:any) => {el.classList.remove('p-treenode-droppoint-active');});
            if (showCast !== null && showCast.isConnected) {
                cnt.removeChild(showCast);
            }
        };
        const recognizeNode = (e:any) => {
            let node:any = e.target;
            if (node === null)
                return null;
            else if (node.classList.contains('p-treenode-content')) {
                node = e.target.parentNode;
            } else if (node.classList.contains('p-treenode-label'))
                node = e.target.parentNode.parentNode;
            else if (node.classList.contains('p-treenode-droppoint'))
                node = e.target;
            else if (node.classList.contains(_componentClass))
                node = e.target;
            else
                return null;
            return node;
        };
        cnt.addEventListener('mousedown', mouseDown);
        cnt.addEventListener('mousemove', mouseMove);
        cnt.addEventListener('mouseup', mouseUp);
        cnt.addEventListener('mouseleave', mouseLeave);
        // cnt.addEventListener('mouseout', mouseLeave);
    };

    const makeTreeView = () =>{
        if (_props.displayExpandButton)
            _parent.append(_makeButtonExpand());
        if (_props.displayCollapseButton)
            _parent.append(_makeButtonCollapse());
        if (_props.displaySearchField)
            _parent.append(_makeSearchField());
        const treeContainer = document.createElement('ul');
        treeContainer.className = 'p-tree-container';
        _makeTreeNodes(_props.data, treeContainer);
        _mainContainer.append(treeContainer);
        _parent.append(_mainContainer);
    };
    const makeDropPoint = () => {
        const dropPoint = document.createElement('li');
        dropPoint.className = 'p-treenode-droppoint';
        return dropPoint;
    };
    function _makeTreeNodes(nodes:any, parent:any) {
        parent.append(makeDropPoint());
        for (const key in nodes) {
            _treeInfo[ nodes[key].element_id ] = nodes[key]['form_settings'];
            const treeNode = document.createElement('li');
            treeNode.setAttribute('key', nodes[key].element_id);
            treeNode.className = _componentClass;
            const treeNodeContent = document.createElement('div');
            treeNodeContent.className = 'p-treenode-content p-treenode-selectable';
            const treeNodeIcon = document.createElement('span');
            if (nodes[key].icon !== null)
                treeNodeIcon.className = 'p-treenode-icon ' + nodes[key].icon;
            const treeNodeLabel = document.createElement('span');
            treeNodeLabel.className = 'p-treenode-label';
            treeNodeLabel.innerHTML = nodes[key].label;
            treeNodeContent.setAttribute('key', nodes[key].element_id);
            treeNodeContent.setAttribute('tabindex', '0');

            if (nodes[key].icon !== null)
                treeNodeContent.append(treeNodeIcon);
            treeNodeContent.append(treeNodeLabel);
            treeNode.append(treeNodeContent);

            if (typeof nodes[key].children !== 'undefined') {
                const treeNodeChildren = document.createElement('ul');
                treeNodeChildren.className = _childrenClass;
                if (_props.autoOpen)
                    treeNodeChildren.style.display = 'block';
                else
                    treeNodeChildren.style.display = 'none';
                const bIcon = document.createElement('span');
                bIcon.className = 'p-tree-toggler-icon pi pi-fw';
                if (_props.autoOpen)
                    bIcon.classList.add('pi-chevron-down');
                else
                    bIcon.classList.add('pi-chevron-right');

                const bOpener = _makeButton(_props.openerButton, () => {
                    if (treeNodeChildren.style.display === 'none') {
                        bIcon.classList.remove('pi-chevron-right');
                        bIcon.classList.add('pi-chevron-down');
                        treeNodeChildren.style.display = 'block';
                    } else {
                        treeNodeChildren.style.display = 'none';
                        bIcon.classList.remove('pi-chevron-down');
                        bIcon.classList.add('pi-chevron-right');
                    }
                });
                bOpener.append(bIcon);
                treeNodeContent.prepend(bOpener);
                _makeTreeNodes(nodes[key].children, treeNodeChildren);

                treeNode.append(treeNodeChildren);
            } else {
                const dummy = document.createElement('button');
                dummy.className = 'p-tree-toggler p-link';
                treeNodeContent.prepend(dummy);
                treeNode.classList.add('p-treenode-leaf');
            }
            parent.append(treeNode);
            parent.append(makeDropPoint());


        }
    }

    const _makeButtonExpand = () =>{
        const children: any = document.getElementsByClassName(_childrenClass);
        let timeOutTime = 0;
        if (children.length > 5000)
            timeOutTime = 50;
        return _makeButton(_props.expandButton, () => {
            _props.setLoader(true);
            window.setTimeout(() => {
                for (const key in children) {
                    if (typeof children[key].style !== 'undefined') {
                        children[key].style.display = 'block';
                        children[key].parentElement.firstChild.getElementsByTagName('span')[0].classList.remove('pi-chevron-right');
                        children[key].parentElement.firstChild.getElementsByTagName('span')[0].classList.add('pi-chevron-down');
                    }
                }
                _props.setLoader(false);
            },timeOutTime);
        });
    };

    const _makeButtonCollapse = () =>{
        const children: any = document.getElementsByClassName(_childrenClass);
        let timeOutTime = 0;
        if (children.length > 5000)
            timeOutTime = 50;
        return _makeButton(_props.collapseButton, () => {
            _props.setLoader(true);
            window.setTimeout(() => {
                for (const key in children) {
                    if (typeof children[key].style !== 'undefined') {
                        children[key].style.display = 'none';
                        children[key].parentElement.firstChild.getElementsByTagName('span')[0].classList.remove('pi-chevron-down');
                        children[key].parentElement.firstChild.getElementsByTagName('span')[0].classList.add('pi-chevron-right');
                    }
                }
                _props.setLoader(false);
            },timeOutTime);
        });
    };

    const _makeSearchField = ()=>{
        const div = document.createElement('div');
        div.className = 'field';
        const searchInput = document.createElement('input');
        searchInput.className = 'p-inputtext p-component custom-inputfield mt-2 w-2';
        div.append(searchInput);
        searchInput.onchange = (ev) =>{
            // @ts-ignore
            console.log(ev.target.value);
            // search in .p-tree-container -> li -> span.p-treenode-label

            //console.log(document.getElementsByClassName('p-tree-container')[0].querySelector('span.p-treenode-label'));

        }
        return div;
    }
    const _makeButton = (props:any, onclick:any) => {
        const button = document.createElement('button');
        button.innerHTML = props.label;
        button.className = props.className;
        button.onclick = onclick;
        return button;
    };
    nodeEvents();
    makeTreeView();
};