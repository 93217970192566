import React from 'react';
import settings from '../config/settings';
import geoSettings from '../config/geoSettings';
import keycloakfetch from '../functions/keycloakfetch';
import {Feature} from 'ol';
import {Point} from 'ol/geom';
import Cluster from 'ol/source/Cluster';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import {Circle, Fill, Icon, Stroke, Style, Text} from 'ol/style';
import {transformCoords} from './SpatialService';
import {isNotUndefined} from '../functions/functionsOpenlayer';
import {getAlarms} from '../components/functions/Notification';

type Messpunkt = {
    epsg_code?: any,
    position?: any
    icon: string
};

const scaleImage = {
    18: 0.05,
    19: 0.08,
    20: 0.12,
    default: 0.14
};

const getSimplePointStatus = (features: any[]) => {
    let status = 1;
    let inactiveCounter = 0;
    let counter = 0;// console.log(features);
    features.forEach(feature => {

        const featureStatus = feature.get('status');
        if (featureStatus !== 2) {
            status = featureStatus > status ? featureStatus : status;
        } else {
            inactiveCounter++;
        }
        counter++;
    });

    if (inactiveCounter == counter) {
        return 2;
    }
    return status;
};

const getColorCode = (status: number) => {
    let color;
    let colorCode;
    switch (status) {
        case 5:
            color = 'red';
            colorCode = '#ea2d43';
            break;
        case 4:
            color = 'orange';
            colorCode = '#fda421';
            break;
        case 3:
            color = 'orange';
            colorCode = '#fda421';
            break;
        case 1:
            color = 'green';
            colorCode = '#68AF23';
            break;
        default:
            color = 'grey';
            colorCode = '#c0c3be';
    }

    return {string: color, code: colorCode};
};

const getMeasuringpointClusterStyle = (zoom: number, feature: any, resolution: number) => {
    const width = 5;
    const features = feature.get('features') ? feature.get('features') : [feature];
    const status = getSimplePointStatus(features);
    const colorCodes = getColorCode(status);
    return new Style({

        image: new Circle({
            radius: width * 2,
            fill: new Fill({color: colorCodes.code}),
            stroke: new Stroke({
                color: '#ffffff',
                width: width / 4
            })
        }),
        text: new Text(
            {
                text: isNotUndefined(feature.get('features')) ? feature.get('features').length.toString() : '',
                fill: new Fill(
                    {
                        color: '#ffffff'
                    })
            })
    });
};

const getFeatureImage = (feature: { get: any }, colorLegend: { code: any; string: any }) => {
    const fIcon = feature.get('icon');
    // if(fIcon==null&&fIcon==undefined)console.log(fIcon)
    const icon = fIcon !== null && fIcon !== undefined ? fIcon : 'unknown';
    const url = geoSettings.sensorTypeImageUrl + colorLegend.string + '/' + icon + '.png';
    // console.log(url)
    return url;
};

const getMpSelectedStyle = (zoom: string | number, feature: any) => {
    const zoomIndex = zoom as keyof typeof scaleImage;
    const status = getSimplePointStatus([feature]);
    const colorCodes = getColorCode(status);
    const iconStyle = new Style({
        image: new Icon(({
            scale: (scaleImage[zoomIndex] ? scaleImage[zoomIndex] : scaleImage.default) / 1.10,
            src: getFeatureImage(feature, colorCodes)
        })),
        text: styleMeasuringpointsText(feature),
        stroke: new Stroke({
            color: '#000000',
            width: 5 / 4
        })
    });

    const circleStyle = new Style({
        image: new Circle({
            radius: 10,
            stroke: new Stroke({
                color: '#000',
                width: 2
            })
        })
    });

    const circleStyle2 = new Style({
        image: new Circle({
            radius: 15,
            stroke: new Stroke({
                color: '#000',
                width: 2
            })
        })
    });

    return ([iconStyle, circleStyle, circleStyle2]);
};

const getHoverStyle = (zoom: string | number, feature: any) => {
    const zoomIndex = zoom as keyof typeof scaleImage;
    const status = getSimplePointStatus([feature]);
    const colorCodes = getColorCode(status);
    const style = new Style({
        image: new Icon(({
            scale: (scaleImage[zoomIndex] ? scaleImage[zoomIndex] : scaleImage.default) / 0.90,
            src: getFeatureImage(feature, colorCodes)
        })),
        text: styleMeasuringpointsText(feature),
        stroke: new Stroke({
            color: '#000000',
            width: 5 / 4
        })
    });
    return style;
};

const getMeasurementPointImageStyle = (zoom: number | undefined, feature: { get: (arg0: string) => any }, resolution: number) => {
    if (zoom === undefined) {
        zoom = 18;
    }
    const featureStatus = feature.get('status');
    const colorCodes = getColorCode(featureStatus);
    const iconStyle = new Icon(({
        src: getFeatureImage(feature, colorCodes)
    }));

    const circleStyle = new Circle({
        radius: 5,
        fill: new Fill({color: colorCodes.code}),
        stroke: new Stroke({
            color: 'white',
            width: 2,
        }),
    });

    const scale = (scaleImage[zoom as keyof typeof scaleImage] ? scaleImage[zoom as keyof typeof scaleImage] : scaleImage.default) / 1.25;
    iconStyle.setScale(scale);

    return new Style({
        image: zoom <= 17 ? circleStyle : iconStyle,
        text: styleMeasuringpointsText(feature)
    });
};


const styleMeasuringpointsText = (feature: { get: any; }) => {
    return new Text(
        {
            textAlign: 'left',
            // textBaseline: 'center',
            font: 'Bold 12px Arial',
            text: feature.get('name'),
            fill: new Fill({color: [0, 0, 0, 1]}),
            stroke: new Stroke({color: [255, 255, 255, 2], width: 3}),
            offsetX: 12,
            offsetY: 1,
            rotation: 0,
            placement: 'point'
        });
};

const getVectorPointLayerSources = (pois: any[]) => {
    const source = new VectorSource({});
    const clusterSource = new Cluster({
        distance: 30,
        source: source
    });

    source.addFeatures(pois);
    clusterSource.addFeatures(pois);

    const vectorLayer = new VectorLayer({
        source: source,
        //  style: pointStylesSelected,
        zIndex: 10000
    });

    vectorLayer.set('name', 'poiLayer');
    vectorLayer.set('id', 'poiLayer');
    vectorLayer.set('type', 'vector');

    return {normalSource: source, clusterSource: clusterSource, vectorLayer: vectorLayer};
};

const getMeasuringPointLayer = async (projectId: string, alarm: any) => {
    const mpPoints = await callData(projectId);
    const pointFeatures = await createFeatures(mpPoints, alarm);
    const sourcesAndLayer = getVectorPointLayerSources(pointFeatures.features);
    return sourcesAndLayer;
};

const createFeatures = async (data: any[], projectId: any) => {
    let i: keyof typeof data;
    const pointFeatures: any[] = [];
    const pointTypes: any[] = [];
    const alarm = await getAlarms(projectId);
    for (i in data) {

        const mp: Messpunkt = data[i];
        if (mp&&mp.epsg_code&&mp.position) {
            const location = mp.position.coordinates;
            if ((location[0] > 0 || location[0] < 0) && mp.epsg_code > 0) {
                const point = await createPoiPoint(mp, data, alarm);
                pointFeatures.push(point);
                if (!pointTypes.includes(mp.icon)) pointTypes.push(mp.icon);
            }
        }
    }
    return {features: pointFeatures, types: pointTypes};
};

export const getStatusNumber = (stringStatus: string) => {
    let status;
    switch (stringStatus.toLowerCase()) {
        case 'ok':
            status = 1;
            break;
        case 'timeout':
            status = 3;
            break;
        case 'warning':
            status = 4;
            break;
        case 'alarm':
            status = 5;
            break;
        default:
            status = 2;
            break;
    }

    return status;
};

export const iterateAlarms=(alarm: any[], channelId: any, channelStatus: number)=>{
    const searchedAlarms = alarm.filter((a: any) => (a.channel_id === channelId));
    if(searchedAlarms.length>0 ){
        const maxAlarm = searchedAlarms.map((a) => getStatusNumber(a.status));
        const highestAlarm = Math.max(...maxAlarm);
        //console.log(searchedAlarms, channelId,highestAlarm)

        const chStatus = searchedAlarms.length>0 ? highestAlarm : channelStatus;


        if (chStatus > channelStatus) {
          //  if(chStatus===5)console.log(alarm, channelId, chStatus)
            channelStatus = chStatus;
        }
    }

    return channelStatus;
};

export const itereateChannelsSetMpStatus = async (mpArray: any[], alarm: any) => {
    let channelStatus = 1;
    if (mpArray.length > 0) {

        if (mpArray.length > 0) {
            for (const i in mpArray) {
                const channelEntry = mpArray[i];
                const currentChannelState=iterateAlarms(alarm,channelEntry.channel_id, channelStatus );
                channelStatus=currentChannelState>channelStatus?currentChannelState:channelStatus;
               //  console.log(mpArray,channelEntry.name, currentChannelState,channelStatus);
            }
        }
    }

    return channelStatus;
};


const createPoiPoint = async (mp: any, data: any, alarm: any) => {
    let poiPoint;

    const channelStatus = await itereateChannelsSetMpStatus(mp.channel, alarm);

    if (mp.position) {

        const location = mp.position.coordinates;
        const coords = await transformCoords([location[0], location[1]], mp.epsg_code, 3857);

        poiPoint = new Feature({
            geometry: new Point(coords),
            type: mp.sensor_type_id,
            name: mp.location_name,
            layerKind: 'poiLayer',
            icon: mp.icon,
            id: mp.location_id,
            projectId: mp.project_id,
            channelId: mp.channel.length > 0 ? mp.channel[0].channel_id : null,
            channelIds: mp.channel.map((ch: any) => {
                return ch.channel_id;
            }),
            status: channelStatus,
            mediaLink: mp.channel.length > 0 ? mp.channel[0].media_link : null,
            grafanaLink: mp.custom_link
            // grafanaLink:'fasdsae'
        });
        poiPoint.setId(mp.location_id);
        // console.log(poiPoint)
    }
    return poiPoint;
};

const callData = async (projectId: any) => {
    const url = settings.apiUrl + '/getMeasuringPoints?project_id=' + projectId + '&new_query=true';
    let retValue: any = [];
    await keycloakfetch.get(url).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
};


export {
    callData,
    createFeatures,
    getColorCode,
    getFeatureImage,
    getMeasuringpointClusterStyle,
    getHoverStyle,
    getMpSelectedStyle,
    getMeasuringPointLayer,
    getMeasurementPointImageStyle,
    getVectorPointLayerSources,
    scaleImage,
    styleMeasuringpointsText
};