/*
 * CharacteristicMonitoringTasksOverview.tsx
 * Author: lnappenfeld
 * Date: 21.02.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import CustomDataTable, {ColumnObject} from '../../global/CustomDataTable';
import {useTranslation} from 'react-i18next';
import {CustomConfirmDialog} from '../../global/CustomConfirmDialog';
import {showMessageOnError, showMessageOnSuccess, showMessageOnWarn} from '../../global/CustomToast';
import {checkPermission, getGroupedItems} from '../../../functions/functionLibrary';
import {permissions} from '../../../config/permissions';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {filterTriStateCheckBox} from '../../global/filter';
import {
    CharacteristicMonitoringTask,
    getCharacteristicMonitoringTasks, getCharacteristicMonitoringTaskTypes, resetCharacteristicMonitoringTaskFlag,
} from '../../functions/Notification';
import CustomDialog, {CustomDialogHeaderType} from '../../global/CustomDialog';
import CharacteristicMonitoringTaskForm from './CharacteristicMonitoringTaskForm';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRefresh} from '@fortawesome/free-solid-svg-icons';
import {getMonitoringAppTypes} from '../../functions/ConditionMonitoring';

export enum CharacteristicMonitoringTaskOverviewType {
    Default = 0,
    NotificationWizard = 1,
}

type Props = {
    projectId: any,
    type: CharacteristicMonitoringTaskOverviewType,
    characteristicMonitoringTasks?: CharacteristicMonitoringTask[],
    elementId?: string
    reload?: boolean,
    setReload?: (reload: boolean)=>void
    setSelectedCharacteristicMonitoringTasks?: (data: CharacteristicMonitoringTask[]) => void
    selectedCharacteristicMonitoringTasks?: CharacteristicMonitoringTask[],
    editCharacteristicMonitoringTask?: boolean,
    setEditCharacteristicMonitoringTask?: (data: boolean) => void,
    showHeader: boolean
}
export const CharacteristicMonitoringTasksOverview = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    const [characteristicMonitoringTasks, setCharacteristicMonitoringTasks] = useState<any[]>(props.characteristicMonitoringTasks ? props.characteristicMonitoringTasks : []);

    // Lokale States für die Untertabelle einer expandedRow, muss so gelöst werden, weil die props an das
    // 'expandedElement' nur einmal initial übergeben werden können, aber keine Aktualisierten props
    const [selectedCharacteristicMonitoringTasks, setSelectedCharacteristicMonitoringTasks] = useState<CharacteristicMonitoringTask[]>([]);
    const [editCharacteristicMonitoringTask, setEditCharacteristicMonitoringTask] = useState<boolean>(false);

    // arrays for filter dropdowns
    const [characteristicMonitoringTaskTypes, setCharacteristicMonitoringTaskTypes] = useState<any[]>([]);
    const [monitoringApps, setMonitoringApps] = useState<any[]>([]);
    const [elements, setElements] = useState<any[]>([]);
    const [channels, setChannels] = useState<any[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const _setMonitoringApps = () => {
        getMonitoringAppTypes(props.projectId).then(result => {
            const items: any = [];
            result.forEach((key: any) => {
                items.push({
                    value: key.id,
                    name: key.name,
                });
            });
            setMonitoringApps(items);
        });
    };

    const _setCharacteristicMonitoringTaskTypes = () => {
        getCharacteristicMonitoringTaskTypes(props.projectId).then(result => {
            const items: any = [];
            result.forEach((key: any) => {
                items.push({
                    value: key.id,
                    name: key.name,
                });
            });
            setCharacteristicMonitoringTaskTypes(items);
        });
    };

    const _setCharacteristicMonitoringTasks = () => {
        let tmpList: any = [];
        getCharacteristicMonitoringTasks(props.projectId).then(result => {
            if (props.elementId) {
                for (let i = 0; i < result.length; i++) {
                    if (props.elementId === result[i].element_id)
                        tmpList.push(result[i]);
                }
            } else {
                tmpList = result;
            }

            setCharacteristicMonitoringTasks(tmpList);
            setDataLoaded(true);
            // filter dropdown values
            setElements(getGroupedItems(tmpList, 'element_id', 'element_name'));
            setChannels(getGroupedItems(tmpList, 'channel_id', 'channel_name'));
        });
    };

    useEffect(() => {
        _setCharacteristicMonitoringTaskTypes();
        _setMonitoringApps();
        if (!props.characteristicMonitoringTasks) {
            _setCharacteristicMonitoringTasks();
        } else {
            setElements(getGroupedItems(props.characteristicMonitoringTasks, 'element_id', 'element_name'));
            setChannels(getGroupedItems(props.characteristicMonitoringTasks, 'channel_id', 'channel_name'));
        }
    }, []);

    // useEffect(() => {
    //     console.log('elementId: ', props.elementId)
    //     _setCharacteristicMonitoringTasks();
    // }, [props.elementId]);

    useEffect(() => {
        // Lade neu, z.B. wenn Elemente gelöscht wurden
        if (props.reload) {
            _setCharacteristicMonitoringTasks();
            if (props.setReload) props.setReload(false);
        }
    }, [props.reload]);

    // Setze die lokalen States für den Dialog zum Anlegen eines NEUEN Channels
    // Könnte auch direkt an den Dialog übergeben werden, so muss aber nur auf die lokalen States geprüft werden
    useEffect(() => {
        if (props.editCharacteristicMonitoringTask) setEditCharacteristicMonitoringTask(props.editCharacteristicMonitoringTask);
    }, [props.editCharacteristicMonitoringTask]);

    useEffect(() => {
        if (props.selectedCharacteristicMonitoringTasks) setSelectedCharacteristicMonitoringTasks(props.selectedCharacteristicMonitoringTasks);
    }, [props.selectedCharacteristicMonitoringTasks]);

    const filterCheckboxIsEnabled = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), t('enabled'), t('disabled'));
    };

    const columns: ColumnObject[] = [{
        field: 'is_active', header: t('active'), style: {maxWidth: '100px'},
        filter: true, filterElement: filterCheckboxIsEnabled,
    },{
        field: 'characteristic_monitoring_task_name', header: t('name'),
        filter: true, filterField: 'characteristic_monitoring_task_name'
    },{
        field: 'characteristic_monitoring_task_type_name', header: t('type'),
        filter: true, filterField: 'characteristic_monitoring_task_type_id', filterDropdownValues: characteristicMonitoringTaskTypes
    },{
        field: 'monitoring_app_name', header: t('alarmManagement:attributes.monitoring_app'),
        filter: true, filterField: 'monitoring_app_id', filterDropdownValues: monitoringApps
    },{
        field: 'element_name', header: t('alarmManagement:attributes.element'),
        filter: true, filterField: 'element_id', filterDropdownValues: elements
    },{
        field: 'channel_name', header: t('channel'),
        filter: true, filterField: 'channel_id', filterDropdownValues: channels
    // },{
    //     field: 'good_from', header: t('alarmManagement:attributes.good_from'), style: {maxWidth: '120px'},
    //     filter: false
    // },{
    //     field: 'good_to', header: t('alarmManagement:attributes.good_to'), style: {maxWidth: '120px'},
    //     filter: false
    // },{
    //     field: 'pre_alarm_from', header: t('alarmManagement:attributes.pre_alarm_from'), style: {maxWidth: '120px'},
    //     filter: false
    // },{
    //     field: 'pre_alarm_to', header: t('alarmManagement:attributes.pre_alarm_to'), style: {maxWidth: '120px'},
    //     filter: false
    // },{
    //     field: 'main_alarm_from', header: t('alarmManagement:attributes.main_alarm_from'), style: {maxWidth: '140px'},
    //     filter: false
    // },{
    //     field: 'main_alarm_to', header: t('alarmManagement:attributes.main_alarm_to'), style: {maxWidth: '140px'},
    //     filter: false
    },{
        field: 'flag', header: t('alarmManagement:attributes.flag'),
        body: checkPermission(permissions.editNotification)
            ? (cmt: CharacteristicMonitoringTask) => {
                return ( cmt['flag']
                    ?
                    <span className='cell-function' onClick={(e) => {
                        e.stopPropagation();
                        // eslint-disable-next-line new-cap
                        CustomConfirmDialog({
                            message: t('alarmManagement:dialogs.resetCharacteristicMonitoringTaskFlagDialog.message'),
                            header: t('confirmation'),
                            translation: t,
                            onConfirm: () => {
                                resetCharacteristicMonitoringTaskFlag(props.projectId, cmt.id).then(result => {
                                    if (result.error) {
                                        showMessageOnError(t('error'), result.error);
                                    } else if (result.nothingToDo) {
                                        showMessageOnWarn(t('warning'), result.nothingToDo);
                                    } else {
                                        _setCharacteristicMonitoringTasks();
                                        showMessageOnSuccess(t('success'), t('alarmManagement:toasts.characteristicMonitoringTaskFlagReset'));
                                    }
                                });
                            }
                        });
                    }}>
                        <span style={{display: 'inline-block', marginRight: '10px'}} title={t('alarmManagement:tooltips.resetFlagTooltip')}>
                            <FontAwesomeIcon icon={faRefresh} aria-disabled={true}/>
                        </span><u>{t('yes')}</u>
                    </span>
                    :
                    <>
                        <span style={{display: 'inline-block', marginRight: '25px'}}></span>{t('no')}
                    </>
                );
            }
            : null
    // },{
    //     field: 'flag_set_value', header: t('alarmManagement:attributes.flag_set_value'), style: {maxWidth: '100px'}
    // },{
    //     field: 'flag_set_value_date', header: t('alarmManagement:attributes.flag_set_value_date'), type: 4
    // },{
    //     field: 'flag_set_when_utc', header: t('alarmManagement:attributes.flag_set_when_utc'), type: 4
    // },{
    //     field: 'flag_auto_reset_after_minutes', header: t('alarmManagement:attributes.flag_auto_reset_after_minutes')
    // },{
    //     field: 'flag_changed_by_user_name', header: t('changedByUsername')
    }];

    const filters = {
        'is_active': {
            value: true,
            matchMode: FilterMatchMode.EQUALS
        },
        'characteristic_monitoring_task_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'monitoring_app_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'element_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'characteristic_monitoring_task_type_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'channel_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
    };

    const isDefaultType = () => {
        return props.type === CharacteristicMonitoringTaskOverviewType.Default;
    };

    const getSelectionMode = () => {
        switch (props.type) {
            case CharacteristicMonitoringTaskOverviewType.Default:
                return checkPermission(permissions.editNotification) ? {selectionMode: 'multiple'} : {};
            // case CharacteristicMonitoringTaskOverviewType.NotificationWizard:
            //     return checkPermission(permissions.editNotification) ? {selectionMode: 'multiple'} : {};
            // TODO: (props.elementId) ? {selectionMode: 'multiple'} : {}
            default:
                return {};
        }
    };

    const dynamicSelectionMode = getSelectionMode();

    return (
        <>
            <CustomDataTable
                {...dynamicSelectionMode}
                id='monitoringTasks'
                expandable={false}
                columns={columns}
                editable={isDefaultType() && checkPermission(permissions.editNotification) ? true : false}
                sortable={true}
                value={characteristicMonitoringTasks}
                paginator={characteristicMonitoringTasks.length > 100}
                selection={selectedCharacteristicMonitoringTasks}
                onSelectionChange={(e: any) => {
                    if (props.setSelectedCharacteristicMonitoringTasks) props.setSelectedCharacteristicMonitoringTasks(e.value);
                }}
                onEditClick={(data: CharacteristicMonitoringTask) => {
                    if (props.setSelectedCharacteristicMonitoringTasks) props.setSelectedCharacteristicMonitoringTasks([data]);
                    setSelectedCharacteristicMonitoringTasks([data]);
                    setEditCharacteristicMonitoringTask(true);
                }}
                headerTitle={props.showHeader ? t('monitoringTasks') : ''}
                ableToDelete={checkPermission(permissions.editMetrology)}
                ableToUpdate={checkPermission(permissions.editMetrology)}
                filters={filters}
                dataloaded={dataLoaded}
                showExportCSV={true}
            />
            {
                <CustomDialog
                    header={selectedCharacteristicMonitoringTasks.length > 0 ? t('alarmManagement:dialogs.editCharacteristicMonitoringTaskDialog.header') : t('alarmManagement:dialogs.createCharacteristicMonitoringTaskDialog.header')}
                    headerType={selectedCharacteristicMonitoringTasks.length > 0 ? CustomDialogHeaderType.Update : CustomDialogHeaderType.Create}
                    formName={'formCharacteristicMonitoringTask'}
                    visible={editCharacteristicMonitoringTask}
                    onCancel={() => {
                        if (props.setEditCharacteristicMonitoringTask) props.setEditCharacteristicMonitoringTask(false);
                        setEditCharacteristicMonitoringTask(false);
                    }}
                    onHide={() => {
                        if (props.setEditCharacteristicMonitoringTask) props.setEditCharacteristicMonitoringTask(false);
                        setEditCharacteristicMonitoringTask(false);
                    }}
                >
                    <CharacteristicMonitoringTaskForm
                        projectId={props.projectId}
                        onFinished={() => {
                            if (props.setEditCharacteristicMonitoringTask) props.setEditCharacteristicMonitoringTask(false);
                            if (props.setSelectedCharacteristicMonitoringTasks) props.setSelectedCharacteristicMonitoringTasks([]);
                            setEditCharacteristicMonitoringTask(false);
                            setSelectedCharacteristicMonitoringTasks([]);
                            _setCharacteristicMonitoringTasks();
                        }}
                        characteristicMonitoringTask={selectedCharacteristicMonitoringTasks.length > 0 ? selectedCharacteristicMonitoringTasks[0] : null}
                    />
                </CustomDialog>
            }
        </>
    );
};
