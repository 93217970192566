/*
 * DisableNotificationActionTemporaryForm.tsx
 * Author: lnappenfeld
 * Date: 10.10.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */


import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {Toast} from 'primereact/toast';
import Input from '../global/Input';


import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {
    createNotification,
    disableNotificationActionTemporary,
    disableNotificationTemporary,
    updateNotification
} from '../functions/Notification';
import DisablePeriodSelectList from './DisablePeriodSelectList';
import moment from 'moment';
import 'moment/locale/de';


type Props = {
    notificationActionData: any,
    projectId: string,
    onFinished: (success: boolean) => void
    setVisible: any
}

const initPeriod = {value: '1', label: '30 Minuten', durationInMinutes: 30};

const DisableNotificationActionTemporaryForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common', 'notifications', 'input']);

    const [disablePeriod, setDisablePeriod] = useState<any>(initPeriod);
    const [disableTimestamp, setDisableTimestamp] = useState<any>(moment().add(initPeriod.durationInMinutes,'minutes'));
    const currentDisableUntilUtc = props.notificationActionData.length > 0
        ? moment(props.notificationActionData[0].is_disabled_until_utc).format('LLLL')
        : null;

    const defaultValues = {
        'is_disabled_until_utc': moment().add(initPeriod.durationInMinutes,'minutes').format('LLLL')
    } as any;

    const {control, formState: {errors}, handleSubmit, setValue} = useForm({defaultValues});

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    const determineDisableUntilUtc = (durationInMinutes: number) => {
        const now = moment();
        now.add(durationInMinutes, 'minutes');
        if (durationInMinutes < 0 || durationInMinutes === 0) {
            setDisableTimestamp(null);
            setValue('is_disabled_until_utc',null);
        } else {
            setDisableTimestamp(now);
            setValue('is_disabled_until_utc', now.format('LLLL'));
        }
    };

    const saveData = (notificationAction: any) => {

        let isDisabledUntilUtc = notificationAction.is_disabled_until_utc;
        if (disableTimestamp) {
            isDisabledUntilUtc = disableTimestamp.format();
        }

        const aIds: string[] = [];
        const nIds: string[] = [];
        props.notificationActionData.forEach((_notificationAction: any) => {
            aIds.push(_notificationAction.action_id);
            nIds.push(_notificationAction.notification_id);
        });

        const data = {
            'is_disabled_until_utc': isDisabledUntilUtc,
            'notification_ids': nIds,
            'action_ids': aIds,
            'project_id': props.projectId
        };

        disableNotificationActionTemporary(data).then(result => {
            if (result.error) {
                showMessageOnError(t('error'), result.error);
            } else {
                showMessageOnSuccess(t('success'), t('alarmManagement:toasts.notificationActionTemporaryDisabled'));
                props.onFinished(true);
                props.setVisible(false);
            }
        });
    };

    return (
        <form id='disableNotificationActionTemporaryFormDialog' onSubmit={handleSubmit(saveData)}>
            <div className={'card'}>
                {currentDisableUntilUtc && props.notificationActionData.length > 0 && moment(props.notificationActionData[0].is_disabled_until_utc) > moment() &&
                    <Input
                        edit={false} label={t('alarmManagement:attributes.is_disabled_until_utc_current')}
                        name={'current_is_disabled_until_utc'}
                        value={currentDisableUntilUtc}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: false}}
                    />}
                <p></p>
                <DisablePeriodSelectList
                    label={t('alarmManagement:attributes.disablePeriod')}
                    name={'is_disabled_until_utc'}
                    value={disablePeriod.value} // for init value in useEffect
                    onChange={(evaluationPeriod: any) => {
                        // contains durationInMinutes
                        setDisablePeriod(evaluationPeriod);
                        determineDisableUntilUtc(evaluationPeriod.durationInMinutes);
                    }}
                    validationRules={{required: false}}
                />
                <Input
                    edit={false} label={t('alarmManagement:attributes.is_disabled_until_utc')}
                    name={'is_disabled_until_utc'}
                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: false}}
                />
            </div>
        </form>
    );
};

export default DisableNotificationActionTemporaryForm;
