import React, {useState, useEffect} from 'react';
import Plants from '../components/conditionMonitoring/Plants/Plants';
import SubPlants from '../components/conditionMonitoring/subplants/SubPlants';
import Details from '../components/conditionMonitoring/details/Details';
import Doppelfilter from '../components/conditionMonitoring/doppelfilter/Doppelfilter';
import EnergyEfficiency from '../components/conditionMonitoring/energy-efficiency/energyEfficiency';
import PlotComponent from '../components/conditionMonitoring/PlotComponent';

type Props = {
    projectId: string;
    colorTheme: string;
    setCustomBreadcrumb: any;
};
const ConditionMonitoring = (props: Props): JSX.Element => {
    const [component, setComponent] = useState<string>('');


    useEffect(() => {
        if (location.hash.indexOf('_doppelfilter') > 1)
            setComponent('doppelFilter');
        else if (location.hash.indexOf('_energy_efficiency') > 1)
            setComponent('energyEfficiency');
        else if (location.hash.indexOf('_detail') > 1)
            setComponent('details');
        else if (location.hash.indexOf('#conditionomonitoring_') > 1)
            setComponent('subPlants');
        else
            setComponent('');

    }, [location.hash]);

    return (
        <>
            {(component === 'doppelFilter') &&
            <Doppelfilter projectId={props.projectId} colorTheme={props.colorTheme} setCustomBreadcrumb={props.setCustomBreadcrumb}/>}
            {(component === 'energyEfficiency') &&
            <EnergyEfficiency projectId={props.projectId} colorTheme={props.colorTheme} setCustomBreadcrumb={props.setCustomBreadcrumb}/>}

            {(component === 'details') &&
            <Details projectId={props.projectId} colorTheme={props.colorTheme}
                setCustomBreadcrumb={props.setCustomBreadcrumb}/>}
            {(component === 'subplants') &&
            <SubPlants projectId={props.projectId} colorTheme={props.colorTheme}
                setCustomBreadcrumb={props.setCustomBreadcrumb}/>}
            {(component === '') &&
            <Plants colorTheme={props.colorTheme} projectId={props.projectId}
                setCustomBreadcrumb={props.setCustomBreadcrumb}/>}
        </>
    );
};
export default ConditionMonitoring;
