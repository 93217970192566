/*
 * PlotChart.tsx
 * Author: fwunderlich
 * Date: 22.12.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useRef, useState} from 'react';
import {getChannelsById, getLastChannelCalibration} from '../functions/Metrology';
import {getBlobValue, getCMDataSets, getData, getForecastData, getValueTypes} from '../functions/Data';
import {Skeleton} from 'primereact/skeleton';
// import Highcharts from 'highcharts';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {
    createMonitoringTaskLimits, deleteMonitoringTaskLimits,
    getCMDataset,
    getCMSpectralData,
    getCoordinates,
    getMonitoringTaskLimits,
    getMonitoringTasks, updateMonitoringTaskLimits
} from './PlotFunctions';
import CustomDialog from '../global/CustomDialog';
import {Toast} from 'primereact/toast';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import Input from '../global/Input';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import TimeRangePicker from '../global/TimeRangePicker';
import {Button} from 'primereact/button';
import {confirmDialog} from 'primereact/confirmdialog';
import {useForm} from 'react-hook-form';
import {Simulate} from 'react-dom/test-utils';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {deleteReports} from '../functions/Reporting';
import {Accordion, AccordionTab} from 'primereact/accordion';
import PlotHighcharts from './PlotHighcharts';
import PlotSonagram from './PlotSonagram';
import {v4 as uuidv4} from 'uuid';
import {Highcharts} from 'highcharts-custom-events';
import PlotLightninchart from './PlotLightninchart';
import {checkPermission} from '../../functions/functionLibrary';
import {permissions} from '../../config/permissions';

type Props = {
    projectId: string
    channelIds: string[],
    title: string,
    // sensorName: string,
    // sensorType: string,
    fromDate: Date | undefined,
    toDate: Date | undefined,
    combineValues: boolean,
    index: number,
    showTimeRangePicker: boolean
    selectedGraphType: string,
    minMaxFromChannel: boolean,

    rerender?: any,
    getMinMaxFromChannel?: boolean,
    chartHeight?: number,
    chartWidth?: number,
    hideLegend?: boolean,
    edit?: boolean,
    link?: string,
    graphTypes?: string[],
    className?: string,
    onChangeGraphType?: (graphType: string, index: number) => void,
    titleAddChannelName?: boolean
    showSkeleton?: boolean
}

const PlotChart = (props: Props): JSX.Element => {

    const chartId = 'chart_' + uuidv4();

    const {t} = useTranslation(['common', 'measurementData', 'input']);

    const LABEL_COLOR = localStorage.getItem('Theme') === 'dark' ? 'white' : 'black';
    const LABEL_BG_COLOR = localStorage.getItem('Theme') === 'dark' ? 'rgba(51, 51, 65, 0.75)' : 'rgba(255, 255, 255, 0.75)';
    const [showSkeleton, setShowSkeleton] = useState(props.showSkeleton);
    const [disableForecast, setDisableForecast] = useState(false);

    const defaultValues = {
        name: '',
        width: 0,
        x: 0,
        y: 0,
        top: true
    };

    const {
        control,
        formState: {errors},
        handleSubmit,
        reset,
        getValues,
        setValue,
    } = useForm<any>({defaultValues});


    const getFormErrorMessage = (name: string) => {
        return (
            errors[name] && (
                <small className="p-error custom">{errors[name]!.message}</small>
            )
        );
    };

    const evaluation_types = [100, 101, 102, 150, 151, 152, 153, 154, 155];
    const blob_types = [117];

    const chartHeight = props.chartHeight;
    const chartWidth = props.chartWidth;

    const toast = useRef<any>();
    const chartRef: any = useRef(null);
    const divRef: any = useRef(null);

    const now = new Date();
    const minusThreeDays = new Date(now);
    minusThreeDays.setDate(now.getDate() - 3);

    const [limitUpdateDelayTimer, setLimitUpdateDelayTimer] = useState<any>(undefined);
    const [channels, setChannels] = useState<any[]>([]);
    const [channelIds, setChannelIds] = useState<string[]>([]);
    const [reloadChart, setReloadChart] = useState<boolean>(false);

    const [chart, setChart] = useState<any>(null);
    const [chartTitle, setChartTitle] = useState<string>('');
    const [chartSubTitle, setChartSubTitle] = useState<string>('');
    const [options, setOptions] = useState<any>({});
    const [chartXAxis, setChartXAxis] = useState<any>(null);
    const [chartYAxis, setChartYAxis] = useState<any>(null);
    const [showCMDatasets, setShowCMDatasets] = useState<boolean>(false);
    const [tempSeries, setTempSeries] = useState<any[]>([]);
    const [series, setSeries] = useState<any[]>([]);
    const [chartOptions, setChartOptions] = useState<any>({});
    const [showBlobDialog, setShowBlobDialog] = useState<boolean>(false);
    const [blobValue, setBlobValue] = useState<any>(undefined);
    const [valueTypes, setValueTypes] = useState<any>(null);
    const [forecastToSet, setForecastToSet] = useState<any>(false);

    const [xAxisCategories, setXAxisCategories] = useState<string[]>([]);

    const [fromDate, setFromDate] = useState<Date | undefined>(props.fromDate || minusThreeDays);
    const [toDate, setToDate] = useState<Date | any>(props.toDate || now);
    const [graphTypes, setGraphTypes] = useState<any[]>([]);
    const [selectedGraphType, setSelectedGraphType] = useState<string>('');
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [info, setInfo] = useState<any>(undefined);
    const [fullscreenEventListener, setFullscreenEventListener] = useState(false);

    const optionsRef = useRef(options);
    const mouseEventRef = useRef('');
    const annotationRef = useRef(null);
    const forecastRef = useRef(forecastToSet);

    // useEffect(() => {
    //     console.log('lI', limitsInit);
    // }, [limitsInit]);

    useEffect(() => {
        // console.log('options', options);
        optionsRef.current = options;
    }, [options]);

    useEffect(() => {
        // console.log(props)
        setFromDate(props.fromDate);
    }, [props.fromDate]);

    useEffect(() => {
        setToDate(props.toDate);
    }, [props.toDate]);

    useEffect(() => {
        setSelectedGraphType(props.selectedGraphType ? props.selectedGraphType : props.graphTypes ? props.graphTypes[0] : '');
    }, [props.selectedGraphType]);

    useEffect(() => {
        if (props.graphTypes) {
            const gt = props.graphTypes.map(item => {
                return {label: t('measurementData:global.plotTypes.' + item), value: item};
            });
            setGraphTypes(gt);
        }
    }, [props.graphTypes]);

    useEffect(() => {
        //  console.log(props.showSkeleton)
        setShowSkeleton(props.showSkeleton);
    }, [props.showSkeleton]);

    const colors: string[] = [
        '#7cb5ec',
        '#6f7989',
        '#90ed7d',
        '#f7a35c',
        '#8085e9',
        '#f15c80',
        '#e4d354',
        '#2b908f',
        '#f45b5b',
        '#91e8e1'
    ];

    const initXAxis = {
        type: 'datetime',
        // labels: {
        //     formatter: function (this: any) {
        //         return Highcharts.dateFormat('%Y-%m-%d<br/>%H:%M:%S', this.value);
        //     }
        // },
        offset: 0,
        min: null,
        max: null,
        gridLineWidth: undefined,
        lineWidth: 1,
        plotLines: [],
        categories: undefined
    };

    const initYAxis = {
        type: 'linear',
        labels: {
            formatter: undefined
        },
        offset: 0,
        min: null,
        max: null,
        gridLineWidth: 1,
        lineWidth: 1,
        plotLines: [],
    };

    const labels: any[] = [];
    const initChartOptions = {
        colors: colors,
        colorAxis: {},
        chart: {
            zoomType: 'xy',
            type: 'line',
            polar: false,
            height: chartHeight,
            width: chartWidth,
        },
        legend:
            {
                maxHeight: 200,
                enabled: props.hideLegend !== true,
            },
        pane: {
            // startAngle: 0,
            // endAngle: undefined
        },
        plotOptions: {
            // series: {
            //     pointStart: 0,
            //     pointInterval: 1
            // },
            // column: {
            //     pointPadding: 0.1,
            //     groupPadding: 0.2
            // }
        },
        tooltip: {enabled: true},
        annotations: [],
        boost: {
            useGPUTranslations: true,
            usePreallocated: true
        },
    };

    useEffect(() => {
        setChannelIds(props.channelIds);

        setOptions({
            accessibility: {
                enabled: false
            },
            credits: {
                enabled: false,
            },
            chart: {
                zoomType: 'xy',
                //     type: 'line',
                //     // height: 650,
                //     // backgroundColor: '#2f3641'
            },
            title: {
                text: chartTitle
            },
            subtitle: {
                text: moment(fromDate).format('DD.MM.YY HH:mm') + ' - ' + moment(toDate).format('DD.MM.YY HH:mm')
            },
            // colors: ['#ffffff', '#8bbc21', '#910000', '#1aadce',
            //     '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a', '#2f7ed8',],

            turboThreshold: 1000000,
            heatmap: {
                turboThreshold: 100000, // increase turboThreshold

            },
            boost: {
                // useGPUTranslations: false,
                // Chart-level boost when there are more than 5 series in the chart
                // seriesThreshold: 1
            },

            legend: {
                ...initChartOptions.legend,
                // layout: 'vertical',
                // align: 'center',
                // verticalAlign: 'bottom'
            },
        });
        return () => {
            if (chart) {
                chart.destroy();
            }
        };
    }, [fromDate, toDate, chartTitle, chartSubTitle]);

    useEffect(() => {
        if (!forecastRef.current) {
            setShowSkeleton(true);
            getDataFromServer();
        }

    }, [JSON.stringify(props.channelIds), fromDate, toDate, selectedGraphType]);

    useEffect(() => {
        setSeries([]);
    }, [tempSeries]);

    useEffect(() => {
        if (series.length > 0) {
            setOptions((prevState: any) => {
                return {...prevState, series: series, xAxis: chartXAxis, yAxis: chartYAxis, ...chartOptions};
            });
        } else {
            if (channels.length > 0 && tempSeries.length > 0 && selectedGraphType != '') {
                setSeries(setDataChartType());
            }
        }
        setForecastToSet(false);
        forecastRef.current = false;
    }, [series]);

    useEffect(() => {
        setOptions((prevState: any) => {
            return {...prevState, xAxis: chartXAxis};
        });
    }, [chartXAxis]);

    useEffect(() => {
        setOptions((prevState: any) => {
            return {...prevState, yAxis: chartYAxis};
        });
    }, [chartYAxis]);

    useEffect(() => {
        setOptions((prevState: any) => {
            return {...prevState, ...chartOptions};
        });
    }, [chartOptions]);


    // useEffect(() => {
    //     console.log('options', options);
    //     if (chartRef && chartRef.current) {
    //         chartRef.current.chart.redraw();
    //     }
    // }, [options]);

    useEffect(() => {
        if (blobValue != undefined) {
            if (!blobValue.error) {
                setShowBlobDialog(true);
            } else {
                showMessageOnError(t('measurementData:attributes.noValue'), blobValue ? blobValue.error : '-');
            }
        }
    }, [blobValue]);

    useEffect(() => {
        getTitle();
        getGroupName();

        // if (channels.length > 0 && measuringDataTable === 'spectral_data' && !showAllSpectralData) {
        //     getLimits(true);
        // }
    }, [channels]);

    const chooseElem = (elem: string, origen: any) => {
        let value;
        switch (elem) {
            case 'chartTitle':
                value = <Skeleton height="2.3rem" width="10em" className="mb-2"></Skeleton>;
                break;
            case 'chartOptions':
                value = <Skeleton height="2.3rem" width="2em" className="mb-2"></Skeleton>;
                break;
            case 'chartChooser':
                value = <Skeleton height="2.3rem" width="15em" className="mb-2"></Skeleton>;
                break;
            case 'cardHeader':
                value = <Skeleton height="3rem" width="15em" className="mb-2"></Skeleton>;
                break;
            case 'chartItSelf':
                value = <Skeleton height="25rem" className="mb-2"></Skeleton>;
                break;
            default:
                value = <Skeleton className="mb-2"></Skeleton>;
                break;
        }

        if (showSkeleton) {
            return value;
        }
        return origen;

    };

    const getDataFromServer = (updateExisting?: boolean) => {

        // console.log(props);
        if (props.channelIds && props.channelIds.length > 0 && fromDate && toDate) {
            chartRef?.current?.chart.showLoading(t('measurementData:global.loading'));

            getChannelsById(props.projectId, props.channelIds).then(async channels => {
                setChannels(channels);
                const tmp_series: any[] = [];

                const vt: any = null;
                if (channels.length > 0) {
                    setShowCMDatasets(false);
                    await getDataFetch(channels, tmp_series);
                }

                chartRef?.current?.chart.hideLoading();

                tmp_series.sort((a, b) => a.name.localeCompare(b.name, 'en', {numeric: true}));

                if (updateExisting) {

                    /* setOptions((prevState: any) => {

                        const tmp = {...prevState};
                        console.log('tmp_update', tmp_series);
                        tmp.series = tmp_series;

                        return tmp;
                    });*/
                } else {
                    setShowSkeleton(false);
                    setTempSeries(tmp_series);
                }
            });
        }
    };

    const getTitle = () => {
        setChartTitle('');
        if (props.title !== '') {
            if (props.titleAddChannelName && channels.length > 0) {
                setChartTitle(props.title + ' - ' + channels[0].channel_name);
            } else {
                setChartTitle(props.title);
            }
        } else if (channels.length === 1) {
            setChartTitle(channels[0].channel_name);
        }
    };

    const cardHeader = () => {
        return (<div className="grid grid-nogutter justify-content-end">
            <div className="col-12 md:col m-auto">{chooseElem('chartTitle', chartTitle)}</div>
            <div className="col-auto my-auto ml-1 mr-1">{chooseElem('chartOptions', <Button
                className="p-button-secondary"
                disabled={disableForecast}
                visible={checkPermission(permissions.seeForecast)}
                onClick={() => {
                    // Hier der API Aufruf für den KI-Server
                    prepareForecast(channelIds);
                }}>{t('measurementData:global.forecast')}</Button>)}</div>
            <div className="col-auto my-auto ml-1 mr-1">{chooseElem('chartOptions', <Button
                className="p-button-secondary"
                icon={'pi pi-window-maximize'}
                onClick={() => {
                    setChartOptions((prevState: any) => {
                        return {
                            ...prevState,
                            chart: {
                                ...prevState.chart,
                                height: window.screen.height,
                                width: document.body.offsetWidth
                            }
                        };
                    });

                    if (!fullscreenEventListener) {
                        document.addEventListener('fullscreenchange', (e) => {
                            if (document.fullscreenElement === null) {
                                setChartOptions((prevState: any) => {
                                    return {
                                        ...prevState,
                                        chart: {
                                            ...prevState.chart,
                                            height: chartHeight,
                                            width: chartWidth
                                        }
                                    };
                                });
                            }
                        });
                        setFullscreenEventListener(true);
                    }

                    document.getElementById(chartId)?.requestFullscreen();
                }}/>)}</div>
            <div className="col-auto">
                {props.graphTypes &&
                    chooseElem('chartChooser', <Dropdown options={graphTypes} value={selectedGraphType}
                                                         onChange={onChange_SelectedGraphType}/>)}
            </div>
            {props.showTimeRangePicker &&
                chooseElem('chartChooser', <div className="col-auto ml-3"><TimeRangePicker from={fromDate} to={toDate}
                                                                                           noLabels={true}
                                                                                           onChange={onChange_TimeRangePicker}/>
                </div>)}
        </div>);
    };

    const getDataFetch = async (channels: any[], tmpArray: any[]) => {
        let vt: any = null;

        await getData(props.projectId, channels.map((x: any) => x.channel_id), props.combineValues, fromDate, toDate).then(async result => {
            for (let i = 0; i < result.length; i++) {
                const data: never[] = [];
                const item = result[i];

                const ch = channels.find((x: any) => {
                    return x.channel_id === item.channel_id;
                });

                if (ch) {
                    // console.log('cti', ch.channel_type_id);
                    if (evaluation_types.indexOf(ch.channel_type_id) >= 0) {
                        if (vt === null) {
                            vt = await getValueTypes();
                        }

                        const vt_hash: any = {};

                        const evalSeries: any[] = [];
                        const evalIndexes: any = {};

                        if (vt) {
                            for (let i = 0; i < vt.length; i++) {
                                const id = vt[i].id.toString();
                                if (!vt_hash[id]) {
                                    vt_hash[id] = {
                                        comment: vt[i].comment,
                                        comment_en: vt[i].comment_en,
                                        chart_symbol: vt[i].chart_symbol
                                    };
                                }
                            }
                        }

                        for (const dataPoint of item.data) {
                            const time = (new Date(dataPoint.timestamp)).getTime();

                            let value_type_id: string = dataPoint.value_type_id;

                            if (value_type_id === null) {
                                value_type_id = '0';
                            }

                            if (!evalIndexes[value_type_id]) {
                                evalIndexes[value_type_id] = {index: evalSeries.length};
                                evalSeries.push({
                                    type: 'scatter',
                                    data: [],
                                    name: vt_hash[value_type_id] ? vt_hash[value_type_id].comment : '',
                                    custom: {
                                        unit: ch.unit,
                                        round: ch.chart_round,
                                        'sensor_name': ch.sensor_name,
                                        'geo_position_x': ch.geo_position_x,
                                        'geo_position_y': ch.geo_position_y,
                                        'channel_type': ch.channel_type_id,
                                    },
                                    marker: {
                                        symbol: vt_hash[value_type_id] ? 'text:' + String.fromCharCode(vt_hash[value_type_id].chart_symbol) : '',
                                    },
                                });
                            }

                            evalSeries[evalIndexes[value_type_id].index].data.push({
                                x: time,
                                y: dataPoint.value,
                                round: ch.chart_round,
                            });
                        }

                        for (let j = 0; j < evalSeries.length; j++) {
                            evalSeries[j].name += '<br/>' + ch.channel_name + ' (' + ch.channel_type_name + ' / ' + evalSeries[j].data.length + ' / ' + ch.unit + ')';
                            tmpArray.push(evalSeries[j]);
                        }

                    } else {
                        iterateOverLineSeries(item, ch, tmpArray);
                    }
                }
            }
        });
    };

    const iterateOverLineSeries = (item: any, ch: any, tmpArray: any) => {
        // console.log('evalseries2')
        const data: any[] = [];
        for (const dataPoint of item.data) {
            const time = (new Date(dataPoint.timestamp)).getTime();
            data.push({x: time, y: dataPoint.value});
            // data.push([time, dataPoint.value, dataPoint.value_type_id]);
        }

        const type = blob_types.indexOf(ch.channel_type_id) >= 0 ? 'scatter' : 'line';

        if (data.length > 0) {
            tmpArray.push({
                type: type,
                data: data,
                name: ch.channel_name + ' (' + ch.channel_type_name + ' / ' + data.length + ' / ' + ch.unit + ')',
                custom: {
                    unit: ch.unit,
                    round: ch.chart_round,
                    sensor_name: ch.sensor_name,
                    geo_position_x: ch.geo_position_x,
                    geo_position_y: ch.geo_position_y,
                    channel_type: ch.channel_type_id,
                },
                point: {
                    events: {
                        click: async (e: any) => {
                            // console.log(ch.channel_id, e.point.x);
                            if (blob_types.indexOf(ch.channel_type_id) >= 0) {
                                const result = await getBlobValue(ch.project_id, ch.channel_id, e.point.x);
                                setBlobValue(result);
                            }
                        }
                    }
                },
            });
        }
    };
    const getGroupName = () => {
        setChartSubTitle(channels.length > 0 ? channels[0].project_group_name : '');
    };

    const setDataChartType = (): any => {
        // console.log('tmpSeries', tempSeries);
        let result = null;
        const types = channels.map((x: any) => {
            return x.channel_type_name;
        });
        const channel = channels[0];

        switch (selectedGraphType) {
            case 'polar': {
                const xA = {
                    // ...initXAxis,
                    tickInterval: 45,
                    reversed: true,
                    min: 0,
                    max: 360,
                    labels: {
                        formatter: (data: any) => {
                            switch (data.value) {
                                case 0:
                                    return '<b>O</b>';
                                case 45:
                                    return 'NO';
                                case 90:
                                    return '<b>N</b>';
                                case 135:
                                    return 'NW';
                                case 180:
                                    return '<b>W</b>';
                                case 225:
                                    return 'SW';
                                case 270:
                                    return '<b>S</b>';
                                case 315 :
                                    return 'SO';
                                default:
                                    return '';
                            }
                        }
                    }
                };
                const yA = [{
                    ...initYAxis,
                    labels: {
                        formatter: undefined
                    },
                    min: 0,
                    max: props.getMinMaxFromChannel ? channel.chart_max : null,
                    // tickPixelInterval: 5
                }];

                setChartOptions({
                    ...initChartOptions,
                    chart: {
                        ...initChartOptions.chart,
                        polar: true,
                    },
                    pane: {
                        ...initChartOptions.pane,
                        startAngle: 90,
                        // endAngle: 360
                    },
                    plotOptions: {
                        ...initChartOptions.plotOptions,
                        series: {
                            pointStart: 0,
                            pointInterval: 45
                        },
                        column: {
                            pointPadding: 0,
                            groupPadding: 0
                        }
                    },
                    tooltip: {
                        ...initChartOptions.tooltip,
                        headerFormat: '<b>{series.name}</b><br>',
                        pointFormat: '{point.timestamp}<br/>Deg: {point.x}<br/>Dist: {point.y}',
                    },
                    xAxis: xA,
                    yAxis: yA,
                });

                result = getCartesianData(channel, types, true);
                break;
            }

            case 'cartesian': {
                result = getCartesianData(channel, types, false);

                let absolutX: number | undefined = undefined;
                let absolutY: number | undefined = undefined;
                let minMax: number | undefined = undefined;

                if (!props.getMinMaxFromChannel) {
                    for (let i = 0; i < result.length; i++) {
                        const minMax = getMinMaxValue(result[i].data.map((item: any) => {
                            return item.x;
                        }), result[i].data.map((item: any) => {
                            return item.y;
                        }));

                        if (minMax.absolutX) {
                            if (!absolutX || minMax.absolutX > absolutX) {
                                absolutX = minMax.absolutX;
                            }
                        }

                        if (minMax.absolutY) {
                            if (!absolutY || minMax.absolutY > absolutY) {
                                absolutY = minMax.absolutY;
                            }
                        }
                    }

                    if (absolutX && absolutY) {
                        minMax = absolutX > absolutY ? absolutX : absolutY;
                    } else if (absolutX) {
                        minMax = absolutX;
                    } else {
                        minMax = absolutY;
                    }
                }
                const min = props.getMinMaxFromChannel && channel.chart_min != channel.chart_max ? channel.chart_min : minMax ? minMax * -1 : undefined;
                const max = props.getMinMaxFromChannel && channel.chart_min != channel.chart_max ? channel.chart_max : minMax;

                // console.log('minmax', min, max);

                const xA = {
                    ...initXAxis,
                    // height: hwValue,
                    // width: hwValue,
                    type: 'linear',
                    labels: {formatter: undefined},
                    title: {
                        text: 'X / Easting (' + channel.unit + ')'
                    },
                    min: min,
                    max: max,
                    gridLineWidth: 1,
                    lineWidth: 0,
                    // tickPixelInterval: 100,
                    plotLines: [{
                        value: 0,
                        width: 2
                    }],
                };
                const yA = [{
                    ...initYAxis,
                    // height: hwValue,
                    // width: hwValue,
                    type: 'linear',
                    labels: {formatter: undefined},
                    title: {
                        text: 'Y / Northing (' + channel.unit + ')'
                    },
                    min: min,
                    max: max,
                    gridLineWidth: 1,
                    lineWidth: 0,
                    // tickPixelInterval: 100,
                    plotLines: [{
                        value: 0,
                        width: 2
                    }],
                }];

                setChartOptions({
                    ...initChartOptions,
                    chart: {
                        ...initChartOptions.chart,
                        zoomType: 'xy',
                        type: 'line',
                        // backgroundColor: '#2f3641'
                    },
                    tooltip: {
                        ...initChartOptions.tooltip,
                        headerFormat: '<b>{series.name}</b><br>',
                        pointFormat: '{point.timestamp}<br/>X: {point.x}<br/>Y: {point.y}',
                    },
                    xAxis: xA,
                    yAxis: yA
                });
                break;
            }


            case 'vector_map': {
                const xA = {
                    ...initXAxis,
                    type: 'linear',
                    labels: {formatter: undefined},
                    title: {
                        text: 'Longitude'
                    },
                };
                const yA = {
                    ...initYAxis,
                    type: 'linear',
                    labels: {formatter: undefined},
                    title: {
                        text: 'Latitude'
                    },
                    gridLineWidth: 0,
                };

                setChartOptions({
                    ...initChartOptions,
                    chart: {
                        ...initChartOptions.chart,
                        zoomType: 'xy',
                        type: 'line',
                        // backgroundColor: '#2f3641'
                    },
                    tooltip: {
                        ...initChartOptions.tooltip,
                        headerFormat: '<b>{series.name}</b><br>',
                        pointFormat: '{point.timestamp}<br/>Lng: {point.x}<br/>Lat: {point.y}',
                    },
                    xAxis: xA,
                    yAxis: yA
                });

                result = getVectorMapData(channel, types);
                break;
            }

            case 'lines':
            default: {
                const goOn = true;

                const xA = initXAxis;

                setChartOptions({
                    ...initChartOptions,
                    chart: {
                        ...initChartOptions.chart,
                        zoomType: 'xy',
                        type: 'line',
                        // height: 650,
                        // backgroundColor: '#2f3641'
                    },
                    tooltip: {
                        ...initChartOptions.tooltip,
                        // headerFormat: '<b>{series.name}</b><br>',
                        // pointFormat: 'X:{point.x}\nY:{point.y}',
                        // valueSuffix: ' {series.custom.unit}',
                        formatter: function (): any {
                            // @ts-ignore
                            console.log('this', this.point);
                            // @ts-ignore
                            return '<div style="font-size: 0.6rem">' + this.series.name + '</div><br/>X: ' + moment(this.x).format('DD.MM.YYYY HH:mm:ss') + '<br/>Y: ' + this.y.toFixed(this.point.round != null ? this.point.round : 3) + (this.point.custom ? '<br/>' + this.point.custom.comment : '');
                        }
                    },
                    xAxis: xA
                    // title: channel.sensor_name
                });

                // for (let i = tempSeries.length - 1; i >= 0; i--) {
                //     let ts = tempSeries[i];
                //
                //     ts.showInLegend = false;
                // }


                if (goOn) {
                    const arrYLabels: string[] = [];

                    const arrYAxis: any[] = [];

                    for (let i = 0; i < channels.length; i++) {
                        const channel = channels[i];

                        const min = props.getMinMaxFromChannel && channel.chart_min != channel.chart_max ? channel.chart_min : null;
                        const max = props.getMinMaxFromChannel && channel.chart_min != channel.chart_max ? channel.chart_max : null;

                        if (arrYLabels.indexOf(channel.unit) < 0) {
                            arrYLabels.push(channel.unit);

                            arrYAxis.push({
                                ...initYAxis,
                                min: min,
                                max: max,
                                title: {
                                    text: channel.unit
                                },
                                labels: {
                                    format: '{value:.3f} ' + channel.unit
                                }
                            });
                        }
                    }

                    setChartYAxis(arrYAxis);
                }

                result = tempSeries.slice();
                break;
            }
        }
        return result;
    };

    const getCartesianData = (channel: any, types: string[], polar: boolean): any[] => {
        const result: any[] = [];
        if (tempSeries.length % 3 === 0) {

            const length = tempSeries.length;
            let index = 0;
            let colorIndex = 0;

            while (index < length) {

                let xIndex = -1;
                let yIndex = -1;
                let zIndex = -1;

                for (let i = 0; i < index + 3; i++) {
                    if (types[i] === 'FIN X') {
                        xIndex = i;
                    }

                    if (types[i] === 'FIN Y') {
                        yIndex = i;
                    }

                    if (types[i] === 'FIN Z') {
                        zIndex = i;
                    }
                }

                const data0: any[] = [];
                const dataVector: any[] = [];

                result.push({
                    type: polar ? 'line' : 'scatter',
                    color: colors[colorIndex % colors.length],
                    name: tempSeries[xIndex].custom.sensor_name,
                    marker: {
                        enabled: !polar,
                        symbol: 'square', radius: 2
                    },
                    data: data0,
                });

                for (let i = 0; i < tempSeries[xIndex].data.length; i++) {

                    let x = tempSeries[xIndex].data[i].x;
                    let y = tempSeries[yIndex].data[i].y;

                    const date = moment(tempSeries[xIndex].data[i].x).format('DD.MM.YYYY HH:mm:ss');

                    if (polar) {
                        const polarCoords = cartesian2Polar(x, y);

                        y = polarCoords.distance;

                        if (polarCoords.degrees < 0) {
                            x = 360 + polarCoords.degrees;
                        } else {
                            x = polarCoords.degrees;
                        }

                    }

                    const data = {
                        x: x,
                        y: y,
                        index: i,
                        timestamp: date,
                        dataLabels: {
                            formatter: () => {
                                return date + ' (' + tempSeries[xIndex].data[i].x.toFixed(3) + ',' + tempSeries[yIndex].data[i].y.toFixed(3) + ')';
                            }
                        }
                    };

                    // @ts-ignore
                    data0.push(data);

                    if (i === 0) {
                        dataVector.push({...data, marker: {enabled: false}});
                    }

                    if (i === tempSeries[xIndex].data.length - 1) {
                        dataVector.push(data);
                    }
                }


                result.push({
                    type: 'line',
                    color: colors[colorIndex % colors.length],
                    name: tempSeries[xIndex].custom.sensor_name + ' Vector',
                    data: dataVector,
                    marker: {
                        symbol: 'square'
                    }
                });

                // result[result.length - 1].data = data0;

                if (tempSeries[zIndex].data.length > 0) {

                    const yStart = tempSeries[zIndex].data[0].y;
                    const yEnd = tempSeries[zIndex].data[tempSeries[zIndex].data.length - 1].y;

                    const data1: any[] = [];

                    result.push({
                        type: 'line',
                        color: colors[colorIndex % colors.length],
                        // @ts-ignore
                        marker: {
                            enabled: true,
                            symbol: (yEnd - yStart) >= 0 ? 'triangle' : 'triangle-down',
                        },
                        name: tempSeries[xIndex].custom.sensor_name + ' Height (' + (yEnd - yStart).toFixed(4) + tempSeries[xIndex].custom.unit + ')',
                        data: data1
                    });

                    data1.push({
                        x: 0,
                        y: yStart,
                        index: 0,
                        timestamp: moment(tempSeries[zIndex].data[0].x).format('DD.MM.YYYY HH:mm:ss'),
                        marker: {
                            enabled: false
                        }
                    });

                    data1.push({
                        x: 0,
                        y: yEnd,
                        index: 0,
                        timestamp: moment(tempSeries[zIndex].data[tempSeries[zIndex].data.length - 1].x).format('DD.MM.YYYY HH:mm:ss'),
                    });
                }
                index += 3;
                colorIndex++;
            }

        }
        return result;
    };

    const getVectorMapData = (channel: any, types: string[]): any[] => {
        const result: any[] = [];
        if (tempSeries.length % 3 === 0) {

            const length = tempSeries.length;
            let index = 0;
            let colorIndex = 0;

            while (index < length) {

                let xIndex = -1;
                let yIndex = -1;
                let zIndex = -1;

                const factor = 10;

                for (let i = 0; i < index + 3; i++) {
                    if (types[i] === 'FIN X') {
                        xIndex = i;
                    }

                    if (types[i] === 'FIN Y') {
                        yIndex = i;
                    }

                    if (types[i] === 'FIN Z') {
                        zIndex = i;
                    }
                }

                const lng = tempSeries[xIndex].custom.geo_position_x;
                const lat = tempSeries[xIndex].custom.geo_position_y;

                const dataVector: any[] = [{x: lng, y: lat}];

                result.push({
                    type: 'line',
                    color: colors[colorIndex % colors.length],
                    name: tempSeries[xIndex].custom.sensor_name + ' Vector (F: *' + factor + ')',
                    data: dataVector,
                    marker: {
                        symbol: 'square', radius: 2
                    }
                });

                if (tempSeries[xIndex].data.length > 0 && tempSeries[yIndex].data.length > 0) {
                    const xStart = tempSeries[xIndex].data[0].x;
                    const yStart = tempSeries[yIndex].data[0].y;

                    const xEnd = tempSeries[xIndex].data[tempSeries[xIndex].data.length - 1].x;
                    const yEnd = tempSeries[yIndex].data[tempSeries[yIndex].data.length - 1].y;

                    const time_end = moment(tempSeries[xIndex].data[tempSeries[xIndex].data.length - 1].x).format('DD.MM.YYYY HH:mm:ss');

                    const polarCoords = cartesian2Polar(xEnd, yEnd);

                    if (polarCoords.degrees < 0) {
                        polarCoords.degrees = 360 + polarCoords.degrees;
                    }

                    const degrees = Math.atan2(yStart - yEnd, xStart - xEnd) * 180 / Math.PI;

                    // console.log('deg', tempSeries[xIndex].custom.sensor_name, degrees);

                    const destCoords = getCoordinates(lng, lat, degrees, polarCoords.distance * factor);

                    dataVector.push({
                        x: destCoords.lng,
                        y: destCoords.lat,
                        timestamp: time_end,
                        dataLabels: {
                            formatter: () => {
                                return time_end;
                            }
                        }
                    });
                }

                if (tempSeries[zIndex].data.length > 0) {

                    const zStart = tempSeries[zIndex].data[0].y;
                    const zEnd = tempSeries[zIndex].data[tempSeries[zIndex].data.length - 1].y;

                    const zDestCoords = getCoordinates(lng, lat, 0, (zEnd - zStart) * factor);

                    const dataHeight: any[] = [];
                    // @ts-ignore
                    result.push({
                        type: 'line',
                        color: colors[colorIndex % colors.length],
                        // @ts-ignore
                        marker: {
                            enabled: true,
                            symbol: (zEnd - zStart) >= 0 ? 'triangle' : 'triangle-down'
                        },
                        name: tempSeries[xIndex].custom.sensor_name + ' Height (' + (zEnd - zStart).toFixed(4) + tempSeries[xIndex].custom.unit + ') (Factor: *' + factor + ')',
                        data: dataHeight,
                    });

                    // @ts-ignore
                    dataHeight.push({
                        x: lng,
                        y: lat,
                        index: 0,
                        timestamp: moment(tempSeries[zIndex].data[0].x).format('DD.MM.YYYY HH:mm:ss'),
                        marker: {
                            enabled: false
                        }
                    });

                    // @ts-ignore
                    dataHeight.push({
                        x: lng,
                        y: zDestCoords.lat,
                        index: 0,
                        timestamp: moment(tempSeries[zIndex].data[tempSeries[zIndex].data.length - 1].x).format('DD.MM.YYYY HH:mm:ss'),
                    });

                }

                const dataLocation: any[] = [{x: lng, y: lat}];

                result.push({
                    type: 'line',
                    color: colors[colorIndex % colors.length],
                    name: tempSeries[xIndex].custom.sensor_name + ' Location',
                    data: dataLocation,
                    marker: {
                        symbol: 'circle'
                    }
                });

                index += 3;
                colorIndex++;
            }
        }
        return result;
    };

    const cartesian2Polar = (x: number, y: number) => {
        const distance = Math.sqrt(x * x + y * y);
        const radians = Math.atan2(y, x); // This takes y first
        const polarCoor = {distance: distance, radians: radians, degrees: radians * (180 / Math.PI)};
        return polarCoor;
    };

    const getMinMaxValue = (xValues: any[], yValues: any[]) => {
        let minX: number | undefined = undefined;
        let maxX: number | undefined = undefined;

        let minY: number | undefined = undefined;
        let maxY: number | undefined = undefined;

        for (let i = 0; i < xValues.length; i++) {
            if (!minX || xValues[i] < minX) {
                minX = xValues[i];
            }
            if (!maxX || xValues[i] > maxX) {
                maxX = xValues[i];
            }
        }

        for (let i = 0; i < yValues.length; i++) {
            if (!minY || yValues[i] < minY) {
                minY = yValues[i];
            }
            if (!maxY || xValues[i] > maxY) {
                maxY = yValues[i];
            }
        }

        const result = {
            minX: minX,
            maxX: maxX,
            minY: minY,
            maxY: maxY,
            absolutX: minX && maxX ? Math.abs(minX) > Math.abs(maxX) ? Math.abs(minX) : Math.abs(maxX) : undefined,
            absolutY: minY && maxY ? Math.abs(minY) > Math.abs(maxY) ? Math.abs(minY) : Math.abs(maxY) : undefined,
        };

        // console.log('minMax', result, xValues, yValues);
        return result;
    };

    const getBlobValueElement = () => {
        if (blobValue) {
            switch (blobValue.content_type) {
                case 'image/jpeg':
                    return <img className={'blob-image'} src={'data:image/jpeg;base64,' + blobValue.value}></img>;

                default:
                    return <div>---</div>;
            }
        }
    };

    const prepareForecast = (channelIds: any) => {

        if (series.length === 1) {
            setShowSkeleton(true);
            getLastChannelCalibration(props.projectId, channelIds[0]).then((calibrated: any) => {
                let addend = 0;
                if (Object.keys(calibrated).length > 0) {
                    addend = calibrated.addend;
                }
                getForecastData(channelIds).then(res => {
                    setForecastToSet(true);
                    forecastRef.current = true;
                    setToDate(new Date(res[res.length - 1].timestamp));
                    const data = [];
                    for (const dataPoint of res) {
                        const time = (new Date(dataPoint.timestamp)).getTime();
                        data.push({x: time, y: dataPoint.value + addend});
                        // data.push([time, dataPoint.value, dataPoint.value_type_id]);
                    }

                    const cloneSeries = Object.assign({}, tempSeries[0]);
                    cloneSeries.data = data;
                    cloneSeries.name = cloneSeries.name + ' 14 Tage';
                    tempSeries.push(cloneSeries);
                    setSeries(tempSeries);
                    // console.log(series, toDate, fromDate,new Date(res[res.length-1].timestamp) );
                    setShowSkeleton(false);
                    setDisableForecast(true);
                });
            });

        }

    };

    // Events

    const onChange_SelectedGraphType = (e: DropdownChangeParams) => {
        setSelectedGraphType(e.target.value);
        if (props.onChangeGraphType) {
            props.onChangeGraphType(e.target.value, props.index);
        }
    };

    const onChange_TimeRangePicker = (from: Date, to: Date) => {
        console.log('from', from);
        setFromDate(from);
        setToDate(to);
    };

    return (
        <div className={'p-2' + props.className}>
            <div className={'card p-3 mb-0 w-full'}
                 data-index={props.index}>
                {cardHeader()}
                {chooseElem('chartItSelf', <div className={'relative'} ref={divRef}>
                    {info && <div className={'plot-info'}>{info}</div>}
                    {props.link &&
                        <a href={props.link} className={'plot-external-link'}><i
                            className={'pi pi-external-link'}/></a>}
                    {series.length <= 0 && <div>{t('measurementData:attributes.noData')}</div>}
                    {series.length > 0 && <div key={channels[0]?.key} className="plot">
                        {/* {!spectralRaw &&*/}
                        <>
                            <PlotHighcharts id={chartId} chartRef={chartRef}
                                            options={options}
                            />
                            {/* <PlotLightninchart id={chartId} data={series} limits={limits}/>*/}
                        </>
                    </div>}
                    <Toast ref={toast}/>
                    {
                        showBlobDialog && blobValue && <CustomDialog
                            header={blobValue.content_type + ' - ' + moment(blobValue.timestamp).format('DD.MM.YYYY HH:mm')}
                            onCancel={() => {
                                setBlobValue(undefined);
                                setShowBlobDialog(false);
                            }}
                            visible={showBlobDialog}
                            onHide={() => {
                                setBlobValue(undefined);
                                setShowBlobDialog(false);
                            }}
                            onClick={() => {
                                setBlobValue(undefined);
                                setShowBlobDialog(false);
                            }}>
                            {getBlobValueElement()}
                        </CustomDialog>
                    }
                </div>)}
            </div>
        </div>
    );
};

export default PlotChart;
