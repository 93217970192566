/*
 * ProjectTypeSelectList.tsx
 * Author: lnappenfeld
 * Date: 10.03.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState, useEffect} from 'react';
import Input from '../global/Input';
import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';

type Props = {
    label: string,
    name: string,
    value?: string;
    setValue?: (e: any) => void,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    disabled?: boolean,
}

const ProjectTypeSelectList = (props: Props):JSX.Element => {

    // Initialisiere mit undefined, damit das Skeletonloading funktioniert
    const [list, setList] = useState();

    useEffect(() => {
        keycloakfetch.get(settings.apiUrl + '/getProjectTypes').then(result => {
            const tmp: any = [];
            result.forEach((key: any) => {
                tmp.push({
                    value: key.id,
                    label: key.name,
                });
            });
            setList(tmp);

        });
    }, []);

    return (
        <Input
            name={props.name}
            edit={true}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            label={props.label}
            value={props.value}
            dropdownOptions={list}
            scrollHeight={'350px'}
            type={'dropdown'}
            onChange={(e: any) => {
                if (props.setValue) props.setValue(e.value);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};

export default ProjectTypeSelectList;