/*
 * TimezoneSelectList.tsx
 * Author: amusiol
 * Date: 24.02.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';
import Input from './Input';
import {useTranslation} from 'react-i18next';

type Props = {
    label: string,
    value?:any,
    onChange?: (e: any) => void,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
}
const TimezoneSelectList = (props: Props): JSX.Element => {

    const {t} = useTranslation();
    // Initialisiere mit undefined, damit das Skeletonloading funktioniert
    const [list, setList] = useState();

    useEffect(() => {
        keycloakfetch.get(settings.apiUrl + '/getTimeZoneList').then(result => {
            setList(result);
        });
    }, []);

    let input:any = <Input
        name={'timezone'}
        edit={true}
        label={props.label}
        value={props.value}
        dropdownOptions={list}
        scrollHeight={'350px'}
        type={'dropdown'}
        tooltip={t('input:tooltips.timezoneSelectList')}
        onChange={(e: any) => {
            if (props.onChange) props.onChange(e);
        }}
    />;
    if(props.validationControl)
        input = <Input
            name={'timezone'}
            edit={true}
            label={props.label}
            dropdownOptions={list}
            scrollHeight={'350px'}
            type={'dropdown'}
            tooltip={t('input:tooltips.timezoneSelectList')}
            onChange={(e: any) => {
                if (props.onChange) props.onChange(e);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    return (<>{input}</>);
};

export default TimezoneSelectList;
