/*
 * Actions.tsx
 * Author: lnappenfeld
 * Date: 04.05.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState, useEffect} from 'react';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {useTranslation} from 'react-i18next';

import {permissions} from '../../config/permissions';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {checkPermission, getGroupedItems} from '../../functions/functionLibrary';
import CustomDialog, {CustomDialogHeaderType} from '../global/CustomDialog';
import DisableNotificationActionTemporaryForm from './DisableNotificationActionTemporaryForm';
import NotificationActionMapForm from './NotificationActionMapForm';
import {
    Action, deleteNotificationAction,
    getActionTypes,
    getNotificationActions,
    getNotificationsActions,
    getNotificationTypes,
    NotificationActionMap
} from '../functions/Notification';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {filterTriStateCheckBox} from '../global/filter';


type Props = {
    projectId: string,
    notificationId?: string,
    headerTitle?: string,
    selectedNotificationActions?: NotificationActionMap[],
    setSelectedNotificationActions?: (data: NotificationActionMap[]) => void,
    reloadNotificationActions?: boolean,
    setReloadNotificationActions?: (data: boolean) => void,
    editNotificationAction?: boolean,
    setEditNotificationAction?: (data: boolean) => void,
    disableNotificationActionTemporary?: boolean,
    setDisableNotificationActionTemporary?: (data: boolean) => void,
}
export const NotificationsActionsMapOverview = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common', 'notifications', 'actions']);

    const [notificationActions, setNotificationActions] = useState<NotificationActionMap[]>([]);
    // Lokale States für die Untertabelle einer expandedRow, muss so gelöst werden, weil die props an das
    // 'expandedElement' nur einmal initial übergeben werden können, aber keine Aktualisierten props
    const [selectedNotificationActions, setSelectedNotificationActions] = useState<NotificationActionMap[]>([]);
    const [editNotificationAction, setEditNotificationAction] = useState<boolean>(false);


    // arrays for filter dropdowns
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [notificationTypes, setNotificationTypes] = useState<any[]>([]);
    const [actions, setActions] = useState<Action[]>([]);
    const [actionTypes, setActionTypes] = useState<any[]>([]);
    const [templates, setTemplates] = useState<any[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const _setActionTypes = () => {
        getActionTypes().then(result => {
            setActionTypes(result);
        });
    };

    const _setNotificationTypes = () => {
        getNotificationTypes(props.projectId).then(result => {
            setNotificationTypes(result);
        });
    };

    const _setNotificationActions = () => {
        if (props.notificationId) {
            getNotificationActions(props.projectId, props.notificationId).then(result => {
                if (result) {
                    _setStates(result);
                }
            });
        } else {
            getNotificationsActions(props.projectId).then(result => {
                if (result) {
                    _setStates(result);
                }
            });
        }
    };

    const _setStates = (values: any[]) => {
        // data table values
        setNotificationActions(values);
        setDataLoaded(true);
        // filter dropdown values
        setNotifications(getGroupedItems(values, 'notification_id', 'notification_name'));
        setActions(getGroupedItems(values, 'action_id', 'action_name'));
        setTemplates(getGroupedItems(values, 'notification_template_id', 'notification_template_name'));
    };

    useEffect(() => {
        // Setze die lokalen States für den Dialog zum Anlegen einer NEUEN NotificationAction
        // Könnte auch direkt an den Dialog übergeben werden, so muss aber nur auf die lokalen States geprüft werden
        if (props.editNotificationAction) setEditNotificationAction(props.editNotificationAction);
        if (props.selectedNotificationActions) setSelectedNotificationActions(props.selectedNotificationActions);
    }, [props.editNotificationAction, props.selectedNotificationActions]);


    useEffect(() => {
        _setNotificationTypes();
        _setActionTypes();
        _setNotificationActions();
        if (props.setSelectedNotificationActions) {
            props.setSelectedNotificationActions([]);
        } else {
            setSelectedNotificationActions([]);
        }
    }, []);


    useEffect(() => {
        // Lade neu, wenn Elemente gelöscht wurden
        if (props.reloadNotificationActions) {
            _setNotificationActions();
        }
        if (props.setReloadNotificationActions) props.setReloadNotificationActions(false);

        return () => setNotificationActions([]);
    }, [props.reloadNotificationActions]);

    const filterCheckboxIsEnabled = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), t('enabled'), t('disabled'));
    };

    const notificationColumns: ColumnObject[] = [
        {
            field: 'is_enabled', header: t('status'), filter: true,
            filterElement: filterCheckboxIsEnabled, style: {maxWidth: '100px'},
        },
        {
            field: 'notification_id',
            header: t('alarmManagement:attributes.notificationId'),
            filter: false, display: false
        },
        {
            field: 'notification_name',
            header: t('notification'),
            filter: true,
            filterField: 'notification_name',
        },
        {
            field: 'notification_type_id',
            header: t('alarmManagement:attributes.alarmTypeId'),
            style: {maxWidth: '100px'},
            filter: false,
            display: false
        },
        {
            field: 'notification_type_name',
            header: t('alarmManagement:attributes.alarmType'),
            filter: true,
            filterField: 'notification_type_id',
            filterDropdownValues: notificationTypes
        },
        {
            field: 'action_id',
            header: t('alarmManagement:attributes.actionId'),
            filter: false,
            display: false
        },
        {
            field: 'action_name',
            header: t('action'),
            filter: true,
            filterField: 'action_id',
            filterDropdownValues: actions
        },
        {
            field: 'action_type_id',
            header: t('alarmManagement:attributes.actionTypeId'),
            style: {maxWidth: '100px'},
            filter: false,
            display: false
        },
        {
            field: 'action_type_name',
            header: t('userManagement:attributes.recipientType'),
            style: {maxWidth: '225px'},
            filter: true,
            filterField: 'action_type_id',
            filterDropdownValues: actionTypes
        },
        {
            field: 'notification_template_id',
            header: t('alarmManagement:attributes.notificationTemplateId'),
            filter: false,
            display: false
        },
        {
            field: 'notification_template_name',
            header: t('template'),
            filter: true,
            filterField: 'notification_template_id',
            filterDropdownValues: templates
        },
        {
            field: 'is_disabled_until_utc',
            header: t('alarmManagement:attributes.is_disabled_until_utc'),
            type: 4,
        },
    ];

    const filters = {
        'notification_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'action_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'action_type_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'notification_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'notification_type_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'notification_template_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'is_enabled': {
            operator: FilterOperator.AND,
            constraints: [{value: props.notificationId ? null : true, matchMode: FilterMatchMode.EQUALS}]
        },
    };

    // if (props.notificationId) delete notificationColumns[0];

    return (
        <>
            <CustomDataTable
                id='notificationActions'
                columns={notificationColumns}
                headerTitle={props.headerTitle ? props.headerTitle : ''}
                editable={true}
                sortable={true}
                displayColumnFilter={true}
                value={notificationActions}
                onRemoveClick={(data: any) => {
                    CustomConfirmDialog({
                        message: t('alarmManagement:dialogs.deleteNotificationAction'),
                        header: t('confirmation'),
                        translation: t,
                        onConfirm: () => {
                            deleteNotificationAction(props.projectId, [data.notification_id], [data.action_id]).then(result => {
                                if (result.error) {
                                    showMessageOnError(t('error'), result.error);
                                } else {
                                    _setNotificationActions();
                                    showMessageOnSuccess(t('success'), t('alarmManagement:toasts.notificationActionsRemoved'));
                                }
                            });
                        }
                    });
                }}
                onEditClick={(data) => {
                    if (props.setSelectedNotificationActions) props.setSelectedNotificationActions([data]);
                    setSelectedNotificationActions([data]);
                    setEditNotificationAction(true);
                }}
                filters={filters}
                ableToDelete={checkPermission(permissions.editNotification)}
                ableToUpdate={checkPermission(permissions.editNotification)}
                selectionMode={(props.notificationId) ? 'single' : 'multiple'}
                selection={selectedNotificationActions}
                onSelectionChange={(e: any) => {
                    if (props.setSelectedNotificationActions) props.setSelectedNotificationActions(e.value);
                }}
                dataloaded={dataLoaded}
                showExportCSV={true}
                showClearFiltersButton={true}
            />
            {
                <CustomDialog
                    header={selectedNotificationActions.length > 0 ? t('alarmManagement:dialogs.editNotificationAction') : t('alarmManagement:dialogs.createNotificationAction')}
                    headerType={selectedNotificationActions.length > 0 ? CustomDialogHeaderType.Update : CustomDialogHeaderType.Create}
                    visible={editNotificationAction}
                    formName={'formEditNotificationActionMap'}
                    onHide={() => {
                        if (props.setEditNotificationAction) props.setEditNotificationAction(false);
                        setEditNotificationAction(false);
                    }}
                    onCancel={() => {
                        if (props.setEditNotificationAction) props.setEditNotificationAction(false);
                        setEditNotificationAction(false);
                    }}
                >
                    <NotificationActionMapForm
                        projectId={props.projectId}
                        onFinished={() => {
                            if (props.setEditNotificationAction) props.setEditNotificationAction(false);
                            if (props.setSelectedNotificationActions) props.setSelectedNotificationActions([]);
                            setEditNotificationAction(false);
                            setSelectedNotificationActions([]);
                            _setNotificationActions();
                        }}
                        data={selectedNotificationActions.length > 0 ? selectedNotificationActions[0] : null}
                    />
                </CustomDialog>
            }
            {
                <CustomDialog
                    header={t('alarmManagement:dialogs.disableNotificationTemporaryDialog.header')}
                    className={'w-3'}
                    formName={'disableNotificationActionTemporaryFormDialog'}
                    onCancel={() => {
                        if (props.setDisableNotificationActionTemporary) props.setDisableNotificationActionTemporary(false);
                    }}
                    onHide={() => {if (props.setDisableNotificationActionTemporary) props.setDisableNotificationActionTemporary(false)}}
                    visible={props.disableNotificationActionTemporary ? props.disableNotificationActionTemporary : false}>
                    <DisableNotificationActionTemporaryForm
                        projectId={props.projectId}
                        onFinished={() => {
                            if (props.setDisableNotificationActionTemporary) props.setDisableNotificationActionTemporary(false);
                            if (props.setSelectedNotificationActions) props.setSelectedNotificationActions([]);
                            setSelectedNotificationActions([]);
                            _setNotificationActions();
                        }}
                        notificationActionData={props.selectedNotificationActions}
                        setVisible={props.setDisableNotificationActionTemporary}
                    />
                </CustomDialog>
            }
        </>
    );
};

