/*
 * LocationSelectList.tsx
 * Author: amusiol
 * Date: 17.10.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState, useEffect} from 'react';
import Input from '../global/Input';
import {getLocations} from '../functions/Global';

export enum LocationSelectListType {
    MetrologyWizard = 1,
}

type Props = {
    type?: LocationSelectListType,
    projectId: string,
    label: string,
    onChange?: (e: any) => void,
    locations?:any,
    value?:string
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    disabled?: boolean,
    tooltip?: string
}

const LocationSelectList = (props: Props):JSX.Element => {

    const [list, setList] = useState<any[]>();

    useEffect(() => {
        if (props.locations) {
            switch (props.type) {
                case LocationSelectListType.MetrologyWizard: {
                    const tmp: any = [{value: 'none', label: '-- none --'}];
                    for (const key in props.locations) {
                        tmp.push({
                            value: key,
                            label: props.locations[key].location_name,
                        });
                    }
                    setList(tmp);
                    break;
                }
                default: {
                    const tmp: any = [];
                    for (const key in props.locations) {
                        tmp.push({
                            value: props.locations[key].location_id,
                            label: props.locations[key].location_name,
                        });
                    }
                    setList(tmp);
                    break;
                }

            }
        } else {
            getLocations(props.projectId).then(result => {
                const tmp: any = [];
                result.forEach((key: any) => {
                    tmp.push({
                        value: key.location_id,
                        label: key.location_name,
                    });
                });
                setList(tmp);
            });
        }
        return () => setList(undefined);
    }, [props.locations]);

    return (
        <Input
            name={'location_id'}
            edit={true}
            label={props.label}
            value={props.value}
            tooltip={props.tooltip}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            showClear={true}
            dropdownOptions={list}
            type={'dropdown'}
            onChange={(e: any) => {
                if (props.onChange) props.onChange(e);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};

export default LocationSelectList;