import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import {
    acknowledgeAlerts,
    getAcknowledgeFilter, getAlarmOriginFilter,
    getAlerts,
    getChannelFilter,
    getPlantFilter, getSubPlantFilter
} from '../functions/AlertsService';
import CustomDataTable, {ColumnObject} from '../../global/CustomDataTable';
import {showMessageOnSuccess} from '../../global/CustomToast';
import {CustomConfirmDialog} from '../../global/CustomConfirmDialog';
import {FilterMatchMode} from 'primereact/api';
import {getGroupedItems} from '../../../functions/functionLibrary';
import {getUserFromLocalStorage} from '../../functions/Global';
import moment from 'moment-timezone';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Paginator } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';

type AlertProps = {
    projectId: string
    alarmOrigin?: number
    colorTheme: string
}
const Alerts = (props: AlertProps) => {
    const { t } = useTranslation(['common']);
    const [alerts, setAlerts] = useState<any>([]);
    const [update, setUpdate] = useState<number>(0);
    const [plants, setPlants] = useState<any>([]);
    const [channels, setChannels] = useState<any>([]);
    const [users, setUsers] = useState<any>([]);
    const [components, setComponents] = useState<any>([]);
    const [origins, setOrigins] = useState<any>([]);
    const [rerenderTable, setRerenderTable] = useState<number>(0);
    const [showTable, setShowTable] = useState<boolean>(true);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [updateTable, setUpdateTable] = useState<boolean>(false);
    const [filteredVals, setFilteredVals] = useState<any>({});
    const [periodFrom, setPeriodFrom] = useState<any>();
    const [periodTo, setPeriodTo] = useState<any>();
    const [filtersState, setFilterState] = useState<any>({});
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const onPageChange = (event: any) => {
        setUpdateTable(true);
        setFirst(event.first);
        setRows(event.rows);
        let data: any = {};
        if (periodFrom && periodTo !== undefined || periodFrom && periodTo !== '') {
            data = {
                ...filteredVals,
                ['status_start']:{
                    'timestamp_from': periodFrom,
                    'timestamp_to': periodTo
                }
            };
        }
        else {
            data = filteredVals;
        }
        getAlerts(props.projectId, data, event.first, event.rows).then((res) => {
            setAlerts(res['finalArr']);
            setTotalRecord(res['totalRecords']);
            setUpdateTable(false);
            setDataLoaded(true);
        });
    };

    const pagingTemplate = {
        layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport ',
        RowsPerPageDropdown: (options: any) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 70, value: 70 },
                { label: 100, value: 100 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
                        {t('conditionMonitoring:items_per_page')}:{' '}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        CurrentPageReport: (options: any) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '140px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    const columns: ColumnObject[] = [{
        field: 'timestamp', header: t('conditionMonitoring:alarm_time'),
        filter: false, type: 4,
        body: (template: any) => {
            const timezone = getUserFromLocalStorage().timezone;
            const newTime = moment(template['timestamp']).tz(timezone).format('DD.MM.YYYY HH:mmZ').split('+');
            return (template['timestamp'] === null ? '---' : <>{newTime[0]}</>);
        }, style:{maxWidth: '150px'}
    },
    {
        field: 'alarm_end', header: t('conditionMonitoring:alarm_end_time'),
        filter: false, type: 4,style:{maxWidth: '150px'},
        body: (template: any)=>{
            const timezone = getUserFromLocalStorage().timezone;
            const newTime = moment(template['alarm_end']).tz(timezone).format('DD.MM.YYYY HH:mmZ').split('+');
            return (template['alarm_end'] === null ? '---' : <>{newTime[0]}</>);
        }
    },
    {
        field: 'plant_name', header:t('conditionMonitoring:plant_name'),
        filter: true, filterDropdownValues: plants, filterField: 'plant_ids',style:{maxWidth: '150px'},
        body: (template: any)=>{
            return (<>
                <p onClick={()=>{
                    (window.location.hash =
                            '#conditionomonitoring_' + template['plant_ids']);
                }}
                >{template['plant_name']}</p></>);
        }
    },
    {
        field: 'component_name', header:t('conditionMonitoring:component_name'),
        filter: true, filterDropdownValues: components, filterField: 'component_ids',
        body: (template: any)=>{
            return (<>
                <p onClick={()=>{
                    window.location.hash ='#conditionomonitoring_' + template['component_ids'] + '_detail';
                }}>{template['component_name']}</p></>);
        }
    },
    {
        field: 'monitoring_system', header:t('conditionMonitoring:monitoring_system'),
        filter: true,  filterField: 'status_origin_type_ids', filterDropdownValues: origins,
        body: (template: any)=>{
            return (<>
                <p onClick={()=>{
                    window.location.hash ='#conditionomonitoring_' + template['component_ids'] + '_doppelfilter';
                }}>{template['monitoring_system']}</p></>);
        }
    },
    {
        field: 'description', header:t('description'),
        filter: false, body: (template: any) => {
            return (
                <>{
                    template['description'] === null ? '-' : template['description']
                }</>
            );
        }
    },
    {
        field: 'channel_name', header:t('conditionMonitoring:measuring_point'),
        filter: true, filterDropdownValues: channels, filterField: 'channel_ids', style:{maxWidth: '170px'},
        body: (template: any) => {
            return (
                <>{
                    template['channel_name']
                }</>
            );
        }
    },
    {
        field: 'acknowledged_timestamp', header:t('conditionMonitoring:date_accepted'),
        filter: false, type: 4,
        body: (template: any) => {
            const timezone = getUserFromLocalStorage().timezone;
            const newTime = template['acknowledged_timestamp'] ? moment(template['acknowledged_timestamp']).tz(timezone).format('DD.MM.YYYY HH:mmZ').split('+') : '-';
            return (<>{newTime[0]}</>);
        },style:{maxWidth: '150px'}
    },
    {
        field: 'acknowledge', header: t('Acknowledge'), filter: true, style: {textAlign: 'center', maxWidth: '170px'},
        filterField: 'acknowledged_by_user_ids', filterDropdownValues: users,
        body: (template: any)=>{
            return (<>
                {template['acknowledgedbyuser'] === null ?
                    <Button
                        style={{borderColor: props.colorTheme === 'dark' ? 'white' : 'black'}}
                        label={t('conditionMonitoring:acknowledged')}
                        icon="pi pi-check"
                        className="p-button-text p-button-raised"
                        onClick={() => {
                            CustomConfirmDialog({
                                message: t('conditionMonitoring:acknowledge_alert_message'),
                                header: t('conditionMonitoring:acknowledge_alert'),
                                translation: t,
                                onConfirm: () => {
                                    const sendData: any = {
                                        'project_id': props.projectId,
                                        'status_origin' : template['status_origin'],
                                        'characteristic_status_id': template['status_id']
                                    };
                                    acknowledgeAlerts(sendData).then(response =>{
                                        if (response['success']) {
                                            // setUpdate(update + 1);
                                            getAlertsValue();
                                            showMessageOnSuccess(t('conditionMonitoring:acknowledged'), t('conditionMonitoring:alarm_acknowledged'));
                                        }
                                    });
                                }
                            });
                        }}
                    />
                    : template['acknowledgedbyuser']}
            </>);
        },
    }
    ];

    const filters = {
        'plant_ids': {value: [localStorage.getItem('plantId')], matchMode: FilterMatchMode.IN},
        'component_ids': {value: rerenderTable === 0 ? [localStorage.getItem('componentId')] : 0, matchMode: FilterMatchMode.IN},
        'channel_ids': {value: null, matchMode: FilterMatchMode.IN},
        'name': {value: null, matchMode: FilterMatchMode.IN},
        'acknowledged_by_user_ids': {value: null, matchMode: FilterMatchMode.IN},
        'status_origin_type_ids': {value: props.alarmOrigin ? [props.alarmOrigin] : null, matchMode: FilterMatchMode.IN},
    };

    const getAlertsValue = ()=>{
        const index = location.hash.lastIndexOf('_');
        const urlId = location.hash.substr(index+1);
        let data: any = {};
        if (urlId === 'doppelfilter') {
            data = {
                'plant_ids': [localStorage.getItem('plantId')],
                'component_ids': [localStorage.getItem('componentId')],
                'status_origin_type_ids': [4],
                ...filteredVals

            };
        }
        else if (urlId === 'efficiency') {
            data = {
                'plant_ids': [localStorage.getItem('plantId')],
                'component_ids': [localStorage.getItem('componentId')],
                'status_origin_type_ids': [3]
            };
        }
        else {
            data = {
                'plant_ids': [localStorage.getItem('plantId')],
                'component_ids': [localStorage.getItem('componentId')],
                ...filteredVals

            };
        }

        getAlerts(props.projectId,  data, first, rows).then((res) => {
            setAlerts(res['finalArr']);
            setTotalRecord(res['totalRecords']);
            setShowTable(true);
            setDataLoaded(true);
        });
    };
    useEffect(() => {
        setShowTable(false);
        getAlertsValue();

        getPlantFilter(props.projectId).then(response => {
            const groupedPlants = getGroupedItems(response, 'id', 'name');
            setPlants(groupedPlants);
        });

        getSubPlantFilter(props.projectId, localStorage.getItem('plantId')).then(response => {
            const groupedSubPlants = getGroupedItems(response, 'id', 'name');
            setComponents(groupedSubPlants);
        });

        getAlarmOriginFilter(props.projectId).then(response => {
            const groupedOrigins = getGroupedItems(response, 'monitoring_app_type_id', 'monitoring_app_type_name');
            setOrigins(groupedOrigins);
        });

        getChannelFilter(props.projectId).then(response => {
            const groupedChannels = getGroupedItems(response, 'id', 'name');
            setChannels(groupedChannels);
        });

        getAcknowledgeFilter(props.projectId).then(response => {
            const groupedUsers = getGroupedItems(response, 'acknowledged_by_user_id', 'acknowledged_by_user');
            const finalUsers: any = [];
            groupedUsers.map((val:any)=>{
                const json = {
                    value: val['value'] !== null ? val['value'] : 'Unacknowldged',
                    name: val['name'] !== null ? val['name'] : 'Unacknowledged'
                };
                finalUsers.push(json);
            });
            finalUsers.push({
                value: 'Unacknowldged',
                name: 'Unacknowledged'
            });
            const newData = [
                finalUsers.find((item: any) => item['value'] === 'Unacknowldged'),
                ...finalUsers.filter((item: any) => item['value'] !== 'Unacknowldged'),
            ];
            setUsers(newData);
        });


    }, [update, rerenderTable]);
    return (
        <>
            <div className="grid">
                <div className='col-12'>
                    <div className="card">
                        {updateTable && <ProgressSpinner style={{display: 'block', top: '250px', margin: '-45px auto'}}/>}
                        {showTable ?
                            <div style={{opacity : updateTable ? '0.5' : '1'}}>
                                <CustomDataTable id={'inline-table'} columns={columns} editable={true} sortable={false} value={alerts} filters={filters} paginator={false}
                                    hideSearch={true} dataloaded={dataLoaded}
                                    filterOptionsField={'plant_ids'} filterOptionSelected={(values: any)=>{
                                        if (values.length > 0) {
                                            getSubPlantFilter(props.projectId, values).then(response => {
                                                const groupedSubPlants = getGroupedItems(response, 'id', 'name');
                                                setComponents(groupedSubPlants);
                                            });
                                        }
                                    }}
                                    timerangefield={true} onTimeRangeSelect={(vals: any)=>{
                                        setUpdateTable(true);
                                        if (vals[0] && vals[1] !== null) {
                                            const timestampFrom = vals[0].toLocaleDateString() + 'T' + vals[0].toLocaleTimeString().split(' ')[0];
                                            setPeriodFrom(timestampFrom);
                                            const timestampTo = vals[1].toLocaleDateString() + 'T' + vals[1].toLocaleTimeString().split(' ')[0];
                                            setPeriodTo(timestampTo);
                                            const data = {
                                                ...filteredVals,
                                                ['status_start']:{
                                                    'timestamp_from': timestampFrom,
                                                    'timestamp_to': timestampTo
                                                }
                                            };
                                            getAlerts(props.projectId, data,  first, rows).then(response => {
                                                setAlerts(response['finalArr']);
                                                setTotalRecord(response['totalRecords']);
                                                setUpdateTable(false);
                                                setDataLoaded(true);
                                            });
                                        }
                                    }}

                                    selectedFilters={(vals: any)=>{
                                        setUpdateTable(true);
                                        setFilteredVals(vals);
                                        if (periodFrom && periodTo !== undefined || periodFrom && periodTo !== '') {
                                            vals = {
                                                ...vals,
                                                ['status_start']:{
                                                    'timestamp_from': periodFrom,
                                                    'timestamp_to': periodTo
                                                }
                                            };
                                        }
                                        getAlerts(props.projectId, vals,  first, rows).then(response => {
                                            setAlerts(response['finalArr']);
                                            setTotalRecord(response['totalRecords']);
                                            setUpdateTable(false);
                                            setDataLoaded(true);
                                        });
                                    }}
                                />
                            </div> : <ProgressSpinner style={{display: 'block'}}/>}

                        <Paginator template={pagingTemplate} first={first} rows={rows} totalRecords={totalRecord} rowsPerPageOptions={[10, 20, 30, 50, 100]} onPageChange={onPageChange} />
                    </div>

                </div>

            </div>

        </>
    );
};

export default Alerts;
