/*
 * WeatherData.tsx
 * Author: lnappenfeld
 * Date: 26.10.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Card} from 'primereact/card';
import {getUserFromLocalStorage, getWeatherLocations, Nullable} from '../functions/Global';
import {getLastWeatherData} from '../functions/Data';
import {hideWaitAnimation, showWaitAnimation} from '../global/CustomWaitAnimation';
import './../../style/weatherdash.css';
import {HighchartsImageDialog} from './HighchartsImageDialog';
import moment from 'moment-timezone';
import {Dropdown} from 'primereact/dropdown';

type Props = {
    projectId: Nullable<string>
    locationId: Nullable<string>,
}

const mapConditionCodeToIcon = (conditionCode: number) => {
    switch (conditionCode) {
        case 16:
            return '☀';
        case 15:
            return '☔';
        case 2:
            return '🌤️';
        case 6:
            return '❓';
        case 12:
            return '🌧️';
        case 3:
            return '☁';
        case 8:
            return '🌤️';
        case 7:
            return '⛅';
        case 19:
            return '⛈';
        case 11:
            return '🌬️';
        case 23:
            return '🌨️';
        case 25:
            return '❄';
        default:
            return '❓'; // Default icon for unknown condition codes
    }
};

export const WeatherData = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [weatherData, setWeatherData] = useState<any>({});
    const [timestamps, setTimestamps] = useState<any>({});
    const [channelIds, setChannelIds] = useState<any>({});
    const [timezone, setTimezone] = useState('Europe/Berlin');

    const [showHighchartsDialog, setShowHighchartsDialog] = useState<boolean>(false);
    const [currentChannelId, setCurrentChannelId] = useState<Nullable<string>>(null);

    const [weatherLocations, setWeatherLocations] = useState<Nullable<any>>(null);
    const [selectedLocationId, setSelectedLocationId] = useState<Nullable<string>>(props.locationId);

    const dateFormat = 'DD.MM.YYYY HH:mm';

    const _setWeatherLocations = () => {
        if (props.projectId)
            getWeatherLocations(props.projectId).then(result => {
                const _weatherLocations: any = [];
                result.forEach((key: any) => {
                    _weatherLocations.push({
                        value: key.location_id,
                        label: key.location_name,
                    });
                });
                setWeatherLocations(_weatherLocations);
            });
    };

    useEffect(() => {
        setTimezone(getUserFromLocalStorage().timezone);
        _setWeatherLocations();

        if (props.projectId && props.locationId) {
            showWaitAnimation();
            getLastWeatherData(props.projectId, props.locationId).then(result => {
                setWeatherData(result.weatherData);
                setTimestamps(result.timestamps);
                setChannelIds(result.channelIds);
                hideWaitAnimation();
            });
        }
    }, []);

    const degreesToCardinal = (degrees: number) => {
        const cardinalDirections = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
        const degreeStep = 360 / cardinalDirections.length;
        const index = Math.round(degrees / degreeStep) % cardinalDirections.length;
        return cardinalDirections[index];
    };

    // On Click event handlder here
    const onConditionCodeClick = () => {
        if (weatherData.conditionCode) {
            setCurrentChannelId(channelIds.conditionCode);
            setShowHighchartsDialog(true);
        }
    };
    const onTemperatureClick = () => {
        if (weatherData.temperature) {
            setCurrentChannelId(channelIds.temperature);
            setShowHighchartsDialog(true);
        }
    };
    const onVisibilityClick = () => {
        if (weatherData.visibility) {
            setCurrentChannelId(channelIds.visibility);
            setShowHighchartsDialog(true);
        }
    };
    const onHumidityClick = () => {
        if (weatherData.humidity) {
            setCurrentChannelId(channelIds.humidity);
            setShowHighchartsDialog(true);
        }
    };
    const onAirPressureClick = () => {
        if (weatherData.airPressure) {
            setCurrentChannelId(channelIds.airPressure);
            setShowHighchartsDialog(true);
        }
    };
    const onWindSpeedClick = () => {
        if (weatherData.windSpeed) {
            setCurrentChannelId(channelIds.windSpeed);
            setShowHighchartsDialog(true);
        }
    };
    const onWindDirectionClick = () => {
        if (weatherData.windDirection) {
            setCurrentChannelId(channelIds.windDirection);
            setShowHighchartsDialog(true);
        }
    };
    const onDewPointClick = () => {
        if (weatherData.temperatureDewPoint) {
            setCurrentChannelId(channelIds.temperatureDewPoint);
            setShowHighchartsDialog(true);
        }
    };


    if (!weatherData) {   // if weather data is not passed or received then
        return <div>Loading Weather data...</div>; // return this
    }

    return (
        <>
            {weatherLocations && weatherLocations.length === 1 &&
                <div className={'card'}>
                    <h2 className={'-mb-1'}>{t('common:location')}: {weatherLocations[0].label}</h2>
                </div>
            }
            {weatherLocations && weatherLocations.length > 1 &&
                <div className={'card'}>
                    <Dropdown
                        style={{width: '100%'}}
                        options={weatherLocations}
                        value={selectedLocationId}
                        onChange={(e: any) => {
                            const locationId = e.value;
                            setSelectedLocationId(locationId);
                            if (props.projectId && locationId) {
                                showWaitAnimation();
                                getLastWeatherData(props.projectId, locationId).then(result => {
                                    setWeatherData(result.weatherData);
                                    setTimestamps(result.timestamps);
                                    setChannelIds(result.channelIds);
                                    hideWaitAnimation();
                                });
                            }
                        }}
                    />
                </div>
            }
            <div className={'card'}>
                <div className="weather-dashboard-grid grid">
                    <Card
                        onClick={onConditionCodeClick}
                        className={weatherData.conditionCode ? ' data-container p-button p-button-outlined' : ' data-container-disabled'}
                    >
                        <div className="icon">{mapConditionCodeToIcon(weatherData.conditionCode)}</div> {/* Add weather icons based on the condition code */}
                        <strong>
                            <h4>{t('typeEntities:channelTypes.conditionCode')}:</h4>
                            { weatherData.conditionCode && <p>{t('weatherCode:' + weatherData.conditionCode)}</p>}
                            { timestamps.conditionCode && <p className={'p-timestamp'}>{moment(timestamps.conditionCode).tz(timezone).format(dateFormat)}</p>}
                            {!weatherData.conditionCode && <div className={'mt-2'}>{t('measurementData:global.noWeatherData')}</div>}
                        </strong>
                    </Card>

                    <Card
                        onClick={onTemperatureClick}
                        className={weatherData.temperature ? 'data-container p-button p-button-outlined' : 'data-container-disabled'}
                    >
                        <div className="icon">&#x1F321;</div>
                        <h4>{t('typeEntities:channelTypes.temperature')}:</h4>
                        { weatherData.temperature && <p>{weatherData.temperature.toFixed(2)} °C</p>}
                        { timestamps.temperature && <p className={'p-timestamp'}>{moment(timestamps.temperature).tz(timezone).format(dateFormat)}</p>}
                        {!weatherData.temperature && <div className={'mt-2'}>{t('measurementData:global.noWeatherData')}</div>}
                    </Card>

                    <Card
                        onClick={onVisibilityClick}
                        className={weatherData.visibility ? 'data-container p-button p-button-outlined' : 'data-container-disabled'}
                    >
                        <div className="icon">👁</div>
                        <h4>{t('typeEntities:channelTypes.visibility')}:</h4>
                        { weatherData.visibility && <p>{(weatherData.visibility.toFixed(0))} m</p>}
                        { timestamps.visibility && <p className={'p-timestamp'}>{moment(timestamps.visibility).tz(timezone).format(dateFormat)}</p>}
                        {!weatherData.visibility && <div className={'mt-2'}>{t('measurementData:global.noWeatherData')}</div>}
                    </Card>
                </div>
                <div className="weather-dashboard-grid grid">
                    <Card
                        onClick={onHumidityClick}
                        className={weatherData.humidity ? 'data-container p-button p-button-outlined' : 'data-container-disabled'}
                    >
                        <div className="icon">&#x1F4A7;  </div>
                        <h4>{t('typeEntities:channelTypes.humidity')}:</h4>
                        { weatherData.humidity && <p>{weatherData.humidity} %</p>}
                        { timestamps.humidity && <p className={'p-timestamp'}>{moment(timestamps.humidity).tz(timezone).format(dateFormat)}</p>}
                        {!weatherData.humidity && <div className={'mt-2'}>{t('measurementData:global.noWeatherData')}</div>}
                    </Card>

                    <Card
                        onClick={onAirPressureClick}
                        className={weatherData.airPressure ? 'data-container p-button p-button-outlined' : 'data-container-disabled'}
                    >
                        <div className = "icon">⏲️</div>
                        <h4>{t('typeEntities:channelTypes.airPressure')}:</h4>
                        { weatherData.airPressure && <p>{weatherData.airPressure} hPa</p>}
                        { timestamps.airPressure && <p className={'p-timestamp'}>{moment(timestamps.airPressure).tz(timezone).format(dateFormat)}</p>}
                        {!weatherData.airPressure && <div className={'mt-2'}>{t('measurementData:global.noWeatherData')}</div>}
                    </Card>

                    <Card
                        onClick={onWindSpeedClick}
                        className={weatherData.windSpeed ? 'data-container p-button p-button-outlined' : 'data-container-disabled'}
                    >
                        <div className="icon"> &#x1F4a8;</div> {/* Add weather icons based on the condition code */}
                        <h4>{t('typeEntities:channelTypes.windSpeed')}:</h4>
                        { weatherData.windSpeed && <p>{weatherData.windSpeed} km/h</p>}
                        { timestamps.windSpeed && <p className={'p-timestamp'}>{moment(timestamps.windSpeed).tz(timezone).format(dateFormat)}</p>}
                        {!weatherData.windSpeed && <div className={'mt-2'}>{t('measurementData:global.noWeatherData')}</div>}
                    </Card>
                </div>
                <div className="weather-dashboard-grid grid">
                    <Card
                        onClick={onWindDirectionClick}
                        className={weatherData.windDirection ? 'data-container p-button p-button-outlined' : 'data-container-disabled'}
                    >
                        <div className="icon">&#129517;</div> {/* Add weather icons based on the condition code */}
                        <h4>{t('typeEntities:channelTypes.windDirection')}:</h4>
                        { weatherData.windDirection && <p>{degreesToCardinal(weatherData.windDirection)}</p>}
                        { timestamps.windDirection && <p className={'p-timestamp'}>{moment(timestamps.windDirection).tz(timezone).format(dateFormat)}</p>}
                        {!weatherData.windDirection && <div className={'mt-2'}>{t('measurementData:global.noWeatherData')}</div>}
                    </Card>

                    <Card
                        onClick={onDewPointClick}
                        className={weatherData.temperatureDewPoint ? 'data-container p-button p-button-outlined' : 'data-container-disabled'}
                    >
                        <div className="icon"> 🧊</div>
                        <h4>{t('typeEntities:channelTypes.temperatureDewPoint')}:</h4>
                        { weatherData.temperatureDewPoint && <p>{weatherData.temperatureDewPoint} °C</p>}
                        { timestamps.temperatureDewPoint && <p className={'p-timestamp'}>{moment(timestamps.temperatureDewPoint).tz(timezone).format(dateFormat)}</p>}
                        {!weatherData.temperatureDewPoint && <div className={'mt-2'}>{t('measurementData:global.noWeatherData')}</div>}
                    </Card>
                </div>

                {showHighchartsDialog && props.projectId && currentChannelId &&
                <HighchartsImageDialog
                    setShowDialog={setShowHighchartsDialog}
                    projectId={props.projectId}
                    channelId={currentChannelId}
                />}
            </div>
        </>
    );
};