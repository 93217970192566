/* eslint-disable camelcase */
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Input from '../global/Input';
import {useForm} from 'react-hook-form';
import {
    createMeasuringDevice, getMeasuringDeviceTypes,
    MeasuringDevice,
    updateMeasuringDevice,
} from '../functions/Metrology';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import TypesSelectList from './TypesSelectList';
import LocationSelectList from '../global/LocationSelectList';
import {Nullable} from '../functions/Global';
import {measuringDeviceObject} from './measuringDevice';
import MetrologySelectList from './MetrologySelectList';
import {checkProjectType, ipValidation, minValidation, ProjectType} from '../../functions/functionLibrary';


type Props = {
    projectId: string,
    measuringDevice: Nullable<MeasuringDevice>,
    onFinished: (success: boolean) => void
}

export const MeasuringDeviceEditForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);
    const parameter:any = props.measuringDevice?.parameter ? JSON.parse(props.measuringDevice.parameter) : {};
    const defaultValues = {
        measuring_device_id: props.measuringDevice ? props.measuringDevice.measuring_device_id : null,
        project_id: props.projectId,
        measuring_device_type_id: props.measuringDevice ? props.measuringDevice.measuring_device_type_id : null,
        name: props.measuringDevice?.measuring_device_name,
        description: props.measuringDevice?.description,
        is_visible: props.measuringDevice ? props.measuringDevice.is_visible : true,
        location_id: props.measuringDevice?.location_id,
        qrcode: props.measuringDevice?.qrcode,
        measuring_manager_id: props.measuringDevice?.measuring_manager_id,
        ip: props.measuringDevice?.ip,
        port: props.measuringDevice?.port,
        login: parameter?.login,
        password: parameter?.password,
        interval: parameter?.interval,
        protokoll: parameter?.protokoll
    };

    const {control, formState: {errors}, handleSubmit, watch, setValue, getValues} = useForm({defaultValues});

    watch('measuring_device_type_id');
    watch('measuring_device_id');

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    const saveDevice = (data: any) => {
        if (props.measuringDevice !== null) {
            updateMeasuringDevice(data).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), result.error);
                } else {
                    showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.measuringDeviceUpdated'));
                    props.onFinished(true);
                }
            });
        } else {
            delete data.measuring_device_id;
            createMeasuringDevice(data).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), result.error);
                } else {
                    showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.measuringDeviceCreated'));
                    props.onFinished(true);
                }

            });
        }
    };

    const makeInput = (objName:string,  obj:any,  edit:boolean, required:boolean) => {
        let options = {};
        if (obj.type === 'checkbox')
            options = { ... {
                value: getValues('is_visible'),
                onChange: (e:any) => {
                    setValue('is_visible', e.value);
                }
            }};
        let validationRules = {required:(required) ? t(obj.label) + ' ' + t('input:required') : false};
        if (objName === 'ip') {
            validationRules = {required:(required) ? t(obj.label) + ' ' + t('input:required') : false, ...ipValidation(t)};
        } else if (objName === 'port' || objName === 'interval') {
            validationRules = {required:(required) ? t(obj.label) + ' ' + t('input:required') : false, ...minValidation(t, 1)};
            options = { ...{
                maxFractionDigits: 0,
                useGrouping: objName === 'port' ? false : true,
                value: getValues(objName),
                onChange: (e:any) => {
                    setValue(objName, e.value);
                }
            }};

        }
        return (<Input
            name={objName}
            edit={edit}
            label={t(obj.label)}
            type={obj.type}
            validationControl={control}
            validationErrorMessage={getFormErrorMessage}
            validationRules={validationRules}
            {... options}
        />);
    };
    return (
        <form id='formMeasuringDevice' onSubmit={handleSubmit(saveDevice)}>
            <div className={'card'}>
                <div className={'grid w-auto'}>
                    <div className={'col-6'}>
                        {checkProjectType(ProjectType.ConditionMonitoring) &&
                        <MetrologySelectList
                            type={'measuring_manager'}
                            projectId={props.projectId}
                            disabled={getValues('measuring_device_id') !== null}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: getValues('measuring_device_type_id') === 204
                                ? false : t('metrologyManagement:attributes.measuringManager') + ' ' + t('input:required')
                            }}
                        />}
                        <TypesSelectList
                            projectId={props.projectId}
                            type={'measuring_device'}
                            disabled={defaultValues.measuring_device_type_id !== null}
                            onChange={(measuringDeviceType) => {
                                setValue('measuring_device_type_id', measuringDeviceType.id);
                                // Set default name for measuring device
                                setValue('name', measuringDeviceType.name);
                            }}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('metrologyManagement:attributes.measuringDeviceType') + ' ' + t('input:required')}}
                        />
                        <LocationSelectList
                            projectId={props.projectId}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: false}}
                            label={t('location')}
                        />
                        <Input
                            edit={true}
                            label={t('visible')}
                            name={'is_visible'}
                            type={'checkbox'}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: false}}
                        />
                    </div>
                    <div className={'col-6'}>
                        { makeInput('name',
                            measuringDeviceObject.name,
                            true, true) }
                        { makeInput('description',
                            measuringDeviceObject.description,
                            true, false) }
                        { makeInput('qrcode',
                            measuringDeviceObject.qrcode,
                            true, false) }
                    </div>
                </div>
            </div>
            {getValues('measuring_device_type_id') === 204 && // PI-Server
            <div className={'card'}>
                <h4>{t('metrologyManagement:attributes.measuringDeviceSpecificParameter')}</h4>
                <div className={'grid w-auto'}>
                    <div className={'col-6'}>
                        {makeInput('protokoll',
                            measuringDeviceObject.protokoll,
                            true, true)}
                        {makeInput('ip',
                            measuringDeviceObject.ip,
                            true, true)}
                        {makeInput('port',
                            measuringDeviceObject.port,
                            true, true)}
                    </div>
                    <div className={'col-6'}>
                        {makeInput('login',
                            measuringDeviceObject.login,
                            true, true)}
                        {makeInput('password',
                            measuringDeviceObject.password,
                            true, true)}
                        {makeInput('interval',
                            measuringDeviceObject.interval,
                            true, true)}
                    </div>
                </div>
            </div>}
            {getValues('measuring_device_type_id') === 200 && // SiMON2
                <div className={'card'}>
                    <h4>{t('metrologyManagement:attributes.measuringDeviceSpecificParameter')}</h4>
                    <div className={'grid w-auto'}>
                        <div className={'col-6'}>
                            {makeInput('ip',
                                measuringDeviceObject.ip,
                                true, true)}
                        </div>
                        <div className={'col-6'}>
                            {makeInput('port',
                                measuringDeviceObject.port,
                                true, true)}
                        </div>
                    </div>
                </div>}
        </form>
    );
};

