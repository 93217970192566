import {FunctionComponent, useContext, useEffect} from 'react';
import MapContext from '../map/MapContext';
import settings from '../../../config/settings';
import geoSettings from '../../../config/geoSettings';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import SLDParser from 'geostyler-sld-parser';
import {transformExtent} from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import {GeoJSON} from 'ol/format';
import {bbox} from 'ol/loadingstrategy';
import VectorLayer from 'ol/layer/Vector';
import {
    addLayerToCounterArray,
    setConfigData,
    setLayerArray,
    setStyleArray
} from '../../../redux/GeomonitoringSlice';
import {
    callFeatureTypes,
    getAbstractValues,
    getWmsLayer,
    parseStyle,
    setPermaLayerVisible
} from '../../../functions/functionsOpenlayer';

type functionProps = {
    // eslint-disable-next-line react/no-unused-prop-types
    projectId: number
};

interface LayerArray {
    [key: string]: any
}

const ConfigLayers: FunctionComponent<functionProps> = (projectId) => {
    // @ts-ignore
    const {map, setMap} = useContext(MapContext);
    //  const [collectingArray, setCollectingArray] =useState([]);
    const workBench = useAppSelector((state: any) => state.configLayer.layerData.geoserverWorkspace);
    const dispatch = useAppDispatch();
    const sldParser = new SLDParser();
    const requestOptions = {
        method: 'GET',
        headers: geoSettings.geoserverAuthentification,
    };
    const collectingObject: LayerArray = {};
    const separatorRasterAttributes: LayerArray = {};
    const layerGraphTitle: LayerArray = {};
    const editable: LayerArray = {};
    const externLegend: LayerArray = {};
    const externLegendPic: LayerArray = {};
    const extentArray: LayerArray = {};
    const layerNameArray: LayerArray = {};
    const layerAttributesArray: LayerArray = {};
    const editAttributesArray: LayerArray = {};
    const layerfeatureInfosArray: LayerArray = {};
    const formatAttributesArray: LayerArray = {};
    const layerNodesArray: LayerArray = {};
    const permaLinkArray = useAppSelector((state: any) => state.configLayer.layerData.permaLinkLayer);
    const isPermaLink = useAppSelector((state: any) => state.configLayer.layerData.isPermaLink);
    const baseWmsWorkspace=useAppSelector((state:any)=>state.configLayer.layerData.baseLayerWorkspace);

    const getUrlBasedByLayer = (layerKind: any) => {
        let url = '';
        switch (layerKind) {
            case 'rasterData':
                url = geoSettings.geoserverRestPath + 'workspaces/' + workBench + '/wmslayers.json';
                break;
            case 'coverageData':
                url = geoSettings.geoserverRestPath + 'workspaces/' + workBench + '/coverages.json';
                break;
            default:
                url = geoSettings.geoserverRestPath + 'workspaces/' + workBench + '/featuretypes.json';
                break;
        }
        return url;
    };

    const callLayersByWorkspace = (layerKind: any) => {
        const url = getUrlBasedByLayer(layerKind);


        return new Promise((resolve, reject) => {
            fetch(url, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        resolve({
                            success: true,
                            data: result
                        });
                    },
                    (error) => {
                        reject({
                            success: false,
                            info: 'GET failed: ' + error
                        });
                    });
        });
    };

    const getFeatureData = (layerKind: any, data: { featureTypes: { featureType: any; }; wmsLayers: { wmsLayer: any; }; coverages: { coverage: any; }; }) => {
        let result;
        switch (layerKind) {
            case 'vectorData':
                result = data.featureTypes.featureType;
                break;
            case 'rasterData':
                result = data.wmsLayers.wmsLayer;
                break;
            case 'coverageData':
                result = data.coverages.coverage;
                break;
            default:
                result = data;
                break;
        }
        return result;
    };

    const getLayerStyleName = (layerName: string) => {
        const url = geoSettings.geoserverRestPath + 'layers/' + layerName + '/styles/';

        return new Promise((resolve, reject) => {
            fetch(url, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        resolve({
                            success: true,
                            data: result
                        });
                    },
                    (error) => {
                        reject({
                            success: false,
                            info: 'GET failed: ' + error
                        });
                    });
        });
    };

    const getLayerStyle = (stylename: string) => {

        const url = geoSettings.geoserverRestPath + 'workspaces/' + workBench + '/styles/' + stylename + '.sld';

        return new Promise((resolve, reject) => {
            fetch(url, requestOptions)
                .then(res => res.text())
                .then(
                    (result) => {
                        resolve({
                            success: true,
                            data: result
                        });
                    },
                    (error) => {
                        reject({
                            success: false,
                            info: 'GET failed: ' + error
                        });
                    });
        });
    };

    const convertHexToRGBA = (hex: string, opacity: string) => {
        let color = hex;
        if (hex.includes('#')) {
            let tempHex = hex.replace('#', '');
            if (tempHex.length === 3) {
                tempHex += tempHex;
            }
            const r = parseInt(tempHex.substring(0, 2), 16);
            const g = parseInt(tempHex.substring(2, 4), 16);
            const b = parseInt(tempHex.substring(4, 6), 16);

            color = 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')';
        }
        return color;
    };

    const perfectStyle = (symbolizer: any) => {
        if (symbolizer.kind === 'Fill') {
            if (typeof symbolizer.outlineColor === 'undefined') {
                symbolizer.outlineColor = 'rgba(0, 0, 0, 0)';
            }
            if (typeof symbolizer.color === 'undefined') {
                symbolizer.color = 'rgba(0, 0, 0, 0)';
            }

        }
        if (symbolizer.kind === 'Mark'&&symbolizer.wellKnownName=== 'ttf://Wingdings#0x6C') {
            symbolizer.radius = symbolizer.radius*3.5;
        }
   
        if (symbolizer.fillOpacity) {
            symbolizer.color = convertHexToRGBA(symbolizer.color, symbolizer.fillOpacity);
        }
        if (symbolizer.outlineOpacity) {
            symbolizer.outlineColor = convertHexToRGBA(symbolizer.outlineColor, symbolizer.outlineOpacity);
        }
        return symbolizer;
    };

    const modifyStyle = (symbolizer: any) => {
        if (!isNaN(symbolizer.size)) {
            symbolizer.size = symbolizer.size * 1.05;
            symbolizer.fontWeight = 'bold"';
        }
        if (!(isNaN(symbolizer.outlineWidth)) && symbolizer.outlineWidth > 0) {
            symbolizer.outlineWidth = symbolizer.outlineWidth < 1 ? 1 : symbolizer.outlineWidth;
            symbolizer.outlineWidth = symbolizer.outlineWidth * 4;
        }
        if (symbolizer.radius > 0) {
            symbolizer.radius = symbolizer.radius * 1.05;
        }
        if (!isNaN(symbolizer.haloWidth)) {
            symbolizer.haloWidth = symbolizer.haloWidth * 1.5;
        }
        if (!isNaN(symbolizer.radius)) {
            symbolizer.radius = symbolizer.radius * 1.5;
        }
        if (symbolizer.outlineColor === 'rgba(0, 0, 0, 0)') {
            symbolizer.outlineColor = 'rgba(0, 0, 0, 1)';
        }
        if (!isNaN(symbolizer.width)) {
            symbolizer.width = symbolizer.width * 4;
        }
        return symbolizer;
    };

    const modifyParsedStyle = async (layerStylePreArray: any, styleToParse: any, layerId: string, styleName: string) => {
        let counter = 0;
        layerId = layerId.toLowerCase();
        const styleRules = styleToParse.output.rules;
        for (let i = 0; i < styleRules.length; i++) {
            counter++;
            for (let symbolizer of styleRules[i].symbolizers) {
                symbolizer = await modifyStyle(symbolizer);

                try {
                    layerStylePreArray[layerId][styleName].highlight = styleToParse.output;

                } catch (e) {
                    console.log(e);
                }
            }
            if (counter === styleRules.length) {
                collectingObject[layerId] = collectingObject[layerId] ? collectingObject[layerId] : [];
                collectingObject[layerId][styleName] = collectingObject[layerId][styleName] ? collectingObject[layerId][styleName] : [];
                collectingObject[layerId][styleName] = layerStylePreArray[layerId][styleName];

            }
        }
        // console.log(collectingObject)
    };

    const parseStyleToOl = async (styleToParse: any, layerId: string, styleName: string) => {
        // console.log('parseStyle',layerId, styleToParse )
        let counter = 0;

        layerId = layerId.toLowerCase();
        const styleNameLower = styleName.toLowerCase();

        const styleRules = styleToParse.output.rules;
        const layerArrayForStyle: any = [];
        for (let i = 0; i < styleRules.length; i++) {
            counter++;
            if (styleRules[i].filter && !styleRules[i].filter[2]) {
                styleRules[i].filter[2] = styleRules[i].name;
            }
            styleRules[i].symbolizers.map((symbolizer: any) => {
                return perfectStyle(symbolizer);
            });

            try {
                if (counter === styleRules.length) {

                    if (!layerArrayForStyle[layerId]) {
                        layerArrayForStyle[layerId] = [];
                    }

                    layerArrayForStyle[layerId][styleNameLower] = {
                        name: styleToParse.output.name,
                        id: styleName,
                        default: styleToParse.output
                    };
                    await modifyParsedStyle(layerArrayForStyle, JSON.parse(JSON.stringify(styleToParse)), layerId, styleNameLower);
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e);
            }
        }
    };

    const parseSldStyle = (styleToParse: any, layerId: any, styleName: any) => {

        const cdataCleanedStyle = styleToParse.replace(/<!\[CDATA\[(.*?)\]\]>/g, '$1');
        try {
            sldParser
                .readStyle(cdataCleanedStyle)
                .then((sldObject: any) => parseStyleToOl(sldObject, layerId, styleName))
                .catch((error: any) => console.log(error));
        } catch (e) {
            console.log(e);
        }
    };

    const getAllLayerStyles = (layerName: any) => {
        getLayerStyleName(layerName).then(function (res: any) {
            if (res.success === true) {
                const stylesArray = res.data.styles.style;
                if (typeof stylesArray !== 'undefined') {
                    for (const style of stylesArray) {
                        if (style.name !== 'default') {
                            getLayerStyle(style.name).then(function (styleRes: any) {
                                if (styleRes.success === true && (styleRes.data).trim() !== '') {

                                    parseSldStyle(styleRes.data, layerName, style.name);
                                }
                            });
                        }
                    }
                }
            }

        });
    };

    const splitLayerName = (layerName: string) => {
        const splitElems = layerName.split('_');
        const treeIndex = parseInt(splitElems[1]);
        const layerIndex = parseInt(splitElems[2]);

        if (Number.isInteger(treeIndex) && Number.isInteger(layerIndex)) {

            return {
                treeIndex: treeIndex,
                treeName: splitElems[0],
                treeNameLower: splitElems[0].toLowerCase(),
                layerIndex: layerIndex,
                name: splitElems[3],
                generatedIndex: treeIndex * 10000000 + layerIndex * 1000,
                generatedLayerIndex: layerIndex * 1000
            };
        } else {
            return false;
        }
    };



    const setLegendUrl = (layerAttributes: { allAvailableRemoteStyles: any; }, layername: string) => {
        if (layerAttributes&&layerAttributes.allAvailableRemoteStyles) {
            const attr = layerAttributes.allAvailableRemoteStyles;
            if (attr.style && attr.style.length > 0) {
                if (attr.style[0].legend) {
                    const legend = attr.style[0].legend;
                    if (legend.onlineResource) {
                        if (!externLegend[layername]) {
                            externLegend[layername] = {};
                        }
                        externLegend[layername].url = legend.onlineResource;
                    }
                }
            }
        }
        const keys = Object.keys(externLegendPic);
        keys.forEach(function (layer: string | number) {

            if (externLegendPic[layer]) {
                externLegend[layer].url = externLegendPic[layer];
                //  console.log('keylegend', externLegend, layer)
            }
        });
    };

    const toCamelCase = (string: string) => {
        let stringCamelCase = string.charAt(0).toLowerCase() + string.substring(1);
        stringCamelCase = string.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, function (match) {
            return match.charAt(match.length - 1).toUpperCase();
        });

        if (string === string.toUpperCase()) {
            return string;
        } else {
            return stringCamelCase;
        }
    };


    const getLayerConfiguration = async (layername: string, altName: string, layerKind: any) => {
        return new Promise(async function (resolve) {
            const layerAttributes: any = await callFeatureTypes(layername, layerKind, workBench, baseWmsWorkspace);
            const layerId = layername.toLowerCase();
            const extent = layerAttributes.latLonBoundingBox;

            if (extent) {
                extentArray[layerId] = transformExtent([extent.minx, extent.miny, extent.maxx, extent.maxy], 'EPSG:4326', 'EPSG:3857');
            }

            if (layerAttributes.data.enabled === true) {
                const abstract = layerAttributes.data.abstract;
                const results = await getAbstractValues(abstract, altName, layerKind);
                resolve({results:results, layerAttributes:layerAttributes, id:layerId});

            } else {
                resolve(false);
            }
        });
    };


    const setGeneralLayerConfigs = async (layerConfig: { seperator: any; graphTitle: any; editable: any; legend: any; externLegendPic: any; } | null, layer: { name: any; }, layerKind: string) => {
        const layerId = layer.name.toLowerCase();
        if (layerConfig&&layerConfig !== null) {
            if (layerKind !== 'baseData') {
                separatorRasterAttributes[layerId] = layerConfig.seperator;
                layerGraphTitle[layerId] = layerConfig.graphTitle;
                editable[layerId] = layerConfig.editable;
            }

            if (!externLegend[layerId]) {
                externLegend[layerId] = {};
            }
            if (!externLegendPic[layerId]) {
                externLegendPic[layerId] = {};
            }


            externLegend[layerId].extern = layerConfig.legend;
            externLegendPic[layerId] = layerConfig.externLegendPic;
        }

        return true;
    };

    const iterateOverStylesAndCreateLayer = async (keys: any, layerConfig: { layerKind: string; }, layerParts: any, layer: { name: any; }, titel: any, elemLength: number, layerVisibility: any, layerStyles: { [x: string]: { default: any; }; }) => {
        let counter = 1;
        for (const style of keys) {
            let lParts = layerParts;
            //   counter * 10;
            if (!keys && layerConfig.layerKind === 'vectorData') {
                console.log('Fehler beim Laden, Style nicht gesetzt bei Layer' + layer.name);
            } else {
                if (elemLength > 1) {
                    // layerPartsCopy.layerIndex = layerPartsCopy.layerIndex + layerPartsCopy.treeIndex + counter;
                    lParts = {...layerParts};


                }

                lParts.generatedIndex += counter;
                await createSimpleLayerWithConfigs(layer, style, layerConfig, lParts, titel, layerVisibility, layerStyles);
            }
            counter++;
        }
    };


    const getConfiguration = async (layer: { name: any; }, layerKind: string) => {
        const layerParts = splitLayerName(layer.name);

        if (!layerParts) {
            return false;
        }
        setNodeName(layerParts);
        const values: any = await getLayerConfiguration(layer.name, layerParts.name, layerKind);
        const layerConfig=values.results;
        if (!layerConfig||layerConfig.functionLayer) {
            return false;
        }

        await setGeneralLayerConfigs(layerConfig, layer, layerKind);
        setLegendUrl(values.layerAttributes, values.id);

        return {parts: layerParts, config: layerConfig};

    };


    const setNodeName=(layerparts:any)=>{
        const index=layerparts.treeNameLower;
        if (!layerNodesArray[index]) {
            layerNodesArray[index]={string:layerparts.treeName, base64:false};
        }
        if(layerNodesArray[index].base64){
            return;
        }
        layerNodesArray[index].string=layerparts.treeName;
    };
    
    const timeoutDispatch = (trigger: any) => {
        //   console.log('timeoutDispatch',trigger)
        if (trigger) {
            setTimeout(function () {
                // console.log(config, trigger)
                dispatchConfigData();
            }, 4000);
        }
    };

    const addLayerWithConfiguration = async (layer: { name: string; }, layerKind: any, trigger: boolean) => {

        const layerId = layer.name.toLowerCase();
        const layerConfig = await getConfiguration(layer, layerKind);
        if (layerConfig === false) {
            timeoutDispatch(trigger);
            return true;
        }
        const config = layerConfig.config;
        const titel = config.layerName;
        const layerVisibility = config.layerVisible;
        let keys: any = [];
        let elemLength = 1;
        if (config !== 'false' && config !== false) {
            const layerStyles = collectingObject[layerId];
            if (!layerStyles) {
                console.log(layerId + ' hat keine vorhandenen Styles. Bitte überprüfen!');
                await createSimpleLayerWithConfigs(layer, null, config, layerConfig.parts, titel, layerVisibility, layerStyles);
            }
            if (layerStyles) {
                keys = Object.keys(layerStyles);
                elemLength = keys.length;
            }
            await iterateOverStylesAndCreateLayer(keys, config, layerConfig.parts, layer, titel, elemLength, layerVisibility, layerStyles);
        }
        timeoutDispatch(trigger);
    };

    const dispatchConfigData = () => {
        const newStyleObject = Object.assign([], collectingObject);
        const dispatchData = {
            layerCounter: projectId.projectId,
            styleArray: newStyleObject,
            layerNames: layerNameArray,
            layerAttributes: layerAttributesArray,
            attributesFormat: formatAttributesArray,
            editAttributes: editAttributesArray,
            layerFeatures: layerfeatureInfosArray,
            externLegend: externLegend,
            externLegendPic: externLegendPic,
            treeName:layerNodesArray
        };
        //  console.log('dispatchLayer',dispatchData)
        dispatch(setConfigData(dispatchData));
    };

    const dispatchLayer = (layerDesignation: string, mainLayerId: string, layerId: string, styleId: any, layerKind: any, simpleLayer: any, parentLayer: any, layerVisibility: any, layerParts: any, layerConfig: any, title: string) => {
        //   console.log(layerDesignation, layerId, styleId, title)
        /* console.log({
            name: layerDesignation,
            mainLayerId: mainLayerId,
            id: layerId,
            styleId: styleId,
            type: layerKind,
            simpleLayer: simpleLayer,
            parentLayer: parentLayer,
            visible: layerVisibility,
            layerParts: layerParts,
            layerConfig: layerConfig,
            styles: collectingObject[mainLayerId.toLowerCase()],
            title: title
        })*/
        dispatch(setLayerArray({
            name: layerDesignation,
            mainLayerId: mainLayerId,
            id: layerId,
            styleId: styleId,
            type: layerKind,
            simpleLayer: simpleLayer,
            parentLayer: parentLayer,
            visible: layerVisibility,
            layerParts: layerParts,
            layerConfig: layerConfig,
            styles: collectingObject[mainLayerId.toLowerCase()],
            title: title
        }));
    };

    const getLayerSource = (layerName: string) => {
        return new VectorSource({
            format: new GeoJSON(),
            url: function () {
                return (
                    'https://gis.dmt.de/geoserver/' + workBench + '/ows?service=WFS&version=2.0.0&request=GetFeature&typeName=' + workBench + ':' + layerName + '&outputFormat=application/json&srsname=EPSG:4326&maxFeatures=15&bbox=' +
                    map
                        .getView()
                        .calculateExtent(map.getSize())
                        .join(',') + ', EPSG:3857'
                );
            },
            strategy: bbox
        });
    };

    const createVectorLayer = (layerSource: any, layerVisible: any, layerParts: any, layerDesignation: any, layerId: any, styleId: any, layer: { parentLayer: any, name:any }) => {
        const vectorLayer = new VectorLayer({
            source: layerSource,
            visible: layerVisible,
            zIndex: layerParts.generatedLayerIndex
        });
        // console.log(layerParts.treeIndex, layerParts.layerIndex,layerParts.treeIndex*10000+layerParts.layerIndex )
        vectorLayer.set('name', layerDesignation);
        vectorLayer.set('originalname', layer.name);
        vectorLayer.set('id', layerId);
        vectorLayer.set('styleId', styleId);
        vectorLayer.set('type', 'vector');
        vectorLayer.set('parentLayer', layer.parentLayer ? layer.parentLayer : null);

        return vectorLayer;
    };

    const addSingleWFSLayer = async (layer: any, layerParts: any, layerVisibility: any, styleToParse: any, styleId: any, layerConfig: any) => {

        const layerDesignation = ((styleId !== null) && (typeof styleId !== 'undefined')) ? styleId : layer.name;
        const layerId = layerDesignation.toLowerCase();
        // console.log(layer, styleId,layerId)
        const layerVisible = setPermaLayerVisible(layerVisibility, isPermaLink, permaLinkArray, layerId);
        const layerSource = getLayerSource(layer.name);
        const vectorLayer = createVectorLayer(layerSource, layerVisible, layerParts, layerDesignation, layerId, styleId, layer);

        map.addLayer(vectorLayer);
        setMap(map);
        dispatchLayer(layerDesignation, layer.name, layerId, styleId, 'vector', true, layer.parentLayer ? layer.parentLayer : null, layerVisible, layerParts, layerConfig, layerConfig.layerName);

        if (typeof layerParts.name !== 'undefined') {
            parseStyle(styleToParse, vectorLayer);
        }
        return vectorLayer;
    };


    // }
    const createSimpleLayerWithConfigs = async (layer: { name: any; }, keys: any | undefined, layerConfig: any, layerParts: any, titel: any, layerVisibility: any, layerStyles: { [x: string]: { default: any; }; }) => {
        let wmsLayer;
        const layerDesignation = layer.name;
        const layerId = layerDesignation.toLowerCase();
        let styleId = layer.name;// Wenn kein anderer Style da
        // console.log(layer, styleId)

        const styleId4Raster = layerStyles ? styleId : null;
        // console.log('createSimple',layer.name, layerStyles, styleId4Raster,styleId)
        if (typeof collectingObject[layerId] !== 'undefined') {
            if (typeof collectingObject[layerId][keys] !== 'undefined') {
                styleId = collectingObject[layerId][keys].id;
            }
        }

        let layerVisible = layerVisibility;

        if (layerId.trim() === 'radarinterferometrie_600_3301_vsenk_20200105_20200925') {
            layerVisible = true;
        }
        // console.log(layerId, layerVisible)
        switch (layerConfig.layerKind) {
            case 'vectorData':
                await addSingleWFSLayer(layer, layerParts, layerVisible, layerStyles[keys].default, styleId, layerConfig);
                break;
            case 'coverageData':
                layerVisible = setPermaLayerVisible(layerVisible, isPermaLink, permaLinkArray, layerId.toLowerCase());
                wmsLayer = await getWmsLayer(layerDesignation, workBench, layerVisible, 'coverage', false, layerId, styleId, styleId4Raster, layerParts.generatedLayerIndex, layerConfig.opacity);
                map.addLayer(wmsLayer);
                dispatchLayer(layerDesignation, layer.name, wmsLayer.get('id'), styleId, 'coverage', true, null, layerVisible, layerParts, layerConfig, layerConfig.layerName);
                break;
            case 'rasterData':
                layerVisible = setPermaLayerVisible(layerVisible, isPermaLink, permaLinkArray, layerId.toLowerCase());
                wmsLayer = await getWmsLayer(layerDesignation, workBench, layerVisible, 'raster', false, layerId, styleId, styleId4Raster, layerParts.generatedLayerIndex, layerConfig.opacity);
                map.addLayer(wmsLayer);
                dispatchLayer(layerDesignation, layer.name, wmsLayer.get('id'), styleId, 'coverage', true, null, layerVisible, layerParts, layerConfig, layerConfig.layerName);
                break;
        }
        // console.log(layerParts)

        setArrayValues(styleId, layer, titel, layerConfig, layerParts, layerId);
        return true;
    };


    const setArrayValues=(styleId: null, layer: { name: any; }, titel: any, layerConfig:any, layerParts: { treeNameLower: string | number; }, layerId: string | number)=>{

        const layerDesignation2 = ((styleId !== null) && (typeof styleId !== 'undefined')) ? styleId : layer.name;

        layerNameArray[layerDesignation2.toLowerCase()] = titel;
        layerAttributesArray[layerId] = layerConfig.layerAttributes;
        formatAttributesArray[layerId] = layerConfig.transformAttributes;
        editAttributesArray[layerId] = layerConfig.editAttributes;
        layerfeatureInfosArray[layerId] = layerConfig.layerInfo;

        if (layerConfig.treeName!==''&&layerNodesArray[layerParts.treeNameLower]) {
            layerNodesArray[layerParts.treeNameLower] = layerConfig.treeName;
        }
    };
    const getLayerStyles = (layerArray: string | any[], layerKind: any) => {
        let counter = 0;
        let trigger = false;
        if (typeof layerArray === 'undefined') {
            return true;
        }

        for (const i in Object.keys(layerArray)) {
            const layer = layerArray[i];
            // console.log(layer)

            if (layer && Object.keys(layer).length > 0) {
                getAllLayerStyles(layer.name);

                setTimeout(async function () {
                    counter++;
                    if (counter === Object.keys(layerArray).length) {
                        trigger = true;
                    }
                    await addLayerWithConfiguration(layer, layerKind, trigger);
                }, 3000);
            }
        }

        if (Object.keys(layerArray).length === 0) {
            dispatch(addLayerToCounterArray(projectId.projectId));
            // console.log('add configlayers')
            dispatch(setStyleArray([]));
        }
        return true;
    };

    const callWFSLayersForMap = () => {
        const layerKindArray = ['rasterData', 'vectorData', 'coverageData'];
        layerKindArray.forEach(async kind => {
            const layersData: any = await callLayersByWorkspace(kind);
            const featureArray = getFeatureData(kind, layersData.data);
            getLayerStyles(featureArray, kind);
            // addLayersWithConfiguration(featureArray, kind);

        });
    };

    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;

        if (workBench !== null) {
            callWFSLayersForMap();
        } else {
            // console.log('add configlayers')
            dispatch(addLayerToCounterArray(projectId.projectId));
            dispatch(setStyleArray([]));
        }


    }, [map]);
    return null;

};
export default ConfigLayers;