/*
 * PlotFunctions.tsx
 * Author: fwunderlich
 * Date: 22.12.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';
import {getChannelsAsTree} from '../functions/Metrology';

export async function getChannel(projectId: string, channelId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getChannel?project_id=' + projectId + '&channel_id=' + channelId).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getCMDataset(projectId: string, datasetIds: string[], measuringDataTable: string) {
    let retValue: any = [];
    const endpointStr = '/getCMDataSet?project_id=' + projectId + '&dataset_ids=' + datasetIds.join(',') +
        '&measuring_data_table=' + measuringDataTable;
    await keycloakfetch.get(settings.apiUrl + endpointStr).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getCMSpectralData(projectId: string, channelId: string, timestampFrom: string, timestampTo: string, max_col: number, max_row: number, merge_result: number, value_type: number, y_min: number | null, y_max: number | null) {
    let retValue: any = [];
    const endpointStr = '/getCMSpectralData?project_id=' + projectId + '&channel_id=' + channelId +
        '&timestamp_from=' + timestampFrom + '&timestamp_to=' + timestampTo + '&max_col=' + max_col + '&max_row=' + max_row + '&merge_result=' + merge_result + '&value_type=' + value_type + '&y_min=' + y_min + '&y_max=' + y_max;
    await keycloakfetch.get(settings.apiUrl + endpointStr).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getMonitoringTasks(projectId: string, channelIds: string[]) {
    let retValue: any = [];
    const endpointStr = '/getMonitoringTasks?project_id=' + projectId + '&channel_ids=' + channelIds.join(',');
    await keycloakfetch.get(settings.apiUrl + endpointStr).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getMonitoringTaskLimits(projectId: string, monitoringTaskLimits: string[]) {
    let retValue: any = [];
    const endpointStr = '/getMonitoringTaskLimits?project_id=' + projectId + '&monitoring_task_ids=' + monitoringTaskLimits.join(',');
    await keycloakfetch.get(settings.apiUrl + endpointStr).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createMonitoringTaskLimits(projectId: string, monitoringTaskLimits: any[]) {
    let retValue: any = [];
    const endpointStr = '/createMonitoringTaskLimits';
    await keycloakfetch.post(settings.apiUrl + endpointStr, {
        project_id: projectId,
        data: monitoringTaskLimits
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function updateMonitoringTaskLimits(projectId: string, monitoringTaskLimits: any[]) {
    let retValue: any = [];
    const endpointStr = '/updateMonitoringTaskLimits';
    await keycloakfetch.put(settings.apiUrl + endpointStr, {
        project_id: projectId,
        data: monitoringTaskLimits
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function deleteMonitoringTaskLimits(projectId: string, monitoringTaskLimitIds: string[]) {
    let retValue: any = [];
    const endpointStr = '/deleteMonitoringTaskLimits';
    await keycloakfetch.delete(settings.apiUrl + endpointStr, {
        project_id: projectId,
        monitoring_task_limit_ids: monitoringTaskLimitIds
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getData(projectId: string, channelId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getData?project_id=' + projectId + '&channel_id=' + channelId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}


export async function getPlotTemplates(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getPlotTemplates?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createPlotTemplate(data: any) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/createPlotTemplate', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function updatePlotTemplate(data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/updatePlotTemplate', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}


export async function deletePlotTemplate(projectId: string, plotTemplateId: string) {
    let retValue: any = [];
    await keycloakfetch.delete(settings.apiUrl + '/deletePlotTemplate', {
        'project_id': projectId,
        'plottemplate_id': plotTemplateId
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export function getCoordinates(startLng: number, startLat: number, bearing: number, distance: number) {
    const bearingRad = (bearing * Math.PI) / 180;
    const EARTH_RADIUS = 6378.1;

    const initialPosition = {
        'latitude': startLat,
        'longitude': startLng
    };

    const initLat = (initialPosition.latitude * Math.PI) / 180;
    const initLon = (initialPosition.longitude * Math.PI) / 180;

    const finalLat = (180 / Math.PI) * (Math.asin(Math.sin(initLat) * Math.cos(distance / EARTH_RADIUS) + Math.cos(initLat) * Math.sin(distance / EARTH_RADIUS) * Math.cos(bearingRad)));

    const finalLon = (180 / Math.PI) * (initLon + Math.atan2(Math.sin(bearingRad) * Math.sin(distance / EARTH_RADIUS) * Math.cos(initLat), Math.cos(distance / EARTH_RADIUS) - Math.sin(initLat) * Math.sin(finalLat)));

    return {lng: finalLon, lat: finalLat};
}

export const channelsForMeasurement = (projectId: string, setIsChannelHash: (arg0: any) => void, setList?: (arg0: any) => void) => {
    getChannelsAsTree(projectId, 'plot').then(result => {
        setIsChannelHash(setSelectable(result, ''));

        if (setList) {
            setList(result);
        }
        // console.log('channels', result);
    });
};

const setSelectable = (parent: any, parentId: string) => {
    let result: any = {};
    if (parent) {
        for (let i = 0; i < parent.length; i++) {
            parent[i].selectable = true;
            parent[i].isChannel = true;
            parent[i].name = parent[i].label;

            if (parent[i].type) {
                parent[i].label += ' (' + parent[i].type + ')';
            }

            if (parent[i].children) {
                parent[i].isChannel = false;
                parent[i].parentId = parentId;
                result = {...result, ...setSelectable(parent[i].children, parent[i].key)};
            } else {
                parent[i].parentId = parentId;
            }
            result[parent[i].key] = {
                name: parent[i].name,
                isChannel: parent[i].isChannel,
                parentId: parent[i].parentId,
                type: parent[i].type,
                'graph_types': parent[i].graph_types
            };
        }
    }

    return result;
};

export const getPlotOptions = (values: any, isChannelHash: any) => {
    for (let i = 0; i < Object.keys(values).length; i++) {
        const key = Object.keys(values)[i];

        values[key].name = isChannelHash[key].name;
        values[key].isChannel = isChannelHash[key].isChannel;
        values[key].parentId = isChannelHash[key].parentId;
        values[key].type = isChannelHash[key].type;
        values[key].graph_types = isChannelHash[key].graph_types;
    }

    return treeSelectToPlotOptions(values);
};

const treeSelectToPlotOptions = (values: any): any[][] => {
    const result: any = {};

    for (let i = 0; i < Object.keys(values).length; i++) {
        const id = Object.keys(values)[i];
        const item = values[id];

        if (item.type) {
            if (!result[id]) {
                result[id] = {
                    'channel_ids': []
                };
            }

            result[id].sensor_name = item.name;
            result[id].type = item.type;
            result[id].graph_types = item.graph_types;
            result[id].checked = true;
        }

        if (item.isChannel) {
            if (!result[item.parentId]) {
                result[item.parentId] = {
                    channel_ids: []
                };
            }
            result[item.parentId].channel_ids.push(id);
        }
    }

    const group = [];

    for (let i = 0; i < Object.keys(result).length; i++) {
        const key = Object.keys(result)[i];

        group.push(result[key]);
    }

    return group;
};

