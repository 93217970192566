import React, {useEffect, useState, useRef} from 'react';

type valueType = {
    boxId: number
    upperText: any;
    lowerText: any;
    unit: string
    channelId: string
    boxColor: string
}
type SVGProps = {
    divId: string
    svgImage: any,
    elementClicked: (index: number)=>void,
    // eslint-disable-next-line @typescript-eslint/ban-types
    values: valueType[],
    lastBox?: any,
    onLastBoxClick: ()=>void,
    filterBox?: any,
    onFilerBoxClick?: any,
}
const SVGComponent = (props: SVGProps)=>{
    useEffect(()=>{
        const el =  document.getElementById(props.divId);
        if (el) {
            el.innerHTML = props.svgImage;
            const finalBox = document.querySelector(
                `#${props.divId} #Custom_Fill_Rect`
            ) as HTMLElement;
            if (finalBox) {
                finalBox.style.fill = props.values[19].boxColor;
                finalBox.style.cursor = 'pointer';
                finalBox.style.height = props.values[19].lowerText * 0.28 ? (props.values[19].lowerText * 0.28).toString() : '';
                finalBox.onclick = () => props.onLastBoxClick();
            }

            const element = document.querySelector(`#${props.divId} #Custom_Fill_Text`) as HTMLElement;
            const element1 = document.querySelector(`#${props.divId} #tspan6169-9`)as HTMLElement;
            if (element) {
                element.innerHTML =  props.values[19].unit !== null ? props.values[19].lowerText + ' '
                    + props.values[19].unit : props.values[19].lowerText + '';
            }
            if (element1) {
                element1.innerHTML = props.values[19].upperText;
            }
            Array.from(document.querySelectorAll(`#${props.divId} [id^=Custom_Box]`).values()).map((box: any) => {
                const matches = box.id.match(/\d+$/);
                if (matches === null || matches.length === 0) return;
                const elementNumber = Number(matches[0]) - 1;
                if (props.values[elementNumber]?.upperText) {
                    box.style.cursor = 'pointer';
                    box.onclick = (e:any) => {
                        props.elementClicked(elementNumber);
                    };
                }

                const element = document.querySelector(
                    `#${props.divId} #${box.id}>rect`
                ) as HTMLElement;
                if (element) {
                    element.style.fill = props.values[elementNumber].boxColor;
                }

                let text = document.querySelector( `#${props.divId} #${box.id}_Custom_Text`) as HTMLElement;
                if (text) {
                    text.style.pointerEvents = 'none';
                    text.innerHTML = props.values[elementNumber].unit !== null ? props.values[elementNumber].lowerText + ' '
                        + props.values[elementNumber].unit : props.values[elementNumber].lowerText + '';
                }
                text = document.querySelector( `#${props.divId} #${box.id}_Custom_Text0`) as HTMLElement;
                if (text) {
                    text.style.pointerEvents = 'none';
                    text.textContent = props.values[elementNumber].upperText;
                }
            });

        }
    },[props.values]);

    return (<>
        <div id={props.divId}></div>
    </>);
};

export default SVGComponent;
