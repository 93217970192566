import keycloakfetch from '../../../functions/keycloakfetch';
import {Nullable} from '../../functions/Global';
import settings from '../../../config/settings';

export type Plants = {
  'id': string,
  'plant_name': string,
  'longitude': Nullable<string>,
  'latitude': Nullable<string>,
  'altitude': Nullable<string>
  'description': Nullable<string>

}

export async function getPlants (projectId: string) {
    const elementIdArr: any = [];
    const allEments: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getElements?project_id=' + projectId + '&element_type_id=14').then(async response => {
        await Promise.all(response.map(async (val: any, index: number) => {
            elementIdArr.push(val['element_id']);
            allEments.push(val);

        }));
    });

    const params = new URLSearchParams({
        'project_id': projectId,
    });
    const body = {
        'element_ids' : elementIdArr
    };
    const getStatus = await keycloakfetch.post(settings.apiUrl + '/getCMStatusForElements?' + params, body);

    const newArr:any = [];
    allEments.map((val: any, index: number)=>{
        let color;
        if (getStatus[val['element_id']] === undefined || getStatus[val['element_id']] === 0) {
            color = 'gray';
        }
        else if (getStatus[val['element_id']] === 1) {
            color = 'green';
        }
        else if (getStatus[val['element_id']] === 2) {
            color = 'yellow';
        }
        else if (getStatus[val['element_id']] === 3) {
            color = 'red';
        }
        const json = {
            index: index,
            id: val['element_id'],
            name: val['element_name'],
            status: '10',
            alerts: '2',
            longitude: val['longitude'],
            latitude: val['latitude'],
            altitude: val['altitude'],
            timestamp: '02.11.2022 23:45:00',
            color: color,
            alarm: true,
            description: val['description'],
            elementTypeId: val['element_type_id'],
            parentId: val['parent_id']
        };

        newArr.push(json);
    });

    return newArr;
}

export async function getFilteredPlants (projectId: string, filter: string) {
    const params = new URLSearchParams({
        'project_id': projectId,
        'filter': filter
    });
    const elementIdArr: any = [];
    const allEments: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getElements?' + params).then(async response => {
        await Promise.all(response.map(async (val: any, index: number) => {
            elementIdArr.push(val['element_id']);
            allEments.push(val);

        }));
    });
    const params1 = new URLSearchParams({
        'project_id': projectId,
    });
    const body = {
        'element_ids' : elementIdArr
    };
    const getStatus = await keycloakfetch.post(settings.apiUrl + '/getCMStatusForElements?' + params1, body);

    const newArr:any = [];
    allEments.map((val: any, index: number)=>{
        let color;
        if (getStatus[val['element_id']] === undefined || getStatus[val['element_id']] === 0) {
            color = 'gray';
        }
        else if (getStatus[val['element_id']] === 1) {
            color = 'green';
        }
        else if (getStatus[val['element_id']] === 2) {
            color = 'yellow';
        }
        else if (getStatus[val['element_id']] === 3) {
            color = 'red';
        }
        const json = {
            index: index,
            id: val['element_id'],
            name: val['element_name'],
            status: '10',
            alerts: '2',
            longitude: val['longitude'],
            latitude: val['latitude'],
            altitude: val['altitude'],
            timestamp: '02.11.2022 23:45:00',
            color: 'gray',
            alarm: true,
            description: val['description'],
            elementTypeId: val['element_type_id'],
            parentId: val['parent_id']
        };

        newArr.push(json);
    });

    return newArr;

}

export async function createPlant (data:any) {
    let retValue: any = [];

    await keycloakfetch.put(settings.apiUrl +  '/createElement', data).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;

}

export async function updatePlant (data:any) {
    let retValue: any = [];

    await keycloakfetch.post(settings.apiUrl +  '/updateElement', data).then((result) => {
        if (result)
            retValue = result;
    });

    return retValue;
}

export async function deletePlant (data: any) {
    let retValue: any = [];

    await keycloakfetch.delete(settings.apiUrl + '/deleteElement', data).then((result) => {
        if (result)
            retValue = result;
    });

    return retValue;
}