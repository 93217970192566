/*
 * CutoffFrequencySelectList.tsx
 * Author: lnappenfeld
 * Date: 07.06.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import Input from '../../global/Input';
import {useTranslation} from 'react-i18next';

type Data = {
    value: number,
    label: string
}

type Props = {
    channelTypeId: number,
    label: string,
    value?: number | null,
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    name?: string,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    disabled?: boolean,
    showClear?: boolean,
}

const optionsMEDA = [
    {value: 12800, label: '12800 Hz'},
    {value: 2560, label: '2560 Hz'},
    {value: 256, label: '256 Hz'},
    {value: 25.6, label: '25.6 Hz'}
] as Data[];

const optionsHKMD = [
    {value: 2560, label: '2560 Hz'},
    {value: 256, label: '256 Hz'},
    {value: 25.6, label: '25.6 Hz'}
] as Data[];

export const CutoffFrequencySelectList = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);
    const [list, setList] = useState<Data[]>([]);

    useEffect(() => {
        switch (props.channelTypeId) {
            case 201:
                setList(optionsMEDA);
                break;
            case 204:
                setList(optionsHKMD);
                break;
            default:
                setList([]);
                break;
        }
    }, []);

    return (
        <Input
            name={props.name ? props.name : 'cutoff_frequency'}
            edit={true}
            showClear={typeof props.showClear === 'boolean' ? props.showClear : false}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            tooltip={t('metrologyManagement:tooltips.cutoffFrequency')}
            label={props.label}
            value={props.value}
            dropdownOptions={list} type={'dropdown'}
            onChange={(e: any) => {
                if (props.setValue) props.setValue(e.value);
                if (props.onChange) props.onChange(e);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};
