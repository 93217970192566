import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BiFullscreen } from 'react-icons/bi';
import { useFullScreenHandle } from 'react-full-screen';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import Filter from './Filter';
import Chart from './Chart';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useTranslation } from 'react-i18next';
import Alerts from '../alerts-table/Alerts';
import {
    getDoppelfilterDetails,
    getMeasurePointValues,
    getMultipleOperationalData
} from '../functions/DoppelfilterConfigurations';
import moment from 'moment-timezone';

type DoppelfilterProps = {
    projectId: string
    colorTheme: string;
    setCustomBreadcrumb: any
};
const Doppelfilter = (props: DoppelfilterProps) => {
    const { t } = useTranslation(['common']);

    const [startTime, setStartTime] = useState<string>(() => {
        const date = new Date();
        date.setDate(date.getDate() - 4);
        date.setHours(0, 0, 0, 0);
        const user = JSON.parse(localStorage.getItem('user') as string);
        const options:any = {
            timeZone: user['timezone'],
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false // Use 24-hour format
        };

        return date.toLocaleString('en-US', options);
    });

    const [endTime, setEndTime] = useState<string>(() => {
        const date = new Date();
        date.setHours(23, 59, 0, 0);
        const user = JSON.parse(localStorage.getItem('user') as string);
        const options:any = {
            timeZone: user['timezone'],
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false // Use 24-hour format
        };

        return date.toLocaleString('en-US', options);

    });

    // SVG Diagram

    const [measurePoint, setMeasurePoint] = useState<any>([]);
    const [graphdata, setGraphData] = useState<any>([]);


    // load graphdata
    const [isGraphDataLoading, setIsGraphDataLoading] = useState<boolean>(false);
    const rerenderGraph= async (points: any[]) => {
        setMeasurePoint(points);
    };
    const updateGraph = async () => {
        setIsGraphDataLoading(true);

        const reqs = measurePoint.map((pointValue: any) =>
            getMultipleOperationalData(props.projectId, pointValue['pointId'], startTime, endTime)
        );

        const responses: any= await axios.all(reqs);

        const newData = responses.map((response: any) => {
            const apiData: any = [];
            if (response[0]) {
                response[0]['json_agg'].forEach((val: any) => {
                    apiData.push([new Date(val['f1']).getTime(), +val['f2'].toFixed(2)]);
                });
            }

            return apiData;
        });

        setGraphData([...newData]);

        setIsGraphDataLoading(false);
    };

    const handle = useFullScreenHandle();

    useEffect(() => {
        updateGraph();
        props.setCustomBreadcrumb([
            {id: 1, label: 'Condition Monitoring', command:()=>{window.location.hash = '#conditionomonitoring';}},
            {id: 2, label: localStorage.getItem('plants'), command:()=>{window.location.hash = '#conditionomonitoring_' + localStorage.getItem('plantId');}},
            {id: 3, label: localStorage.getItem('component'), command:()=>{window.location.hash = '#conditionomonitoring_' + localStorage.getItem('componentId') + '_detail';}},
            {id: 4, label: t('conditionMonitoring:doppelfilter'), command:()=>{}}
        ]);
    }, [startTime, endTime, measurePoint]);

    const getValues = async ()=>{
        const monitoringAppId: any = localStorage.getItem('monitoringAppId') ? localStorage.getItem('monitoringAppId') : '';
        const monitoringAppTypeId: any = localStorage.getItem('monitoringAppTypeId') ? localStorage.getItem('monitoringAppTypeId') : '';
        const url = location.hash.split('_');
        const imageDetails = await getDoppelfilterDetails(props.projectId, monitoringAppId, monitoringAppTypeId,  '1', url[1]);
        const graphData:any = [];
        imageDetails.map((val: any)=>{
            if (val['number'] === 14) {
                graphData.push(val);
            }
        });
        if (graphData[0]['tag_name'] !== '---') {
            rerenderGraph([{
                'point': graphData[0]['tag_name'],
                'pointId': graphData[0]['channel_id'],
                'min': graphData[0]['chart_min'],
                'max': graphData[0]['chart_max']}]);
        }
        else {
            rerenderGraph([]);
        }
        
    };
    useEffect( () => {
        getValues();
    }, [props.colorTheme]);


    return (

        <>
            <div className="content">
                <div className="grid">
                    <div className="col-5">
                        <div className='card h-full'>
                            <Filter
                                projectId={props.projectId}
                                rerenderGraph={rerenderGraph}
                                colorTheme={props.colorTheme}
                                measurePoints={measurePoint}
                            />
                        </div>
                    </div>

                    <div className=" col-7">
                        <div className='card h-full'>
                            <div className="grid align-items-center justify-content-center">
                                <div className="sm:col-12 md:col-6 text-center lg:col-2 xl:col-4 responsiveDates">
                                    <label htmlFor="range" className="time-select mt-3 mb-2">
                                        {t('conditionMonitoring:from')}
                                    </label>
                                    <Calendar
                                        className="pl-2"
                                        value={new Date(startTime)}
                                        onChange={(e: any) => {
                                            setStartTime(e.target.value);
                                        }}
                                        showTime
                                        dateFormat="dd.mm.yy"
                                        hideOnDateTimeSelect
                                        maxDate={new Date()}
                                        // maxDate={endTime || new Date('01.01.30000')}
                                    />
                                </div>

                                <div className="sm:col-12 md:col-6 lg:col-2 xl:col-4 text-center responsiveDates">
                                    <label htmlFor="range" className="time-select mt-3 mb-2">
                                        {t('conditionMonitoring:to')}
                                    </label>
                                    <Calendar
                                        className="pl-2"
                                        value={new Date(endTime)}
                                        onChange={(e:any) => {
                                            setEndTime(e.target.value);
                                        }}
                                        showTime
                                        dateFormat="dd.mm.yy"
                                        hideOnDateTimeSelect
                                        minDate={new Date(startTime)}
                                        // minDate={startTime || new Date('01.01.01')}
                                    />
                                </div>

                                <div className="sm:col-12 md:col-6 lg:col-2 xl:col-3 text-right ml-7">
                                    <Button
                                        onClick={handle.enter}
                                        icon={<BiFullscreen />}
                                        className="p-button p-component p-button-rounded p-button-secondary p-button-text filter-icon p-button-icon-only"
                                    ></Button>
                                </div>
                            </div>

                            <Chart
                                {...{
                                    measurePoint,
                                    graphdata,
                                    rerenderGraph,
                                    handle,
                                }}
                                isLoading={isGraphDataLoading}
                            />
                        </div>

                    </div>
                </div>

                <Alerts projectId={props.projectId} alarmOrigin={4} colorTheme={props.colorTheme}/>
            </div>
        </>
    // );
    );
};

export default Doppelfilter;
