/*
 * Wiki.tsx
 * Author: fwunderlich
 * Date: 19.04.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState, useEffect, useRef} from 'react';
import {Button} from 'primereact/button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMaximize, faUpRightFromSquare} from '@fortawesome/free-solid-svg-icons';

type IFrameProps = {
    url: any,
    show: boolean,
}

const Wiki = (props: IFrameProps): JSX.Element => {
    const componentRef: any = useRef();
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        setHeight(componentRef.current.offsetHeight);
        setWidth(componentRef.current.offsetWidth);
    });

    useEffect(() => {
        if (height > 0 && width > 0) {
            const iframeElement: any = document.querySelector('iframe');
            iframeElement.style.width = width + 'px';
            iframeElement.style.height = height + 'px';
        }
    }, [height, width]);

    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noreferrer');
    };

    return (<>
        {props.show &&
        <div className={'wiki-buttons'}>
            <Button
                style={{float: 'right', zIndex: 100}} className={'p-button m-1'}
                onClick={() => {
                    // set iframe to fullscreen
                    if (iframeRef && iframeRef.current) {
                        const elem = iframeRef.current;
                        // @ts-ignore
                        elem.requestFullscreen().catch((e) => {
                            console.log('error: ', e);
                        });
                    }
                }}
            >
                <FontAwesomeIcon className='sidebar-icon' icon={faMaximize}/>
            </Button>
            {
                <Button
                    style={{float: 'right', zIndex: 100}} className={'p-button m-1'}
                    onClick={() => {
                        // link to wiki
                        openInNewTab(props.url);
                    }}
                >
                    <FontAwesomeIcon className='sidebar-icon' icon={faUpRightFromSquare}/>
                </Button>
            }
        </div>}
        <div ref={componentRef} className={'iframe-wrapper'}>
            <iframe
                ref={iframeRef}
                allowFullScreen={true}
                style={{'borderRadius': '6px', border: 0, display: props.show ? 'inline-block' : 'none'}}
                src={props.url}
                width={'100%'}
                height={'100%'}
                id={'wki_iframe'}
                className={''}
            />
        </div>
    </>);
};

export default Wiki;
