/*
 * NotificationAddRemoveActionsForm.tsx
 * Author: lnappenfeld
 * Date: 13.12.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */


import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {
    Action,
    addActionsToNotifications,
    getNotificationTemplates,
    Notification,
    removeActionsFromNotifications
} from '../functions/Notification';
import {ActionOverviewType, ActionsOverview} from '../userManagement/ActionsOverview';
import {useForm} from 'react-hook-form';
import Input from '../global/Input';

type Data = {
    value: string,
    label: string,
    actionTypeId: number,
    notificationTypeId: number
}

export enum AddOrRemoveType {
    Add = 1,
    Remove = -1
}

type Props = {
    projectId: string,
    selectedNotifications: Notification[],
    onFinished: (success: boolean) => any
    type: AddOrRemoveType
}

const NotificationAddRemoveActionsForm = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    // Initialisiere mit undefined, damit das Skeletonloading funktioniert
    const [smsTemplates, setSmsTemplates] = useState<Data[]>();
    // Initialisiere mit undefined, damit das Skeletonloading funktioniert
    const [emailTemplates, setEmailTemplates] = useState<Data[]>();
    const [selectedActions, setSelectedActions] = useState<Action[]>([]);

    const {control, formState: {errors}, trigger, getValues, setValue, handleSubmit} = useForm();

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    useEffect(() => {
        getNotificationTemplates(props.projectId).then(result => {
            const templates: Data[] = [];
            result.forEach((key: any) => {
                templates.push({
                    value: key.template_id,
                    label: key.name,
                    actionTypeId: key.action_type_id,
                    notificationTypeId: key.notification_type_id
                });
            });

            const SMSTemplates = templates.filter((ele: Data) => {
                return ele.actionTypeId === 0 && props.selectedNotifications[0].notification_type_id === ele.notificationTypeId;
            });
            setSmsTemplates(SMSTemplates);
            if (SMSTemplates.length === 1) {
                setValue('sms_template_id', SMSTemplates[0].value);
            }

            const EMAILTemplates = templates.filter((ele: Data) => {
                return ele.actionTypeId === 1 && props.selectedNotifications[0].notification_type_id === ele.notificationTypeId;
            });
            setEmailTemplates(EMAILTemplates);
            if (EMAILTemplates.length === 1) {
                setValue('email_template_id', EMAILTemplates[0].value);
            }
        });

    }, []);

    const saveData = (actions: any) => {
        if (selectedActions.length === 0) {
            showMessageOnError(t('error'), t('toasts:nothingSelected'));
            return;
        }

        actions = {
            ...{'actions': JSON.stringify(selectedActions)},
            ...{'notifications': JSON.stringify(props.selectedNotifications)},
            ...actions
        };

        if (props.type === AddOrRemoveType.Add) {
            addActionsToNotifications(props.projectId, actions).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), result.error);
                } else {
                    showMessageOnSuccess(t('success'), t('alarmManagement:toasts.notificationActionsAdded'));
                    props.onFinished(true);
                }
            });
        } else {
            removeActionsFromNotifications(props.projectId, props.selectedNotifications, selectedActions).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), result.error);
                } else {
                    showMessageOnSuccess(t('success'), t('alarmManagement:toasts.notificationActionsRemoved'));
                    props.onFinished(true);
                }
            });
        }
    };


    return (
        <form id='formDialog' onSubmit={handleSubmit(saveData)}>
            <div className='card'>
                <ActionsOverview
                    projectId={props.projectId}
                    type={ActionOverviewType.NotificationAddRemoveActions}
                    selectedActions={selectedActions}
                    setSelectedActions={setSelectedActions}
                />
            </div>
            {props.type === AddOrRemoveType.Add &&
            <div className='card'>
                <div className='grid mt-2'>
                    <div className='col-12 lg:col-6'>
                        <Input
                            name={'email_template_id'}
                            scrollHeight={'450px'}
                            edit={true}
                            dropdownOptions={emailTemplates}
                            type={'dropdown'}
                            label={t('alarmManagement:attributes.emailTemplate')}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('alarmManagement:attributes.emailTemplate') + ' ' + t('input:required')}}
                        />
                    </div>
                    <div className='col-12 lg:col-6'>
                        <Input
                            name={'sms_template_id'}
                            scrollHeight={'450px'}
                            edit={true}
                            dropdownOptions={smsTemplates}
                            type={'dropdown'}
                            label={t('alarmManagement:attributes.smsTemplate')}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('alarmManagement:attributes.smsTemplate') + ' ' + t('input:required')}}
                        />
                    </div>
                </div>
            </div>}
        </form>
    );
};

export default NotificationAddRemoveActionsForm;
