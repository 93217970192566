/*
 * CurrentUserSettings.tsx
 * Author: lnappenfeld
 * Date: 08.02.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React from 'react';
import {useTranslation} from 'react-i18next';
import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';
import LanguageSelectList from '../global/LanguageSelectList';
import {Button} from 'primereact/button';
import Input from '../global/Input';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {getUserFromLocalStorage} from '../functions/Global';
import TimezoneSelectList from '../global/TimezoneSelectList';
import functionLibrary from '../../functions/functionLibrary';
import {useForm} from 'react-hook-form';
import {showWaitAnimation} from '../global/CustomWaitAnimation';

const CurrentUserSettings = (): JSX.Element => {

    const {t, i18n} = useTranslation(['common', 'userManagement']);

    const defaultValues = {
        'first_name': getUserFromLocalStorage().first_name || null,
        'last_name': getUserFromLocalStorage().last_name || null,
        'email': getUserFromLocalStorage().email,
        'username': getUserFromLocalStorage().username,
        'sms': getUserFromLocalStorage().sms || null,
        'company': getUserFromLocalStorage().company || null,
        'language_id': getUserFromLocalStorage().language_id,
        'timezone': getUserFromLocalStorage().timezone,
        'is_saving_userfilters_enabled': getUserFromLocalStorage().is_saving_userfilters_enabled
    };

    const {control, formState: {errors}, handleSubmit} = useForm({defaultValues});

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    const saveUserSettings = (data: any) => {
        const enabled = {enabled: true}; // Darf sich einloggen
        const user = {...data, ...enabled};

        keycloakfetch.post(settings.apiUrl + '/updateMe', user).then(result => {
            if (result.error) {
                showMessageOnError(t('error'), result.error);
            } else {
                // Set i18next language
                i18n.changeLanguage(user.language_id);
                // Update localStorage
                localStorage.setItem('user', JSON.stringify(user));

                showMessageOnSuccess(t('success'), t('userManagement:toasts.currentUserUpdated'));
                showWaitAnimation();
                window.location.reload();
            }
        });
    };

    return (
        <>
            <div className="userSettings">
                <div className={'card col-12 md:col-6 md:col-offset-3'}>
                    <div className="rowContainer m-2">
                        <h1>{t('userManagement:global.userSettings')}</h1>
                    </div>
                    <div className='grid m-2'>
                        <div className='col-12 lg:col-6'>
                            <Input
                                edit={true} label={t('userManagement:attributes.firstname')} name={'first_name'}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: t('userManagement:attributes.firstname') + ' ' + t('input:required')}}
                            />
                            <Input
                                edit={true} label={t('userManagement:attributes.lastname')} name={'last_name'}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: t('userManagement:attributes.lastname') + ' ' + t('input:required')}}
                            />
                            <Input
                                edit={true} label={t('userManagement:attributes.username')} disabled={true} name={'username'}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: true}}
                            />
                            <Input
                                edit={true} label={'Email'} disabled={true} name={'email'}
                                validationControl={control}
                                validationErrorMessage={getFormErrorMessage}
                                validationRules={functionLibrary.emailValidation(t)}
                            />
                            <Input
                                edit={true} label={t('userManagement:attributes.saveFilters')} name={'is_saving_userfilters_enabled'}
                                type={'checkbox'} validationControl={control} validationRules={{required: false}}
                            />
                        </div>
                        <div className='col-12 lg:col-6'>
                            <Input
                                edit={true} label={'SMS'} name={'sms'}
                                validationErrorMessage={getFormErrorMessage}
                                validationControl={control} validationRules={functionLibrary.smsValidation(t, false)}
                            />
                            <Input
                                edit={true} label={t('userManagement:attributes.company')} name={'company'}
                                validationControl={control} validationRules={{required: false}}
                            />
                            <LanguageSelectList
                                label={t('language')}
                                validationControl={control}
                                validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: t('language') + ' ' + t('input:required')}}
                            />
                            <TimezoneSelectList
                                label={t('timezone')}
                                validationControl={control}
                                validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: t('timezone') + ' ' + t('input:required')}}
                            />
                        </div>
                    </div>
                    <div className="flex justify-content-end m-3">
                        <Button className={'p-button p-button-outlined'} onClick={handleSubmit(saveUserSettings)}>
                            <i className="pi pi-save mr-2"/>{t('save')}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CurrentUserSettings;
