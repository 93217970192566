/*
 * GrafanaDashboardSelectList.tsx
 * Author: lnappenfeld
 * Date: 18.10.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState, useEffect} from 'react';
import Input from '../global/Input';
import {getGrafanaDashboards, getLocations} from '../functions/Global';

type Props = {
    projectId: string,
    name?: string,
    label: string,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    showClear?: boolean
}

export const GrafanaDashboardSelectList = (props: Props):JSX.Element => {

    // Initialisiere mit undefined, damit das Skeletonloading funktioniert
    const [list, setList] = useState<any>();

    useEffect(() => {
        getGrafanaDashboards(props.projectId).then(result => {
            const tmp: any = [];
            result.forEach((key: any) => {
                tmp.push({
                    value: key.dashboard_id,
                    label: key.name,
                });
            });
            setList(tmp);
        });
        return () => setList(null);
    }, []);

    return (
        <Input
            name={props.name ? props.name : 'dashboard_id'}
            edit={true}
            showClear={typeof props.showClear === 'boolean' ? props.showClear : true}
            label={props.label}
            dropdownOptions={list}
            scrollHeight={'350px'}
            type={'dropdown'}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};