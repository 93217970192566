/*
 * MeasuringManagerDialogEditForm.tsx
 * Author: lnappenfeld
 * Date: 25.05.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import CustomDialog, {CustomDialogHeaderType} from '../global/CustomDialog';
import {MeasuringManagerForm} from './MeasuringManagerForm';


type Props = {
    projectId: string,
    selectedMeasuringManagers: any,
    setSelectedMeasuringManagers: any,
    editMeasuringManager: boolean,
    setEditMeasuringManager: (data: boolean) => void,
    setMeasuringManagers?: any,
    setReloadMeasuringManagers: (data: boolean) => void,
}

export const MeasuringManagerDialogEditForm = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);
    useEffect(() => {
    }, [props.selectedMeasuringManagers])
    return (
        <CustomDialog
            header={props.selectedMeasuringManagers.length > 0 ? t('metrologyManagement:dialogs.editMeasuringManagerDialog.header') : t('metrologyManagement:dialogs.createMeasuringManagerDialog.header')}
            headerType={props.selectedMeasuringManagers.length > 0 ? CustomDialogHeaderType.Update : CustomDialogHeaderType.Create}
            formName={'formMeasuringManager'}
            onCancel={() => {
                props.setEditMeasuringManager(false);
            }}
            onHide={() => {
                props.setEditMeasuringManager(false);
            }}
            visible={props.editMeasuringManager}
        >
            <MeasuringManagerForm
                projectId={props.projectId}
                onFinished={() => {
                    props.setEditMeasuringManager(false);
                    props.setSelectedMeasuringManagers([]);
                    props.setReloadMeasuringManagers(true);
                }}
                measuringManager={props.selectedMeasuringManagers.length > 0 ? props.selectedMeasuringManagers[0] : null} />
        </CustomDialog>
    );

};

