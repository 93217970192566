import {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import MapContext from '../map/MapContext';
import OLVectorLayer from 'ol/layer/Vector';
import {Circle, Fill, Icon, Stroke, Style} from 'ol/style';
import {Vector as VectorSource} from 'ol/source';
import Cluster from 'ol/source/Cluster';
import VectorLayer from 'ol/layer/Vector';
import {
    callData,
    createFeatures,
    getMeasuringpointClusterStyle,
    getMpSelectedStyle,
    getMeasurementPointImageStyle,
    getHoverStyle,
    getStatusNumber, iterateAlarms
} from '../../../services/MeasuringPointService';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {setLayerArray, addLayerToCounterArray, setMPTypes} from '../../../redux/GeomonitoringSlice';
import {setPermaLayerVisible} from '../../../functions/functionsOpenlayer';
import geoSettings from '../../../config/geoSettings';

type poiProps = {
    alarm: any,
    zoomMap: number,
    projectId: number,
}

type mpCollector = { [key: string]: [] }

const PoiLayer: FunctionComponent<poiProps> = ({alarm, zoomMap, projectId}) => {
    // @ts-ignore

    const {map, setMap} = useContext(MapContext);
    const [zoom, setZoom] = useState(zoomMap);
    const refZoom = useRef(zoom);
    const {t} = useTranslation(['geomonitoring']);
    const permaLinkArray = useAppSelector((state: any) => state.configLayer.layerData.permaLinkLayer);
    const isPermaLink = useAppSelector((state: any) => state.configLayer.layerData.isPermaLink);
    const filterMp = useAppSelector((state: any) => state.configLayer.layerData.filterMp);
    const alarmArray = useAppSelector((state: any) => state.configLayer.layerData.alarmArray);
    const dispatch = useAppDispatch();
    const [vLayer, setVlayer] = useState(null);
    const [mpArray, setMpArray] = useState([]);
    const [featuresExists, setFeaturesExists] = useState(false);
    const [cSource, setCSource] = useState({
        removeFeature(feature: any) {

        },
        addFeature(mp: any) {

        }
    });
    const [nSource, setNSource] = useState({
        removeFeature(feature: any) {

        },
        addFeature(mp: any) {

        }
    });
    const refVLayer = useRef(vLayer);
    const refCSource = useRef(cSource);
    const refNSource = useRef(nSource);
    const refMpArray = useRef(mpArray);
    const refAlarm = useRef(alarmArray);
    const mpStorage: mpCollector = {};

    const mpSelectedStyle = (feature: any) => {
        // const zoom = map.getView().getZoom();
        return getMpSelectedStyle(zoom, feature);
    };

    const hoverStyle = (feature: any) => {
        const zoom = map.getView().getZoom();
        return getHoverStyle(zoom, feature);
    };

    const pointerMoveEvent = () => {
        let highlightedFeatures: any[] = [];
        let feature_onHover;
        map.on('pointermove', function (e: { pixel: any; coordinate: any; }) {
            highlightedFeatures.forEach(f => f.setStyle(null));
            highlightedFeatures = [];
            feature_onHover = map.forEachFeatureAtPixel(e.pixel, (f: {
                    values_: { features: any; };
                    get: (arg0: string) => string | number | boolean;
                    setStyle: (arg0: any) => void;
                    getId: () => any;
                }) => {
                if (typeof f.values_.features === 'undefined') {
                    if (f.get('layerKind') && f.get('layerKind') === 'poiLayer' && (f.get('overlay') !== true)) {
                        f.setStyle(mpSelectedStyle);
                    }
                }
                if (f.getId()) {
                    if (f.get('layerKind') && f.get('layerKind') === 'poiLayer') {
                        if (f.get('overlay') !== true) {
                            f.setStyle(hoverStyle);
                        }
                        highlightedFeatures.push(f);
                    }
                }
            },
            {
                hitTolerance: 5
            });
        });
    };

    const getMpStyles = (feature: any, resolution: number) => {

        if (zoom !== undefined && zoom <= 17) {
            if (feature.get('features').length === 1) {
                const features = feature.get('features');
                return getMeasurementPointImageStyle(18, features[0], resolution);
            }
            return getMeasuringpointClusterStyle(zoom, feature, resolution);
        } else {
            return getMeasurementPointImageStyle(zoom, feature, resolution);
        }
    };

    const getVectorLayerObject = (clusterSource: any, pointFeatures: any, layerId: string, layerVisibility: boolean) => {

        const vectorLayer = new VectorLayer({
            source: clusterSource
        });

        vectorLayer.set('name', layerId);
        vectorLayer.set('id', layerId);
        vectorLayer.set('type', 'vector');
        vectorLayer.set('zIndex', geoSettings.poiLayerKey);
        vectorLayer.set('visible', layerVisibility);
        vectorLayer.setStyle(getMpStyles);
        return vectorLayer;
    };

    const getSources = (pointFeatures: any) => {

        const source = new VectorSource({});
        const clusterSource = new Cluster({
            distance: 30,
            source: source
        });
        source.addFeatures(pointFeatures.features);
        clusterSource.addFeatures(pointFeatures.features);
        setNSource(source);
        setCSource(clusterSource);
        setFeaturesExists(true);
        return {cluster: clusterSource, vector: source};
    };

    const createLayer = async (map: any) => {

        const pois = await callData(projectId);
        const pointFeatures = await createFeatures(pois, projectId);
        const layerId = 'poiLayer';
        const layerVisibility = setPermaLayerVisible(true, isPermaLink, permaLinkArray, layerId);
        const toogleVisibility = pois.length <= 0;
        const sources = getSources(pointFeatures);
        const vectorLayer = getVectorLayerObject(sources.cluster, pointFeatures, layerId, layerVisibility);

        map.addLayer(vectorLayer);
        map.on('moveend', function () {
            const newZoom = map.getView().getZoom();
            if (zoom !== newZoom) {
                setZoom(newZoom);
            }
        });

        // @ts-ignore
        setVlayer(vectorLayer);
        poiDispatches(layerId, toogleVisibility, pointFeatures.types);
        setMap(map);

        return () => {
            if (map) {
                map.removeLayer(vectorLayer);
            }
        };
    };

    const poiDispatches = (layerId: string, toogleVisibility: boolean, mpTypes: any) => {
        dispatch(setLayerArray({
            name: layerId,
            mainLayerId: layerId,
            id: layerId,
            type: 'vector',
            parentLayer: null,
            toogleHidden: toogleVisibility,
            visible: true,
            styleId: null,
            simpleLayer: true,
            layerParts: null,
            layerConfig: null,
            styles: null,
            title: 'POI'
        }));
        dispatch(addLayerToCounterArray(projectId));
      // console.log('add poilayers')
        dispatch(setMPTypes(mpTypes));
    };

    const removedFilteredFeature = (feature: any, id: string) => {
        const icon = feature.get('icon');
        if (icon === id || (icon === null && id === 'unknown')) {
            refNSource.current.removeFeature(feature);
            refCSource.current.removeFeature(feature);
            const newNSource = Object.assign({}, refNSource.current);
            const newCSource = Object.assign({}, refCSource.current);
            if (!mpStorage[id]) {
                mpStorage[id] = [];

            }
            setCSource(newCSource);
            setNSource(newNSource);
        }
    };

    const addFeaturesToStorageArray = (id: string, features: any) => {
        const newObj = Object.assign({}, refMpArray.current);
        if (Object.keys(newObj).includes(id)) {
            refMpArray.current = {...newObj, [id]: features};
            setMpArray({...newObj, [id]: features});
        } else {
            // @ts-ignore
            newObj[id] = features;
            refMpArray.current = newObj;
            setMpArray(newObj);
        }

    };

    const iterateChannelStatus = async (feature: any) => {
        let channelStatus = 1;
        const channelIds = feature.get('channelIds');
        if (channelIds.length > 0) {
            for (const i in channelIds) {
                const channelId = channelIds[i];
                channelStatus = iterateAlarms(alarmArray, channelId, channelStatus);
            }
        }
        feature.set('status', channelStatus);
        return feature;
    };


    const removeMp = (id: string) => {
        // @ts-ignore
        const cFeatures = (refCSource.current).getFeatures();
        // @ts-ignore
        const nFeatures = (refNSource.current).getFeatures();
        cFeatures.forEach((f: any) => {
            const cFeaturesCollection = f.get('features');
            addFeaturesToStorageArray(id, cFeaturesCollection);
            cFeaturesCollection.forEach((feature: any) => {
                removedFilteredFeature(feature, id);
            });


        });
        if (nFeatures) {
            addFeaturesToStorageArray(id, nFeatures);
            nFeatures.forEach((nf: any) => {
                removedFilteredFeature(nf, id);
            });
        }
    };

    const addMp = (id: string) => {
        const mpKeys=Object.keys(refMpArray.current);
        if(mpKeys.length>0&&mpKeys.includes(id)){
            // @ts-ignore
            refMpArray.current[id].forEach((mp: any) => {
                refCSource.current.addFeature(mp);
                refNSource.current.addFeature(mp);
            });
        }

    };


    const filterMpFeatures = (filteredMp: any) => {
        const id = filteredMp.mpType;
        const status = filteredMp.value ? filteredMp.value.checked : false;
        if (!status) {
            removeMp(id);
        } else {
            addMp(id);
        }
    };
    
    const loopChannelStatusUpdate=(fArray:any)=>{
        fArray.forEach(async (feature: any) => {
            await iterateChannelStatus(feature);
        });
    };
    
    const test4LoopAndIterateAlarm=async(fArray:any, f:any)=>{
        if (fArray) {
            loopChannelStatusUpdate(fArray);
        } else {
            if (f!==null)
                await iterateChannelStatus(f);
        }
    };

    useEffect(() => {
        if (zoom > 17) {
            // @ts-ignore
            vLayer?.setSource(refNSource.current);
        } else {
            // @ts-ignore
            vLayer?.setSource(refCSource.current);
        }
        // @ts-ignore
        vLayer?.setStyle(getMpStyles);
        // @ts-ignore
        setVlayer(vLayer);
        refZoom.current = zoom;
    }, [zoom]);

    useEffect(() => {
        refVLayer.current = vLayer;
        refCSource.current = cSource;
        refNSource.current = nSource;
    }, [vLayer]);

    useEffect(() => {
        if (!filterMp || Object.keys(filterMp).length === 0) return;

        filterMp.forEach((feature: any) => {
            filterMpFeatures(feature);
        });

    }, [filterMp]);

    useEffect(() => {
        refMpArray.current = mpArray;
    }, [mpArray]);

    useEffect(() => {
        refAlarm.current = alarmArray;
        if (!featuresExists) return;
        // @ts-ignore
        const cFeatures = (refCSource.current).getFeatures();
        // @ts-ignore
        const nFeatures = (refNSource.current).getFeatures();
        cFeatures.forEach(async (f: any) => {
            const cFeaturesCollection = f.get('features');
            await test4LoopAndIterateAlarm(cFeaturesCollection,f);
        }
        );
        test4LoopAndIterateAlarm(nFeatures, null).then(r => {});
    }, [alarmArray]);

    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;

        // eslint-disable-next-line no-console
        createLayer(map).then(r => console.log('PoiLayer erstellt'));
        pointerMoveEvent();

    }, [map]);

    return null;
};

export default PoiLayer;