import keycloakfetch from '../../functions/keycloakfetch';
import settings, {safeguardGlobalRoleIds} from '../../config/settings';
import {permissionsIcon} from '../../config/permissions';

export type User = {
    'id': string,
    'username': string,
    'first_name': string,
    'last_name': string,
    'email': string,
    'sms': string,
    'role_id': string,
    'enabled': string,
    'company': string,
    'language_id': string,
    'timezone': string
}
export type Role = {
    id: string,
    name: string,
    permissions: number[]
}

export type Permission = {
    key: number,
    label: string
}

export const isSuperuserRole = (roleId: string) => {
    return roleId === safeguardGlobalRoleIds.superuser;
};

export const isAdminRole = (roleId: string) => {
    return roleId === safeguardGlobalRoleIds.admin;
};

export async function getUser(projectId: string, userId: string | null) {
    let retValue:any = [];
    await keycloakfetch.get(settings.apiUrl + '/getUser?project_id=' + projectId + '&user_id=' + userId).then((result) => {
        retValue = result;
    });
    return retValue;
}

export async function getUsers(projectId:string, roleId:string='') {
    let retValue:any = [];
    let roleStr = '';
    if (roleId !== '')
        roleStr = '&role_id='+roleId;
    await keycloakfetch.get(settings.apiUrl + '/getUsers?project_id='+projectId + roleStr).then(result =>{
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function checkUserLogin(setting:string, value:string) {
    let retValue:any = [];
    await keycloakfetch.get(settings.apiUrl + '/checkUserLogin?setting=' + setting + '&value=' + value).then(result =>{
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getIsAdmin() {
    let retValue:any = [];
    await keycloakfetch.get(settings.apiUrl + '/isAdmin').then(result =>{
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getNoneProjectUsers(projectId:string) {
    let retValue:any = [];
    await keycloakfetch.get(settings.apiUrl + '/getNoneProjectUsers?project_id='+projectId).then(result =>{
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createUser(projectId:string, data:any) {
    data = {...{'project_id': projectId}, ...data};
    let retValue:any = [];
    await keycloakfetch.put(settings.apiUrl + '/createUser', data).then(result =>{
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function updateUser(projectId:string, userId:string, globalUser: boolean, data:any) {
    data = {...{'project_id': projectId}, ...{'user_id': userId}, ...{'global_user': globalUser}, ...data};
    let retValue:any = [];
    await keycloakfetch.post(settings.apiUrl + '/updateUser', data).then(result =>{
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function deleteUsers(projectId: string, userIds: string[]) {
    let retValue:any = [];
    await keycloakfetch.delete(settings.apiUrl + '/deleteUsers', {
        'project_id': projectId,
        'user_ids': userIds
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getRole(projectId: string, roleId: string) {
    let retValue:any = null;
    await keycloakfetch.get(settings.apiUrl + '/getRole?project_id=' + projectId + '&role_id=' + roleId).then(result =>{
        if (result && result.length === 1)
            retValue = result[0];
    });
    return retValue;
}

export async function getRoles(projectId:string) {
    let retValue:any = [];
    await keycloakfetch.get(settings.apiUrl + '/getRoles?project_id='+projectId).then(result =>{
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getGlobalRoles() {
    let retValue:any = [];
    await keycloakfetch.get(settings.apiUrl + '/getGlobalRoles').then(result =>{
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getPermissionTypes(projectId:string, t:any) {
    let retValue:any = [];
    await keycloakfetch.get(settings.apiUrl + '/getPermissionTypes?project_id='+projectId).then(result =>{
        if (result) {
            for (const i in result) {
                result[i].label = t('userManagement:permissions.' + result[i].key);
                try {
                    // @ts-ignore
                    result[i].icon = permissionsIcon[result[i].key];
                }catch (e) {
                    result[i].icon = '';
                }
            }
            retValue = result;
        }
    });
    return retValue;
}

export async function getMyPermissions(projectId: string) {
    let retValue:any = [];
    await keycloakfetch.get(settings.apiUrl + '/getMyPermissions/?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function moveUserToRole(projectId:string, selectedRoleId:string, selectedRoleUsers:any) {
    const data = {
        'project_id': projectId,
        'role_id': selectedRoleId,
        'users': selectedRoleUsers.map((user: any) => user.id)
    };
    let retValue:any = [];
    await keycloakfetch.post(settings.apiUrl + '/moveUserToRole', data).then(result =>{
        console.log(result);
        if (result)
            retValue = result;
    });
    console.log(retValue);
    return retValue;
}

export async function createRole(projectId:string, roleName: string, permissions:any) {
    let retValue:any = [];
    const body = {
        'project_id': projectId,
        'role_name': roleName,
        'permissions': JSON.stringify(permissions)
    };
    await keycloakfetch.put(settings.apiUrl + '/createRole', body).then(result =>{
        if (result)
            retValue = result;
    });
    return retValue;
}
export async function updateRole(projectId:string, roleId: string, roleName: string, permissions:any) {
    let retValue:any = [];
    const body = {
        'project_id': projectId,
        'role_id': roleId,
        'role_name': roleName,
        permissions: JSON.stringify(permissions)
    };
    await keycloakfetch.post(settings.apiUrl + '/updateRole', body).then(result =>{
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function deleteRole(projectId:string, roleId: string) {
    let retValue:any = [];
    const body = {
        'project_id': projectId,
        'role_id': roleId
    };
    await keycloakfetch.delete(settings.apiUrl + '/deleteRole', body).then(result =>{
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function logoutMeFromGrafana() {
    let retValue: any = false;
    await keycloakfetch.get(settings.apiUrl + '/logoutMeFromGrafana?').then((result) => {
        if (result.success)
            retValue = true;
        if (result.error)
            retValue = result;
    });
    return retValue;
}
