/*
 * NotificationManagement.tsx
 * Author: amusiul
 * Date: 17.11.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import ViewButtons from '../components/global/ViewButtons';
import {permissions} from '../config/permissions';
import {
    buttonActivateCharacteristicMonitoringTasks,
    buttonActivateMonitoringTasks,
    buttonActivateNotification,
    buttonActivateNotificationAction,
    buttonAddActionsToNotifications,
    buttonCreateCharacteristicMonitoringTask,
    buttonCreateMonitoringTask,
    buttonCreateNotification,
    buttonCreateNotificationAction,
    buttonCreateNotificationRange,
    buttonDeactivateCharacteristicMonitoringTasks,
    buttonDeactivateMonitoringTasks,
    buttonDeactivateNotification,
    buttonDeactivateNotificationAction,
    buttonDeactivateNotificationActionTemporary,
    buttonDeleteCharacteristicMonitoringTasks,
    buttonDeleteMonitoringTasks,
    buttonDeleteNotification,
    buttonDeleteNotificationAction,
    buttonDeleteNotificationRange,
    buttonNotificationWizard,
    buttonRemoveActionsFromNotifications,
    buttonUpdateNotificationRanges,
    makeButtonItem
} from '../components/global/viewButtonSelection';
import {checkPermission, checkProjectType, ProjectType} from '../functions/functionLibrary';
import {NotificationsOverview} from '../components/notification/NotificationsOverview';
import CustomDialog, {CustomDialogHeaderType} from '../components/global/CustomDialog';
import NotificationTemplateEditForm from '../components/notification/NotificationTemplateEditForm';
import LayoutMainContentTabMenu from '../components/global/LayoutMainContentTabMenu';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faArrowsLeftRightToLine,
    faArrowsToEye,
    faBell,
    faEnvelopeOpenText,
    faRadiationAlt,
    faSitemap,
} from '@fortawesome/free-solid-svg-icons';
import {NotificationTemplatesOverview} from '../components/notification/NotificationTemplatesOverview';
import {NotificationsActionsMapOverview} from '../components/notification/NotificationsActionsMapOverview';
import HistoricalAlarms from '../components/notification/HistoricalAlarms';
import {NotificationRangesOverview} from '../components/notification/NotificationRangesOverview';
import {confirmDialog} from 'primereact/confirmdialog';
import {showMessageOnError, showMessageOnSuccess} from '../components/global/CustomToast';
import {CustomConfirmDialog} from '../components/global/CustomConfirmDialog';
import {
    activateCharacteristicMonitoringTasksState,
    activateMonitoringTasksState,
    activateNotificationActionState,
    activateNotificationState,
    CharacteristicMonitoringTask,
    deactivateCharacteristicMonitoringTasksState,
    deactivateMonitoringTasksState,
    deactivateNotificationActionState,
    deactivateNotificationState,
    deleteCharacteristicMonitoringTasks,
    deleteMonitoringTasks,
    deleteNotification,
    deleteNotificationAction,
    deleteNotificationRange,
    MonitoringTask,
    Notification,
    NotificationRange
} from '../components/functions/Notification';
import {Type} from '../components/notification/NotificationRangeEditForm';
import NotificationAddRemoveActionsForm, {
    AddOrRemoveType
} from '../components/notification/NotificationAddRemoveActionsForm';
import {
    CharacteristicMonitoringTaskOverviewType,
    CharacteristicMonitoringTasksOverview
} from '../components/conditionMonitoring/monitoring/CharacteristicMonitoringTasksOverview';
import {
    MonitoringTaskOverviewType,
    MonitoringTasksOverview
} from '../components/conditionMonitoring/monitoring/MonitoringTasksOverview';
import {hideWaitAnimation, showWaitAnimation} from '../components/global/CustomWaitAnimation';

type Props = {
    projectId: any
}

const NotificationManagement = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    const [selectedNotifications, setSelectedNotifications] = useState<Notification[]>([]);
    const [reloadNotifications, setReloadNotifications] = useState(true);
    const [editNotification, setEditNotification] = useState<boolean>(false);
    const [notificationWizard, setNotificationWizard] = useState<boolean>(false);

    const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
    const [reloadNotificationTemplates, setReloadNotificationTemplates] = useState<boolean>(false);
    const [editTemplate, setEditTemplate] = useState<boolean>(false);

    const [selectedNotificationActions, setSelectedNotificationActions] = useState<any>([]);
    const [reloadNotificationActions, setReloadNotificationActions] = useState<boolean>(false);
    const [editNotificationAction, setEditNotificationAction] = useState<boolean>(false);
    const [disableNotificationActionTemporary, setDisableNotificationActionTemporary] = useState<boolean>(false);

    // < 0: delete; > 0: add; = 0: close Dialog
    const [addOrRemoveActions, setAddOrRemoveActions] = useState<number>(0);

    const [selectedNotificationRanges, setSelectedNotificationRanges] = useState<NotificationRange[]>([]);
    const [reloadNotificationRanges, setReloadNotificationRanges] = useState<boolean>(false);
    const [editNotificationRange, setEditNotificationRange] = useState<boolean>(false);
    const [notificationRangeDialogType, setNotificationRangeDialogType] = useState<Type>(Type.Single);

    const [selectedCharacteristicMonitoringTasks, setSelectedCharacteristicMonitoringTasks] = useState<CharacteristicMonitoringTask[]>([]);
    const [reloadCharacteristicMonitoringTasks, setReloadCharacteristicMonitoringTasks] = useState<boolean>(false);
    const [editCharacteristicMonitoringTask, setEditCharacteristicMonitoringTask] = useState<boolean>(false);
    // const [characteristicMonitoringTaskDialogType, setCharacteristicMonitoringTaskDialogType] = useState<Type>(Type.Single);

    const [selectedMonitoringTasks, setSelectedMonitoringTasks] = useState<MonitoringTask[]>([]);
    const [reloadMonitoringTasks, setReloadMonitoringTasks] = useState<boolean>(false);
    const [editMonitoringTask, setEditMonitoringTask] = useState<boolean>(false);

    const [viewButtonsRerender, setViewButtonsRerender] = useState<boolean>(true);

    const _deactivateNotification = (data: Notification[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('alarmManagement:dialogs.deactivateNotificationState'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const n: string[] = [];
                data.forEach((notification: Notification) => {
                    n.push(notification.id);
                });
                deactivateNotificationState(props.projectId, n).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('apiErrors:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('alarmManagement:toasts.notificationDisabled'));
                        setSelectedNotifications([]);
                        setReloadNotifications(true);
                    }
                });
            }
        });
    };

    const _activateNotification = (data: Notification[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('alarmManagement:dialogs.activateNotificationState'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const n: string[] = [];
                data.forEach((notification: Notification) => {
                    n.push(notification.id);
                });
                activateNotificationState(props.projectId, n).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('apiErrors:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('alarmManagement:toasts.notificationEnabled'));
                        setSelectedNotifications([]);
                        setReloadNotifications(true);
                    }
                });
            }
        });
    };

    const _deleteNotification = (data: Notification[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('alarmManagement:dialogs.deleteNotificationDialog.message'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const ids: string[] = [];
                data.forEach((notification: Notification) => {
                    ids.push(notification.id);
                });
                deleteNotification(props.projectId, ids).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('alarmManagement:toasts.notificationDeleted'));
                        setSelectedNotifications([]);
                        setReloadNotifications(true);
                    }
                });
            }
        });
    };

    const _deleteNotificationAction = (data: any) => {
        // eslint-disable-next-line new-cap
        confirmDialog({
            message: t('alarmManagement:dialogs.deleteNotificationAction'),
            header: t('confirmation'),
            icon: 'pi pi-info-circle',
            acceptLabel: t('yes'),
            rejectLabel: t('no'),
            accept: () => {
                const aids: string[] = [];
                const nids: string[] = [];
                data.forEach((action: any) => {
                    aids.push(action.action_id);
                    nids.push(action.notification_id);
                });
                deleteNotificationAction(props.projectId, nids, aids).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), result.error);
                    } else {
                        showMessageOnSuccess(t('success'), t('alarmManagement:toasts.notificationActionsRemoved'));
                        setSelectedNotificationActions([]);
                        setReloadNotificationActions(true);
                    }
                });

            }
        });
    };
    const _activateNotificationAction = (data: any) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('alarmManagement:dialogs.activateNotificationActionState'),
            header: t('confirmation'),
            onConfirm: () => {
                const aids: string[] = [];
                const nids: string[] = [];
                data.forEach((action: any) => {
                    aids.push(action.action_id);
                    nids.push(action.notification_id);
                });
                activateNotificationActionState(props.projectId, nids, aids).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), result.error);
                    } else {
                        showMessageOnSuccess(t('success'), t('alarmManagement:toasts.notificationActionEnabled'));
                        setSelectedNotificationActions([]);
                        setReloadNotificationActions(true);
                    }
                });
            },
            translation: t
        });
    };
    const _deactivateNotificationAction = (data: any[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('alarmManagement:dialogs.deactivateNotificationActionState'),
            header: t('confirmation'),
            onConfirm: () => {
                const aids: string[] = [];
                const nids: string[] = [];
                data.forEach((action: any) => {
                    aids.push(action.action_id);
                    nids.push(action.notification_id);
                });
                deactivateNotificationActionState(props.projectId, nids, aids).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), result.error);
                    } else {
                        showMessageOnSuccess(t('success'), t('alarmManagement:toasts.notificationActionDisabled'));
                        setSelectedNotificationActions([]);
                        setReloadNotificationActions(true);
                    }
                });
            },
            translation: t
        });
    };

    const _deleteNotificationRanges = (data: NotificationRange[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('alarmManagement:dialogs.deleteNotificationRangeDialog.message'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const n: string[] = [];
                data.forEach((range: NotificationRange) => {
                    n.push(range.id);
                });
                deleteNotificationRange(props.projectId, n).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), result.error);
                    } else {
                        showMessageOnSuccess(t('success'), t('alarmManagement:toasts.characteristicMonitoringTaskDeleted'));
                        setSelectedNotificationRanges([]);
                        setReloadNotificationRanges(true);
                    }
                });
            }
        });
    };

    const _deleteCharacteristicMonitoringTasks = (data: CharacteristicMonitoringTask[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('alarmManagement:dialogs.deleteCharacteristicMonitoringTasks.message'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const n: string[] = [];
                data.forEach((task: CharacteristicMonitoringTask) => {
                    n.push(task.id);
                });
                showWaitAnimation();
                deleteCharacteristicMonitoringTasks(props.projectId, n).then(result => {
                    hideWaitAnimation();
                    if (result.error) {
                        showMessageOnError(t('error'), result.error);
                    } else {
                        showMessageOnSuccess(t('success'), t('alarmManagement:toasts.characteristicMonitoringTaskDeleted'));
                        setSelectedCharacteristicMonitoringTasks([]);
                        setReloadCharacteristicMonitoringTasks(true);
                    }
                });
            }
        });
    };
    const _activateCharacteristicMonitoringTasks = (data: CharacteristicMonitoringTask[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('alarmManagement:dialogs.activateCharacteristicMonitoringTasksState'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const n: string[] = [];
                data.forEach((cmt: CharacteristicMonitoringTask) => {
                    n.push(cmt.id);
                });
                activateCharacteristicMonitoringTasksState(props.projectId, n).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('apiErrors:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('alarmManagement:toasts.characteristicMonitoringTaskEnabled'));
                        setSelectedCharacteristicMonitoringTasks([]);
                        setReloadCharacteristicMonitoringTasks(true);
                    }
                });
            }
        });
    };

    const _deactivateCharacteristicMonitoringTasks = (data: CharacteristicMonitoringTask[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('alarmManagement:dialogs.activateCharacteristicMonitoringTasksState'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const ids: string[] = [];
                data.forEach((cmt: CharacteristicMonitoringTask) => {
                    ids.push(cmt.id);
                });
                deactivateCharacteristicMonitoringTasksState(props.projectId, ids).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t(result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('alarmManagement:toasts.characteristicMonitoringTaskDisabled'));
                        setSelectedCharacteristicMonitoringTasks([]);
                        setReloadCharacteristicMonitoringTasks(true);
                    }
                });
            }
        });
    };

    const _deleteMonitoringTasks = (data: MonitoringTask[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('alarmManagement:dialogs.deleteMonitoringTasks.message'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const n: string[] = [];
                data.forEach((task: MonitoringTask) => {
                    n.push(task.monitoring_task_id);
                });
                showWaitAnimation();
                deleteMonitoringTasks(props.projectId, n).then(result => {
                    hideWaitAnimation();
                    if (result.error) {
                        showMessageOnError(t('error'), result.error);
                    } else {
                        showMessageOnSuccess(t('success'), t('alarmManagement:toasts.monitoringTaskDeleted'));
                        setSelectedMonitoringTasks([]);
                        setReloadMonitoringTasks(true);
                    }
                });
            }
        });
    };
    const _activateMonitoringTasks = (data: MonitoringTask[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('alarmManagement:dialogs.activateMonitoringTasksState'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const n: string[] = [];
                data.forEach((mt: MonitoringTask) => {
                    n.push(mt.monitoring_task_id);
                });
                activateMonitoringTasksState(props.projectId, n).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('apiErrors:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('alarmManagement:toasts.monitoringTaskEnabled'));
                        setSelectedMonitoringTasks([]);
                        setReloadMonitoringTasks(true);
                    }
                });
            }
        });
    };
    const _deactivateMonitoringTasks = (data: MonitoringTask[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('alarmManagement:dialogs.deactivateMonitoringTasksState'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const ids: string[] = [];
                data.forEach((mt: MonitoringTask) => {
                    ids.push(mt.monitoring_task_id);
                });
                deactivateMonitoringTasksState(props.projectId, ids).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t(result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('alarmManagement:toasts.monitoringTaskDisabled'));
                        setSelectedMonitoringTasks([]);
                        setReloadMonitoringTasks(true);
                    }
                });
            }
        });
    };


    // Prüft ob der notification_type aller ausgewählten notifications identisch ist
    const _typesOfSelectedNotificationsAreEqual = () => {
        if (selectedNotifications.length === 0)
            return false;
        const firstNotification = selectedNotifications[0];
        for (const notification of selectedNotifications) {
            if (notification.notification_type_id !== firstNotification.notification_type_id) {
                return false;
            }
        }
        // when we reach this line all notification types are equal
        return true;
    };

    const menuItems = [
        {
            id: 'characteristicMonitoringTasks',
            label: t('alarmManagement:global.characteristicMonitoringTasks'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faArrowsToEye}/>,
            component: <CharacteristicMonitoringTasksOverview
                projectId={props.projectId}
                type={CharacteristicMonitoringTaskOverviewType.Default}
                reload={reloadCharacteristicMonitoringTasks}
                setReload={setReloadCharacteristicMonitoringTasks}
                selectedCharacteristicMonitoringTasks={selectedCharacteristicMonitoringTasks}
                setSelectedCharacteristicMonitoringTasks={setSelectedCharacteristicMonitoringTasks}
                editCharacteristicMonitoringTask={editCharacteristicMonitoringTask}
                setEditCharacteristicMonitoringTask={setEditCharacteristicMonitoringTask}
                showHeader={false}
            />,
            visible: (props.projectId !== null && checkProjectType(ProjectType.ConditionMonitoring) && checkPermission(permissions.seeNotification)),
            buttons: (
                <ViewButtons
                    title={t('')}
                    items={checkPermission(permissions.editNotification) ? [
                        makeButtonItem(buttonDeleteCharacteristicMonitoringTasks, t,
                            () => _deleteCharacteristicMonitoringTasks(selectedCharacteristicMonitoringTasks),
                            checkPermission(permissions.editNotification),
                            selectedCharacteristicMonitoringTasks.length < 1),
                        makeButtonItem(buttonCreateCharacteristicMonitoringTask, t,
                            () => {
                                setSelectedCharacteristicMonitoringTasks([]);
                                setEditCharacteristicMonitoringTask(true);
                            },
                            checkPermission(permissions.editNotification)),
                        makeButtonItem(buttonActivateCharacteristicMonitoringTasks, t,
                            () => _activateCharacteristicMonitoringTasks(selectedCharacteristicMonitoringTasks),
                            checkPermission(permissions.editNotification),
                            selectedCharacteristicMonitoringTasks.length < 1),
                        makeButtonItem(buttonDeactivateCharacteristicMonitoringTasks, t,
                            () => _deactivateCharacteristicMonitoringTasks(selectedCharacteristicMonitoringTasks),
                            checkPermission(permissions.editNotification),
                            selectedCharacteristicMonitoringTasks.length < 1),
                    ] : []} rerender={viewButtonsRerender}/>
            )
        },
        {
            id: 'monitoringTasks',
            label: t('alarmManagement:global.monitoringTasks'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faArrowsLeftRightToLine}/>,
            component: <MonitoringTasksOverview
                projectId={props.projectId}
                type={MonitoringTaskOverviewType.Default}
                reload={reloadMonitoringTasks}
                setReload={setReloadMonitoringTasks}
                selectedMonitoringTasks={selectedMonitoringTasks}
                setSelectedMonitoringTasks={setSelectedMonitoringTasks}
                editMonitoringTask={editMonitoringTask}
                setEditMonitoringTask={setEditMonitoringTask}
                showHeader={false}
            />,
            visible: (props.projectId !== null && checkProjectType(ProjectType.ConditionMonitoring) && checkPermission(permissions.seeNotification)),
            buttons: (
                <ViewButtons
                    title={t('')}
                    items={checkPermission(permissions.editNotification) ? [
                        makeButtonItem(buttonDeleteMonitoringTasks, t,
                            () => _deleteMonitoringTasks(selectedMonitoringTasks),
                            checkPermission(permissions.editNotification),
                            selectedMonitoringTasks.length < 1),
                        makeButtonItem(buttonCreateMonitoringTask, t,
                            () => {
                                setSelectedMonitoringTasks([]);
                                setEditMonitoringTask(true);
                            },
                            checkPermission(permissions.editNotification)),
                        makeButtonItem(buttonActivateMonitoringTasks, t,
                            () => _activateMonitoringTasks(selectedMonitoringTasks),
                            checkPermission(permissions.editNotification),
                            selectedMonitoringTasks.length < 1),
                        makeButtonItem(buttonDeactivateMonitoringTasks, t,
                            () => _deactivateMonitoringTasks(selectedMonitoringTasks),
                            checkPermission(permissions.editNotification),
                            selectedMonitoringTasks.length < 1),
                    ] : []} rerender={viewButtonsRerender}/>
            )
        },
        {
            id: 'notifications',
            label: t('alarmManagement:global.notifications'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faBell}/>,
            component: <NotificationsOverview
                projectId={props.projectId}
                selectedNotifications={selectedNotifications}
                setSelectedNotifications={setSelectedNotifications}
                reloadNotifications={reloadNotifications}
                setReloadNotifications={setReloadNotifications}
                editNotification={editNotification}
                setEditNotification={setEditNotification}
                notificationWizard={notificationWizard}
                setNotificationWizard={setNotificationWizard}
            />,
            visible: props.projectId !== null && checkPermission(permissions.seeNotification),
            buttons: (
                <ViewButtons
                    title={t('')}
                    items={checkPermission(permissions.editNotification) ? [
                        makeButtonItem(buttonNotificationWizard, t,
                            () => {
                                setSelectedNotifications([]);
                                setNotificationWizard(true);
                            },
                            checkPermission(permissions.editNotification)),
                        makeButtonItem(buttonCreateNotification, t,
                            () => {
                                setSelectedNotifications([]);
                                setEditNotification(true);
                            },
                            checkPermission(permissions.editNotification)),
                        // makeButtonItem(buttonCreateNotificationRange, t,
                        //     () => {
                        //         setNotificationRangeDialogType(Type.Single);
                        //         setSelectedNotificationRanges([]);
                        //         setEditNotificationRange(true);
                        //     },
                        //     checkPermission(permissions.editNotification) &&
                        //     checkProjectType(ProjectType.GeoMonitoring)
                        // ),
                        makeButtonItem(buttonDeleteNotification, t,
                            () => _deleteNotification(selectedNotifications),
                            checkPermission(permissions.editNotification),
                            selectedNotifications.length < 1),
                        makeButtonItem(buttonActivateNotification, t,
                            () => _activateNotification(selectedNotifications),
                            checkPermission(permissions.editNotification),
                            selectedNotifications.length < 1),
                        makeButtonItem(buttonDeactivateNotification, t,
                            () => _deactivateNotification(selectedNotifications),
                            checkPermission(permissions.editNotification),
                            selectedNotifications.length < 1),
                        makeButtonItem(buttonAddActionsToNotifications, t,
                            () => setAddOrRemoveActions(1),
                            checkPermission(permissions.editNotification),
                            selectedNotifications.length < 1 || !_typesOfSelectedNotificationsAreEqual()),
                        makeButtonItem(buttonRemoveActionsFromNotifications, t,
                            () => setAddOrRemoveActions(-1),
                            checkPermission(permissions.editNotification),
                            selectedNotifications.length < 1),
                    ] : []}
                    rerender={viewButtonsRerender}/>
            )
        },
        {
            id: 'notificationRanges',
            label: t('alarmManagement:global.notificationRanges'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faArrowsLeftRightToLine}/>,
            component: <NotificationRangesOverview
                projectId={props.projectId}
                reloadNotificationRanges={reloadNotificationRanges}
                setReloadNotificationRanges={setReloadNotificationRanges}
                selectedNotificationRanges={selectedNotificationRanges}
                setSelectedNotificationRanges={setSelectedNotificationRanges}
                editNotificationRange={editNotificationRange}
                setEditNotificationRange={setEditNotificationRange}
                notificationRangeDialogType={notificationRangeDialogType}
                setNotificationRangeDialogType={setNotificationRangeDialogType}
            />,
            visible: (props.projectId !== null && checkProjectType(ProjectType.GeoMonitoring) && checkPermission(permissions.seeNotification)),
            buttons: (
                <ViewButtons
                    title={t('')}
                    items={checkPermission(permissions.editNotification) ? [
                        makeButtonItem(buttonCreateNotificationRange, t,
                            () => {
                                setNotificationRangeDialogType(Type.Single);
                                setSelectedNotificationRanges([]);
                                setEditNotificationRange(true);
                            },
                            checkPermission(permissions.editNotification)
                        ),
                        makeButtonItem(buttonUpdateNotificationRanges, t,
                            () => {
                                setNotificationRangeDialogType(Type.Multiple);
                                setEditNotificationRange(true);
                            },
                            checkPermission(permissions.editNotification),
                            selectedNotificationRanges.length < 1
                        ),
                        makeButtonItem(buttonDeleteNotificationRange, t,
                            () => _deleteNotificationRanges(selectedNotificationRanges),
                            checkPermission(permissions.editNotification),
                            selectedNotificationRanges.length < 1
                        )
                    ] : []} rerender={viewButtonsRerender}/>
            )
        },
        {
            id: 'notificationsActionsMap',
            label: t('alarmManagement:global.notificationActions'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faSitemap}/>,
            component: <NotificationsActionsMapOverview
                projectId={props.projectId}
                selectedNotificationActions={selectedNotificationActions}
                setSelectedNotificationActions={setSelectedNotificationActions}
                reloadNotificationActions={reloadNotificationActions}
                setReloadNotificationActions={setReloadNotificationActions}
                editNotificationAction={editNotificationAction}
                setEditNotificationAction={setEditNotificationAction}
                disableNotificationActionTemporary={disableNotificationActionTemporary}
                setDisableNotificationActionTemporary={setDisableNotificationActionTemporary}
            />,
            visible: props.projectId !== null && checkPermission(permissions.seeNotification),
            buttons: (
                <ViewButtons
                    title={t('')}
                    items={checkPermission(permissions.editNotification) ? [
                        makeButtonItem(buttonCreateNotificationAction, t,
                            () => {
                                setSelectedNotificationActions([]);
                                setEditNotificationAction(true);
                            },
                            checkPermission(permissions.editNotification)
                        ),
                        makeButtonItem(buttonActivateNotificationAction, t,
                            () => _activateNotificationAction(selectedNotificationActions),
                            checkPermission(permissions.editNotification),
                            (selectedNotificationActions.length < 1)
                        ),
                        makeButtonItem(buttonDeactivateNotificationAction, t,
                            () => _deactivateNotificationAction(selectedNotificationActions),
                            checkPermission(permissions.editNotification),
                            (selectedNotificationActions.length < 1)
                        ),
                        makeButtonItem(buttonDeactivateNotificationActionTemporary, t,
                            () => setDisableNotificationActionTemporary(true),
                            checkPermission(permissions.editNotification),
                            (selectedNotificationActions.length < 1)
                        ),
                        makeButtonItem(buttonDeleteNotificationAction, t,
                            () => _deleteNotificationAction(selectedNotificationActions),
                            checkPermission(permissions.editNotification),
                            (selectedNotificationActions.length < 1)
                        ),
                    ] : []} rerender={viewButtonsRerender}/>
            )
        },
        {
            id: 'notificationsTemplates',
            label: t('alarmManagement:global.notificationTemplates'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faEnvelopeOpenText}/>,
            component: <NotificationTemplatesOverview
                projectId={props.projectId}
                reloadTemplates={reloadNotificationTemplates}
                setReloadTemplates={setReloadNotificationTemplates}
                selectedTemplate={selectedTemplate}
                setSelectTemplate={setSelectedTemplate}
                editTemplate={(data: any) => {
                    setEditTemplate(true);
                    setSelectedTemplate(data);
                }}
            />,
            visible: props.projectId !== null && checkPermission(permissions.editNotificationTemplates),
            buttons: (
                <ViewButtons title={t('')} items={[]}/>
            )
        },
        {
            id: 'historicalAlarms',
            label: t('alarmManagement:global.historicalAlarms'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faRadiationAlt}/>,
            component: <HistoricalAlarms projectId={props.projectId}/>,
            visible: (props.projectId !== null && checkProjectType(ProjectType.GeoMonitoring) && checkPermission(permissions.seeReports))
        }
    ];

    return (
        <>
            <LayoutMainContentTabMenu
                items={menuItems}
                // Bei GeoMonitoring-Projekten werden die ersten beiden Tabs
                // (MonitoringTasks und CharacteristicMonitoringTasks) übersprungen
                activeIndex={checkProjectType(ProjectType.GeoMonitoring) ? 2 : 0}
                onTabChange={(data: any) => {
                    setViewButtonsRerender(!viewButtonsRerender);
                    switch (data.index) {
                        case 0:
                            setReloadNotifications(true);
                            break;
                        case 1:
                            setReloadNotificationRanges(true);
                            break;
                        case 2:
                            setReloadNotificationActions(true);
                            break;
                        case 3:
                            break;
                    }
                }}
            />
            {
                <CustomDialog
                    header={selectedTemplate
                        ? t('alarmManagement:dialogs.editNotificationTemplateDialog.header')
                        : t('alarmManagement:dialogs.createNotificationTemplateDialog.header')}
                    headerType={selectedTemplate ? CustomDialogHeaderType.Update : CustomDialogHeaderType.Create}
                    className={'h-full w-10'}
                    onCancel={() => setEditTemplate(false)}
                    formName={'notificationTemplateEditFormDialog'}
                    visible={editTemplate}
                    onHide={() => setEditTemplate(false)}
                >
                    <NotificationTemplateEditForm
                        projectId={props.projectId}
                        onFinished={(success) => {
                            setEditTemplate(false);
                            if (success) setReloadNotificationTemplates(true);
                        }}
                        templateData={selectedTemplate}
                        setVisible={setEditTemplate}
                    />
                </CustomDialog>
            }
            {
                <CustomDialog
                    header={addOrRemoveActions > 0
                        ? t('alarmManagement:dialogs.addNotificationActionsDialog.header')
                        : t('alarmManagement:dialogs.removeNotificationActionsDialog.header')}
                    headerType={addOrRemoveActions > 0 ? CustomDialogHeaderType.Add : CustomDialogHeaderType.Remove}
                    formName={'formDialog'}
                    maximized={false}
                    visible={addOrRemoveActions !== 0}
                    onHide={() => {
                        setAddOrRemoveActions(0);
                    }}
                    onCancel={() => {
                        setAddOrRemoveActions(0);
                    }}
                >
                    <NotificationAddRemoveActionsForm
                        type={addOrRemoveActions > 0 ? AddOrRemoveType.Add : AddOrRemoveType.Remove}
                        selectedNotifications={selectedNotifications}
                        projectId={props.projectId}
                        onFinished={() => {
                            setAddOrRemoveActions(0);
                            setSelectedNotifications([]);
                        }}
                    />
                </CustomDialog>
            }

        </>
    );
};

export default NotificationManagement;
