import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {Toast} from 'primereact/toast';
import {createProjectGroup, deleteProjectGroup, updateProjectGroup} from '../functions/Project';
import CustomChip from '../global/CustomChip';
import {Chip} from 'primereact/chip';
import {classNames} from 'primereact/utils';


type Props = {
    projectId: any,
    groups: any,
    mayEditProject?: boolean,
    onChange?: (changes: any[]) => any,
    className?: string
}

export const ProjectGroupOverview = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);
    const toast = useRef<any>();
    const [count, setCount] = useState<number>(0);
    const [list, setList] = useState<any>([]);
    const minItems = 1;
    const [created, setCreated] = useState(false);
    const [changedIds, setChangedIds] = useState<any[]>([]);
    const [sensorInfo, setSensorInfo] = useState<any>([]);

    // useEffect(() => {
    //     if (deleteId !== '') {
    //         CustomConfirmDialog({
    //             translation: t,
    //             header: t('project:deleteGroup.header'),
    //             message: t('project:deleteGroup.message'),
    //             onConfirm: () => {
    //                 deleteProjectGroup(props.projectId, deleteId).then(result => {
    //                     if (result.error) {
    //                         showMessageOnError(t('error'), result.error.current);
    //                     } else {
    //                         showMessageOnSuccess(t('success'), t('project:groupDeleted').current);
    //                     }
    //                     setDeleteId('');
    //                 })
    //             },
    //             onCancel: () => {
    //                 setDeleteId('');
    //             }
    //         })
    //     }
    // }, [deleteId]);

    // useEffect(() => {
    //     getSensorInfo(props.projectId).then(result => {
    //         setSensorInfo(result);
    //     })
    // }, []);

    useEffect(() => {
        setCreated(false);
        const tmp: any = [];
        let cnt: number = 0;

        let groups = Array.from(props.groups);
        const first = groups.filter((x: any) => {
            return x.id === props.projectId;
        });
        groups = groups.filter((x: any) => {
            return x.id !== props.projectId;
        });
        groups = [first[0], ...groups];

        if (!props.mayEditProject) {
            // Wenn der Benutzer das Projekt nicht editieren darf, sieht er normale Chips
            groups.forEach((key: any) => {
                const chip = (<Chip label={key.name} className={'m-1'}/>);
                tmp.push(chip);
                cnt++;
            });
        } else {
            groups.forEach((key: any) => {
                const hasSensor = key.location_count > 0;

                const removable = (key.id !== props.projectId && !hasSensor && props.mayEditProject) || false;
                const editable = (key.id !== props.projectId && props.mayEditProject) || false;

                const deletedId = changedIds.filter(x => {
                    return x.id === key.id && x.type === 'DELETE';
                });

                const chip =
                    (<CustomChip
                        className="inline-flex m-2" label={key.name} deletable={removable}
                        editable={true} startInEditMode={key.id === 'new'}
                        key={key.id}
                        deleted={deletedId.length > 0}
                        icon="pi pi-flag"
                        onCreate={(label: string) => {
                            console.log('chip created');
                            createProjectGroup(props.projectId, label).then(result => {
                                key.id = result.id;
                                setCreated(true);
                            });
                        }}

                        onChange={(label: string) => {
                            let changes = Array.from(changedIds);
                            changes = changes.filter(x => {
                                return (x.id !== key.id && x.type === 'UPDATE') || x.type === 'DELETE';
                            });

                            changes.push({id: key.id, label: label, type: 'UPDATE'});
                            setChangedIds(changes);
                        }}

                        onDelete={() => {
                            const changes = Array.from(changedIds);
                            changes.push({id: key.id, type: 'DELETE'});
                            setChangedIds(changes);
                        }}

                        onUndo={() => {
                            let changes = Array.from(changedIds);
                            changes = changes.filter(x => {
                                return x.id !== key.id && x.type === 'DELETE';
                            });
                            setChangedIds(changes);
                        }}
                    />);
                tmp.push(chip);
                cnt++;
            });
        }

        if (cnt > minItems) {
            setList(tmp);
        }

        setCount(cnt);
    }, [props.groups.length, created, changedIds, props.mayEditProject]);

    useEffect(() => {
        if (props.onChange) {
            props.onChange(changedIds);
        }
    }, [changedIds]);

    return (
        <div className={classNames('project-group-overview', props.className)}>{count > minItems &&
        <h4>{t('projectManagement:attributes.projectGroups')}</h4>}
            <div className={'project-group-list'}>
                {list}
            </div>
        </div>
    );

};
