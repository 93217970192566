import {confirmDialog} from 'primereact/confirmdialog';

type Props = {
    message: string,
    header: string,
    onConfirm: any,
    onCancel?: any,
    translation: any,
}
export const CustomConfirmDialog = (props:Props) =>{
    return confirmDialog({
        message:props.message,
        header:props.header,
        icon: 'pi pi-info-circle',
        acceptLabel: props.translation('yes'),
        rejectLabel: props.translation('no'),
        accept: props.onConfirm,
        reject: props.onCancel? props.onCancel : () =>{}
    });
};
