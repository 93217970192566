import React, {useEffect, useRef, useState} from 'react';
import {Button} from 'primereact/button';
import {useTranslation} from 'react-i18next';
import '../../style/file-management.scss';
import {Image} from 'primereact/image';
import {CustomConfirmDialog} from './CustomConfirmDialog';
import {deleteFile, getFiles, insertFile} from '../functions/Global';
import {showMessageOnError, showMessageOnSuccess} from './CustomToast';
import {hideWaitAnimation, showWaitAnimation} from './CustomWaitAnimation';

type Nullable<T> = T | null;

type Props = {
    project_id: Nullable<string>,
    maxSize?: number
    onSelect?: (id: string, sizeInBytes: number) => void,
}

type File = {
    id: string,
    name: string,
    type_id: number,
    type_name: string,
    project_id: string,
    data: string,
    size: number
}

const FileManagement = (props: Props) => {

    const inputFile: any = useRef(null);
    const {t} = useTranslation(['common']);

    const [files, setFiles] = useState<File[]>([]);
    const [selectedContent, setSelectedContent] = useState<string>('');

    useEffect(() => {
        _getFiles();
    }, []);

    const _getFiles = () => {
        showWaitAnimation();
        getFiles(props.project_id).then(result => {

            for (let j = 0; j < result.length; j++) {
                let file = result[j];

                file.size = base64ToBytes(file.data);
                console.log(file.size);
            }

            setFiles(result);
            hideWaitAnimation();
        });
    };

    const base64ToBytes = (base64: string) => {
        return 4 * Math.ceil((base64.length / 3)) * 0.5624896334383812;
    };

    const getSizeString = (bytes: number) => {
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(1024));

        return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
    };

    const chooseFile = (e: any) => {
        if (inputFile.current !== null) {
            inputFile.current.click();
        }
    };

    const getBase64 = (file: any, cb: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    const uploadFile = (e: any) => {

        if (e.target.files[0].size > 1000000) {
            showMessageOnError(t('error'), t('errorMessages:fileExceed1MbLimitError'));
        } else {
            getBase64(e.target.files[0], (result: any) => {
                const arr = result.split('base64,');
                if (arr.length === 2) {

                    const split = e.target.files[0].name.split('.');
                    const extension = split[split.length - 1].toLowerCase();
                    let file_type_id = 0;

                    switch (extension) {
                        case 'png':
                            file_type_id = 0;
                            break;

                        case 'jpg':
                        case 'jpeg':
                            file_type_id = 1;
                            break;
                    }


                    const body = {
                        project_id: props.project_id,
                        file_type_id: file_type_id,
                        data: arr[1],
                        name: e.target.files[0].name
                    };

                    showWaitAnimation();
                    insertFile(body).then(result => {
                        hideWaitAnimation();
                        if (result.error) {
                            showMessageOnError(t('error'), t(result.error));
                        } else {
                            showMessageOnSuccess(t('success'), t('projectManagement:toasts.fileUploaded'));
                            _getFiles();
                        }
                    });
                }
            });
        }
    };

    const showFile = (file: File) => {
        let content;

        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(file.size) / Math.log(1024));

        const sizeStr = `${parseFloat((file.size / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;

        switch (file.type_name.toLowerCase()) {
            case 'png':
            case 'jpeg':
            case 'jpg':
                content = (
                    <Image
                        className="fm-image" src={'data:image/' + file.type_name + ';base64, ' + file.data}
                        preview width="80"
                    />
                );
                break;
        }

        return (
            <div className="col-auto">
                <div className={'m-3 p-1 fm-content' + (selectedContent === file.id ? ' selected' : '')}
                     onClick={() => {
                         setSelectedContent(file.id);
                         if (props.onSelect) {
                             props.onSelect(file.id, file.size);
                         }
                     }}>
                    <div className="mt-2">{content}</div>
                    <div className="fm-filename">{file.name}</div>
                    <div>{'(' + sizeStr + ')'}</div>
                    <Button icon="pi pi-times"
                            className="p-button-rounded p-button-danger p-button-text fm-delete-button"
                            aria-label="Delete" onClick={() => {
                        CustomConfirmDialog({
                            message: t('projectManagement:dialogs.deleteFile.message'),
                            header: t('confirmation'),
                            translation: t,
                            onConfirm: () => {
                                deleteFile(props.project_id, file.id).then(result => {
                                    if (result.error) {
                                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                                    } else {
                                        showMessageOnSuccess(t('success'), t('projectManagement:toasts.fileDeleted'));
                                        _getFiles();
                                    }
                                });
                            }
                        });
                    }}
                    />
                </div>
            </div>
        );
    };

    return (
        <div className="fm grid grid-nogutter mt-3">
            {props.maxSize && <div>{getSizeString(props.maxSize)}</div>}
            <div className="grid grid-nogutter col fm-body">
                {files && files.map((file) => {
                    return showFile(file);
                })}
            </div>
            <div className="fm-button-controls p-2">
                <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    accept=".png, .jpeg, .jpg"
                    onInput={uploadFile}
                    style={{display: 'none'}}
                />
                <Button
                    className="p-button p-button-outlined" style={{backgroundColor: 'white'}}
                    icon="pi pi-plus" onClick={chooseFile}
                />
            </div>
        </div>
    );
};

export default FileManagement;
