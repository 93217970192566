/*
 * NotificationWizardOptionForm.tsx
 * Author: lnappenfeld
 * Date: 08.12.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */


import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import Input from '../global/Input';
import {
    createNotificationAction,
    getActions,
    getNotifications,
    getNotificationsActions, getNotificationTemplates,
    NotificationActionMap, updateNotificationAction
} from '../functions/Notification';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {Nullable} from '../functions/Global';

type Data = {
    value: string,
    label: string,
    actionTypeId: number,
    notificationTypeId: number
}

type Props = {
    templates: Data[];
    notificationTypeId: Nullable<number>,
    data: Nullable<any>,
    setData: (data: Nullable<any>) => void,
    onFinished: (success: boolean) => void
}

const NotificationWizardOptionForm = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    const [smsTemplates, setSmsTemplates] = useState<Data[]>([]);
    const [emailTemplates, setEmailTemplates] = useState<Data[]>([]);

    const options = [
        {value: '0', label: t('alarmManagement:attributes.creationTypeOption1')},
        {value: '1', label: t('alarmManagement:attributes.creationTypeOption2')},
        {value: '2', label: t('alarmManagement:attributes.creationTypeOption3')}
    ];

    const defaultValues = {
        'template_email_id': props.data === null ? null : props.data.template_email_id,
        'template_sms_id': props.data === null ? null : props.data.template_sms_id,
        'notification_create_type_id': props.data === null ? '0' : props.data.notification_create_type_id.toString(),
    };

    const {control, formState: {errors}, trigger, getValues, setValue} = useForm({defaultValues});

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    useEffect(() => {
        const SMSTemplates = props.templates.filter((ele: Data) => {
            return ele.actionTypeId === 0 && props.notificationTypeId === ele.notificationTypeId;
        });
        setSmsTemplates(SMSTemplates);
        if (SMSTemplates.length === 1) {
            setValue('template_sms_id', SMSTemplates[0].value);
        }

        const EMAILTemplates = props.templates.filter((ele: Data) => {
            return ele.actionTypeId === 1 && props.notificationTypeId === ele.notificationTypeId;
        });
        setEmailTemplates(EMAILTemplates);
        if (EMAILTemplates.length === 1) {
            setValue('template_email_id', EMAILTemplates[0].value);
        }

        // set initial values in parent component
        updateData();
    }, []);

    // Für den NotificationWizard: wenn man zwischen den Schritten hin und her springt müssen die Daten gespeichert werden
    const updateData = () => {
        const data = {
            'template_email_id': getValues('template_email_id'),
            'template_sms_id': getValues('template_sms_id'),
            'notification_create_type_id': parseInt(getValues('notification_create_type_id')),
        };

        if (props.setData) {
            props.setData(data);
        }
    };

    return (
        <form id='formDialog' onSubmit={(event) => {
            trigger().then((success) => {
                event.preventDefault();
                props.onFinished(success);
            });
        }}>
            <div className={'card'}>
                <Input
                    name={'template_email_id'}
                    scrollHeight={'450px'}
                    edit={true}
                    dropdownOptions={emailTemplates}
                    type={'dropdown'}
                    label={t('alarmManagement:attributes.emailTemplate')}
                    onChange={e => {updateData();}}
                    validationControl={control}
                    validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: t('alarmManagement:attributes.emailTemplate') + ' ' + t('input:required')}}
                />
                <Input
                    name={'template_sms_id'}
                    scrollHeight={'450px'}
                    edit={true}
                    dropdownOptions={smsTemplates}
                    type={'dropdown'}
                    label={t('alarmManagement:attributes.smsTemplate')}
                    onChange={e => {updateData();}}
                    validationControl={control}
                    validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: t('alarmManagement:attributes.smsTemplate') + ' ' + t('input:required')}}
                />
            </div>
            <div className={'card'}>
                <Input
                    name={'notification_create_type_id'}
                    scrollHeight={'450px'}
                    edit={true}
                    dropdownOptions={options}
                    type={'dropdown'}
                    label={t('alarmManagement:attributes.creationType')}
                    onChange={e => {updateData();}}
                    validationControl={control}
                    validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: t('alarmManagement:attributes.creationType') + ' ' + t('input:required')}}
                />
            </div>
        </form>
    );

};

export default NotificationWizardOptionForm;
