import React, {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import ConfigurationDialogue from './ConfigurationDialogue';
import {checkPermission} from '../../../functions/functionLibrary';
import {permissions} from '../../../config/permissions';
import {
    getDoppelfilterDetails,
    getMappedSVGDiagram,
    getOperationalData,
    getBoxValueData,
    getStatusColor, getMeasurePointValues
} from '../functions/DoppelfilterConfigurations';
import {Buffer} from 'buffer';
import SVGComponent from './SVGComponent';
import {hideWaitAnimation, showWaitAnimation} from '../../global/CustomWaitAnimation';

const A = styled.a`
  cursor: pointer;
`;

export type FilterProps = {
    projectId: string
    rerenderGraph: (measurePoints: any[]) => Promise<void>;
    colorTheme: string;
   measurePoints: string[];
};

function Filter({
    projectId,
    rerenderGraph,
    colorTheme,
    measurePoints
}: FilterProps) {
    const [renderDetails, setRenderDetails] = useState<boolean>(false);
    const [svgFile, setSvgFile] = useState<any>('');
    const [showSvg, setShowSvg] = useState<boolean>(true);
    const [iconName, setIconName] = useState<string>('');
    const [updateValues, setUpdateValues] = useState<number>(0);
    const [values, setValues] = useState<any>([]);

    useEffect(()=>{
        if (checkPermission(permissions.editMetrology)) {
            setIconName('pi pi-pencil');
        }
        else {
            setIconName('pi pi-eye');
        }

        const url = location.hash.split('_');
        const monitoringAppId: any = localStorage.getItem('monitoringAppId') ? localStorage.getItem('monitoringAppId') : '';
        const monitoringAppTypeId: any = localStorage.getItem('monitoringAppTypeId') ? localStorage.getItem('monitoringAppTypeId') : '';
        getMappedSVGDiagram(projectId, url[1], monitoringAppTypeId ).then(async resp=>{
            if (resp.length > 0 && resp[0]['schematic_illustration_type_id'] !== null) {
                showWaitAnimation();
                let tagArr: any = [];
                const res = await getDoppelfilterDetails(projectId, monitoringAppId, monitoringAppTypeId, resp[0]['schematic_illustration_type_id'], url[1]);
                const channelIds: any = [];
                res.map((val: any)=>{
                    if (val['channel_id'] !== null) {
                        channelIds.push(val['channel_id']);
                    }
                });
                const data: any = {
                    'project_id': projectId,
                    'monitoring_app_id': monitoringAppId,
                    'channel_ids': channelIds
                };
                const statusColor = await getStatusColor(data);
                const boxData = await getBoxValueData(data);
                for (let i=1; i <=20; i++) {
                    if (statusColor['data'][i] !== undefined && boxData['data'][i] !== undefined ) {
                        let color = '';
                        if (statusColor['data'][i]['status'] === 0) {
                            color = 'gray';
                        }
                        else if (statusColor['data'][i]['status'] === 1) {
                            color = 'green';
                        }
                        else if (statusColor['data'][i]['status'] === 2) {
                            color = 'yellow';
                        }
                        else if (statusColor['data'][i]['status'] === 3) {
                            color = 'red';
                        }
                        tagArr.push({
                            'boxId': Number(i),
                            'channelId': statusColor['data'][i]['channel_id'],
                            'upperText': statusColor['data'][i]['channel_name'].length > 10 ? statusColor['data'][i]['channel_name'].substr(0, 11) + '...' : statusColor['data'][i]['channel_name'],
                            'fullUpperText' : statusColor['data'][i]['channel_name'],
                            'lowerText': boxData['data'][i]['chart_round'] > 0 ? Number(boxData['data'][i]['value']).toFixed(boxData['data'][i]['chart_round']) : boxData['data'][i]['value'] ? boxData['data'][i]['value'] : '',
                            'unit': boxData['data'][i]['unit'] ? boxData['data'][i]['unit'] : '',
                            // 'boxColor': boxData['data'][i]['value'] && statusColor['data'][i]['channel_id'] !== '7f08a4f5-a02b-4844-b070-affcd6ccc09c' ? color : statusColor['data'][i]['channel_id'] === '7f08a4f5-a02b-4844-b070-affcd6ccc09c' ? 'blue' : color,
                            'boxColor': boxData['data'][i]['value'] && boxData['data'][i]['tag']!== 14 ? color : boxData['data'][i]['tag'] === 14 ? 'blue' : color,
                            'defaultColor' : color
                        });
                    }
                    else {
                        tagArr.push({
                            'boxId': Number(i),
                            'channelId': statusColor['data'][i] !== undefined ? statusColor['data'][i]['channel_id'] : '',
                            'upperText': statusColor['data'][i] !== undefined ? statusColor['data'][i]['channel_name'] : '',
                            'fullUpperText' : statusColor['data'][i] !== undefined ? statusColor['data'][i]['channel_name'] : '',
                            'lowerText':'',
                            'unit': '',
                            'boxColor': 'gray',
                            'defaultColor': 'gray'
                        });
                    }
                }
                tagArr = tagArr.sort((a:any,b:any) => a['boxId'] - b['boxId']);
                setSvgFile(setColor(Buffer.from(resp[0]['data']).toString()));
                setValues(tagArr);
                hideWaitAnimation();
            }
            

        });
    },[colorTheme, showSvg, updateValues]);

    const triggerPointOnGraph = (point: any) => {
        let indexFound: any;
        let isFound: boolean = false;
        measurePoints.map((val: any, index: number)=>{
            if (val['point'] === point['point']) {
                indexFound = index;
                isFound = true;
            }
        });
        if (isFound) {
            measurePoints.splice(indexFound, 1);

        }
        else measurePoints.push(point);
        rerenderGraph([...measurePoints]);
    };


    const setColor = (svgText: string) => {
        let tmp = svgText;
        if (svgText) {
            if (colorTheme === 'dark') {
                tmp = tmp.replaceAll('#000000', 'white');
            }
        }
        return tmp;
    };
    return (

        <div className="card-content information-card">
            <Button
                icon={iconName}
                className="p-button-rounded p-button-secondary p-button-text filter-icon"
                onClick={() => {
                    setRenderDetails(true);
                    setShowSvg(false);
                }}
            />
            <Button
                icon='pi pi-refresh'
                className="p-button-rounded p-button-secondary p-button-text filter-icon"
                onClick={() => {
                    setUpdateValues(updateValues + 1);
                }}
            />

            {renderDetails && <ConfigurationDialogue  projectId={projectId} colorTheme={colorTheme} modal={renderDetails} onHide={async ()=>{
                setRenderDetails(false);
                setShowSvg(true);
                // const getValues = await getMeasurePointValues(projectId, '0de0bcd4-76cb-46c0-a4ab-dc070732a577');
                const monitoringAppId: any = localStorage.getItem('monitoringAppId') ? localStorage.getItem('monitoringAppId') : '';
                const monitoringAppTypeId: any = localStorage.getItem('monitoringAppTypeId') ? localStorage.getItem('monitoringAppTypeId') : '';
                const url = location.hash.split('_');
                const imageDetails = await getDoppelfilterDetails(projectId, monitoringAppId, monitoringAppTypeId,  '1', url[1]);
                const graphData:any = [];
                imageDetails.map((val: any)=>{
                    if (val['number'] === 14) {
                        graphData.push(val);
                    }
                });
                if (graphData[0]['tag_name'] !== '---') {
                    rerenderGraph([{
                        'point': graphData[0]['tag_name'],
                        'pointId': graphData[0]['channel_id'],
                        'min': graphData[0]['chart_min'],
                        'max': graphData[0]['chart_max']}]);
                }
                else {
                    rerenderGraph([]);
                }

            }} /> }
            <div style={{ textAlign: 'center' }}>
                {showSvg &&
                    <SVGComponent divId={'svg-content'} svgImage={svgFile} 
                        elementClicked={async (index: number)=>{
                            const getValues = await getMeasurePointValues(projectId, values[index]['channelId']);
                            triggerPointOnGraph({
                                'point': values[index]['fullUpperText'],
                                'pointId' : values[index]['channelId'],
                                'min': getValues[0]['chart_min'],
                                'max': getValues[0]['chart_max']
                            });
                            if (values[index]['boxColor'] === 'green' || values[index]['boxColor'] === 'gray' || values[index]['boxColor'] === 'red' || values[index]['boxColor'] === 'yellow') {
                                values[index]['boxColor'] = 'blue';
                            } else if (values[index]['boxColor'] === 'blue') {
                                values[index]['boxColor'] = values[index]['defaultColor'];
                                // values[index]['boxColor'] = values[index]['lowerText'].toString().length > 0 ? 'green': 'gray';
                            }
                            setValues([...values]);
                        }} values={values}
                        onLastBoxClick={async ()=>{
                            if (values[19]['upperText'].length > 0) {
                                const getValues = await getMeasurePointValues(projectId, values[19]['channelId']);
                                triggerPointOnGraph({
                                    'point': values[19]['fullUpperText'],
                                    'pointId' : values[19]['channelId'],
                                    'min': getValues[0]['chart_min'],
                                    'max': getValues[0]['chart_max']
                                });
                                // triggerPointOnGraph({'point': values[19]['fullUpperText'], 'pointId' : values[19]['channelId']});
                                if (values[19]['boxColor'] === 'green' || values[19]['boxColor'] === 'gray') {
                                    values[19]['boxColor'] = 'blue';
                                }
                                else if (values[19]['boxColor'] === 'blue') {
                                    values[19]['boxColor'] = values[19]['defaultColor'];
                                    // values[19]['boxColor'] = values[19]['lowerText'].toString().length > 0 ? 'green': 'gray';
                                }
                                setValues([...values]);
                            }
                        }}
                    />}

            </div>
        </div>

    );
}

export default Filter;
