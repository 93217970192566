/*
 * CustomDataTable.tsx
 * Author: Frank Wunderlich
 * Date: 07.02.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useRef, useState} from 'react';
import {Checkbox} from 'primereact/checkbox';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {DataTable} from 'primereact/datatable';
import {Column, ColumnSortParams} from 'primereact/column';
import {Calendar} from 'primereact/calendar';
import {Button} from 'primereact/button';
import {useTranslation} from 'react-i18next';
import {Ripple} from 'primereact/ripple';
import {classNames} from 'primereact/utils';
import {MultiSelect, MultiSelectChangeParams} from 'primereact/multiselect';
import './../../style/override.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ProgressSpinner} from 'primereact/progressspinner';
import {getUserFromLocalStorage, Nullable, setUserSettings} from '../functions/Global';
import {faEye, faEyeSlash, faSlash} from '@fortawesome/free-solid-svg-icons';
import {faCircleCheck, faCircleXmark} from '@fortawesome/free-regular-svg-icons';
import {useParams} from 'react-router-dom';
import {Skeleton} from 'primereact/skeleton';
import {CustomConfirmDialog} from './CustomConfirmDialog';
import {convertLanguageCodeToLocale} from '../../functions/functionLibrary';

const moment = require('moment-timezone');
import 'moment/locale/es';

type Props = {
    id: string,
    columns: ColumnObject[],
    editable: boolean,
    sortable: boolean,
    value: any[], // data
    header?: string,
    headerTitle?: string,
    className?: string,
    maxCharacters?: number,
    selection?: any,
    selectionMode?: any,
    scrollHeight?: string,
    style?: any,
    tableId?: string,
    ableToDelete?: boolean,
    ableToUpdate?: boolean,
    customButtons?: CustomButton[],
    buttonToolTipEdit?: string,
    buttonToolTipDelete?: string,
    onEditClick?: (e: any) => void,
    onRemoveClick?: (e: any) => void,
    onRowEditComplete?: (e: any) => void,
    onRowClick?: (e: any) => void,
    onSelectionChange?: (e: any) => void,
    filters?: any,
    onFilterChanged?: (e: any) => void,
    showLoading?: boolean
    paginator?: boolean

    // Grouping
    rowGroupMode?: string,
    groupRowsBy?: string,
    expandable?: boolean,
    expandableRowGroups?: boolean, // Aufklappbar oder nicht
    expandedRows?: any,
    scrollToFirstExpaned?: boolean,
    rowGroupHeaderButtons?: CustomButton[],
    // expandedRows?: any, // alle aufgeklappten Gruppen
    // setExpandedRows?: (e: any) => any,
    onRowToggle?: (e: any) => any,
    onRowExpand?: (e: any) => any,
    onRowCollapse?: (e: any) => any,
    rowGroupHeaderTemplate?: (e: any) => any,
    rowGroupFooterTemplate?: (e: any) => any

    // Ordering
    reorderableRows?: boolean,
    onRowReorder?: (e: any) => any

    // Column Styling
    rowClass?: RowClass[],
    displayColumnFilter?: boolean,

    // Edit
    editMode?: string,

    // Filters
    filterOptionSelected?: (values: any) => void,
    filterOptionsField?: string
    selectedFilters?: (values: any) => void,
    hideSearch?: boolean,
    timerangefield?: boolean,
    onTimeRangeSelect?: (values: any) => void,
    showClearFiltersButton?: boolean,

    // Global Filter
    hideGlobalFilter?: boolean,

    // Skeleton Loading
    dataloaded?: boolean
    skeletonCount?: number,

    // Exporting
    showExportCSV?: boolean
}

export type ColumnObject = {
    field: string,
    header: string,
    type?: ColumnObjectType,
    options?: any[],
    body?: any,
    filter?: boolean,
    filterDropdownValues?: any[],
    filterDropdownField?: string,
    filterField?: string,
    filterElement?: (options: any) => void,
    showFilterMenu?: boolean,
    showFilterMatchModes?: boolean,
    style?: any,
    onClick?: (e: any) => void,
    rowOrder?: boolean,
    display?: boolean,
    editCell?: (e: any) => void,
    editCellClassName?: string,
    editCellStyle?: string,
    sortFunction?: (sort: ColumnSortParams) => void,
    dateFormat?: string     // Für Datumsspalten (type=4), kann bei Abweichung vom Default angegeben werden
    currency?: string       // Für Numberspalten (type=1), z.B. EUR, USD, ...
}


export type CustomButton = {
    // icon: any,
    onClick: (data: any) => void;
    tooltip?: string,
    tooltipOptions?: any,
    className?: string,
    icons: any[],
    permitted: boolean // permissions check
}

enum ColumnObjectType {
    Text,
    Number,
    Checkbox,
    Dropdown,
    Date,
    Buttons,
    Image,
    Boolean,
}

export type RowClass = {
    key: string,
    value: any,
    class: string,
}

const CustomDataTable = (props: Props): JSX.Element => {

    const tableRef = useRef<any>(null);

    const skeletonValues = [];
    const count = props.skeletonCount ? props.skeletonCount : 10;

    for (let i = 0; i < count; i++) {
        skeletonValues.push([]);
    }

    const params = useParams();

    const {t} = useTranslation(['common', 'customDataTable']);

    const user_string = localStorage.getItem('user');
    let uuf = false;
    if (user_string) {
        uuf = JSON.parse(user_string).is_saving_userfilters_enabled;
    }

    const [copy, setCopy] = useState<any[]>([]);
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [columnFilters, setColumnFilters] = useState<any>({});
    const [columnDisplayFilters, setColumnDisplayFilters] = useState<any>({});
    const [usedFilters, setUsedFilters] = useState<any>({});
    const [filters, setFilters] = useState<any>({});

    // Gibt an ob mindestens ein Filter gesetzt ist
    const [isFilterSet, setIsFilterSet] = useState<boolean>(false);
    // Die gefilterten 'props.values', also Tabellenzeilen in einem Array
    // Wird aktualisiert, wenn sich die Tabellefilter ändern
    const [filteredValues, setFilteredValues] = useState<any>(props.value);

    const [page, setPage] = useState<any>(undefined);
    const [pageRows, setPageRows] = useState<number>(10);
    const [first, setFirst] = useState<number>(0);
    const [filterTemp, setFilterTemp] = useState<any>(undefined);
    const [expandedRows, setExpandedRows] = useState<any>(null);
    const [scrollToFirstExpanedTriggered, setScrollToFirstExpanedTriggered] = useState<boolean>(false);

    const [useUserFilters, setUseUserFilters] = useState<boolean>(uuf);
    const [filterVals, setFilterVals] = useState<any>({});
    const [dates, setDates] = useState<any>(null);
    const [showSkeleton, setShowSkeleton] = useState<any>(true);
    // console.log('props', props);
    // const [makeCopy, setMakeCopy] = useState(true);

    const [sortField, setSortField] = useState<any>(undefined);
    const [sortOrder, setSortOrder] = useState<any>(undefined);

    const buttonIconWidth = 50;
    const customButtonWidth = 42;
    const buttonColumnWidth = (props.ableToUpdate ? buttonIconWidth : 0)
        + (props.ableToDelete && (typeof props.selectionMode === 'undefined' || props.selectionMode === 'single') ? buttonIconWidth : 0)
        + (props.customButtons ? props.customButtons.filter(item => {
            return item.permitted;
        }).length * customButtonWidth : 0);

    const showPaginator = (): boolean => {
        return typeof props.paginator === 'boolean' ? props.paginator : props.value.length > 100;
    };

    useEffect(() => {
        setExpandedRows(props.expandedRows);
    }, [props.expandedRows]);

    useEffect(() => {
        if (props.value) {

            const pRows = localStorage.getItem('pageRows_' + props.id);
            if (pRows) {
                setPageRows(parseInt(pRows));
            }

            // let makeCopy = true;
            //
            // const idKey = props.tableId || 'Id';
            // if (copy.length) {
            //     const valueIds = props.value.map((x: any) => {
            //         return x[idKey];
            //     });
            //     const copyIds = copy.map((x: any) => {
            //         return x[idKey];
            //     });
            //
            //     if (valueIds.length === copyIds.length && valueIds.every(function (value, index) {
            //         return value === copyIds[index];
            //     })) {
            //         makeCopy = false;
            //     }
            // }
            //
            // if (makeCopy) {
            //     console.log('make copy: ', props.value);
            //     console.log('make copy: ', !!props.value);
            //     setCopy(JSON.parse(JSON.stringify(props.value)));
            // }
            // setMakeCopy(false);

            for (const item in props.filters) {
                if (usedFilters[item]) {
                    props.filters[item].value = usedFilters[item].value;
                }
            }

            setFilters(props.filters);
            if (props.showClearFiltersButton) {
                // Prüft ob mindestens ein Filter gesetzt ist und zeigt dann den Button zum Leeren der Filter an
                checkIsFilterSet(props.filters);
            }

            // Setze initial die gefilterten Werte mit den übergeben Werten, bzw. wenn sich die values ändern
            setFilteredValues(props.value);
        }


    }, [props.value]);

    useEffect(() => {
        if (props.dataloaded === true) {
            setShowSkeleton(false);
        } else {
            setShowSkeleton(true);
        }
    }, [props.dataloaded]);

    useEffect(() => {
        if (tableRef.current && !scrollToFirstExpanedTriggered && !showSkeleton) {
            if (props.scrollToFirstExpaned) {
                const exp = tableRef.current.getElement().getElementsByClassName('p-datatable-row-expansion');

                if (exp.length > 0) {
                    exp[0].previousElementSibling.scrollIntoView({block: 'center', inline: 'center'});

                }
                setScrollToFirstExpanedTriggered(true);
            }
        }
    }, [tableRef.current]);

    const [selectedColumns, setSelectedColumns] = useState<ColumnObject[]>([]);
    useEffect(() => {
        const settings = getUserFromLocalStorage().settings;
        let displaySettings: any = [];

        if (params && params.projectId) {
            if (settings[params.projectId]) {
                displaySettings = settings[params.projectId]['display_' + props.id];
            }
        }

        let tmp;

        if (displaySettings && displaySettings.length > 0) {
            tmp = props.columns.filter(col => displaySettings.indexOf(col.field) >= 0);
        } else {
            tmp = props.columns.filter(col => col.display === true || col.display !== false);
        }

        let columnOrder: string[] = [];

        if (params && params.projectId) {
            if (settings[params.projectId]) {
                columnOrder = settings[params.projectId]['columnorder_' + props.id];
            }
        }

        if (columnOrder && columnOrder.length > 0) {
            tmp.sort((a: any, b: any) => {
                return columnOrder.indexOf(a.field) > columnOrder.indexOf(b.field) ? 1 : -1;
            });
        }
        setSelectedColumns(tmp);

        let sort = settings['sort_' + props.id];

        if (sort) {
            setSortField(sort.sortField);
            setSortOrder(sort.sortOrder);
        }

    }, [props.columns]);

    const onColumnToggle = (event: any) => {
        const selectedColumns = event.value;
        const orderedSelectedColumns = props.columns.filter(col => selectedColumns.some((sCol: any) => sCol.field === col.field));
        console.log('orderedSelectedColumns', orderedSelectedColumns);
        setSelectedColumns(orderedSelectedColumns);

        clearTimeout(filterDelayTimer);
        filterDelayTimer = setTimeout(() => {
            if (params && params.projectId) {
                setUserSettings('display_' + props.id, orderedSelectedColumns.map(item => item.field), params.projectId);
            }
        }, 1500);
    };

    const convertToCSV = (data: any) => {
        const rows = [];
        const csvSeparator = ';';

        // 1. Add header row
        let header = '';
        props.columns && selectedColumns.length > 0 && selectedColumns.map((column: ColumnObject) => {
            header += column.header + csvSeparator;
        });
        // Füge die Header-Zeile hinzu. Entferne aber das letzte Komma, da es überflüssig ist
        rows.push(header.slice(0, -1));

        // 2. Add data rows
        data.forEach((row: any) => {
            // Filtere nur die Spalten, die im Objekt vorhanden sind
            const filteredColumns = selectedColumns.filter(column => Object.keys(row).includes(column.field));
            // Extrahiere die Werte in der Reihenfolge der gefilterten Spalten
            const values = filteredColumns.map(column => row[column.field]);
            // Erstelle die Semikolon-separierte Zeichenkette
            rows.push(values.join(csvSeparator));
        });

        return rows.join('\n');
    };

    const exportCSV = () => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('customDataTable:dialogs.exportAsCSVDialog.message'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                // Exportiere nur die gerade sichtbaren, also die gefilerten Daten
                const data = convertToCSV(filteredValues);

                // Create a Byte Order Mark, so that excel will use UTF-8 instead of ANSI for encoding
                // https://stackoverflow.com/questions/42462764/javascript-export-csv-encoding-utf-8-issue
                const universalBOM = '\uFEFF';
                const blob = new Blob([universalBOM + data], {type: 'text/csv;charset=utf-8;'});
                const url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', 'data.csv');
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        });
    };

    const clearFilters = () => {
        const _filters = filters;
        for (const key in _filters) {
            // Setze den Filter auf null
            if (key === 'global') {
                // Globale Filtersuche
                _filters[key]['value'] = null;
            } else {
                // Spaltenfilter
                _filters[key]['constraints'][0]['value'] = null;
            }

        }
        // Überschreibe den Filterstate
        setFilters(_filters);

        // Setze den globalen Filter zurück
        setGlobalFilter([]);

        // Setze die internen Tabellenfilter zurück
        tableRef.current.reset();

        // Überschreibe den State, dass kein Filter mehr gesetzt ist
        setIsFilterSet(false);
    };

    // Prüft ob mindestens ein Filter gesetzt ist und zeigt dann den Button zum Leeren der Filter an
    const checkIsFilterSet = (_filters: any) => {
        for (const key in _filters) {
            if (key === 'global') {
                // Globale Filtersuche
                if (Array.isArray(_filters[key]['value'])) {
                    if (_filters[key]['value'].length > 0) {
                        // Filter ist gesetzt (Global, Array)
                        setIsFilterSet(true);
                        return;
                    }
                } else {
                    if (_filters[key]['value'] !== null) {
                        // Filter ist gesetzt (Global, NOT NULL)
                        setIsFilterSet(true);
                        return;
                    }
                }

            } else {
                // Spaltenfilter
                if (_filters[key]['constraints'] && _filters[key]['constraints'][0] && !Array.isArray(_filters[key]['constraints'][0]['value']) && _filters[key]['constraints'][0]['value'] !== null) {
                    // Filter ist gesetzt (NOT NULL)
                    setIsFilterSet(true);
                    return;
                } else if (_filters[key]['constraints'] && _filters[key]['constraints'][0] && Array.isArray(_filters[key]['constraints'][0]['value']) && _filters[key]['constraints'][0]['value'].length > 0) {
                    // Filter ist gesetzt (Array)
                    setIsFilterSet(true);
                    return;
                }
            }

        }
        // Es ist kein Filter gesetzt
        setIsFilterSet(false);
    };

    const formatCurrency = (value: any) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    const priceBodyTemplate = (rowData: any) => {
        return formatCurrency(rowData.price);
    };


    const header = (
        <>
            {!props.hideSearch && <div
                className={'table-header grid grid-noguter px-0 mx-0 relative -mt-3 ' + (props.headerTitle ? '' : ' mb-2')}>
                <div className="col pl-0">
                    <h3 className="m-0 white-space-nowrap">{props.headerTitle}</h3>
                </div>
                <div className="col-auto flex">
                    {props.showClearFiltersButton === true && isFilterSet &&
                        <div className="search-input ml-3">
                            <Button
                                className={'p-button'}
                                icon={'pi pi-filter-slash'}
                                onClick={clearFilters}
                                tooltip={t('customDataTable:tooltips.clearFilters')}
                                tooltipOptions={{position: 'top'}}
                            />
                        </div>}
                    {props.showExportCSV === true &&
                        <div className="search-input ml-3">
                            <Button
                                className={'p-button-outlined'}
                                icon={'pi pi-file'}
                                onClick={exportCSV}
                                tooltip={t('customDataTable:tooltips.exportAsCSV')}
                                tooltipOptions={{position: 'top'}}
                            />
                        </div>}
                    {props.displayColumnFilter === true &&
                    <div className="search-input ml-3">
                        <MultiSelect
                            value={selectedColumns}
                            options={props.columns}
                            optionLabel="header"
                            onChange={onColumnToggle}
                            style={{width: '20em'}}
                            placeholder={t('customDataTable:visibleColumns')}
                            fixedPlaceholder={true}
                        />
                    </div>}
                    {(props.hideGlobalFilter === undefined || !props.hideGlobalFilter) &&
                    <div className="search-input ml-3">
                        {props.timerangefield &&
                        <Calendar value={dates} className="mr-2" dateFormat="dd.mm.yy" onChange={(e) => {
                            setDates(e.value);
                            props.onTimeRangeSelect && props.onTimeRangeSelect(e.value);
                        }}
                        selectionMode="range" readOnlyInput
                        placeholder={t('conditionMonitoring:alarm_range')}/>}
                        <span className="p-input-icon-left">
                            <i className="pi pi-search"/>
                            <InputText type="search" onInput={(e: any) => {
                                setGlobalFilter(e.target.value || []);
                            }}
                            value={globalFilter}
                            placeholder={t('customDataTable:search.placeholder')}/>
                        </span>
                    </div>}
                </div>
                {props.showLoading &&
                <div style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    textAlign: 'center',
                    zIndex: '3000'
                }}>
                    <ProgressSpinner
                        style={{width: '50px', height: '50px', margin: 'auto', zIndex: '4000'}}
                        animationDuration=".5s"
                    />
                </div>}
            </div>}
        </>
    );

    const isChanged = (rowData: any, key: string) => {
        // console.log(rowData);
        // return rowData.ChangedByUser || false;
        return rowData.ChangedByUser && rowData.ChangedByUser.indexOf(key) >= 0;
        // let changed = false;
        // if (copy.length) {
        //     let copyItems = copy.filter((x: any) => {
        //         return x.Id === rowData.Id
        //     });
        //
        //     if (copyItems.length) {
        //         changed = copyItems[0][key] !== rowData[key];
        //     }
        // }
        // return changed;
    };

    const textColumn = (rowData: any, key: string, onClick: any) => {
        // console.log('rowData: ',rowData);
        // console.log('key: ', key);
        // console.log(typeof rowData[key]);

        let value = rowData[key];

        if (onClick !== undefined) {
            value = (<div onClick={(e) => {
                e.stopPropagation();
                onClick(rowData);
            }}><u>{value}</u></div>);
        }

        if (key === 'is_enabled') {
            const dynamicProps: any = {};
            if (rowData['is_enabled']) dynamicProps['style'] = {color: 'green'};
            return (
                <div style={{textAlign: 'center'}}>
                    <FontAwesomeIcon
                        icon={rowData['is_enabled'] === null ? faSlash : rowData['is_enabled'] ? faCircleCheck : faCircleXmark}
                        className="m-2"
                        {...dynamicProps}
                    />
                </div>
            );
        } else if (key === 'is_visible') {
            const dynamicProps: any = {};
            if (rowData['is_visible']) dynamicProps['style'] = {color: 'green'};
            return (
                <div style={{textAlign: 'center'}}>
                    <FontAwesomeIcon
                        icon={rowData['is_visible'] === null ? faSlash : rowData['is_visible'] ? faEye : faEyeSlash}
                        className="m-2"
                        {...dynamicProps}
                    />
                </div>
            );
        } else if (key === 'is_active') {
            const dynamicProps: any = {};
            if (rowData['is_active']) dynamicProps['style'] = {color: 'green'};
            return (
                <div style={{textAlign: 'center'}}>
                    <FontAwesomeIcon
                        icon={rowData['is_active'] === null ? faSlash : rowData['is_active'] ? faCircleCheck : faCircleXmark}
                        className="m-2"
                        {...dynamicProps}
                    />
                </div>
            );
        } else if (key === 'is_project_active') {
            const dynamicProps: any = {};
            if (rowData['is_project_active']) dynamicProps['style'] = {color: 'green'};
            return (
                <div style={{textAlign: 'center'}}>
                    <FontAwesomeIcon
                        icon={rowData['is_project_active'] === null ? faSlash : rowData['is_project_active'] ? faCircleCheck : faCircleXmark}
                        className="m-2"
                        {...dynamicProps}
                    />
                </div>
            );
        } else if (key === 'is_logical') {
            const dynamicProps: any = {};
            if (rowData['is_logical']) dynamicProps['style'] = {color: 'green'};
            return (
                <div style={{textAlign: 'center'}}>
                    <FontAwesomeIcon
                        icon={rowData['is_logical'] === null ? faSlash : rowData['is_logical'] ? faCircleCheck : faCircleXmark}
                        className="m-2"
                        {...dynamicProps}
                    />
                </div>
            );
        } else if (key === 'flag') {
            const dynamicProps: any = {};
            if (rowData['flag']) dynamicProps['style'] = {color: 'green'};
            return (
                <div style={{textAlign: 'center'}}>
                    <FontAwesomeIcon
                        icon={rowData['flag'] === null ? faSlash : rowData['flag'] ? faCircleCheck : faCircleXmark}
                        className="m-2"
                        {...dynamicProps}
                    />
                </div>
            );
        } else if (key === 'mobile') {
            const dynamicProps: any = {};
            if (rowData['mobile']) dynamicProps['style'] = {color: 'green'};
            return (
                <div style={{textAlign: 'center'}}>
                    <FontAwesomeIcon
                        icon={rowData['mobile'] === null ? faSlash : rowData['mobile'] ? faCircleCheck : faCircleXmark}
                        className="m-2"
                        {...dynamicProps}
                    />
                </div>
            );
        } else if (key === 'is_automatic') {
            const dynamicProps: any = {};
            if (rowData['is_automatic']) dynamicProps['style'] = {color: 'green'};
            return (
                <div style={{textAlign: 'center'}}>
                    <FontAwesomeIcon
                        icon={rowData['is_automatic'] === null ? faSlash : rowData['is_automatic'] ? faCircleCheck : faCircleXmark}
                        className="m-2"
                        {...dynamicProps}
                    />
                </div>
            );
        } else if (key === 'is_rotational_speed_dependent') {
            const dynamicProps: any = {};
            if (rowData['is_rotational_speed_dependent']) dynamicProps['style'] = {color: 'green'};
            return (
                <div style={{textAlign: 'center'}}>
                    <FontAwesomeIcon
                        icon={rowData['is_rotational_speed_dependent'] === null ? faSlash : rowData['is_rotational_speed_dependent'] ? faCircleCheck : faCircleXmark}
                        className="m-2"
                        {...dynamicProps}
                    />
                </div>
            );
        } else if (key === 'save_in_nextcloud') {
            const dynamicProps: any = {};
            if (rowData['save_in_nextcloud']) dynamicProps['style'] = {color: 'green'};
            return (
                <div style={{textAlign: 'center'}}>
                    <FontAwesomeIcon
                        icon={rowData['save_in_nextcloud'] === null ? faSlash : rowData['save_in_nextcloud'] ? faCircleCheck : faCircleXmark}
                        className="m-2"
                        {...dynamicProps}
                    />
                </div>
            );
        } else if (key === 'send_as_pdf') {
            const dynamicProps: any = {};
            if (rowData['send_as_pdf']) dynamicProps['style'] = {color: 'green'};
            return (
                <div style={{textAlign: 'center'}}>
                    <FontAwesomeIcon
                        icon={rowData['send_as_pdf'] === null ? faSlash : rowData['send_as_pdf'] ? faCircleCheck : faCircleXmark}
                        className="m-2"
                        {...dynamicProps}
                    />
                </div>
            );
        } else {
            return (<div>{value}</div>);
        }

    };

    const numberColumn = (rowData: any, key: string, currency: Nullable<string> = null) => {
        let value = rowData[key];

        if (value && currency) {
            const language = getUserFromLocalStorage().language_id;
            const localesString = convertLanguageCodeToLocale(language);
            value = value.toLocaleString(localesString, { style: 'currency', currency: currency });
        }

        return (
            <div style={{textAlign: 'right'}}>
                {value}
            </div>
        );
    };

    // Berechne die Summe der Numberspalten und stellen den Wert in dem Footer da
    const numberFooterColumn = (key: string, currency: Nullable<string> = null) => {
        let sum = 0;
        for (const val of filteredValues) {
            sum += val[key];
        }

        let value = sum.toString();
        // Füge eine Einheit hinzu wenn es sich um ein Währungsbetrag handelt
        if (currency) {
            const language = getUserFromLocalStorage().language_id;
            const localesString = convertLanguageCodeToLocale(language);
            value = sum.toLocaleString(localesString, { style: 'currency', currency: currency });
        }

        return (
            <div className={'flex w-full'}>
                <div >
                    ∑
                </div>
                <div className={'flex justify-content-end w-full'} style={{marginLeft: 'auto'}}>
                    {value}
                </div>
            </div>
        );
    };

    const dateColumn = (rowData: any, key: string, dateFormat: string = 'DD.MM.YYYY HH:mmZ') => {
        const timezone = getUserFromLocalStorage().timezone;

        let value = '';

        if (rowData[key]) {
            value = moment(rowData[key]).tz(timezone).format(dateFormat);
        }

        return (<div>
            <div className={isChanged(rowData, key) ? 'changed' : ''}></div>
            <div className={(rowData.DeletedByUser ? 'deleted' : '') + (rowData.CreatedByUser ? ' created' : '')}>
                {value !== 'Invalid date' ? value : '-'}
            </div>
        </div>);
    };

    const buttonTemplate = (rowData: any, options: any) => {
        return (<div className="grid grid-nogutter">
            {options.map((item: any, index: number) => {
                return (
                    <div className={'col-auto' + (index < options.length - 1 ? ' mr-2' : '')}>
                        <Button
                            className="py-1 p-button-sm p-button-outlined"
                            onClick={() => item.onClick(rowData.Id)}>{item.html}
                        </Button>
                    </div>
                );
            })}
        </div>);
    };

    const buttonColumn = (selection: any) => {
        // console.log(selection);
        const remove = (selection: any) => {
            if (props.onRemoveClick)
                props.onRemoveClick(selection);
        };
        const edit = (selection: any) => {
            if (props.onEditClick)
                props.onEditClick(selection);
        };
        let canBeDeleted = true;
        if (selection.users) {
            if (selection.users.length > 0) {
                canBeDeleted = false;
            }
        }

        // console.log(typeof props.selectionMode === 'undefined')
        // console.log(props.selectionMode === 'single')
        // console.log((typeof props.selectionMode === 'undefined' || props.selectionMode === 'single'))
        // console.log(props.ableToDelete && (typeof props.selectionMode === 'undefined' || props.selectionMode === 'single'))

        return (
            <>

                {props.ableToUpdate &&
                <Button
                    className="p-button m-1 p-button-text" style={{minWidth: '20px'}}
                    tooltip={props.buttonToolTipEdit ? props.buttonToolTipEdit : t('edit')}
                    tooltipOptions={{position: 'top'}} onClick={() => {
                        edit(selection);
                    }}>
                    <i className={'pi pi-pencil'}/>
                </Button>}
                {props.ableToDelete && (typeof props.selectionMode === 'undefined' || props.selectionMode === 'single') &&
                <Button
                    className="p-button-danger p-button-text m-1" style={{minWidth: '20px'}}
                    disabled={!canBeDeleted}
                    tooltip={props.buttonToolTipDelete ? props.buttonToolTipDelete : t('delete')}
                    tooltipOptions={{position: 'top'}}
                    onClick={() => {
                        remove(selection);
                    }}>
                    <i className="pi pi-trash"/>
                </Button>}
                {props.customButtons && props.customButtons.map(item => {
                    if (item.permitted) {
                        // Setze icon initial mit dem nullten Element (Standardfall)
                        let icon = item.icons[0].icon;
                        // Bei mehreren, setze icon je nach Wert
                        for (const iconObj of item.icons) {
                            const value = selection[iconObj.key];
                            if (value === iconObj.value) {
                                icon = iconObj.icon;
                                break;
                            }
                        }

                        const className = item.className ? item.className + ' p-button m-1 p-button-text' : 'p-button m-1 p-button-text';

                        return (
                            <Button
                                className={className} style={{minWidth: '20px'}}
                                tooltip={item.tooltip} tooltipOptions={item.tooltipOptions}
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    item.onClick(selection);
                                }}
                            >
                                {icon}
                            </Button>
                        );
                    }
                })}
            </>
        );
    };

    const imageTemplate = (rowData: any, key: any) => {
        return <img src={rowData[key]}/>;
    };

    const skeletonBodyTemplate = () => {
        return <Skeleton height="2.3rem"></Skeleton>;
    };

    const onPage = (e: any) => {
        setPage(e);
        const rows = parseInt(e.rows);
        localStorage.setItem('pageRows_' + props.id, e.rows);
        setPageRows(rows);
        setFirst(parseInt(e.first));
        return rows;
    };

    const template1: any =
        {
            layout: 'CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown',
            'PrevPageLink':
                (options: any) => {
                    return (
                        <button
                            type="button" className={options.className} onClick={options.onClick}
                            disabled={options.disabled}>
                            <span className="p-3"><i className="pi pi-angle-left"></i></span>
                            <Ripple/>
                        </button>
                    );
                },
            'NextPageLink':
                (options: any) => {
                    return (
                        <button
                            type="button" className={options.className} onClick={options.onClick}
                            disabled={options.disabled}>
                            <span className="p-3"><i className="pi pi-angle-right"></i></span>
                            <Ripple/>
                        </button>
                    );
                },
            'PageLinks':
                (options: any) => {
                    // console.log(options)
                    // if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                    //     const className = classNames(options.className, {'p-disabled': true});
                    //
                    //     return <span className={className} style={{userSelect: 'none'}}>...</span>;
                    // }

                    if (options.page !== options.currentPage) {
                        return <div></div>;
                    }

                    return (
                        <button type="button" className={options.className} onClick={options.onClick}>
                            {options.page + 1 + '/' + (options.totalPages)}
                            <Ripple/>
                        </button>
                    );
                },
            'RowsPerPageDropdown':
                (options: any) => {
                    const dropdownOptions = [
                        {label: 10, value: 10},
                        {label: 20, value: 20},
                        {label: 50, value: 50},
                        {label: t('customDataTable:paginator.all'), value: options.totalRecords}
                    ];

                    return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange}/>;
                },
        };

    const booleanTemplate = (rowData: any, field: string) => {
        return (
            <i className={classNames('m-auto pi', {
                'true-icon pi-check-circle': rowData[field],
                'false-icon pi-times-circle': !rowData[field]
            })}
            />);
    };

    const filterDropdownTemplate = (options: any, values?: any[], field?: string) => {
        // console.log("options", options);
        // console.log("values", values);
        // console.log('colfilter', columnFilters);

        if (values) {
            for (let i = 0; i < values.length; i++) {
                const item = values[i];

                if (item.value === undefined) {
                    item.value = item.id;
                }
            }
        }

        // console.log("values", values);

        return (
            <MultiSelect
                value={options.value} options={values}
                itemTemplate={(option: any) => {
                    return (<div>{option.name}</div>);
                }}
                // selectedItemsLabel={'{0} ' + t('customDataTable:filter.items_selected')}
                // maxSelectedLabels={1}
                onChange={(e: MultiSelectChangeParams) => {
                    if (props.filterOptionSelected) {
                        const optionKey: any = options['field'];
                        let filterArr: any = {};
                        filterArr = {
                            ...filterVals,
                            [optionKey]: e['value']
                        };
                        setFilterVals({
                            ...filterVals,
                            [optionKey]: e['value']
                        });
                        props.selectedFilters && props.selectedFilters(filterArr);
                        options['field'] === props.filterOptionsField && props.filterOptionSelected(e['value']);
                    }

                    setColumnFilters((prevState: any) => ({
                        ...prevState,
                        [options.field]: e.value
                    }));
                    return options.filterApplyCallback(e.value);
                    // options.filterApplyCallback(e.value);
                    // options.filterCallback(e.value);
                }} optionLabel={field ? field : 'name'} placeholder={t('customDataTable:filter.any')}
                className="p-column-filter"
            />
        );
    };

    const allowExpansion = (rowData: any) => {
        return rowData.expandableElement !== undefined;
    };

    const rowExpansionTemplate = (rowData: any) => {
        return (<div className="pl-5 relative" style={{'zIndex': '0'}}>{rowData.expandableElement}</div>);
    };

    const textEditor = (options: any) => {
        return getEditor(options, 'text');
    };

    const numberEditor = (options: any) => {
        return getEditor(options, 'number');
    };

    const dateEditor = (options: any) => {
        return getEditor(options, 'datetime-local');
    };

    const getEditor = (options: any, type: string) => {
        return (
            <InputText
                className={classNames(options.column.props.editCellClassName)}
                style={options.column.props.editCellStyle}
                type={type} value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
            />
        );
    };

    const dropdownEditor = (options: any, items: any) => {
        return (
            <Dropdown
                className={classNames('w-full', options.column.props.editCellClassName)}
                style={options.column.props.editCellStyle}
                value={options.value ? options.value.toString() : null}
                options={items.map((i: any) => {
                    return {value: i.id, label: i.name};
                })}
                onChange={(e) => options.column.props.editCell(e.target.value)}
            />
        );
    };

    const settings = getUserFromLocalStorage().settings;

    if (useUserFilters) {
        let filterSettings: any = {};

        if (params && params.projectId) {
            if (settings[params.projectId]) {
                filterSettings = settings[params.projectId]['filters_' + props.id];
            }
        }

        if (filterSettings && props.filters) {
            // console.log(filterSettings)
            for (let i = 0; i < Object.keys(filterSettings).length; i++) {
                const key = Object.keys(filterSettings)[i];
                props.filters[key] = filterSettings[key];
            }
        }
    }

    const spreadProps = {...props};

    // @ts-ignore
    delete spreadProps.editable;
    delete spreadProps.ableToDelete;
    delete spreadProps.ableToUpdate;
    delete spreadProps.headerTitle;
    delete spreadProps.onEditClick;
    delete spreadProps.onRemoveClick;
    delete spreadProps.hideGlobalFilter;
    // @ts-ignore
    delete spreadProps.sortable;
    delete spreadProps.expandable;
    delete spreadProps.filters;
    delete spreadProps.dataloaded;
    delete spreadProps.skeletonCount;
    delete spreadProps.showExportCSV;
    delete spreadProps.showClearFiltersButton;
    delete spreadProps.scrollToFirstExpaned;

    let filterDelayTimer: any;

    const onSort = (e: any) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
        setUserSettings('sort_' + props.id, {sortField: e.sortField, sortOrder: e.sortOrder});
    };

    return (<><DataTable
        {...spreadProps}
        ref={tableRef}
        value={showSkeleton ? skeletonValues : props.value}
        reorderableColumns={true}
        onColReorder={(e: any) => {
            const columnOrder = e.columns.map((x: any) => x.props.field);
            setUserSettings('columnorder_' + props.id, columnOrder, params.projectId);
        }}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={onSort}
        rowClassName={(data: any, options: any) => {
            if (props.rowClass && props.rowClass.length > 0) {
                for (let i = 0; i < props.rowClass.length; i++) {
                    const rc = props.rowClass[i];
                    if (data[rc.key] === rc.value) {
                        return rc.class;
                    }
                }
            }
            return '';
        }}
        scrollHeight={props.scrollHeight || 'flex'}
        style={props.style || {'tableLayout': 'fixed'}}
        columnResizeMode="fit"
        resizableColumns={true}
        filters={showSkeleton ? null : filters}
        showGridlines
        className={'pt-2' + (props.className ? ' ' + props.className : '')}
        globalFilter={globalFilter}
        header={header}
        scrollable={true}
        selectionMode={(typeof props.selectionMode === 'undefined') ? 'single' : props.selectionMode}
        // onSelectionChange={(e: any) => {
        //     console.log(e);
        //     if (props.onSelectionChange) {
        //         props.onSelectionChange(e);
        //     }
        // }}
        paginator={showPaginator()}
        onPage={onPage}
        first={first ? first : 0}
        rows={props.scrollToFirstExpaned ? props.value.length : pageRows ? pageRows : 10}
        rowsPerPageOptions={[10, 20, 50, 100]}
        responsiveLayout="scroll"
        paginatorTemplate={template1}
        currentPageReportTemplate={t('customDataTable:paginator.showing') + ' {first} ' + t('customDataTable:paginator.to') + ' {last} ' + t('customDataTable:paginator.of') + ' {totalRecords}'}
        emptyMessage={t('customDataTable:noentry')}
        onValueChange={filteredData => {setFilteredValues(filteredData)}}
        filterDisplay="menu"
        onFilter={(e: any) => {

            if (props.onFilterChanged) {
                props.onFilterChanged(e.filters);
            } else {
                setFilters(e.filters);
            }

            checkIsFilterSet(e.filters);

            if (useUserFilters) {
                let save = true;
                delete e.filters.global;

                if (filterTemp) {
                    const tmp = filterTemp;

                    delete tmp.global;

                    if (JSON.stringify(e.filters) === JSON.stringify(tmp)) {
                        save = false;
                    }
                }

                if (save) {
                    clearTimeout(filterDelayTimer);
                    filterDelayTimer = setTimeout(() => {
                        if (params && params.projectId) {
                            setUserSettings('filters_' + props.id, e.filters, params.projectId);
                        }
                    }, 1500);
                }

                setFilterTemp(e.filters);
            }
        }}
        expandedRows={expandedRows}
        onRowToggle={(e) => {
            setExpandedRows(e.data);
            if (props.onRowToggle)
                props.onRowToggle(e);
        }}
        onRowExpand={props.onRowExpand}
        onRowCollapse={props.onRowCollapse}
        rowExpansionTemplate={rowExpansionTemplate}
        onColumnResizeEnd={(e) => {
            // Die Werte müssen nicht gespeichert werden, so lange man die initiale Breite nicht korrekt setzen kann.
            // setUserSettings('dt_column_width_' + props.id + '_' + e.column.props.field, e.element.offsetWidth);
        }}
    >
        {props.columns && selectedColumns.length > 0 && props.editable &&
        <Column
            body={showSkeleton ? skeletonBodyTemplate : buttonColumn}
            columnKey={'buttons'}
            field="buttons"
            style={{minWidth: buttonColumnWidth + 'px', maxWidth: buttonColumnWidth + 'px'}}
            // frozen={true}
            alignFrozen="left"
            reorderable={false}
        />}
        {props.columns && selectedColumns.length > 0 && (props.selectionMode && props.selectionMode === 'multiple') &&
        <Column
            body={showSkeleton ? skeletonBodyTemplate : undefined}
            columnKey={'multiple'}
            className="selection-column pt-3" selectionMode={props.selectionMode}
            reorderable={false}
            // frozen={true}
        />}
        {props.columns && selectedColumns.length > 0 && (props.selectionMode && props.selectionMode === 'checkbox') &&
        // In diesem Modus kann man eine Zeile nur selektieren, wenn die Checkbox angeklickt wurde. Dann muss die
        // Spalte aber den selectionMode 'multiple' besitzen und die DataTable den selectionMode 'checkbox'
        <Column
            body={showSkeleton ? skeletonBodyTemplate : undefined}
            columnKey={'checkbox'}
            className="selection-column pt-3"
            selectionMode={'multiple'} reorderable={false}
            // frozen={true}
        />}
        {props.columns && selectedColumns.length > 0 && props.expandable &&
        <Column
            body={showSkeleton ? skeletonBodyTemplate : undefined} columnKey={'expander'}
            className="expander-column pt-2" expander={allowExpansion} reorderable={false}
            // frozen={true}
        />}
        {props.columns && selectedColumns.length > 0 && selectedColumns.map((item: ColumnObject) => {

            const filterTemplate = {
                showApplyButton: false,
                showClearButton: false,
                showFilterMatchModes: false,
                showFilterMenuOptions: false,
                showFilterOperator: false
            };


            // Funktioniert leider nicht, scheinbar gibt es keine Möglichkeit die Columns resizeable zu machen und dann initial eine individuelle Breite zu setzen...
            // minWidth würde die Breite setzen, aber dann hat man keine Möglichkeit die Column wieder kleiner zu bekommen.
            // if (getUserFromLocalStorage().settings) {
            //     let width = getUserFromLocalStorage().settings['dt_column_width_' + props.id + '_' + item.field];
            //
            //     if (item.style === undefined) {
            //         item.style = {}
            //     }
            //
            //     if (width && item.style.width === undefined) {
            //         item.style.width = width + 'px';
            //     }
            // }

            switch (item.type) {
                case 1: // Number
                    return (
                        <Column
                            {...item}
                            {...filterTemplate}
                            key={item.field} sortable={props.sortable}
                            body={showSkeleton ? skeletonBodyTemplate : item.body ? item.body : (rowData: any) => numberColumn(rowData, item.field, item.currency)}
                            editor={item.editCell ? (options) => numberEditor(options) : null}
                            footer={showSkeleton ? skeletonBodyTemplate : item.body ? item.body : () => numberFooterColumn(item.field, item.currency)}
                            onCellEditComplete={item.editCell ? (e: any) => {
                                if (item.editCell) {
                                    item.editCell(e.newValue);
                                }
                            } : undefined}
                        />
                    );

                case 2: // Checkbox
                    return (
                        <Column
                            {...item}
                            {...filterTemplate}
                            body={showSkeleton ? skeletonBodyTemplate : undefined}
                            key={item.field}
                            selectionMode="multiple" // sortable={props.sortable}
                            style={{minWidth: '10px', maxWidth: '30px'}}
                        />
                    );

                case 3: // Dropdown
                    return (
                        <Column
                            {...item}
                            {...filterTemplate}
                            key={item.field} sortable={props.sortable}
                            body={showSkeleton ? skeletonBodyTemplate : item.body ? item.body : (rowData: any) => textColumn(rowData, item.field, item.onClick)}
                            editor={item.editCell ? (options) => dropdownEditor(options, item.filterDropdownValues) : null}
                        />
                    );

                case 4: // Date
                    return (
                        <Column
                            {...item}
                            {...filterTemplate}
                            key={item.field} sortable={props.sortable}
                            body={showSkeleton ? skeletonBodyTemplate : item.body ? (rowData) => item.body(rowData) : (rowData) => dateColumn(rowData, item.field, item.dateFormat)}
                            editor={item.editCell ? (options) => dateEditor(options) : null}
                            onCellEditComplete={item.editCell ? (e: any) => {
                                if (item.editCell) {
                                    item.editCell(e.newValue);
                                }
                            } : undefined}
                            onFilterClear={() => {
                                setColumnFilters((prevState: any) => ({
                                    ...prevState,
                                    [item.field]: null
                                }));
                            }}
                            filterElement={item.filterDropdownValues ? (options) => {
                                // Ändere den String bei den Filter Dropdown Werten oder benutze das übergebene Dateformat
                                // von 2024-04-01T00:00:00.000Z zu 01.04.2024 02:00+02:00
                                const dateFormat = item.dateFormat ? item.dateFormat : 'DD.MM.YYYY HH:mmZ';
                                const timezone = getUserFromLocalStorage().timezone;
                                const language = getUserFromLocalStorage().language_id;
                                let values = item.filterDropdownValues;
                                if (item.filterDropdownValues) {
                                    values = [];
                                    for (const dropdownValue of item.filterDropdownValues) {
                                        moment.locale(language);
                                        dropdownValue.name = moment(dropdownValue.value).tz(timezone).format(dateFormat);
                                        values.push(dropdownValue);
                                    }
                                }
                                return filterDropdownTemplate(options, values, item.filterDropdownField);
                            } : item.filterElement ? item.filterElement : null}
                        />
                    );

                case 5: // Buttons
                    return (
                        <Column
                            {...item}
                            {...filterTemplate}
                            key={item.field} sortable={props.sortable}
                            body={(rowData) => showSkeleton ? skeletonBodyTemplate : buttonTemplate(rowData, item.options)}
                        />
                    );

                case 6: // Image
                    return (
                        <Column
                            {...item}
                            {...filterTemplate}
                            key={item.field} sortable={props.sortable}
                            body={(rowData) => showSkeleton ? skeletonBodyTemplate : imageTemplate(rowData, item.field)}
                        />
                    );

                case 7: // Boolean
                    return (
                        <Column
                            {...item}
                            {...filterTemplate}
                            key={item.field} sortable={props.sortable}
                            style={{minWidth: '6rem'}}
                            body={(rowData) => showSkeleton ? skeletonBodyTemplate : booleanTemplate(rowData, item.field)}
                        />
                    );

                default: // Text
                    return (
                        <Column
                            {...item}
                            {...filterTemplate}
                            key={item.field} sortable={props.sortable}
                            body={showSkeleton ? skeletonBodyTemplate : item.body ? item.body : (rowData: any) => textColumn(rowData, item.field, item.onClick)}
                            onFilterClear={() => {
                                setColumnFilters((prevState: any) => ({
                                    ...prevState,
                                    [item.field]: null
                                }));
                            }}
                            // style={item.style? item.style : null}
                            filterElement={item.filterDropdownValues ? (options) => {
                                // console.log('options: ', options)
                                // console.log('filterDropdownValues: ', item.filterDropdownValues)
                                // console.log('filterDropdownField: ', item.filterDropdownField)
                                return filterDropdownTemplate(options, item.filterDropdownValues, item.filterDropdownField);
                            } : item.filterElement ? item.filterElement : null}

                            editor={item.editCell ? (options) => textEditor(options) : null}
                            onCellEditComplete={item.editCell ? (e: any) => {
                                if (item.editCell) {
                                    item.editCell(e.newRowData);
                                }
                            } : undefined}
                        />
                    );
            }
        })}
    </DataTable></>);
};

export default CustomDataTable;
