/*
 * PlotChannelSelectList.tsx
 * Author: fwunderlich
 * Date: 22.12.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import Input from '../global/Input';
import {channelsForMeasurement, getPlotOptions} from './PlotFunctions';
import {channel} from 'diagnostics_channel';

type Props = {
    projectId: string,
    label: string,
    list?: any, /* {value, label} */
    values?: any[],
    onChange?: (plotOptions: any, rawData: any) => void,
    name?: string,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
}
const PlotChannelSelectList = (props: Props): JSX.Element => {
    const [list, setList] = useState(props.list);
    const [channelHash, setChannelHash] = useState<any>({});

    useEffect(() => {
        setList(props.list);
        if (typeof props.list === 'undefined') {
            channelsForMeasurement(props.projectId, setChannelHash, setList);
        }
    }, []);

    return (
        <div className="col-auto">
            <Input
                name={props.name ? props.name : 'channel_id'}
                edit={true}
                label={props.label}
                value={props.values}
                scrollHeight={'500px'}
                dropdownOptions={list} type={'treeSelect'}
                onChange={(e: any) => {
                    console.log(e);
                    const values = e.target.value;
                    const plotOptions = getPlotOptions(values, channelHash);

                    if (props.onChange) props.onChange(plotOptions, values);
                }}
                selectionMode={'checkbox'}
                validationControl={props.validationControl}
                validationErrorMessage={props.validationErrorMessage}
                validationRules={props.validationRules}
            />
        </div>
    );
};

export default PlotChannelSelectList;
