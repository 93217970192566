/*
 * ProjectManagement.tsx
 * Author: lnappenfeld
 * Date: 16.02.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {checkPermission} from '../functions/functionLibrary';
import {permissions} from '../config/permissions';
import {CustomConfirmDialog} from '../components/global/CustomConfirmDialog';
import {FormType, getProjects, toggleProjectStatus} from '../components/functions/Project';
import {showMessageOnError, showMessageOnSuccess} from '../components/global/CustomToast';
import ProjectEditForm from '../components/projectManagement/ProjectEditForm';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDashboard, faFolderPlus, faGears, faClone, faMoneyCheck} from '@fortawesome/free-solid-svg-icons';
import LayoutMainContentTabMenu from '../components/global/LayoutMainContentTabMenu';
import {GrafanaDashboard, Nullable} from '../components/functions/Global';
import {faFolderOpen} from '@fortawesome/free-regular-svg-icons';
import {useNavigate} from 'react-router-dom';
import ViewButtons from '../components/global/ViewButtons';
import {
    buttonCreateGrafanaDashboard, buttonCreateProject, buttonCreateProjectGroup, buttonDisableProject,
    buttonDuplicateProject, buttonEnableProject, buttonExportProject, buttonImportProject,
    buttonSaveProjectSettings,
    makeButtonItem
} from '../components/global/viewButtonSelection';
import ProjectAdvancedSettingsForm from '../components/projectManagement/ProjectAdvancedSettingsForm';
import GrafanaDashboardsOverview from '../components/projectManagement/GrafanaDashboardsOverview';
import {hideWaitAnimation, showWaitAnimation} from '../components/global/CustomWaitAnimation';
import {ProjectMonthBillingOverview} from '../components/billing/ProjectMonthBillingOverview';


type Props = {
    project: any,
    setProject: (e: any) => void,
}

const ProjectManagement = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);
    const navigate = useNavigate();

    const mayEditProject = checkPermission(permissions.editProject);
    const mayCreateProject = checkPermission(permissions.createProject);

    const [confirmDialogVisible, setConfirmDialogVisible] = useState<boolean>(false);
    const [createGroupFormVisible, setCreateGroupFormVisible] = useState<boolean>(false);
    const [projectGroups, setProjectGroups] = useState(props.project ? props.project.project_groups : null);

    // Kein Multiselect vorgesehen
    const [selectedGrafanaDashboard, setSelectedGrafanaDashboard] = useState<Nullable<GrafanaDashboard>>(null);
    const [reloadGrafanaDashboards, setReloadGrafanaDashboards] = useState<boolean>(false);
    const [showGrafanaDashboardDialog, setShowGrafanaDashboardDialog] = useState<boolean>(false);

    useEffect(() => {
        setProjectGroups(props.project ? props.project.project_groups : null);
    }, [props.project]);

    useEffect(() => {
        if (confirmDialogVisible) {
            // eslint-disable-next-line new-cap
            CustomConfirmDialog({
                translation: t,
                header: t('confirmation'),
                message: props.project.is_active ? t('projectManagement:dialogs.disableProject.message') : t('projectManagement:dialogs.enableProject.message'),
                onConfirm: changeProjectStatusAccept,
                onCancel: () => setConfirmDialogVisible(false)
            });
        }
    }, [confirmDialogVisible]);

    useEffect(() => {
        if (createGroupFormVisible) {
            props.project.project_groups.push({id: 'new', name: 'group name'});
            setProjectGroups(props.project.project_groups);
            setCreateGroupFormVisible(false);
        }
    }, [createGroupFormVisible]);

    const changeProjectStatusAccept = () => {
        toggleProjectStatus(props.project.id).then(result => {
            if (result.error) {
                showMessageOnError(t('error'), result.error);
            } else {
                if (props.setProject) {
                    const _project = props.project;
                    // result is new is_active value
                    _project['is_active'] = result;
                    props.setProject(_project);
                }
                showMessageOnSuccess(t('success'), t('projectManagement:toasts.statusChanged'));
            }
            setConfirmDialogVisible(false);
        });
    };

    const menuItems = [
        {
            id: 'settings',
            label: t('projectManagement:global.generalInformation'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faFolderOpen}/>,
            component: <ProjectEditForm
                project={props.project}
                setProject={props.setProject}
                groups={projectGroups}
                mayEditProject={mayEditProject}
                onFinished={(project) => {
                    // update project data
                    if (project) {
                        getProjects().then(projects => {
                            let fullProject: null;
                            // eslint-disable-next-line prefer-const
                            fullProject = projects.filter((x: any) => {
                                return x.id === project.id;
                            })[0];
                            if (fullProject && props.setProject) {
                                props.setProject(fullProject);
                                // navigate('/project/' + project.id + '/#project_0');
                            }
                        });
                    }
                }}
                type={FormType.Edit}
            />,
            projectRequired: true,
            visible: props.project !== null,
            // visible: props.project !== null && ((checkPermission(permissions.isAdmin) || checkPermission(permissions.editProject))),
            buttons: mayEditProject ? (
                <ViewButtons
                    title={t('')}
                    items={[
                        makeButtonItem(buttonSaveProjectSettings, t, () => {}),
                        makeButtonItem(buttonCreateProjectGroup, t, () => {
                            setCreateGroupFormVisible(true);
                        }),
                        makeButtonItem(buttonEnableProject, t, () => {
                            setConfirmDialogVisible(true);
                        }, props.project && !props.project.is_active),
                        makeButtonItem(buttonDisableProject, t, () => {
                            setConfirmDialogVisible(true);
                        }, props.project && props.project.is_active),
                    ]}
                />
            ) : null
        },
        {
            id: 'advancedSettings',
            label: t('projectManagement:global.advancedSettings'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faGears}/>,
            component: <ProjectAdvancedSettingsForm
                project={props.project}
                onFinished={(project) => {
                    // update project data
                    if (project) {
                        showWaitAnimation();
                        getProjects().then(projects => {
                            let fullProject: null;
                            // eslint-disable-next-line prefer-const
                            fullProject = projects.filter((x: any) => {
                                return x.id === project.id;
                            })[0];
                            if (fullProject && props.setProject) {
                                props.setProject(fullProject);
                                // navigate('/project/' + project.id + '/#project_0');
                            }
                            hideWaitAnimation();
                        });
                    }
                }}

            />,
            projectRequired: true,
            visible: props.project !== null && ((checkPermission(permissions.isAdmin) || checkPermission(permissions.editProject))),
            buttons: mayEditProject ? (
                <ViewButtons
                    title={t('')}
                    items={[
                        makeButtonItem(buttonSaveProjectSettings, t, () => {}),
                    ]}
                />
            ) : null
        },
        {
            id: 'grafana',
            label: t('projectManagement:global.grafanaDashboards'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faDashboard}/>,
            component: <GrafanaDashboardsOverview
                project={props.project}
                reload={reloadGrafanaDashboards}
                setReload={setReloadGrafanaDashboards}
                selectedGrafanaDashboard={selectedGrafanaDashboard}
                setSelectedGrafanaDashboard={setSelectedGrafanaDashboard}
                showGrafanaDashboardDialog={showGrafanaDashboardDialog}
                setShowGrafanaDashboardDialog={setShowGrafanaDashboardDialog}
            />,
            projectRequired: true,
            visible: props.project !== null && ((checkPermission(permissions.isAdmin) || checkPermission(permissions.seeGrafanaDashboards))),
            buttons: checkPermission(permissions.editGrafanaDashboards) ? (
                <ViewButtons
                    title={t('')}
                    items={[
                        makeButtonItem(buttonCreateGrafanaDashboard, t, () => {
                            setSelectedGrafanaDashboard(null);
                            setShowGrafanaDashboardDialog(true);
                        }),
                    ]}
                />
            ) : null
        },
        // [06.07.2023] Verberge Import/Export/Duplicate Projekt vorübergehend, bis es komplett implementiert wird
        // {
        //     id: 'importExportDuplicateProject',
        //     label: t('projectManagement:global.importExportDuplicateProject'),
        //     icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faClone}/>,
        //     component: <><h4>Under Construction - Coming soon</h4></>,
        //     projectRequired: false,
        //     visible: (checkPermission(permissions.isAdmin) || checkPermission(permissions.editProject)),
        //     buttons: (
        //         <ViewButtons
        //             title={t('')}
        //             items={checkPermission(permissions.importExportDuplicateProjects) ? [
        //                 makeButtonItem(buttonImportProject, t, () => {
        //
        //                 }, true, true),
        //                 makeButtonItem(buttonExportProject, t, () => {
        //
        //                 }, props.project !== null, true),
        //                 makeButtonItem(buttonDuplicateProject, t, () => {
        //
        //                 }, props.project !== null, true)
        //             ] : []}
        //         />
        //     )
        // },
        {
            id: 'createProject',
            label: t('projectManagement:global.createProject'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faFolderPlus}/>,
            component: <ProjectEditForm
                project={null}
                onFinished={(project) => {
                    // set new current project and navigate to them
                    if (project) {
                        getProjects().then(projects => {
                            let fullProject: null;
                            // eslint-disable-next-line prefer-const
                            fullProject = projects.filter((x: any) => {
                                return x.id === project.id;
                            })[0];
                            if (fullProject && props.setProject) {
                                props.setProject(fullProject);
                                navigate('/project/' + project.id + '/#project_0');
                            }
                        });
                    }
                }}
                type={FormType.Create}
            />,
            projectRequired: false,
            visible: (checkPermission(permissions.isAdmin) || checkPermission(permissions.createProject)),
            buttons: mayCreateProject ? (
                <ViewButtons
                    title={t('')}
                    items={[
                        makeButtonItem(buttonCreateProject, t, () => {}),
                    ]}
                />
            ) : null
        }
    ];

    {/* activeIndex -> 0: General Information, 3: Create Project*/
    }
    return (<LayoutMainContentTabMenu items={menuItems} activeIndex={props.project ? 0 : 3}/>);
};

export default ProjectManagement;
