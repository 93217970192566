/*
 * ReportTemplateForm.tsx
 * Author: lnappenfeld
 * Date: 19.07.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {useForm} from 'react-hook-form';
import {createReportTemplate, updateReportTemplate} from '../functions/Reporting';
import Input from '../global/Input';

type Props = {
    projectId: string,
    reportTemplate: any,
    onFinished: (success: boolean) => void
}

const ReportTemplateForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [file, setFile] = useState<any>();
    const defaultValues = {
        name: props.reportTemplate ? props.reportTemplate.name : '',
    };
    const {control, formState: {errors}, handleSubmit, reset, setValue} = useForm({defaultValues});


    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    const getBase64 = (file: any, cb: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    const saveReportTemplate = (reportTemplate: any) => {
        let data = {
            'name': reportTemplate.name,
            'project_id': props.projectId,
            'file': file
        };

        if (props.reportTemplate) {
            const reportAddId = {'report_template_id': props.reportTemplate.id};
            data = {...reportAddId, ...data};

            updateReportTemplate(data).then(result => {
                if (!result.error)
                    showMessageOnSuccess(t('success'), t('reportingManagement:toasts.templateUpdated'));
                else
                    showMessageOnError(t('error'), result.error);
                props.onFinished(true);
            });

        } else {
            createReportTemplate(data).then(result => {
                if (result.error)
                    showMessageOnError(t('error'), result.error);
                else
                    showMessageOnSuccess(t('success'), t('reportingManagement:toasts.templateCreated'));
                props.onFinished(true);
            });
        }
    };

    function parseWordDocxFile(inputElement: any) {
        const files = inputElement.target.files || [];
        if (!files.length) return;
        const file = files[0];

        reset();
        setValue('name', file.name.split('.')[0]);
        getBase64(file, (result: any) => {
            const arr = result.split('base64,');
            setFile(arr[1]);
        });

    }

    return (
        <>
            <style>
                {`.report-template-form-upload-button input[type="file"] {
                    display: none;
                }
                .report-template-form-upload-button label {
                }`}
            </style>
            <form id='formDialog' onSubmit={handleSubmit(saveReportTemplate)}>
                <div className={'card'}>
                    {!props.reportTemplate
                        ? <div className="report-template-form-upload-button pt-3 mb-3" style={{display: 'flex', justifyContent: 'center'}}>
                            <label htmlFor="file-upload" className="p-button p-button-outlined">
                                {t('upload')}
                            </label>
                            <input id="file-upload" type="file" accept=".docx, .docm" onChange={parseWordDocxFile}/></div>
                        : null}
                    <Input
                        edit={true} label={t('name')} name={'name'}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('name') + ' ' + t('input:required')}}
                    />
                </div>
            </form>
        </>
    );
};

export default ReportTemplateForm;

