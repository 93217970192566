/*
 * BillingItemTypesOverview.tsx
 * Author: lnappenfeld
 * Date: 01.08.2024
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {
    BillingItemType, getBillingItemTypes,
} from '../functions/Billing';
import {checkPermission, getGroupedItems} from '../../functions/functionLibrary';
import {permissions} from '../../config/permissions';
import {FilterMatchMode, FilterOperator} from 'primereact/api';

type Props = {
    projectId: string,
    reload: boolean,
    setReload: (reload: boolean) => void,
    setSelectedBillingItemTypes: (data: BillingItemType[]) => void
    selectedBillingItemTypes: BillingItemType[],
}

export const BillingItemTypesOverview = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [billingTypes, setBillingTypes] = useState<BillingItemType[]>([]);

    // arrays for filter dropdowns
    const [filterBillingTypes, setFilterBillingTypes] = useState<any[]>([]);
    const [filterValidDates, setFilterValidDates] = useState<any[]>([]);

    useEffect(() => {
        _getBillingItemTypes();
    }, []);

    useEffect(() => {
        if (props.reload) {
            props.setReload(false);
            _getBillingItemTypes();
        }
    }, [props.reload]);

    const _getBillingItemTypes = () => {
        getBillingItemTypes(props.projectId).then(result => {
            setBillingTypes(result);
            setDataLoaded(true);

            // filter dropdown values
            setFilterBillingTypes(getGroupedItems(result, 'billing_item_main_type_id', 'billing_item_main_type_name'));
            setFilterValidDates(getGroupedItems(result, 'valid_from_date_utc', 'valid_from_date_utc'));
        });
    };

    const columns: ColumnObject[] = [
        {
            field: 'id', header: 'ID', display: false
        }, {
            field: 'billing_item_main_type_name', header: t('common:name'),
            filter: true, filterField: 'billing_item_main_type_id', filterDropdownValues: filterBillingTypes
        }, {
            field: 'price', header: t('billing:attributes.price'), type: 1,
            filter: true, currency: 'EUR'
        }, {
            field: 'valid_from_date_utc', header: t('billing:attributes.billing_item_type_timestamp'),
            filter: true, filterDropdownValues: filterValidDates, type: 4, dateFormat: 'DD.MM.YYYY'
        }
    ];

    const filters = {
        'billing_item_main_type_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'price': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'valid_from_date_utc': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        }
    };

    return (
        <CustomDataTable
            id='billingTypesOverview'
            selectionMode={checkPermission(permissions.isSuperuser) ? 'multiple' : 'single'}
            columns={columns}
            editable={false}
            sortable={true}
            value={billingTypes}
            ableToUpdate={true}
            dataloaded={dataLoaded}
            showClearFiltersButton={true}
            displayColumnFilter={true}
            paginator={true}
            filters={filters}
            selection={props.selectedBillingItemTypes}
            onSelectionChange={(e: any) => {
                if (props.setSelectedBillingItemTypes) props.setSelectedBillingItemTypes(e.value);
            }}
        />
    );
};