import React, { useEffect, useState } from 'react';
import { Image } from 'primereact/image';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {
    deleteMonitoringApp,
    getImages,
    getMonitoringApps,
    getMonitoringAppsStatus,
    getStatus
} from '../functions/DetailsService';
import UploadImage from './UploadImage';
import Doppelfilter from '../doppelfilter/Doppelfilter';
import EnergyEfficiency from '../energy-efficiency/energyEfficiency';
import { useTranslation } from 'react-i18next';
import { Buffer } from 'buffer';
import Alerts from '../alerts-table/Alerts';
import { Carousel } from 'primereact/carousel';
import {getSingleComponent} from '../functions/SubPlantsService';
import {hideWaitAnimation, showWaitAnimation} from '../../global/CustomWaitAnimation';
import {showMessageOnError, showMessageOnSuccess} from '../../global/CustomToast';
import PolynomialGraph from '../energy-efficiency/PolynomialGraph';
import {CustomConfirmDialog} from '../../global/CustomConfirmDialog';
import UpdateMonitoringApps from './UpdateMonitoringApps';
import {forEach} from 'ol/geom/flat/segments';

type DetailsProps = {
    projectId: string;
    colorTheme: string;
    setCustomBreadcrumb:any;
};
const Details = (props: DetailsProps) => {
    const { t } = useTranslation(['common']);

    const [uploadImageDialogue, setUploadImageDialogue] =
        useState<boolean>(false);
    const [noImage, setNoImage] = useState<any>(true);
    const [loading, setLoading] = useState<any>(false);
    const [imageData, setImageData] = useState<any>([]);
    const [status, setStatus] = useState<any>(0);
    const [filterView, setFilterView] = useState<boolean>(false);
    const [efficiencyView, setEfficiencyView] = useState<boolean>(false);
    const [plantName, setPlantName] = useState<string>('');
    const [sapNumber, setSapNumber] = useState<string>('');
    const [componentName, setComponentName] = useState<string>('');
    const [plantDetails, setPlantDetails] = useState<any>([]);
    const [monitoringApps, setMonitoringApps] = useState<any>([]);
    const [updateDialogue, setUpdateDialogue] = useState<boolean>(false);
    const [boxCount, setBoxCount] = useState<number  | undefined>(undefined);
    const [appValues, setAppValues] = useState<any>();
    const [formType, setFormType] = useState<string>('');
    const [updateStates, setUpdateStates] = useState<number>(0);
    const [monitoringAppId, setMonitoringAppId] = useState<string>('');
    const [monitoringAppTypeId, setMonitoringAppTypeId] = useState<string>('');

    const color = props.colorTheme === 'dark' ? 'white' : 'black';
    const bookmarkColor = props.colorTheme === 'dark' ? 'black' : 'white';
    const bgColorMiddle = props.colorTheme === 'dark' ? '#050505' : '#eaeaea';
    const bgColor = props.colorTheme === 'dark' ? '#333333' : '#ffffff';
    const borderColor = props.colorTheme === 'dark' ? '#444444' : '#efefef';
    const dropdownBgColor = props.colorTheme === 'dark' ? 'black' : 'white';
    const dropdownFontColor = props.colorTheme === 'dark' ? 'white' : 'black';

    const assignStatusValue = () => {
        let statusValue;
        if (status > 2) {
            statusValue = (
                <h3 className="details-heading" style={{ color: 'green' }}>
                    {status} A
                </h3>
            );
        } else {
            statusValue = (
                <h3 className="details-heading" style={{ color: 'red' }}>
                    {status}
                </h3>
            );
        }

        return statusValue;
    };

    const boxesViews = (item: any) => {
        if (
            item['monitoring_app_name'] === 'Mechanical Seal Filter' ||
            item['monitoring_app_name'] === 'Gleitringdichtungsfilter'
        ) {
            setFilterView(true);
            const plantId = localStorage.getItem('plantId');
            localStorage.setItem('monitoringAppId', item['monitoringAppId']);
            localStorage.setItem('monitoringAppTypeId', item['monitoringAppTypeId']);
            const url = location.hash.split('_');
            props.setCustomBreadcrumb([
                {
                    id: props.projectId,
                    label: plantName,
                    command: () => (window.location.hash = '#conditionomonitoring_' + plantId),
                },
                {
                    id: item['id'],
                    label: componentName,
                    command: () => {
                        window.location.hash = '#conditionomonitoring_' + url[1] + '_detail';
                    },
                },
                {
                    id: 'doppelfilter',
                    label: t('conditionMonitoring:seal_filter'),
                    command: () => {
                        window.location.hash = '#conditionomonitoring_' + url[1] + '_doppelfilter';
                    },
                },
            ]);
            window.location.hash =
                '#conditionomonitoring_' + url[1] + '_doppelfilter';
        }
        else if (
            item['monitoring_app_name'] === 'Energy Efficiency' ||
            item['monitoring_app_name'] === 'Energie-Effizienz'
        ) {
            // showMessageOnError(t('error'), t('conditionMonitoring:under_development'));
            setEfficiencyView(true);
            const plantId = localStorage.getItem('plantId');
            localStorage.setItem('monitoringAppId', item['monitoringAppId']);
            localStorage.setItem('monitoringAppTypeId', item['monitoringAppTypeId']);
            const url = location.hash.split('_');
            props.setCustomBreadcrumb([
                {
                    id: props.projectId,
                    label: plantName,
                    command: () => (window.location.hash = '#conditionomonitoring_' + plantId),
                },
                {
                    id: item['id'],
                    label: componentName,
                    command: () => {
                        window.location.hash = '#conditionomonitoring_' + url[1] + '_detail';
                    }
                },
                {
                    id: 'energy',
                    label: t('conditionMonitoring:energy_efficiency'),
                    command: () => () => {
                        window.location.hash = '#conditionomonitoring_' + url[1] + '_energy_efficiency';},
                },
            ]);
            window.location.hash =
                '#conditionomonitoring_' +
                url[1] +
                '_energy_efficiency';
        }
        else {
            showMessageOnError(t('error'), t('conditionMonitoring:not_developed'));
        }
    };

    const getAllImages = () => {
        const plantId:any = location.hash.split('_');
        getImages(props.projectId, plantId[1]).then((resp) => {
            if (resp.length > 0) {
                setNoImage(false);
                setLoading(true);
                setImageData(resp);
            } else {
                setNoImage(true);
                setLoading(false);
            }
        });
    };

    const imageUrl = window.location.origin + '/';

    const imageTemplate = (images: any) =>{
        return (
            <div className="surface-border border-round m-1 text-center checkDiv">
                <Image  src={
                    'data:image/png;base64,' +
                        Buffer.from(images['data'], 'binary').toString(
                            'base64'
                        )
                } className="w-15 shadow-2 imageSpan" imageClassName="imageDiv" preview/>

            </div>
        );
    };

    const nearestDivisible = (number: number) => {
        // const nonEmptyCount = monitoringApps.filter((obj: any) => Object.keys(obj).length > 0).length;

        if (number === 0 || number === 1 || number === 2 || number === 3 || number === 4 ) {
            return 5;
        }
        else if (number === 5 || number === 6 || number === 7 || number === 8 || number === 9 || number === 10) {
            return 10;
        }
    };
    const monitoringAPICall = (plantId: string)=>{
        getMonitoringApps(props.projectId, plantId[1]).then(async response =>{
            showWaitAnimation();
            const monitoringApp: any = [];
            await Promise.all(response.map(async (val: any)=>{
                const statusInfo = await getMonitoringAppsStatus(props.projectId, val['id']);
                let color;
                if (statusInfo['status'] === undefined || statusInfo['status'] === 0 || statusInfo['status'] === null) {
                    color = 'gray';
                }
                else if (statusInfo['status'] === 1) {
                    color = 'green';
                }
                else if (statusInfo['status'] === 2) {
                    color = 'yellow';
                }
                else if (statusInfo['status'] === 3) {
                    color = 'red';
                }
                const json = {
                    'monitoringAppId': val['id'],
                    'monitoring_app_name' : val['monitoring_app_name'],
                    'monitoringAppTypeId' : val['monitoring_app_type_id'],
                    'count': val['count'] + 1,
                    'status' : statusInfo['status'] === undefined ? 0 : statusInfo['status'],
                    'color': color,
                    'schematic_illustration_type_id': val['schematic_illustration_type_id']
                };
                monitoringApp.push(json);
            }));
            monitoringApp.sort((a:any,b:any) => a['count'] - b['count']);

            if (monitoringApp.length >= 0) {
                const foundObject = monitoringApp.find((obj: any) => obj.monitoring_app_name === 'Energie-Effizienz');
                if (foundObject) {
                    setMonitoringAppId(foundObject['monitoringAppId']);
                    setMonitoringAppTypeId(foundObject['monitoringAppTypeId']);
                }

            }

            const maxNum = monitoringApp.length > 0 ? monitoringApp[monitoringApp.length-1]['count'] : 0;
            const totalNumber = nearestDivisible(maxNum);
            const resultArray = [];
            if (totalNumber) {
                for (let index = 1; index < totalNumber + 1; index++) {
                    const currentItem = monitoringApp.find((item: any) => item['count'] === index);
                    if (currentItem) {
                        resultArray.push(currentItem);
                    } else {
                        resultArray.push({});
                    }
                }}

            setMonitoringApps(resultArray);

            hideWaitAnimation();
        });
    };

    useEffect(() => {
        const data = {
            'project_id': props.projectId,
            'monitoring_app_id': 'b210d324-d729-45f6-8301-fe2424e9ee8a',
            'channel_ids':['8904a468-b7e9-4104-aaa4-7423438ff035']
        };
        getStatus(data).then((resp) => {
            const val = resp['data']['5'];
            setStatus(val['value'].toFixed(val['chart_round']) + ' ' + val['unit']);
        });
        setPlantName(localStorage.getItem('plants')|| '');
        setComponentName(localStorage.getItem('component')|| '');
        const plantId:any = location.hash.split('_');
        getSingleComponent(props.projectId, plantId[1]).then(response=> {
            if (response.length > 0) {
                const spNum = response[0] && response[0]['element_parameter'][0]!== null ? response[0]['element_parameter'][0]['value'] : '';
                setSapNumber(spNum);
                setPlantDetails([{
                    'id': response[0]['element_id'],
                    'name': response[0]['element_name']
                }
                ]);
            }
            getAllImages();

        });

        monitoringAPICall(plantId);

        props.setCustomBreadcrumb([
            {id: 1, label: 'Condition Monitoring', command:()=>{window.location.hash = '#conditionomonitoring';}},
            {id: 2, label: localStorage.getItem('plants'), command:()=>{window.location.hash = '#conditionomonitoring_' + localStorage.getItem('plantId');}},
            {id: 3, label: localStorage.getItem('component'), command:()=>{window.location.hash = '#conditionomonitoring_' + localStorage.getItem('componentId');}}
        ]);

    }, []);
    return (
        <>
            {!filterView && !efficiencyView && (
                <>
                    {' '}
                    <div className="grid">
                        <div className="col-4">
                            <div className="card h-full slider-cover" style={{ backgroundColor: 'black' }}>
                                <i className="pi pi-pencil mr-2 editImage"
                                    onClick={() => setUploadImageDialogue(true)}
                                ></i>
                                {noImage ? (
                                    <>
                                        <Image
                                            src={imageUrl + '/conditionmonitoring/pck-logo.png'}
                                            height="418px"
                                            width="100%"
                                            preview
                                        />
                                        <p
                                            className="legend upload-image text-center"
                                            onClick={() => setUploadImageDialogue(true)}
                                        >
                                            <i className="pi pi-image mr-2"></i>
                                            {t('conditionMonitoring:upload_image')}
                                        </p>
                                    </>
                                ) : (
                                    <></>
                                )}

                                {loading ? (

                                    <Carousel value={imageData} numVisible={1} numScroll={1} orientation="horizontal"
                                        itemTemplate={imageTemplate} />
                                ) :
                                    (
                                        <></>
                                    )}
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="card h-full" style={{opacity: '0.6'}}>
                                <div className="card-content pt-2 adjustMargins">
                                    <div className="flex justify-content-between pr-3">
                                        <h3 className="details-heading">
                                            {t('conditionMonitoring:status')}:
                                        </h3>
                                        <h3 className="details-heading">{assignStatusValue()}</h3>
                                    </div>

                                    <div className="flex justify-content-between pr-3">
                                        <h3 className="details-heading">
                                            {t('conditionMonitoring:sap_equipment')}:
                                        </h3>
                                        <h3 className="details-heading">
                                            { sapNumber}
                                        </h3>
                                    </div>

                                    <div className="flex justify-content-between pr-3">
                                        <h3 className="p-bold">{t('conditionMonitoring:motor')}</h3>
                                    </div>

                                    <div className="flex justify-content-between pr-3">
                                        <h3 className="details-heading">
                                            {t('conditionMonitoring:operating_hour')} MotLagA:
                                        </h3>
                                        <h3 className="details-heading"> 1.235 h</h3>
                                    </div>

                                    <div className="flex justify-content-between pr-3">
                                        <h3 className="details-heading">
                                            {t('conditionMonitoring:last_repair')} :
                                        </h3>
                                        <h3 className="details-heading"> 24.12.20</h3>
                                    </div>

                                    <div className="flex justify-content-between pr-3">
                                        <h3 className="details-heading">
                                            {t('conditionMonitoring:run_time')}:{' '}
                                        </h3>
                                        <h3 className="details-heading"> 1.234 h</h3>
                                    </div>
                                    <div className="flex justify-content-between pr-3">
                                        <h3 className="details-heading">
                                            {t('conditionMonitoring:lubrication')} :
                                        </h3>
                                        <h3 className="details-heading">1.4.2021</h3>
                                    </div>
                                    <h3 className="p-bold">Pumpe</h3>

                                    <div className="flex justify-content-between pr-3">
                                        <h3 className="details-heading">
                                            {t('conditionMonitoring:operating_hour')} PpLagA:
                                        </h3>
                                        <h3 className="details-heading">123 h</h3>
                                    </div>
                                    <div className="flex justify-content-between pr-3">
                                        <h3 className="details-heading">
                                            {t('conditionMonitoring:last_repair')}:
                                        </h3>
                                        <h3 className="details-heading">24.12.20</h3>
                                    </div>

                                    <div className="flex justify-content-between pr-3">
                                        <h3 className="details-heading">
                                            {t('conditionMonitoring:run_time')}:
                                        </h3>
                                        <h3 className="details-heading">1.234 h</h3>
                                    </div>

                                    <div className="flex justify-content-between pr-3">
                                        <h3 className="details-heading mb-5">
                                            {t('conditionMonitoring:lubrication')}:
                                        </h3>
                                        <h3 className="details-heading"> 1.4.2021</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-5">
                            <div className="card plot h-full">
                                {monitoringAppId !== '' && monitoringAppTypeId !== '' && <PolynomialGraph projectId={props.projectId} monitoringAppId={monitoringAppId} monitoringAppTypeId={monitoringAppTypeId} />}
                            </div>
                        </div>
                    </div>
                    {uploadImageDialogue && (
                        <UploadImage
                            projectId={props.projectId}
                            modal={uploadImageDialogue}
                            imageData={imageData}
                            plantDetails={plantDetails}
                            hidePopup={() => {
                                setUploadImageDialogue(false);
                                getAllImages();
                            }}
                        />
                    )}
                    <div className="grid m-2">
                        <div className="col grid mb-2">
                            {
                                monitoringApps.map((item: any, index:number) => {
                                    if (item['monitoringAppId']) {
                                        return (
                                            <>
                                                <div
                                                    key={
                                                        item.monitoring_app_name + '_' + item.color
                                                    }
                                                    className="col flex flex-column card-container green-container card-example description-cards"
                                                    style={{
                                                        color: color,
                                                        fontWeight: 600,
                                                        background:
                                                        'linear-gradient(180deg, ' +
                                                        bgColor +
                                                        ' 0%, ' +
                                                        bgColorMiddle +
                                                        ' 0%, ' +
                                                        item.color +
                                                        ' 100%)',
                                                        borderColor: borderColor,
                                                    }}
                                                    onClick={() => {
                                                        boxesViews(item);
                                                    }}
                                                >
                                                    <div className="flex w-full">
                                                        <div className="grid w-full justify-content-end card-top-line">
                                                            <div className="col-auto m-auto card-menu">
                                                                <div className='dropdown'><i className="pi pi-ellipsis-h dropbtn" />
                                                                    <div className='dropdown-content'>
                                                                        <a onClick={(e)=> {
                                                                            e.stopPropagation();
                                                                            setBoxCount(item['count']);
                                                                            setAppValues(item);
                                                                            setFormType('edit');
                                                                            setUpdateDialogue(true);
                                                                        }} style={{
                                                                            color: dropdownFontColor, backgroundColor: dropdownBgColor
                                                                        }}>{t('edit')}</a>
                                                                        <a onClick={(e: any)=>{
                                                                            e.stopPropagation();
                                                                            CustomConfirmDialog({
                                                                                message: t('conditionMonitoring:plant_deleted_message') + item['monitoring_app_name'] + t('conditionMonitoring:plant_deleted_message_con'),
                                                                                header: t('confirmation'),
                                                                                translation: t,
                                                                                onConfirm: () => {
                                                                                    const data = {
                                                                                        project_id: props.projectId,
                                                                                        element_id: localStorage.getItem('componentId'),
                                                                                        monitoring_app_type_id: item['monitoringAppTypeId']
                                                                                    };
                                                                                    deleteMonitoringApp(data).then((resp:any) =>{
                                                                                        if (resp['error']) {
                                                                                            showMessageOnError(t('error'), t('error'));
                                                                                        } else {
                                                                                            showMessageOnSuccess(
                                                                                                t('conditionMonitoring:object_deleted'),
                                                                                                t('conditionMonitoring:object_deleted_first') + item['monitoring_app_name'] + t('conditionMonitoring:object_deleted_second')
                                                                                            );
                                                                                            const plantId:any = location.hash.split('_');
                                                                                            monitoringAPICall(plantId);

                                                                                        }
                                                                                    });
                                                                                }
                                                                            });
                                                                        }} style={{
                                                                            color: dropdownFontColor, backgroundColor: dropdownBgColor
                                                                        }}>{t('delete')}</a>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex w-full grid grid-nogutter">
                                                        <div className="grid grid-nogutter h-full">
                                                            <div className="col-12 h-auto">
                                                                {item.monitoring_app_name}
                                                                <br />
                                                                {/* <div className="subline">{item.subline}</div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="">*/}
                                                    {/*    {item.name === 'Vibration Diagnosis' ||*/}
                                                    {/*    item.name === 'Schwingungsdiagnose' ? (*/}
                                                    {/*            <h3*/}
                                                    {/*                className="mt-4"*/}
                                                    {/*                style={{*/}
                                                    {/*                    textAlign: 'center',*/}
                                                    {/*                    fontSize: '40px',*/}
                                                    {/*                    fontWeight: '800',*/}
                                                    {/*                }}*/}
                                                    {/*            >*/}
                                                    {/*            96*/}
                                                    {/*            </h3>*/}
                                                    {/*        ) : (*/}
                                                    {/*            <></>*/}
                                                    {/*        )}*/}
                                                    {/* </div>*/}
                                                </div>
                                            </>
                                        );
                                    }
                                    else {
                                        return (
                                            <div className='col flex flex-column card-container card-example description-cards demo-card' style={{justifyContent: 'center', borderStyle: 'dashed'}}
                                                onClick={()=>{
                                                    setBoxCount(index+1);
                                                    setFormType('create');
                                                    setUpdateDialogue(true);
                                                }}>
                                                <div className="flex w-full grid grid-nogutter" style={{justifyContent: 'center'}}>
                                                    <div className="grid grid-nogutter h-full">
                                                        <div className="col-12 h-auto" ><i className='pi pi-plus' style={{fontWeight: '800', fontSize: '45px'}}></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })
                            }

                            {/* {lastBoxContent}*/}
                        </div>
                    </div>

                    { updateDialogue && <UpdateMonitoringApps projectId={props.projectId} count={boxCount}
                        monitoringApps={appValues} type={formType} modal={updateDialogue} allApps={monitoringApps}
                        hidePopup={()=>{setUpdateDialogue(false); setAppValues([]); const plantId:any = location.hash.split('_');
                            monitoringAPICall(plantId);}} />}
                    <Alerts projectId={props.projectId} colorTheme={props.colorTheme}/>

                </>
            )}

            {efficiencyView && <EnergyEfficiency projectId={props.projectId} colorTheme={props.colorTheme} setCustomBreadcrumb={props.setCustomBreadcrumb}/>}
            {filterView && (
                <Doppelfilter
                    projectId={props.projectId}
                    colorTheme={props.colorTheme}
                    setCustomBreadcrumb={props.setCustomBreadcrumb}
                />
            )}
        </>
    );
};

export default Details;
