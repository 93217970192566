/*
 * OnlyOfficeEditor.tsx
 * Author: amusiol
 * Date: 04.07.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import settings from '../../config/settings';
import {getReportFileID} from '../functions/Reporting';

type Props = {
    urlToOpenFile?: string,
    urlToSaveFile?: string,
    plugins: boolean,
    fileId: string,
    projectId: string,
    fileType:string
}

export const OnlyOfficeEditor = (props: Props) => {
    const editorId = 'placeholder';
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [token, setToken] = useState('');
    const [editor, setEditor] = useState(null);

    useEffect(() => {
        const ooUrl = settings.ooUrl;
        const resourceUrl = ooUrl + '/web-apps/apps/api/documents/api.js';
        const script = document.createElement('script');
        script.src = resourceUrl;
        script.async = true;
        script.onload = () => setScriptLoaded(true);
        document.head.appendChild(script);
        return () => {
            if (editor !== null) {
                // @ts-ignore
                editor.destroyEditor();
            }
            document.head.removeChild(script);
        };
    }, []);

    // useEffect(() =>{
    //     keycloakfetch.get(settings.apiUrl + '/getOOToken').then(result => {
    //         if(result)
    //             setToken(result);
    //     });
    // },[]);

    useEffect(() => {
        if (scriptLoaded) {
            getReportFileID(props.projectId, props.fileId, props.fileType).then(result => {
                if (result) {
                    // console.log(result);
                    const conf = {
                        'document': {
                            'fileType': 'docx',
                            'key': (props.plugins)? result.id : 'doc_' + new Date().getTime(),
                            'title': (props.plugins)? 'Template' : 'Document',
                            'url': props.urlToOpenFile +result.id,
                            'forcesavetype': 0,
                            'permissions': {
                                'comment': true,
                                'copy': true,
                                'deleteCommentAuthorOnly': false,
                                'download': true,
                                'edit': true,
                                'editCommentAuthorOnly': false,
                                'fillForms': true,
                                'modifyContentControl': false,
                                'modifyFilter': true,
                                'print': true,
                                'review': false,
                                'chat': true
                            },
                        },
                        'documentType': 'word',
                        'height': '100%',
                        // 'token': token,
                        'type': 'desktop',
                        'width': '100%',
                        'editorConfig': {
                            'user': {
                                // @ts-ignore
                                'id': JSON.parse(localStorage.getItem('user').toString()).id,
                                // @ts-ignore
                                'name': JSON.parse(localStorage.getItem('user').toString()).first_name +' '+ JSON.parse(localStorage.getItem('user').toString()).last_name
                            },
                            'callbackUrl': props.urlToSaveFile+result.id,
                            'createUrl': '',
                            'lang': 'de',
                            'plugins': {
                                'autostart': [
                                    'asc.{94DF0B57-299D-4F68-AF6F-9A6BB53F3031}'
                                ]
                            },
                            'customization': {
                                'forcesave': true,
                                'plugins': props.plugins,
                                'zoom': 100,
                                'toolbarHideFileName': true,
                                'review':{
                                    'trackChanges': false
                                }
                            }
                        }
                    };
                    // console.log(conf);
                    // @ts-ignore
                    setEditor( new window.DocsAPI.DocEditor(editorId, conf));
                    // console.log(editor);
                    
                    // editor.destroyEditor();
                    // let eventMethod:any = window.addEventListener;
                    /* let eventer:any = window.addEventListener;
                    let messageEvent:string = "message";
                    eventer(messageEvent, function (e:any) {
                        //data you receive from iframe
                        console.log('from iFrame', e.data);
                        if(e.data == 'message you want to send'){
                            console.log('init iFrame communication');
                            var destination:any = document.getElementsByTagName("iframe")[0].contentWindow;
                            console.log(document.getElementsByTagName("iframe")[0].name);
                            destination = destination.document.getElementsByTagName("iframe")[0].contentWindow;
                            console.log(document.getElementsByTagName("iframe")[0].name);
                            destination.postMessage('uuid from project','*');
                        }
                    }); */

                    // let eventer:any = window.addEventListener;
                    // let messageEvent:string = "message";
                    // eventer(messageEvent, function (e:any) {
                    //     //data you receive from iframe
                    //     console.log('from iFrame', e.data);
                    //     const iframes: any = document.getElementsByTagName("iframe");
                    //     console.log("iframes: ", iframes);
                    //     for (const iframe of iframes) {
                    //
                    //     }
                    //     var destination:any = document.getElementsByTagName("iframe")[0];
                    //     console.log(destination);
                    //     if (typeof destination !== 'undefined')
                    //         destination = destination.document.getElementsByTagName("iframe")[0];
                    //     console.log(destination);
                    //     destination.postMessage(localStorage.getItem('access-token'),'*');
                    // });

                }
            });
        }

    }, [scriptLoaded, token]);


    return (<div id={editorId}/>);

};