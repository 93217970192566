import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Checkbox} from 'primereact/checkbox';
import {dependPermissions} from '../../config/permissions';

type Props = {
    allPermissions: any,
    selectedPermissions: any
    setSelectedPermissions: any
}

const RolePermissionList = (props: Props): JSX.Element  =>{
    const {t} = useTranslation(['common', 'roles']);

    const allPermissions = props.allPermissions;
    const selectedPermissions = JSON.parse(JSON.stringify(props.selectedPermissions));
    const setSelectedPermissions = props.setSelectedPermissions;
    const [all, setAll] = useState(false);
    const checkAll = (e:any) => {
        if (e.value===false) {
            setAll(true);
            setSelectedPermissions(allPermissions);
        } else {
            setAll(false);
            setSelectedPermissions([]);
        }
    };
    const onPermissionChange = (e: { value: any, checked: boolean }) => {
        const _selectedPermissions = [...selectedPermissions];
        console.log('_selectedPermissions: ', _selectedPermissions);
        if (e.checked) {
            // Füge das gerade ausgewählte Recht dem Array hinzu
            _selectedPermissions.push(e.value);
            // @ts-ignore
            const arr = dependPermissions[e.value.key.toString()];
            // Füge die, zum gerade ausgewählten Recht, abhängigen Rechte dem Array hinzu, wenn es noch nicht bereits
            // exisitiert
            for (const i in arr) {
                for (const perm of allPermissions) {
                    if (perm.key === arr[i] && _selectedPermissions.findIndex(ele => ele.key === perm.key) === -1) {
                        _selectedPermissions.push(perm);
                        break;
                    }
                }
            }
        } else {
            // Lösche das Recht direkt, wenn es keine Abhängigkeiten hat, ansonsten löschen auch die Abhängigkeiten
            const unselectedKey = e.value.key;
            const permissionDependedKeys = [];
            for (let i = 0; i < _selectedPermissions.length; i++) {
                const currentSelectedPermission = _selectedPermissions[i];
                if (currentSelectedPermission.key === unselectedKey) {
                    // Lösche das Recht
                    _selectedPermissions.splice(i, 1);

                    // Fülle das Array mit allen Rechten, die ebenfalls gelöscht werden sollen
                    for (const [key, value] of Object.entries(dependPermissions)) {
                        for (const depPermission of value) {
                            if (unselectedKey === depPermission) {
                                permissionDependedKeys.push(parseInt(key));
                            }
                        }
                    }
                }
            }
            // Lösche alle Rechte, die ebenfalls gelöscht werden sollen
            for (const dependedKey of permissionDependedKeys) {
                for (let y = 0; y < _selectedPermissions.length; y++) {
                    if (_selectedPermissions[y].key === dependedKey) {
                        _selectedPermissions.splice(y, 1);
                    }
                }
            }
        }

        console.log('_selectedPermissions: ', _selectedPermissions);

        setSelectedPermissions(_selectedPermissions);
    };

    return (<>
        <div key="checkAll" className="field-checkbox bg-gray-100 rowContainer">
            <Checkbox
                inputId={'checkAll'}
                name={'checkAll'}
                value={all}
                onChange={checkAll}
                checked={all}
            />
            <label htmlFor={'checkAll'}><b>{t('name')}</b></label>
        </div>
        {
            allPermissions.map((permission:any) => {
                return (
                    <div key={permission.key} className="field-checkbox rowContainer">
                        <Checkbox
                            inputId={permission.key}
                            name="category"
                            value={permission}
                            onChange={onPermissionChange}
                            checked={selectedPermissions.some((item:any) => item.key === permission.key)}
                            disabled={permission.key === 'R'}
                        />
                        <label htmlFor={permission.key}>{permission.label}</label>
                    </div>
                );
            })
        }
    </>);

};
export default RolePermissionList;