/*
 * DisablePeriodSelectList.tsx
 * Author: lnappenfeld
 * Date: 02.09.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import Input from '../global/Input';

type Props = {
    label: string,
    value?: number,
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    name?: string,
    disabled?: boolean,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
}

const DisablePeriodSelectList = (props: Props): JSX.Element => {

    const disablePeriods = [
        {value: '0', label: 'Zurücksetzen', durationInMinutes: 0},
        {value: '1', label: '30 Minuten', durationInMinutes: 30},
        {value: '2', label: 'Eine Stunde', durationInMinutes: 60},
        {value: '3', label: 'Zwei Stunden', durationInMinutes: 120},
        {value: '4', label: 'Drei Stunden', durationInMinutes: 180},
        {value: '5', label: 'Vier Stunden', durationInMinutes: 240},
        {value: '6', label: 'Acht Stunden', durationInMinutes: 480},
        {value: '7', label: 'Zwölf Stunden', durationInMinutes: 720},
        {value: '8', label: 'Ein Tag', durationInMinutes: 1440},
    ];

    const {t} = useTranslation(['common']);

    // set evaluationPeriod in parent component
    useEffect(() => {
        const disablePeriod = disablePeriods.filter((x: any) => {
            return x.value === props.value;
        })[0];
        if (props.setValue) props.setValue(disablePeriod);
        if (props.onChange) props.onChange(disablePeriod);
    }, []);

    return (
        <Input
            name={props.name ? props.name : 'evaluation_period'}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            edit={true}
            label={props.label}
            value={props.value}
            dropdownOptions={disablePeriods}
            type={'dropdown'}
            onChange={(e: any) => {
                const disablePeriod = disablePeriods.filter((x: any) => {
                    return x.value === e.value;
                })[0];
                if (props.setValue) props.setValue(disablePeriod);
                if (props.onChange) props.onChange(disablePeriod);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );

};

export default DisablePeriodSelectList;

