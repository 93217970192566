import keycloakfetch from '../../../functions/keycloakfetch';
import settings from '../../../config/settings';

export async function getImages (projectId: string, plantId: string) {
    const params = new URLSearchParams({
        project_id: projectId,
        element_id: plantId
    });
    const response = await keycloakfetch.get(settings.apiUrl + '/getElementFiles?' + params).then(response => {
        return response;
    });
    return response;

}

export async function getStatus (data: any) {
    let retValue : any = [];
    await keycloakfetch.post(settings.apiUrl + '/getCMOperatingDataLast', data).then(result => {
        if (result) {
            retValue = result;
        }
    });
    return retValue;
}

export async function uploadSubPlantImage(data: any) {
    let retValue: any = [];
    let secondValue: any = [];

    await keycloakfetch.put(settings.apiUrl +  '/insertFile', data).then((result) => {
        if (result)
            retValue = result;
    });

    const elementData = {
        project_id: data['project_id'],
        element_id: data['element_id'],
        file_id: retValue['id']
    };
    await keycloakfetch.put(settings.apiUrl + '/createElementFile', elementData).then((result)=> {
        if (result)
            secondValue = result;
    });

    return elementData;
}
  
export async function deleteSubPlantImage(data: any) {
    let retValue: any = [];
  
    await keycloakfetch.delete(settings.apiUrl +  '/deleteElementFile', data).then((result) => {
        if (result)
            retValue = result;
    });
   
    return retValue;
}

export async function getMonitoringApps(projectId: string, elementId: string) {
    let retValue : any = [];
    const params = new URLSearchParams({
        'project_id': projectId,
        'element_id' : elementId
    });
    await keycloakfetch.get(settings.apiUrl + '/getMappedMonitoringAppTypes?' + params).then(result => {
        if (result) {
            retValue = result;
        }
    });
    return retValue;
}

export async function getMonitoringAppsStatus(projectId: string, monitoringAppId: string) {
    let retValue : any = [];
    const params = new URLSearchParams({
        'project_id': projectId,
        'monitoring_app_id' : monitoringAppId
    });
    await keycloakfetch.get(settings.apiUrl + '/getCMMonitoringAppStatus?' + params).then(result => {
        if (result) {
            retValue = result;
        }
    });
    return retValue;
}

export async function getAllMonitoringApps(projectId: string) {
    let retValue : any = [];
    const params = new URLSearchParams({
        'project_id': projectId,
    });
    await keycloakfetch.get(settings.apiUrl + '/getMonitoringAppTypes?' + params).then(result => {
        if (result) {
            retValue = result;
        }
    });
    return retValue;
}

export async function createMonitoringApps(data: any){
    let retValue : any = [];
    await keycloakfetch.post(settings.apiUrl + '/createMappedMonitoringAppType', data).then(result => {
        if (result) {
            retValue = result;
        }
    });
    return retValue;
}

export async function updateMonitoringApps(data: any){
    let retValue : any = [];
    await keycloakfetch.put(settings.apiUrl + '/updateMappedMonitoringAppType', data).then(result => {
        if (result) {
            retValue = result;
        }
    });
    return retValue;
}

export async function deleteMonitoringApp(data: any){
    let retValue : any = [];
    await keycloakfetch.delete(settings.apiUrl + '/deleteMappedMonitoringAppType', data).then(result => {
        if (result) {
            retValue = result;
        }
    });
    return retValue;
}