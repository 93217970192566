import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';
import {Nullable} from './Global';


export type BillingItemType = {
    'id':                string,
    'project_id':        string;
    'project_name':      string;
    'billing_item_main_type_id':   number;
    'billing_item_main_type_name': string;
    'price':             number;
    'valid_from_date_utc': string;
}

export type BillingItem = {
    'billing_item_type_id':        string;
    'project_id':                  string;
    'project_name':                string;
    'billing_item_main_type_id':   number;
    'billing_item_main_type_name': string;
    'date':                        string;
    'quantity':                    number;
    'price':                       number;
    'total':                       number;
    'description':                 Nullable<string>;
    'timestamp':                   string;
    'billing_item_type_timestamp': string;
    'count':                       number;
    'valid_from_date_utc':         string;
}


export type BillingItemSumMonth = {
    'project_id':        string;
    'project_name':      string;
    'billing_item_main_type_id':   number;
    'billing_item_main_type_name': string;
    'sum_quantity':      number;
    'price':             number;
    'sum_total':         number;
    'description':       string;
    'date_month':        string;
}

export type BillingItemSumMonthProProject = {
    'project_id': string,
    'project_name': string,
    'project_timestamp_start': string,
    'project_timestamp_end': string,
    'is_project_active': boolean,
    'sum_total': number,
    'date_month': string
}

export type BillingItemSumTotalProProject = {
    'project_id': string,
    'project_name': string,
    'project_timestamp_start': string,
    'project_timestamp_end': string,
    'is_project_active': boolean,
    'sum': number
}

export async function getBillingItemMainTypes(projectId: string) {
    let retValue:any = [];
    await keycloakfetch.get(settings.apiUrl + '/getBillingItemMainTypes?project_id=' + projectId).then(result =>{
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getBillingItemTypes(projectId: string) {
    let retValue:any = [];
    await keycloakfetch.get(settings.apiUrl + '/getBillingItemTypes?project_id=' + projectId).then(result =>{
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createBillingItemType(data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/createBillingItemType', data).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function updateBillingItemType(data: any) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/updateBillingItemType', data).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function deleteBillingItemType(projectId: string, billingItemTypeIds: string[]) {
    let retValue: any = [];
    await keycloakfetch.delete(settings.apiUrl + '/deleteBillingItemTypes', {
        'project_id': projectId,
        'billing_item_type_ids': billingItemTypeIds
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getBillingItems(projectId: string) {
    let retValue:any = [];
    await keycloakfetch.get(settings.apiUrl + '/getBillingItems?project_id=' + projectId).then(result =>{
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getBillingItemSumMonth(projectId: string) {
    let retValue:any = [];
    await keycloakfetch.get(settings.apiUrl + '/getBillingItemSumMonth?project_id=' + projectId).then(result =>{
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getBillingItemSumMonthProProject() {
    let retValue:any = [];
    await keycloakfetch.get(settings.apiUrl + '/getBillingItemSumMonthProProject').then(result =>{
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getBillingItemSumTotalProProject() {
    let retValue:any = [];
    await keycloakfetch.get(settings.apiUrl + '/getBillingItemSumTotalProProject').then(result =>{
        if (result)
            retValue = result;
    });
    return retValue;
}
