import React, {useEffect, useState} from 'react';
import Input from '../global/Input';
import {getDataLinks} from '../functions/Metrology';

type Props = {
    projectId: string,
    label: string,
    list?: any, /* {value, label} */
    value?: string,
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
}
const DataLinkSelectList = (props: Props): JSX.Element => {

    const [list, setList] = useState(props.list);

    useEffect(() => {
        if (typeof props.list === 'undefined') {
            getDataLinks(props.projectId).then(result => {
                setList(result);
            });
        } else {
            setList(props.list);
        }
    }, []);

    return (
        <Input
            name={'datalink_id'}
            edit={true}
            label={props.label}
            scrollHeight={'500px'}
            dropdownOptions={list}
            type={'dropdown'}
            showClear={false}
            onChange={(e: any) => {
                if (props.setValue) props.setValue(e.value);
                if (props.onChange) props.onChange(e);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};
export default DataLinkSelectList;
