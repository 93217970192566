/*
 * SimpleOLMAp.tsx
 * Author: lnappenfeld
 * Date: 05.10.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, { useState, useEffect, useRef } from 'react';
import ol, {Feature, Map, Overlay, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import OSM from 'ol/source/OSM';
import {Attribution, Control, defaults as defaultControls} from 'ol/control';
import 'ol/ol.css';
import {Point} from 'ol/geom';
import {Circle, Text} from 'ol/style';
import {Fill, Stroke, Style} from 'ol/style';
import {fromLonLat, toLonLat, transformExtent} from 'ol/proj';
import {Draw, Modify, Snap} from 'ol/interaction';
import CircleStyle from 'ol/style/Circle';
import {loadEpsgScripts, transformCoords} from '../../services/SpatialService';
import '../../style/SimpleOLMap.scss';
import {XYZ} from 'ol/source';

class ToggleBaseLayerControl extends Control {
    /**
     * @param {Object} [opt_options] Control options.
     */
    constructor(opt_options) {
        const options = opt_options || {};

        const button = document.createElement('button');
        button.setAttribute('id', 'toggleBaseLayerButton');
        button.innerHTML = 'S';
        button.title = 'Sat/Strasse';

        const element = document.createElement('div');
        element.className = 'toggle-base-layer ol-unselectable ol-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target,
        });

        button.addEventListener('click', this.toggleBaseLayer.bind(this), false);
    }

    toggleBaseLayer(e) {
        e.preventDefault();
        const baseLayer = this.getMap().getLayers().getArray()[0];
        const button = document.getElementById('toggleBaseLayerButton');
        if (baseLayer.getSource() instanceof OSM) {
            baseLayer.setSource(new XYZ({
                attributions: ['Powered by Esri',
                    'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community'],
                url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            }));
            button.innerHTML = 'O';
        } else {
            baseLayer.setSource(new OSM());
            button.innerHTML = 'S';
        }
    }
}

/**
 * Map
 * The map is the core component of OpenLayers. For a map to render, a view, one or more layers, and a target container
 * are needed.
 *
 * View
 * The view determines how the map is rendered. It is used to set the resolution, the center location, etc. It is like
 * a camera through which we look at the map’s content.
 *
 * Layers
 * Layers can be added to the map in a stacked order. Layers can be either raster layers (images), or vector layers
 * (points/lines/polygons).
 *
 * Source
 * Each layer has a source, which knows how to load the layer content. In the case of vector layers, its source reads
 * vector data using a format class (for example GeoJSON or KML) and provides the layer with a set of features.
 *
 * Features
 * Features represent real world things and can be rendered using different geometries (like point, line or polygon)
 * using a given style, which determines its look (line thinkness, fill color, etc).
 *
 * A Map has layers -> A Layer has a source -> A Source has features
 */

function SimpleOLMap(props) {
    const [map, setMap] = useState();

    const [featuresLayer, setFeaturesLayer] = useState(new VectorLayer({}));
    const [allLocationsLayer, setAllLocationsLayer] = useState(new VectorLayer({}));
    const [feature, setFeature] = useState(null);
    const [allVisibleLocations, setAllVisibleLocations] = useState([]);

    const mapElement = useRef();
    const mapRef = useRef();
    mapRef.current = map;

    const getAllLocationsStyle = (location) => {
        const zoom = props.center.location_zoom;
        return new Style({
            text: zoom < 17 ? null : new Text({text: location.location_name, textAlign: 'center', textBaseline: 'top', offsetY: 10, font: 'bold 15px sans-serif'}),
            image: new Circle({
                fill: new Fill({
                    color: 'rgb(64, 64, 64)'
                }),
                radius: 5,
            })
        });
    };

    useEffect(() => {
        // Zeige den Namen als Text der anderen Punkte nur über einem Zoomlevel von 17
        updateAllLocationsStyle();
        if (map)
            map.getView().setZoom(props.center.location_zoom);
    }, [props.center.location_zoom]);

    const updateAllLocationsStyle = () => {
        if (allLocationsLayer.getSource()) {
            const features = allLocationsLayer.getSource().getFeatures();
            for (let i = 0; i < features.length; i++) {
                const _feature = features[i];
                const _location = allVisibleLocations[i];
                if (_location) {
                    const style = getAllLocationsStyle(_location);
                    _feature.setStyle(style);
                }
            }
        }
    };

    const addAllLocations = async (layer) => {
        const allLocations = [];
        for (const location of props.locations) {

            // continue, if position or epsg code is null
            if (!location.geo_position_x || !location.geo_position_y || !location.epsg_code) continue;

            // show all, but not the current location which should be edited
            if (location.location_id === props.location.location_id) continue;

            const transformedLocation = location;

            if (location.epsg_code) {
                const coords = [location.geo_position_x, location.geo_position_y, location.geo_position_z];
                const transformedCoords = await transformCoords(coords, location.epsg_code, 3857);
                // console.log('srcCode: ', props.location.epsg_code);
                // console.log('desCode: ', 3857);
                // console.log('coordinates: ', coords);
                // console.log('transformedCoords: ', transformedCoords);

                transformedLocation.geo_position_x = transformedCoords[0];
                transformedLocation.geo_position_y = transformedCoords[1];
                transformedLocation.geo_position_z = transformedCoords[2];
            }

            const feature = new Feature({
                // geometry: new Point(fromLonLat([props.location.geo_position_x, props.location.geo_position_y])),
                geometry: new Point([transformedLocation.geo_position_x, transformedLocation.geo_position_y]),
                name: props.location.name,
            });
            feature.setStyle(getAllLocationsStyle(transformedLocation));
            layer.getSource().addFeature(feature);

            allLocations.push(transformedLocation);
        }
        // console.log('Anzahl features: ', layer.getSource().getFeatures().length);
        // console.log('Anzahl locations: ', allLocations.length);
        setAllVisibleLocations(allLocations);
    };


    // mounting useEffect
    useEffect(() => {
        const center = props.center;

        // create and add vector source layer
        const source = new VectorSource();
        const initialFeaturesLayer = new VectorLayer({
            source: source,
            zIndex: 10
        });

        const initialAllLocationsLayer = new VectorLayer({
            source: new VectorSource(),
            zIndex: 10
        });
        (async () => {
            await addAllLocations(initialAllLocationsLayer);
        })();

        const interactions = [];

        if (!props.location.location_id) {
            // Add drawing interaction if new location should be created
            const draw = new Draw({
                type: 'Point',
                source: source,
                style: new Style({
                    image: new CircleStyle({
                        radius: 5,
                        fill: new Fill({color: 'black'}),
                    }),
                }),
            });

            draw.on('drawend', (event) => {
                const _feature = event.feature;
                const coordinates = _feature.getGeometry().getCoordinates();

                _feature.setStyle(new Style({
                    text: new Text({text: props.location.location_name, textAlign: 'center', textBaseline: 'top', offsetY: 10, font: 'bold 15px sans-serif'}),
                    image: new Circle({
                        fill: new Fill({
                            color: 'rgb(255,0,0)'
                        }),
                        stroke: new Stroke({
                            color: '#4b8cad',
                            width: 1.25,
                        }),
                        radius: 9,
                    })
                }));

                setFeature(_feature);

                // update input field in formular
                // props.onPositionChange(toLonLat(coordinates));
                props.onPositionChange([coordinates[0], coordinates[1], 0.0]);

                initialMap.removeInteraction(draw);
            });
            interactions.push(draw);
        } else {
            // Show current feature if current location should be updated
            (async () => {
                const mp = props.location;

                if (props.location.epsg_code) {
                    const coords = [props.location.geo_position_x, props.location.geo_position_y, props.location.geo_position_z];
                    const transformedCoords = await transformCoords(coords, props.location.epsg_code, 3857);
                    // console.log('srcCode: ', props.location.epsg_code);
                    // console.log('desCode: ', 3857);
                    // console.log('coordinates: ', coords);
                    // console.log('transformedCoords: ', transformedCoords);

                    mp.geo_position_x = transformedCoords[0];
                    mp.geo_position_y = transformedCoords[1];
                    mp.geo_position_z = transformedCoords[2];
                }

                const feature = new Feature({
                    // geometry: new Point(fromLonLat([props.location.geo_position_x, props.location.geo_position_y])),
                    geometry: new Point([mp.geo_position_x, mp.geo_position_y]),
                    name: props.location.name,
                });

                feature.setStyle(new Style({
                    text: new Text({text: props.location.location_name, textAlign: 'center', textBaseline: 'top', offsetY: 10, font: 'bold 15px sans-serif'}),
                    image: new Circle({
                        fill: new Fill({
                            color: 'rgb(255,0,0)'
                        }),
                        stroke: new Stroke({
                            color: '#4b8cad',
                            width: 1.25,
                        }),
                        radius: 9,
                    })
                }));

                initialFeaturesLayer.getSource().addFeature(feature);

            })();
        }

        // Add modify interaction
        const modify = new Modify({
            source: source,
            style: new Style({
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({color: 'black'}),
                }),
            }),
        });
        modify.on('modifyend', (event) => {
            // console.log('modifyend');

            if (!event.features || event.features.getArray().length <= 0) return;

            const _features = event.features.getArray();
            const coordinates = _features[0].getGeometry().getCoordinates();

            // update input field in formular
            // props.onPositionChange(toLonLat(coordinates));
            // props.onPositionChange(coordinates);
            props.onPositionChange([coordinates[0], coordinates[1], 0.0]);

        });
        interactions.push(modify);

        // create map
        const initialMap = new Map({
            controls: defaultControls({
                attribution: false,
                fullscreen: true,
                zoom: true
            }).extend([
                new Attribution({collapsible: false}),
                new ToggleBaseLayerControl()
            ]),
            target: mapElement.current,
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
                initialFeaturesLayer,
                initialAllLocationsLayer
            ],
            view: new View({
                projection: 'EPSG:3857',
                // center: fromLonLat([center.geo_position_x, center.geo_position_y]), // Long, Lat
                center: [center.geo_position_x, center.geo_position_y],
                zoom: center.location_zoom,
            }),

        });

        // add interactions to map
        for (const interaction of interactions) {
            initialMap.addInteraction(interaction);
        }

        let currZoom = initialMap.getView().getZoom();
        initialMap.on('moveend', function (e) {
            const newZoom = initialMap.getView().getZoom();
            if (currZoom !== newZoom) {
                currZoom = newZoom;
                props.onZoomChange(newZoom);
            }
        });

        // console.log(initialMap);
        setMap(initialMap);
        setFeaturesLayer(initialFeaturesLayer);
        setAllLocationsLayer(initialAllLocationsLayer);

    }, []);

    const setFeatureText = (feature) => {
        const image = feature.getStyle().image_;
        feature.setStyle(new Style({
            text: new Text({text: props.location.location_name, textAlign: 'center', textBaseline: 'top', offsetY: 10, font: 'bold 15px sans-serif'}),
            image: image
        }));
    };

    // Aktualisiere Feature, da die aktuellen Werte bei draw.on('drawend') noch nicht vorhanden sind...
    useEffect(() => {
        if (feature) {
            setFeatureText(feature);
        }

    }, [feature]);


    // update map if features prop changes - logic formerly put into componentDidUpdate
    useEffect(() => {
        if (props.location && props.location.geo_position_x && props.location.geo_position_y && featuresLayer) { // may be null on first render
            const mp = props.location;

            (async () => {
                if (props.location.epsg_code) {
                    const coords = [props.location.geo_position_x, props.location.geo_position_y, props.location.geo_position_z];
                    const transformedCoords = await transformCoords(coords, props.location.epsg_code, 3857);
                    // console.log('srcCode: ', props.location.epsg_code);
                    // console.log('desCode: ', 3857);
                    // console.log('coordinates: ', coords);
                    // console.log('transformedCoords: ', transformedCoords);

                    mp.geo_position_x = transformedCoords[0];
                    mp.geo_position_y = transformedCoords[1];
                    mp.geo_position_z = transformedCoords[2];
                }

                if (featuresLayer.getSource()) {
                    const _features = featuresLayer.getSource().getFeatures();
                    if (_features.length > 0) {
                        const _feature = _features[0];
                        // old
                        // _feature.setGeometry(new Point(fromLonLat([mp.geo_position_x, mp.geo_position_y])));
                        _feature.setGeometry(new Point([mp.geo_position_x, mp.geo_position_y]));

                        const image = _feature.getStyle().image_;
                        _feature.setStyle(new Style({
                            text: new Text({
                                text: props.location.location_name,
                                textAlign: 'center',
                                textBaseline: 'top',
                                offsetY: 10,
                                font: 'bold 15px sans-serif'
                            }),
                            image: image
                        }));
                    }
                }

            })();

            // fit map to feature extent (with 100px of padding)
            // map.getView().fit(featuresLayer.getSource().getExtent(), {
            //     padding: [100, 100, 100, 100],
            //     // maxZoom: 20
            // });

        }
    }, [props.location]);


    return (
        <div
            style={{height:'100%',width:'100%'}}
            ref={mapElement}
            className="map-container"
        />
    );
}

export default SimpleOLMap;