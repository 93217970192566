import React, {useEffect, useState} from 'react';
import Input from '../global/Input';
import {getChannels, getMeasuringDevices, getMeasuringManagers, getSensors} from '../functions/Metrology';
import {useTranslation} from 'react-i18next';
import {checkProjectType, ProjectType} from '../../functions/functionLibrary';

type Props = {
    type:string,
    projectId: string,
    onChange?: (e: any) => void,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    disabled?: boolean,
    showClear?: boolean
}

const MetrologySelectList = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    const [metrologyObject, setMetrologyObject] = useState<[]>([]);
    const [list, setList] = useState<any>(undefined);
    const [labelKey, setLabelKey] = useState('unknown');

    const makeLists = (result:any)=>{
        const tmp:any = [];
        const tmpObj: any = {};
        result.forEach((obj: any) => {
            let label = obj[props.type+'_name'] + ' (' + obj[props.type+'_type_name'] + ')';
            if (props.type === 'measuring_manager') {
                label = obj[props.type+'_name'];
            } else if (props.type === 'measuring_device' && checkProjectType(ProjectType.ConditionMonitoring)) {
                label = obj[props.type+'_name'] + ' (' + obj[props.type+'_type_name' ] + ' ' +
                    t('metrologyManagement:attributes.atMeasuringManager') + ' \'' +
                    obj['measuring_manager_name'] + '\')';
            }

            tmp.push({
                value: obj[props.type+'_id'],
                label: label
            });
            tmpObj[obj[props.type+'_id']] = obj;
        });
        setList(tmp);
        setMetrologyObject(tmpObj);
    };
    useEffect(() =>{
        switch (props.type) {
            case 'channel':
                setLabelKey('channel');
                getChannels(props.projectId).then(result => {
                    makeLists(result);
                });
                break;
            case 'sensor':
                setLabelKey('sensor');
                getSensors(props.projectId).then(result => {
                    makeLists(result);
                });
                break;
            case 'measuring_device':
                setLabelKey('metrologyManagement:attributes.measuringDevice');
                getMeasuringDevices(props.projectId).then(result => {
                    makeLists(result);
                });
                break;
            case 'measuring_manager':
                setLabelKey('metrologyManagement:attributes.measuringManager');
                getMeasuringManagers(props.projectId).then(result => {
                    makeLists(result);
                });
                break;
        }
    },[]);


    return (
        // <>{ list.length > 0 &&
        <Input
            name={props.type+'_id'}
            edit={true}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            showClear={typeof props.showClear === 'boolean' ? props.showClear : false}
            label={t(labelKey)}
            onChange={(e) => {
                console.log('metrologyObject: ', metrologyObject)
                if (props.onChange) props.onChange(metrologyObject[e.value]);
            }}
            dropdownOptions={list}
            type={'dropdown'}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
        // }</>
    );
};
export default MetrologySelectList;
