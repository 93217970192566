/*
 * PlotHighcharts.tsx
 * Author: fwunderlich
 * Date: 15.06.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useRef, useState} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import boost from 'highcharts/modules/boost';
import CustomEvents from 'highcharts-custom-events';
import HCAnnot from 'highcharts/modules/annotations';

type Props = {
    chartRef: any,
    options: any,
    id?: string
}

const PlotHighcharts = (props: Props): JSX.Element => {

    HCAnnot(Highcharts);
    CustomEvents(Highcharts);

    boost(Highcharts);

    useEffect(() => {
        (function (H) {
            // @ts-ignore
            function symbolWrap(proceed, symbol, x, y, w, h, options) {
                if (symbol.indexOf('text:') === 0) {
                    // @ts-ignore
                    const text = symbol.split(':')[1], svgElem = this.text(text, x, y)
                        .attr({
                            translateY: h,
                            translateX: -1
                        })
                        .css({
                            fontFamily: '"Font Awesome 5 Free"',
                            fontSize: h * 2,
                            'font-weight': 900
                        });

                    if (svgElem.renderer.isVML) {
                        // @ts-ignore
                        svgElem.fillSetter = function (value, key, element) {
                            // @ts-ignore
                            element.style.color = H.Color(value).get('rgb');
                        };
                    }
                    return svgElem;
                }
                // @ts-ignore
                return proceed.apply(this, [].slice.call(arguments, 1));
            }

            // @ts-ignore
            H.wrap(H.SVGRenderer.prototype, 'symbol', symbolWrap);
            // @ts-ignore
            if (H.VMLRenderer) {
                // @ts-ignore
                H.wrap(H.VMLRenderer.prototype, 'symbol', symbolWrap);
            }
        }(Highcharts));
    }, []);

    return (<div id={props.id}>
            <HighchartsReact
                ref={props.chartRef}
                highcharts={Highcharts}
                options={props.options}
                // allowChartUpdate={true}
                // immutable={true}
                // updateArgs={[true, true, true]}
                // callback={(c: any) => {
                //     console.log(c)
                //     // setChart(c);
                //     // chartRef.current = c;
                // }}
            />
        </div>
    );
};

export default PlotHighcharts;
