import {
    Channel,
    ChannelType,
    createChannel,
    getChannel,
    getChannelTypes,
    getDataLinkPorts,
    getLogicalChannelFunctions,
    getSensor,
    getSensorTypeChannelTypeMap,
    LogicalChannel,
    LogicalChannelFunction,
    Sensor,
    SensorTypeChannelTypeMap,
    updateChannel
} from '../functions/Metrology';
import React, {useEffect, useState} from 'react';
import Input from '../global/Input';
import DataLinkSelectList from './DataLinkSelectList';
import DataLinkPortSelectList from './DataLinkPortSelectList';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import TypesSelectList from './TypesSelectList';
import TimezoneSelectList from '../global/TimezoneSelectList';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {LogicalChannelFunctionField} from './LogicalChannelFunctionField';
import {LogicalChannelEditForm} from './LogicalChannelEditForm';
import {Nullable} from '../functions/Global';
import {TabPanel, TabView} from 'primereact/tabview';
import {checkProjectType, ProjectType} from '../../functions/functionLibrary';
import {ChannelCalibrationOverview} from './ChannelCalibrationOverview';
import {SensorSelectList} from './SensorSelectList';


type Props = {
    projectId: string,
    channelId?: Nullable<string>,
    onFinished: any,
    channelType?: Nullable<number>
}
const ChannelEditForm = (props: Props) => {
    const {t} = useTranslation(['common']);
    const [activeIndex, setActiveIndex] = useState(0);

    const defaultValues: any = {
        'channel_id': null,
        'channel_name': null,
        'description': null,
        'unit': null,
        'sensor_id': null,
        'project_id': props.projectId,
        'channel_type_id': props.channelType ? props.channelType : null,
        'timezone': 'Europe/Berlin',
        'media_link': null,
        'is_visible': true,
        'is_active': true,
        'max_allowed_data_age_in_hours': 24,
        'chart_min': null,
        'chart_max': null,
        'chart_round': null,
        'count': null,
        'datalink_id': null,
        'port': null,
        'is_logical': props.channelType ? (props.channelType === 140) : false, // 140: Berechnet
        'logical_channel_settings': {
            'operating_state_id': null,
            'lead_time': null,
            'follow_up_time': null,
            'recording_time': null,
            'measuring_interval': null,
            'sampling_rate': 5120,
            'scaling_factor': 1,
            'offset': 0,
            'cutoff_frequency': 2560,
            'number_of_values': null,
            'saving_condition_rmsa': false,
            'threshold_rmsa': null,
            'saving_condition_rmsv': false,
            'threshold_rmsv': null,
            'saving_condition_rmsd': false,
            'threshold_rmsd': null,
            'duration_in_seconds': null,
            'signal_type': 0,
            'save_data': true,
            'synchronous_measuring': false,
            'continually_measuring': false,
            'measuring_data_output': 0,
            'output_path': null,
            fp1: null,
            fp2: null,
            fp3: null,
            fp4: null,
            fp5: null,
            fp6: null,
            fp7: null
        }
    };
    const [sensor, setSensor] = useState<Sensor>();
    const [channel, setChannel] = useState<Channel>(defaultValues);
    const {control, formState: {errors}, handleSubmit, reset, getValues, setValue, watch, trigger} = useForm({defaultValues});

    const [logicalChannelFunctions, setLogicalChannelFunctions] = useState([]);
    const [dynamicFunctionFields, setDynamicFunctionFields] = useState([]);
    const [logicalChannelSettings, setLogicalChannelSettings] = useState<LogicalChannel>(Object);
    const [showPort, setShowPort] = useState(false);

    // Für das Mapping zwischen Sensor und ChannelType
    const [channelTypes, setChannelTypes] = useState<ChannelType[]>([]);
    // Initialisiere mit undefined, damit das Skeletonloading funktioniert
    const [selectableChannelTypes, setSelectableChannelTypes] = useState<ChannelType[]>();
    const [sensorTypeChannelTypeMap, setSensorTypeChannelTypeMap] = useState<SensorTypeChannelTypeMap[]>([]);

    // Damit der useEffect funktioniert, der alle Sensorangaben aus der Datenbank holt
    watch('sensor_id');
    // Damit die validationRules von der DataLinkPortSelectList funktioniert
    watch('datalink_id');

    const getFormErrorMessage = (name: string) => {
        if (name.includes('.')) {
            const elements = name.split('.');
            const inputName = elements[1];
            // @ts-ignore
            if (errors['logical_channel_settings'] && errors['logical_channel_settings'][inputName]) {
                // @ts-ignore
                return (errors['logical_channel_settings'][inputName] &&
                    // @ts-ignore
                    <small className="p-error custom">{errors['logical_channel_settings'][inputName].message}</small>);
            }
        } else {
            // @ts-ignore
            return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
        }
    };

    const _setChannelTypes = () => {
        getChannelTypes(props.projectId).then(result => {
            setChannelTypes(result);
            setSelectableChannelTypes(result);
        });
    };

    const _setSensorTypeChannelTypeMap = () => {
        getSensorTypeChannelTypeMap(props.projectId).then(result => {
            setSensorTypeChannelTypeMap(result);
        });
    };

    const _setLogicalChannelFunctions = async () => {
        return await getLogicalChannelFunctions(props.projectId).then(result => {
            const tmp: any = [];
            result.forEach((key: any) => {
                if (checkProjectType(key.project_type_id) || key.project_type_id === 3)
                    tmp.push({value: key.value, label: t('functions:global.' + key.label), definition: key.definition});
            });
            setLogicalChannelFunctions(tmp);
            return tmp;
        });
    };

    useEffect(() => {
        _setChannelTypes();
        _setSensorTypeChannelTypeMap();

        if (props.channelId) {
            getChannel(props.projectId, props.channelId).then(async result => {
                // Setzt die dynamischen Inputfelder der Berechungsfunktion des Kanals.
                // Aus asynchronen Gründen müssen zuerst die in der DB definierten logical_channel_functions gelesen
                // werden und dann an die Funktion _setFunctionFields übergeben werden.
                if (result.channel_type_id === 140) { // ChannelTyp: Berechnet
                    const _logicalChannelFunctions = await _setLogicalChannelFunctions();
                    if (result.logical_channel_settings) {
                        _setFunctionFields(result.logical_channel_settings.fp1, _logicalChannelFunctions);
                    }
                }
                setChannel(result);
                setLogicalChannelSettings(result.logical_channel_settings);
                if (result.port !== '' && !result.is_logical) {
                    setShowPort(true);
                }
                if (result.logical_channel_settings) {
                    // Umrechnung von [ms] in [s]
                    // (Im Frontend soll der Benutzer die Angabe des Messintervalls in Sekunden machen).
                    result.logical_channel_settings.measuring_interval = result.logical_channel_settings.measuring_interval / 1000;
                }

                reset(result);
            });

        }

    }, [props.channelId]);

    // Holt die in der Datenbank gespeicherten LogialChannelFunctions, damit der Benutzer die bei dem ChannelTyp
    // 'Berechnet' auswählen kann
    useEffect(() => {
        if (channel.channel_type_id === 140) { // ChannelTyp: Berechnet
            _setLogicalChannelFunctions();
        }
    }, [channel.channel_type_id]);

    useEffect(() => {
        if (getValues('sensor_id')) {
            getSensor(props.projectId, getValues('sensor_id')).then(sensor => {
                setSensor(sensor);
            });
        }
    }, [getValues('sensor_id')]);

    /**
     * Filtert die ChannelTypeSelectList, je nach dem welcher Sensor ausgewählt ist
     * @param sensor
     */
    const onSensorChange = (sensor: Sensor) => {
        // Wenn der channel_type angeben ist, z.B. Berechnet, darf man den nicht ändern und es
        // wird auch nicht gefiltert.
        if (props.channelType) {
            return;
        }

        setValue('channel_type_id', null);

        // Zeige wieder alle ChannelTypen an
        if (sensor === undefined) {
            setSelectableChannelTypes(channelTypes);
            return;
        }

        // Set selectable SensorTypes
        const currentChannelTypes = [] as ChannelType[];
        // Mapping zwischen Sensor und Channel muss korrekt sein
        for (const channelType of channelTypes) {
            for ( const mappingObject of sensorTypeChannelTypeMap) {
                if (mappingObject.sensor_type_id === sensor.sensor_type_id &&
                    mappingObject.channel_type_id === channelType.id) {
                    currentChannelTypes.push(channelType);
                }
            }
        }
        setSelectableChannelTypes(currentChannelTypes);
    };

    const onDataLinkChange = (event: any) => {
        const datalinkId = event.value;
        // Setze die neue datalink_id
        setValue('datalink_id', datalinkId);

        // Prüfe ob es in der Portliste des neuen Datalinks einen Port mit dem gleichen Namen gibt, wie der aktuell
        // ausgewählte Port. Wenn nicht, wird der neue aktuelle Port auf null gesetzt.
        const currentPort = getValues('port');
        getDataLinkPorts(props.projectId, datalinkId).then(ports => {
            const isCurrentPortInNewPorts = ports.some((port: any) => {
                return port.value === currentPort;
            });

            if (!isCurrentPortInNewPorts) {
                setValue('port', null);
            }
        });
    };

    const _setFunctionFields = (currentFunction: string, _logicalChannelFunctions: any = logicalChannelFunctions) => {
        const fields: any = [];

        // @ts-ignore
        for (const f of _logicalChannelFunctions) {

            if (currentFunction === f['value']) {
                let fset: any = [];
                let fsetTitle = '';
                for (const field in f['definition']) {
                    const def: LogicalChannelFunction = f['definition'][field];
                    if (field !== 'fp1') {
                        // Wenn neu angelegt wird (CREATE Channel), lade die in logical_channel_functions angegebenen
                        // Default-Werte
                        if (!props.channelId) {
                            const value = f['definition'][field]['value'];
                            setValue('logical_channel_settings.' + field, value);
                        }

                        const inputType = f['definition'][field]['input_type'];


                        if (f['definition'][field]['main_field_to'] || f['definition'][field]['main_field']) {
                            fset.push(
                                <LogicalChannelFunctionField
                                    projectId={props.projectId} name={field}
                                    definition={def} control={control} type={inputType}
                                    // setValue für Input type 'number'
                                    setValue={(value: any) => {

                                        setValue('logical_channel_settings.' + field, value);
                                    }}
                                    getFormErrorMessage={getFormErrorMessage}
                                />
                            );
                            fsetTitle = t('functions:fields.' + f['definition'][field]['group']);
                        } else {
                            if (fset.length > 0) {
                                fields.push(
                                    <fieldset>
                                        <legend><h5>&nbsp;{fsetTitle}&nbsp;</h5></legend>{fset}
                                    </fieldset>
                                );
                            }
                            fset = [];
                            fields.push(
                                <LogicalChannelFunctionField
                                    projectId={props.projectId} name={field}
                                    definition={def} control={control} type={inputType}
                                    // setValue für Input type 'number'
                                    setValue={(value: any) => {
                                        console.log('setValue: ', value)
                                        setValue('logical_channel_settings.' + field, value);
                                    }}
                                    getFormErrorMessage={getFormErrorMessage}
                                />
                            );
                        }
                    }
                }
                break;
            }
        }
        // return fields;
        setDynamicFunctionFields(fields);
    };

    const saveChannelData = (channel: Channel) => {

        if (channel.channel_type_id === 140 && channel.logical_channel_settings && channel.logical_channel_settings?.fp1 === 'difference') {
            // @ts-ignore
            channel.logical_channel_settings.fp5 = new Date();
            // @ts-ignore
            if (channel.logical_channel_settings.fp6?.indexOf('hour') < 0) {
                if (channel.logical_channel_settings?.fp6 === '')
                    channel.logical_channel_settings.fp6 = '1';
                channel.logical_channel_settings.fp6 = channel.logical_channel_settings.fp6 + ' hour';
            }
        } else if (channel.channel_type_id === 140 && channel.logical_channel_settings?.fp1 === 'addition') {
            // @ts-ignore
            if (channel.logical_channel_settings.fp7?.indexOf('hour') < 0) {
                if (channel.logical_channel_settings.fp7 === '')
                    channel.logical_channel_settings.fp7 = '1';
                channel.logical_channel_settings.fp7 = channel.logical_channel_settings.fp7 + ' hour';
            }
        } else if (channel.channel_type_id !== 140 && channel.logical_channel_settings) {
            // Umrechnung von [s] in [ms] (Im Frontend soll der Benutzer die Angabe des Messintervalls
            // in Sekunden machen, in der DB wird das in Millisekunden abgespeichert).
            // @ts-ignore
            channel.logical_channel_settings.measuring_interval = channel.logical_channel_settings.measuring_interval * 1000;
        }

        console.log(channel);

        if (channel.channel_id) {
            updateChannel(channel).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), result.error);
                } else {
                    showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.channelUpdated'));
                    props.onFinished(true);
                }
            });
        } else {
            createChannel(channel).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), result.error);
                } else {
                    showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.channelCreated'));
                    props.onFinished(true);
                }
            });
        }
    };

    const setDefaults = (channelType: ChannelType) => {
        channel['channel_type_id'] = channelType.id;
        channel['channel_name'] = channelType.name;
        channel['chart_min'] = channelType.chart_min ? channelType.chart_min : null;
        channel['chart_max'] = channelType.chart_max ? channelType.chart_max : null;
        channel['chart_round'] = channelType.chart_round ? channelType.chart_round : null;
        channel['unit'] = channelType.measuring_unit;
        channel['channel_name'] = channelType.name;
        channel['is_logical'] = channelType.is_logical;
        channel['timezone'] = 'Europe/Berlin';
        channel['is_visible'] = true;
        channel['is_active'] = true;

        channel['sensor_id'] = getValues('sensor_id');

        // Setze die Default-Werte der logical_channel_settings abhängig vom ausgewählten Channeltyp.
        // BEDA, HKBD, ZEIT, HKZT, MEDA, HKMD und Berechnet.
        // Bei allen anderen ChannelTypen wird alle Default-Werte von logical_channel_settings auf null gesetzt.
        switch (channelType.id) {
            case 200: // BEDA
            case 203: // HKBD
            {
                const logicalChannelSettings = {
                    'operating_state_id': null,     // Wird angegeben bei             ZEIT, HKZT, MEDA, HKMD
                    'lead_time': null,              // Wird angegeben bei BEDA, HKBD, ZEIT, HKZT, MEDA, HKMD...
                    'follow_up_time': null,         // Wird angegeben bei BEDA, HKBD, ZEIT, HKZT, MEDA, HKMD...
                                                    // ...aber nur wenn ein Betriebszustand ausgewählt ist
                    'recording_time': null,         // Wird berechnet
                    'measuring_interval': 1,        // Wird angegeben bei BEDA, HKBD, ZEIT, HKZT, MEDA, HKMD
                    'sampling_rate': 5120,          // Wird angegeben bei BEDA, HKBD, ZEIT, HKZT
                    'scaling_factor': 1,            // Wird angegeben bei BEDA, HKBD
                    'offset': 0,                    // Wird angegeben bei BEDA, HKBD
                    'cutoff_frequency': null,       // Wird angegeben bei                         MEDA, HKMD
                    'number_of_values': null,       // Wird berechnet
                    'saving_condition_rmsa': null,  // Wird angegeben bei                         MEDA, HKMD
                    'threshold_rmsa': null,         // Wird angegeben bei                         MEDA, HKMD
                    'saving_condition_rmsv': null,  // Wird angegeben bei                         MEDA, HKMD
                    'threshold_rmsv': null,         // Wird angegeben bei                         MEDA, HKMD
                    'saving_condition_rmsd': null,  // Wird angegeben bei                         MEDA, HKMD
                    'threshold_rmsd': null,         // Wird angegeben bei                         MEDA, HKMD
                    'duration_in_seconds': null,    // Wird angegeben bei              ZEIT, HKZT
                    'signal_type': 0,               // Wird angegeben bei BEDA, HKBD , ZEIT
                    'save_data': true,              // Wird angegeben bei BEDA, HKBD
                    'synchronous_measuring': null,  // Wird angegeben bei              ZEIT, HKZT
                    'continually_measuring': null,  // Wird angegeben bei              ZEIT, HKZT
                    'measuring_data_output': 0,
                    'output_path': null,
                    fp1: null,
                    fp2: null,
                    fp3: null,
                    fp4: null,
                    fp5: null,
                    fp6: null,
                    fp7: null
                };
                channel['logical_channel_settings'] = logicalChannelSettings;
                break;
            }
            case 202: // ZEIT
            case 205: // HKZT
            {
                const logicalChannelSettings = {
                    'operating_state_id': null,     // Wird angegeben bei             ZEIT, HKZT, MEDA, HKMD
                    'lead_time': null,              // Wird angegeben bei BEDA, HKBD, ZEIT, HKZT, MEDA, HKMD...
                    'follow_up_time': null,         // Wird angegeben bei BEDA, HKBD, ZEIT, HKZT, MEDA, HKMD...
                                                    // ...aber nur wenn ein Betriebszustand ausgewählt ist
                    'recording_time': null,         // Wird berechnet
                    'measuring_interval': 3600,     // Wird angegeben bei BEDA, HKBD, ZEIT, HKZT, MEDA, HKMD
                    'sampling_rate': 5120,          // Wird angegeben bei BEDA, HKBD, ZEIT, HKZT
                    'scaling_factor': null,         // Wird angegeben bei BEDA, HKBD
                    'offset': null,                 // Wird angegeben bei BEDA, HKBD
                    'cutoff_frequency': null,       // Wird angegeben bei                         MEDA, HKMD
                    'number_of_values': null,       // Wird berechnet
                    'saving_condition_rmsa': null,  // Wird angegeben bei                         MEDA, HKMD
                    'threshold_rmsa': null,         // Wird angegeben bei                         MEDA, HKMD
                    'saving_condition_rmsv': null,  // Wird angegeben bei                         MEDA, HKMD
                    'threshold_rmsv': null,         // Wird angegeben bei                         MEDA, HKMD
                    'saving_condition_rmsd': null,  // Wird angegeben bei                         MEDA, HKMD
                    'threshold_rmsd': null,         // Wird angegeben bei                         MEDA, HKMD
                    'duration_in_seconds': 1   ,    // Wird angegeben bei              ZEIT, HKZT
                    'signal_type': 0,               // Wird angegeben bei BEDA, HKBD , ZEIT
                    'save_data': null,              // Wird angegeben bei BEDA, HKBD
                    'synchronous_measuring': false, // Wird angegeben bei              ZEIT, HKZT
                    'continually_measuring': false, // Wird angegeben bei              ZEIT, HKZT
                    'measuring_data_output': 0,
                    'output_path': null,
                    fp1: null,
                    fp2: null,
                    fp3: null,
                    fp4: null,
                    fp5: null,
                    fp6: null,
                    fp7: null
                };
                channel['logical_channel_settings'] = logicalChannelSettings;
                break;
            }
            case 201: // MEDA
            case 204: // HKMD
            {
                const logicalChannelSettings = {
                    'operating_state_id': null,     // Wird angegeben bei             ZEIT, HKZT, MEDA, HKMD
                    'lead_time': null,              // Wird angegeben bei BEDA, HKBD, ZEIT, HKZT, MEDA, HKMD...
                    'follow_up_time': null,         // Wird angegeben bei BEDA, HKBD, ZEIT, HKZT, MEDA, HKMD...
                                                    // ...aber nur wenn ein Betriebszustand ausgewählt ist
                    'recording_time': null,         // Wird berechnet
                    'measuring_interval': 3600,     // Wird angegeben bei BEDA, HKBD, ZEIT, HKZT, MEDA, HKMD
                    'sampling_rate': null,          // Wird angegeben bei BEDA, HKBD, ZEIT, HKZT
                    'scaling_factor': null,         // Wird angegeben bei BEDA, HKBD
                    'offset': null,                 // Wird angegeben bei BEDA, HKBD
                    'cutoff_frequency': 2560,       // Wird angegeben bei                         MEDA, HKMD
                    'number_of_values': null,       // Wird berechnet
                    'saving_condition_rmsa': false, // Wird angegeben bei                         MEDA, HKMD
                    'threshold_rmsa': null,         // Wird angegeben bei                         MEDA, HKMD
                    'saving_condition_rmsv': false, // Wird angegeben bei                         MEDA, HKMD
                    'threshold_rmsv': null,         // Wird angegeben bei                         MEDA, HKMD
                    'saving_condition_rmsd': false, // Wird angegeben bei                         MEDA, HKMD
                    'threshold_rmsd': null,         // Wird angegeben bei                         MEDA, HKMD
                    'duration_in_seconds': null,    // Wird angegeben bei              ZEIT, HKZT
                    'signal_type': null,            // Wird angegeben bei BEDA, HKBD , ZEIT
                    'save_data': null,              // Wird angegeben bei BEDA, HKBD
                    'synchronous_measuring': null,  // Wird angegeben bei              ZEIT, HKZT
                    'continually_measuring': null,  // Wird angegeben bei              ZEIT, HKZT
                    'measuring_data_output': 0,
                    'output_path': null,
                    fp1: null,
                    fp2: null,
                    fp3: null,
                    fp4: null,
                    fp5: null,
                    fp6: null,
                    fp7: null
                };
                channel['logical_channel_settings'] = logicalChannelSettings;
                break;
            }
            case 140: // Berechnet
            {
                // Setze alle cm-values auf null
                const logicalChannelSettings = {
                    'operating_state_id': null,     // Wird angegeben bei             ZEIT, HKZT, MEDA, HKMD
                    'lead_time': null,              // Wird angegeben bei BEDA, HKBD, ZEIT, HKZT, MEDA, HKMD
                    'follow_up_time': null,         // Wird angegeben bei BEDA, HKBD, ZEIT, HKZT, MEDA, HKMD
                    'recording_time': null,         // Wird berechnet
                    'measuring_interval': null,     // Wird angegeben bei BEDA, HKBD, ZEIT, HKZT, MEDA, HKMD
                    'sampling_rate': null,          // Wird angegeben bei BEDA, HKBD, ZEIT, HKZT
                    'scaling_factor': null,         // Wird angegeben bei BEDA, HKBD
                    'offset': null,                 // Wird angegeben bei BEDA, HKBD
                    'cutoff_frequency': null,       // Wird angegeben bei                         MEDA, HKMD
                    'number_of_values': null,       // Wird berechnet
                    'saving_condition_rmsa': null,  // Wird angegeben bei                         MEDA, HKMD
                    'threshold_rmsa': null,         // Wird angegeben bei                         MEDA, HKMD
                    'saving_condition_rmsv': null,  // Wird angegeben bei                         MEDA, HKMD
                    'threshold_rmsv': null,         // Wird angegeben bei                         MEDA, HKMD
                    'saving_condition_rmsd': null,  // Wird angegeben bei                         MEDA, HKMD
                    'threshold_rmsd': null,         // Wird angegeben bei                         MEDA, HKMD
                    'duration_in_seconds': null,    // Wird angegeben bei              ZEIT, HKZT
                    'signal_type': null,            // Wird angegeben bei BEDA, HKBD , ZEIT
                    'save_data': null,              // Wird angegeben bei BEDA, HKBD
                    'synchronous_measuring': null,  // Wird angegeben bei              ZEIT, HKZT
                    'continually_measuring': null,  // Wird angegeben bei              ZEIT, HKZT
                    'measuring_data_output': null,
                    'output_path': null
                };
                channel['logical_channel_settings'] = logicalChannelSettings;
                break;
            }
            default:
            {
                // Setze alle logical_channel-values auf null
                const logicalChannelSettings = {
                    'operating_state_id': null,     // Wird angegeben bei             ZEIT, HKZT, MEDA, HKMD
                    'lead_time': null,              // Wird angegeben bei BEDA, HKBD, ZEIT, HKZT, MEDA, HKMD
                    'follow_up_time': null,         // Wird angegeben bei BEDA, HKBD, ZEIT, HKZT, MEDA, HKMD
                    'recording_time': null,         // Wird berechnet
                    'measuring_interval': null,     // Wird angegeben bei BEDA, HKBD, ZEIT, HKZT, MEDA, HKMD
                    'sampling_rate': null,          // Wird angegeben bei BEDA, HKBD, ZEIT, HKZT
                    'scaling_factor': null,         // Wird angegeben bei BEDA, HKBD
                    'offset': null,                 // Wird angegeben bei BEDA, HKBD
                    'cutoff_frequency': null,       // Wird angegeben bei                         MEDA, HKMD
                    'number_of_values': null,       // Wird berechnet
                    'saving_condition_rmsa': null,  // Wird angegeben bei                         MEDA, HKMD
                    'threshold_rmsa': null,         // Wird angegeben bei                         MEDA, HKMD
                    'saving_condition_rmsv': null,  // Wird angegeben bei                         MEDA, HKMD
                    'threshold_rmsv': null,         // Wird angegeben bei                         MEDA, HKMD
                    'saving_condition_rmsd': null,  // Wird angegeben bei                         MEDA, HKMD
                    'threshold_rmsd': null,         // Wird angegeben bei                         MEDA, HKMD
                    'duration_in_seconds': null,    // Wird angegeben bei              ZEIT, HKZT
                    'signal_type': null,            // Wird angegeben bei BEDA, HKBD , ZEIT
                    'save_data': null,              // Wird angegeben bei BEDA, HKBD
                    'synchronous_measuring': null,  // Wird angegeben bei              ZEIT, HKZT
                    'continually_measuring': null,  // Wird angegeben bei              ZEIT, HKZT
                    'measuring_data_output': null,
                    'output_path': null,
                    fp1: null,
                    fp2: null,
                    fp3: null,
                    fp4: null,
                    fp5: null,
                    fp6: null,
                    fp7: null
                };
                channel['logical_channel_settings'] = logicalChannelSettings;
                break;
            }
        }

        reset(channel);

        if (!channelType.is_logical) {
            setShowPort(true);
        } else {
            setShowPort(false);
        }
    };

    return (
        <TabView activeIndex={activeIndex} onTabChange={(e) => {setActiveIndex(e.index);}}>
            <TabPanel header={t('settings')}>
                <form id="formChannel" onSubmit={handleSubmit(saveChannelData)}>
                    <div className={'mt-3 ml-2 mr-2'}>
                        <div className={'grid card'}>
                            <div className={'col-6'}>
                                <SensorSelectList
                                    projectId={props.projectId}
                                    label={t('sensor')}
                                    name={'sensor_id'}
                                    onChange={onSensorChange}
                                    disabled={!!props.channelId}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('channel') + ' ' + t('input:required')}}
                                />
                                <TypesSelectList
                                    projectId={props.projectId}
                                    type={'channel'}
                                    list={selectableChannelTypes}
                                    onChange={(e) => {
                                        setDefaults(e);
                                    }}
                                    disabled={!!props.channelId || !!props.channelType || getValues('sensor_id') === null}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('metrologyManagement:attributes.channelType') + ' ' + t('input:required')}}
                                />
                                <Input
                                    edit={true} label={t('name')} name={'channel_name'}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('name') + ' ' + t('input:required')}}
                                />
                                <div className={'grid'}>
                                    <div className={'col-10'}>
                                        <Input
                                            edit={true} label={t('description')} name={'description'} type={'textarea'}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false}}
                                        />
                                    </div>
                                    <div className={'col-2'}>
                                        <Input
                                            edit={true} label={t('active')} name={'is_active'} type={'checkbox'}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'col-6'}>
                                <TimezoneSelectList
                                    label={t('timezone')}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('timezone') + ' ' + t('input:required')}}
                                />
                                <Input
                                    edit={true}
                                    label={t('unit')}
                                    name={'unit'}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('unit') + ' ' + t('input:required')}}
                                    // validationRules={{required: (channelType?.measuring_unit === null || channel.unit === null)
                                    //         ? false
                                    //         : t('unit') + ' ' + t('input:required')}}
                                />
                                {showPort && <>
                                    <DataLinkSelectList
                                        projectId={props.projectId}
                                        label={t('metrologyManagement:attributes.dataLink')}
                                        onChange={onDataLinkChange}
                                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                                        validationRules={{required: false}}
                                    />
                                    {getValues('datalink_id') &&
                                    <DataLinkPortSelectList
                                        projectId={props.projectId}
                                        dataLinkId={getValues('datalink_id')}
                                        channelId={props.channelId ? props.channelId : null}
                                        label={t('metrologyManagement:attributes.port')}
                                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                                        // Port und Datalink können immer nur zusammen mit dem Channel verknüpft werden.
                                        // Wenn die Ports aus port_status gelöscht werden, bleibt die Verknüpfung in
                                        // channel_map. Der Port wird aber nicht in dem Dropdown geladen. Dann kann man
                                        // aber trotzdem noch den Channel speichern.
                                        validationRules={{required: getValues('datalink_id') === null
                                            ? false
                                            : t('metrologyManagement:attributes.port') + ' ' + t('input:required')}}
                                    />}
                                </>}
                                {checkProjectType(ProjectType.ConditionMonitoring) &&
                                    (channel.channel_type_id >= 200 && channel.channel_type_id < 300) && // Zwischen 200 und 299
                                    channel.channel_type_id !== 210 && // Darf nicht vom Typ PiTag sein
                                <>
                                    {/*<Input // Aufnahmezeit dient dem Benuter nur als Hilfe. Wird nachträglich in den*/}
                                    {/*    // Endpunkten bestimmt*/}
                                    {/*    edit={true} disabled={true}*/}
                                    {/*    label={t('metrologyManagement:attributes.recordingTime')}*/}
                                    {/*    tooltip={t('metrologyManagement:tooltips.recordingTime')}*/}
                                    {/*    name={'logical_channel_settings.recording_time'}*/}
                                    {/*    type={'number'} maxFractionDigits={0} min={0}*/}
                                    {/*    value={getValues('logical_channel_settings.recording_time')}*/}
                                    {/*    onChange={(data: any) => {*/}
                                    {/*        setValue('logical_channel_settings.recording_time', data.value);*/}
                                    {/*    }}*/}
                                    {/*    validationControl={control} validationErrorMessage={getFormErrorMessage}*/}
                                    {/*    validationRules={{required: false}}*/}
                                    {/*/>*/}
                                    <Input
                                        edit={true}
                                        label={t('metrologyManagement:attributes.measuringInterval')}
                                        tooltip={t('metrologyManagement:tooltips.measuringInterval')}
                                        name={'logical_channel_settings.measuring_interval'}
                                        type={'number'} maxFractionDigits={0} min={0}
                                        value={getValues('logical_channel_settings.measuring_interval')}
                                        onChange={(data: any) => {
                                            setValue('logical_channel_settings.measuring_interval', data.value);
                                        }}
                                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                                        validationRules={{required: t('metrologyManagement:attributes.measuringInterval') + ' ' + t('input:required')}}
                                    />
                                </>}
                            </div>
                        </div>
                        {(channel.is_logical || channel.channel_type_id === 117 || channel.channel_type_id === 140) && // 117: Bild, 114: Berechnet
                        <div className={'grid card pb-0'}>
                            <h4>{t('metrologyManagement:attributes.channelSpecificParameter')}</h4>
                            {channel.channel_type_id === 140 && // Berechnet
                                <>
                                    <Input
                                        name={'logical_channel_settings.fp1'}
                                        edit={true}
                                        disabled={!!props.channelId} // die Berechnungsfunktion soll man nicht verändern können
                                        label={t('functions:global.logical_channel_functions')}
                                        tooltip={getValues('logical_channel_settings.fp1')
                                            ? t('functions:tooltips.' + getValues('logical_channel_settings.fp1'))
                                            : ''}
                                        dropdownOptions={logicalChannelFunctions}
                                        type={'dropdown'}
                                        onChange={(e: any) => {
                                            _setFunctionFields(e.value);
                                        }}
                                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                                        validationRules={{required: false}}
                                    />
                                    {dynamicFunctionFields}
                                </>
                            }
                            {channel.channel_type_id === 117 && // Bild
                                <Input
                                    edit={true} label={t('metrologyManagement:attributes.mediaLink')}
                                    name={'media_link'}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                />
                            }
                            {channel.is_logical && channel.channel_type_id !== 140 && sensor && // CM logical_channels
                                <div className={'col-12'}>
                                    <LogicalChannelEditForm
                                        channel={channel}
                                        logicalChannelSettings={logicalChannelSettings}
                                        sensor={sensor}
                                        getValues={getValues}
                                        setValue={setValue}
                                        watch={watch}
                                        control={control}
                                        getFormErrorMessage={getFormErrorMessage}
                                    />
                                </div>
                            }
                        </div>
                        }
                        <div className={'grid card'}>
                            <div className={'col-6'}>
                                <Input
                                    edit={true}
                                    label={t('metrologyManagement:attributes.maxAllowedDataAgeInHours')}
                                    tooltip={t('metrologyManagement:tooltips.maxAllowedDataAgeInHours')}
                                    name={'max_allowed_data_age_in_hours'}
                                    type={'number'}
                                    maxFractionDigits={1}
                                    onChange={(data: any) => {
                                        setValue('max_allowed_data_age_in_hours', data.value);
                                    }}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                />
                                <Input
                                    edit={true}
                                    label={t('metrologyManagement:attributes.chartRound')}
                                    tooltip={t('metrologyManagement:tooltips.chartRound')}
                                    name={'chart_round'}
                                    type={'number'}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                    value={getValues('chart_round')}
                                    onChange={(data: any) => {
                                        setValue('chart_round', data.value);
                                    }}
                                />
                            </div>
                            <div className={'col-6'}>
                                <Input
                                    edit={true}
                                    label={checkProjectType(ProjectType.ConditionMonitoring)
                                        ? t('metrologyManagement:attributes.chartMinAbsolute')
                                        : t('metrologyManagement:attributes.chartMinRelative')
                                    }
                                    tooltip={checkProjectType(ProjectType.ConditionMonitoring)
                                        ? t('metrologyManagement:tooltips.chartMinAbsolute')
                                        : t('metrologyManagement:tooltips.chartMinRelative')
                                    }
                                    name={'chart_min'}
                                    type={'number'}
                                    maxFractionDigits={5}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                    onChange={(data: any) => {
                                        setValue('chart_min', data.value);
                                    }}
                                />
                                <Input
                                    edit={true}
                                    label={checkProjectType(ProjectType.ConditionMonitoring)
                                        ? t('metrologyManagement:attributes.chartMaxAbsolute')
                                        : t('metrologyManagement:attributes.chartMaxRelative')
                                    }
                                    tooltip={checkProjectType(ProjectType.ConditionMonitoring)
                                        ? t('metrologyManagement:tooltips.chartMaxAbsolute')
                                        : t('metrologyManagement:tooltips.chartMaxRelative')
                                    }
                                    name={'chart_max'}
                                    type={'number'}
                                    maxFractionDigits={5}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                    onChange={(data: any) => {
                                        setValue('chart_max', data.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </TabPanel>

            {checkProjectType(ProjectType.GeoMonitoring) &&
            <TabPanel
                header={t('metrologyManagement:attributes.chanelCalibration')}
                disabled={!props.channelId}
            >
                <ChannelCalibrationOverview
                    projectId={props.projectId}
                    channelId={props.channelId ? props.channelId : null}
                />
            </TabPanel>}
        </TabView>
    );
};
export default ChannelEditForm;
