/*
 * ProjectDayBillingOverview.tsx
 * Author: lnappenfeld
 * Date: 27.08.2024
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {
    BillingItem,
    BillingItemSumMonth, getBillingItems,
    getBillingItemSumMonth,
} from '../functions/Billing';
import {getGroupedItems} from '../../functions/functionLibrary';
import {FilterMatchMode, FilterOperator} from 'primereact/api';

type Props = {
    projectId: string,
    reload: boolean,
    setReload: (reload: boolean) => void,
}

export const ProjectDayBillingOverview = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [billingItemSumMonth, setBillingItemSumMonth] = useState<BillingItem[]>([]);

    // arrays for filter dropdowns
    const [billingTypes, setBillingTypes] = useState<any[]>([]);
    const [filterDates, setFilterDates] = useState<any[]>([]);
    const [filterDatesValidFrom, setFilterDatesValidFrom] = useState<any[]>([]);

    useEffect(() => {
        _getBillingItems();
    }, []);

    useEffect(() => {
        if (props.reload) {
            props.setReload(false);
            _getBillingItems();
        }
    }, [props.reload]);

    const _getBillingItems = () => {
        getBillingItems(props.projectId).then(result => {
            setBillingItemSumMonth(result);
            setDataLoaded(true);

            console.log(result)

            // filter dropdown values
            setBillingTypes(getGroupedItems(result, 'billing_item_main_type_id', 'billing_item_main_type_name'));
            setFilterDates(getGroupedItems(result, 'date', 'date'));
            setFilterDatesValidFrom(getGroupedItems(result, 'valid_from_date_utc', 'valid_from_date_utc'));
        });
    };

    const columns: ColumnObject[] = [
        {
            field: 'billing_item_type_id', header: t('Id'),
            display: false
        }, {
            field: 'project_name', header: t('common:project'),
            filter: true, display: false
        }, {
            field: 'billing_item_main_type_id', header: t('typeId'), style: {maxWidth: '100px'},
            filter: false, display: false
        }, {
            field: 'billing_item_main_type_name', header: t('type'),
            filter: true, filterField: 'billing_item_main_type_id', filterDropdownValues: billingTypes
        }, {
            field: 'quantity', header: t('billing:attributes.sum_quantity'), type: 1,
            filter: true
        }, {
            field: 'price', header: t('billing:attributes.price'), type: 1,
            filter: true, currency: 'EUR'
        }, {
            field: 'total', header: t('billing:attributes.sum_total'), type: 1,
            filter: true, currency: 'EUR'
        }, {
            field: 'description', header: t('description'),
            filter: true, display: false
        }, {
            field: 'valid_from_date_utc', header: t('billing:attributes.billing_item_type_timestamp'), type: 4, dateFormat: 'DD.MM.YYYY',
            filter: true, filterField: 'valid_from_date_utc', filterDropdownValues: filterDatesValidFrom
        }, {
            field: 'date', header: t('common:day'), type: 4, dateFormat: 'DD MMMM YYYY',
            filter: true, filterField: 'date', filterDropdownValues: filterDates
        }
    ];

    const filters = {
        'project_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'billing_item_main_type_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'description': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'quantity': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'price': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'total': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'date': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'valid_from_date_utc': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        }
    };

    return (
        <CustomDataTable
            id='projectDayBillingOverview'
            columns={columns}
            filters={filters}
            editable={false}
            sortable={true}
            value={billingItemSumMonth}
            ableToUpdate={true}
            dataloaded={dataLoaded}
            showExportCSV={true}
            showClearFiltersButton={true}
            displayColumnFilter={true}
            paginator={true}
        />
    );
};