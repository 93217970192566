/*
 * GrafanaDashboardsOverview.tsx
 * Author: lnappenfeld
 * Date: 15.02.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */


import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getGrafanaDashboards, GrafanaDashboard, Nullable} from '../functions/Global';
import {checkPermission, getSumOf1ToN} from '../../functions/functionLibrary';
import {permissions} from '../../config/permissions';
import CustomDataTable from '../global/CustomDataTable';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import CustomDialog, {CustomDialogHeaderType} from '../global/CustomDialog';
import {GrafanaDashboardsEditForm} from './GrafanaDashboardsEditForm';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {deleteGrafanaDashboard, Project, reorderGrafanaDashboards} from '../functions/Project';

type Props = {
    project: Project,
    reload: boolean,
    setReload: (reload: boolean) => void
    selectedGrafanaDashboard: Nullable<GrafanaDashboard>,
    setSelectedGrafanaDashboard: (dashboard: Nullable<GrafanaDashboard>) => void
    showGrafanaDashboardDialog: boolean,
    setShowGrafanaDashboardDialog: (value: boolean) => void
}

const GrafanaDashboardsOverview = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [grafanaDashboards, setGrafanaDashboards] = useState<GrafanaDashboard[]>([]);

    const getGrafanaDashboardsFunc = () => {
        if (props.project && props.project.id)
            getGrafanaDashboards(props.project.id).then(dashboards => {
                // prüfe ob fehlerhafte counts in der Datenbank hinzugefügt wurden und korrigiere diese
                const targetSum = getSumOf1ToN(dashboards.length);
                let currentSum = 0;
                for (let i = 0; i < dashboards.length; i++) {
                    currentSum += dashboards[i].count;
                }
                if (currentSum !== targetSum) {
                    let j = 0;
                    for (let i = 0; i < dashboards.length; i++) {
                        const item = dashboards[i];
                        item.count = j + 1;
                        if (item.change === 0) item.change = 2;
                        if (item.change !== 3) j++;
                    }
                }
                setGrafanaDashboards(dashboards);
                setDataLoaded(true);
            });
    };

    useEffect(() => {
        getGrafanaDashboardsFunc();
    }, []);

    useEffect(() => {
        getGrafanaDashboardsFunc();
    }, [props.project]);

    useEffect(() => {
        if (props.reload) {
            getGrafanaDashboardsFunc();
            props.setReload(false);
        }
    }, [props.reload]);


    const columns = [
        {field: '', header: '', rowReorder: true, style: {maxWidth: '33px', paddingTop: '14px'}},
        {field: 'dashboard_id', header: 'DashboardId', style: {maxWidth: '400px'}},
        {field: 'name', header: t('name')},
        {field: 'mobile', header: t('forMobile'), style: {maxWidth: '120px'}},
    ];

    // Lösche Reorder-Spalte wenn der Benutzer nicht editieren darf
    if (!checkPermission(permissions.editGrafanaDashboards)) {
        columns.splice(0, 1);
    }

    const onRowReorder = (e: any) => {
        const reorderedDashboards = e.value;
        let j = 0;
        for (let i = 0; i < reorderedDashboards.length; i++) {
            const item = reorderedDashboards[i];
            item.count = j + 1;
            if (item.change === 0) item.change = 2;
            if (item.change !== 3) j++;
        }

        // TODO: updateGrafanaDashboard()
        const data = {
            'project_id': props.project.id,
            'grafanaDashboards': reorderedDashboards
        };
        reorderGrafanaDashboards(data).then(result => {
            if (result.error) {
                showMessageOnError(t('error'), result.error);
            } else {
                showMessageOnSuccess(t('success'), t('projectManagement:toasts.grafanaDashboardsReordered'));
                setGrafanaDashboards(reorderedDashboards);
                setDataLoaded(true);
            }
        });
    };

    return (
        <>
            <CustomDataTable
                id="grafana-dashboards"
                headerTitle={'Dashboards'}
                columns={columns}
                editable={checkPermission(permissions.editGrafanaDashboards)}
                sortable={false}
                value={grafanaDashboards}
                reorderableRows={true}
                onRowReorder={onRowReorder}
                onRemoveClick={(dashboard: any) => {
                    // props.setSelectedRole(null);
                    CustomConfirmDialog({
                        message: t('projectManagement:dialogs.deleteGrafanaDashboard.message'),
                        header: t('confirmation'),
                        translation: t,
                        onConfirm: () => {
                            if (props.project.id)
                                deleteGrafanaDashboard(props.project.id, dashboard.dashboard_id).then(result => {
                                    if (!result.error) {
                                        showMessageOnSuccess(t('success'), t('projectManagement:toasts.grafanaDashboardDeleted'));
                                        props.setReload(true);
                                    } else {
                                        showMessageOnError(t('error'), result.error);
                                    }
                                });
                        }
                    });
                }}
                onEditClick={(e: any) => {
                    props.setSelectedGrafanaDashboard(e);
                    props.setShowGrafanaDashboardDialog(true);
                }}
                ableToUpdate={checkPermission(permissions.editGrafanaDashboards)}
                ableToDelete={checkPermission(permissions.editGrafanaDashboards)}
                dataloaded={dataLoaded}
            />
            <CustomDialog
                onHide={() => {
                    props.setShowGrafanaDashboardDialog(false);
                }}
                visible={props.showGrafanaDashboardDialog}
                header={props.selectedGrafanaDashboard
                    ? t('projectManagement:dialogs.editGrafanaDashboard.header')
                    : t('projectManagement:dialogs.createGrafanaDashboard.header')}
                headerType={props.selectedGrafanaDashboard ? CustomDialogHeaderType.Update : CustomDialogHeaderType.Create}
                onCancel={() => {
                    props.setSelectedGrafanaDashboard(null);
                    props.setShowGrafanaDashboardDialog(false);
                }}
                formName={'grafanaDashboardForm'}
                style={{ width: '25vw' }}
            >
                <GrafanaDashboardsEditForm
                    project={props.project}
                    grafanaDashboard={props.selectedGrafanaDashboard}
                    onFinished={() => {
                        props.setSelectedGrafanaDashboard(null);
                        props.setShowGrafanaDashboardDialog(false);
                        props.setReload(true);

                        if (grafanaDashboards === undefined || grafanaDashboards.length <= 0) {
                            window.location.reload();
                        }
                    }}
                />
            </CustomDialog>
        </>
    );
};

export default GrafanaDashboardsOverview;
