import React from 'react';
import {useTranslation} from 'react-i18next';
import Input from '../global/Input';
import {useForm} from 'react-hook-form';
import {
    createMeasuringDevice,
    MeasuringDevice,
    updateMeasuringDevice,
} from '../functions/Metrology';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import TypesSelectList from './TypesSelectList';
import LocationSelectList from '../global/LocationSelectList';
import {Nullable} from '../functions/Global';
import CustomDialog, {CustomDialogHeaderType} from '../global/CustomDialog';
import {MeasuringDeviceEditForm} from './MeasuringDeviceEditForm';


type Props = {
    projectId: string,
    selectedSelectedMeasuringDevices: any,
    setSelectedMeasuringDevices: any,
    editMeasuringDevice: boolean,
    setEditMeasuringDevice: (data: boolean) => void,
    setMeasuringDevices?: any,
    setReloadMeasuringDevice: (data: boolean) => void,
}

export const MeasuringDeviceDialogEditForm = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);
    return (
        <CustomDialog
            header={props.selectedSelectedMeasuringDevices.length > 0 ? t('metrologyManagement:dialogs.editMeasuringDeviceDialog.header') : t('metrologyManagement:dialogs.createMeasuringDeviceDialog.header')}
            headerType={props.selectedSelectedMeasuringDevices.length > 0 ? CustomDialogHeaderType.Update : CustomDialogHeaderType.Create}
            formName={'formMeasuringDevice'}
            style={{ width: '45vw'}}
            onCancel={() => {
                props.setEditMeasuringDevice(false);
            }}
            onHide={() => {
                props.setEditMeasuringDevice(false);
            }}
            visible={props.editMeasuringDevice}
        >
            <MeasuringDeviceEditForm
                projectId={props.projectId}
                onFinished={() => {
                    props.setEditMeasuringDevice(false);
                    props.setSelectedMeasuringDevices([]);
                    props.setReloadMeasuringDevice(true);
                }}
                measuringDevice={props.selectedSelectedMeasuringDevices.length > 0 ? props.selectedSelectedMeasuringDevices[0] : null} />
        </CustomDialog>
    );

};

