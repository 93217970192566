import React, {useEffect, useState} from 'react';
import CustomDialog from '../global/CustomDialog';
import {Image} from 'primereact/image';
import {getUserFromLocalStorage} from '../functions/Global';
import {getHighchartsImage} from '../functions/Data';
import {hideWaitAnimation, showWaitAnimation} from '../global/CustomWaitAnimation';
import {useTranslation} from 'react-i18next';


type Props = {
    setShowDialog: any,
    projectId: string,
    channelId: string
}
export const HighchartsImageDialog = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);
    const [highchartsImage, setHighchartsImage] = useState(null);

    useEffect(() => {
        showWaitAnimation();
        const Theme = localStorage.getItem('Theme');
        const theme = Theme !== null && Theme !== 'undefined' ? Theme : 'light';
        const timezone = getUserFromLocalStorage().timezone;
        getHighchartsImage(props.projectId, props.channelId, timezone, theme).then(result => {
            setHighchartsImage(result);
            hideWaitAnimation();
        });
        return () => {
            setHighchartsImage(null);
            hideWaitAnimation();
        };
    }, []);
    return (<>
        {highchartsImage &&
            <CustomDialog
                onHide={() => {
                    props.setShowDialog(false);
                }}
                onCancel={() => {
                    props.setShowDialog(false);
                }}
                visible={true}
                maximized={false}
                showFooter={false}

                header={t('measurementData:global.measurementData')}>
                <Image
                    className="fm-image" src={'data:image/' + 'typeName.png' + ';base64, ' + highchartsImage}
                />
            </CustomDialog>}
    </>);
};
