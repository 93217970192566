import React, {useEffect, useState} from 'react';
import CustomDialog from '../../global/CustomDialog';
import {getPointChannels, Points} from '../functions/DoppelfilterConfigurations';
import CustomDataTable, {ColumnObject} from '../../global/CustomDataTable';
import {useForm} from 'react-hook-form';
import {showMessageOnError} from '../../global/CustomToast';
import {useTranslation} from 'react-i18next';
import {Button} from 'primereact/button';
import {ChannelDialogEditForm} from '../../metrology/ChannelDialogEditForm';
import {Nullable} from '../../functions/Global';

type CreateConfigurationProps = {
    projectId: string,
    modal: boolean,
    onHide: ()=>void,
    formType: string,
    rowValue: any
    channelName: string
    details: any
    onReturn: (val: any)=>void

}
const CreateConfiguration = (props: CreateConfigurationProps)=>{
    const { t } = useTranslation(['common']);
    const [createChannelDialogue, setCreateChannelDialogue] = useState<boolean>(false);
    const [channelType, setChannelType] = useState<Nullable<number>>(null);

    const [data, setData] = useState<any>([]);
    const [row, setRow] = useState<any>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const columns: ColumnObject[] = [
        {field: 'channel_name', header: t('conditionMonitoring:channel_name'), filter: false},
        {field: 'sensor_type_name', header: t('type'), filter: false},
        {field: 'measuring_device_name', header: t('measuring_device'), filter: false},
        {field: 'unit', header:t('unit'), filter: false},
        {field: 'sensor_name', header:t('sensor'), filter: false},
        {field: 'description', header: t('description'), filter: false}

    ];

    const {
        handleSubmit,
    } = useForm<any>({ });
    const saveData = () => {
        const isFound: any = [];
        props.details.map((value:any)=>{
            if (value['tag_name'] === row['channel_name']) {
                isFound.push({'isFound' : true});
            }
        });
        if (isFound.length >= 1) {
            showMessageOnError(t('error'), t('conditionMonitoring:channel_select_error'));
            return;
        }
        else {
            const rowValue = {
                'channel_id': row['channel_id'],
                'channel_name': row['channel_name'],
                'unit': row['unit'],
                'number': props.rowValue['number'],
            };
            props.onReturn(rowValue);
        }

    };

    const getData = ()=>{
        getPointChannels(props.projectId).then(resp => {
            setData(resp);
            setDataLoaded(true);
            if (props.channelName !== '---') {
                resp.map((val:any)=>{
                    if (val['channel_name'] === props.channelName) {
                        setRow(val);
                    }
                });
            }
        });
    };
    useEffect(()=>{
        getData();
    },[]);

    return (<>
        <CustomDialog onCancel={()=>{props.onHide();}} visible={props.modal} header={t('conditionMonitoring:update_point')} showFooter={true} headerType={props.formType === 'edit' ? 3 : 2 } formName={'pointForm'} style={{width: '1500px'}} submitText={t('save')}>
            <div className="grid">
                <Button
                    label={t('metrologyManagement:buttons.createVirtualChannel')}
                    icon="pi pi-plus"
                    className="p-button-text p-button-raised"
                    onClick={() => {
                        setChannelType(140);
                        setCreateChannelDialogue(true);

                    }}
                />
                <Button
                    label={t('metrologyManagement:buttons.createChannel')}
                    icon="pi pi-plus"
                    className="p-button-text p-button-raised ml-2"
                    onClick={() => {
                        setChannelType(null);
                        setCreateChannelDialogue(true);
                    }}
                />
            </div>

            {createChannelDialogue && <ChannelDialogEditForm
                projectId={props.projectId}
                selectedChannels={[]}
                setSelectedChannels={() => {}}
                selectedChannelType={channelType}
                editChannel={createChannelDialogue}
                setEditChannel={setCreateChannelDialogue}
                setReloadChannel={() => {
                    getData();
                }}
            />}


            <form id="pointForm" onSubmit={(e) => {
                e.preventDefault();
                const element: any = e.target;
                if (element.id !== 'pointForm') return;
                saveData();
                return;
            }}>
                <CustomDataTable id={'configurations'} columns={columns} sortable={true} value={data} editable={false} dataloaded={dataLoaded} selectionMode={'single'} selection={row} onRowClick={(e)=>{
                    setRow(e.data);
                }}
                />
            </form>
        </CustomDialog>
    </>);


};

export default CreateConfiguration;