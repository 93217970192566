/*
 * LocationOverview.tsx
 * Author: lnappenfeld
 * Date: 31.08.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */
import React, {useEffect, useState} from 'react';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {useTranslation} from 'react-i18next';
import {CustomConfirmDialog} from './CustomConfirmDialog';
import {showMessageOnError, showMessageOnSuccess} from './CustomToast';
import {deleteLocation, getEPSGCodes, getLocations, getProjectGroups, Location} from '../functions/Global';
import {filterTriStateCheckBox} from './filter';
import {checkPermission} from '../../functions/functionLibrary';
import {permissions} from '../../config/permissions';
import {FilterMatchMode, FilterOperator} from 'primereact/api';

type Props = {
    project: any,
    reload?:boolean,
    setReload?:(reload:boolean)=>void
    setSelectedLocations: (data: Location[]) => void
    selectedLocations: Location[],
    editLocation?: boolean,
    setEditLocation?: (data: boolean) => void,
}
export const LocationOverview = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common', 'metrology']);

    const [locations, setLocations] = useState<Location[]>([]);

    // arrays for filter dropdowns
    const [projectGroups, setProjectGroups] = useState([]);
    const [epsgCodes, setEpsgCodes] = useState([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const _setLocations = () => {
        getLocations(props.project.id).then(result => {
            setLocations(result);
            setDataLoaded(true);
        });
    };

    const _setProjectGroups = () => {
        getProjectGroups(props.project.id).then(result => {
            setProjectGroups(result);
        });
    };

    const _setEpsgCodes = () => {
        getEPSGCodes(props.project.id).then(result => {
            const tmp: any = [];
            result.forEach((key: any) => {
                tmp.push({
                    value: key.code,
                    name: key.name,
                });
            });
            setEpsgCodes(tmp);
        });
    };

    useEffect(() => {
        _setProjectGroups();
        _setEpsgCodes();
        _setLocations();
    }, []);

    useEffect(() => {
        // Lade neu, z.B. wenn Elemente gelöscht wurden
        if (props.reload) {
            _setLocations();
            if (props.setReload) props.setReload(false);
        }
    }, [props.reload]);

    const filterCheckboxIsVisible = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), t('visible'), t('hidden'));
    };
    const columns: ColumnObject[] = [{
        field: 'is_visible',
        header: t('visible'),
        filter: true,
        filterElement: filterCheckboxIsVisible,
        style: {maxWidth: '120px'}
    }, {
        field: 'location_id',
        header: t('locations:attributes.locationId'),
        filter: false,
        display: false
    },{
        field: 'location_name',
        header: t('name'),
        filter: true,
    }, {
        field: 'project_group_id',
        header: t('projectManagement:attributes.projectGroupId'),
        filter: false,
        display: false
    },{
        field: 'project_group_name',
        header: t('projectManagement:attributes.projectGroup'),
        filter: true,
        filterField: 'project_group_id',
        filterDropdownValues: projectGroups
    }, {
        field: 'location_description',
        header: t('description'),
        filter: true
    },{
        field: 'geo_position',
        header: t('position'),
        filter: false
    }, {
        field: 'epsg_code_name',
        header: t('locations:attributes.epsg'),
        filter: true,
        filterField: 'epsg_code',
        filterDropdownValues: epsgCodes
    }];

    const filters = {
        'is_visible': {
            operator: FilterOperator.AND,
            constraints: [{value: true, matchMode: FilterMatchMode.EQUALS}]
        },
        'project_group_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'location_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'location_description': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'epsg_code': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        }
    };

    if (props.project.project_groups.length === 1) {
        // Lösche project_group_id und project_group_name, wenn das Projekt nur eine Gruppe hat
        const index1ToDelete = columns.findIndex(obj => obj.field === 'project_group_id');
        if (index1ToDelete !== -1) {
            columns.splice(index1ToDelete, 1);
        }
        const index2ToDelete = columns.findIndex(obj => obj.field === 'project_group_name');
        if (index2ToDelete !== -1) {
            columns.splice(index2ToDelete, 1);
        }
    }

    return (
        <>
            <CustomDataTable
                id='locations'
                selectionMode= 'multiple'
                columns={columns}
                editable={checkPermission(permissions.editMetrology)}
                sortable={true}
                displayColumnFilter={true}
                value={locations}
                selection={props.selectedLocations}
                onSelectionChange={(e: any) => {
                    if (props.setSelectedLocations) props.setSelectedLocations(e.value);
                }}
                onRemoveClick={(data: Location) => {
                    CustomConfirmDialog({
                        message: t('locations:deleteLocationDialog.message'),
                        header: t('locations:deleteLocationDialog.header'),
                        translation: t,
                        onConfirm: () => {
                            deleteLocation(props.project.id, [data.location_id]).then(result => {
                                if (result.error) {
                                    showMessageOnError(t('error'), t('errorMessages:' + result.error));
                                } else {
                                    _setLocations();
                                    showMessageOnSuccess(t('success'), t('locations:toasts.locationDeleted'));
                                }
                            });
                        }
                    });
                }}
                onEditClick={(data: Location) => {
                    if (props.setSelectedLocations) props.setSelectedLocations([data]);
                    if (props.setEditLocation) props.setEditLocation(true);
                }}
                ableToDelete={checkPermission(permissions.editMetrology)}
                ableToUpdate={checkPermission(permissions.editMetrology)}
                filters={filters}
                dataloaded={dataLoaded}
                showExportCSV={true}
                showClearFiltersButton={true}
            />
        </>
    );
};
