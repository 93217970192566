/*
 * SystemManagement.tsx
 * Author: lnappenfeld
 * Date: 18.08.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {checkPermission} from '../functions/functionLibrary';
import {permissions} from '../config/permissions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import LayoutMainContentTabMenu from '../components/global/LayoutMainContentTabMenu';
import ViewButtons from '../components/global/ViewButtons';
import {SystemSettingsOverview} from '../components/systemManagement/SystemSettingsOverview';
import {Nullable} from '../components/functions/Global';
import {SystemSetting} from '../components/functions/System';
import {buttonCreateSystemSetting, makeButtonItem} from '../components/global/viewButtonSelection';
import CustomDialog, {CustomDialogHeaderType} from '../components/global/CustomDialog';
import {SystemSettingForm} from '../components/systemManagement/SystemSettingForm';
import {faScrewdriverWrench} from '@fortawesome/free-solid-svg-icons';

export const SystemManagement = (): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [selectedSystemSetting, setSelectedSystemSetting] = useState<Nullable<SystemSetting>>(null);
    const [showSystemSettingDialog, setShowSystemSettingDialog] = useState<boolean>(false);
    const [reloadSystemSettings, setReloadSystemSettings] = useState<boolean>(false);

    const menuItems = [
        {
            id: 'settings',
            label: t('sysM_systemSettings'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faScrewdriverWrench}/>,
            component: <SystemSettingsOverview
                reload={reloadSystemSettings}
                setReload={setReloadSystemSettings}
                setSelectedSystemSetting={setSelectedSystemSetting}
                setShowSystemSettingDialog={setShowSystemSettingDialog}
            />,
            projectRequired: true,
            visible: checkPermission(permissions.isAdmin),
            buttons: checkPermission(permissions.isAdmin) ? (
                <ViewButtons
                    title={t('')}
                    items={[
                        makeButtonItem(buttonCreateSystemSetting, t, () => {
                            setSelectedSystemSetting(null);
                            setShowSystemSettingDialog(true);
                        }),
                    ]}
                />
            ) : null
        }
    ];

    return (<>
        <LayoutMainContentTabMenu items={menuItems} />
        <CustomDialog
            onHide={() =>{ setShowSystemSettingDialog(false);}}
            visible={showSystemSettingDialog}
            header={selectedSystemSetting
                ? t('systemManagement:dialogs.editSystemSetting.header')
                : t('systemManagement:dialogs.createSystemSetting.header')}
            headerType={selectedSystemSetting ? CustomDialogHeaderType.Update : CustomDialogHeaderType.Create}
            onCancel={() => setShowSystemSettingDialog(false)}
            formName={'systemSettingForm'}
            style={{ width: '25vw'}}
        >
            <SystemSettingForm
                systemSetting={selectedSystemSetting}
                onFinished={()=>{
                    setSelectedSystemSetting(null);
                    setShowSystemSettingDialog(false);
                    setReloadSystemSettings(true);
                }}
            />
        </CustomDialog>
    </>);
};