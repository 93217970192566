/*
 * GrafanaDashboard.tsx
 * Author: lnappenfeld
 * Date: 17.10.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */
import React, {useState, useEffect, useRef, createRef} from 'react';
import {getGrafanaDashboards} from '../components/functions/Global';
import LayoutMainContentTabMenu from '../components/global/LayoutMainContentTabMenu';
import {checkPermission} from '../functions/functionLibrary';
import {permissions} from '../config/permissions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMaximize, faUpRightFromSquare} from '@fortawesome/free-solid-svg-icons';
import {Button} from 'primereact/button';
import settings from '../config/settings';

type IFrameProps = {
    dashboardId: any
    url: string,
    mayEditGrafanaDashboards: boolean
}

const IFrameWrapper = (props: IFrameProps): JSX.Element => {

    const iframeRef = useRef<HTMLIFrameElement>(null);

    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noreferrer');
    };

    return (
        <>
            <div className={'grafana-buttons'}>
                <Button
                    style={{float: 'right'}} className={'p-button m-1'}
                    onClick={() => {
                        // set iframe to fullscreen
                        if (iframeRef && iframeRef.current) {
                            const elem = iframeRef.current;
                            // @ts-ignore
                            elem.requestFullscreen().catch((e) => {
                                console.log('error: ', e);
                            });
                        }
                    }}
                >
                    <FontAwesomeIcon className='sidebar-icon' icon={faMaximize}/>
                </Button>
                {props.mayEditGrafanaDashboards &&
                <Button
                    style={{float: 'right'}} className={'p-button m-1'}
                    onClick={() => {
                        // link to grafana
                        openInNewTab(props.url);
                    }}
                >
                    <FontAwesomeIcon className='sidebar-icon' icon={faUpRightFromSquare}/>
                </Button>
                }
            </div>
            <iframe
                ref={iframeRef}
                allowFullScreen={true}
                style={{"borderRadius": "6px"}}
                src={props.url}
                width="100%"
                height="100%"
                id={'dashboard_' + props.dashboardId}
                className=""
                frameBorder="0"
            />
        </>
    );
};

type Props = {
    projectId: any
}

const GrafanaDashboard = (props: Props): JSX.Element => {

    const isAdmin = checkPermission(permissions.isAdmin);
    const editGrafanaDashboards = checkPermission(permissions.editGrafanaDashboards);
    const [dashboards, setDashboards] = useState<any>([]);

    useEffect((() => {
        const board: any = [];
        getGrafanaDashboards(props.projectId).then(result => {
            result.forEach((key: any) => {
                const url = settings.grafanaUrl + '/d/' + key.dashboard_id + '?theme=light';
                board.push({
                    id: key.dashboard_id,
                    label: key.name,
                    component: <IFrameWrapper
                        dashboardId={key.dashboard_id}
                        url={url}
                        mayEditGrafanaDashboards={(isAdmin || editGrafanaDashboards)}
                    />
                });
            });
            setDashboards(board);
        });
        return () => setDashboards([]);
    }), []);

    return (<>
        {checkPermission(permissions.seeGrafanaDashboards) &&
        <LayoutMainContentTabMenu items={dashboards} activeIndex={0}/>}
    </>);
};

export default GrafanaDashboard;
