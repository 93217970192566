import React, {useEffect, useState} from 'react';
import Input from '../global/Input';
import {getDataLinkPorts} from '../functions/Metrology';
import {Nullable} from '../functions/Global';
import {useTranslation} from 'react-i18next';

type Props = {
    projectId: string,
    dataLinkId: any,
    channelId: string | null
    label: string,
    list?: any, /* {value, label} */
    value?: Nullable<string>,
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
}
const DataLinkPortSelectList = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);
    const [list, setList] = useState(props.list);

    useEffect(() => {
        setList(props.list);
        if (props.dataLinkId)
            getDataLinkPorts(props.projectId, props.dataLinkId, props.channelId).then(result => {
                setList(result);
            });
    }, [props.dataLinkId]);

    return (
        <Input
            name={'port'}
            edit={true}
            showClear={false}
            tooltip={t('metrologyManagement:tooltips.datalinkPort')}
            label={props.label}
            value={props.value}
            scrollHeight={'500px'}
            dropdownOptions={list} type={'dropdown'}
            onChange={(e: any) => {
                if (props.setValue) props.setValue(e.value);
                if (props.onChange) props.onChange(e);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};
export default DataLinkPortSelectList;
