/*
 * Billing.tsx
 * Author: lnappenfeld
 * Date: 31.07.2024
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState} from 'react';

import {useTranslation} from 'react-i18next';
import {checkPermission} from '../functions/functionLibrary';
import {permissions} from '../config/permissions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import LayoutMainContentTabMenu from '../components/global/LayoutMainContentTabMenu';
import {
    faCalendar,
    faCalendarDay,
    faCoins, faEuroSign,
    faFileInvoice,
} from '@fortawesome/free-solid-svg-icons';
import {GlobalBillingOverview} from '../components/billing/GlobalBillingOverview';
import {BillingItemTypesOverview} from '../components/billing/BillingItemTypesOverview';
import {ProjectMonthBillingOverview} from '../components/billing/ProjectMonthBillingOverview';
import {GlobalMonthBillingOverview} from '../components/billing/GlobalMonthBillingOverview';
import CustomDialog, {CustomDialogHeaderType} from '../components/global/CustomDialog';
import {BillingItemTypeForm} from '../components/billing/BillingItemTypeForm';
import {BillingItemType, deleteBillingItemType} from '../components/functions/Billing';
import {buttonCreateBillingItemType, buttonDeleteBillingItemType, makeButtonItem} from '../components/global/viewButtonSelection';
import ViewButtons from '../components/global/ViewButtons';
import {CustomConfirmDialog} from '../components/global/CustomConfirmDialog';
import {showMessageOnError, showMessageOnSuccess} from '../components/global/CustomToast';
import {ProjectDayBillingOverview} from '../components/billing/ProjectDayBillingOverview';

type Props = {
    project: any,
}

export const Billing = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [reloadGlobalBillingOverview, setReloadGlobalBillingOverview] = useState<boolean>(false);
    const [reloadGlobalMonthBillingOverview, setReloadGlobalMonthBillingOverview] = useState<boolean>(false);
    const [reloadBillingItemTypes, setReloadBillingItemTypes] = useState<boolean>(false);
    const [reloadProjectBilling, setReloadProjectBilling] = useState<boolean>(false);

    const [selectedBillingItemTypes, setSelectedBillingItemTypes] = useState<BillingItemType[]>([]);
    const [editBillingItemType, setEditBillingItemType] = useState<boolean>(false);

    const menuItems = [
        {
            id: 'globalBillingOverview',
            label: t('billing:global.billingItemSumTotalProProject'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faEuroSign}/>,
            component: <GlobalBillingOverview
                reload={reloadGlobalBillingOverview}
                setReload={setReloadGlobalBillingOverview}
            />,
            projectRequired: false,
            visible: props.project === null && checkPermission(permissions.isSuperuser),
            buttons: null
        },
        {
            id: 'globalMonthBillingOverview',
            label: t('billing:global.billingItemSumMonthProProject'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faFileInvoice}/>,
            component: <GlobalMonthBillingOverview
                reload={reloadGlobalMonthBillingOverview}
                setReload={setReloadGlobalMonthBillingOverview}
            />,
            projectRequired: false,
            visible: props.project === null && checkPermission(permissions.isSuperuser),
            buttons: null
        },
        {
            id: 'billingItemTypesOverview',
            label: t('billing:global.billingItemTypes'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faCoins}/>,
            component: <>{props.project && <BillingItemTypesOverview
                projectId={props.project.id}
                reload={reloadBillingItemTypes}
                setReload={setReloadBillingItemTypes}
                selectedBillingItemTypes={selectedBillingItemTypes}
                setSelectedBillingItemTypes={setSelectedBillingItemTypes}
            />}</>,
            projectRequired: true,
            visible: props.project !== null && ((checkPermission(permissions.isSuperuser) || checkPermission(permissions.seeBilling))),
            buttons: (
                <ViewButtons
                    title={t('')}
                    items={checkPermission(permissions.isSuperuser) ? [
                        makeButtonItem(buttonCreateBillingItemType, t, () => {
                            setSelectedBillingItemTypes([]);
                            setEditBillingItemType(true);
                        }, checkPermission(permissions.isSuperuser)),
                        makeButtonItem(buttonDeleteBillingItemType, t,
                            () => _deleteBillingItemType(selectedBillingItemTypes),
                            checkPermission(permissions.isSuperuser),
                            selectedBillingItemTypes.length < 1),
                    ] : []}
                />
            )
        },
        {
            id: 'projectMonthBilling',
            label: t('billing:global.billingItemSumMonth'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faCalendar}/>,
            component: <>{props.project && <ProjectMonthBillingOverview
                projectId={props.project.id}
                reload={reloadProjectBilling}
                setReload={setReloadProjectBilling}
            />}</>,
            projectRequired: true,
            visible: props.project !== null && ((checkPermission(permissions.isSuperuser) || checkPermission(permissions.seeBilling))),
            buttons: null
        },
        {
            id: 'projectDayBilling',
            label: t('billing:global.billingItemSumDays'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faCalendarDay}/>,
            component: <>{props.project && <ProjectDayBillingOverview
                projectId={props.project.id}
                reload={reloadProjectBilling}
                setReload={setReloadProjectBilling}
            />}</>,
            projectRequired: true,
            visible: props.project !== null && ((checkPermission(permissions.isSuperuser) || checkPermission(permissions.seeBilling))),
            buttons: null
        }
    ];

    const _deleteBillingItemType = (data: BillingItemType[]) => {
        // eslint-disable-next-line new-cap
        CustomConfirmDialog({
            message: t('billing:dialogs.deleteBillingItemType.message'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                const ids: string[] = [];
                data.forEach((billingItemType: BillingItemType) => {
                    ids.push(billingItemType.id);
                });
                deleteBillingItemType(props.project.id, ids).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('billing:toasts.billingItemTypeDeleted'));
                        setSelectedBillingItemTypes([]);
                        setReloadBillingItemTypes(true);
                    }
                });
            }
        });
    };

    {/* activeIndex -> 0: GlobalBilling, 2: BillingTypes*/}
    return (
        <>
            <LayoutMainContentTabMenu items={menuItems} activeIndex={props.project ? 2 : 0}/>
            {props.project &&
                <CustomDialog
                    onHide={() => {
                        setEditBillingItemType(false);
                    }}
                    visible={editBillingItemType}
                    header={selectedBillingItemTypes.length > 0
                        ? t('billing:dialogs.editBillingItemType.header')
                        : t('billing:dialogs.createBillingItemType.header')}
                    headerType={selectedBillingItemTypes.length > 0 ? CustomDialogHeaderType.Update : CustomDialogHeaderType.Create}
                    onCancel={() => {
                        setEditBillingItemType(false);
                    }}
                    formName={'billingItemTypeDialog'}
                    className={'w-3'}
                >
                    <BillingItemTypeForm
                        projectId={props.project.id}
                        billingItemType={selectedBillingItemTypes.length > 0 ? selectedBillingItemTypes[0] : null}
                        onFinished={() => {
                            setEditBillingItemType(false);
                            setSelectedBillingItemTypes([]);
                            setReloadBillingItemTypes(true);
                        }}
                    />
                </CustomDialog>
            }
        </>
    );
};