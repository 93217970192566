import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';
import {Nullable} from './Global';

type DropdownData = {
    value: number,
    label: string
}

// ---------------------------------------------------------------------------------------------------------------------
// MonitoringApps
// ---------------------------------------------------------------------------------------------------------------------

export async function getMonitoringAppTypes(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getMonitoringAppTypes?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getMonitoringApps(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getMonitoringApps?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

// ---------------------------------------------------------------------------------------------------------------------
// OperatingStates
// ---------------------------------------------------------------------------------------------------------------------
export interface OperatingState {
    'operating_state_id': string,
    'project_id'?: Nullable<string>,
    'operating_state_name': string,
    'description': Nullable<string>,
    'element_id': Nullable<string>,
    'element_name'?: Nullable<string>,
    'count'?: Nullable<number>
}

export async function getOperatingStates(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getOperatingStates?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createOperatingState(data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/createOperatingState', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function updateOperatingState(data: any) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/updateOperatingState', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function deleteOperatingStates(projectId: string, operatingStateIds: string[]) {
    let retValue: any = [];
    await keycloakfetch.delete(settings.apiUrl + '/deleteOperatingStates', {
        'project_id': projectId,
        'operating_state_ids': operatingStateIds
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

// ---------------------------------------------------------------------------------------------------------------------
// OperatingStateConditions
// ---------------------------------------------------------------------------------------------------------------------
export interface OperatingStateCondition {
    'operating_state_condition_id': string,
    'operating_state_id': Nullable<string>,
    'operating_state_name': Nullable<string>,
    'channel_id': Nullable<string>,
    'channel_name'?: Nullable<string>,
    'unit'?: Nullable<number>,
    'op1': Nullable<number>,
    'value1': Nullable<number>,
    'op2': Nullable<number>,
    'value2': Nullable<number>,
    'op3': Nullable<number>
}

export const operatingStateConditionOperatorOptions = [
    {value: 1, label: '<'},
    {value: 2, label: '<='},
    {value: 3, label: '='},
] as DropdownData[];

export const operatingStateConditionBooleanOptions = [
    {value: 0, label: 'is'},
    {value: 1, label: 'isNot'},
] as DropdownData[];

export async function getOperatingStateConditions(projectId: string, operatingStateId: string) {
    let retValue: any = [];
    const additionalParams = '&operating_state_id=' + operatingStateId;
    await keycloakfetch.get(settings.apiUrl + '/getOperatingStateConditions?project_id=' + projectId + additionalParams).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createOperatingStateCondition(data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/createOperatingStateCondition', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function updateOperatingStateCondition(data: any) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/updateOperatingStateCondition', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function deleteOperatingStateConditions(projectId: string, operatingStateConditionIds: string[]) {
    let retValue: any = [];
    await keycloakfetch.delete(settings.apiUrl + '/deleteOperatingStateConditions', {
        'project_id': projectId,
        'operating_state_condition_ids': operatingStateConditionIds
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}