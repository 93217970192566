import React, {useEffect, useState} from 'react';
import {BreadCrumb} from 'primereact/breadcrumb';
import {Project} from '../../components/functions/Project';
import CustomImage from '../../components/global/CustomImage';
import {useTranslation} from 'react-i18next';
import {Image} from 'primereact/image';
import {getWeatherStatus} from '../../components/functions/Metrology';
import settings from '../../config/settings';
import {WeatherDataDialog} from '../../components/data/WeatherDataDialog';
import {checkProjectType, ProjectType} from '../../functions/functionLibrary';
import Input from '../../components/global/Input';
import {Button} from 'primereact/button';
import {getLocations, getWeatherLocations, Nullable} from '../../components/functions/Global';
import {Dropdown} from 'primereact/dropdown';
import {Tooltip} from 'primereact/tooltip';

type Props = {
    project: Project,
    routes: any,
    onMenuButtonClick: (event: any) => void,
    menuMode: string,
    sideBarStatic: boolean,
    colorScheme: string | null
    customBreadcrumb: any;
}
const Breadcrumb = (props: Props) => {
    const {t} = useTranslation(['common']);
    let items: any = [];

    const projectName = props.project === null ? '' : props.project.name;
    const projectLogo = props.project === null ? null : props.project.logo;

    const [bcItems, setBcItems] = useState([items]);
    // Wenn es keine Wetterlocation gibt, wird der Wetterstatus nie angezeigt
    const [weatherStatus, setWeatherStatus] = useState<any>(-1);
    const [showWeatherDataDialog, setShowWeatherDataDialog] = useState<boolean>(false);
    const [selectedLocationId, setSelectedLocationId] = useState<Nullable<string>>(null);
    const [weatherLocations, setWeatherLocations] = useState<Nullable<any>>(null);

    const _setWeatherStatus = (locationId: string) => {
        getWeatherStatus(props.project.id, locationId).then(result => {
            // Wenn kein aktueller Datensatz vorhanden ist (also älter als 60 Minuten) zeige ein '-'
            const status: any = {status: '-', temperature: '- C°'};

            result.forEach((item: any) => {
                if (item.channel_type_id === 109)
                    status.temperature = item.value ? (item.value.toFixed(0) + '°C') : null;
                if (item.channel_type_id === 145)
                    status.status = item.value ? t('weatherCode:' + item.value) : null;

            });

            setWeatherStatus(status);
        });
    };


    useEffect(() => {
        if (props.project && props.project.project_type_id === ProjectType.GeoMonitoring) {
            getWeatherLocations(props.project.id).then(result => {
                const _weatherLocations: any = [];
                result.forEach((key: any) => {
                    _weatherLocations.push({
                        value: key.location_id,
                        label: key.location_name,
                    });
                });
                setWeatherLocations(_weatherLocations);

                if (_weatherLocations.length > 0) {
                    setSelectedLocationId(_weatherLocations[0].value);

                    _setWeatherStatus(_weatherLocations[0].value);
                }
            });
        } else {
            setWeatherStatus(-1);
        }
    }, [props.project]);

    if (props.project !== null)
        items.push({label: projectName});

    switch (window.location.hash) {
        case '#usersettings':
            items.push({label: t('settings')});
            break;
    }

    useEffect(() => {
        items = [];
        if (props.project !== null)
            items.push({label: projectName});

        switch (window.location.hash) {
            case '#usersettings':
                items.push({label: t('settings')});
                break;
        }
        props.routes.forEach((item: any) => {
            // console.log(item.visible, '#' + item.id, '===', window.location.hash);
            let location = window.location.hash;
            if (location.indexOf('_') > -1)
                location = location.substring(0, location.indexOf('_'));

            if (item.items && item.items.length > 0) {
                item.items.forEach((item2: any) => {
                    // console.log(item2.visible, '#' + item2.id, '===', window.location.hash);
                    if (item2.visible && '#' + item2.id === location) {
                        items.push(item);
                        // console.log(item2.visible, '#' + item2.id, '===', window.location.hash);
                        items.push(item2);
                        return;
                    }
                });
                return;
            } else if (item.visible && '#' + item.id === location) {
                // console.log(item.visible, '#' + item.id, '===', window.location.hash);
                item.url = '#' + item.id;
                items.push(item);
                return;
            }
        });
        if (props.customBreadcrumb === null)
            setBcItems(items);
    }, [window.location.hash]);

    useEffect(() => {
        if (props.customBreadcrumb !== null && props.customBreadcrumb.length > 0) {
            const testItem = items;
            props.customBreadcrumb.forEach((itemC: any) => {
                testItem.push(itemC);
            });
            setBcItems(testItem);
        }
    }, [props.customBreadcrumb]);

    const isStatic = () => {
        return props.menuMode === 'static';
    };

    const onBreadcrumbWeatherStatusClick = () => {
        setShowWeatherDataDialog(true);
    };

    return (
        <>
            <div className="layout-breadcrumb-container">
                <div className="layout-topbar">
                    <div className="layout-topbar-left">
                        <button className="topbar-menu-button p-link ml-0" onClick={props.onMenuButtonClick}>
                            <i className="pi pi-chevron-right"></i>
                        </button>
                    </div>
                </div>
                <div className="layout-breadcrumb-left-items card hidden lg:inline-block">
                    {bcItems && <BreadCrumb
                        model={bcItems}
                        className="layout-breadcrumb"
                        home={{
                            template: <div style={{'fontSize': '1.25rem'}}>{props.sideBarStatic ? '' : 'Safeguard '}
                                <Image
                                    src={settings.logoutRedirect + 'safeguard-logo-' + props.colorScheme + '.png'}
                                    height="15px"
                                    alt={'safeguard-logo'}
                                    style={{'paddingTop': '1px'}}
                                />
                            </div>
                        }}/>}
                </div>
                {weatherStatus !== -1 &&
                <>
                    <div className={'layout-breadcrumb-left-items card px-3 py-2 ml-6 lg:ml-0'} style={{height: '37px'}}>
                        <Tooltip target=".show-weather-dashboard-div" />
                        <div
                            style={{cursor: 'pointer'}}
                            onClick={onBreadcrumbWeatherStatusClick}
                            className={'show-weather-dashboard-div'}
                            data-pr-tooltip={t('geomonitoring:tooltips.showWeatherDashboard') + (weatherLocations.length === 1
                                ? ' (' + t('common:location') + ': ' + weatherLocations[0].label + ')'
                                : '')
                            }
                            data-pr-position="bottom"
                            data-pr-at="center+17"
                        >
                            {weatherStatus.temperature} {weatherStatus.status}
                        </div>
                        {weatherLocations.length > 1 &&
                            <Dropdown
                                style={{marginLeft: '15px', marginRight: '-10px'}}
                                // tooltip={t('locations:global.location')}
                                tooltip={t('common:location')}
                                tooltipOptions={{ position: 'bottom' }}
                                options={weatherLocations}
                                value={selectedLocationId}
                                onChange={(e: any) => {
                                    const locationId = e.value;
                                    setSelectedLocationId(locationId);
                                    _setWeatherStatus(locationId);
                                }}
                            />
                        }
                    </div>
                </>}
                <div className="layout-breadcrumb-right-items card">
                    {projectLogo &&
                    <div className={'card'} style={{backgroundColor: 'white'}}>
                        <CustomImage
                            image={props.project.logo} showPlaceholder={true}
                            maxWidth={'100%'}/>
                    </div>}
                </div>
            </div>
            <WeatherDataDialog
                projectId={props.project ? props.project.id : null}
                locationId={selectedLocationId}
                showDialog={showWeatherDataDialog}
                setShowDialog={setShowWeatherDataDialog}
            />
        </>
    );
};

export default Breadcrumb;

