import React from 'react';
import {Skeleton} from 'primereact/skeleton';

export const SkeletonLayerTree = () => {
    return (
        <div className="card" data-testid={'layerSkeletton'}>
            <div className="border-round border-1 surface-border p-4">
                <ul className="m-0 p-0 list-none">
                    <li className="mb-3">
                        <div className="flex">
                            <Skeleton size="1.5rem" className="mr-2"></Skeleton>
                            <div style={{flex: '1'}}>
                                <Skeleton height="1.5rem" className="mb-2" width="40%"></Skeleton>
                            </div>
                        </div>
                    </li>
                    <li className="mb-3">
                        <div className="flex">
                            <Skeleton size="1.5rem" className="mr-2"></Skeleton>
                            <div style={{flex: '1'}}>
                                <Skeleton height="1.5rem" className="mb-2" width="40%"></Skeleton>
                            </div>
                        </div>
                    </li>
                    <li className="mb-3">
                        <div className="flex">
                            <Skeleton size="1.5rem" className="mr-2"></Skeleton>
                            <div style={{flex: '1'}}>
                                <Skeleton height="1.5rem" className="mb-2" width="40%"></Skeleton>
                            </div>
                        </div>
                    </li>
                    <li className="mb-3">
                        <div className="flex">
                            <Skeleton size="1.5rem" className="mr-2"></Skeleton>
                            <div style={{flex: '1'}}>
                                <Skeleton height="1.5rem" className="mb-2" width="40%"></Skeleton>
                            </div>
                        </div>
                    </li>
                    <li className="mb-3">
                        <div className="flex">
                            <Skeleton size="1.5rem" className="mr-2"></Skeleton>
                            <div style={{flex: '1'}}>
                                <Skeleton height="1.5rem" className="mb-2" width="40%"></Skeleton>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};
