/*
 * MeasuringManagerForm.tsx
 * Author: lnappenfeld
 * Date: 25.05.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import Input from '../global/Input';
import {Nullable} from '../functions/Global';
import {MeasuringManager, createMeasuringManager, updateMeasuringManager} from '../functions/Metrology';
import {ipValidation} from '../../functions/functionLibrary';
import LocationSelectList from '../global/LocationSelectList';
import ChannelSelectList from './ChannelSelectList';
import {ElementTreeSelectList} from './ElementTreeSelectList';

type Props = {
    projectId: string,
    measuringManager: Nullable<MeasuringManager>,
    onFinished: (success: boolean) => void
}

export const MeasuringManagerForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const measuringManagerId = props.measuringManager ? props.measuringManager.measuring_manager_id : null;

    const defaultValues = {
        'measuring_manager_name': props.measuringManager ? props.measuringManager.measuring_manager_name : null,
        'description': props.measuringManager ? props.measuringManager.description : null,
        'identifier': props.measuringManager ? props.measuringManager.identifier : null,
        'location_id': props.measuringManager ? props.measuringManager.location_id : null,
        'element_id': props.measuringManager ? props.measuringManager.element_id : null,
    };

    const {control, formState: {errors}, handleSubmit, reset, getValues, watch, setValue, trigger} = useForm({defaultValues});

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    const saveData = (measuringManager: any) => {
        const name = {'name': measuringManager.measuring_manager_name};
        const projectId = {'project_id': props.projectId};
        measuringManager = {...projectId, ...name, ...measuringManager};

        if (measuringManagerId !== null) {
            const _measuringManagerId = {'measuring_manager_id': measuringManagerId};
            measuringManager = {..._measuringManagerId, ...measuringManager};

            updateMeasuringManager(measuringManager).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), t('errorMessages:' + result.error));
                } else {
                    showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.measuringManagerUpdated'));
                    props.onFinished(true);
                }
            });
        } else {
            createMeasuringManager(measuringManager).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), t('errorMessages:' + result.error));
                } else {
                    showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.measuringManagerCreated'));
                    props.onFinished(true);
                }
            });
        }

    };

    return (
        <form id='formMeasuringManager' onSubmit={handleSubmit(saveData)}>
            <div className={'card'} style={{minWidth: '600px'}}>
                <div className={'grid w-auto'}>
                    <div className={'col-6'}>
                        <Input
                            edit={true} label={t('name')} name={'measuring_manager_name'}
                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('name') + ' ' + t('input:required')}}
                        />
                        <Input
                            edit={true} label={t('metrologyManagement:attributes.identifier')} name={'identifier'}
                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('metrologyManagement:attributes.identifier') + ' ' + t('input:required')}}
                        />
                        <Input
                            edit={true} label={t('description')} name={'description'} type={'textarea'}
                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: false}}
                        />
                    </div>
                    <div className={'col-6'}>
                        <LocationSelectList
                            projectId={props.projectId}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: false}}
                            label={t('location')}
                        />
                        <ElementTreeSelectList
                            type={'measuring_manager'}
                            projectId={props.projectId}
                            label={t('metrologyManagement:attributes.measuringManagerElementName')}
                            name={'element_id'}
                            onChange={e => setValue('element_id', e.value)}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: false}}
                        />
                    </div>
                </div>
            </div>
        </form>
    );
};