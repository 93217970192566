/*
 * TimeRangePicker.tsx
 * Author: fwunderlich
 * Date: 21.10.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import Input from './Input';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

type Props = {
    from?: Date,
    to?: Date,
    validationControlFrom?: any,
    validationErrorMessageFrom?: (name: string) => void,
    validationRulesFrom?: any,
    validationControlTo?: any,
    validationErrorMessageTo?: (name: string) => void,
    validationRulesTo?: any,
    onChange?: (from: Date, to: Date) => void,
    noLabels?: boolean
}

const TimeRangePicker = (props: Props) => {
    const {t} = useTranslation(['common']);

    const [internal, setInternal] = useState<any>();
    const [internalFrom, setInternalFrom] = useState<Date | undefined>();
    const [internalTo, setInternalTo] = useState<Date | undefined>();
    const [showTime, setShowTime] = useState<boolean>(false);

    const [from, setFrom] = useState<Date | undefined>();
    const [to, setTo] = useState<Date | undefined>();

    useEffect(() => {
        setFrom(props.from);
        setTo(props.to);
        setInternalFrom(props.from);
        setInternalTo(props.to);
        setInternal([props.from, props.to]);
    }, [props.from, props.to]);

    useEffect(() => {
        // console.log(from, to);
        if (from && to) {
            if (props.onChange) {
                props.onChange(from, to);
            }
        }
    }, [from, to]);

    return (
        <Input
            className="w-full" type="date" name="datetimepicker" label={props.noLabels ? '' : t('timerange')}
            edit={true}
            options={{selectionMode: 'range', showTime: false}}
            validationControl={props.validationControlFrom}
            validationErrorMessage={props.validationErrorMessageFrom}
            validationRules={props.validationRulesFrom}
            value={internal}
            onChange={(e) => {

                let f = new Date(e.value[0]);
                let t = e.value[1] ? new Date(e.value[1]) : new Date(e.value[0]);

                f.setHours(0);
                f.setMinutes(0);
                f.setSeconds(0);

                setInternalFrom(f);

                t.setHours(23);
                t.setMinutes(59);
                t.setSeconds(59);

                setInternalTo(t);

                setInternal(e.value);
            }}
            onBlur={(e) => {
                setFrom(internalFrom);
                setTo(internalTo);
            }}
        />
    );
};

export default TimeRangePicker;
