/*
 * ReportTemplatesOverview.tsx
 * Author: lnappenfeld
 * Date: 19.07.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */
import React, {useEffect, useState} from 'react';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {useTranslation} from 'react-i18next';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {
    deleteReportTemplates,
    getReportTemplates,
    Report,
    ReportTemplate
} from '../functions/Reporting';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {OnlyOfficeDialog} from '../global/OnlyOfficeDialog';
import settings from '../../config/settings';
import {
    faFileLines,
} from '@fortawesome/free-regular-svg-icons';
import {Nullable} from '../functions/Global';
import {ReportsOverview} from './ReportsOverview';
import {Tooltip} from 'primereact/tooltip';

type Props = {
    projectId: string
    reload: boolean,
    setReload: (reload: boolean) => void
    mayEditReporting: boolean,
    editReportTemplate: (template: ReportTemplate) => void,
    selectedReportTemplates: ReportTemplate[],
    setSelectedReportTemplates: (templates: ReportTemplate[]) => void,

    // Only for Maintable
    showDeleteReportTemplatesDialog?: boolean,
    setShowDeleteReportTemplatesDialog?: (e: boolean) => void,
}
export const ReportTemplatesOverview = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common', 'reporting']);
    const [reportTemplates, setReportTemplates] = useState<any[]>([]);
    const [showOnlyOffice, setShowOnlyOffice] = useState<boolean>(false);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [selectedReportTemplateId, setSelectedReportTemplateId] = useState<string>('');

    useEffect(() => {
        if (props.reload) {
            _setReportTemplates();
        }
        props.setReload(false);
    }, [props.reload]);


    useEffect(() => {
        _setReportTemplates();
        props.setSelectedReportTemplates([]);
    }, []);

    useEffect(() => {
        if (props.showDeleteReportTemplatesDialog && props.selectedReportTemplates) {
            delReportTemplates(props.selectedReportTemplates);
        }
    }, [props.showDeleteReportTemplatesDialog]);

    const _setReportTemplates = () => {
        getReportTemplates(props.projectId).then(result => {
            for (let i = 0; i < result.length; i++) {
                if (result[i].template_is_used_in_report)
                    result[i]['expandableElement'] = (
                        <div className={'card'}>
                            <ReportsOverview
                                projectId={props.projectId}
                                mayEditReporting={props.mayEditReporting}
                                reportTemplateId={result[i].id}
                            />
                        </div>
                    );
            }
            setReportTemplates(result);
            setDataLoaded(true);
        });
    };

    const reportColumns: ColumnObject[] = [
        {
            field: 'id', header: t('reportingManagement:attributes.reportTemplateId'),
            filter: false, display: false
        },
        {field: 'name', header: t('name'), filter: false, filterField: 'name',
            body: props.mayEditReporting ? (template: ReportTemplate) => {
                return (<>
                    <Tooltip target=".cell-function" />
                    <FontAwesomeIcon icon={faFileLines} className='ml-2'/>{' '}
                    <u className='cell-function' onClick={(e) => {
                        e.stopPropagation();
                        setSelectedReportTemplateId(template.id);
                        setShowOnlyOffice(true);
                    }}
                    data-pr-tooltip={t('reportingManagement:tooltips.openInOnlyOffice')}
                    data-pr-position={'top'}>
                        {template.name}
                    </u>
                </>);
            } : null},
        {field: 'changed_by_username', header: t('changedByUsername')},
        {field: 'changed_at_utc', header: t('changedAtLocale'), type: 4}
    ];

    const delReportTemplates = (data: ReportTemplate[]) => {
        const reportTemplateIds: string[] = [];
        for (const reportTemplate of data) {
            reportTemplateIds.push(reportTemplate.id);
        }
        CustomConfirmDialog({
            message: t('reportingManagement:dialogs.deleteReportTemplatesDialog.message'),
            header: t('confirmation'),
            translation: t,
            onConfirm: () => {
                deleteReportTemplates(props.projectId, reportTemplateIds).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                    } else {
                        showMessageOnSuccess(t('success'), t('reportingManagement:toasts.templatesDeleted'));
                        _setReportTemplates();
                        if (props.setSelectedReportTemplates) props.setSelectedReportTemplates([]);
                    }
                    if (props.setShowDeleteReportTemplatesDialog) props.setShowDeleteReportTemplatesDialog(false);
                });
            },
            onCancel: () => {
                if (props.setShowDeleteReportTemplatesDialog) props.setShowDeleteReportTemplatesDialog(false);
            }
        });
    };

    return (
        <>
            <CustomDataTable
                id='reports-templates'
                columns={reportColumns}
                editable={true}
                sortable={true}
                expandable={true}
                displayColumnFilter={true}
                value={reportTemplates}
                selectionMode={'multiple'}
                selection={props.selectedReportTemplates}
                onSelectionChange={(e: any) => {
                    if (props.setSelectedReportTemplates) props.setSelectedReportTemplates(e.value);
                }}
                onRemoveClick={(data: any) => {
                    CustomConfirmDialog({
                        message: t('reportingManagement:dialogs.deleteReportTemplateDialog.message'),
                        header: t('confirmation'),
                        translation: t,
                        onConfirm: () => {
                            deleteReportTemplates(props.projectId, [data.id]).then(result => {
                                if (result.error) {
                                    showMessageOnError(t('error'), t('errorMessages:' + result.error));
                                } else {
                                    _setReportTemplates();
                                    showMessageOnSuccess(t('success'), t('reportingManagement:toasts.templateDeleted'));
                                }
                            });
                        }
                    });
                }}
                onEditClick={props.editReportTemplate}
                ableToDelete={props.mayEditReporting}
                ableToUpdate={props.mayEditReporting}
                dataloaded={dataLoaded}
                showExportCSV={true}
            />
            {showOnlyOffice &&
            <OnlyOfficeDialog
                fileType={'template'}
                urlToOpenFile={settings.ooApiUrl + '/getReportTemplateFileAsBuffer?report_id='}
                urlToSaveFile={settings.ooApiUrl + '/saveReportTemplateFileFromBuffer?report_id='}
                setShowOnlyOfficeDialog={setShowOnlyOffice}
                plugins={true}
                fileId={selectedReportTemplateId}
                projectId={props.projectId}
            />}
        </>
    );
};
