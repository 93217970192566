/*
 * ReportFilesOverview.tsx
 * Author: lnappenfeld
 * Date: 25.07.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {useTranslation} from 'react-i18next';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {
    getReportFileID,
    getReportFiles, Report, ReportFile
} from '../functions/Reporting';
import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';
import {OnlyOfficeEditor} from '../global/OnlyOfficeEditor';
import {ProgressSpinner} from 'primereact/progressspinner';
import {ReportsOverview} from './ReportsOverview';
import {Nullable} from '../functions/Global';

type Props = {
    projectId: string
    reportId: string
    editReportFile: any,
}
export const ReportFilesOverview = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common', 'reporting']);

    const [reportFiles, setReportFiles] = useState<any[]>([]);
    const [showLoading, setShowLoading] = useState(false);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    useEffect(() => {
        _setReportFiles();
    }, []);

    const _setReportFiles = () => {
        setShowLoading(true);
        getReportFiles(props.projectId, props.reportId).then(result => {
            setReportFiles(result);
            setShowLoading(false);
            setDataLoaded(true);
        });
    };

    const reportFileColumns: ColumnObject[] = [
        {field: 'file_name', header: t('name')},
        {field: 'report_template_name', header: t('reportingManagement:global.reportTemplate')},
        {field: 'timestamp', header: t('changedAtLocale'), type: 4}
    ];

    return (
        <>
            <CustomDataTable
                id='report-files'
                headerTitle={t('reportingManagement:global.reportFiles')}
                columns={reportFileColumns}
                editable={true}
                sortable={true}
                value={reportFiles}
                onEditClick={props.editReportFile}
                paginator={true}
                ableToDelete={false}
                ableToUpdate={true} // immer true: update heißt nur in onlyOffice öffnen und nicht verändern
                showLoading={showLoading}
                dataloaded={dataLoaded}
            />
        </>
    );
};