import React, {useEffect, useRef, useState} from 'react';
import Input from '../global/Input';
import {Checkbox} from 'primereact/checkbox';
import ViewButtons from '../global/ViewButtons';
import PlotChannelSelectList from './PlotChannelSelectList';
import TimeRangePicker from '../global/TimeRangePicker';
import {getUserFromLocalStorage, setUserSettings} from '../functions/Global';
import {useTranslation} from 'react-i18next';
import {createPlotTemplate, deletePlotTemplate, getPlotTemplates, updatePlotTemplate} from './PlotFunctions';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {Toast} from 'primereact/toast';
import CustomDialog, {CustomDialogHeaderType} from '../global/CustomDialog';
import {ConfirmDialog} from 'primereact/confirmdialog';
import PlotEditTemplate from './PlotEditTemplate';
import {isAdminRole, isSuperuserRole} from '../functions/UserManagement';

type Props = {
    projectId: string;
    loadInitial: boolean;
    onSelectedTemplateChanged: (template: any) => void;
    type: PlotTemplateType;
    selectedChannels?: any;
    plotOptions?: any;
}

type PlotTemplateDropDown = {
    label: string,
    code: string,
    items: any[]
}

export enum PlotTemplateType {
    Charts,
    Exporting
}

const PlotTemplate = (props: Props): JSX.Element => {
    const toast = useRef<any>();
    const {t} = useTranslation(['common', 'measurementData', 'customDataTable']);

    const now = new Date();
    const minusSevenDays = new Date(now);
    minusSevenDays.setDate(now.getDate() - 7);

    const [selectedDropdownTemplate, setSelectedDropdownTemplate] = useState<any>(null);
    const [plotTemplates, setPlotTemplates] = useState<PlotTemplateDropDown[]>([]);
    const [initialTemplate, setInitialTemplate] = useState<string>('');
    const [createdTemplateId, setCreatedTemplateId] = useState<string>('');

    const [selectedChannels, setSelectedChannels] = useState<any>({});
    const [selectedTemplate, setSelectedTemplate] = useState<any>({
        plotOptions: [],
        from: minusSevenDays,
        to: now,
        combineCharts: 0,
        minMaxFromChannel: false,
        combineValues: true
    });

    const [templateUser, setTemplateUser] = useState<boolean>(true);
    const [templateName, setTemplateName] = useState<string>('');

    const [showCreateTemplateDialog, setShowCreateTemplateDialog] = useState<boolean>(false);
    const [showUpdateTemplateDialog, setShowUpdateTemplateDialog] = useState<boolean>(false);
    const [showDeleteTemplateDialog, setShowDeleteTemplateDialog] = useState<boolean>(false);

    useEffect(() => {
        getPlotTemplatesForDropdown();
    }, []);

    useEffect(() => {
        if (props.loadInitial) {
            const value = getUserFromLocalStorage().settings[props.projectId] ? getUserFromLocalStorage().settings[props.projectId]['initialPlotTemplate'] : '';
            setInitialTemplate(value);
        }
    }, [props.loadInitial]);

    useEffect(() => {
        // console.log('props', props.selectedTemplate);
        if (props.selectedChannels) {
            setSelectedChannels(props.selectedChannels);
        }
    }, [props.selectedChannels]);

    useEffect(() => {
        if (props.plotOptions) {
            setSelectedTemplate((prevState: any) => {
                return {...prevState, plotOptions: props.plotOptions};
            });
        }
    }, [props.plotOptions]);

    useEffect(() => {
        if (initialTemplate) {
            // console.log('it', initialTemplate);
            getPlotTemplatesForDropdown();
        }
    }, [initialTemplate]);

    useEffect(() => {
        const selectId = createdTemplateId || initialTemplate;

        if (selectId !== '') {
            if (plotTemplates.length > 0) {
                setSelectedDropdownTemplate(getPlotTemplateById(selectId));
            }
        }

        setCreatedTemplateId('');
    }, [plotTemplates]);

    useEffect(() => {
        if (selectedDropdownTemplate) {
            plotTemplateSelected(selectedDropdownTemplate.value);
        }
    }, [selectedDropdownTemplate]);

    useEffect(() => {
        // console.log('onSelectedTemplateChanged');
        props.onSelectedTemplateChanged(selectedTemplate);
    }, [selectedTemplate]);

    /*
    useEffect(() => {
        console.log('onSelectedChannelsChanged', selectedChannels);
        // props.onSelectedChannelsChanged(selectedChannels);
    }, [selectedChannels]);
     */

    const plotTemplateSelected = (id: string) => {
        if (plotTemplates.length > 0) {
            const template = getPlotTemplateById(id);
            if (template) {
                setSelectedTemplate((prevState: any) => {
                    return {
                        ...prevState, plotOptions: template.plotOptions,
                        combineCharts: template.combineCharts,
                        minMaxFromChannel: template.minMaxFromChannel,
                        combineValues: template.combineValues
                    };
                });
                setSelectedChannels(template.dropdown);
            }
        }
    };

    const getPlotTemplateById = (id: string): any => {
        let result = null;
        if (id) {
            for (let i = 0; i < plotTemplates[0].items.length; i++) {
                if (plotTemplates[0].items[i].value === id) {
                    result = plotTemplates[0].items[i];
                    break;
                }
            }
            if (result === null && plotTemplates[1]) {
                for (let i = 0; i < plotTemplates[1].items.length; i++) {
                    if (plotTemplates[1].items[i].value === id) {
                        result = plotTemplates[1].items[i];
                        break;
                    }
                }
            }
        }
        return result;
    };

    const createPlotTemplateCallback = (label: string, checked: boolean) => {
        if (label !== '') {
            const data: any = {
                'project_id': props.projectId,
                name: label,
                'user_only': checked,
                settings: {
                    plotOptions: selectedTemplate.plotOptions,
                    dropdown: selectedChannels,
                    combineCharts: selectedTemplate.combineCharts,
                    minMaxFromChannel: selectedTemplate.minMaxFromChannel,
                    combineValues: selectedTemplate.combineValues
                }
            };

            createPlotTemplate(data).then((result) => {
                setTemplateName('');
                setTemplateUser(true);
                setShowCreateTemplateDialog(false);
                getPlotTemplatesForDropdown();
                setCreatedTemplateId(result);

                if (result.error) {
                    showMessageOnError(t('error'), result.error);
                } else {
                    showMessageOnSuccess(t('measurementData:toasts.templateSaved'), t('measurementData:toasts.templateSavedText'));
                }
            });

        } else {
            showMessageOnError(t('measurementData:toasts.name'), t('measurementData:toasts.noNameError'));
        }
    };

    const updatePlotTemplateCallback = (label: string, checked: boolean) => {
        if (label !== '') {
            const data: any = {
                id: selectedDropdownTemplate.value,
                'project_id': props.projectId,
                name: label,
                'user_only': checked,
                settings: {
                    plotOptions: selectedTemplate.plotOptions,
                    dropdown: selectedChannels,
                    combineCharts: selectedTemplate.combineCharts,
                    minMaxFromChannel: selectedTemplate.minMaxFromChannel,
                    combineValues: selectedTemplate.combineValues
                }
            };

            updatePlotTemplate(data).then((result) => {
                setShowUpdateTemplateDialog(false);
                getPlotTemplatesForDropdown();
                setCreatedTemplateId(selectedDropdownTemplate.value);
                if (result.error) {
                    showMessageOnError(t('error'), result.error);
                } else {
                    showMessageOnSuccess(t('measurementData:toasts.templateSaved'), t('measurementData:toasts.templateSavedText'));
                }
            });
        } else {
            showMessageOnError(t('measurementData:attributes.templateName'), t('measurementData:toasts.noNameError'));
        }
    };

    const getPlotTemplatesForDropdown = () => {
        getPlotTemplates(props.projectId).then(result => {
            const p: PlotTemplateDropDown = {label: t('project'), code: 'P', items: []};
            const u: PlotTemplateDropDown = {label: t('measurementData:attributes.user'), code: 'U', items: []};

            for (let i = 0; i < result.length; i++) {
                const item = result[i];
                item.settings.label = item.name;
                item.settings.value = item.id;
                item.settings.user_id = item.user_id;
                if (item.user_id) {
                    u.items.push(item.settings);
                } else {
                    p.items.push(item.settings);
                }
            }

            const arr: any[] = [];

            if (p.items.length > 0) {
                arr.push(p);
            }

            if (u.items.length > 0) {
                arr.push(u);
            }

            setPlotTemplates(arr);
        });
    };

    const allowMinMaxFromChannel = () => {
        return selectedTemplate.combineCharts === 0;
    };

    const isAdmin = (): boolean => {
        return isSuperuserRole(getUserFromLocalStorage().role_id) || isAdminRole(getUserFromLocalStorage().role_id);
    };

    return (
        <>
            <div className="grid col-12">
                <div className="col-10 md:col-6">
                    <div className="grid">
                        <div className="col-12 md:col-6 m-auto">
                            <Input
                                name={'templates'}
                                edit={true} showClear={true}
                                type="dropdown"
                                label={t('measurementData:attributes.templates')}
                                value={selectedDropdownTemplate ? selectedDropdownTemplate.value : null}
                                scrollHeight={'500px'}
                                dropdownOptions={plotTemplates}
                                options={{
                                    optionLabel: 'label',
                                    optionGroupLabel: 'label',
                                    optionGroupChildren: 'items'
                                }}
                                onChange={(e: any) => {
                                    setSelectedDropdownTemplate(e.target);

                                    // if clear has been clicked
                                    if (e.target.value === undefined) {
                                        setSelectedTemplate((prevState: any) => {
                                            return {...prevState, plotOptions: []};
                                        });
                                        setSelectedChannels([]);
                                    }
                                }}
                            />
                        </div>
                        <div className="col-12 md:col mb-2 mt-auto">
                            <Checkbox inputId="cb1" className="mr-2" value={t('measurementData:attributes.initialShow')}
                                onChange={(e) => {
                                    const value = e.checked ? selectedDropdownTemplate.value : '';
                                    setUserSettings('initialPlotTemplate', value, props.projectId);
                                    setInitialTemplate(value);
                                }}
                                disabled={!selectedDropdownTemplate}
                                checked={selectedDropdownTemplate && selectedDropdownTemplate.value === initialTemplate}/>
                            <label htmlFor="cb1"
                                className={'p-checkbox-label inline-block' + (!selectedDropdownTemplate ? ' p-disabled' : '')}>{t('measurementData:attributes.initialShow')}
                            </label>
                        </div>

                    </div>
                </div>
                <div className="col-2 md:col-6">
                    <ViewButtons title={''} items={[
                        {
                            label: t('measurementData:buttons.createTemplateButtonText'),
                            className: 'p-button-success',
                            icon: 'pi pi-plus',
                            visible: true,
                            disabled: !selectedTemplate || selectedTemplate.plotOptions.length <= 0,
                            onClick: () => {
                                setShowCreateTemplateDialog(true);
                            },
                        },
                        {
                            label: t('measurementData:buttons.updateTemplateButtonText'),
                            icon: 'fa fa-pen',
                            visible: true,
                            disabled: selectedDropdownTemplate === null || !selectedTemplate || selectedTemplate.plotOptions.length <= 0,
                            onClick: () => {

                                const template = getPlotTemplateById(selectedDropdownTemplate.value);
                                setTemplateName(template.label);
                                setTemplateUser(template.userOnly);

                                setShowUpdateTemplateDialog(true);
                            },
                        },
                        {
                            label: t('measurementData:buttons.deleteTemplateButtonText'),
                            className: 'p-button-danger',
                            icon: 'pi pi-trash',
                            visible: true,
                            disabled: selectedDropdownTemplate === null || (!isAdmin() && getPlotTemplateById(selectedDropdownTemplate.value).user_id === null),
                            onClick: () => {
                                setShowDeleteTemplateDialog(true);
                            },
                        },
                    ]}
                    /></div>
            </div>
            <div className="grid col-12">
                <div className="col-12 md:col-3">
                    <PlotChannelSelectList
                        projectId={props.projectId}
                        label={t('common:sensors')}
                        values={selectedChannels}
                        onChange={(plotOptions, rawData) => {
                            setSelectedTemplate((prevState: any) => {
                                return {...prevState, plotOptions: plotOptions};
                            });
                            if (rawData !== undefined) {
                                setSelectedChannels(rawData);
                            }
                        }}
                    />
                </div>
                <div className="col-12 md:col-3">
                    <Input name="combineCharts"
                        label={props.type === PlotTemplateType.Charts
                            ? t('measurementData:attributes.combineCharts')
                            : t('measurementData:attributes.combineExporting')}
                        type="dropdown" edit={true}
                        value={selectedTemplate.combineCharts}
                        dropdownOptions={props.type === PlotTemplateType.Charts ? [
                            {label: t('measurementData:attributes.combineCharts0'), value: 0},
                            {label: t('measurementData:attributes.combineCharts1'), value: 1},
                            {label: t('measurementData:attributes.combineCharts2'), value: 2}] : [
                            {label: t('measurementData:attributes.combineExporting0'), value: 0},
                            {label: t('measurementData:attributes.combineExporting1'), value: 1},
                            {label: t('measurementData:attributes.combineExporting2'), value: 2}]}
                        onChange={(e: any) => {
                            setSelectedTemplate((prevState: any) => {
                                return {
                                    ...prevState,
                                    combineCharts: e.target.value
                                };
                            });
                        }}/></div>
                <div className="col-12 md:col-2 mb-3 mt-auto">
                    {props.type !== PlotTemplateType.Exporting && <>
                        <Checkbox
                            inputId="cbMM"
                            className="mr-2"
                            checked={allowMinMaxFromChannel() && selectedTemplate.minMaxFromChannel}
                            disabled={!allowMinMaxFromChannel()}
                            onChange={(e) => {
                                setSelectedTemplate((prevState: any) => {
                                    return {
                                        ...prevState,
                                        minMaxFromChannel: e.checked
                                    };
                                });
                            }}
                        />
                        <label htmlFor="cbMM"
                            className={'p-checkbox-label inline-block' + (!allowMinMaxFromChannel() ? ' p-disabled' : '')}>{t('measurementData:attributes.minMaxFromChannel')}
                        </label>
                    </>}
                </div>
                <div className="col-12 md:col-2 mb-3 mt-auto">
                    {props.type !== PlotTemplateType.Exporting && <>
                        <Checkbox
                            inputId="cbCombineValues"
                            className="mr-2"
                            checked={selectedTemplate.combineValues}
                            onChange={(e) => {
                                setSelectedTemplate((prevState: any) => {
                                    return {
                                        ...prevState,
                                        combineValues: e.checked
                                    };
                                });
                                // setCombineValues(e.checked);
                            }}
                        />
                        <label htmlFor="cbCombineValues"
                            className={'p-checkbox-label inline-block'}>{t('measurementData:attributes.combineValues')}
                        </label>
                    </>}
                </div>
                <div className="col-12 md:col">
                    <div className="grid col-12 md:col md:justify-content-end p-0 m-0">
                        <TimeRangePicker
                            from={selectedTemplate ? selectedTemplate.from : minusSevenDays}
                            to={selectedTemplate ? selectedTemplate.to : now}
                            onChange={(from: Date, to: Date) => {

                                setSelectedTemplate((prevState: any) => {
                                    return {...prevState, from: from, to: to};
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
            <Toast ref={toast}/>
            {showCreateTemplateDialog && <CustomDialog
                header={t('measurementData:dialogs.createTemplate.header')}
                headerType={CustomDialogHeaderType.Create}
                visible={showCreateTemplateDialog}
                onHide={() => setShowCreateTemplateDialog(false)}
                onCancel={() => {
                    setShowCreateTemplateDialog(false);
                }}
                formName={'formDialog'}>
                <PlotEditTemplate label={''} userOnly={templateUser} disableUserOnly={!isAdmin()}
                    onSave={(label: string, checked: boolean) => {
                        createPlotTemplateCallback(label, checked);
                    }}
                />
            </CustomDialog>}
            {showUpdateTemplateDialog && <CustomDialog
                header={t('measurementData:dialogs.editTemplate.header')}
                headerType={CustomDialogHeaderType.Update}
                visible={showUpdateTemplateDialog}
                onHide={() => setShowUpdateTemplateDialog(false)}
                onCancel={() => {
                    setShowUpdateTemplateDialog(false);
                }}
                formName={'formDialog'}>
                <PlotEditTemplate label={templateName} userOnly={templateUser} disableUserOnly={!isAdmin()}
                    onSave={(label: string, checked: boolean) => {
                        updatePlotTemplateCallback(label, checked);
                    }}
                />
            </CustomDialog>}
            {
                showDeleteTemplateDialog &&
                <ConfirmDialog
                    header={getPlotTemplateById(selectedDropdownTemplate?.value).label}
                    message={t('measurementData:dialogs.deleteTemplate.message')}
                    visible={showDeleteTemplateDialog}
                    accept={() => {
                        deletePlotTemplate(props.projectId, selectedDropdownTemplate.value).then(result => {
                            setShowDeleteTemplateDialog(false);
                            setSelectedDropdownTemplate(null);
                            getPlotTemplatesForDropdown();
                            if (result.error) {
                                showMessageOnError(t('error'), result.error);
                            } else {
                                showMessageOnSuccess(t('measurementData:toasts.templateDeleted'), t('measurementData:toasts.templateDeletedText'));
                            }
                        });
                    }}
                    onHide={() => {
                        setShowDeleteTemplateDialog(false);
                    }}
                />
            }
        </>
    );
};

export default PlotTemplate;
