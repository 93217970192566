/*
 * NotificationDialog.tsx
 * Author: lnappenfeld
 * Date: 02.05.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */


import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import Input from '../global/Input';

import NotificationRuleCoincidenceTypeSelectList from './NotificationRuleCoincidenceTypeSelectList';
import CMValueTypeSelectList from '../metrology/CMValueTypeSelectList';
import NotificationTypeSelectList from './NotificationTypeSelectList';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {createNotification, Notification, updateNotification} from '../functions/Notification';
import {checkProjectType, integerValidation, minValidation, ProjectType} from '../../functions/functionLibrary';
import IntervalSelection from '../global/IntervalSelection';
import {Nullable} from '../functions/Global';
import {ElementTreeSelectList} from '../metrology/ElementTreeSelectList';

type Props = {
    notificationData: Nullable<Notification>,
    projectId: string,
    onFinished: (success: boolean) => any

    // Bisher nur für den NotificationWizard vorgesehen
    setNotificationData?: (notification: Nullable<any>) => void
}

const NotificationEditForm = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    const [executionInterval, setExecutionInterval] = useState<Nullable<string>>(props.notificationData ? props.notificationData.execution_interval : null);
    const [executionIntervalText, setExecutionIntervalText] = useState<string>('');
    const notificationId = (props.notificationData !== null && props.notificationData.id) ? props.notificationData.id : null;

    const executionType = [
        {name: t('alarmManagement:attributes.alarmControlled'), value: false},
        {name: t('alarmManagement:attributes.timed'), value: true},
    ];
    const reportType = [
        {name: t('alarmManagement:attributes.pre_alarm'), value: true},
        {name: t('alarmManagement:attributes.main_alarm'), value: false},
    ];

    const checkAdditionalSettings = (type: string) => {
        if (type === 'gm') {
            return (
                (props.notificationData?.notification_type_id === 1 ||
                    props.notificationData?.notification_type_id === 6 ||
                    props.notificationData?.notification_type_id === 7) &&
                (props.notificationData !== null)
            );
        } else {
            return (
                props.notificationData?.notification_type_id === 3 &&
                (props.notificationData !== null)
            );
        }
    };

    const defaultValues = {
        'name': props.notificationData === null ? null : props.notificationData?.name,
        'project_id': props.projectId,
        'description': props.notificationData === null ? null : props.notificationData?.description,
        'additional_content': props.notificationData === null ? null : props.notificationData?.additional_content,
        'additional_settings': props.notificationData === null ? null : props.notificationData?.additional_settings,
        'is_enabled': props.notificationData === null ? true : props.notificationData?.is_enabled,
        'is_timed': props.notificationData === null ? false : props.notificationData?.is_timed,
        'execution_interval': props.notificationData === null ? null : props.notificationData?.execution_interval,
        'notification_type_id': props.notificationData === null ? '6' : props.notificationData?.notification_type_id + '',
        // Nur für die automatische Validierung. Das executionInterval wird weiterhin in der Komponente
        // 'IntervalSelection' gesetzt und in dem state 'executionInterval' verwaltet
        'interval_day': props.notificationData !== null && props.notificationData.execution_interval && props.notificationData.is_timed
            ? props.notificationData.execution_interval.split(';')[1].split(',')
            : [],
        'interval_time': props.notificationData !== null && props.notificationData.execution_interval && props.notificationData.is_timed
            ? props.notificationData.execution_interval.split(';')[2].split(',')
            : [],

        'max_allowed_data_age_in_minutes': props.notificationData === null ? null : props.notificationData?.max_allowed_data_age_in_minutes,

        // GM settings
        'coincidence_type_id': checkAdditionalSettings('gm') ? JSON.parse(JSON.stringify(props.notificationData?.additional_settings)).coincidence_type_id.toString() : '0',
        'coincidence_level': checkAdditionalSettings('gm') ? JSON.parse(JSON.stringify(props.notificationData?.additional_settings)).coincidence_level : null,

        // CM settings
        'element_id': checkAdditionalSettings('cm') ? JSON.parse(JSON.stringify(props.notificationData?.additional_settings)).element_id : null,
        'is_spectral_monitoring_active': checkAdditionalSettings('cm') ? JSON.parse(JSON.stringify(props.notificationData?.additional_settings)).is_spectral_monitoring_active : true,
        'threshold_cmi': checkAdditionalSettings('cm') ? JSON.parse(JSON.stringify(props.notificationData?.additional_settings)).threshold_cmi : 90,
        'number_cmi_alarms': checkAdditionalSettings('cm') ? JSON.parse(JSON.stringify(props.notificationData?.additional_settings)).number_cmi_alarms : 10,
        'value_type': checkAdditionalSettings('cm') ? JSON.parse(JSON.stringify(props.notificationData?.additional_settings)).value_type.toString() : '0',
        'is_beda_monitoring_active': checkAdditionalSettings('cm') ? JSON.parse(JSON.stringify(props.notificationData?.additional_settings)).is_beda_monitoring_active : true,
        'number_beda_alarms': checkAdditionalSettings('cm') ? JSON.parse(JSON.stringify(props.notificationData?.additional_settings)).number_beda_alarms : 10,
        'report_pre_alarm': checkAdditionalSettings('cm') ? JSON.parse(JSON.stringify(props.notificationData?.additional_settings)).report_pre_alarm : false,
        'flag_auto_reset_after_minutes': checkAdditionalSettings('cm') ? JSON.parse(JSON.stringify(props.notificationData?.additional_settings)).flag_auto_reset_after_minutes : 60,
    };

    const {control, formState: {errors}, handleSubmit, reset, getValues, watch, setValue, trigger} = useForm({defaultValues});

    watch('notification_type_id');
    watch('is_timed');
    watch('is_enabled');
    watch('is_spectral_monitoring_active');
    watch('is_beda_monitoring_active');
    watch('report_pre_alarm');
    watch('coincidence_type_id');
    watch('interval_day');
    watch('interval_time');

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    useEffect(() => {
        // set initial values in parent component
        if (props.setNotificationData) {
            updateNotificationData();
        }
    }, []);

    const saveData = (notification: any) => {
        console.log('saveData!!!!!!!');
        notification['notification_type_id'] = parseInt(notification.notification_type_id);
        notification['execution_interval'] = executionInterval;

        const notificationAddId = {'notification_id': notificationId};
        if (notificationId !== null)
            notification = {...notificationAddId, ...notification};

        if (notificationId !== null) {
            updateNotification(notification).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), result.error);
                } else {
                    showMessageOnSuccess(t('success'), t('alarmManagement:toasts.notificationUpdated'));
                    props.onFinished(true);
                }
            });
        } else {
            createNotification(notification).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), result.error);
                } else {
                    showMessageOnSuccess(t('success'), t('alarmManagement:toasts.notificationCreated'));
                    props.onFinished(true);
                }
            });
        }
    };

    const getNotificationSettingsInputElements = () => {
        const gmInputElements = (
            <>
                <NotificationRuleCoincidenceTypeSelectList
                    name={'coincidence_type_id'}
                    label={t('alarmManagement:attributes.coincidence_type_name')}
                    onChange={(e: any) => {
                        setValue('coincidence_type_id', e.value);
                        if (props.setNotificationData) {updateNotificationData();}
                    }}
                    validationControl={control}
                    validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: t('alarmManagement:attributes.coincidence_type_name') + ' ' + t('input:required')}}
                />
                {parseInt(getValues('coincidence_type_id')) === 2 &&
                    <Input
                        edit={true} label={t('alarmManagement:attributes.coincidence_level')} name={'coincidence_level'}
                        onChange={() => {if (props.setNotificationData) {updateNotificationData();}}}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{
                            ...{required: t('alarmManagement:attributes.coincidence_level') + ' ' + t('input:required')},
                            ...integerValidation(t), ...minValidation(t, 1)
                        }}
                    />
                }
            </>
        );

        const cmInputElements = (
            <>
                <ElementTreeSelectList
                    type={'notification'}
                    projectId={props.projectId}
                    label={t('alarmManagement:attributes.notificationElementName')}
                    name={'element_id'}
                    disabled={!!notificationId}
                    onChange={(e: any) => {
                        setValue('element_id', e.value);
                        if (props.setNotificationData) {updateNotificationData();}
                    }}
                    validationControl={control}
                    validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: t('alarmManagement:attributes.notificationElementName') + ' ' + t('input:required')}}
                />
                <div className={'card'}>
                    <Input
                        edit={true} label={t('alarmManagement:attributes.flag_auto_reset_after_minutes')}
                        name={'flag_auto_reset_after_minutes'}
                        onChange={() => {if (props.setNotificationData) {updateNotificationData();}}}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('alarmManagement:attributes.flag_auto_reset_after_minutes') + ' ' + t('input:required')}}
                    />
                    <Input
                        edit={true}
                        label={t('alarmManagement:attributes.execution_type')}
                        name={'is_timed'}
                        type={'selectButton'}
                        value={getValues('is_timed')}
                        onChange={(e: any) => {
                            setValue('is_timed', e.value);
                            if (props.setNotificationData) {updateNotificationData();}
                        }}
                        selectButtonOptions={executionType}
                    />
                    {getValues('is_timed') &&
                    <>
                        <IntervalSelection
                            interval={executionInterval}
                            onChange={(interval: Nullable<string>, intervalText: string) => {
                                setExecutionInterval(interval);
                                setExecutionIntervalText(intervalText);
                                if (props.setNotificationData) {updateNotificationData();}
                            }}
                            intervalDay={getValues('interval_day')}
                            setIntervalDay={(intervalDay: string[]) => {
                                setValue('interval_day', intervalDay);
                            }}
                            intervalTime={getValues('interval_time')}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                        />
                        {((executionInterval && executionInterval.split(';')[0] === '2') ||
                                getValues('interval_day').length > 0) && getValues('interval_time').length > 0 &&
                            <p>{executionIntervalText}</p>}
                    </>}
                </div>
                <div className={'card'}>
                    <Input
                        edit={true} label={t('alarmManagement:attributes.is_spectral_monitoring_active')}
                        onChange={() => {if (props.setNotificationData) {updateNotificationData();}}}
                        name={'is_spectral_monitoring_active'} type={'checkbox'}
                        validationControl={control} validationRules={{required: false}}
                    />
                    <Input
                        edit={true} disabled={!getValues('is_spectral_monitoring_active')}
                        label={t('alarmManagement:attributes.threshold_cmi')} name={'threshold_cmi'}
                        onChange={() => {if (props.setNotificationData) {updateNotificationData();}}}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('alarmManagement:attributes.threshold_cmi') + ' ' + t('input:required')}}
                    />
                    {!getValues('is_timed') &&
                    <Input
                        edit={true} disabled={!getValues('is_spectral_monitoring_active')}
                        label={t('alarmManagement:attributes.number_cmi_alarms')} name={'number_cmi_alarms'}
                        onChange={() => {if (props.setNotificationData) {updateNotificationData();}}}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('alarmManagement:attributes.number_cmi_alarms') + ' ' + t('input:required')}}
                    />}
                    <CMValueTypeSelectList
                        edit={true}
                        disabled={!getValues('is_spectral_monitoring_active')}
                        name={'value_type'}
                        label={t('alarmManagement:attributes.value_type')}
                        onChange={(e: any) => {
                            setValue('value_type', e.value);
                            if (props.setNotificationData) {updateNotificationData();}
                        }}
                        validationControl={control}
                        validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('alarmManagement:attributes.value_type') + ' ' + t('input:required')}}
                    />
                </div>
                <div className={'card'}>
                    <Input
                        edit={true} label={t('alarmManagement:attributes.is_beda_monitoring_active')}
                        name={'is_beda_monitoring_active'}
                        onChange={() => {if (props.setNotificationData) {updateNotificationData();}}}
                        type={'checkbox'} validationControl={control} validationRules={{required: false}}
                    />
                    {!getValues('is_timed') &&
                    <Input
                        edit={true} disabled={!getValues('is_beda_monitoring_active')}
                        label={t('alarmManagement:attributes.number_beda_alarms')} name={'number_beda_alarms'}
                        onChange={() => {if (props.setNotificationData) {updateNotificationData();}}}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('alarmManagement:attributes.number_beda_alarms') + ' ' + t('input:required')}}
                    />}
                    {!getValues('is_timed') &&
                        <Input
                            edit={true}
                            disabled={!getValues('is_beda_monitoring_active')}
                            label={t('alarmManagement:attributes.report_from')}
                            name={'report_pre_alarm'}
                            type={'selectButton'}
                            value={getValues('report_pre_alarm')}
                            onChange={(e: any) => {
                                setValue('report_pre_alarm', e.value);
                                if (props.setNotificationData) {updateNotificationData();}
                            }}
                            selectButtonOptions={reportType}
                        />}
                </div>
            </>
        );

        const cmMetrologyProblemElements = (
            <>
                <div className={'card'}>
                    <Input
                        edit={true} label={t('alarmManagement:attributes.flag_auto_reset_after_minutes')}
                        name={'flag_auto_reset_after_minutes'}
                        onChange={() => {if (props.setNotificationData) {updateNotificationData();}}}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('alarmManagement:attributes.flag_auto_reset_after_minutes') + ' ' + t('input:required')}}
                    />
                    <Input
                        edit={true}
                        label={t('alarmManagement:attributes.execution_type')}
                        name={'is_timed'}
                        type={'selectButton'}
                        value={getValues('is_timed')}
                        onChange={(e: any) => {
                            setValue('is_timed', e.value);
                            if (props.setNotificationData) {updateNotificationData();}
                        }}
                        selectButtonOptions={executionType}
                    />
                    {getValues('is_timed') &&
                        <>
                            <IntervalSelection
                                interval={executionInterval}
                                onChange={(interval: Nullable<string>, intervalText: string) => {
                                    setExecutionInterval(interval);
                                    setExecutionIntervalText(intervalText);
                                    if (props.setNotificationData) {updateNotificationData();}
                                }}
                                intervalDay={getValues('interval_day')}
                                setIntervalDay={(intervalDay: string[]) => {
                                    setValue('interval_day', intervalDay);
                                }}
                                intervalTime={getValues('interval_time')}
                                validationControl={control}
                                validationErrorMessage={getFormErrorMessage}
                            />
                            {((executionInterval && executionInterval.split(';')[0] === '2') ||
                                    getValues('interval_day').length > 0) && getValues('interval_time').length > 0 &&
                                <p>{executionIntervalText}</p>}
                        </>}
                </div>
            </>
        );

        switch (parseInt(getValues('notification_type_id'))) {
            case 1: // Geomonitoring Warning
            case 6: // Geomonitoring Alarm
            case 7: // Geomonitoring Normal
                return gmInputElements;
            case 3: // Conditionmonitoring
                return cmInputElements;
            case 4: // "Conditionmonitoring Device Health"
            case 5: // "Conditionmonitoring Sensor Health"
                return cmMetrologyProblemElements;
            default:
                return (<></>);
        }
    };

    // Für den NotificationWizard: wenn man zwischen den Schritten hin und her springt müssen die Daten gespeichert werden
    const updateNotificationData = () => {
        let additionSettings = null;
        switch (parseInt(getValues('notification_type_id'))) {
            case 1: // Geomonitoring Warning
            case 6: // Geomonitoring Alarm
            case 7: // Geomonitoring Normal
                additionSettings = {
                    'coincidence_type_id': getValues('coincidence_type_id'),
                    'coincidence_level': getValues('coincidence_level')
                };
                break;
            case 3: // Conditionmonitoring
                additionSettings = {
                    'element_id': getValues('element_id'),
                    'is_spectral_monitoring_active': getValues('is_spectral_monitoring_active'),
                    'threshold_cmi': getValues('threshold_cmi'),
                    'number_cmi_alarms': getValues('number_cmi_alarms'),
                    'value_type': getValues('value_type'),
                    'is_beda_monitoring_active': getValues('is_beda_monitoring_active'),
                    'number_beda_alarms': getValues('number_beda_alarms'),
                    'report_pre_alarm': getValues('report_pre_alarm'),
                    'flag_auto_reset_after_minutes': getValues('flag_auto_reset_after_minutes'),
                };
        }

        console.log('additionSettings: ', additionSettings)

        const data = {
            'notification_type_id': parseInt(getValues('notification_type_id')),
            'project_id': props.projectId,
            'name': getValues('name'),
            'description': getValues('description'),
            'additional_content': getValues('additional_content'),
            'is_enabled': getValues('is_enabled'),
            'is_timed': getValues('is_timed'),
            'execution_interval': executionInterval,
            'additional_settings': additionSettings,
            'coincidence_type_id': getValues('coincidence_type_id'),
            'coincidence_level': getValues('coincidence_level'),
            'element_id': getValues('element_id'),
            'is_spectral_monitoring_active': getValues('is_spectral_monitoring_active'),
            'threshold_cmi': getValues('threshold_cmi'),
            'number_cmi_alarms': getValues('number_cmi_alarms'),
            'value_type': getValues('value_type'),
            'is_beda_monitoring_active': getValues('is_beda_monitoring_active'),
            'number_beda_alarms': getValues('number_beda_alarms'),
            'report_pre_alarm': getValues('report_pre_alarm'),
            'flag_auto_reset_after_minutes': getValues('flag_auto_reset_after_minutes')
        };

        if (props.setNotificationData) {
            props.setNotificationData(data);
        }
    };

    return (
        <>
            <form id='formDialog' onSubmit={(event) => {
                if (props.setNotificationData) {
                    trigger().then((success) => {
                        event.preventDefault();
                        props.onFinished(success);
                    });
                } else {
                    trigger().then((isValid) => {
                        if (isValid) {
                            saveData(getValues());
                        }
                        event.preventDefault();
                    });
                }}}
            >
                <div className='grid'>
                    <div className='col-12 sm:col-6'>
                        <div className={'card'}>
                            <Input
                                edit={true} label={t('name')} name={'name'}
                                tooltip={checkProjectType(ProjectType.GeoMonitoring) && props.setNotificationData
                                    ? t('alarmManagement:tooltips.notificationFromWizardName')
                                    : ''}
                                onChange={() => {if (props.setNotificationData) {updateNotificationData();}}}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: t('name') + ' ' + t('input:required')}}
                            />
                            <Input
                                edit={true} label={t('description')} name={'description'}
                                onChange={() => {if (props.setNotificationData) {updateNotificationData();}}}
                                type={'textarea'} validationControl={control} validationRules={{required: false}}
                            />
                            <Input
                                edit={true} label={t('alarmManagement:attributes.additional_content')}
                                onChange={() => {if (props.setNotificationData) {updateNotificationData();}}}
                                name={'additional_content'}
                                type={'textarea'} validationControl={control} validationRules={{required: false}}
                            />
                            <Input
                                edit={true}
                                label={t('enabled')}
                                name={'is_enabled'}
                                type={'checkbox'}
                                value={getValues('is_enabled')}
                                onChange={(e: any) => {
                                    setValue('is_enabled', e.target.value);
                                    if (props.setNotificationData) {updateNotificationData();}
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-12 sm:col-6'>
                        <div className={'card'}>
                            <NotificationTypeSelectList
                                projectId={props.projectId}
                                name={'notification_type_id'}
                                label={t('alarmManagement:attributes.alarmType')}
                                disabled={notificationId !== null}
                                onChange={(e: any) => {
                                    setValue('notification_type_id', e.value);
                                    if (props.setNotificationData) {updateNotificationData();}
                                }}
                                validationControl={control}
                                validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: t('alarmManagement:attributes.alarmType') + ' ' + t('input:required')}}
                            />
                            {getNotificationSettingsInputElements()}
                            {(parseInt(getValues('notification_type_id')) === 1 || parseInt(getValues('notification_type_id')) === 6 ||
                              parseInt(getValues('notification_type_id')) === 7 || parseInt(getValues('notification_type_id')) === 3) &&
                                <Input
                                    edit={true} label={t('alarmManagement:attributes.max_allowed_data_age_in_minutes')}
                                    name={'max_allowed_data_age_in_minutes'} type={'number'} maxFractionDigits={0}
                                    dropdownOptions={[]}
                                    onChange={(e: any) => {
                                        if (props.setNotificationData) {
                                            updateNotificationData();
                                        } else {
                                            setValue('max_allowed_data_age_in_minutes', e.value);
                                        }
                                    }}
                                    tooltip={t('alarmManagement:tooltips.max_allowed_data_age_in_minutes')}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{
                                        ...{required: false},
                                        ...integerValidation(t), ...minValidation(t, 0)
                                    }}
                                />
                            }
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default NotificationEditForm;
