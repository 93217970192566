/*
 * NotificationWizard.tsx
 * Author: lnappenfeld
 * Date: 07.12.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Steps} from 'primereact/steps';
import NotificationEditForm from './NotificationEditForm';
import NotificationRangeEditForm, {Type} from './NotificationRangeEditForm';
import NotificationWizardOptionForm from './NotificationWizardOptionForm';
import {Nullable} from '../functions/Global';
import {Channel, getChannels, getElementTree} from '../functions/Metrology';
import {
    Action,
    createNotificationFromWizard,
    getActions,
    getMonitoringTasks,
    getNotificationTemplates, MonitoringTask,
} from '../functions/Notification';
import {NotificationWizardChannelOverview} from './NotificationWizardChannelOverview';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {Button} from "primereact/button";
import {ActionOverviewType, ActionsOverview} from '../userManagement/ActionsOverview';
import {ElementOverview} from '../metrology/ElementOverview';
import {MonitoringTaskOverviewType, MonitoringTasksOverview} from '../conditionMonitoring/monitoring/MonitoringTasksOverview';


type Props = {
    projectId: any
    onFinished: (success: boolean) => void,
    setSubmitButtonDisabled: (disabled: boolean) => void
}

const NotificationWizard = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const gmSteps = [
        {label: t('alarmManagement:global.notification')},
        {label: t('metrologyManagement:global.channels')},
        {label: t('alarmManagement:global.notificationRanges')},
        {label: t('userManagement:global.actions')},
        {label: t('options')}
    ];

    const channelProblemSteps = [
        {label: t('alarmManagement:global.notification')},
        // {label: t('metrologyManagement:global.channels')},
        {label: t('userManagement:global.actions')},
        {label: t('options')}
    ];

    const cmSteps = [
        {label: t('alarmManagement:global.notification')},
        {label: t('metrologyManagement:global.elementTree')},
        {label: t('alarmManagement:global.monitoringTasks')},
        {label: t('userManagement:global.actions')},
        {label: t('options')}
    ];

    const deviceHealthSteps = [
        {label: t('alarmManagement:global.notification')},
        // {label: t('metrologyManagement:global.measuringDevices')},
        {label: t('userManagement:global.actions')},
        {label: t('options')}
    ];

    const sensorHealthSteps = [
        {label: t('alarmManagement:global.notification')},
        // {label: t('metrologyManagement:global.sensors')},
        {label: t('userManagement:global.actions')},
        {label: t('options')}
    ];

    const [activeIndex, setActiveIndex] = useState(0);
    const [steps, setSteps] = useState(gmSteps);


    // Daten in dieser Komponente verwalten, damit die Tabellen beim Wechseln der Steps nicht
    // immer wieder neu geladen werden müssen
    const [channels, setChannels] = useState<Channel[] | []>([]);
    const [actions, setActions] = useState<Action[] | []>([]);
    const [monitoringTasks, setMonitoringTasks] = useState<MonitoringTask[] | []>([]);
    const [elements, setElements] = useState([]);
    const [notificationTemplates, setNotificationTemplates] = useState([]);

    const [notificationData, setNotificationData] = useState<Nullable<any>>(null);
    const [notificationRangeData, setNotificationRangeData] = useState<Nullable<any>>(null);
    const [optionData, setOptionData] = useState<Nullable<any>>(null);
    const [selectedChannels, setSelectedChannels] = useState<Channel[]>([]);
    const [selectedActions, setSelectedActions] = useState<Action[]>([]);
    const [selectedMonitoringTasks, setSelectedMonitoringTasks] = useState<MonitoringTask[]>([]);
    const [selectedElementId, setSelectedElementId] = useState<Nullable<any>>(null);

    const _setChannels = () => {
        getChannels(props.projectId).then(result => {
            setChannels(result);
        });
    };

    const _setActions = () => {
        getActions(props.projectId, t).then(result => {
            setActions(result);
        });
    };

    const _setMonitoringTasks = () => {
        getMonitoringTasks(props.projectId, selectedElementId).then(result => {
            setMonitoringTasks(result);
        });
    };

    const _setElements = () => {
        getElementTree(props.projectId).then((data) =>{
            setElements(data.root);
        });
    };

    const _setNotificationTemplates = () => {
        getNotificationTemplates(props.projectId).then(result => {
            const tmp: any = [];
            result.forEach((key: any) => {
                tmp.push({
                    value: key.template_id,
                    label: key.name,
                    actionTypeId: key.action_type_id,
                    notificationTypeId: key.notification_type_id
                });
            });
            setNotificationTemplates(tmp);
        });
    };

    useEffect(() => {
        _setChannels();
        _setActions();
        _setMonitoringTasks();
        _setElements();
        _setNotificationTemplates();
    }, []);

    useEffect(() => {
        _setMonitoringTasks();
        setSelectedMonitoringTasks([]);
    }, [selectedElementId]);

    useEffect(() => {
        if (notificationData === null) return;

        switch (notificationData.notification_type_id) {
            case 1: // "Geomonitoring Warning"
            case 6: // "Geomonitoring Alarm"
            case 7: // "Geomonitoring Normal"
                setSteps(gmSteps);
                break;
            case 3: // "Conditionmonitoring"
                setSteps(cmSteps);
                break;
            case 2: // "Geomonitoring Channel Health (ChannelProblem)"
                setSteps(channelProblemSteps);
                break;
            case 4: // "Conditionmonitoring Device Health"
                setSteps(deviceHealthSteps);
                break;
            case 5: // "Conditionmonitoring Sensor Health"
                setSteps(sensorHealthSteps);
                break;
            default:
                break;
        }
    }, [notificationData]);

    // Speicherbutton nur freischalten wenn alles nötige ausgewählt und eingetragen ist
    useEffect(() => {
        if (notificationData === null || notificationData.name === null || notificationData.name === '' ||
            selectedChannels.length === 0 || selectedActions.length === 0 || notificationRangeData === null ||
            optionData === null || optionData.template_email_id === null || optionData.template_sms_id === null ||
            optionData.notification_create_type_id === null) {
            props.setSubmitButtonDisabled(true);
        } else {
            props.setSubmitButtonDisabled(false);
        }
    }, [notificationData, notificationRangeData, optionData, selectedChannels, selectedActions]);

    const saveData = () => {
        console.log('saveData!!');
        const data = {
            'project_id': props.projectId,
            'notification_type_id': notificationData.notification_type_id,
            'notification_create_type_id': optionData.notification_create_type_id,
            'data': JSON.stringify({
                notification: notificationData,
                range: notificationRangeData,
                actions: selectedActions,
                channels: selectedChannels,
                templates: optionData,
                elements: []
            })
        };

        createNotificationFromWizard(data).then(result => {
            if (result.error) {
                showMessageOnError(t('error'), JSON.stringify(result.error));
                return;
            } else {
                showMessageOnSuccess(t('success'), t('alarmManagement:toasts.notificationFromWizardCreated'));
                props.onFinished(true);
            }
        });
    };


    const pageNotificationEditForm = () => {
        return (
            <NotificationEditForm
                projectId={props.projectId}
                notificationData={notificationData}
                setNotificationData={setNotificationData}
                onFinished={(success) => {
                    if (success) {
                        saveData();
                    }
                }}
            />
        );
    };

    const pageChannelOverview = () => {
        return (
            <NotificationWizardChannelOverview
                projectId={props.projectId}
                channels={channels}
                selectedChannels={selectedChannels}
                setSelectedChannels={setSelectedChannels}
                onFinished={(success) => {
                    if (success) {
                        saveData();
                    }
                }}
            />
        );
    };

    const pageNotificationRangeEditForm = () => {
        return (
            <NotificationRangeEditForm
                projectId={props.projectId}
                notificationRangeData={notificationRangeData ? [notificationRangeData] : []}
                setNotificationRangeData={setNotificationRangeData}
                type={Type.Wizard}
                onFinished={(success) => {
                    if (success) {
                        saveData();
                    }
                }}
            />
        );
    };

    const pageElementOverview = () => {
        return (
            <ElementOverview
                projectId={props.projectId}
                elements={elements}
                setSelectedElementId={setSelectedElementId}
                // channels={channels}
                // selectedChannels={selectedChannels}
                // setSelectedChannels={setSelectedChannels}
                // onFinished={(success) => {
                //     if (success) {
                //         saveData();
                //     }
                // }}
            />
        );
    };

    const pageMonitoringTaskOverview = () => {
        return (
            <form id='formDialog' onSubmit={(event) => {
                event.preventDefault();
                if (selectedMonitoringTasks.length > 0) {
                    saveData();
                }
            }}>
                <MonitoringTasksOverview
                    projectId={props.projectId}
                    type={MonitoringTaskOverviewType.NotificationWizard}
                    monitoringTasks={monitoringTasks}
                    selectedMonitoringTasks={selectedMonitoringTasks}
                    setSelectedMonitoringTasks={setSelectedMonitoringTasks}
                    showHeader={true}
                    // elementId={selectedElementId}
                />
            </form>
        );
    };

    const pageActionsOverview = () => {
        return (
            <form id='formDialog' onSubmit={(event) => {
                event.preventDefault();
                if (selectedActions.length > 0) {
                    saveData();
                }
            }}>
                <ActionsOverview
                    projectId={props.projectId}
                    type={ActionOverviewType.NotificationWizard}
                    actions={actions}
                    selectedActions={selectedActions}
                    setSelectedActions={setSelectedActions}
                />
            </form>
        );
    };

    const pageOptions = () => {
        return (
            <NotificationWizardOptionForm
                data={optionData}
                setData={setOptionData}
                templates={notificationTemplates}
                notificationTypeId={notificationData ? notificationData.notification_type_id : null}
                onFinished={(success) => {
                    if (success) {
                        saveData();
                    }
                }}
            />
        );
    };

    const renderPageGM = () => {
        switch (activeIndex) {
            case 0:
                return pageNotificationEditForm();
            case 1:
                return pageChannelOverview();
            case 2:
                return pageNotificationRangeEditForm();
            case 3:
                return pageActionsOverview();
            case 4:
                return pageOptions();
        }
    };

    const renderPageMetrologyProblem = () => {
        switch (activeIndex) {
            case 0:
                return pageNotificationEditForm();
            case 1:
                return pageActionsOverview();
            case 2:
                return pageOptions();
        }
    };

    const renderPageCM = () => {
        switch (activeIndex) {
            case 0:
                return pageNotificationEditForm();
            case 1:
                return pageElementOverview();
            case 2:
                return pageMonitoringTaskOverview();
            case 3:
                return pageActionsOverview();
            case 4:
                return pageOptions();
        }
    };

    const renderPage = () => {
        if (notificationData === null) return renderPageGM();

        switch (notificationData.notification_type_id) {
            case 1: // "Geomonitoring Warning"
            case 6: // "Geomonitoring Alarm"
            case 7: // "Geomonitoring Normal"
                return renderPageGM();
            case 3: // "Conditionmonitoring"
                return renderPageCM();
            case 2: // "Geomonitoring Channel Health (ChannelProblem)"
            case 4: // "Conditionmonitoring Device Health"
            case 5: // "Conditionmonitoring Sensor Health"
                return renderPageMetrologyProblem();
            default:
                break;
        }
    };

    return (
        <div>
            <div className="card">
                <Steps model={steps} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false}/>
            </div>
            <div className="card wizard-scroll-card-content">
                {renderPage()}
            </div>
            <div className="card wizard-button-card">
                <div className="grid grid-nogutter pt-1">
                    <div className="col">
                        {activeIndex > 0 && <Button onClick={() => {
                            setActiveIndex(activeIndex - 1);
                        }}>{t('back')}</Button>}
                    </div>
                    <div className="col-auto">
                        {activeIndex < steps.length-1 && <Button onClick={() => {
                            setActiveIndex(activeIndex + 1);
                        }}>{t('further')}</Button>}
                    </div>
                </div>
            </div>
        </div>
    );

};

export default NotificationWizard;

