import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';

export type SystemSetting = {
    key: string,
    value: string
}

export async function getSystemSettings() {
    let retValue:any = [];
    await keycloakfetch.get(settings.apiUrl + '/getSystemSettings?').then(result =>{
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createSystemSetting(data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/createSystemSetting', data).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function updateSystemSetting(data: any) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/updateSystemSetting', data).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}