import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';
import {getUserFromLocalStorage, Nullable} from './Global';

export type Notification = {
    'id': string,
    'notification_type_id': Nullable<number>,
    'notification_type_name': Nullable<string>,
    'project_id': Nullable<string>,
    'name': Nullable<string>,
    'description': Nullable<string>,
    'additional_content': Nullable<string>,
    'is_enabled': Nullable<boolean>,
    'is_timed': Nullable<boolean>,
    'execution_interval': Nullable<string>,
    'count': Nullable<number>,
    'created_at_utc': Nullable<string>,
    'additional_settings': any,
    'last_timestamp': Nullable<any>,
    'max_allowed_data_age_in_minutes': Nullable<number>
}
export type NotificationRange = {
    'id': string,
    'notification_id': string,
    'channel_id': string,
    'channel_name': string,
    'minimum': Nullable<number>,
    'maximum': Nullable<number>,
    'flag': boolean,
    'flag_set_value': number,
    'flag_set_value_date': string,
    'flag_set_when_utc': string,
    'flag_auto_reset_after_minutes': Nullable<number>,
    'flag_changed_by_user_id': string,
    'flag_changed_by_user_name': string,
    'minimum_change_on_autoreset': number,
    'maximum_change_on_autoreset': number
}

export type Action = {
    'action_id': Nullable<string>,
    'action_name': string,
    'project_id': string,
    'action_type_id': number,
    'action_type_name': string,
    'recipient_address': string,
    'recipient_address_source': number
    'recipient_id': string
    'recipient_type': number
    'email': string,
    'sms': string
}

export type NotificationActionMap = {
    'notification_id':            string;
    'notification_name':          string;
    'notification_type_id':       number;
    'notification_type_name':     string;
    'action_id':                  string;
    'action_name':                string;
    'action_type_id':             number;
    'action_type_name':           string;
    'notification_template_id':   string;
    'notification_template_name': string;
    'is_enabled':                 boolean;
    'is_disabled_until_utc':      string;
    'project_id':                 string;
    'recipient_address_source':   number;
    'recipient_type':             string;
    'recipient_address':          string;
}

export type MonitoringTask = {
    'monitoring_task_name': string,
    'monitoring_task_id': string,
    'element_id': string,
    'element_name': string,
    'channel_id': string,
    'channel_name': string,
    'project_id': string,
    'operating_state_id': Nullable<string>, // Betriebszustand der den logischen Kanal triggert, der überwacht wird
    'description': Nullable<string>,
    'rotational_speed_mode': number,
    'rotational_speed_source_id': Nullable<string>,
    'rotational_speed_value': number,
    'rotational_speed_width': number,
    'last_measuring': Nullable<string>,
    'last_runtime_measuring': Nullable<string>,
    'last_daily_average_measuring': Nullable<string>,
    'is_active': boolean,
    'count': number
}

export type MonitoringTaskLimit = {
    'monitoring_task_limit_id':      string,
    'monitoring_task_id':            string;
    'monitoring_task_name'?:         string;
    'element_id'?:                   string,
    'element_name'?:                 string,
    'channel_id'?:                   string,
    'channel_name'?:                 string,
    'project_id'?:                   string;
    'element_limit_id':              Nullable<string>;
    'amplitude':                     number;
    'amplitude_das':                 number;
    'amplitude_ras':                 number;
    'center_frequency':              number;
    'frequency_range':               number;
    'type':                          number;
    'description':                   Nullable<string>;
    'is_rotational_speed_dependent': boolean;
    'is_automatic':                  boolean;
    'is_description_visible':        boolean;
    'is_threshold_visible':          boolean;
}

export type CharacteristicMonitoringTask = {
    'id': string,
    'characteristic_monitoring_task_name': string,
    'characteristic_monitoring_task_type_id': number,
    'characteristic_monitoring_task_type_name': string,
    'monitoring_app_id': string,
    'monitoring_app_name': string,
    'element_id': string,
    'element_name': string,
    'channel_id': string,
    'channel_name': string,
    'project_id': string,
    'description': Nullable<string>,
    'is_active': boolean,
    'good_from': number,
    'good_to': number,
    'pre_alarm_from': number,
    'pre_alarm_to': number,
    'main_alarm_from': number,
    'main_alarm_to': number,
    'flag': boolean,
    'flag_set_value': number,
    'flag_set_value_date': string,
    'flag_set_when_utc': string,
    'flag_auto_reset_after_minutes': Nullable<number>,
    'flag_changed_by_user_id': string,
    'flag_changed_by_user_name': string,
    'count': number
}

// ---------------------------------------------------------------------------------------------------------------------
// Notifications
// ---------------------------------------------------------------------------------------------------------------------
export async function getNotifications(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getNotifications?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

/**
 * Erhalte Benachrichtigungen für die Komponente zum Selektieren (MultiSelect) von Benachrichtigungen im Formular
 * 'MonitoringTaskForm'. Es werden nur Benachrichtigungen vom Typ 3 ('ConditionMonitoring') zurückgegeben und wenn
 * is_spectral_monitoring_active = True ist.
 * @param projectId
 * @param elementId - KomponentenId an dem die Überwachungsaufgabe hängt
 */
export async function getNotificationsForMonitoringTask(projectId: string, elementId: string) {
    let retValue: any = [];
    const attributeStr = '?project_id=' + projectId + '&element_id=' + elementId;
    await keycloakfetch.get(settings.apiUrl + '/getNotificationsForMonitoringTask' + attributeStr).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

/**
 * Erhalte Benachrichtigungen für die Komponente zum Selektieren (MultiSelect) von Benachrichtigungen im Formular
 * 'CharactersticMonitoringTaskForm'. Es werden nur Benachrichtigungen vom Typ 3 ('ConditionMonitoring') zurückgegeben
 * und wenn is_beda_monitoring_active = True ist.
 * @param projectId
 * @param elementId - KomponentenId an dem die Kennwertüberwachungsaufgabe hängt
 */
export async function getNotificationsForCharacteristicMonitoringTask(projectId: string, elementId: string) {
    let retValue: any = [];
    const attributeStr = '?project_id=' + projectId + '&element_id=' + elementId;
    await keycloakfetch.get(settings.apiUrl + '/getNotificationsForCharacteristicMonitoringTask' + attributeStr).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getNotificationTypes(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getNotificationTypes?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function updateNotification(data: any) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/updateNotification', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createNotification(data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/createNotification', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createNotificationFromWizard(data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/createNotificationFromWizard', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function deleteNotification(projectId: string, notificationId: string[]) {
    let retValue: any = [];
    await keycloakfetch.delete(settings.apiUrl + '/deleteNotification', {
        'project_id': projectId,
        'notification_id': notificationId
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function disableNotificationTemporary(data: any) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/disableNotificationTemporary', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

// ---------------------------------------------------------------------------------------------------------------------
// NotificationRanges
// ---------------------------------------------------------------------------------------------------------------------

export async function getNotificationRanges(projectId: string, notificationId: string = '') {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getNotificationRanges?project_id=' + projectId + '&notification_id=' + notificationId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

// aktualisiere eine oder mehrere Ranges
export async function updateNotificationRanges(projectId: string, notificationRangeIds: string[], data: any) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/updateNotificationRanges',
        {...data, 'project_id': projectId, 'notification_range_ids': notificationRangeIds}
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createNotificationRange(projectId: string, data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/createNotificationRange',
        {...data, 'project_id': projectId}
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function deleteNotificationRange(projectId: string, notificationRangeId: string[]) {
    let retValue: any = [];
    await keycloakfetch.delete(settings.apiUrl + '/deleteNotificationRange', {
        'project_id': projectId,
        'notification_range_id': notificationRangeId
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function resetNotificationRangeFlag(projectId: string, notificationRangeId: string) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/resetNotificationRangeFlag', {
        'project_id': projectId,
        'notification_range_id': notificationRangeId
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

// ---------------------------------------------------------------------------------------------------------------------
// Notification Actions
// ---------------------------------------------------------------------------------------------------------------------
export async function getNotificationActions(projectId: string, notificationId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getNotificationActions?project_id=' + projectId + '&notification_id=' + notificationId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getNotificationsActions(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getNotificationsActions?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createNotificationAction(projectId: string, data: any) {
    let retValue: any = [];
    data = {...{'project_id': projectId}, ...data};
    await keycloakfetch.put(settings.apiUrl + '/createNotificationAction', data).then(result => {
        retValue = result;
    });
    return retValue;
}

export async function updateNotificationAction(projectId: string, data: any) {
    let retValue: any = [];
    data = {...{'project_id': projectId}, ...data};
    await keycloakfetch.post(settings.apiUrl + '/updateNotificationAction', data).then(result => {
        retValue = result;
    });
    return retValue;
}

export async function deleteNotificationAction(projectId: string, notificationId: string[], actionId: string[]) {
    let retValue: any = [];
    await keycloakfetch.delete(settings.apiUrl + '/deleteNotificationAction', {
        'project_id': projectId,
        'notification_id': notificationId,
        'action_id': actionId
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function activateNotificationState(projectId: string, notificationId: string[]) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/activateNotificationState', {
        'project_id': projectId,
        'notification_id': notificationId
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function deactivateNotificationState(projectId: string, notificationId: string[]) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/deactivateNotificationState', {
        'project_id': projectId,
        'notification_id': notificationId
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function toggleNotificationActionState(projectId: string, notificationId: string, actionId: string) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/toggleNotificationActionState', {
        'project_id': projectId,
        'notification_id': notificationId,
        'action_id': actionId
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function activateNotificationActionState(projectId: string, notificationId: string[], actionId: string[]) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/activateNotificationActionState', {
        'project_id': projectId,
        'notification_id': notificationId,
        'action_id': actionId
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function deactivateNotificationActionState(projectId: string, notificationId: string[], actionId: string[]) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/deactivateNotificationActionState', {
        'project_id': projectId,
        'notification_id': notificationId,
        'action_id': actionId
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function disableNotificationActionTemporary(data: any) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/disableNotificationActionTemporary', data
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function addActionsToNotifications(projectId: string, data: any) {
    let retValue: any = [];
    data = {...{'project_id': projectId}, ...data};
    await keycloakfetch.put(settings.apiUrl + '/addActionsToNotifications', data).then(result => {
        retValue = result;
    });
    return retValue;
}

export async function removeActionsFromNotifications(projectId: string, notifications: Notification[], actions: Action[]) {
    let retValue: any = [];
    await keycloakfetch.delete(settings.apiUrl + '/removeActionsFromNotifications', {
        'project_id': projectId,
        'notifications': JSON.stringify(notifications),
        'actions': JSON.stringify(actions)
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

// ---------------------------------------------------------------------------------------------------------------------
// Actions
// ---------------------------------------------------------------------------------------------------------------------
export async function getActions(projectId: string, t: any, actionTypeId: number | null = null) {
    let retValue: any = [];
    let additionalParams = '';
    if (actionTypeId !== null) additionalParams = '&action_type_id=' + actionTypeId;

    await keycloakfetch.get(settings.apiUrl + '/getActions?project_id=' + projectId + additionalParams).then(result => {
        if (result) {
            // Recipient Type aus Übersetzungsdatei lesen
            for (const i in result) {
                result[i]['recipient_type'] = t('actions:recipientType.' + result[i].recipient_type);
            }
            retValue = result;
        }
    });
    return retValue;
}

export async function getActionOfUser(projectId: string, recipientId: string, actionTypeId: number, t: any) {
    let retValue: any = null;
    const params = '?project_id=' + projectId + '&recipient_id=' + recipientId + '&action_type_id=' + actionTypeId;
    await keycloakfetch.get(settings.apiUrl + '/getActionOfUser' + params).then(result => {
        if (result) {
            // Recipient Type aus Übersetzungsdatei lesen
            for (const i in result) {
                result[i]['recipient_type'] = t('actions:recipientType.' + result[i].recipient_type);
            }
            retValue = result[0];
        }
    });
    return retValue;
}

export async function getActionTypes() {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getActionTypes').then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function deleteActions(projectId: string, actionIds: string[]) {
    let retValue: any = [];
    await keycloakfetch.delete(settings.apiUrl + '/deleteActions', {
        'action_ids': actionIds,
        'project_id': projectId
    }).then((result) => {
        retValue = result;
    });
    return retValue;
}

function _createActionObject(projectId: string, data: any, actionId: string = '') {

    let action = {
        'project_id': projectId,
        'action_type_id': parseInt(data.action_type_id),
        'name': data.name,
        'recipient_id': data.recipient_address_source === 1 ? null : data.recipient_id,
        'recipient_address_source': data.recipient_address_source,
        'recipient_address': data.recipient_address_source === 0 ? null : data.recipient_address
    };
    const actionAddId = {'action_id': actionId};
    if (actionId !== '')
        action = {...actionAddId, ...action};
    return action;
}

export async function updateAction(projectId: string, actionId: string, data: any) {
    let retValue: any = [];
    const action = _createActionObject(projectId, data, actionId);
    await keycloakfetch.post(settings.apiUrl + '/updateAction', action).then(result => {
        retValue = result;
    });
    return retValue;
}

export async function createAction(projectId: string, data: any) {
    let retValue: any = [];
    const action = _createActionObject(projectId, data);
    await keycloakfetch.put(settings.apiUrl + '/createAction', action).then(result => {
        retValue = result;
    });
    return retValue;
}

// ---------------------------------------------------------------------------------------------------------------------
// Alarms
// ---------------------------------------------------------------------------------------------------------------------
export async function getAlarms(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getAlarms?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getHistoricalAlarms(projectId: string, notificationId: string, from: Date | undefined, to: Date | undefined) {
    let retValue: any = [];
    const timezone = getUserFromLocalStorage().timezone;
    const params = '?project_id=' + projectId + '&notification_id=' + notificationId +
        (from ? '&from=' + from.toISOString() : '') + (to ? '&to=' + to.toISOString() : '') +
        (timezone ? '&timezone=' + timezone : '');
    await keycloakfetch.get(settings.apiUrl + '/getHistoricalAlarms' + params).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function rateHistoricalAlarms(projectId: string, data: any) {
    let retValue: any = [];
    data = {...{'project_id': projectId}, ...data};
    await keycloakfetch.post(settings.apiUrl + '/rateHistoricalAlarms', data).then(result => {
        retValue = result;
    });
    return retValue;
}

// ---------------------------------------------------------------------------------------------------------------------
// Templates
// ---------------------------------------------------------------------------------------------------------------------
export async function getNotificationTemplates(projectId: string, notificationId: string | null = null) {
    let retValue: any = [];
    let additionalParams = '';
    if (notificationId !== null) additionalParams = '&notification_id=' + notificationId;
    await keycloakfetch.get(settings.apiUrl + '/getNotificationTemplates?project_id=' + projectId + additionalParams).then(result => {
        if (result) {
            for (const i in result) {
                const tmpContent = document.createElement('p');
                tmpContent.innerHTML = result[i].content;
                result[i].text_content = tmpContent.textContent;
            }
            retValue = result;
        }
    });
    return retValue;
}

export async function getNotificationTemplate(projectId: string, notificationTemplateId: string) {
    let retValue: any = [];
    const params = '?project_id=' + projectId + '&template_id=' + notificationTemplateId;
    await keycloakfetch.get(settings.apiUrl + '/getNotificationTemplate' + params).then(result => {
        if (result) {
            for (const i in result) {
                const tmpContent = document.createElement('p');
                tmpContent.innerHTML = result[i].content;
                result[i].text_content = tmpContent.textContent;
            }
            retValue = result;
        }
    });
    return retValue;
}

export async function getNotificationTemplateMetadata(notificationTypeId: number) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getNotificationTemplateMetadata?notification_type_id=' + notificationTypeId).then(result => {
        if (result) {
            retValue = result;
        }
    });
    return retValue;
}

export async function updateNotificationTemplate(projectId: string, template: any) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/updateNotificationTemplate', template).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createNotificationTemplate(projectId: string, template: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/createTemplate', template).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

// ---------------------------------------------------------------------------------------------------------------------
// MonitoringTasks
// ---------------------------------------------------------------------------------------------------------------------
export async function getMonitoringTasks(projectId: string, elementId: Nullable<string> = null) {
    let retValue: any = [];
    let additionalParamStr = '';
    if (elementId) additionalParamStr = '&element_id=' + elementId;
    await keycloakfetch.get(settings.apiUrl + '/getMonitoringTasks?project_id=' + projectId + additionalParamStr).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function updateMonitoringTask(projectId: string, data: any) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/updateMonitoringTask',
        {...data, 'project_id': projectId}
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createMonitoringTask(projectId: string, data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/createMonitoringTask',
        {...data, 'project_id': projectId}
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function deleteMonitoringTasks(projectId: string, monitoringTaskIds: string[]) {
    let retValue: any = [];
    await keycloakfetch.delete(settings.apiUrl + '/deleteMonitoringTasks', {
        'project_id': projectId,
        'monitoring_task_ids': monitoringTaskIds
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function resetMonitoringTaskFlag(projectId: string, monitoringTaskId: string) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/resetMonitoringTaskFlag', {
        'project_id': projectId,
        'monitoring_task_id': monitoringTaskId
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function activateMonitoringTasksState(projectId: string, monitoringTaskIds: string[]) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/activateMonitoringTasksState', {
        'project_id': projectId,
        'monitoring_task_ids': monitoringTaskIds
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function deactivateMonitoringTasksState(projectId: string, monitoringTaskIds: string[]) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/deactivateMonitoringTasksState', {
        'project_id': projectId,
        'monitoring_task_ids': monitoringTaskIds
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getNotificationTaskMonitoringTaskMap(projectId: string, notificationTaskId: Nullable<string> = null, monitoringTaskId: Nullable<string> = null) {
    let retValue: any = [];
    let additionalParamStr = '';
    if (notificationTaskId) {
        additionalParamStr = '&notification_task_id=' + notificationTaskId;
    } else if (monitoringTaskId) {
        additionalParamStr = '&monitoring_task_id=' + monitoringTaskId;
    }
    await keycloakfetch.get(settings.apiUrl + '/getNotificationTaskMonitoringTaskMap?project_id=' + projectId + additionalParamStr).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

// ---------------------------------------------------------------------------------------------------------------------
// MonitoringTaskLimits
// ---------------------------------------------------------------------------------------------------------------------
export async function getMonitoringTaskLimits(projectId: string, monitoringTaskId: string) {
    let retValue: any = [];
    const endpointStr = '/getMonitoringTaskLimits?project_id=' + projectId + '&monitoring_task_id=' + monitoringTaskId;
    await keycloakfetch.get(settings.apiUrl + endpointStr).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

// ---------------------------------------------------------------------------------------------------------------------
// CharacteristicMonitoringTasks
// ---------------------------------------------------------------------------------------------------------------------
export async function getCharacteristicMonitoringTaskTypes(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getCharacteristicMonitoringTaskTypes?project_id=' + projectId).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}
export async function getCharacteristicMonitoringTasks(projectId: string, elementId: Nullable<string> = null) {
    let retValue: any = [];
    let additionalParamStr = '';
    if (elementId) additionalParamStr = '&element_id=' + elementId;
    await keycloakfetch.get(settings.apiUrl + '/getCharacteristicMonitoringTasks?project_id=' + projectId + additionalParamStr).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}
export async function updateCharacteristicMonitoringTask(projectId: string, data: any) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/updateCharacteristicMonitoringTask',
        {...data, 'project_id': projectId}
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}
export async function createCharacteristicMonitoringTask(projectId: string, data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/createCharacteristicMonitoringTask',
        {...data, 'project_id': projectId}
    ).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}
export async function deleteCharacteristicMonitoringTasks(projectId: string, characteristicMonitoringTaskIds: string[]) {
    let retValue: any = [];
    await keycloakfetch.delete(settings.apiUrl + '/deleteCharacteristicMonitoringTasks', {
        'project_id': projectId,
        'characteristic_monitoring_task_ids': characteristicMonitoringTaskIds
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}
export async function resetCharacteristicMonitoringTaskFlag(projectId: string, characteristicMonitoringTaskId: string) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/resetCharacteristicMonitoringTaskFlag', {
        'project_id': projectId,
        'characteristic_monitoring_task_id': characteristicMonitoringTaskId
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}
export async function activateCharacteristicMonitoringTasksState(projectId: string, characteristicMonitoringTaskIds: string[]) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/activateCharacteristicMonitoringTasksState', {
        'project_id': projectId,
        'characteristic_monitoring_task_ids': characteristicMonitoringTaskIds
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}
export async function deactivateCharacteristicMonitoringTasksState(projectId: string, characteristicMonitoringTaskIds: string[]) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/deactivateCharacteristicMonitoringTasksState', {
        'project_id': projectId,
        'characteristic_monitoring_task_ids': characteristicMonitoringTaskIds
    }).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getNotificationTaskCharacteristicMonitoringTaskMap(projectId: string, notificationTaskId: Nullable<string> = null, characteristicMonitoringTaskId: Nullable<string> = null) {
    let retValue: any = [];
    let additionalParamStr = '';
    if (notificationTaskId) {
        additionalParamStr = '&notification_task_id=' + notificationTaskId;
    } else if (characteristicMonitoringTaskId) {
        additionalParamStr = '&characteristic_monitoring_task_id=' + characteristicMonitoringTaskId;
    }
    await keycloakfetch.get(settings.apiUrl + '/getNotificationTaskCharacteristicMonitoringTaskMap?project_id=' + projectId + additionalParamStr).then(result => {
        if (result)
            retValue = result;
    });
    return retValue;
}
