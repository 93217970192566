/*
 * MeasuringManagerOverview.tsx
 * Author: lnappenfeld
 * Date: 25.05.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Channel, getMeasuringManagers, MeasuringManager, Sensor,
} from '../functions/Metrology';
import {checkPermission, getGroupedItems} from '../../functions/functionLibrary';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {permissions} from '../../config/permissions';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {hideWaitAnimation, showWaitAnimation} from '../global/CustomWaitAnimation';
import {MeasuringDeviceOverview} from './MeasuringDeviceOverview';
import {getLocation, Location} from '../functions/Global';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencil} from '@fortawesome/free-solid-svg-icons';

type Props = {
    project: any,
    reload: boolean,
    setReload: (reload: boolean) => void
    setSelectedMeasuringManagers: (data: MeasuringManager[]) => void
    selectedMeasuringManagers: MeasuringManager[],
    setEditMeasuringManager: (data: boolean) => void,

    setSelectedLocations: (data: Location[]) => void,
    setEditLocation: (data: boolean) => void,

    reloadMeasuringDevices?: boolean,
    setReloadMeasuringDevices?: (reload: boolean) => void
    selectedMeasuringDevices?: any[],
    setSelectedMeasuringDevices?: (data: any[]) => void
    editMeasuringDevice?: boolean,
    setEditMeasuringDevice?: (data: boolean) => void,

    reloadSensors?: boolean,
    setReloadSensors?: (reload: boolean) => void
    setEditSensor?: (data: boolean) => void,
    setSelectedSensors?: (data: Sensor[]) => void
    selectedSensors?: Sensor[],

    reloadChannels?: boolean,
    setReloadChannels?: (reload: boolean) => void
    setSelectedChannels?: (data: Channel[]) => void
    selectedChannels?: Channel[],
    setEditChannel?: (data: boolean) => void,
}

export const MeasuringManagerOverview = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [measuringManagers, setMeasuringManagers] = useState<any[]>([]);

    // arrays for filter dropdowns
    const [locations, setLocations] = useState<any[]>([]);
    const [elements, setElements] = useState<any[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const _setMeasuringManagers = () => {
        showWaitAnimation();
        getMeasuringManagers(props.project.id).then(result => {
            console.log(result);
            for (let i = 0; i < result.length; i++) {
                const measuringManager = {...result[i]};
                delete measuringManager.expandableElement;
                // Füge die Unterkomponente nur hinzu, wenn der Messmanager auch Messgeräte verwaltet
                if (result[i].has_measuring_devices)
                    result[i]['expandableElement'] = (
                        <MeasuringDeviceOverview
                            key={'md'+i}
                            measuringManagerId={result[i].measuring_manager_id}
                            project={props.project}
                            showHeader={true}
                            reload={props.reloadMeasuringDevices}
                            setReload={props.setReloadMeasuringDevices}
                            setEditMeasuringDevice={props.setEditMeasuringDevice}
                            selectedMeasuringDevices={props.selectedMeasuringDevices}
                            setSelectedMeasuringDevices={props.setSelectedMeasuringDevices}
                            setEditSensor={props.setEditSensor}
                            selectedSensors={props.selectedSensors}
                            setSelectedSensors={props.setSelectedSensors}
                            reloadSensors={props.reloadSensors}
                            setReloadSensors={props.setReloadSensors}
                            setEditChannel={props.setEditChannel}
                            selectedChannels={props.selectedChannels}
                            setSelectedChannels={props.setSelectedChannels}
                            reloadChannels={props.reloadChannels}
                            setReloadChannels={props.setReloadChannels}
                            setEditLocation={props.setEditLocation}
                            setSelectedLocations={props.setSelectedLocations}
                            setEditMeasuringManager={props.setEditMeasuringManager}
                            setSelectedMeasuringManagers={props.setSelectedMeasuringManagers}
                        />
                    );
            }
            if (result.error === undefined) {
                setMeasuringManagers(result);
                setDataLoaded(true);
                setLocations(getGroupedItems(result, 'location_id', 'location_name'));
                setElements(getGroupedItems(result, 'element_id', 'element_name'));
            }
            hideWaitAnimation();
        });
    };

    useEffect(() => {
        _setMeasuringManagers();
        return () => props.setSelectedMeasuringManagers([]);
    }, []);

    useEffect(() => {
        // Lade neu, z.B. wenn Elemente gelöscht wurden
        if (props.reload) {
            _setMeasuringManagers();
            if (props.setReload) props.setReload(false);
        }
    }, [props.reload]);

    const columns: ColumnObject[] = [{
        field: 'measuring_manager_id', header: t('locations:attributes.measuringManagerId'),
        filter: false, display: false
    },{
        field: 'measuring_manager_name', header: t('name'),
        filter: true, filterField: 'measuring_manager_name'
    }, {
        field: 'description', header: t('description'),
        filter: true, filterField: 'description', display: false
    }, {
        field: 'identifier', header: t('metrologyManagement:attributes.identifier'),
        filter: true, filterField: 'identifier'
    },{
        field: 'location_id', header: t('locations:attributes.locationId'),
        filter: false, display: false
    },{
        field: 'location_name', header: t('location'),
        filter: true, filterField: 'location_id', filterDropdownValues: locations,
        body: (checkPermission(permissions.editMetrology) && props.setEditLocation) ? (device: any) => {
            return (<u className='cell-function' onClick={(e) => {
                e.stopPropagation();
                getLocation(props.project.id, device.location_id).then((location) => {
                    if (props.setSelectedLocations) props.setSelectedLocations([location]);
                    if (props.setEditLocation) props.setEditLocation(true);
                });
            }}>
                <FontAwesomeIcon icon={faPencil} className='ml-2'/>&nbsp;{device.location_name}</u>);
        } : null
    }, {
        field: 'element_id', header: t('metrologyManagement:attributes.sensorElementId'),
        filter: false, display: false
    }, {
        field: 'element_name', header: t('metrologyManagement:attributes.measuringManagerElementName'),
        filter: true, filterField: 'element_id', filterDropdownValues: elements
    }];

    const filters = {
        'measuring_manager_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'description': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'identifier': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'location_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'element_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        }
    };

    return (
        <>
            <CustomDataTable
                id="measuringManagers"
                selectionMode={'multiple'}
                expandable={true}
                columns={columns}
                editable={checkPermission(permissions.editMetrology)}
                sortable={true}
                displayColumnFilter={true}
                value={measuringManagers}
                paginator={measuringManagers.length > 100}
                selection={props.selectedMeasuringManagers}
                onSelectionChange={(e: any) => {
                    if (props.setSelectedMeasuringManagers) props.setSelectedMeasuringManagers(e.value);
                }}
                onEditClick={(data: MeasuringManager) => {
                    if (props.setSelectedMeasuringManagers) props.setSelectedMeasuringManagers([data]);
                    if (props.setEditMeasuringManager) props.setEditMeasuringManager(true);
                }}
                ableToDelete={checkPermission(permissions.editMetrology)}
                ableToUpdate={checkPermission(permissions.editMetrology)}
                filters={filters}
                dataloaded={dataLoaded}
                showExportCSV={true}
            />
        </>
    );
};
