import React, { useContext, useEffect, useState } from 'react';
import { ScaleLine } from 'ol/control';
import MapContext from '../map/MapContext';

const ScaleLineControl = () => {
    // @ts-ignore
    const { map } = useContext(MapContext);

    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;

        const scaleLineControll = new ScaleLine({});
        map.addControl(scaleLineControll);

        return () => map.controls.remove(scaleLineControll);
    }, [map]);

    return null;
};

export default ScaleLineControl;