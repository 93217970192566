const permissions = {
    "isSuperuser": 999,
    "isAdmin": 0,
    "seeLayer": 19,
    "editLayer": 20,
    "seeMeasuringData": 21,
    "seeAllUsers": 7,
    "seeUM": 1,
    "editUM": 2,
    "editProject": 6,
    "createProject": 13,
    "importExportDuplicateProjects": 28,
    "hideChannels": 8,
    "seeMetrology": 9,
    "editMetrology": 11,
    "controlDevices": 22,
    "turnOnDevices": 29,
    "turnOffDevices": 30,
    "seeNotification": 3,
    "editNotification": 4,
    "editNotificationTemplates": 5,
    "seeAlarms": 10,
    "editAlarms": 12,
    "seeAllProjects": 14,
    "seeReports": 15,
    "editReports": 16,
    "seeGrafanaDashboards": 17,
    "editGrafanaDashboards": 18,
    "seeNextcloud": 23,
    "editNextcloud": 32,
    "shareNextcloud": 33,
    "accessCesium": 24,
    "accessWiki": 31,
    "accessScanner": 35,
    "accessApp": 25,
    "editAppCQCode": 26,
    "editAppObservation": 27,
    "seeForecast":34,
    "saveProjektBookmark":36,
    "seeBilling": 37
};

const permissionsIcon = {
    "999":'pi pi-key', // isSuperuser
    "0":  'pi pi-shield', // isAdmin
    "1":  'pi pi-eye', // seeUM
    "3":  'pi pi-eye', // seeNotification
    "7":  'pi pi-eye', // seeAllUsers
    "9":  'pi pi-eye', // seeMetrology
    "10": 'pi pi-eye', // seeAlarms
    "14": 'pi pi-eye', // seeAllProjects
    "15": 'pi pi-eye', // seeReports
    "17": 'pi pi-eye', // seeGrafanaDashboards
    "19": 'pi pi-eye', // seeLayer
    "21": 'pi pi-eye', // seeMeasuringData
    "37": 'pi pi-money-bill', // seeBilling
    "23": 'pi pi-cloud', // seeNextcloud
    "32": 'pi pi-cloud', // editNextcloud
    "33": 'pi pi-share-alt', // shareNextcloud
    "24": 'pi pi-box', // accessCesium
    "31": 'pi pi-info-circle', // accessWiki
    "25": 'pi pi-mobile', // accessApp
    "8":  'pi pi-eye-slash', //hideChannels
    "2":  'pi pi-pencil', // editUM
    "4":  'pi pi-pencil', // editNotification
    "5":  'pi pi-pencil', // editNotificationTemplates
    "6":  'pi pi-pencil', // editProject
    "11": 'pi pi-pencil', // editMetrology
    "12": 'pi pi-pencil', // editAlarms
    "16": 'pi pi-pencil', // editReports
    "18": 'pi pi-pencil', // editGrafanaDashboards
    "20": 'pi pi-pencil', // editLayer
    "26": 'pi pi-pencil', // editAppCQCode
    "27": 'pi pi-pencil', // editAppObservation
    "13": 'pi pi-plus-circle', // createProject
    "22": 'pi pi-desktop', // controlDevices
    "29": 'pi pi-desktop', // turnOnDevices
    "30": 'pi pi-desktop', // turnOffDevices
    "28": 'pi pi-clone' // importExportDuplicateProjects
};

// dependencies:
// Ein Benutzer der Reports editieren kann, muss auch grafana dashboards sehen können
// Ein Benutzer der das Projekt editieren kann, muss auch Locations (Messtechnik) sehen können
// Ein Benutzer der Benachrichtiguen editieren kann, muss auch die Messtechnik sehen können
// Ein Benutzer der die Karte sehen kann, muss auch die Alarme sehen können
// ...
const dependPermissions = {
    '0': [],
    '1': [],
    '2': [1],                   // editUM
    '3': [9],                   // seeNotification
    '4': [1, 3, 9, 10],         // editNotification
    '5': [3],                   // editNotificationTemplates
    '6': [9, 17, 19],           // editProject
    '7': [1],                   // seeAllUsers
    '8': [9],                   // hideChannels
    '9': [],
    '10':[9],                   // seeAlarms
    '11':[9],                   // editMetrology
    '12':[3, 9, 10],            // editAlarms
    '16':[1, 9, 10, 15, 17],    // editReports
    '18':[1, 17],               // editGrafanaDashboards
    '32':[1, 23],               // editNextcloud
    '33':[23],                  // shareNextcloud
    '19':[10],                  // seeLayer
    '20':[19],                  // editLayer
    '21':[9],                   // seeMeasuringData
    '22':[9],                   // controlDevices
    '29':[9],                   // turnOnDevices
    '30':[9],                   // turnOffDevices
    '26':[25],                  // editAppCQCode
    '27':[25]                   // editAppObservation
};

export {
    permissions,
    permissionsIcon,
    dependPermissions
}
