import React, {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {fetchChannels} from '../../../functions/functionsOpenlayer';

import PlotChart from '../../plot/PlotChart';
import {Dialog, DialogPositionType} from 'primereact/dialog';
import {ProgressSpinner} from 'primereact/progressspinner';
import moment from 'moment/moment';

interface propsObj {
    [key: string]: any
}

type controlProps = {
    projectId: string,
    locationIds?: string[]
}

const ChartControl: FunctionComponent<controlProps> = ({projectId, locationIds}) => {
    const {t} = useTranslation(['common', 'geomonitoring']);
    const dialogRef: any = useRef(null);

    const nowTemp = new Date();
    const minusThreeDaysTemp = new Date(nowTemp);
    minusThreeDaysTemp.setDate(nowTemp.getDate() - 3);

    const [now, setNow] = useState<Date>(nowTemp);
    const [minusThreeDays, setMinusThreeDays] = useState<Date>(minusThreeDaysTemp);
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const [ids, setIds] = useState<any>(undefined);

    const [position, setPosition] = useState<DialogPositionType>('right');
    const [screenCoords, setScreenCoords] = useState<any[]>([]);

    useEffect(() => {
        const temp = [];

        const height = window.innerHeight / 3;
        const width = window.innerWidth / 3;

        let top = 0;
        let bottom = height;
        let left = 0;
        let right = width;

        temp.push({top: top, bottom: bottom, left: left, right: right});

        left = right;
        right += width;

        temp.push({top: top, bottom: bottom, left: left, right: right});

        left = right;
        right += width;

        temp.push({top: top, bottom: bottom, left: left, right: right});

        top = bottom;
        bottom += height;
        left = 0;
        right = width;

        temp.push({top: top, bottom: bottom, left: left, right: right});

        left = right;
        right += width;

        temp.push({top: top, bottom: bottom, left: left, right: right});

        left = right;
        right += width;

        temp.push({top: top, bottom: bottom, left: left, right: right});

        top = bottom;
        bottom += height;
        left = 0;
        right = width;

        temp.push({top: top, bottom: bottom, left: left, right: right});

        left = right;
        right += width;

        temp.push({top: top, bottom: bottom, left: left, right: right});

        left = right;
        right += width;

        temp.push({top: top, bottom: bottom, left: left, right: right});

        setScreenCoords(temp);
    }, []);

    useEffect(() => {
        setIds(locationIds);
        // setSelectedLocations([]);
    }, [locationIds]);

    useEffect(() => {
        const fetchTemp = async () => {
            if (ids && ids.length > 0) {
                const locations = [];
                for (let i = 0; i < ids.length; i++) {
                    const loc = ids[i];

                    locations.push(await fetchChannels(projectId, loc));
                }

                setSelectedLocations(locations);
            }
        };
        fetchTemp();
    }, [ids]);

    const checkPosition = (e: any) => {
        const element = dialogRef.current.getElement();

        const x = element.offsetLeft + (element.offsetWidth / 2);
        const y = element.offsetTop + (element.offsetHeight / 2);

        for (let i = 0; i < screenCoords.length; i++) {
            const coords = screenCoords[i];

            if (x >= coords.left && x <= coords.right && y >= coords.top && y <= coords.bottom) {

                switch (i) {
                    case 0:
                        setPosition('top-left');
                        break;
                    case 1:
                        setPosition('top');
                        break;
                    case 2:
                        setPosition('top-right');
                        break;
                    case 3:
                        setPosition('left');
                        break;
                    case 4:
                        setPosition('center');
                        break;
                    case 5:
                        setPosition('right');
                        break;
                    case 6:
                        setPosition('bottom-left');
                        break;
                    case 7:
                        setPosition('bottom');
                        break;
                    case 8:
                        setPosition('bottom-right');
                        break;
                }
                break;
            }
        }
    };

    return (
        <div className={'chart-controls'}>
            <Dialog
                className={'chart-control-dialog'}
                ref={dialogRef}
                header={selectedLocations.length > 0 ? selectedLocations[0].name : ''}
                visible={ids && ids.length > 0} modal={false} style={{width: '500px', height: '400px'}}
                appendTo={'self'}
                position={position}
                resizable={false}
                onDragEnd={checkPosition}
                onHide={() => setIds([])}>
                {selectedLocations.length <= 0 && <ProgressSpinner className={'m-auto w-full'}/>}
                {selectedLocations.length > 0 && <div className="overflow-hidden" style={{'minHeight': '50%'}}>
                    <div className="overflow-auto h-full w-full"
                        style={{'minHeight': '50%'}}>
                        <div
                            style={{'textAlign': 'center'}}>{moment(minusThreeDays).format(t('dateformat')) + ' - ' + moment(now).format(t('dateformat'))}</div>
                        {selectedLocations.map((location) => {
                            // console.log(selectedLocations);
                            return location.channels.map((channel: any) => {
                                return (<div className={'m-2'}>
                                    <PlotChart projectId={projectId.toString()}
                                        channelIds={[channel.channel_id]}
                                        title={''}
                                        selectedGraphType={'lines'}
                                        showTimeRangePicker={false}
                                        minMaxFromChannel={false}
                                        index={0}
                                        fromDate={minusThreeDays}
                                        toDate={now} combineValues={true}
                                        chartHeight={210} hideLegend={true}
                                        link={'/project/' + projectId + '/#measurementdata_' + channel.sensor_id}/>
                                </div>);
                            });
                        })}
                    </div>
                </div>}
            </Dialog>
        </div>
    );
}
;

export default ChartControl;
