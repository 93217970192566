/*
 * ActionDialog.tsx
 * Author: lnappenfeld
 * Date: 28.04.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */


import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';

// import { Editor } from '@tinymce/tinymce-react';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Input from '../global/Input';
import ActionTypeSelectList from '../userManagement/ActionTypeSelectList';
import NotificationTypeSelectList from './NotificationTypeSelectList';

import {
    createNotificationTemplate,
    getNotificationTemplateMetadata,
    updateNotificationTemplate
} from '../functions/Notification';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {Editor} from '@tinymce/tinymce-react';

type Template = {
    'template_id': string,
    'name': string,
    'subject': string,
    'content': string,
    'action_type_id': number,
    'notification_type_id': number
}

type Props = {
    templateData: Template,
    projectId: string,
    onFinished: (success: boolean) => void
    setVisible: any
}

const NotificationTemplateEditForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);
    const editorRef = useRef<any>(null);
    const templateId = (typeof props.templateData.template_id !== 'undefined') ? props.templateData.template_id : null;
    const [metadata, setMetadata] = useState<any>([]);
    const [editorPlaceholders, setEditorPlaceholders] = useState<any>([]);

    const defaultValues = {
        'name': templateId === null ? '' : props.templateData.name,
        'subject': templateId === null ? '' : props.templateData.subject,
        'content': templateId === null ? '' : props.templateData.content,
        'action_type_id': templateId === null ? '0' : props.templateData.action_type_id.toString(),
        'notification_type_id': templateId === null ? '0' : props.templateData.notification_type_id.toString(),
    };
    const {control, formState: {errors}, handleSubmit, getValues, setValue} = useForm({defaultValues});
    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };


    useEffect(() => {
        getNotificationTemplateMetadata(props.templateData.notification_type_id).then(result => {
            const tmp = [];
            const placeholders = [];
            for (const key in result) {
                tmp.push(<p> {key+' '}{ result[key].forLoop && <i className={'pi pi-replay'}></i>}</p>);

                const menuItem = {
                    type: 'menuitem',
                    text: key,
                    content: '{{' + key + '}}' ,
                    icon: '',
                    submenuItems: [] as any[]
                };
                if (result[key].forLoop) {
                    menuItem.type = 'nestedmenuitem';
                    menuItem.icon = 'reload';
                    const subMenuItemLoopHead = {
                        type: 'menuitem',
                        text: 'Schleifenkopf',
                        content: '{{#each ' + key + '}}',
                        icon: 'paste-row-before',
                    };
                    menuItem.submenuItems.push(subMenuItemLoopHead);
                    for (const data of result[key].metadata) {
                        tmp.push(<p> {' - '+data}</p>);

                        const subMenuItem = {
                            type: 'menuitem',
                            text: data,
                            content: '{{' + data + '}}',
                        };
                        menuItem.submenuItems.push(subMenuItem);

                    }
                    const subMenuItemLoopFoot = {
                        type: 'menuitem',
                        text: 'Schleifenfuß',
                        content: '{{/each}}',
                        icon: 'paste-row-after',
                    };
                    menuItem.submenuItems.push(subMenuItemLoopFoot);
                }
                placeholders.push(menuItem);
            }

            setMetadata(tmp);
            setEditorPlaceholders(placeholders);
        });

        // Ersetze SMS-Zeilenumbruch (%0A) durch HTML-Zeilenumbruch (<br/>) damit es im Editor richtig angezeigt wird
        // Beim Speichern wird es wieder zurück konvertiert
        if (parseInt(getValues('action_type_id')) === 0) { // SMS
            const templateStr = getValues('content');
            const newTemplateStr = templateStr.replaceAll('%0A', '<br/>');
            setValue('content', newTemplateStr);
        }

        // TinyMCE kann keinen Platzhalter innerhalb einer Tabelle zwischen den Tags <tbody> und <tr> bzw. zwischen
        // </tr> und </tbody> anzeigen. Die Platzhalter {{#each ...}} und {{/each}} müssen aber an den beiden zuvor
        // genannten Stellen stehen, damit der Dienst zum Füllen des Templates mit handlebars.js funktioniert.
        // Aus diesem Grund wird der öffnende Loop-Platzhalter {{#each ...}} vor dem Tag <table> und der schließende
        // Loop-Platzhalter {{/each}} hinter dem Tag </table> eingefügt.
        // Anschließend beim Speichern des Templates in die Datenbank werden die Platzhalter wieder an die richtigen
        // Stellen innerhalb der Tabelle platziert.
        if (parseInt(getValues('action_type_id')) === 1) { // EMail
            const templateStr: string | any = getValues('content');
            let newTemplateStr = templateStr;

            // extract all tableStrings from template (<table ... </table>) or an empty array if no table exists
            const tableStrings: string[] = templateStr.match(/\<table([\s\S]+?)\<\/table\>/g) || [];
            for (const tableStr of tableStrings) {

                const startIndexOfLoopOpener: number = tableStr.indexOf('{{#each');
                const partOfTableStr: string = tableStr.substring(startIndexOfLoopOpener);
                // loopOpenerStr: {{#each ...}}
                const loopOpenerStr: string = partOfTableStr.substring(0, partOfTableStr.indexOf('}}') + 2);
                // loopCloserStr: {{/each}}
                const loopCloserStr: string = '{{/each}}';

                let newTableStr: string = tableStr;
                // replace <tbody>{{#each ...}} with <tbody>
                newTableStr = newTableStr.replace('<tbody>' + loopOpenerStr, '<tbody>');
                // replace {{/each}}</tbody> with </tbody>
                newTableStr = newTableStr.replace(loopCloserStr + '</tbody>',  '</tbody>');
                // add loopOpenerStr above of tableStr and loopCloserStr below of tableStr
                newTableStr = loopOpenerStr + newTableStr + loopCloserStr;
                // replace old tableStr with newTableStr
                newTemplateStr = newTemplateStr.replace(tableStr, newTableStr);

            }

            // console.log(newTemplateStr);
            setValue('content', newTemplateStr);
        }

    }, []);

    const getLoopOpenerStr = (templateStr: string, tableStr: string) => {
        const startIndexOfTableStr: number = templateStr.indexOf(tableStr);
        const templateOpenerSubstr: string = templateStr.substring(0, startIndexOfTableStr);
        return templateStr
            .substring(templateOpenerSubstr.lastIndexOf('{{#'), startIndexOfTableStr)
            .replace(/[\r\n]/gm, '')
            .replace('<br />', '')
            .replace('<br/>', '');
    };

    const saveData = (data: any) => {
        // Ersetze HTML-Zeilenumbruch (<br/>) durch SMS-Zeilenumbruch (%0A) damit es wieder richtig in die DB kommt
        let templateStr1: string = editorRef.current.getContent();
        let templateStr2: string = editorRef.current.getContent();
        let newTemplateStr: string = editorRef.current.getContent();
        if (parseInt(data.action_type_id) === 0) { // SMS
            newTemplateStr = newTemplateStr.replaceAll('<br/>', '%0A');
            newTemplateStr = newTemplateStr.replaceAll('<br />', '%0A');
            newTemplateStr = newTemplateStr.replaceAll('<br>', '%0A');
            setValue('content', newTemplateStr);
        }

        // Konvertiere Tableloops für TinyMCE nach Tableloops für handlebars.js in DB
        if (parseInt(data.action_type_id) === 1) {
            const tableStrings: string[] = editorRef.current.getContent().match(/\<table([\s\S]+?)\<\/table\>/g);

            if (tableStrings !== null) {
                // loopCloserStr: {{/each}}
                const loopCloserStr: string = '{{/each}}';
                // remove all loopCloserStrings below of tableStr
                newTemplateStr = newTemplateStr.replaceAll(loopCloserStr, '');

                // Es müssen zwei Schleifen sein, da zweimal der gleiche loopOpenerStr in dem Template existieren kann
                // 1. Loop: Lösche alle loopOpenerStr vor den Tabellen (die loopCloserStrings wurden schon entfernt)
                for (const tableStr of tableStrings) {
                    // loopOpenerStr: {{#each ...}}
                    const loopOpenerStr = getLoopOpenerStr(templateStr1, tableStr);

                    // Entferne den letzten tableStr aus dem initialen Template,
                    // falls genau der gleiche Tabellenstring mehrfach existiert.
                    // Dadurch wird nicht immer der gleiche loopOpenerStr extrahiert
                    templateStr1 = templateStr1.replace(tableStr, '');

                    // remove loopOpenerStr above of tableStr
                    newTemplateStr = newTemplateStr.replace(loopOpenerStr, '');
                }

                // 2. Loop: Ersetze die alte Tabelle durch die neue Tabelle, die die Loop-Platzhalter enthält
                for (const tableStr of tableStrings) {
                    // loopOpenerStr: {{#each ...}}
                    const loopOpenerStr = getLoopOpenerStr(templateStr2, tableStr);

                    // Entferne den letzten tableStr aus dem initialen Template,
                    // falls genau der gleiche Tabellenstring mehrfach existiert.
                    // Dadurch wird nicht immer der gleiche loopOpenerStr extrahiert
                    templateStr2 = templateStr2.replace(tableStr, '');

                    let newTableStr: string = tableStr;
                    // replace <tbody> with <tbody>{{#each ...}}
                    newTableStr = newTableStr.replace('<tbody>', '<tbody>' + loopOpenerStr);
                    // replace </tbody> with {{/each}}</tbody>
                    newTableStr = newTableStr.replace('</tbody>', loopCloserStr + '</tbody>');
                    // replace old tableStr with newTableStr
                    newTemplateStr = newTemplateStr.replace(tableStr, newTableStr);
                }
            }
        }

        let template = {
            'project_id': props.projectId,
            'name': data.name,
            'subject': data.subject,
            'content': newTemplateStr,
            'action_type_id': data.action_type_id,
            'notification_type_id': data.notification_type_id
        };
        const templateAddId = {'template_id': templateId};
        if (templateId !== null)
            template = {...templateAddId, ...template};

        if (templateId !== null) {
            updateNotificationTemplate(props.projectId, template).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'),result.error);
                } else {
                    showMessageOnSuccess(t('success'), t('alarmManagement:toasts.templateUpdated'));
                    props.onFinished(true);
                    props.setVisible(false);
                }
            });
        } else {
            createNotificationTemplate(props.projectId, template).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'),result.error);
                } else {
                    showMessageOnSuccess(t('success'), t('alarmManagement:toasts.templateCreated'));
                    props.onFinished(true);
                    props.setVisible(false);
                }
            });
        }
    };

    return (
        <form id='formDialog' onSubmit={handleSubmit(saveData)}>
            <div className='grid'>
                <div className='col-12 sm:col-6'>
                    <Input
                        edit={true} label={t('name')} name={'name'}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('name') + ' ' + t('input:required')}}
                    />
                    <NotificationTypeSelectList
                        projectId={props.projectId}
                        label={t('alarmManagement:attributes.alarmType')}
                        onChange={e => setValue('notification_type_id', e.value)}
                        validationControl={control}
                        validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('alarmManagement:attributes.alarmType') + ' ' + t('input:required')}}
                    />
                </div>
                <div className='col-12 sm:col-6'>
                    <Input
                        edit={true} label={t('subject')} name={'subject'}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('subject') + ' ' + t('input:required')}}
                    />
                    <ActionTypeSelectList
                        label={t('userManagement:attributes.actionType')}
                        onChange={e => setValue('action_type_id', e.value)}
                        validationControl={control}
                        validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('userManagement:attributes.actionType') + ' ' + t('input:required')}}
                    />
                </div>
            </div>

            <p style={{ color: 'red' }}>
                Die Kopfzeile einer Tabelle muss als Header deklariert werden.<br/>
                Dazu die Zeile markieren und dann im Menu <strong>Table {'>'} Row {'>'} Row Properties {'> '}
                General {'>'} Row type</strong> auf Header setzen.
            </p>
            {editorPlaceholders.length > 0 &&
            <div style={{zIndex: 999}}>
                <Editor
                    onInit={(evt, editor) => editorRef.current = editor}
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    initialValue={getValues('content')}
                    init={{
                        // images_upload_handler: function (blobInfo, success, failure) {
                        //     success('data:' + blobInfo.blob().type + ';base64,' + blobInfo.base64());
                        // },
                        // forced_root_block : false,
                        height: 500,
                        menubar: 'file edit view insert format tools table help',
                        plugins: [
                            'print preview paste importcss searchreplace autolink autosave save ' +
                            'directionality code visualblocks visualchars fullscreen link ' +
                            'media template codesample table charmap hr pagebreak nonbreaking ' +
                            'anchor toc insertdatetime advlist lists wordcount ' +
                            'textpattern noneditable help charmap quickbars emoticons',
                        ],
                        toolbar:
                            'placeholders undo redo | bold italic underline | ' +
                            'fontselect fontsizeselect formatselect | ' +
                            'alignleft aligncenter alignright alignjustify | ' +
                            'outdent indent |  numlist bullist | ' +
                            'forecolor backcolor removeformat | ' +
                            'emoticons | fullscreen preview | ' +
                            'insertfile link',
                        // templates: editorPlaceholders,
                        setup: function (editor) {
                            // fill custom menu with placeholders
                            const items: any = [];
                            for (const placeholder of editorPlaceholders) {
                                if (placeholder.type === 'nestedmenuitem') {

                                    const submenuItems: { type: any; text: any; icon: any; onAction: () => void; }[] = [];
                                    for (const submenuItem of placeholder.submenuItems) {
                                        const item = {
                                            type: submenuItem.type,
                                            text: submenuItem.text,
                                            icon: submenuItem.icon,
                                            onAction: function () {
                                                editor.insertContent(submenuItem.content);
                                            }
                                        };
                                        submenuItems.push(item);
                                    }

                                    const item = {
                                        type: placeholder.type,
                                        text: placeholder.text,
                                        icon: placeholder.icon,
                                        getSubmenuItems: function () {
                                            return submenuItems;
                                        }
                                    };

                                    items.push(item);
                                } else {
                                    const item = {
                                        type: placeholder.type,
                                        text: placeholder.text,
                                        onAction: function () {
                                            editor.insertContent(placeholder.content);
                                        }
                                    };
                                    items.push(item);
                                }

                            }

                            /* example, adding a toolbar menu button */
                            editor.ui.registry.addMenuButton('placeholders', {
                                text: 'Placeholders',
                                fetch: function (callback) {
                                    callback(items);
                                }
                            });

                        },
                        'content_style': 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                />
            </div>}
        </form>
    );

};

export default NotificationTemplateEditForm;
