/*
 * ActionsOverview.tsx
 * Author: lnappenfeld
 * Date: 27.04.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState, useEffect, useRef, useContext} from 'react';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {useTranslation} from 'react-i18next';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {
    Action,
    deleteActions,
    getActions,
    getActionTypes,
} from '../functions/Notification';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {checkPermission} from '../../functions/functionLibrary';
import {permissions} from '../../config/permissions';

export enum ActionOverviewType {
    Default = 0,
    NotificationWizard = 1,
    NotificationAddRemoveActions = 2
}

type Props = {
    projectId: string
    type: ActionOverviewType,
    actions?: Action[],
    reload?: boolean,
    setReload?: (reload: boolean) => void
    selectedActions: Action[],
    setSelectedActions: (e: Action[]) => void,
    showActionDialog?: boolean,
    setShowActionDialog?: (e: boolean) => void,
    editNotificationTemplate?: (notification: any) => void,
    setReloadUsers?: (e: boolean) => void
}

export const ActionsOverview = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);
    const [actions, setActions] = useState<any>(props.actions ? props.actions : []);
    const [actionTypes, setActionTypes] = useState<any[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(props.actions ? true : false);

    const recipientTypeFilterType = [
        {value: 0, name: t('userManagement:global.user')},
        {value: 1, name: t('noUser')}
    ];

    const _setActions = () => {
        setDataLoaded(false);
        getActions(props.projectId, t).then(result => {
            setActions(result);
            setDataLoaded(true);
        });
    };

    const _setActionTypes = () => {
        getActionTypes().then(result => {
            setActionTypes(result);
        });
    };

    useEffect(() => {
        if (!props.actions)
            _setActions();
        _setActionTypes();
    }, []);

    useEffect(() => {
        if (props.reload) {
            _setActions();
            _setActionTypes();
            if (props.setReload) props.setReload(false);
        }
    }, [props.reload]);

    const _deleteAction = (data: any) => {
        CustomConfirmDialog({
            message: t('userManagement:dialogs.deleteActionDialog.message'),
            header: t('userManagement:dialogs.deleteActionDialog.header'),
            translation: t,
            onConfirm: () => {
                deleteActions(props.projectId, [data.action_id]).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                    } else {
                        _setActions();
                        showMessageOnSuccess(t('success'), t('userManagement:toasts.actionDeleted'));
                    }
                });
            }
        });
    };

    const actionColumns = [
        {field: 'action_name', header: t('name'), style: {minWidth: '300px'}, filter: true, filterField: 'action_name'},
        {
            field: 'action_type_name',
            filterField: 'action_type_id',
            header: t('userManagement:attributes.actionType'),
            filter: true,
            filterDropdownValues: actionTypes,
            style: {minWidth: '200px', maxWidth: '225px'}
        },
        {
            field: 'recipient_address_source',
            header: t('userManagement:attributes.recipientType'),
            filter: true,
            filterDropdownValues: recipientTypeFilterType,
            body: (rowData: any) => {
                return rowData['recipient_address_source'] === 0
                    ? t('userManagement:global.user')
                    : t('noUser');
            },
            style: {minWidth: '200px', maxWidth: '225px'}
        },
        {
            field: 'recipient_address',
            header: t('userManagement:attributes.recipientAddress'),
            style: {minWidth: '250px'}
        }
    ] as any;

    const filters = {
        'action_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'action_type_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'recipient_address_source': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
    };

    const getSelectionMode = () => {
        switch (props.type) {
            case ActionOverviewType.Default:
                return checkPermission(permissions.editUM) ? {selectionMode: 'multiple'} : {};
            case ActionOverviewType.NotificationWizard:
            case ActionOverviewType.NotificationAddRemoveActions:
                return checkPermission(permissions.editNotification) ? {selectionMode: 'multiple'} : {};
            default:
                return {};
        }
    };

    const dynamicSelectionMode = getSelectionMode();

    return (
        <>
            <CustomDataTable
                {...dynamicSelectionMode}
                id='actions-actions'
                columns={actionColumns}
                editable={props.type === ActionOverviewType.Default && checkPermission(permissions.editUM)}
                sortable={true}
                value={actions}
                selection={props.selectedActions}
                onSelectionChange={(e: any) => {
                    if (props.setSelectedActions)
                        props.setSelectedActions(e.value);
                }}
                onRemoveClick={_deleteAction}
                onEditClick={(data) => {
                    props.setSelectedActions([data]);
                    if (props.setShowActionDialog) props.setShowActionDialog(true);
                }}
                filters={filters}
                ableToDelete={checkPermission(permissions.editUM)}
                ableToUpdate={props.type === ActionOverviewType.Default && checkPermission(permissions.editUM)}
                dataloaded={dataLoaded}
                showClearFiltersButton={true}
            />
        </>
    );
};
