import React, {FunctionComponent, useCallback, useContext, useEffect, useRef, useState} from 'react';
import MapContext from '../map/MapContext';
import {Trans, useTranslation} from 'react-i18next';
import {InputText} from 'primereact/inputtext';
import {OverlayPanel} from 'primereact/overlaypanel';
import {transformCoords} from '../../../services/SpatialService';
import settings from '../../../config/settings';
import keycloakfetch from '../../../functions/keycloakfetch';
import {Feature, Overlay} from 'ol';
import {Coordinate, toStringHDMS} from 'ol/coordinate.js';
import {classNames} from 'primereact/utils';
import {Fill, Icon, Stroke, Style} from 'ol/style';
import {Point} from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import {WKT} from 'ol/format';
import {useAppSelector, useAppDispatch} from '../../../redux/hooks';
import {setShowPopUp} from "../../../redux/GeomonitoringSlice";

type geocodingProps = {
    projectId: any
}

const GeocodingControl: FunctionComponent<geocodingProps> = (props) => {
    // @ts-ignore
    const {map} = useContext(MapContext);
    const [visible, setVisible] = useState(false);
    const {t} = useTranslation(['geomonitoring']);
    // const [value, setValue] = useState('51.13035, 6.446869');
    const [value, setValue] = useState('');
    const op = useRef(null);
    const [popupClassName, setPopupClassName] = useState('top-right');
    const content = document.getElementById('geopopup-content');
    const closer = document.getElementById('geopopup-closer');
    const [geocodeContent, setGeocodeContent] = useState('');
    const [toogleAllowed, setToogleAllowed] = useState(false);
    const valueRef = useRef(value);
    const toast = useRef(null);
    const [popup, setPopup] = useState({
        setPosition(coordinate: any) {
        }
    });
    const popupRef = useRef(popup);
    const container = document.getElementById('popupMarker');
    const [popupAdress, setPopupAdress]=useState('');
    const [popupCoords, setPopupCoords]=useState('');
    const [interactionsDisabled, setInteractionsDisabled]=useState(false);
    const [vectorLayer, setVectorLayer] = useState({});
    const [vectorSource, setVectorSource] = useState({
        addFeature(iconFeature: Feature<Point>) {
        },
        clear() {

        }
    });
    const vectorRef = useRef(vectorLayer);
    const vectorSourceRef = useRef(vectorSource);
    const adressRef = useRef(popupAdress);
    const coordsRef = useRef(popupCoords);
    const toogleRef = useRef(toogleAllowed);
    const [checked, setChecked] = useState(false);
    const refChecked=useRef(checked);
    const dispatch = useAppDispatch();

    // http://localhost:1841/nuguard/project/05209cbd-7d53-4e08-a2d6-e70bb33b3393/8daa9aee-34a2-46e2-8043-c06d37de0416/#geomonitoring
    const createBaseMapButton = () => {
        const buttonBookmarkMap = document.createElement('button');
        buttonBookmarkMap.id = 'buttonGeocoding';
        buttonBookmarkMap.title = 'Geocoding';
        buttonBookmarkMap.innerHTML = '<span style="color: black"><i class="fas fa-search"/></span>';
        buttonBookmarkMap.addEventListener('click', showSearch, false);
        return buttonBookmarkMap;
    };

    const showSearch = (e: any) => {
        // @ts-ignore
        op.current.toggle(e);
        toogleFnc(true);
    };

    const toogleFnc=(val:boolean)=>{
        setToogleAllowed(val);
        toogleRef.current=val;
    };

    const addControl = () => {

        const geocodingElem = document.getElementById('buttonGeocoding');
        if (geocodingElem === null) {
            const buttonBookmarkMap = createBaseMapButton();
            const elementLegende = document.getElementById('olToolkitControls');
            elementLegende?.appendChild(buttonBookmarkMap);
        }
        return [geocodingElem];
    };

    const createGeocodePoint=(newCoords:any)=>{
        //  placePopup(newCoords);
        const geoFeature = new Feature({
            geometry: new Point(newCoords),
            name:'geocodingMarker'
        });

        const iconStyle = new Style({
            image: new Icon({
                anchor: [0.5, 45],
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                src: '/assets/layout/images/markerIcon.png',
            }),
        });
        geoFeature.setStyle(iconStyle);
        vectorSourceRef.current.addFeature(geoFeature);
    };

    const createGeocodePolygon=(shape:any)=>{
        const format = new WKT();
        const geoFeature=format.readFeature(shape, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
        });

        const iconStyle = new Style({
            stroke: new Stroke({
                color: 'blue',
                width: 3,
            }),
            fill: new Fill({
                color: 'rgba(0, 0, 255, 0.2)',
            }),
        });
        geoFeature.setStyle(iconStyle);
        // @ts-ignore
        vectorSource.addFeature(geoFeature);
        // @ts-ignore
        vectorSourceRef.current.addFeature(geoFeature);
    };

    const setGeocodemarker = (newCoords: any,shape:any,  polygon:boolean, zoom:number, changeZoom:boolean) => {

        if (!polygon) {
            createGeocodePoint(newCoords);
        } else {
            createGeocodePolygon(shape);
        }
        if (changeZoom) {
            map.getView().setCenter(newCoords);
            map.getView().setZoom(zoom);
        }

    };

    const getGeocoodedByAdress = async (address: string) => {
        const url = settings.apiUrl + '/getHERECoordinates?address=' + address + '&project_id=' + props.projectId;
        let retValue: any = [];
        await keycloakfetch.get(url).then((result) => {
            if (result)
                retValue = result;
        });

        //   console.log(retValue);
        return retValue;
    };

    const easterEgg=(address:string)=>{
        const schalkeCoords=[51.55458092545464, 7.067659646474887];
        if (address.includes('Schalke 04')) {
            const newCoords = transformCoords([schalkeCoords[1], schalkeCoords[0]], 4326, 3857);
            // console.log(data.MatchLevel);
            displayPointPosition(newCoords,{lat:schalkeCoords[0].toString(), lng:schalkeCoords[1].toString()}, address +'! Blau und Weiß ein Leben lang.', true);
            return true;
        }
        return false;
    };

    const getAdressByCoords= async (sanitizedCoords: string[]) => {

        const url = settings.apiUrl + '/getHEREAddress?lng=' + sanitizedCoords[0] + '&lat=' + sanitizedCoords[1] + '&project_id=' + props.projectId;
        // console.log(url)
        let retValue: any = [];
        await keycloakfetch.get(url).then((result) => {
            if (result)
                retValue = result;
        });
        adressRef.current=sanitizedCoords[0]+', '+sanitizedCoords[1];
        coordsRef.current=retValue;
        return retValue;
    };

    const displayPointPosition=(newCoords: any, coordsPoint: { lat: any; lng: any; }, address:string, changeZoom:boolean)=>{
        setGeocodemarker(newCoords, null,false,17, changeZoom);
        //  console.log(address, coordsPoint)
        adressRef.current=address;
        coordsRef.current=(coordsPoint.lat).toFixed(6)+', '+(coordsPoint.lng).toFixed(6);
    };

    const displayPolygon=(location: { Shape: { Value: any; }; }, newCoords: any, address: string, zoomLevel: number)=>{
        const coordsPolygon=location.Shape.Value;
        setGeocodemarker(newCoords,coordsPolygon, true, zoomLevel,true);
        adressRef.current=address;
        coordsRef.current='POLYGON';
    };

    const clearSource=()=>{
        vectorSourceRef.current.clear();
        vectorSource.clear();
    };

    const addressFunction=(coords: any, address: string)=>{
        clearSource();
        if (coords&&coords.hasOwnProperty('lat')) {
            const newCoords = transformCoords([coords.lng, coords.lat], 4326, 3857);
            displayPointPosition(newCoords,{lat:coords.lat, lng:coords.lng}, address, true);
        } else if (Object.keys(coords).length==2&&typeof address==='string') {
            //console.log('placed popup');
            const newCoords = transformCoords([coords.lng, coords.lat], 4326, 3857);
            displayPointPosition(newCoords,{lng:coords.lng, lat:coords.lat}, address, false);
            // @ts-ignore
            const popupContent=<div><b>{adressRef.current}</b><hr width="80%" />{coordsRef.current}</div>;
            // @ts-ignore
            setGeocodeContent(popupContent);
            placePopup(newCoords);
        } else {
            // @ts-ignore
            toast.current.show({severity:'info', summary: 'Info', detail:t('adressNotFound'), life: 3000});
        }
        return true;
    };

    const coordsFunction=async(coords:any)=>{
        const coordsArray=(typeof coords === 'object' && coords.length === 2);
        const sanitizedCoords=coordsArray?coords:coords.split(',').map((coord:string)=>parseFloat(coord.trim()));
        const address= await getAdressByCoords(sanitizedCoords);
        if (address ) {
            return addressFunction({lat:sanitizedCoords[0], lng:sanitizedCoords[1]}, address);
            // return true;
        } else {
            // @ts-ignore
            toast.current.show({severity:'info', summary: 'Info', detail:t('adressNotFound'), life: 3000});
        }
        return false;
    };

    const handleEnterFunction = async (inputString: string) => {
        clearSource();

        const regex=/(^[-+]?(?:[1-8]?\d(?:\.\d+)?|90(?:\.0+)?)),\s*([-+]?(?:180(?:\.0+)?|(?:(?:1[0-7]\d)|(?:[1-9]?\d))(?:\.\d+)?))$/;
        if (regex.test(inputString)===true) {
            return coordsFunction(inputString);
        } else if (easterEgg(inputString)) {
            return true;
        }
        else {
            const coords = await getGeocoodedByAdress(inputString);
            return addressFunction(coords, inputString);
        }

    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleEnterFunction(value).then(r => console.log('geocoding successful'));
            // handleMouseLeave(null);
        }
        handleMouseEnter(null);
    };

    const handleMouseEnter= (event: any) => {
        if (!interactionsDisabled) {
            map.getInteractions().forEach((e: { setActive: (arg0: boolean) => void; }) => {
                e.setActive(false);
            });
            setInteractionsDisabled(true);
        }
    };

    const handleMouseLeave = (event: any) => {
        if (interactionsDisabled) {
            map.getInteractions().forEach((e: { setActive: (arg0: boolean) => void; }) => {
                e.setActive(true);
            });
            setInteractionsDisabled(false);
        }
    };

    const removePopup = () => {
        popupRef.current.setPosition(undefined);
        closer?.blur();
    };

    const placePopup = (coordinate: any) => {
        popupRef.current.setPosition(coordinate);
        map.addOverlay(popupRef.current);
    };

    const contextMenuListener=()=>{
        map.getViewport().addEventListener('contextmenu', function (evt: { preventDefault: () => void; }) {
            evt.preventDefault();
            clearSource();
            removePopup();

            if (toogleRef.current) {
                // @ts-ignore
                op.current.toggle({currentTarget:null});
                toogleFnc(false);
            }


        });
    };

    const pointerMovelistener=()=>{
        map.on('pointermove', function (e: { originalEvent: any; }) {
            const pixel = map.getEventPixel(e.originalEvent);
            const hit = map.hasFeatureAtPixel(pixel);
            map.getTarget().style.cursor = hit ? 'pointer' : '';
        });
    };

    const forEachFeatureAtPixel = (pixel: any) => {

        return new Promise((resolve) => {
            const fArray: any = [];
            let marker:boolean=false;
            map.forEachFeatureAtPixel(pixel, function (f: any, parentLayer: any) {
                if (f.getId()&&f.get('name')!=='geocodingMarker') {
                    fArray.push(f.getId());
                } else if (f.get('name')=='geocodingMarker') {
                    marker=true;
                }

            });
            resolve({aLength:fArray.length>0, gMarker:marker});
        });
    };

    const forEachLayerAtPixel = (e: any) => {
        let counter = 0;
        const viewResolution = map.getView().getResolution();
        const layerArray = map.getLayers();
        return new Promise(resolve => {
            layerArray.forEach(function (layer: any) {
                if (layer.get('visible') && !layer.get('baseLayer') && layer.get('type') === 'raster') {
                    const url = layer.getSource().getFeatureInfoUrl(
                        e.coordinate,
                        viewResolution,
                        'EPSG:3857',
                        {'INFO_FORMAT': 'application/json'}
                    );

                    if (url) {

                        fetch(url)
                            .then((response) => response.text())
                            .then((json) => {
                                counter++;
                                if ((JSON.parse(json).features).length>0) {

                                    resolve(true);
                                } else {
                                    if (counter===layerArray.getArray().length) {
                                        resolve(false);
                                    }
                                }

                            });
                    } else {
                        counter++;
                    }
                } else {
                    counter++;
                }
                if (counter===layerArray.length) {
                    resolve(false);
                }

            }
            );

        });
    };

    const clickListener=()=>{
        map.on('click', async function (evt: { pixel: any; coordinate: Coordinate | undefined; }) {
            const pixelFeaturesDatatables: any = await forEachFeatureAtPixel(evt.pixel);
            const rasterFeaturesDatatables: any = await forEachLayerAtPixel(evt);
           if (refChecked.current)
            {
                const coords=evt.coordinate?evt.coordinate:[0,0];
                const newCoords = transformCoords(coords, 3857, 4326);
                // @ts-ignore
                coordsFunction([newCoords[1],newCoords[0]]);

                return;
            }

            if (pixelFeaturesDatatables.gMarker) {
                placePopup(evt.coordinate);
                // @ts-ignore
                //  const convertedCoords=transformCoords([evt.coordinate[0], evt.coordinate[1]], 3857, 4326);
                // @ts-ignore
                const popupContent=<div><b>{adressRef.current}</b><hr width="80%" />{coordsRef.current}</div>;
                // @ts-ignore
                setGeocodeContent(popupContent);
            }


        });
    };

    const createPopup=()=>{
        const popup = new Overlay({
            element: container ? container : undefined
        });

        setPopup(popup);
        popupRef.current = popup;

        // @ts-ignore
        if (closer) {
            closer.onclick = function () {
                removePopup();
                return false;
            };
        }
    };

    const createLayer=()=>{
        const vectorSource = new VectorSource();

        const vectorLayer = new VectorLayer({
            source: vectorSource,
            zIndex: 200000000
        });
        map.addLayer(vectorLayer);
        vectorRef.current = vectorLayer;
        vectorSourceRef.current = vectorSource;
    };

    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        createLayer();
        createPopup();
        contextMenuListener();
        pointerMovelistener();
        clickListener();

        const geocodingControl = addControl();

        return () => map.controls.remove(geocodingControl);
    }, [map]);

    useEffect(() => {
        valueRef.current=value;
    }, [value]);

    useEffect(()=>{
        refChecked.current=checked;
        dispatch(setShowPopUp(!checked));
    },[checked]);

    const mapELem=document.getElementsByClassName('ol-viewport');

    return (
        // @ts-ignore
        <div><OverlayPanel ref={op} className={'layer-geocoding p-2'} dismissable={false} appendTo={mapELem[0]}>
            <div>
                <div className="flex flex-wrap justify-content-center gap-3">
                <InputText value={value} onChange={(e) => setValue(e.target.value)} onKeyDown={handleKeyDown} onFocus={handleMouseEnter} onMouseLeave={handleMouseLeave}placeholder={t('searchAdress')}/>
               <div className="flex align-items-center"><Checkbox inputId={'checkReverse'} onChange={e => setChecked(e.checked)} checked={checked}></Checkbox>
                   <label htmlFor="checkReverse" className="ml-2">{t('reverseGeocoding')}</label></div>
            </div>
            </div>
        </OverlayPanel>
        <Toast ref={toast} />
        <div className="markerOverlay">
            <div id="popupMarker" className={classNames('ol-popup', 'map-info', popupClassName)}>
                <div className="map-info-content">
                    <a href="#" id="geopopup-closer" className="ol-popup-closer"></a>
                    <div id="geopopup-content">
                        {geocodeContent}
                    </div>
                </div>
            </div>
        </div>
        </div>);
};

export default GeocodingControl;
