import React, {FC, PropsWithChildren, useEffect, useRef, useState} from 'react';
import {Map, View} from 'ol';
import {loadEpsgScripts, transformCoords} from '../../../services/SpatialService';
import MapContext from './MapContext';
import Config from '../../../views/global/Config';
import LayerTree from '../panel/LayerTree';
import {useParams} from 'react-router-dom';
import Popup from '../layer/Popup';
import ToolkitControl from '../controls/ToolkitControl';
import {Controls, ScaleLineControl} from '../controls';
import ChartControl from '../controls/ChartControl';

type Props = {
    // Map:Record<string, unknown>
    zoom: number
    center: any,
    propElem: any
}
const MapOl: FC<PropsWithChildren<Props>> = ({children, zoom, center, propElem}) => {
    const [map, setMap] = useState<Map | undefined>(Object);
    const [layerTreeCollapsed, setLayerTreeCollapsed] = useState(false);
    const [measuringPointIds, setMeasuringPointIds] = useState<string[]>([]);
    let mapElement = useRef<HTMLDivElement>(null);
    const refMap = useRef(map);
    refMap.current = map;

    const tooltipDiv =
        <div id="tooltip" className="ol-popup" style={{
            minHeight: '20px',
            maxHeight: '60px',
            display: 'none',
            backgroundColor: '#ffffff',
            color: '#000000'
        }}>
            <div id="tooltip-content" className="popup-content"></div>
            <div id="marker" title="Marker"></div>
        </div>;

    const getMap = () => {
        if (!mapElement.current) return;
        if (propElem.project && propElem.project.spatialBookmark) {
            const bookmark = propElem.project.spatialBookmark;
            center = [bookmark.position_x, bookmark.position_y]; //Umformatieren
            zoom = bookmark.zoom;
        }
        const initialMap = new Map({
            target: mapElement.current,
            pixelRatio: 1,
            layers: [],
            view: new View({
                projection: 'EPSG:3857',
                center: center,
                zoom: zoom
            }),
        });
        setMap(initialMap);
    };

    useEffect(() => {
        if (map && map.on) {
            // console.log(map);
            map.on('click', async (evt: any) => {
                setMeasuringPointIds([]);
                let mp: string[] = [];
                map.forEachFeatureAtPixel(evt.pixel, function (f: any, parentLayer: any) {
                    if (f.get('layerKind') === 'poiLayer') {
                        mp.push(f.get('id'));
                    }
                });
                setMeasuringPointIds(mp);
            });
        }
    }, [map]);

    useEffect(() => {
        if (center[0] !== 0 || center[1] !== 0) {
            loadEpsgScripts().then(() => {
                getMap();
            }).catch((error: string) => {
                // eslint-disable-next-line no-console
                console.error('Error with ' + error);
                console.error(error);
            });
        }
        return () => {
            setMap(undefined);
        };
    }, [zoom]);


    return (<><MapContext.Provider value={{map, setMap}}> {tooltipDiv}
        <div style={{height: '100vh', width: '100%'}} ref={mapElement}
             className="map-container"><>{children}
            <Controls key={propElem.project.id + '_controlsMaps'}>
                <ToolkitControl projectId={propElem.project.id} projectBaseLayer={propElem.project.base_layer}/>
                <ScaleLineControl/>
                <ChartControl projectId={propElem.project.id} locationIds={measuringPointIds}/>
            </Controls></>
        </div>

        <Popup projectId={propElem.project.id}/>
        <LayerTree baseLayer={propElem.project.base_layer} projectId={propElem.project.id} onCollapsed={(collapsed) => {
            setLayerTreeCollapsed(collapsed);
        }} locationIds={measuringPointIds}/>
    </MapContext.Provider>
    </>);

};
export default MapOl;
