/*
 * MonitoringTaskLimitsOverview.tsx
 * Author: lnappenfeld
 * Date: 20.07.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import CustomDataTable, {ColumnObject} from '../../global/CustomDataTable';
import {useTranslation} from 'react-i18next';
import {checkPermission, getGroupedItems} from '../../../functions/functionLibrary';
import {permissions} from '../../../config/permissions';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {filterTriStateCheckBox} from '../../global/filter';
import {
    MonitoringTaskLimit,
    getMonitoringTaskLimits
} from '../../functions/Notification';
import {
    buttonCalculateMonitoringTaskLimit, buttonCreateMonitoringTaskLimit,
    buttonDeleteMonitoringTaskLimits, buttonOpenDiagram,
    makeButtonItem
} from '../../global/viewButtonSelection';
import ViewButtons from '../../global/ViewButtons';
import {Nullable} from '../../functions/Global';
import {Checkbox} from 'primereact/checkbox';
import {Dropdown} from 'primereact/dropdown';
import {InputNumber} from 'primereact/inputnumber';
import {InputText} from 'primereact/inputtext';
import {v4 as uuidv4} from 'uuid';

export enum MonitoringTaskLimitsOverviewType {
    MonitoringTaskTable = 1,
    MonitoringTaskForm = 2
}

type Props = {
    type: MonitoringTaskLimitsOverviewType,
    projectId: any,
    showHeader: boolean
    monitoringTaskId: Nullable<string>,
    monitoringTaskLimits?: MonitoringTaskLimit[],
    setMonitoringTaskLimits?: (mtl: MonitoringTaskLimit[]) => void
}
export const MonitoringTaskLimitsOverview = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    const [monitoringTaskLimits, setMonitoringTaskLimits] = useState<MonitoringTaskLimit[]>(
        props.monitoringTaskLimits ? props.monitoringTaskLimits : []
    );
    const [selectedMonitoringTaskLimits, setSelectedMonitoringTaskLimits] = useState<MonitoringTaskLimit[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    // arrays for filter dropdowns
    const types = [
        {value: 1, name: t('alarmManagement:attributes.upperLimit')},
        {value: 2, name: t('alarmManagement:attributes.lowerLimit')}
    ];

    const _setMonitoringTaskLimits = () => {
        if (props.monitoringTaskId)
            getMonitoringTaskLimits(props.projectId, props.monitoringTaskId).then((result: any) => {
                setMonitoringTaskLimits(result);
                setDataLoaded(true);
            });
    };

    useEffect(() => {
        _setMonitoringTaskLimits();
    }, []);

    useEffect(() => {
        if (props.monitoringTaskLimits) {
            setMonitoringTaskLimits(props.monitoringTaskLimits);
            setDataLoaded(true);
        }
    }, [props.monitoringTaskLimits]);


    const addLimit = () => {
        const monitoringTaskLimit: MonitoringTaskLimit = {
            'monitoring_task_limit_id':      uuidv4(),
            'monitoring_task_id':            props.monitoringTaskId ? props.monitoringTaskId : uuidv4(),
            'element_limit_id':              null,
            'amplitude':                     160,
            'amplitude_das':                 160,
            'amplitude_ras':                 160,
            'center_frequency':              0,
            'frequency_range':               10,
            'type':                          1,
            'description':                   null,
            'is_rotational_speed_dependent': true,
            'is_automatic':                  true,
            'is_description_visible':        false,
            'is_threshold_visible':          false
        };
        setMonitoringTaskLimits([...monitoringTaskLimits, monitoringTaskLimit]);
        if (props.setMonitoringTaskLimits) props.setMonitoringTaskLimits([...monitoringTaskLimits, monitoringTaskLimit]);
        setDataLoaded(true);
    };

    const deleteLimits = () => {
        const idsToDelete: string[] = [];
        for (const limit of selectedMonitoringTaskLimits) {
            idsToDelete.push(limit.monitoring_task_limit_id);
        }
        const updatedMTLs = monitoringTaskLimits.filter(obj => !idsToDelete.includes(obj.monitoring_task_limit_id));
        setMonitoringTaskLimits(updatedMTLs);
        if (props.setMonitoringTaskLimits) props.setMonitoringTaskLimits(updatedMTLs);
        setDataLoaded(true);
        setSelectedMonitoringTaskLimits([]);
    };

    const updateColumn = (rowId: string, columnName: string, updatedValue: Nullable<number | boolean | string>) => {
        const updatedMTLs = monitoringTaskLimits.map((obj)  => {
            if (obj.monitoring_task_limit_id === rowId) {
                // @ts-ignore
                obj[columnName] = updatedValue;
            }
            return obj;
        });

        setMonitoringTaskLimits(updatedMTLs);
        if (props.setMonitoringTaskLimits) props.setMonitoringTaskLimits(updatedMTLs);
        setDataLoaded(true);
    };


    const filterCheckboxIsAutomatic = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), t('yes'), t('no'));
    };
    const filterCheckboxIsRotationalSpeedDependent = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), t('yes'), t('no'));
    };

    const columns: ColumnObject[] = [{
        field: 'description', header: t('description'),
        filter: false, filterField: 'description',
        body: props.type === MonitoringTaskLimitsOverviewType.MonitoringTaskForm ? (monitoringTaskLimit: MonitoringTaskLimit) => {
            return (
                <div style={{textAlign: 'center'}}>
                    <InputText
                        value={monitoringTaskLimit.description ? monitoringTaskLimit.description : ''}
                        onChange={(e) => {
                            updateColumn(monitoringTaskLimit.monitoring_task_limit_id, 'description', e.target.value);
                        }}
                    />
                </div>
            );
        } : null
    },{
        field: 'amplitude', header: t('alarmManagement:attributes.amplitude'),
        filter: false, filterField: 'amplitude',
        body: props.type === MonitoringTaskLimitsOverviewType.MonitoringTaskForm ? (monitoringTaskLimit: MonitoringTaskLimit) => {
            return (
                <div style={{textAlign: 'center'}}>
                    <InputNumber
                        value={monitoringTaskLimit.amplitude}
                        onValueChange={(e) => {
                            updateColumn(monitoringTaskLimit.monitoring_task_limit_id, 'amplitude', e.value);
                        }}
                        mode={'decimal'} minFractionDigits={1} maxFractionDigits={3}
                    />
                </div>
            );
        } : null
    },{
        field: 'center_frequency', header: t('alarmManagement:attributes.center_frequency'),
        filter: false, filterField: 'center_frequency',
        body: props.type === MonitoringTaskLimitsOverviewType.MonitoringTaskForm ? (monitoringTaskLimit: MonitoringTaskLimit) => {
            return (
                <div style={{textAlign: 'center'}}>
                    <InputNumber
                        value={monitoringTaskLimit.center_frequency}
                        onValueChange={(e) => {
                            updateColumn(monitoringTaskLimit.monitoring_task_limit_id, 'center_frequency', e.value);
                        }}
                        mode={'decimal'} minFractionDigits={1} maxFractionDigits={3}
                    />
                </div>
            );
        } : null
    },{
        field: 'frequency_range', header: t('alarmManagement:attributes.frequency_range'),
        filter: false, filterField: 'frequency_range',
        body: props.type === MonitoringTaskLimitsOverviewType.MonitoringTaskForm ? (monitoringTaskLimit: MonitoringTaskLimit) => {
            return (
                <div style={{textAlign: 'center'}}>
                    <InputNumber
                        value={monitoringTaskLimit.frequency_range}
                        onValueChange={(e) => {
                            updateColumn(monitoringTaskLimit.monitoring_task_limit_id, 'frequency_range', e.value);
                        }}
                        mode={'decimal'} minFractionDigits={1} maxFractionDigits={3}
                    />
                </div>
            );
        } : null
    },{
        field: 'type', header: t('alarmManagement:attributes.type'),
        filter: true, filterField: 'type', filterDropdownValues: types,
        body: (monitoringTaskLimit: MonitoringTaskLimit) => {
            if (props.type === MonitoringTaskLimitsOverviewType.MonitoringTaskForm ) {
                return (
                    <div style={{textAlign: 'center'}}>
                        <Dropdown
                            value={monitoringTaskLimit.type}
                            options={types} optionLabel={'name'}
                            onChange={(e) => {
                                updateColumn(monitoringTaskLimit.monitoring_task_limit_id, 'type', e.value);
                            }}
                        />
                    </div>
                );
            } else {
                // 1: Oberer Schwellwert 2: Unterer Schwellwert
                switch (monitoringTaskLimit.type) {
                    case 1:
                        return t('alarmManagement:attributes.upperLimit');
                    case 2:
                        return t('alarmManagement:attributes.lowerLimit');
                    default:
                        return t('error');
                }
            }
        }
    },{
        field: 'is_rotational_speed_dependent', header: t('alarmManagement:attributes.is_rotational_speed_dependent'),
        filter: true, filterElement: filterCheckboxIsRotationalSpeedDependent,
        body: props.type === MonitoringTaskLimitsOverviewType.MonitoringTaskForm ? (monitoringTaskLimit: MonitoringTaskLimit) => {
            return (
                <div style={{textAlign: 'center'}}>
                    <Checkbox
                        checked={monitoringTaskLimit.is_rotational_speed_dependent}
                        onChange={(e) => {
                            updateColumn(monitoringTaskLimit.monitoring_task_limit_id, 'is_rotational_speed_dependent', e.checked);
                        }}
                    />
                </div>
            );
        } : null
    },{
        field: 'is_automatic', header: t('alarmManagement:attributes.is_automatic'),
        filter: true, filterElement: filterCheckboxIsAutomatic,
        body: props.type === MonitoringTaskLimitsOverviewType.MonitoringTaskForm ? (monitoringTaskLimit: MonitoringTaskLimit) => {
            return (
                <div style={{textAlign: 'center'}}>
                    <Checkbox
                        checked={monitoringTaskLimit.is_automatic}
                        onChange={(e) => {
                            updateColumn(monitoringTaskLimit.monitoring_task_limit_id, 'is_automatic', e.checked);
                        }}
                    />
                </div>
            );
        } : null
    }];

    const filters = {
        'is_automatic': {
            value: null,
            matchMode: FilterMatchMode.EQUALS
        },
        'is_rotational_speed_dependent': {
            value: null,
            matchMode: FilterMatchMode.EQUALS
        },
        'type': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
    };

    return (
        <>
            <ViewButtons
                title={t('')}
                items={props.type === MonitoringTaskLimitsOverviewType.MonitoringTaskForm ? [
                    makeButtonItem(buttonDeleteMonitoringTaskLimits, t, () => {deleteLimits();},
                        true, selectedMonitoringTaskLimits.length < 1),
                    makeButtonItem(buttonCreateMonitoringTaskLimit, t, () => {addLimit();}),
                    makeButtonItem(buttonOpenDiagram, t, () => {}, true, true),
                    makeButtonItem(buttonCalculateMonitoringTaskLimit, t, () => {}, true, true)
                ] : [
                    makeButtonItem(buttonOpenDiagram, t, () => {}, true, true)
                ]}
            />
            <CustomDataTable
                id='monitoringTasks'
                expandable={false}
                columns={columns}
                editable={false}
                sortable={true}
                value={monitoringTaskLimits}
                paginator={monitoringTaskLimits.length > 10}
                selectionMode={props.type === MonitoringTaskLimitsOverviewType.MonitoringTaskForm ? 'checkbox' : null}
                selection={selectedMonitoringTaskLimits}
                onSelectionChange={(e: any) => {
                    setSelectedMonitoringTaskLimits(e.value);
                }}
                headerTitle={props.showHeader ? t('alarmManagement:global.monitoringTaskLimits') : ''}
                ableToDelete={checkPermission(permissions.editMetrology)}
                ableToUpdate={checkPermission(permissions.editMetrology)}
                filters={filters}
                dataloaded={dataLoaded}
            />
        </>
    );
};
