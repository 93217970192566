/*
 * SensorOverview.tsx
 * Author: lnappenfeld
 * Date: 04.08.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */
import React, {useEffect, useState} from 'react';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {useTranslation} from 'react-i18next';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {
    Channel,
    deleteSensor,
    getMeasuringDevice, getMeasuringManager,
    getSensors,
    getSensorTypes, MeasuringDevice,
    Sensor
} from '../functions/Metrology';
import {ChannelsOverview} from './ChannelsOverview';
import {checkPermission, checkProjectType, getGroupedItems, ProjectType} from '../../functions/functionLibrary';
import {permissions} from '../../config/permissions';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {filterTriStateCheckBox} from '../global/filter';
import {getLocation, Location} from '../functions/Global';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencil} from '@fortawesome/free-solid-svg-icons';

type Props = {
    project: any,
    measuringDeviceId?: string
    reload?: boolean,
    setReload?: (reload: boolean) => void
    reloadChannels?: boolean,
    setReloadChannels?: (reload: boolean) => void
    setSelectedSensors?: (data: Sensor[]) => void
    selectedSensors?: Sensor[],
    setSelectedChannels?: (data: Channel[]) => void
    selectedChannels?: Channel[],
    setEditSensor?: (data: boolean) => void,
    setEditChannel?: (data: boolean) => void,
    showHeader: boolean,
    setSelectedLocations?: (data: Location[]) => void,
    setEditLocation?: (data: boolean) => void,
    setSelectedMeasuringDevices?: any,
    setEditMeasuringDevice?: any
}
export const SensorOverview = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common', 'sensors']);

    const [sensors, setSensors] = useState<any[]>([]);
    // Lokale States für die Untertabelle einer expandedRow, muss so gelöst werden, weil die props an das
    // 'expandedElement' nur einmal initial übergeben werden können, aber keine Aktualisierten props
    const [selectedSensors, setSelectedSensors] = useState<Sensor[]>([]);

    // arrays for filter dropdowns
    const [sensorTypes, setSensorTypes] = useState<any[]>([]);
    const [measuringDevices, setMeasuringDevices] = useState<any[]>([]);
    const [locations, setLocations] = useState<any[]>([]);
    const [elements, setElements] = useState<any[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const _setSensors = () => {
        const tmplist: any = [];
        getSensors(props.project.id).then(result => {
            for (let i = 0; i < result.length; i++) {
                // Füge die Unterkomponente nur hinzu, wenn der Sensor auch Channels hat
                if (result[i].has_channels)
                    result[i]['expandableElement'] = (
                        <div className={'card'}>
                            <ChannelsOverview
                                key={'co'+i}
                                projectId={props.project.id}
                                sensorId={result[i].sensor_id}
                                showHeader={true}
                                setEditChannel={props.setEditChannel}
                                selectedChannels={props.selectedChannels}
                                setSelectedChannels={props.setSelectedChannels}
                                setReload={props.setReloadChannels}
                                reload={props.reloadChannels}
                            />
                        </div>
                    );
                if (props.measuringDeviceId === result[i].measuring_device_id)
                    tmplist.push(result[i]);
            }
            if (props.measuringDeviceId) {
                setSensors(tmplist);
                setDataLoaded(true);
                // filter dropdown values
                setSensorTypes(getGroupedItems(tmplist, 'sensor_type_id', 'sensor_type_name'));
                setMeasuringDevices(getGroupedItems(tmplist, 'measuring_device_id', 'measuring_device_name'));
                setLocations(getGroupedItems(tmplist, 'location_id', 'location_name'));
                setElements(getGroupedItems(tmplist, 'element_id', 'element_name'));
            } else {
                setSensors(result);
                setDataLoaded(true);
                // filter dropdown values
                setSensorTypes(getGroupedItems(result, 'sensor_type_id', 'sensor_type_name'));
                setMeasuringDevices(getGroupedItems(result, 'measuring_device_id', 'measuring_device_name'));
                setLocations(getGroupedItems(result, 'location_id', 'location_name'));
                setElements(getGroupedItems(result, 'element_id', 'element_name'));
            }
        });
    };

    useEffect(() => {
        _setSensors();
    }, []);

    useEffect(() => {
        // Lade neu, z.B. wenn Elemente gelöscht wurden
        if (props.reload) {
            _setSensors();
            if (props.setReload) props.setReload(false);
        }
    }, [props.reload]);


    useEffect(() => {
        if (props.selectedSensors) setSelectedSensors(props.selectedSensors);
    }, [props.selectedSensors]);

    const filterCheckboxIsEnabled = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), t('enabled'), t('disabled'));
    };

    const filterCheckboxIsVisible = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), t('visible'), t('invisible'));
    };

    let columns: ColumnObject[] = [
        {
            field: 'is_active', header: t('active'), style: {maxWidth: '100px'},
            filter: true, filterElement: filterCheckboxIsEnabled,
        }, {
            field: 'is_visible', header: t('visible'), style: {maxWidth: '120px'},
            filter: true, filterElement: filterCheckboxIsVisible
        }, {
            field: 'sensor_id', header: t('metrologyManagement:attributes.sensorId'),
            filter: false, display: false
        }, {
            field: 'sensor_name', header: t('name'),
            filter: true, filterField: 'sensor_name'
        }, {
            field: 'description', header: t('description'),
            filter: true, filterField: 'description', display: false
        }, {
            field: 'sensor_type_id', header: t('typeId'), style: {maxWidth: '100px'},
            filter: false, display: false
        }, {
            field: 'sensor_type_name', header: t('type'),
            filter: true, filterField: 'sensor_type_id', filterDropdownValues: sensorTypes
        }, {
            field: 'measuring_device_id', header: t('metrologyManagement:attributes.measuringDeviceId'),
            filter: false, display: false
        }, {
            field: 'measuring_device_name', header: t('metrologyManagement:attributes.measuringDevice'),
            filter: true, filterField: 'measuring_device_id', filterDropdownValues: measuringDevices,
            body: checkPermission(permissions.editMetrology) && props.setEditMeasuringDevice ? (sensor: any) => {
                return sensor.measuring_device_id ? (<u className='cell-function' onClick={(e) => {
                    e.stopPropagation();
                    getMeasuringDevice(props.project.id, sensor.measuring_device_id).then((device) => {
                        props.setSelectedMeasuringDevices([device]);
                        props.setEditMeasuringDevice(true);
                    });
                }}>
                    <FontAwesomeIcon icon={faPencil} className='ml-2'/>&nbsp;{sensor.measuring_device_name}</u>)
                    : null;
            } : null
        }, {
            field: 'location_id', header: t('locations:attributes.locationId'),
            filter: false, display: false
        },{
            field: 'location_name', header: t('location'),
            filter: true, filterField: 'location_id', filterDropdownValues: locations,
            body: (checkPermission(permissions.editMetrology) && props.setEditLocation) ? (device: any) => {
                return device.location_id ? (<u className='cell-function' onClick={(e) => {
                    e.stopPropagation();
                    getLocation(props.project.id, device.location_id).then((location) => {
                        if (props.setSelectedLocations) props.setSelectedLocations([location]);
                        if (props.setEditLocation) props.setEditLocation(true);
                    });
                }}>
                    <FontAwesomeIcon icon={faPencil} className='ml-2'/>&nbsp;{device.location_name}</u>)
                    : null;
            } : null
        }];

    // Zeige die Spalte 'element_name' nur in ConditionMonitoring-Projekten an (und natürlich bei MonitoringEverything)
    if (checkProjectType(ProjectType.ConditionMonitoring)) {
        columns.push({
            field: 'element_id', header: t('metrologyManagement:attributes.sensorElementId'),
            filter: false, display: false
        }, {
            field: 'element_name', header: t('metrologyManagement:attributes.sensorElementName'),
            filter: true, filterField: 'element_id', filterDropdownValues: elements
        }, {
            field: 'sensitivity', header: t('metrologyManagement:attributes.sensitivity'),
            filter: false, display: false
        }, {
            field: 'sensitivity_unit', header: t('metrologyManagement:attributes.sensitivityUnit'),
            filter: false, display: false
        });
    }

    const filters = {
        'is_active': {
            operator: FilterOperator.AND,
            constraints: [{value: true, matchMode: FilterMatchMode.EQUALS}]
        },
        'is_visible': {
            operator: FilterOperator.AND,
            constraints: [{value: true, matchMode: FilterMatchMode.EQUALS}]
        },
        'sensor_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'sensor_type_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'location_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'measuring_device_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        },
        'element_id': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        }
    };

    return (
        <>
            <CustomDataTable
                key={'sensors'}
                id='sensors'
                selectionMode={(props.measuringDeviceId) ? 'single' : 'multiple'}
                expandable={true}
                columns={columns}
                editable={checkPermission(permissions.editMetrology)}
                sortable={true}
                displayColumnFilter={true}
                value={sensors}
                paginator={sensors.length > 100}
                selection={selectedSensors}
                onSelectionChange={(e: any) => {
                    if (props.setSelectedSensors) props.setSelectedSensors(e.value);
                }}
                onRemoveClick={(data: Sensor) => {
                    // eslint-disable-next-line new-cap
                    CustomConfirmDialog({
                        message: t('metrologyManagement:dialogs.deleteSensorDialog.message'),
                        header: t('confirmation'),
                        translation: t,
                        onConfirm: () => {
                            if(data.sensor_id)
                                deleteSensor(props.project.id, [data.sensor_id]).then(result => {
                                    if (result.error) {
                                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                                    } else {
                                        _setSensors();
                                        showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.sensorDeleted'));
                                    }
                                });
                        }
                    });
                }}
                onEditClick={(data: Sensor) => {
                    if (props.setSelectedSensors) props.setSelectedSensors([data]);
                    setSelectedSensors([data]);
                    if (props.setEditSensor) props.setEditSensor(true);
                }}
                headerTitle={props.showHeader ? t('sensors') : ''}
                ableToDelete={checkPermission(permissions.editMetrology)}
                ableToUpdate={checkPermission(permissions.editMetrology)}
                filters={filters}
                dataloaded={dataLoaded}
                showExportCSV={true}
                showClearFiltersButton={true}
            />
        </>
    );
};
