import React, { useRef, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsBoost from 'highcharts/modules/boost';
import HighchartsReact from 'highcharts-react-official';
import {Button} from 'primereact/button';
import {useTranslation} from 'react-i18next';

type ChartProps = {
    forderhoheFunction? : number,
    wirkungsgradFunction?: number,
    leistungsbedarfFunction?: number,
    graphMax?: number,
    minThreshold: number,
    maxThreshold: number
    getFordValues: (values: any)=>void,
    getWirkValues: (values: any)=> void,
    getLeistValues: (values: any)=> void,
    rects: any,
    updateRects:(values:any)=> void,
    rectangleCount: number,
    setRectangleCount: any,
    graphForderhohe: any,
    graphLeistungsbedarf: any,
    graphWirkungsgrad: any,
}
const GraphConfiguration = (props: ChartProps) => {
    const { t } = useTranslation(['common']);
    HighchartsBoost(Highcharts);
    const chartRef = useRef<any>(null);
    const startX = useRef<any>(null);
    const startY = useRef<any>(null);
    // const [rectangleCount, setRectangleCount] = useState(0);
    const rectangleElements = useRef<Highcharts.SVGElement[]>([]);
    const colors = ['yellow', 'blue', 'orange'];
    props.rects.map((r:any,index:number) => {
        const chart = chartRef.current?.chart;
        const xAxis = chart?.xAxis[0];
        const yAxis = chart?.yAxis[0];

        const x1 = xAxis?.toPixels(Number(r.x1));
        const y1 = yAxis?.toPixels(Number(r.y1));
        const x2 = xAxis?.toPixels(Number(r.x2));
        const y2 = yAxis?.toPixels(Number(r.y2));

        const width = Math.abs(x2 - x1);
        const height = Math.abs(y1 - y2);

        const rectangleElement = chartRef.current?.chart?.renderer
            .rect(Number(x1), Number(y1), Number(width), Number(height), 5)
            .attr({
                'stroke-width': 2,
                stroke: 'black',
                fill: colors[index],
                opacity: 0.1,
                zIndex: 5
            })
            .add()
            .toFront();
        if (rectangleElement === undefined) return;
        if (rectangleElements.current.length < 3)
            rectangleElements.current.push(rectangleElement);
        else {
            rectangleElements.current[index].destroy();
            rectangleElements.current[index] = rectangleElement;
        }
    });

    useEffect(() => {
        const chart = chartRef.current?.chart;
        const xAxis = chartRef.current?.chart?.xAxis[0];
        const yAxis = chartRef.current?.chart?.yAxis[0];

        const handleContainerMouseDown = (event: MouseEvent) => {
            const { layerX, layerY }: any = event;
            startX.current = xAxis.toValue(layerX);
            startY.current = yAxis.toValue(layerY);
        };

        const handleContainerMouseUp = () => {
            if (props.rectangleCount >= 3) return;

            const { layerX, layerY }: any = event;

            const x1 = Math.ceil(startX.current);
            const y1 = Math.ceil(startY.current);
            const x2 = Math.ceil(xAxis.toValue(layerX));
            const y2 = Math.ceil(yAxis.toValue(layerY));

            const color = colors[props.rectangleCount];
            props.rects[props.rectangleCount] = {
                x1,
                y1,
                x2,
                y2
            };
            props.updateRects([...props.rects]);

            if (color === 'yellow') props.getFordValues({ x1, y1, x2, y2 });
            else if (color === 'blue') props.getWirkValues({ x1, y1, x2, y2 });
            else props.getLeistValues({ x1, y1, x2, y2 });

            startX.current = null;
            startY.current = null;
            props.setRectangleCount((prevCount:any) => prevCount + 1);
        };

        const handleContainerMouseMove = (event: MouseEvent) => {
            if (!startX.current || !startY.current || props.rectangleCount >= 3) return;

            const { layerX, layerY }: any = event;

            const x1 = Math.ceil(startX.current);
            const y1 = Math.ceil(startY.current);
            const x2 = Math.ceil(xAxis.toValue(layerX));
            const y2 = Math.ceil(yAxis.toValue(layerY));

            const color = colors[props.rectangleCount];
            const rectsCopy = JSON.parse(JSON.stringify(props.rects));
            rectsCopy[props.rectangleCount] = {
                x1,
                y1,
                x2,
                y2
            };
            props.updateRects([...rectsCopy]);

        };

        const handleContainerMouseLeave = () => {
            if (!startX.current || !startY.current) return;

            // Remove the unfinished rectangle
            startX.current = null;
            startY.current = null;
        };

        if (chart) {
            chart.container.addEventListener('mousedown', handleContainerMouseDown);
            chart.container.addEventListener('mouseup', handleContainerMouseUp);
            chart.container.addEventListener('mousemove', handleContainerMouseMove);
            chart.container.addEventListener('mouseleave', handleContainerMouseLeave);

            return () => {
                chart.container.removeEventListener(
                    'mousedown',
                    handleContainerMouseDown
                );
                chart.container.removeEventListener(
                    'mouseup',
                    handleContainerMouseUp
                );
                chart.container.removeEventListener(
                    'mousemove',
                    handleContainerMouseMove
                );
                chart.container.removeEventListener(
                    'mouseleave',
                    handleContainerMouseLeave
                );
            };
        }},[props.rectangleCount, props.rects]);

    function createScatterSeries(
        name: string,
        color: string,
        data: any[],
        valueSuffix: string
    ) {
        // console.log(name, ' ===> ', data);
        return {
            name,
            type: 'scatter',
            color,
            data: data.map((point) => ({
                x: point.value_x,
                y: point.value_y,
                date: point.date
            })),
            tooltip: {
                pointFormatter: function (this: Highcharts.Point): string {
                    interface CustomPoint extends Highcharts.Point {
                        date: string;
                    }
                    const customPoint = this as CustomPoint;
                    return `<span>Date: ${Highcharts.dateFormat(
                        '%m/%d/%Y %l:%M%p',
                        new Date(customPoint.date).getTime()
                    )}</span><br/>
          <span>Value X: ${customPoint.x} ${valueSuffix}</span><br/>
          <span>Value Y: ${customPoint.y}</span>`;
                }
            },
            enableMouseTracking: true
        };
    }
    const forderhoheSeries = createScatterSeries('Forderhöhe', 'red', props.graphForderhohe, 'Kw');
    const leistungsbedarfSeries = createScatterSeries(
        'Leistungsbedarf',
        'green',
        props.graphLeistungsbedarf,
        '%'
    );
    const wirkungsgradSeries = createScatterSeries(
        'Wirkungsgrad',
        'yellow',
        props.graphWirkungsgrad,
        'm3/h'
    );

    const
        options ={
            chart: {
                zoomType: 'none',
                // height: 500,
            },
            title: {
                text: `${t('conditionMonitoring:energy_eff_graph')}`,
            },
            credits: {
                enabled: false,
            },
            xAxis: [{
                min: 0,
                max: Number(props.graphMax),
                title: {
                    text: 'Förderstrom [m3/h]',
                },
                plotLines: [{
                    color: '#FF0000', // Red
                    width: 2,
                    value: props.minThreshold,
                    label: {
                        text: `${props.minThreshold}`,
                        rotation: '(center, 0, 0)',
                        style: {color: '#FF0000'},
                        y: 15,
                        x: -25
                    }
                },{
                    color: '#FF0000', // Red
                    width: 2,
                    value: props.maxThreshold,
                    label: {
                        text: `${props.maxThreshold}`,
                        rotation: '(center, 0, 0)',
                        style: {color: '#FF0000'},
                        y: 15,
                        x: 8
                    }
                }]
            }],
            yAxis: [{ // Primary yAxis
                title: {
                    text: 'Förderhöhe [m]/Leistungsbedarf [Kw] ',
                },
            }, { // Secondary yAxis
                title: {
                    text: 'Wirkungsgrad [%]',
                },
                opposite: true
            }],
            tooltip: {
                shared: true
            },
            plotOptions: {
                series: {
                    boostThreshold: 10000, // Set the threshold for boosting
                },
            },
            series: [
                {
                    name: 'Förderhöhe_Kennl.',
                    type: 'line',
                    // yAxis: 1,
                    data: props.forderhoheFunction,
                    color: 'blue',
                    enableMouseTracking: false
                },
                {
                    name: 'Wirkungsgrad_Kennl.',
                    type: 'line',
                    states:{
                        hover:{
                            enabled: false
                        },
                    },
                    // yAxis: 1,
                    data: props.wirkungsgradFunction,
                    color: 'orange',
                    enableMouseTracking: false
                },
                {
                    name: 'Leistungsbedarf_Kennl.',
                    type: 'line',
                    states:{
                        hover:{
                            enabled: false
                        },
                    },
                    // yAxis: 1,
                    data: props.leistungsbedarfFunction,
                    color: 'yellow',
                    enableMouseTracking: false
                },
                forderhoheSeries,
                leistungsbedarfSeries,
                wirkungsgradSeries,
                {
                    data: [0],
                    yAxis: 1,
                    showInLegend: false,
                    enableMouseTracking: false,
                    visible: true,

                }
            ],
        };


    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartRef}
            />


            {props.rectangleCount > 0 && <div className='flex flex-wrap mt-2'>
                <div className="rectangle1"></div>
                <p className='p-1' style={{fontSize: '10px'}}>{t('conditionMonitoring:forderhohe_rectangle')}</p>
                <div className="rectangle2 ml-1"></div>
                <p className='p-1' style={{fontSize: '10px'}}>{t('conditionMonitoring:wirkungsgrad_rectangle')}</p>
                <div className="rectangle3 ml-1"></div>
                <p className='p-1' style={{fontSize: '10px'}}>{t('conditionMonitoring:leistungsbedarf_rectangle')}</p>
            </div>}


        </>

    );
};

export default GraphConfiguration;
