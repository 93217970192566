import React, {FC, PropsWithChildren} from "react";

type Props = {

}
const Controls:FC<PropsWithChildren<Props>>  = ({ children }) => {
    return <div>{children}</div>;
};

export default Controls;
