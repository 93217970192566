/*
 * OperatingStateForm.tsx
 * Author: lnappenfeld
 * Date: 16.06.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import Input from '../global/Input';
import {Nullable} from '../functions/Global';
import {
    createOperatingState,
    OperatingState,
    OperatingStateCondition,
    updateOperatingState
} from '../functions/ConditionMonitoring';
import {ElementTreeSelectList} from './ElementTreeSelectList';
import {OperatingStateConditionOverview} from './OperatingStateConditionOverview';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {ViewButton} from '../global/ViewButton';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {ViewButtonsContainer} from '../global/ViewButtonsContainer';
import {checkPermission} from '../../functions/functionLibrary';
import {permissions} from '../../config/permissions';
import {
    buttonCreateOperatingState,
    buttonCreateOperatingStateCondition, buttonDeleteOperatingStates,
    makeButtonItem
} from '../global/viewButtonSelection';
import ViewButtons from '../global/ViewButtons';
import {OperatingStateConditionDialogEditForm} from './OperatingStateConditionDialogEditForm';
import {OSCFormType} from './OperatingStateConditionForm';
import {getChannel} from '../functions/Metrology';

type Props = {
    projectId: string,
    operatingState: Nullable<OperatingState>,
    onFinished: (success: boolean) => void,
    // OperatingStateConditions
    // reloadOperatingStateConditions?: boolean,
    // setReloadOperatingStateConditions?: (reload: boolean) => void
    // setSelectedOperatingStateConditions?: (data: OperatingStateCondition[]) => void
    // selectedOperatingStateConditions?: OperatingStateCondition[],
    // setEditOperatingStateCondition?: (data: boolean) => void,
    // setSelectedOperatingState?: OperatingState
}

export const OperatingStateForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [oscFormType, setOscFormType] = useState<OSCFormType>(OSCFormType.Default);

    const [selectedOperatingStateConditions, setSelectedOperatingStateConditions] = useState<OperatingStateCondition[]>([]);
    const [reloadOperatingStateConditions, setReloadOperatingStateConditions] = useState<boolean>(true);
    const [editOperatingStateCondition, setEditOperatingStateCondition] = useState<boolean>(false);

    // Für den Typ 'NewOperatingState' im OperatingStateConditionDialog
    const [newOperatingStateConditions, setNewOperatingStateConditions] = useState<OperatingStateCondition[]>([]);

    const operatingStateId = props.operatingState ? props.operatingState.operating_state_id : null;

    const defaultValues = {
        'name': props.operatingState ? props.operatingState.operating_state_name : null,
        'description': props.operatingState ? props.operatingState.description : null,
        'element_id': props.operatingState ? props.operatingState.element_id : null,
    };

    const {control, formState: {errors}, handleSubmit, getValues, setValue, trigger} = useForm({defaultValues});

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    useEffect(() => {
        if (props.operatingState && props.operatingState.operating_state_id) {
            // Aktualisiere einen bestehenden Betriebszustand
            setOscFormType(OSCFormType.Default);
        } else {
            // Erstelle einen neuen Betriebszustand
            setOscFormType(OSCFormType.NewOperatingState);
        }
    }, []);

    const saveData = (operatingState: any) => {
        const projectId = {'project_id': props.projectId};
        operatingState = {...projectId, ...operatingState};

        if (operatingStateId !== null) {
            const _operatingStateId = {'operating_state_id': operatingStateId};
            operatingState = {..._operatingStateId, ...operatingState};

            updateOperatingState(operatingState).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), t('errorMessages:' + result.error));
                } else {
                    showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.operatingStateUpdated'));
                    props.onFinished(true);
                }
            });
        } else {
            const _operatingStateConditions = {'operating_state_conditions': newOperatingStateConditions};
            operatingState = {..._operatingStateConditions, ...operatingState};
            createOperatingState(operatingState).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), t('errorMessages:' + result.error));
                } else {
                    showMessageOnSuccess(t('success'), t('metrologyManagement:toasts.operatingStateCreated'));
                    props.onFinished(true);
                }
            });
        }

    };

    return (
        <>
            <form id='formOperatingState' onSubmit={handleSubmit(saveData)}>
                <div className={'card'}>
                    <div className={'grid'}>
                        <div className={'col-6'}>
                            <Input
                                edit={true} label={t('name')} name={'name'}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: t('name') + ' ' + t('input:required')}}
                            />
                        </div>
                        <div className={'col-6'}>
                            <ElementTreeSelectList
                                type={'operating_state'}
                                projectId={props.projectId}
                                label={t('metrologyManagement:attributes.operatingStateElementName')}
                                name={'element_id'}
                                onChange={e => setValue('element_id', e.value)}
                                validationControl={control}
                                validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: t('metrologyManagement:attributes.sensorElementName') + ' ' + t('input:required')}}
                            />
                        </div>
                        <div className={'col-12'}>
                            <Input
                                edit={true} label={t('description')} name={'description'} type={'textarea'}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: false}}
                            />
                        </div>
                    </div>
                </div>
            </form>
            <div className={'card'}>
                <ViewButtons
                    title={t('')}
                    items={[
                        makeButtonItem(buttonCreateOperatingStateCondition, t, () => {
                            // if (props.setSelectedOperatingStateConditions) props.setSelectedOperatingStateConditions([]);
                            // if (props.setEditOperatingStateCondition) props.setEditOperatingStateCondition(true);
                            setSelectedOperatingStateConditions([]);
                            setEditOperatingStateCondition(true);
                        })
                    ]}
                />
                <OperatingStateConditionOverview
                    type={oscFormType}
                    projectId={props.projectId}
                    operatingStateId={operatingStateId}
                    selectedOperatingStateConditions={selectedOperatingStateConditions}
                    setSelectedOperatingStateConditions={setSelectedOperatingStateConditions}
                    setEditOperatingStateCondition={setEditOperatingStateCondition}
                    setReload={setReloadOperatingStateConditions}
                    reload={reloadOperatingStateConditions}

                    setOperatingStateConditions={setNewOperatingStateConditions}
                    operatingStateConditions={newOperatingStateConditions}
                />
            </div>
            <OperatingStateConditionDialogEditForm
                type={oscFormType}
                projectId={props.projectId}
                editOperatingStateCondition={editOperatingStateCondition}
                setEditOperatingStateCondition={setEditOperatingStateCondition}
                selectedOperatingStateConditions={selectedOperatingStateConditions}
                setSelectedOperatingStateConditions={setSelectedOperatingStateConditions}
                setReloadOperatingStateConditions={setReloadOperatingStateConditions}
                selectedOperatingStates={props.operatingState ? [props.operatingState] : []}
                addOperatingStateCondition={(newOsc: OperatingStateCondition) => {
                    console.log('newOSC: ', newOsc);
                    if (newOsc.channel_id) {
                        getChannel(props.projectId, newOsc.channel_id).then(channel => {
                            newOsc.channel_name = channel.channel_name;
                            newOsc.unit = channel.unit;
                            setNewOperatingStateConditions([...newOperatingStateConditions, newOsc]);
                        });
                    }
                }}
                updateOperatingStateCondition={(osc: OperatingStateCondition) => {
                    console.log('osc: ', osc);
                    if (osc.channel_id) {
                        getChannel(props.projectId, osc.channel_id).then(channel => {
                            osc.channel_name = channel.channel_name;
                            osc.unit = channel.unit;

                            // 1. Lösche die alte Betriebszustandsbedingung
                            const newArray = newOperatingStateConditions?.filter(
                                obj => obj.operating_state_condition_id !== osc.operating_state_condition_id
                            ) as OperatingStateCondition[];
                            console.log('newArray ', newArray)

                            // 2. Füge die neue Betriebszustandsbedingung hinzu
                            setNewOperatingStateConditions([...newArray, osc]);
                        });
                    }
                }}
            />
        </>
    );
};