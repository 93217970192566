/*
 * Actions.tsx
 * Author: lnappenfeld
 * Date: 27.04.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Action, deleteActions} from '../functions/Notification';
import {ActionOverviewType, ActionsOverview} from './ActionsOverview';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';

type Nullable<T> = T | null;

type Props = {
    projectId: any,
    reloadNotificationActions: boolean,
    setReloadNotificationActions: (e: boolean) => void,
    showActionDialog: boolean,
    setShowActionDialog: (e: boolean) => void,
    selectedActions: Action[],
    setSelectedActions: (e: Action[]) => void,
    showDeleteActionsDialog: boolean,
    setShowDeleteActionsDialog: (e: boolean) => void,
    setReloadUsers: (e: boolean) => void
}

const Actions = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    useEffect(() => {
        if (props.showDeleteActionsDialog) {
            _deleteActions(props.selectedActions);
        }
    }, [props.showDeleteActionsDialog]);

    // lösche mehrere Aktionen
    const _deleteActions = (data: Nullable<Action[]>) => {
        if (!data) return;
        const actionIds: string[] = [];
        for (const action of data) {
            if (typeof action.action_id === "string") {
                actionIds.push(action.action_id);
            }
        }
        CustomConfirmDialog({
            message: t('userManagement:dialogs.deleteActionsDialog.message'),
            header: t('userManagement:dialogs.deleteActionsDialog.header'),
            translation: t,
            onConfirm: () => {
                deleteActions(props.projectId, actionIds).then(result => {
                    if (result.error) {
                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                    } else {
                        props.setReloadNotificationActions(true);
                        props.setSelectedActions([]);
                        showMessageOnSuccess(t('success'), t('userManagement:toasts.actionsDeleted'))
                    }
                    props.setShowDeleteActionsDialog(false);

                });
            },
            onCancel: () => {
                props.setShowDeleteActionsDialog(false);
            }
        });
    };

    return (
        <ActionsOverview
            projectId={props.projectId}
            type={ActionOverviewType.Default}
            reload={props.reloadNotificationActions}
            setReload={props.setReloadNotificationActions}
            selectedActions={props.selectedActions}
            setSelectedActions={props.setSelectedActions}
            showActionDialog={props.showActionDialog}
            setShowActionDialog={props.setShowActionDialog}
        />
    );
};

export default Actions;