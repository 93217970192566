/*
 * ReportActionsMapOverview.tsx
 * Author: lnappenfeld
 * Date: 20.12.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {useTranslation} from 'react-i18next';
import {
    getReportActions,
} from '../functions/Reporting';

type Props = {
    projectId: string
    reportId: string
}
export const ReportActionsMapOverview = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common', 'reporting']);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [reportActions, setReportActions] = useState<any[]>([]);

    useEffect(() => {
        _setReportFiles();
    }, []);

    const _setReportFiles = () => {
        getReportActions(props.projectId, props.reportId).then(result => {
            setReportActions(result);
            setDataLoaded(true);
        });
    };

    const reportFileColumns: ColumnObject[] = [
        {
            field: 'is_enabled', header: t('status'), style: {maxWidth: '100px'},
        },
        {
            field: 'report_name',
            header: t('name'),
            style: {minWidth: '250px'},
        },
        {
            field: 'report_type_name',
            header: t('alarmManagement:attributes.alarmType'),
        },
        {
            field: 'action_name',
            header: t('action'),
        },
        {
            field: 'action_type_name',
            header: t('userManagement:attributes.recipientType'),
            style: {maxWidth: '225px'},
        },
    ];

    return (
        <>
            <CustomDataTable
                id='report-actions'
                headerTitle={t('reportingManagement:global.reportActions')}
                columns={reportFileColumns}
                editable={false}
                sortable={true}
                value={reportActions}
                ableToDelete={false}
                ableToUpdate={false}
                dataloaded={dataLoaded}
            />
        </>
    );
};